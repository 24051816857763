<template>
  <section class="message-center-deliverability">
    <div class="deliverability-info-wrapper">
      <InfoCard
        class="info-card"
        :value="overviewStats.total"
        desc="Processed"
      />
      <InfoCard
        class="info-card"
        :value="statsDelivered.total"
        :secondary-value="statsDelivered.percent"
        desc="Delivered"
        percent-secondary
      />
      <InfoCard
        v-if="!isSms"
        class="info-card"
        :value="statsBounced.total"
        :secondary-value="statsBounced.percent"
        desc="Bounced"
        percent-secondary
      />
      <InfoCard
        class="info-card"
        :value="statsFailed.total"
        :secondary-value="statsFailed.percent"
        desc="Failed"
        percent-secondary
      />
    </div>

    <div class="deliverability-charts-wrapper">
      <DeliveryBarChart
        v-if="!isSms && !!deliveryStats.mailProviders"
        title="Email Providers"
        :series="deliveryStats.mailProviders"
        :loading="isFetchingMessageDeliveryStats"
      />
      <DeliveryBarChart
        v-if="!isSms && !!deliveryStats.browsers"
        title="Browsers"
        :series="deliveryStats.browsers"
        :loading="isFetchingMessageDeliveryStats"
      />
      <DeliveryBarChart
        v-if="!isSms && !!deliveryStats.devices"
        title="Devices"
        :series="deliveryStats.devices"
        :loading="isFetchingMessageDeliveryStats"
      />
      <DeliveryBarChart
        v-if="isSms && !!deliveryStats.countryCodes"
        title="Country Codes"
        :series="deliveryStats.countryCodes"
        :loading="isFetchingMessageDeliveryStats"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import InfoCard from './components/info-card.vue';
import DeliveryBarChart from './components/delivery-bar-chart.vue';

export default {
  name: 'MessageCenterMessageViewDeliverability',
  components: { InfoCard, DeliveryBarChart },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,

      messageDeliveryStats: state => state.message.messageDeliveryStats,
      isFetchingMessageDeliveryStats: state => state.message.isFetchingMessageDeliveryStats,

      recipients: state => state.recipient.recipients,
      totalRecipientsCount: state => state.recipient.totalRecipientsCount,
      isFetchingRecipients: state => state.recipient.isFetchingRecipients,
      hasFetchRecipientsFailed: state => state.recipient.hasFetchRecipientsFailed,
    }),

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms';
    },

    overviewStats() {
      if (!!this.currentSelectedMessage && !!this.currentSelectedMessage.statusDetails) {
        const { totalMessages, sent, opened, clicked, unsubscribed, bounced, failed } = this.currentSelectedMessage.statusDetails;

        const stats = {
          total: totalMessages || 0,
          sent: (sent || 0) + (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          opened: (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          clicked: clicked || 0,
          unsubscribed: unsubscribed || 0,
          bounced: bounced || 0,
          failed: failed || 0,
        };

        const ctorValue = stats.opened === 0 ? 0 : (stats.clicked / stats.opened) * 100;

        return {
          ...stats,
          ctor: ctorValue > 0 && ctorValue < 10 ? ctorValue.toFixed(1) : Math.trunc(ctorValue) || 0,
        };
      }

      return {
        total: 0,
        sent: 0,
        bounced: 0,
        failed: 0,
      };
    },

    statsDelivered() {
      return {
        total: this.overviewStats.sent || 0,
        percent: this.overviewStats.total === 0 ? 0 : Math.trunc((this.overviewStats.sent / this.overviewStats.total) * 100) || 0,
      }
    },

    statsBounced() {
      return {
        total: this.overviewStats.bounced || 0,
        percent: this.overviewStats.total === 0 ? 0 : Math.trunc((this.overviewStats.bounced / this.overviewStats.total) * 100) || 0,
      }
    },

    statsFailed() {
      return {
        total: this.overviewStats.failed || 0,
        percent: this.overviewStats.total === 0 ? 0 : Math.trunc((this.overviewStats.failed / this.overviewStats.total) * 100) || 0,
      }
    },

    deliveryStats() {
      if (!!this.messageDeliveryStats) {
        const { mailProviders, devices, browsers, countryCodes } = this.messageDeliveryStats;

        const stats = {};

        // We need to calculate the percentage for each count value
        //
        if (!!mailProviders && Object.keys(mailProviders).length > 0) {
          const tot = Object.values(mailProviders).reduce((acc, val) => acc + val, 0)
          stats['mailProviders'] = {
            name: 'mailProviders',
            data: Object.entries(mailProviders).reduce((acc, [key, value]) => {
              acc[key] = tot === 0 ? 0 : Math.floor((value / tot) * 100);
              return acc;
            }, {}),
          }
        }

        if (!!devices && Object.keys(devices).length > 0) {
          const tot = Object.values(devices).reduce((acc, val) => acc + val, 0)
          stats['devices'] = {
            name: 'devices',
            data: Object.entries(devices).reduce((acc, [key, value]) => {
              acc[key] = tot === 0 ? 0 : Math.floor((value / tot) * 100);
              return acc;
            }, {}),
          }
        }

        if (!!browsers && Object.keys(browsers).length > 0) {
          const tot = Object.values(browsers).reduce((acc, val) => acc + val, 0)
          stats['browsers'] = {
            name: 'browsers',
            data: Object.entries(browsers).reduce((acc, [key, value]) => {
              acc[key] = tot === 0 ? 0 : Math.floor((value / tot) * 100);
              return acc;
            }, {}),
          }
        }

        if (!!countryCodes && Object.keys(countryCodes).length > 0) {
          const tot = Object.values(countryCodes).reduce((acc, val) => acc + val, 0)
          stats['countryCodes'] = {
            name: 'countryCodes',
            data: Object.entries(countryCodes).reduce((acc, [key, value]) => {
              acc[key] = tot === 0 ? 0 : Math.floor((value / tot) * 100);
              return acc;
            }, {}),
          }
        }

        return stats;
      }

      return {
        mailProviders: null,
        devices: null,
        browsers: null
      }
    },
  },

  async mounted() {
    if (!this.currentSelectedMessage) {
      this.fetchMessage();
      this.fetchMessageRecipients();
      this.fetchMessageDeliveryStats();
    }
  },

  methods: {
    ...mapActions([
      'message/FETCH_MESSAGE',
      'message/FETCH_MESSAGE_DELIVERY_STATS',
      'recipient/FETCH_MESSAGE_RECIPIENTS',
    ]),

    async fetchMessage() {
      await this['message/FETCH_MESSAGE'](this.oid)
    },

    async fetchMessageRecipients() {
      await this['recipient/FETCH_MESSAGE_RECIPIENTS']({
        messageTaskOid: this.oid,
      });
    },

    async fetchMessageDeliveryStats() {
      await this['message/FETCH_MESSAGE_DELIVERY_STATS'](this.oid);
    }
  },
}
</script>

<style lang="scss" scoped>
.message-center-deliverability {
  margin-top: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .deliverability-info-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;

    width: 90%;
    height: 100%;
    max-width: 1200px;

    margin-bottom: 30px;
  }

  .deliverability-charts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;

    width: 90%;
    height: 100%;
    max-width: 1200px;

    margin-bottom: 30px;
  }
}
</style>
