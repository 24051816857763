function isUnsubscribeLink(shortUrl) {
  const { meta } = shortUrl;
  if (!meta) return false

  if (typeof meta === 'object') {
    return meta.unsubscribeLink || meta["unsubscribe-link"] || false;
  } else if (typeof meta === 'string') {
    try {
      return JSON.parse(meta)["unsubscribe-link"] || false
    } catch {
      console.error("Failed to parse JSON object: ", meta)
      return false
    }
  }
}

function extractClickStatsFromSnapshot(clicksSnapshot, shortUrls) {
  return clicksSnapshot.reduce((items, clicksSnapshotItem) => {
    const shortUrl = shortUrls.find(u => u.targetUrl === clicksSnapshotItem.targetUrl)

    // If link is an unsubscribe-link, we hide it from the list
    if (!!shortUrl && isUnsubscribeLink(shortUrl)) {
      return items
    }

    items.push({
      name: clicksSnapshotItem.targetUrl,
      uniqueTotal: clicksSnapshotItem.uniqueTotal,
      total: clicksSnapshotItem.total,
      timestamp: clicksSnapshotItem.sysMtime,
    })
    return items
  }, [])
}

function extractLatestDateFromClickStats(clicksSnapshot, getLatestSyncDate) {
  if (typeof getLatestSyncDate !== 'function') {
    return null
  }

  let latestDate = null

  clicksSnapshot.forEach(clicksSnapshotItem => {
    latestDate = getLatestSyncDate(latestDate, clicksSnapshotItem.sysMtime)
  })

  return latestDate
}

function extractNotClickedUrlsFromShortUrls(clicksSnapshot, clickStats, shortUrls) {
  let notClickedUrls = []

  // If some urls are not getting clicked, we add them anyway
  if (clicksSnapshot.length !== shortUrls.length) {
    notClickedUrls = shortUrls.reduce((acc, shortUrl) => {
      // Add only if is not already present and is not an unsubscribe-link
      if (!(clickStats.find(o => o.name === shortUrl.targetUrl)) && !isUnsubscribeLink(shortUrl)) {
        acc.push({
          name: shortUrl.targetUrl,
          uniqueTotal: 0,
          total: 0,
          timestamp: shortUrl.sysCtime,
        })
      }
      return acc
    }, [])
  }

  return notClickedUrls
}

function sortClickStats(clickStats) {
  return clickStats.sort((a, b) => {
    if (!a?.total || !b?.total) return 0;
    if (a.total > b.total) return -1;
    if (a.total < b.total) return 1;
  })
}

export function extractClicksStats(clicksSnapshot, shortUrls, latestTimestampCallback = null, getLatestSyncDate = null) {
  if (!clicksSnapshot) return []

  const clickStats = extractClickStatsFromSnapshot(clicksSnapshot, shortUrls)
  const notClickedUrls = extractNotClickedUrlsFromShortUrls(clicksSnapshot, clickStats, shortUrls)
  
  if (typeof latestTimestampCallback === 'function') {
    const latestDate = extractLatestDateFromClickStats(clicksSnapshot, getLatestSyncDate)
    latestTimestampCallback(latestDate)
  }

  return sortClickStats(clickStats.concat(notClickedUrls))
}