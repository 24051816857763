<template>
  <section class="email-editor-wrapper">
    <am2-select-events-modal
      v-if="location==='message-center'"
      :opened="displaySelectEventsModal"
      :default-event-oid="defaultEventOidForSelectEventsModal"
      @confirm="handleSelectEventConfirm"
      @cancel="handleSelectEventCancel"
    />
    <am2-select-dynamic-tags-modal
      v-if="location==='message-center'"
      :is-show="showSelectDynamicTagsModal"
      :dynamic-tags="dynamicTagsForBeefree"
      @select="handleDynamicTagsSelect"
      @cancel="handleDynamicTagsCancel"
    />
    <am2-loading-section
      v-if="!displayBeefreeEditor"
      class="loading-section"
    />
    <section
      ref="editor"
      class="editor"
      :style="{
        padding: $arMediaQuery.pageContent.maxWidth('sm') ? '30px 18px' : '47px 59px',
      }"
    >
      <div
        class="beefree-editor"
        :id="`beefree-editor-${beefreeEditorHash}`"
      ></div>
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { generateHash } from '@/utils/helpers'

import BeePlugin from '@mailupinc/bee-plugin'

const EMPTY_TEMPLATE = {
  "page": {
    "title": "",
    "description": "",
    "template": {
      "name": "template-base",
      "type": "basic",
      "version": "0.0.1"
    },
    "body": {
      "type": "mailup-bee-page-proprerties",
      "container": {
        "style": {
          "background-color": "#FFFFFF"
        }
      },
      "content": {
        "style": {
          "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
          "color": "#000000"
        },
        "computedStyle": {
          "linkColor": "#0068A5",
          "messageBackgroundColor": "transparent",
          "messageWidth": "500px"
        }
      }
    },
    "rows": [
      {
        "type": "one-column-empty",
        "container": {
          "style": {
            "background-color": "transparent"
          }
        },
        "content": {
          "style": {
            "background-color": "transparent",
            "color": "#000000",
            "width": "500px"
          }
        },
        "columns": [
          {
            "grid-columns": 12,
            "modules": [
              {
                "type": "mailup-bee-newsletter-modules-empty",
                "descriptor": {}
              }
            ],
            "style": {
              "background-color": "transparent",
              "padding-top": "5px",
              "padding-right": "0px",
              "padding-bottom": "5px",
              "padding-left": "0px",
              "border-top": "0px dotted transparent",
              "border-right": "0px dotted transparent",
              "border-bottom": "0px dotted transparent",
              "border-left": "0px dotted transparent"
            }
          }
        ]
      }
    ]
  }
}

export default {
  name: 'BeefreeEmailEditor',

  props: {
    dynamicTags: {
      type: Array,
      default: null,
    },
    location: {
      type: String,
      default: 'message-center',
      validator: (val) => ['message-center', 'automation'].indexOf(val) > -1,
    }
  },

  data() {
    const outputDesign = (jsonFile) => this.$emit('outputDesign', jsonFile);

    return {
      design: null,
      displaySelectEventsModal: false,
      defaultEventOidForSelectEventsModal: null,

      // Beefree
      displayBeefreeEditor: false,
      beefreeSelectEventCallback: null,
      beefreeSelectEventCancelCallback: null,
      beefreeEditorHash: generateHash(),
      beePlugin: null,
      beeConfig: {
        container: `beefree-editor`,
        language: 'en-US',
        trackChanges: true,
        loadingSpinnerTheme: 'light',
        contentDialog: {
          addOn: {
            label: 'Insert Event',
            handler: (resolve, reject, args) => {
              this.handleSelectEventClick(resolve, reject)
            }
          },
          mergeTags: {
            label: 'Dynamic tags',
            handler: (resolve, reject) => {
              this.handleSelectDynamicTagsModal(resolve, reject)
            }
          },
        },
        onChange: function (jsonFile, response) {
          outputDesign(jsonFile)
        },
        onComment: function (change, jsonFile) {
          outputDesign(jsonFile)
        },
        onTogglePreview: () => {
          this.updateShowPreview()
        },
        onWarning: function (warningMessage) {
          console.warn('OID', this.promoterOid, 'BEEFREE EDITOR WARN:', warningMessage);
        },
        onError: function (errorMessage) {
          console.error('OID', this.promoterOid, 'BEEFREE EDITOR ERR: ', errorMessage);
        },
        contentDefaults: {},
      },
      showPreviewDynamicTags: false,
      showStructure: false,
      showPreview: false,
      showSelectDynamicTagsModal: false,
      selectDynamicTagsModalCallback: null,
      selectDynamicTagsModalCancelCallback: null,
    };
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      accountOid: state => state.auth.account.oid,
      firstName: state => state.auth.account.firstName,
      lastName: state => state.auth.account.lastName,
    }),
    ...mapGetters({
      getEventImageUrl: 'event/getEventImageUrl',
    }),
    dynamicTagsForBeefree() {
      if (!this.dynamicTags) {
        return [];
      }

      // Tag samplers show up during Preview Dynamic Tags
      return this.dynamicTags.map(function (tag) {
        let tag_sampler = tag
        if (tag === 'email_address') {
          tag_sampler = "sampler@arep.co"
        } else if (tag === "first_name") {
          tag_sampler = "Mary Sue"
        }

        return {
          name: tag,
          value: `{{${tag}}}`,
          previewValue: tag_sampler,
        }
      });
    },
    beefreeEditorIsReadyForEditing() {
      return !!this.beePlugin;
    },
  },

  mounted() {
    this.initEmailEditor();
  },

  methods: {
    async getToken() {
      try {
        const token = await this.$api.beefree.initialiseBeefreePlugin(this.promoterOid)
        this.beePlugin = new BeePlugin(token)

        this.beePlugin.start({
          ...this.beeConfig,
          uid: this.promoterOid,
          container: `beefree-editor-${this.beefreeEditorHash}`,
          language: 'en-US',
          username: `${this.firstName} ${this.lastName}`,
          userHandle: this.accountOid,
        }, this.design || EMPTY_TEMPLATE)
        this.displayBeefreeEditor = true;
      } catch(error) {
        console.error("Failed to fetch Beefree Auth", error)
      }
    },
    setDesign(template_data) {
      // Loading the template into the editor
      const design = (!template_data) ? EMPTY_TEMPLATE : template_data;
      this.design = typeof design === "string" ? JSON.parse(design) : design;
      this.$emit("outputDesign", JSON.stringify(this.design))
      this.loadBeefreeDesign(this.design);
    },
    async loadBeefreeDesign(design) {
      if (!this.beefreeEditorIsReadyForEditing) {
        return;
      }

      this.beePlugin.load(design)
    },
    async initEmailEditor() {
      this.getToken()
      this.loadBeefreeDesign(this.design);
    },
    setEventInBeefree(event) {
      const tinyEvent = {
        oid: event.oid,
        name: event.name,
        description: event.description,
        location: event.location,
        date: this.$arUtils.event.generateDateForEmail(event),
        imageUrl: this.getEventImageUrl(event),
        purchaseTicketUrl: this.$arUtils.event.getEventPurchaseTicketUrl(event) || 'https://example.com',
      };

      if (!tinyEvent.imageUrl) {
        this.$arNotification.push({
          type: 'error',
          message: 'Event does not have an image',
        });
      }

      if (!tinyEvent.purchaseTicketUrl || tinyEvent.purchaseTicketUrl === 'https://example.com') {
        this.$arNotification.push({
          type: 'error',
          message: 'Add URL to the button "Purchase"',
        });
      }

      const rowAddonFromBeefree = {
        "type": "rowAddon",
        "value": {
          "name": "First item",
          "justify-content": "center",
          "align-items": "center",
          "columns": [{
            "weight": 4,
            "modules": [{
              "type": "image",
              "src": tinyEvent.imageUrl,
            }],
          }, {
            "weight": 5,
            "modules": [{
              "type": "paragraph",
              "text": `<span style="font-size: 16px;">${tinyEvent.name}</span><br />${tinyEvent.location}<br />${tinyEvent.date}`,
              "size": 14,
            }]
          }, {
            "weight": 3,
            "modules": [{
              "type": "button",
              "text": "Purchase",
              "href": tinyEvent.purchaseTicketUrl,
              "background-color": "#945bf1",
            }]
          }]
        },
      }

      this.beefreeSelectEventCallback(rowAddonFromBeefree)
    },
    handleSelectEventClick(resolve = null, reject = null) {
      if (this.location === 'automation') {
        this.$emit('selectEventClick')
      } else {
        this.displaySelectEventsModal = true;
      }
      this.defaultEventOidForSelectEventsModal = null;
      this.beefreeSelectEventCallback = resolve;
      this.beefreeSelectEventCancelCallback = reject;
    },
    handleSelectEventConfirm(event) {
      this.displaySelectEventsModal = false;
      this.setEventInBeefree(event);
    },
    handleSelectEventCancel() {
      this.displaySelectEventsModal = false;
      this.beefreeSelectEventCancelCallback("cancelled")
    },
    handleSelectDynamicTagsModal(resolve = null, reject = null) {
      if (this.location === 'automation') {
        this.$emit('selectDynamicTag')
      } else {
        this.showSelectDynamicTagsModal = true;
      }
      this.selectDynamicTagsModalCallback = resolve;
      this.selectDynamicTagsModalCancelCallback = reject;
    },
    handleDynamicTagsSelect(dynamicTag) {
      this.showSelectDynamicTagsModal = false;
      this.selectDynamicTagsModalCallback(dynamicTag)
    },
    handleDynamicTagsCancel() {
      this.showSelectDynamicTagsModal = false;
      this.selectDynamicTagsModalCancelCallback()
    },
    updateShowPreview() {
      this.showPreview = !this.showPreview
      this.$emit("setBeefreeShowPreview", this.showPreview)
    },
    preview() {
      if (!this.beePlugin || !this.beePlugin.instance) {
        return null;
      }
      // Beefree inside its editor already has a preview function, unlike Unlayer that we have to build an HTML to be able to preview.
      this.beePlugin.preview()
    },
    toggleStructureOutlines() {
      if (!this.beePlugin || !this.beePlugin.instance) {
        return null;
      }
      // Structure outlines are grids around the columns of Beefree
      this.beePlugin.toggleStructure()
      this.showStructure = !this.showStructure;
      this.$emit("setBeefreeShowStructure", this.showStructure)
    },
    previewDynamicTags() {
      if (!this.beePlugin || !this.beePlugin.instance) {
        return null;
      }
      this.beePlugin.toggleMergeTagsPreview()
      this.showPreviewDynamicTags = !this.showPreviewDynamicTags;
      this.$emit("setBeefreeShowPreviewDynamicTags", this.showPreviewDynamicTags)
    },
  },
};
</script>
<style lang="scss" scoped>
.email-editor-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .editor {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: $zIndexRegular;

    .beefree-editor {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }

  .loading-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: $zIndexHigh;
  }
}


</style>
