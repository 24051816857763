<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      header="Edit Email Template"
      width="100%"
      height="100%"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="email-editor-modal"
    >
      <div slot="body" class="body">
        <div
          class="top-right-tools"
        >
          <ar-link-button
            data-test-id="automation-email-select-template-link"
            text="Select Template"
            :color="$arStyle.color.$purple500"
            :textProps="{
              size: 'xs',
            }"
            :disabled="showSelectEmailTemplateSection || showSelectEventSection"
            @click="handleSelectTemplateSelector"
          />
          <ar-link-button
            v-if="templateType === 'beefree'"
            class="u-margin-left-5"
            data-test-id="automation-email-select-template-link"
            text="Import Dynamic Tags"
            :color="$arStyle.color.$purple500"
            :textProps="{
              size: 'xs',
              style: {
                pointerEvents: 'auto'
              }
            }"
            v-tooltip.bottom="{
              content: isPermissionEnabled(['export-all']) ? null : 'You don\'t have the required permissions to access this feature'
            }"
            :disabled="showSelectEmailTemplateSection || showSelectEventSection || !isPermissionEnabled(['export-all'])"
            @click="handleImportDynamicTagsSectionClick"
          />
        </div>

        <EmailEditor
          ref="email-editor"
          id="automation-email-editor"
          class="email-editor"
          :type="templateType"
          :show-dynamic-tags-tool="false"
          @templateUpdate="handleDesignOutput"
          @emailHtmlUpdate="handleHtmlOutput"
          :location="location"
          @selectEventClick="handleSelectEventSectionClick"
          @selectDynamicTag="handleSelectDynamicTagsSectionClick"
        />

        <am2-select-email-template-section
          v-show="showSelectEmailTemplateSection"
          id="automation-email-template-section"
          :template-type="templateType"
          @select="(template, templateType, emailHtml) => handleTemplateSelect(template, templateType, emailHtml)"
        />

        <am2-select-events-section
          v-show="showSelectEventSection"
          id="automation-event-section"
          :template-type="templateType"
          @select="(event) => handleEventSelect(event)"
        />

        <am2-import-dynamic-tags-section
          v-show="showImportDynamicTagsSection"
          :is-show="showImportDynamicTagsSection"
          id="automation-event-section"
          :template-type="templateType"
          @import="handleDynamicTagsImport"
        />

        <am2-select-dynamic-tags-section
          v-show="showSelectDynamicTagsSection"
          :dynamic-tags="dynamicTagsForBeefree"
          @select="handleDynamicTagsSelect"
        />


      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          text="Cancel"
          outlined
          :style="{
            width: '140px',
          }"
          data-test-id="automation-email-footer-cancel-button"
          @click="handleCancelClick"
        />
        <ar-simple-button
          v-show="!(showSelectEmailTemplateSection || showSelectEventSection || showImportDynamicTagsSection || showSelectDynamicTagsSection)"
          text="Done"
          :style="{
            width: '140px',
          }"
          data-test-id="automation-email-footer-done-button"
          @click="handleDoneClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import EmailEditor from '@/components/editors/email-editor';
export default {
  name: 'EmailEditorModal',
  components: {
    EmailEditor,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    templateType: {
      type: String,
      default: null,
      validator: (val) => ['unlayer', 'rich-text', 'custom', 'beefree'].indexOf(val) > -1,
    },
    defaultTemplate: {
      type: [Object, String],
      default: null,
    },
    location: {
      type: String,
      default: 'message-center',
      validator: (val) => ['message-center', 'automation'].indexOf(val) > -1,
    }
  },
  data() {
    return {
      template: null,
      emailHtml: null,
      showSelectEmailTemplateSection: false,
      showSelectEventSection: false,
      showImportDynamicTagsSection: false,
      showSelectDynamicTagsSection: false,
    };
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this['auth/FETCH_PERMISSIONS']()
        this.initEmailEditor();
      }
    },
  },

  computed: {
    ...mapGetters({
      dynamicTags: 'message/availableDynamicTagsInEmailMessage',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    dynamicTagsForBeefree() {
      if (!this.dynamicTags) {
        return [];
      }

      // Tag samplers show up during Preview Dynamic Tags
      return this.dynamicTags.map(function (tag) {
        let tag_sampler = tag
        if (tag === 'email_address') {
          tag_sampler = "sampler@arep.co"
        } else if (tag === "first_name") {
          tag_sampler = "Mary Sue"
        }

        return {
          name: tag,
          value: `{{${tag}}}`,
          previewValue: tag_sampler,
        }
      });
    },
  },

  methods: {
    ...mapActions([
      'auth/FETCH_PERMISSIONS',
    ]),
    ...mapMutations([
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/SET_CSV_PREVIEW_CONTACTS',
    ]),
    handleTemplateSelect(template, templateType, emailHtml) {
      this.$refs['email-editor'].setDesign(template)
      this.$emit('select', template, templateType, emailHtml)
      this.showSelectEmailTemplateSection = false
    },
    async initEmailEditor() {
      await this.$nextTick();
      if (this.defaultTemplate) {
        this.$refs['email-editor'].setDesign(this.defaultTemplate);
      }
    },
    handleSelectTemplateSelector() {
      this.showSelectEmailTemplateSection = true
    },
    handleImportDynamicTagsSectionClick() {
      this.showImportDynamicTagsSection = true
    },
    handleSelectDynamicTagsSectionClick() {
      this.showSelectDynamicTagsSection = true;
    },
    handleDesignOutput(template) {
      this.template = template;
    },
    handleHtmlOutput(emailHtml) {
      this.emailHtml = emailHtml;
    },
    handleCancelClick() {
      if (this.showSelectEmailTemplateSection) {
        this.showSelectEmailTemplateSection = false
        return
      }
      if (this.showSelectEventSection) {
        this.showSelectEventSection = false
        this.$refs["email-editor"].handleSelectEventCancel()
        return
      }
      if (this.showImportDynamicTagsSection) {
        this.showImportDynamicTagsSection = false
        return
      }
      if (this.showSelectDynamicTagsSection) {
        this.showSelectDynamicTagsSection = false
        this.$refs["email-editor"].handleDynamicTagsCancel()
        return
      }
      this.$emit('cancel');
    },
    handleDoneClick() {
      this.$emit('update', this.template, this.emailHtml);
    },
    handleModalClose() {
      this.handleCancelClick()
    },
    handleSelectEventSectionClick() {
      this.showSelectEventSection = true;
    },
    handleSelectEventSectionClose() {
      this.showSelectEventSection = false;
    },
    handleEventSelect(event) {
      this.$refs['email-editor'].setEventInBeefree(event)
      this.showSelectEventSection = false
    },
    handleDynamicTagsSelect(dynamicTags) {
      this.$refs['email-editor'].handleDynamicTagsSelect(dynamicTags);
      this.showSelectDynamicTagsSection = false
    },
    async handleDynamicTagsImport({ dynamicTagHeaders, dynamicTagRows, tagsResourceOid, tagMaxWidthMap }) {
      this['message/SET_CSV_PREVIEW_CONTACTS']({
        headers: dynamicTagHeaders,
        rows: dynamicTagRows,
      });
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          dynamicTagHeaders,
          tagsResourceOid,
          tagMaxWidthMap,
        },
      });
      await this.$nextTick();
      this.showImportDynamicTagsSection = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-right-tools {
  padding: 12px;
  position: fixed;
  top: 18px;
  right: 82px;
  z-index: 999;
  cursor: pointer;
}

.email-editor-modal {
  .body {
    width: 100%;
    height: 100%;
    .email-editor {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 23px 29px;
  }
}
</style>
