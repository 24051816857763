<template>
  <div class="line-annotation-module"
       :style="{ left: `${tooltipLeftX}px` }"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
       v-click-outside="handleClickOutside"
  >
    <div
      v-if="activeAnnotation && isTooltipShowing"
      class="line-annotation"
    >
      <!-- CUSTOM ANNOTATION -->
      <template v-if="activeAnnotation.provider === 'custom'">
        <div v-if="isLineAnnotationEditing">
          <ar-textarea
            class="line-annotation__textarea line-annotation__textarea--title"
            :placeholder="'Custom title'"
            :rows="2"
            v-model="titleValue"
          />
          <ar-textarea
            class="line-annotation__textarea"
            :placeholder="'Add a note'"
            :rows="3"
            v-model="bodyValue"
          />
          <ar-simple-button
            type="purple"
            text="Save"
            class="line-annotation__save-button"
            @click="handleSaveEdit"
          />
          <div class="line-annotation__delete-button-wrapper">
            <ar-link-button
              class="line-annotation__delete-button"
              text="Delete"
              :has-underline="true"
              :color="$arStyle.color.red500"
              @click="handeDeleteAnnotation"
            />
          </div>
        </div>
        <div v-else>
          <div class="line-annotation__custom-header">
            <ar-text
              size="14px"
              weight="bold"
              multipleLines
              :maxLines="3"
              :text="activeAnnotation.title ? activeAnnotation.title : 'Custom annotation'"
              v-tooltip.top="{
                content: activeAnnotation.title,
              }"
            />
            <div class="line-annotation__custom-edit-btn" @click="handleEdit">
              <ar-text
                size="xxs"
                text="Edit"
                :style="{
              color: $arStyle.color.purple500,
              textDecoration: 'underline',
              cursor: 'pointer',
              userSelect: 'none',
              marginLeft: 'auto',
            }"
              />
            </div>
          </div>
          <ar-text
            class="line-annotation__desc"
            size="12px"
            multipleLines
            :maxLines="3"
            :text="activeAnnotation.body ? activeAnnotation.body : 'Add a note'"
          />
        </div>
      </template>

      <!-- CAMPAIGN -->
      <template v-if="activeAnnotation.provider === 'campaign'">
        <div class="line-annotation__header">
          <div class="line-annotation__icon">
            <ar-icon
              name="campaign-circles"
              size="24px"
              :color="$arStyle.color.purple500"
            />
          </div>
          <div class="line-annotation__link"
               @click="handleViewCampaign"
          >
            <ar-text
            text="View campaign"
            size="xs"
            :style="{
              color: $arStyle.color.purple500,
              textDecoration: 'underline',
              cursor: 'pointer',
              userSelect: 'none',
            }"
            />
          </div>
        </div>
        <div class="line-annotation__content">
          <ar-text
            class="line-annotation__title"
            :text="activeAnnotation.body"
            size="14px"
            weight="bold"
            multipleLines
            :maxLines="3"
            v-tooltip.top="{
              content: activeAnnotation.body,
            }"
          />
          <ar-text
            class="line-annotation__desc"
            :text="handleGetFormattedDate()"
            size="12px"
            multipleLines
            :maxLines="3"
          />
        </div>
      </template>

      <!-- EMAIL OR SMS -->
      <template v-if="activeAnnotation.provider === 'email' || activeAnnotation.provider === 'sms'">
        <div class="line-annotation__header">
          <div class="line-annotation__icon">
            <ar-icon
              :name="activeAnnotation.provider === 'email' ? 'email' : 'sms'"
              size="24px"
              :color="$arStyle.color.purple500"
            />
          </div>
          <div class="line-annotation__link"
               @click="handleViewMessage"
          >
            <ar-text
              text="View message"
              size="xs"
              :style="{
              color: $arStyle.color.purple500,
              textDecoration: 'underline',
              cursor: 'pointer',
              userSelect: 'none',
            }"
            />
          </div>
        </div>
        <div class="line-annotation__content">
          <ar-text
            class="line-annotation__title"
            :text="activeAnnotation.body"
            size="14px"
            weight="bold"
            multipleLines
            :maxLines="3"
            v-tooltip="{
              content: activeAnnotation.body,
            }"
          />
          <ar-text
            class="line-annotation__desc"
            :text="handleGetFormattedDate()"
            size="12px"
            multipleLines
            :maxLines="3"
          />
        </div>
        <template v-if="isConversionEnabled">
          <ar-divider class="line-annotation__divider" />
          <div class="line-annotation__footer">
            <ar-text
              class="line-annotation__footer-text"
              :text="getFormattedRevenue()"
              size="xs"
            />
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'
import {formatNumberWithCommas} from '@/utils/helpers';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export default {
  name: 'LineAnnotationModule',
  props: {
    chartConfig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tooltipLeftX: 0,
      bodyValue: '',
      titleValue: '',
    };
  },
  watch: {
    activeAnnotation: {
      handler: function (val) {
        if (val) {
          this.setInitTooltip()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      event: state => state.event.currentEvent,
      activeAnnotation: state => state.event.activeLineAnnotation,
      isTooltipShowing: state => state.event.isTooltipShowing,
      isLineAnnotationEditing: state => state.event.isLineAnnotationEditing,
    }),
    ...mapGetters({
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
      selectedTimezone: 'event/getSalesChartTimezone',
      isFeatureEnabled: 'auth/isFeatureEnabled'
    }),
    isConversionEnabled() {
      return this.isFeatureEnabled(['conversions'])
    }
  },
  methods: {
    ...mapActions({
      deleteAnnotation: 'event/DELETE_ANNOTATION',
      updateAnnotation: 'event/UPDATE_ANNOTATION',
    }),
    ...mapMutations([
      'event/DELETE_LINE_ANNOTATION',
      'event/SET_ACTIVE_LINE_ANNOTATION',
      'event/CHANGE_CUSTOM_LINE_ANNOTATION',
      'event/SET_IS_TOOLTIP_SHOWING',
      'event/SET_IS_LINE_ANNOTATION_EDITING',
    ]),
    setInitTooltip() {
      this['event/SET_IS_LINE_ANNOTATION_EDITING'](false)
      this.bodyValue = this.activeAnnotation.body || ''
      this.titleValue = this.activeAnnotation.title || ''
      const { startDate, clientX } = this.activeAnnotation
      const xInMilliseconds = this.getDateInMillisecondWithTimezone(startDate)
      const x = this.calculateLeftInPx(xInMilliseconds, clientX)
      this.tooltipLeftX = x
    },

    calculateLeftInPx(xInMilliseconds, clientX) {
      let gridWidth = this.chartConfig.gridWidth
      let yAxisOffset = this.chartConfig.translateX

      const xProportionPx = (xInMilliseconds - this.chartConfig.minX) / (this.chartConfig.maxX - this.chartConfig.minX) * gridWidth
      const halfOfTooltipWidth = 118
      const x = this.chartConfig.translateX + xProportionPx - halfOfTooltipWidth
      return x
    },

    handleViewCampaign() {
      this.$router.push(`/campaigns/${this.activeAnnotation.oid}/view/dashboard`)
    },

    handleViewMessage() {
      this.$router.push(`/message-center/messages/${this.activeAnnotation.oid}/view/overview`)
    },

    handleEdit() {
      this['event/SET_IS_LINE_ANNOTATION_EDITING'](true)
    },

    handleSaveEdit() {
      this.updateAnnotation({
        oid: this.activeAnnotation.oid,
        body: this.bodyValue,
        title: this.titleValue,
      })

      this['event/SET_ACTIVE_LINE_ANNOTATION']({
        ...this.activeAnnotation,
        body: this.bodyValue,
        title: this.titleValue,
      })
      this['event/CHANGE_CUSTOM_LINE_ANNOTATION']({ oid: this.activeAnnotation.oid, body: this.bodyValue, title: this.titleValue })
      this['event/SET_IS_LINE_ANNOTATION_EDITING'](false)
    },

    handleGetFormattedDate() {
      const dateObj = dayjs.utc(this.activeAnnotation.startDate).tz(this.selectedTimezone)
      return `Sent ${dateObj.format('h:mma')} ${this.selectedTimezone}・${dateObj.format('MMM Do')}`
    },

    getDateInMillisecondWithTimezone(dateUtcString) {
      let dateObj = dayjs.utc(dateUtcString).tz(this.selectedTimezone);
      let offsetInMilliseconds = dateObj.utcOffset() * 60 * 1000;
      return dateObj.valueOf() + offsetInMilliseconds;
    },

    formatNumber(amount) {
      return formatNumberWithCommas(amount / 100);
    },

    getFormattedRevenue() {
      return 'Attributed revenue・' + this.regionSettingsCurrencySymbol + this.formatNumber(this.activeAnnotation.revenue || 0)
    },

    handeDeleteAnnotation() {
      this.deleteAnnotation(this.activeAnnotation.oid)
      this['event/DELETE_LINE_ANNOTATION'](this.activeAnnotation.oid)
      this['event/SET_ACTIVE_LINE_ANNOTATION'](null)
      this['event/SET_IS_LINE_ANNOTATION_EDITING'](false)
    },
    handleMouseEnter() {
      this['event/SET_IS_TOOLTIP_SHOWING'](true)
    },
    handleMouseLeave() {
      if (!this.isLineAnnotationEditing) this['event/SET_IS_TOOLTIP_SHOWING'](false)
    },
    handleClickOutside() {
      this['event/SET_IS_TOOLTIP_SHOWING'](false)
      this['event/SET_ACTIVE_LINE_ANNOTATION'](null)
      this['event/SET_IS_LINE_ANNOTATION_EDITING'](false)
    },
  },
};
</script>

<style lang="scss" scoped>
.line-annotation-module {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 20;
}

.line-annotation {
  position: relative;
  width: 236px;
  z-index: 100;
  border-radius: 4px;
  border: 1px solid var(--Borders-Sky-Blue-Grey-400, #EDF1F5);
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 12px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    margin-bottom: 12px;
  }

  &__custom-header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  &__custom-edit-btn {
    margin-left: auto;
  }

  &__save-button {
    height: 40px;
    width: 100%;
  }

  &__delete-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 16px;
  }

  &__delete-button {
    margin-left: auto;
    margin-right: auto;
  }

  &__textarea {
    margin-bottom: 8px;
    ::v-deep {
      textarea {
        padding: 5px 10px;
      }
    }
  }

  &__textarea--title {
    ::v-deep {
      textarea {
        font-weight: bold;
      }
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    border: 2px solid #EBEBEB;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__divider {
    margin: 12px 0;
  }

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: $skyBlueGrey800;
    margin-bottom: 4px;
  }

  &__desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: $skyBlueGrey700;
  }
}
</style>
