var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"send-test-email-modal",attrs:{"is-open":_vm.opened,"header":"Send a test email","width":"707px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleModalClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('ar-field',{attrs:{"label":"Send a test email to","error-messages":[_vm.veeErrors.first('email-addresses')]}},[_c('div',{staticClass:"u-display-flex"},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
              emailAddresses: true,
              maxTestEmailRecipients: { max: 9 },
            }),expression:"{\n              required: true,\n              emailAddresses: true,\n              maxTestEmailRecipients: { max: 9 },\n            }"}],staticClass:"u-flex-grow-1",attrs:{"placeholder":"bob@acme.com, jane@acme.com","data-vv-name":"email-addresses","data-vv-as":"email addresses","autocomplete":"email"},on:{"enter":_vm.handleSendTestEmailClick},model:{value:(_vm.emailAddresses),callback:function ($$v) {_vm.emailAddresses=$$v},expression:"emailAddresses"}}),_vm._v(" "),_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.messageCannotBeTested && 'Please go back and add a sender and subject line before testing'),expression:"messageCannotBeTested && 'Please go back and add a sender and subject line before testing'",modifiers:{"top":true}}],staticClass:"u-margin-left-3",style:({
              width: '148px',
            }),attrs:{"text":"Send test email","outlined":"","loading":_vm.isUpdatingEmail || _vm.isSendingTestEmail,"disabled":_vm.messageCannotBeTested},on:{"click":_vm.handleSendTestEmailClick}})],1),_vm._v(" "),(_vm.emailSendToCopy)?_c('ar-state-message',{staticClass:"u-margin-top-3",style:({
            wordBreak: 'break-word',
          }),attrs:{"type":"success","has-icon":"","disableTextColor":"","text":_vm.emailSendToCopy}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-5",style:({
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xs","text":"Use commas to separate multiple emails"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({
          width: '140px',
        }),attrs:{"text":"Done"},on:{"click":_vm.handleDoneClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }