<template>
  <section
    v-if="!!item.config"
  >
    <InfoBranch
      v-for="(event, index) in eventData"
      :key="`purchased-event-${index}`"
      :content="event.name"
      :description="event.location"
      :style="{
        marginTop: '2px',
      }"
    />

  </section>
</template>
<script>
import InfoBranch from '../utils/InfoBranch'
export default {
  name: 'PurchasedTicket',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  computed: {
    eventData() {
      if (!this.item?.config) {
        return null
      }
      
      if (this.item['config']?.['event-oids'] || this.item['config']?.['event-oid']) {
        return this.item.meta?.events || null
      } else {
        return [{ name: 'Any event'}]
      }

    },
  }
}
</script>