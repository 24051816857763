<template>
  <am2-card-container layout="regular">
    <div class="overview-delivery-summary-container">
      <div class="overview-delivery-summary-header">
        <ar-text
          text="Delivery summary"
          size="sm"
        />
        <div class="report-link" @click="handleReportClick">
          <ar-text
            text="View report"
            size="xs"
            :style="{
              color: $arStyle.color.purple500,
            }"
          />
        </div>
      </div>
      <div class="overview-delivery-summary-body">
        <RecipientsOverviewRate 
          :total="overviewStats.total"
          :value="overviewStats.sent"
          :multiple="false"
          title="Delivered"
        />
        <div class="single-rate-container">
          <SinglePercentRate
            title="Failed"
            :total="overviewStats.failed"
            :value="overviewStats.total === 0 ? 0 : Math.trunc((overviewStats.failed / overviewStats.total) * 100) || 0"
            link
            @click="goToRecipients('failed')"
            data-test-id="delivery-summary-stats-failed-rate"
          />
          <div class="stats-separator"></div>
          <SinglePercentRate
            v-if="!isSms"
            title="Bounced"
            :total="overviewStats.bounced"
            :value="overviewStats.total === 0 ? 0 : Math.trunc((overviewStats.bounced / overviewStats.total) * 100) || 0"
            link
            @click="goToRecipients('bounced')"
            data-test-id="delivery-summary-stats-bounced-rate"
          />
          <div v-if="!isSms" class="stats-separator"></div>
          <SinglePercentRate
            title="Unsubscribed"
            :total="overviewStats.unsubscribed"
            :value="overviewStats.total === 0 ? 0 : Math.trunc((overviewStats.unsubscribed / overviewStats.total) * 100) || 0"
            link
            @click="goToRecipients('unsubscribed')"
            data-test-id="delivery-summary-stats-unsubscribed-rate"
          />
        </div>
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import RecipientsOverviewRate from './recipients-overview-rate.vue'
import SinglePercentRate from './single-percent-rate.vue'

export default {
  name: 'DeliverySummaryStats',
  components: {RecipientsOverviewRate, SinglePercentRate},

  props: {
    overviewStats: {
      type: Object,
      default: () => {},
    },
    oid: {
      type: Number,
      default: null,
    },
    isSms: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    handleReportClick() {
      this.$router.push({
        path: 'deliverability'
      });
    },

    goToRecipients(viewBy) {
      const url = `/message-center/messages/${this.$route.params.oid}/view/recipients`;

      this.$router.push({ path: url, query: { viewBy } });
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-delivery-summary {
  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 30px;
  }

  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.2em;
  }

  .overview-delivery-summary-body {
    padding-top: 30px;
  }

  .single-rate-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    padding-top: 34px;
    border-top: 1px solid $blueGrey400;
  }

  .report-link {
    color: $purple500;
    text-decoration: underline;
    cursor: pointer;
  }

  .stats-separator {
    width: 1px;
    background-color: $blueGrey400;
  }
}
</style>