<template>
  <EditableFrame
    class="remove-tag-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
    @click.native="handleClickOutside"
  >
    <ar-text
      class="tag-list-heading"
      size="13px"
      text="TAGS"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <div class="remove-tag-container">
      <div class="remove-tag-dropdown-wrapper" @click="toggleOptions" data-test-id="remove-tag-toggle">
        <ar-text
          class="remove-tag-copy"
          size="14px"
          :text="messageListText"
          multiple-lines
          :max-lines="1"
          line-height="20px"
        />
        <ArrowHead 
          class="remove-tag-dropdown-arrowhead"
          :class="listOpen && 'rotate'"
          height="8px" 
          width="10px" 
          fill="#8492a6"          
        />
      </div>
      <div v-if="listOpen" class="remove-tag-search-wrapper">
        <am2-search
          class="remove-tag-search"
          v-model="listSearchText"
          placeholder="Search tags..."
          data-test-id="remove-tag-search"
        />
        <div class="tags-list-wrapper">
          <div class="no-match-wrapper" v-if="!!listSearchText && !tags.length">
            <ar-text              
              class="tag-name"
              size="xs"
              text="No matching tag found"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              weight="normal"
            />
          </div>
            <div v-for="tag in tags" :key="tag.oid" class="tag-link-wrapper">
            <a
              class="tag-link"
              @click="() => setSelectedTag(tag)"
              :data-test-id="`remove-tag-anchor-${tag.oid}`"
            >
              <div class="item-wrapper">
                <ar-text
                  :class="[
                    'tag-name',
                    selectedTag && tag.oid === selectedTag.oid && 'selected'
                  ]"
                  size="xs"
                  :text="tag.name"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  :weight="selectedTag && tag.oid === selectedTag.oid ? 'bold' : 'normal'"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TagRemove',
  components: {
    EditableFrame,
    ArrowHead
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      listOpen: false,
      listSearchText: '',
      selectedTag: null,
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    listSearchText() {
      this.searchForTagsOnBackend(this.listSearchText)
    }
  },
  computed: {
    ...mapGetters({
      tags: 'tag/tagsList',
    }),
    messageListText() {
      if (!!this.selectedTag?.name) return this.selectedTag?.name
      return 'Select a tag...'
    },
    savedButtonDisabled() {
      return !this.selectedTag
    }
  },
  methods: {
    ...mapActions({
      fetchTagsList: 'automation/FETCH_TAGS',
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG'
    }),
    searchForTagsOnBackend(text) {
      this.fetchTagsList({filterString: text})
    },
    handleClickOutside(e) {
      if (!this.listOpen) return
      
      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'remove-tag-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.listOpen = false
        this.listSearchText = ''
      }
    },
    setSelectedTag(tag) {
      this.selectedTag = { oid: tag.oid, name: tag.name }
      this.listOpen = false
      this.listSearchText = ''
    },

    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    handleSave() {
      let contextData = {
        config: {
          'tag-oid': this.selectedTag.oid
        },
        meta: {
          name: this.selectedTag.name,
        }
      }

      this.updateActionConfig({id: this.item.id, data: contextData})
      this.$emit('close')
    },
    resetDefaults() {
      this.selectedTag = null
      this.listOpen = false
      this.listSearchText = ''
    },
    async setItemVals() {
      this.resetDefaults()
      let tagsSearchList = !!this.tags.length ? this.tags : await this.fetchTagsList({})

      if (!this.item.config?.['tag-oid']) return

      let tag = tagsSearchList.find(tag => tag.oid === this.item.config['tag-oid'])

      this.selectedTag = tag
    }
  },
  mounted() {
    this.setItemVals()
  }
}
</script>
<style lang="scss" scoped>
.remove-tag-wrapper {
  width: 100%;
  height: 100%;
  
  .tag-list-heading {
    margin-bottom: 14px;
    pointer-events: none;
  }

  .remove-tag-container {
    
    .remove-tag-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(14) ui-px2em(18) ui-px2em(16) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      margin-bottom: 10px;
      cursor: pointer;

      .remove-tag-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .remove-tag-search-wrapper {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      overflow: scroll;
      z-index: 10;
      margin-bottom: 10px;

      .remove-tag-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .tags-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: auto;
        padding-bottom: 10px;

        .tag-link-wrapper {
          width: 100%;
        }

        .no-match-wrapper {
          width: 100%;
          padding: 12px 16px;
        }

        .tag-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;
          
            .tag-name {
              &.selected {
                color: $purple500;
              }
            }
          }

        }
      }
    }
  }
}
</style>