<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="ticketbooth-modal"
        header="Connect to Ticketbooth"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'ticketbooth-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >

          <ar-field
            label="Account Name"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="accountName"
              name="accountName"
              placeholder="Please enter your Ticketbooth Account Name"
              id="ticketbooth-account-name-input"
              type="text"
              data-test-id="ticketbooth-account-name-input"
            />
          </ar-field>

        </div>
        <div
          slot="body"
          :class="[
            'ticketbooth-modal-body-2',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >

          <ar-field
            label="API Token"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="apiToken"
              name="apiToken"
              placeholder="Please enter your Ticketbooth API Token"
              id="ticketbooth-api-token-input"
              type="password"
              data-test-id="ticketbooth-api-token-input"
            />
          </ar-field>

        </div>
        <div
          slot="body"
          :class="[
            'ticketbooth-modal-body-3',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
          <ar-text
              class="u-margin-top-2"
            size="xs"
            text="Or instead"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />

          <ar-field
            label="Email"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="email"
              name="email"
              placeholder="Please enter your Ticketbooth email"
              id="ticketbooth-email-input"
              type="text"
              data-test-id="ticketbooth-email-input"
            />
          </ar-field>

          <ar-field
            label="Password"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="password"
              name="password"
              placeholder="Please enter your Ticketbooth password"
              id="ticketbooth-password-input"
              type="password"
              data-test-id="ticketbooth-password-input"
            />
          </ar-field>

        </div>
        <div
          slot="footer"
          :class="[
            'ticketbooth-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="ticketbooth-confirm-button"
            data-test-id="ticketbooth-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>

  <script>
  import { mapState } from 'vuex';

  export default {
    name: 'AskInputModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        accountName: null,
        apiToken: null,
        email: null,
        password: null
      };
    },

    computed: {
      confirmButtonDisabled() {
        return !this.accountName || (!this.apiToken && !(this.email && this.password));
      },
      ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    },

    watch: {
      isShow(val) {
        if (val) {
          this.accountName = null;
          this.apiToken = null;
          this.email = null;
          this.password = null;
        }
      },
    },

    methods: {
      handleConfirmClick() {
        let params = {
            accountName: this.accountName,
            apiToken: this.apiToken,
            email: this.email,
            password: this.password
        }

        this.$emit('confirm', params);
      },
      handleCloseClick() {
        this.$emit('cancel');
      },
      toggleApplyPrefix() {
        this.applyPrefix = !this.applyPrefix;
      }
    },
  };
  </script>

  <style lang="scss" scoped>
  .ticketbooth-modal {
    .ticketbooth-modal-body {
      padding: 20px 30px 0px;
    }
    .ticketbooth-modal-body-2 {
      padding: 0px 30px 0px;
    }
    .ticketbooth-modal-body-3 {
      padding: 20px 30px 30px;
    }
    .ticketbooth-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>
