var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('am2-ticketspice-modal',{attrs:{"is-show":_vm.showTicketspiceModal,"loading":_vm.isCreatingTicketspiceIntegration},on:{"confirm":_vm.handleTicketspiceConfirm,"cancel":function($event){_vm.showTicketspiceModal = false}}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Ticketspice","description":"Automatically sync your members, events, orders from Ticketspice","icon-props":{
          name: 'ticketspice',
          color: _vm.$arStyle.color.ticketspice,
          height: '46px',
          width: '40px',
        }}})],1),_vm._v(" "),(!_vm.isFetchingTicketspiceIntegration && _vm.integrationsSummary)?_c('div',{staticClass:"title-section-right"},[(!_vm.isFetchingTicketspiceIntegration && _vm.ticketspiceIntegration && _vm.currentIntegration)?_c('am2-integration-account-select',{style:({ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }),attrs:{"default-selected-integration-oid":_vm.currentIntegration.oid,"available-integrations":_vm.integrationsSummary},on:{"select":_vm.handleIntegrationChange,"new-account":_vm.handleAddNewIntegration}}):_vm._e(),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Reconnect Integration',
            value: 'reconnectIntegration',
          },
          {
            name: 'Remove Account',
            value: 'removeIntegration',
          } ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),_c('am2-tabs',{staticClass:"tab-options",attrs:{"items":[
      { title: 'Notifications' },
      { title: 'Status' }
    ],"selected-tab-index":_vm.selectedTabIndex},on:{"select":function (selected, idx) { return _vm.selectedTabIndex = idx; }}}),_vm._v(" "),(_vm.selectedTabIndex === 0)?_c('div',[_c('NotificationsContainer',{attrs:{"integration":_vm.currentIntegration,"loading":_vm.isFetchingTicketspiceIntegration}})],1):_vm._e(),_vm._v(" "),(_vm.selectedTabIndex === 1)?_c('div',{staticClass:"integration-status-container"},[(_vm.isAddingTicketspiceIntegration || _vm.isFetchingTicketspiceIntegration)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Ticketspice connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"is-fetching-sync-task":_vm.isFetchingSyncTask,"sync-status":_vm.syncStatus,"sync-time":_vm.syncTime,"sync-copies":{
        'in-progress': 'Syncing data from Ticketspice',
        'pending': 'The data sync is still pending',
        'completed': 'Your Ticketspice account is currently connected',
        'cancelled': 'Your Ticketspice account connection is cancelled',
        'failed': this.syncErrorMessage,
        'default': 'Syncing tasks not found',
      }},on:{"startSync":_vm.handleStartSync,"stopSync":_vm.handleStopSync}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }