<template>
  <div :class="[{ 'registration-top' : registration }, {'md-max': $arMediaQuery.pageContent.maxWidth('sm')}, 'top']">
    <div :class="[{ 'thick-bar' : dropdown }, {'md-min': $arMediaQuery.pageContent.minWidth('md')}, {'lg-min': $arMediaQuery.pageContent.minWidth('lg')}, 'top__heading']">
      <div class="header">
        <am2-heading
          type="h1"
          size="lg"
          :title="title"
        />
        <ar-text
          v-if="!!fixedAmout"
          class="title-highlight"
          size="lg"
          :text="fixedAmout"
        />
        <am2-search
          v-if="searchLeft"
          class="search-left"
          v-model="searchString"
          :placeholder="searchLeft.placeholder"
          @enter="handleSearch"
          @clear="handleSearch"
          :style="searchLeft.style || null"
          data-test-id="top-search-input"
        />
      </div>
      <am2-link-button-dropdown
        v-if="dropdown"
        :items="dropdown.items"
        :item-key="dropdown.activeItemKey"
        :style="{
          marginTop: '8px',
        }"
        :dropdown-style="dropdownStyle"
        @select="handleDropdownItemSelect"
        data-test-id="top-link-button-dropdown"
      />
    </div>

    <div :class="[{'md-max': $arMediaQuery.pageContent.maxWidth('sm')}, 'top__controls']">
      <am2-search
        v-if="search"
        class="search"
        v-model="searchString"
        :placeholder="search.placeholder"
        @enter="handleSearch"
        @clear="handleSearch"
        :style="search.style || null"
        data-test-id="top-search-input"
      />

      <slot name="button">
        <am2-split-dropdown
          v-if="button && button.type === 'split'"
          :items="button.options"
          class="bottom-left"
          :class="toClassObject(button.classes)"
          :text="button.text"
          :disabled="button.disabled"
          :icon-name="button.iconName ? button.iconName : null"
          :icon-props="button.iconName ? button.iconProps : null"
          :icon-distance="button.iconDistance || null"
          :toggle-dropdown-on-button-click="button.toggleDropdownOnButtonClick"
          :classNames="button.dropdownClassNames || []"
          @select="runOptionalAction"
          @buttonClick="button.action"
          data-test-id="top-split-dropdown"
        />
        <ar-simple-button
          v-else-if="button"
          v-bind="button"
          @click="button.action"
          data-test-id="top-simple-button"
        />
      </slot>

      <am2-expand-icon-button
        v-if="expandIconButton"
        class="expand-button"
        v-bind="expandIconButton"
        @click="expandIconButton.action"
        data-test-id="top-expand-icon-button"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Top',

  props: {
    title: {
      type: String,
      default: '',
    },
    amount: {
      type: [String, Number],
      default: '',
    },
    dropdown: {
      type: Object,
      required: false,
      default: () => {},
    },
    search: {
      type: Object,
      default: null,
    },
    searchLeft: {
      type: Object,
      default: null,
    },
    button: {
      type: Object,
      required: false,
      default: () => {},
    },
    expandIconButton: {
      type: Object,
      required: false,
      default: () => {},
    },
    registration: {
      type: Boolean,
      default: false,
    },
    dropdownStyle: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    searchString: '',
  }),

  computed: {
    fixedAmout() {
      if (typeof this.amount === 'number') {
        return parseInt(this.amount, 10).toLocaleString();
      }
      return this.amount;
    },
    dropdownButtonProps() {
      const activeItem = this.dropdown.items.find(i => i.key === this.dropdown.activeItemKey);
      return {
        text: activeItem ? activeItem.name : this.dropdown.title
      }
    },
  },

  methods: {
    handleDropdownItemSelect(item) {
      item.action();
    },
    toClassObject(classes) {
      const classObj = {};
      if (classes) {
        classes.forEach(c => classObj[c] = true);
      }
      return classObj;
    },
    runOptionalAction(item, index) {
      if (item.action) {
        item.action(item, index);
      }
    },
    handleSearch() {
      if (!!this.search) {
        this.search.action(this.searchString);
      } else if (!!this.searchLeft) {
        this.searchLeft.action(this.searchString);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;

  &.md-max {
    flex-direction: column;
    align-items: flex-start;

    .top__heading {
      padding-bottom: 16px;
    }
  }

  &__controls {
    display: flex;

    .search {
      margin-right: 16px;
      width: 300px;
    }

    .search-left {
      width: 300px;
    }

    .expand-button {
      margin-left: 7px;
    }

    &.md-max {
      width: 100%;

      .search {
        width: 100%;
        margin-right: 8px;
      }

    }


  }


}
.registration-top {
  margin: 33px 0 27px 0;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .title-highlight {
    color: $purple500;
    margin-left: 10px;
  }
}
</style>
