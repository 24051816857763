<template>
  <div
  data-test-id="company-page-messages"
  >
    <div
      v-if="isFetchingPromoterMessageTasks"
      class="loading-container"
    >
      <am2-loading-spinner size="48px" />
    </div>
    <div
      v-else-if="noMessages"
      :style="{
        marginTop: '16px',
      }"
    >
      <am2-no-content-section
        icon-name="ar-message-in-circle"
        header="No messages"
        body="No upcoming/active/recently-failed messages have been sent by this promoter"
      />
    </div>
    <div v-else>
      <!-- In-progress -->
      <div v-if="inProgress.length > 0" class="table-wrapper">
        <div class="table-header">
          <ar-text
            size="xs"
            text="Sending"
            weight="bold"
          />
        </div>
        <am2-messages-table
          :head="getTableHeadings('inProgress')"
          :messages="inProgress"
          :loading="isFetchingPromoterMessageTasks && inProgress.length === 0"
          :style="{
            marginTop: '16px',
          }"
        />
      </div>
  
      <!-- Scheduled -->
      <div v-if="scheduled.length > 0" class="table-wrapper">
        <div class="table-header">
          <ar-text
            size="xs"
            text="Scheduled"
            weight="bold"
          />
          <ar-text
            size="xs"
            :text="`(${scheduledCount} message${scheduledCount > 1 ? 's' : ''})`"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </div>
        <am2-messages-table
          :head="getTableHeadings('scheduled')"
          :messages="scheduled"
          :loading="isFetchingPromoterMessageTasks && scheduled.length === 0"
          :style="{
            marginTop: '16px',
          }"
        />
      </div>

      <!-- Sent -->
      <div v-if="sent.length > 0" class="table-wrapper">
        <div class="table-header">
          <ar-text
            size="xs"
            text="Recently sent"
            weight="bold"
          />
          <ar-text
            size="xs"
            :text="`(${sentCount} message${sentCount > 1 ? 's' : ''})`"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </div>
        <am2-messages-table
          :head="getTableHeadings('sent')"
          :messages="sent"
          :loading="isFetchingPromoterMessageTasks && sent.length === 0"
          :style="{
            marginTop: '16px',
          }"
        />
      </div>
  
      <!-- Failed -->
      <div v-if="failed.length > 0" class="table-wrapper">
        <div class="table-header">
          <ar-text
            size="xs"
            text="Recently failed"
            weight="bold"
          />
          <ar-text
            :text="`(${failedCount} message${failedCount > 1 ? 's' : ''})`"
            size="xs"
            class="header-link"
            @click.native="setMessageFilter('completed')"
          />
        </div>
        <am2-messages-table
          :head="getTableHeadings('failed')"
          :messages="failed"
          :loading="isFetchingPromoterMessageTasks && failed.length === 0"
          :style="{
            marginTop: '16px',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'CompanyMessages',

  data() {
    return {
      searchString: '',
    }
  },

  computed: {
    ...mapState({
      currentPromoter: state => state.admin.currentPromoter,

      promoterMessageTasks: state => state.admin.promoterMessageTasks,
      isFetchingPromoterMessageTasks: state => state.admin.isFetchingPromoterMessageTasks,
      hasFailedFetchingPromoterMessageTasks: state => state.admin.hasFailedFetchingPromoterMessageTasks,
    }),

    inProgress() {
      return this.promoterMessageTasks['in-progress']?.rows || [];
    },

    inProgressCount() {
      return this.promoterMessageTasks['in-progress']?.count || 0;
    },

    scheduled() {
      return this.promoterMessageTasks['scheduled']?.rows || [];
    },

    scheduledCount() {
      return this.promoterMessageTasks['scheduled']?.count || 0;
    },

    sent() {
      return this.promoterMessageTasks['completed']?.rows || [];
    },

    sentCount() {
      return this.promoterMessageTasks['completed']?.count || 0;
    },

    failed() {
      return this.promoterMessageTasks['failed']?.rows || [];
    },

    failedCount() {
      return this.promoterMessageTasks['failed']?.count || 0;
    },

    noMessages() {
      return !this.isFetchingPromoterMessageTasks
        && (this.hasFailedFetchingPromoterMessageTasks ||
        ( this.inProgress.length === 0 
          && this.sent.length === 0
          && this.scheduled.length === 0
          && this.failed.length === 0 )
        );
    },
  },

  created() {
    this.reloadData()
  },

  methods: {
    ...mapActions([
      'admin/FETCH_PROMOTER_MESSAGE_TASKS_OVERVIEW',
    ]),

    async reloadData() {
      this['admin/FETCH_PROMOTER_MESSAGE_TASKS_OVERVIEW']({
        promoterOid: this.$route.params.oid,
        search: this.searchString,
      });
    },

    getTableHeadings(tableType) {
      const commonHeadings = [
        {
          name: 'Oid',
          key: 'oid',
          format: 'oid',
        },
        {
          name: 'Message',
          key: 'message',
          format: 'message',
        },
      ];
      const tableHeadings = {
        inProgress: [
          ...commonHeadings,
          {
            name: '',
            key: 'status',
            format: 'status',
          },
        ],
        scheduled: [
          ...commonHeadings,
        ],
        sent: [
          ...commonHeadings,
        ],
        failed: [
          ...commonHeadings,
        ],
      };

      return tableHeadings[tableType] || [];
    },
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.table-wrapper {
  margin-top: 28px;

  &:first-child {
    margin-top: 0px;
  }
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}
</style>