<template>
  <div>

    <div class="hero-wrapper">
      <div class="hero-content">
        <am2-step-link
          class="back-link"
          text="Back to admin"
          @click="handleStepLinkClick"
        />
        <am2-heading
          class="heading"
          type="h1"
          size="lg"
          :title="computedTitle"
        />
      </div>
    </div>

    <am2-navbar
      :menu="menu"
      v-ar-sticky-top="{
        priority: 1,
      }"
      width="1296px"
      lateral-padding
      reduced
    />

    <div
      v-if="isFetchingPromoterAccount"
      class="select-search-dropdown__loading"
    >
      <am2-loading-spinner size="48px" />
    </div>
    <div v-else>
      <nuxt-child
        class="content--centered"
        @on-promoter-deleted="onPromoterDeleted"
      />
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'CompanyPage',

  computed: {
    ...mapState({
      currentPromoter: state => state.admin.currentPromoter,
      isFetchingPromoterAccount: state => state.admin.isFetchingPromoterAccount,
    }),

    ...mapGetters({
      historicalPreviousPagePath: 'application/historicalPreviousPagePath',
      isAdminAccount: 'auth/isAdminAccount'
    }),

    menu() {
      return {
        overview: {
          name: 'Overview',
          to: `/admin/companies/${this.$route.params.oid}/edit/overview`,
        },
        permissions: {
          name: 'Permissions',
          to: `/admin/companies/${this.$route.params.oid}/edit/permissions`,
          featureKeys: null,
        },
        accounts: {
          name: 'Accounts',
          to: `/admin/companies/${this.$route.params.oid}/edit/accounts`,
          featureKeys: null,
        },
        messages: {
          name: 'Messages',
          to: `/admin/companies/${this.$route.params.oid}/edit/messages`,
          featureKeys: null,
        },
        events: {
          name: 'Events',
          to: `/admin/companies/${this.$route.params.oid}/edit/events`,
          featureKeys: null,
        },
        alphaSMS: {
          name: 'Alpha SMS',
          to: `/admin/companies/${this.$route.params.oid}/edit/alpha-sms`,
          featureKeys: null,
        },
      }
    },

    computedTitle() {
      return this.currentPromoter?.name || ''
    },
  },

  mounted() {
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }

    const hasPromoterChanged = !!this.currentPromoter && this.$route.params.oid !== this.currentPromoter.oid;

    if (hasPromoterChanged || (!this.currentPromoter && !this.isFetchingPromoterAccount)) {
      this.fetchPromoterAccount(this.$route.params.oid)
    }
  },

  methods: {
    ...mapActions([
      'admin/FETCH_PROMOTER',
      'admin/FETCH_MORE_PROMOTER_ACCOUNTS',
      'admin/FETCH_PROMOTER_REGION_SETTINGS',
      'admin/FETCH_MORE_PROMOTERS',
    ]),

    goBackToCompanies() {
      if (this.$route.query.from === 'sms') {
        this.$router.push({
          path: '/admin/sms/'
        });
      } else {
        this['admin/FETCH_MORE_PROMOTERS']({
          select: 'name,oid,emailAddress,country,companyCategory,sysCtime',
          reload: true,
          count: true,
        });
        this.$router.push({
          path: '/admin/companies/'
        });
      }
    },

    onPromoterDeleted() {
      this.goBackToCompanies()
    },

    fetchPromoterAccount(oid) {
      this['admin/FETCH_PROMOTER']({ oid });
      this['admin/FETCH_PROMOTER_REGION_SETTINGS']({ oid });
    },

    handleStepLinkClick() {
      this.goBackToCompanies()
    },
  }
}
</script>

<style lang="scss" scoped>
.hero {
  &-wrapper {
    min-height: 100px;
    background-color: white;
    display: flex;
    align-items: end;
    justify-content: center;
  }

  &-content {
    width: 1296px;
    padding: 0px 24px;
    margin: 30px 0;

    .heading {
      padding: 20px 0;
    }
  }
}

.simple-button {
  width: 125px;
}

.content--centered {
  max-width: 1296px;
  padding: 0 24px;
  margin: 30px auto;
}
</style>