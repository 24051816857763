var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sms-page",attrs:{"data-test-id":"sms-page"}},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreData),expression:"fetchMoreData"}],staticClass:"u-margin-top-6",attrs:{"infinite-scroll-disabled":"disableFetchMoreMessages","infinite-scroll-distance":10}},[_c('am2-heading',{staticClass:"u-margin-bottom-2",style:({
        marginTop: '56px',
      }),attrs:{"type":"h1","size":"lg","title":"SMS Customers"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-bottom-8",attrs:{"multiple-lines":"","size":"xs","text":"All companies that have Twilio subaccounts are shown below"}}),_vm._v(" "),(_vm.isFetchingTwilioSMSSenderEnabledCompanies)?_c('div',{staticClass:"loading-container"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):(!!_vm.twilioSMSSenderEnabledCompanies)?_c('am2-table',{staticClass:"sms-companies-table",attrs:{"heads":_vm.tableHeadings,"rows":_vm.twilioSMSSenderEnabledCompanies,"has-sticky-header":"","enable-row-click":"","has-control-section":""},on:{"rowClick":_vm.handleSMSRowClick},scopedSlots:_vm._u([{key:"oid",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"number-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.oid}})],1)])}},{key:"emailAddress",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.emailAddress}})],1)])}},{key:"name",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.name}})],1)])}},{key:"country",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.country}})],1)])}},{key:"type",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.type}})],1)])}}])}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }