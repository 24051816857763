<template>
  <am2-card-container
    v-if="isSmsEnabled || isEmailEnabled"
    class="engagement"
    layout="regular"
  >
    <div class="engagement__top">
      <div>
        <ar-text
          class="name"
          :text="title"
          size="md"
          :style="{
            color: $arStyle.color.blueGrey800,
            marginBottom: '8px',
          }"

        />
        <div class="engagement__top-desc">
          <ar-text
            :text="description"
            size="sm"
            :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
          />
          <div @click="goToRecipients">
            <ar-text
              text="View messages"
              size="xs"
              :style="{
              color: $arStyle.color.purple500,
              textDecoration: 'underline',
              cursor: 'pointer',
            }"
            />
          </div>
        </div>
      </div>

      <am2-message-type-icon-select
        v-if="!isOnlyOneMessageType"
        :selected="typeMessageTab"
        @select="handleSelectMessageType"
      />
    </div>

    <div v-if="!isEmpty" class="engagement__main">
      <template v-if="typeMessageTab === 'email'">
        <RecipientsOverviewRate
          :total="calculatedData.email.totalSent"
          :value="calculatedData.email.opened"
          title="Open rate"
          link
          :tooltip="`% of emails opened`"
          data-test-id="recipients-overview-open-rate"
          @click="goToRecipients"
        />

        <div class="engagement__main-divider"></div>

        <RecipientsOverviewRate
          :total="calculatedData.email.totalSent"
          :value="calculatedData.email.clicked"
          title="Click rate"
          link
          :tooltip="`% of emails clicked`"
          data-test-id="recipients-overview-open-rate"
          @click="goToRecipients"
        />

        <div class="engagement__main-divider"></div>

        <SinglePercentRate
          class="single"
          title="Click to open rate"
          :tooltip="`% of recipients who clicked a link in the email after opening it`"
          :value="calculatedData.email.ctor"
        />
      </template>

      <template v-else>
        <RecipientsOverviewRate
          :total="statistics.sms.totalMessages"
          :value="statistics.sms.clicked"
          title="Click rate"
          link
          :tooltip="`% of sms clicked`"
          data-test-id="recipients-overview-open-rate"
          @click="goToRecipients"
        />
      </template>
    </div>
    <div v-else class="engagement__empty">
      <ar-text
        text="No messages linked to this event have been sent"
        size="xs"
        :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
      />
    </div>
  </am2-card-container>
</template>

<script>
import RecipientsOverviewRate from "~/pages/message-center/messages/view/components/recipients-overview-rate.vue";
import {mapActions, mapGetters, mapState} from 'vuex'
import SinglePercentRate from '~/pages/message-center/messages/view/components/single-percent-rate.vue'

export default {
  name: "EngagementSection",
  components: {
    SinglePercentRate,
    RecipientsOverviewRate,
  },
  data() {
    return {
      typeMessageTab: "email",
    }
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled'
    }),
    ...mapState({
      statistics: state => state.event.engagementInsights,
      isFetchingEngagementInsight: state => state.event.isFetchingEngagementInsight,
    }),
    isEmpty() {
      return !(this.statistics && this.statistics[this.typeMessageTab]?.messagesCount);

    },
    isSmsEnabled() {
      return this.isFeatureEnabled(['sms-enabled'])
    },
    isEmailEnabled() {
      return this.isFeatureEnabled(['email'])
    },
    isOnlyOneMessageType() {
      return this.isSmsEnabled && !this.isEmailEnabled || !this.isSmsEnabled && this.isEmailEnabled
    },
    title() {
      return this.typeMessageTab === 'email' ? 'Email engagement' : 'SMS engagement'
    },
    description() {
      const total = this.calculatedData?.[this.typeMessageTab]?.messagesCount || 0
      return `From ${total} messages sent`
    },
    calculatedData() {
      if (!this.statistics) return null
      return {
        email: this.calculateStats(this.statistics.email),
        sms: this.calculateStats(this.statistics.sms)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchEngagementStats: 'event/FETCH_ENGAGEMENT_STATS'
    }),
    handleSelectMessageType(type) {
      this.typeMessageTab = type;
    },
    goToRecipients() {
      this.$router.push({
        path: 'messages'
      });
    },

    calculateStats(data) {
      const {
        totalMessages,
        sent,
        opened,
        clicked,
        unsubscribed,
        bounced,
        failed,
        messagesCount,
      } = data

      const stats = {
        total: totalMessages || 0,
        sent: (sent || 0) + (opened || 0) + (clicked || 0) + (unsubscribed || 0),
        opened: (opened || 0) + (clicked || 0) + (unsubscribed || 0),
        clicked: clicked || 0,
        unsubscribed: unsubscribed || 0,
        bounced: bounced || 0,
        failed: failed || 0,
        totalSent: (totalMessages || 0) - (bounced || 0),
        messagesCount: messagesCount || 0
      };

      const ctorValue = stats.opened === 0 ? 0 : (stats.clicked / stats.opened) * 100;

      return {
        ...stats,
        ctor: ctorValue > 0 && ctorValue < 10 ? ctorValue.toFixed(1) : Math.trunc(ctorValue) || 0
      };
    }
  },

  mounted() {
    if (this.isSmsEnabled && !this.isEmailEnabled) {
      this.typeMessageTab = 'sms'
    }
  }
};
</script>

<style scoped lang="scss">
.engagement {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 20px;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    margin-bottom: 32px;
  }

  &__top-desc {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main-divider {
    width: 1px;
    height: 96px;
    background-color: $skyBlueGrey400;
    margin: 0 32px;
  }

  .rate-container {
    margin: 0;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 40px;
  }
}
</style>
