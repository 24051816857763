<template>
  <am2-card-container
    layout="soft"
    class="revenue-by-message-stats"
  >
    <div v-if="isLocked" layout="soft" class="revenue-by-message-stats__locked">
      <am2-card-container
        layout="soft"
        class="revenue-by-message-stats__locked-content">
        <am2-tag
          class="free-tag"
          text="PREMIUM"
          text-size="xxxs"
          shape="round"
          type="gold"
          :style="{
          marginRight: '16px',
        }"
        />
        <div>
          <ar-text
            text="Revenue tracking"
            size="sm"
            weight="bold"
            :style="{
            marginBottom: '4px',
            color: $arStyle.color.blueGrey800,
        }"
          />
          <ar-text
            text="Track revenue attributed to all message sends linked to this event "
            size="xs"
            :style="{
            color: $arStyle.color.blueGrey700,
        }"
          />
        </div>
      </am2-card-container>
    </div>

    <div class="revenue-by-message-stats__content" :class="[isLocked && 'locked']">
      <ar-text
        text="Ticket sales attributed to"
        :style="{marginRight: '34px'}"
      />

      <div class="revenue-by-message-stats__container">
        <div class="revenue-by-message-stats__icon-wrapper">
          <ar-icon
            class="revenue-by-message-stats__icon"
            name="email"
            :color="$arStyle.color.purple500"
          />
        </div>

        <div class="revenue-by-message-stats__text">
          <template v-if="!isLoading">
            <div class="revenue-by-message-stats__label">{{ formatEmailText(formattedStats.email.count) }}</div>
            <div class="revenue-by-message-stats__value">{{regionSettingsCurrencySymbol}}{{ formatNumber(formattedStats.email.revenue) }}</div>
          </template>
          <template v-else>
            <am2-typography-skeleton
              size="xs"
              :style="{
              marginBottom: '10px',
              maxWidth: '70px',
          }"
            />
            <am2-typography-skeleton
              size="xs"
              :style="{
              maxWidth: '150px',
          }"
            />
          </template>
        </div>
      </div>

      <div class="revenue-by-message-stats__container">
        <div class="revenue-by-message-stats__icon-wrapper">
          <ar-icon
            class="revenue-by-message-stats__icon"
            name="sms"
            :color="$arStyle.color.green500"
          />
        </div>

        <div class="revenue-by-message-stats__text">
          <template v-if="!isLoading">
            <div class="revenue-by-message-stats__label">{{ formatSmsText(formattedStats.sms.count) }}</div>
            <div class="revenue-by-message-stats__value">{{ regionSettingsCurrencySymbol }}{{ formatNumber(formattedStats.sms.revenue) }}</div>
          </template>
          <template v-else>
            <am2-typography-skeleton
              size="xs"
              :style="{
              marginBottom: '10px',
              maxWidth: '70px',
          }"
            />
            <am2-typography-skeleton
              size="xs"
              :style="{
              maxWidth: '150px',
          }"
            />
          </template>
        </div>
      </div>

      <div
        v-if="!isLoading && (formattedStats.email.revenue === 0 && formattedStats.sms.revenue === 0)"
        class="revenue-by-message-stats__link"
        @click="goToMessages">
        <ar-text
          text="Link previously sent messages"
          size="xs"
          :style="{
          color: $arStyle.color.purple500,
          textDecoration: 'underline',
          cursor: 'pointer',
        }"
        />
        <ar-icon
          name="tooltip-question-mark"
          class="u-margin-left-2"
          :color="$arStyle.color.blueGrey700"
          height="16px"
          width="16px"
          v-tooltip.bottom="{
              content: 'Select a previously sent message and link one or more events via the conversions tab. Your combined conversions will appear here.',
            }"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {formatNumberWithCommas} from '@/utils/helpers';

export default {
  name: 'RevenueByMessageStats',
  data() {
    return {
      stats: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      auth: state => state.auth,
    }),
    ...mapGetters({
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    formattedStats() {
      if (!this.stats?.email || !this.stats?.sms) return {
        email: { count: 0, revenue: 0 },
        sms: { count: 0, revenue: 0 }
      };
      return this.stats
    },

    isLocked() {
      return !this.isFeatureEnabled(['conversions'])
    }
  },
  methods: {
    async fetchMessageStats() {
      this.isLoading = true;
      const { promoterOid } = this.auth.account;
      try {
        this.stats = await this.$axios.$get(`/promoter/${promoterOid}/event/${this.oid}/revenue-event-by-messages`);
      } finally {
        this.isLoading = false;
      }
    },
    formatNumber(amount) {
      return formatNumberWithCommas(amount / 100);
    },
    goToMessages() {
      this.$router.push('/message-center/messages');
    },
    formatEmailText(number) {
      const sendWord = number === 1 ? 'send' : 'sends';
      return `${number} Email ${sendWord}`
    },
    formatSmsText(number) {
      const sendWord = number === 1 ? 'send' : 'sends';
      return `${number} SMS ${sendWord}`
    },
  },
  mounted() {
    if (!this.isLocked) {
      this.fetchMessageStats();
    }
  }
}
</script>

<style scoped lang="scss">
.revenue-by-message-stats {
  position: relative;
  &__content {
    padding: 32px;
    display: flex;
    align-items: center;
  }

  &__container {
    display: flex;
    align-items: center;
    margin-right: max(12px, 112px);
  }

  &__icon-wrapper {
    border: 2px solid #EBEBEB;
    height: 40px;
    min-width: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &__text {
    margin-left: 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__label {
    margin-bottom: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.2px;
    color: $blueGrey700;
  }

  &__value {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: $blueGrey800;
  }

  &__link {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__locked {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__locked-content {
    display: flex;
    align-items: center;
    padding: 16px;
  }
}

.locked {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}
</style>
