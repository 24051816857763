<template>
  <section>
    <am2-top-create
      v-if="isAdminAccount"
      title="Create new company"
      :exit-path="exitPath"
    />
    <div
      v-if="isAdminAccount"
      :class="[
        'wrapper',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max'
      ]"
    >
      <div class="inner">

        <am2-heading
          type="h1"
          size="lg"
          title="Company Details"
          :style="{ marginBottom: '38.5px' }"
        />
        <am2-field name="promoter-name" label="Company Name">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="promoter-name"
              v-model="editPromoter.name"
              name="promoter-name"
              data-vv-as="company name"
              data-vv-name="companyName"
              placeholder="Company Name"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-name')"
              type="error"
              :text="veeErrors.first('promoter-name')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field  name="promoter-category" label="Company Category">
          <ar-simple-select
            data-vv-name="companyCategory"
            data-vv-as="company type"
            placeholder="Company type"
            :items="companyCategoryDropdownItems"
            data-test-id="company-category-selector"
            @select="handleCompanyTypeDropdownSelect"
          />
        </am2-field>
        <am2-field name="promoter-address1" label="Address1">
          <div>
            <ar-input
              id="promoter-address1"
              ref="promoter-address1"
              v-model="editPromoter.address1"
              name="promoter-address1"
              data-vv-as="address1"
              data-vv-name="address1"
              placeholder="Address1"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-address1')"
              type="error"
              :text="veeErrors.first('promoter-address1')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="promoter-address2" label="Address2">
          <div>
            <ar-input
              id="promoter-address2"
              ref="promoter-address2"
              v-model="editPromoter.address2"
              name="promoter-address2"
              data-vv-as="address2"
              data-vv-name="address2"
              placeholder="Address2"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-address2')"
              type="error"
              :text="veeErrors.first('promoter-address2')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="promoter-state" label="State">
          <div>
            <ar-input
              id="promoter-state"
              ref="promoter-state"
              v-model="editPromoter.state"
              name="promoter-state"
              data-vv-as="state"
              data-vv-name="state"
              placeholder="State"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-state')"
              type="error"
              :text="veeErrors.first('promoter-state')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="promoter-city" label="City">
          <div>
            <ar-input
              id="promoter-city"
              ref="promoter-city"
              v-model="editPromoter.city"
              name="promoter-city"
              data-vv-as="city"
              data-vv-name="city"
              placeholder="City"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-city')"
              type="error"
              :text="veeErrors.first('promoter-city')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="promoter-postcode" label="Postcode">
          <div>
            <ar-input
              id="promoter-postcode"
              ref="promoter-postcode"
              v-model="editPromoter.postcode"
              name="promoter-postcode"
              data-vv-as="postcode"
              data-vv-name="postcode"
              placeholder="Postcode"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('promoter-postcode')"
              type="error"
              :text="veeErrors.first('promoter-postcode')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field  name="promoter-country" label="Country">
          <ar-country-select
            value-type="iso"
            v-model="editPromoter.country"
            data-vv-name="country"
            data-vv-as="country"
            enable-clear
          />
        </am2-field>


        <am2-field name="promoter-defaultTimezone" label="Default Timezone">
          <div>
            <am2-timezone-select
              placeholder="Please select a default timezone..."
              v-model="regionSettings.defaultTimezone"
              ref="promoter-defaultTimezone"
              data-vv-name="promoter-defaultTimezone"
              data-vv-as="timezone"
              class="input--block"
              :enableClear="true"
            />

            <ar-state-message
              v-if="veeErrors.has('promoter-defaultTimezone')"
              type="error"
              :text="veeErrors.first('promoter-defaultTimezone')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="promoter-currency" label="Currency">
          <div>
            <ar-simple-select
              id="promoter-currency"
              ref="promoter-currency"
              enable-filter
              class="selector"
              name="promoter-currency"
              data-vv-as="currency"
              :items="currencyOptions"
              placeholder="Please select currency..."
              enable-clear
              @clear="() => selectCurrency(null)"
              @select="selectCurrency"
              :default-select-index="selectedCurrencyIndex"
            />
          </div>
        </am2-field>

        <am2-heading
          type="h1"
          size="lg"
          title="User Details"
          class="u-margin-top-10"
          :style="{ marginBottom: '38.5px' }"
        />
        <am2-field name="account-first-name" label="First Name">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="account-first-name"
              ref="account-first-name"
              v-model="editPromoter.firstName"
              autocomplete="given-name"
              name="account-first-name"
              data-vv-as="first name"
              data-vv-name="firstName"
              placeholder="First name"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('account-first-name')"
              type="error"
              :text="veeErrors.first('account-first-name')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="account-last-name" label="Last Name">
          <div>
            <ar-input
              v-validate.disable="'required'"
              id="account-last-name"
              ref="account-last-name"
              v-model="editPromoter.lastName"
              autocomplete="family-name"
              name="account-last-name"
              data-vv-as="last name"
              data-vv-name="lastName"
              placeholder="Last name"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('account-last-name')"
              type="error"
              :text="veeErrors.first('account-last-name')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="account-email-address" label="Email Address">
          <div>
            <ar-input
              v-validate.disable="'required|email'"
              id="account-email-address"
              ref="account-email-address"
              v-model="editPromoter.emailAddress"
              autocomplete="email"
              name="account-email-address"
              data-vv-as="email address"
              data-vv-name="emailAddress"
              placeholder="Email Address"
              class="input--block"
              type="text"
            />
            <ar-state-message
              v-if="veeErrors.has('account-email-address')"
              type="error"
              :text="veeErrors.first('account-email-address')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>
        <am2-field name="account-password" label="Password (Optional)">
          <div>
            <ar-snackbar
              type="warning"
              message="A random password will be generated for you if you don't enter one"
              :style="{
                maxWidth: '500px',
                marginBottom: '10px',
              }"
            />
            <ar-input
              v-validate="'arPasswordLength'"
              id="account-password"
              ref="account-password"
              v-model="editPromoter.password"
              name="account-password"
              autocomplete="new-password"
              data-vv-as="password"
              data-vv-name="password"
              placeholder="Password"
              class="input--block"
              type="password"
              text-secure
            />
            <ar-state-message
              v-if="veeErrors.has('account-password')"
              type="error"
              :text="veeErrors.first('account-password')"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </am2-field>

      </div>
    </div>
    <nav :class="[
      'navigation',
      $arMediaQuery.pageContent.maxWidth('md') && 'md-max'
    ]">
      <ar-simple-button
        text="Cancel"
        outlined
        @click="handleCancelClick"
      />
      <ar-simple-button
        text="Create new company"
        :loading="isCreatingAccount"
        @click="handleCreateNewAccountClick"
      />
    </nav>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { companyCategoryTypes, CURRENCY_CODES } from '~/utils/constants';

export default {
  name: 'NewCompany',
  layout: 'edit',

  data: () => ({
    editPromoter: {
      description: null,
      companyCategory: null,
      name: null,
      city: null,
      postcode: null,
      state: null,
      address1: null,
      address2: null,
      emailAddress: null,
      country: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      password: null,
    },
    isCreatingAccount: false,
    exitPath: '/admin/companies',
    regionSettings: {
      defaultTimezone: null,
      currency: null,
    },
    currencyOptions: CURRENCY_CODES.map((c) => ({ name: c.code })),
  }),

  computed: {
    ...mapGetters({
      isAdminAccount: 'auth/isAdminAccount',
    }),

    companyCategoryDropdownItems() {
      return companyCategoryTypes();
    },

    selectedCurrencyIndex() {
      if (this.regionSettings.currency === null) {
        return null;
      }
      const currencyCode = this.regionSettings.currency;
      const index = this.currencyOptions.findIndex((c) => c.name === currencyCode);
      return index === -1 ? null : index;
    },
  },

  mounted() {
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }
  },
  methods: {
    ...mapActions([
      'CREATE_STAFF_ACCOUNT',
      'admin/CREATE_PROMOTER_REGION_SETTINGS'
    ]),

    handleCompanyTypeDropdownSelect(item) {
      this.editPromoter.companyCategory = item.key;
    },

    async handleCreateNewAccountClick() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      try {
        this.isCreatingAccount = true;
        const promoterAccount = await this.CREATE_STAFF_ACCOUNT(this.editPromoter)
        await this['admin/CREATE_PROMOTER_REGION_SETTINGS']({oid: promoterAccount.oid, property: this.regionSettings})

        this.$arNotification.push({ 
          type: 'success',
          message: `Successfully created ${this.editPromoter.firstName}'s account`
        })
        this.$router.push({ path: this.exitPath })
      }
      catch (e) {
        console.error(e)

        if (e.response && e.response.status === 409) {
          this.$arNotification.push({
            type: 'error',
            message: 'There is already an account with this email address',
          })
        } else {
          this.$arNotification.push({
            type: 'error',
            message: 'Failed to create account',
          })
        }
      } 
      finally {
        this.isCreatingAccount = false
      }
    },

    handleCancelClick() {
      this.$router.push({
        path: this.exitPath,
      });
    },

    selectCurrency(val) {
      this.regionSettings.currency = val?.name || null;
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  height: calc(100vh - 90px);
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  margin-top: 90px;
  padding-bottom: 110px;
  overflow-y: auto;

  .inner {
    padding: 50px 0;
    width: 800px;
    margin: 0 auto;
  }
}

.navigation {
  width: 100%;
  z-index: $zIndexRegular;
  height: 110px;
  padding: 30px;
  border-top: 1px solid $skyBlueGrey500;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;

  &.md-max {
    height: 90px;
    padding: 20px;
  }
}
</style>