var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-test-id":"company-page-messages"}},[_c('am2-twilio-sub-account-modal',{attrs:{"is-show":_vm.isTwilioSubAccountModalOpen},on:{"cancel":_vm.closeTwilioSubAccountModal,"confirm":_vm.handleCreateTwilioSubAccount}}),_vm._v(" "),_c('am2-twilio-alphanumeric-id-modal',{attrs:{"is-show":_vm.isTwilioAlphanumericIdModalOpen,"mode":_vm.smsSenderModalMode,"data":_vm.selectedSMSSender},on:{"cancel":_vm.closeTwilioAlphanumerIdModal,"confirm":_vm.handleCreateTwilioAlphanumericId}}),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-flex-flow-row u-justify-content-space-between u-align-items-center"},[_c('div',[_c('am2-heading',{attrs:{"type":"h1","title":"Alphanumeric SMS","size":"md"}}),_vm._v(" "),(!_vm.noMessages && !_vm.isFetchingTwilioSubAccounts)?_c('div',{staticClass:"u-display-flex u-flex-flow-row",style:({
          marginTop: '12px',
        })},[_c('ar-text',{attrs:{"size":"xs","text":"Sub-account SID:","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-2",attrs:{"size":"xs","text":_vm.subAccountSID}}),_vm._v(" "),_c('ar-link-button',{staticClass:"u-margin-left-2",attrs:{"text":"Delete","color":_vm.$arStyle.color.red500},on:{"click":_vm.handleDeleteSubAccount}})],1):_vm._e()],1),_vm._v(" "),(!_vm.noMessages && !_vm.isFetchingTwilioSubAccounts)?_c('div',{staticClass:"u-display-flex u-flex-flow-row"},[_c('ar-link-button',{staticClass:"u-margin-right-4",attrs:{"text":"View connection guide"},on:{"click":_vm.handleConnectionGuideClick}}),_vm._v(" "),_c('ar-simple-button',{attrs:{"outlined":false,"text":"Add alphanumeric ID"},on:{"click":_vm.openCreateAlphanumericIdModal}})],1):_vm._e()]),_vm._v(" "),(_vm.isFetchingTwilioSubAccounts)?_c('div',{staticClass:"loading-container"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):(_vm.noMessages)?_c('div',{style:({
      marginTop: '32px',
    })},[_c('am2-no-content-section',{attrs:{"header":"Add a sub-account to start using alphanumeric sender ID’s"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('ar-simple-button',{style:({
            marginTop: '32px',
          }),attrs:{"outlined":false,"text":"Add sub-account"},on:{"click":_vm.openCreateTwilioSubAccountModal}})]},proxy:true}])})],1):_c('div',{style:({
      marginTop: '32px',
    })},[_c('am2-table',{staticClass:"message-table",attrs:{"heads":_vm.tableHeadings,"rows":_vm.twilioSMSSender,"has-sticky-header":"","empty-text":"No data available"},scopedSlots:_vm._u([{key:"friendlyName",fn:function(ref){
    var data = ref.data;
return _c('div',{staticClass:"string-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.friendlyName}})],1)])}},{key:"menu",fn:function(ref){
    var data = ref.data;
return _c('div',{staticClass:"menu-cell"},[_c('div',{staticClass:"action-container"},[_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
              name: 'menu',
            },"items":_vm.menuOptions},on:{"select":function (item) { return _vm.handleMenuOptionSelect(item.eventName, data); }}})],1)])}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }