<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="message-list-modal"
      :header="cardTitle"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'message-list-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div
          class="u-display-flex u-margin-bottom-3"
        >
          <ar-text
            size="xs"
            text="Subscribe associated contacts to list (optional)"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-icon
            v-tooltip.top="tooltipTextList"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-field
          :error-messages="[
            veeErrors.first('messageListOid')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            v-model="messageListOid"
            :channels="['email']"
          />
        </ar-field>
        <div
          class="u-display-flex u-margin-bottom-3 u-margin-top-5"
        >
          <ar-text
            size="xs"
            text="Pick a date to sync from (optional)"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-icon
            v-tooltip.top="tooltipTextDatePicker"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-field
          :error-messages="[
            veeErrors.first('syncFrom')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-datepicker
            ref="datepicker-1"
            :value="syncFrom"
            name="datepicker"
            placeholder="Date"
            :style="{
              width: '100%',
              height: '40px'
            }"
            @input="handleDateSelect(...arguments)"
            data-test-id="filter-date-input-1"
            :no-left-padding=true
          />
        </ar-field>
      </div>
      <div
        slot="body"
        :class="[
          'message-list-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
      </div>
      <div
        slot="footer"
        :class="[
          'message-list-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    integration: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      messageListOid: null,
      syncFrom: null
    };
  },

  computed: {
    confirmButtonDisabled() {
      return false;
    },
    cardTitle() {
      return "Connect to " + this.integration.name;
    },
    tooltipTextList() {
      return "Automatically subscribe all attendees of events from this " + this.integration.name + " account.";
    },
    tooltipTextDatePicker() {
      return "Sync events, orders and attendees on and after a particular date from this " + this.integration.name + " account.";
    }
  },

  watch: {
    isShow(val) {
      if (val) {
        this.messageListOid = null;
        this.syncFrom = null;
      }
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', { 
        messageListOid: this.messageListOid,
        syncFrom: this.syncFrom,
        integration: this.integration
      });
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    handleDateSelect(date) {
      this.syncFrom = date.toString();
    }
  }
};
</script>

<style lang="scss" scoped>
.message-list-modal {
  .message-list-modal-body {
    padding: 20px 30px 30px;
  }
  .message-list-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
