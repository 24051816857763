import { Module } from "vuex";
import { RootState } from '@/store/modules/types';
import { clone } from '@/utils/helpers';
import { getCurrencySymbol } from '@/utils/constants';
import { authActions } from './actions';
import {
  checkFeatureEnabled,
  checkIsAdminAccount,
  generateCountdown,
  checkPermissionEnabled,
  flattenMaps
} from './utils';

export const initialAuthState = (): AuthState => ({
  promoter: null,
  account: null,
  xAuthToken: null,
  isDoingLogin: false,
  nextLoginRetryTime: null,
  isDoingSignup: false,
  isChangingPassword: false,
  isSendingResetPasswordEmail: false,
  nextSendResetPasswordEmailRetryTime: null,
  isResettingPassword: false,
  isVerifyingEmailAddress: false,
  storedEmailAddress: null,
  useSampling: false,
  regionSettings: null,
  isUpdatingRegionSettings: false,
  users: null,
  invitees: null,
  isFetchingUsers: false,
  isFetchingInvitees: false,
  defaultUserRoles: null,
  promoterUserRoles: null,
  permissions: {},
  isFinishingInvite: false,
  isValidatingInvite: false,
  isFetchingTwilioSubaccount: false,
  twilioSubAccount: null,
  isFetchingSMSSenderIDs: false,
  twilioSMSSender: null,
  isFetchingDomains: false,
  domains: null,
  isCreatingDomainAuth: false,
  isValidatingDomain: false,
  currentDomain: null,
  timeToValue: null,
  isFetchingTimeToValue: false,
});

const accountModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialAuthState,
  actions: authActions,
  mutations: {
    SET_PROMOTER(state, payload: Promoter) {
      state.promoter = clone(payload);
    },
    SET_PROMOTER_ACCOUNT(state, payload: PromoterAccount) {
      if (payload.features && typeof payload.features === 'object' && !Array.isArray(payload.features)) {
        const flattenedFeatures= flattenMaps(payload.features);
        console.log('Detected that features is in legacy format nested ', payload.features, ' flattened features ', flattenedFeatures);
        state.account = { ...clone(payload), features: flattenedFeatures };
      } else {
        state.account = clone(payload);
      }
    },
    SET_X_AUTH_TOKEN(state, xAuthToken: string) {
      state.xAuthToken = xAuthToken;
    },
    // Login
    SET_IS_DOING_LOGIN(state, isDoingLogin: boolean) {
      state.isDoingLogin = isDoingLogin;
    },
    SET_NEXT_LOGIN_RETRY_TIME(state, newLoginRetryTime: number) {
      state.nextLoginRetryTime = newLoginRetryTime;
    },
    // Signup
    SET_IS_DOING_SIGNUP(state, isDoingSignup: boolean) {
      state.isDoingSignup = isDoingSignup;
    },
    // Change Password
    SET_IS_CHANING_PASSWORD(state, isChangingPassword: boolean) {
      state.isChangingPassword = isChangingPassword;
    },
    // Send Reset Password Email
    SET_IS_SENDING_RESET_PASSWORD_EMAIL(state, isSendingResetPasswordEmail: boolean) {
      state.isSendingResetPasswordEmail = isSendingResetPasswordEmail;
    },
    SET_NEXT_SEND_RESET_PASSWORD_EMAIL_RETRY_TIME(state, nextSendResetPasswordEmailRetryTime: number) {
      state.nextSendResetPasswordEmailRetryTime = nextSendResetPasswordEmailRetryTime;
    },
    // Reset Password
    SET_IS_RESETTING_PASSWORD(state, isResettingPassword: boolean) {
      state.isResettingPassword = isResettingPassword;
    },
    // Verify Email Address
    SET_IS_VERIFYING_EMAIL_ADDRESS(state, isVerifyingEmailAddress: boolean) {
      state.isVerifyingEmailAddress = isVerifyingEmailAddress;
    },
    // We use storedEmailAddress to temporarily store an email string when we want it to persist between pages of the app
    SET_STORED_EMAIL_ADDRESS(state, emailAddress: string) {
      state.storedEmailAddress = emailAddress;
    },
    // Sample insights to reduce the number of rows we need to generate the result
    SET_SAMPLING(state, shouldSample: boolean) {
      state.useSampling = shouldSample;
    },

    SET_REGION_SETTINGS(state, regionSettings: PromoterProperty) {
      state.regionSettings = regionSettings;
    },
    SET_IS_UPDATING_REGION_SETTINGS(state, isUpdatingRegionSettings: boolean) {
      state.isUpdatingRegionSettings = isUpdatingRegionSettings;
    },
    // User settings
    SET_USERS(state, users: PromoterAccount[]) {
      state.users = users;
    },
    SET_INVITEES(state, invitees: PromoterInviteLog[]) {
      state.invitees = invitees;
    },
    SET_IS_FETCHING_USERS(state, isFetchingUsers: boolean) {
      state.isFetchingUsers = isFetchingUsers;
    },
    SET_IS_FETCHING_INVITEES(state, isFetchingInvitees: boolean) {
      state.isFetchingInvitees = isFetchingInvitees;
    },
    SET_DEFAULT_USER_ROLES(state, defaultUserRoles: UserRole[]) {
      state.defaultUserRoles = defaultUserRoles
    },
    SET_PROMOTER_USER_ROLES(state, promoterUserRoles: UserRole[]) {
      state.promoterUserRoles = promoterUserRoles
    },
    SET_PROMOTER_ACCOUNT_DATA(state, accountData: PromoterAccount) {
      state.users = state.users.map((user) => {
        if (user.oid === accountData.oid) {
          return {
            ...user,
            ...accountData
          }
        } else {
          return user
        }
      })
    },
    DELETE_PROMOTER_ACCOUNT(state, accountOid: number) {
      state.users = state.users.filter(user => user.oid !== accountOid)
    },
    SET_PERMISSIONS(state, permissions: PermissionsState) {
      state.permissions = clone(permissions);
    },
    // Finish invite
    SET_IS_FINISHING_INVITE(state, isFinishingInvite: boolean) {
      state.isFinishingInvite = isFinishingInvite;
    },
    // Finish invite
    SET_IS_VALIDATING_INVITE(state, isValidatingInvite: boolean) {
      state.isValidatingInvite = isValidatingInvite;
    },
    // Twilio alphanumeric
    SET_IS_FETCHING_TWILIO_SUBACCOUNT(state, isFetchingTwilioSubaccount: boolean) {
      state.isFetchingTwilioSubaccount = isFetchingTwilioSubaccount;
    },
    SET_TWILIO_SUBACCOUNT(state, twilioSubAccount: TwilioSubAccount) {
      state.twilioSubAccount = twilioSubAccount
    },
    SET_IS_FETCHING_SMS_SENDER_IDS(state, isFetchingSMSSenderIDs: boolean) {
      state.isFetchingSMSSenderIDs = isFetchingSMSSenderIDs;
    },
    SET_SMS_SENDER_IDS(state, twilioSMSSender: TwilioSMSSender[]) {
      state.twilioSMSSender = clone(twilioSMSSender);
    },
    // Domains
    SET_IS_FETCHING_DOMAINS(state, isFetchingDomains: boolean) {
      state.isFetchingDomains = isFetchingDomains;
    },
    SET_DOMAINS(state, domains: Domain[]) {
      state.domains = domains
    },
    SET_IS_CREATING_DOMAIN_AUTH(state, isCreatingDomainAuth: boolean) {
      state.isCreatingDomainAuth = isCreatingDomainAuth;
    },
    SET_IS_VALIDATING_DOMAIN(state, isValidating: boolean) {
      state.isValidatingDomain = isValidating;
    },
    ADD_TO_DOMAINS(state, domain: Domain) {
      state.domains = [
        domain,
        ...clone(state.domains),
      ];
    },
    SET_MFA_ENABLED(state, value: boolean) {
      state.account.mfaEnabled = value;
    },

    // Time to Value
    SET_IS_FETCHING_TIME_TO_VALUE(state, isFetchingTimeToValue: boolean) {
      state.isFetchingTimeToValue = isFetchingTimeToValue;
    },
    SET_TIME_TO_VALUE(state, timeToValue: TimeToValue) {
      state.timeToValue = timeToValue
    },
  },
  getters: {
    nextLoginRetryCountdown(state, getters, rootState): number {
      if (!state.nextLoginRetryTime) { return 0; }
      return generateCountdown(state.nextLoginRetryTime , rootState.application.applicationTime);
    },
    nextSendResetPasswordEmailRetryCountdown(state, getters, rootState): number {
      if (!state.nextSendResetPasswordEmailRetryTime) { return 0; }
      return generateCountdown(state.nextSendResetPasswordEmailRetryTime , rootState.application.applicationTime);
    },
    isAdminAccount(state) {
      if (!state.xAuthToken) { return false; }
      return checkIsAdminAccount(state.xAuthToken);
    },
    isFeatureEnabled(state) {
      return (featureKeys: string[]) => {
        if (!state.account || !state.account.features) { return false; }
        return checkFeatureEnabled(featureKeys, state.account.features);
      };
    },
    promoterAccountOid(state): number | null {
      return state.account?.oid || null;
    },
    userPromoterOid(state): number | null {
      if (!state.account) { return null; }
      return state.account.promoterOid ?? null;
    },
    // permissionKeys chaining is in the form of 'AND'
    isPermissionEnabled(state) {
      return (permissionKeys: string[]) => {
        if (!state.account || !state.permissions) { return null; }
        return checkPermissionEnabled(permissionKeys, state.permissions)
      }
    },
    regionSettingsCurrencySymbol(state): string | null {
      if (!state.regionSettings?.additionalInfo?.currency) {
        return '$'
      }

      return getCurrencySymbol(state.regionSettings.additionalInfo.currency)
    },
    country(state): string | null {
      return state.account?.companyCountry
    }
  },
};

export default accountModule;
