import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Get promoter property
   * @param promoterAccountOids
   * @returns
   */
  async get(promoterOid: number, type: string): Promise<PromoterProperty | null> {
    const { status, data: { rows } } = await axios.get(`/promoter/${promoterOid}/property`, {
      params: {
        $top:'all',
        $count: true,
        $filter: `type = ${type} AND promoterOid = ${promoterOid}`,
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Promoter Property error',
        message: `Error getting Promoter Property from promoter oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }

    if (rows.length > 0) {
      return rows[0]
    } else {
      return null
    }
  },

  /**
   * Get promoter property
   * @param promoterAccountOids
   * @returns
   */
  async searchOids(promoterOid: number, type: string, oids: number[]): Promise<PromoterProperty[] | null> {
    if (!oids || oids.length === 0) return null;
    const oidsString = `oid = ${oids.join(' OR oid = ')}`
    const { status, data: { rows } } = await axios.get(`/promoter/${promoterOid}/property`, {

      params: {
        $top:'all',
        $count: true,
        $filter: `type = ${type} AND promoterOid = ${promoterOid} AND (${oidsString})`,
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Promoter Property error',
        message: `Error getting Promoter Property from promoter oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }

    return rows
  },
  async searchValues(promoterOid: number, type: string, values: string[]): Promise<PromoterProperty[] | null> {
    if (!values || values.length === 0) return null;
    const valuesString = `property = ${values.join(' OR property = ')}`
    const { status, data: { rows } } = await axios.get(`/promoter/${promoterOid}/property`, {

      params: {
        $top:'all',
        $count: true,
        $filter: `type = ${type} AND promoterOid = ${promoterOid} AND (${valuesString})`,
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Promoter Property error',
        message: `Error getting Promoter Property from promoter oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }

    return rows
  },
  /**
   * Add promoter property
   * @param promoterAccountOids
   * @returns
   */
  async post(promoterOid: number, propertyObj: object): Promise<PromoterProperty | null> {
    const { status, data } = await axios.post(`/promoter/${promoterOid}/property`, propertyObj);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Update Promoter Property error',
        message: `Error updating Promoter Property with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Update promoter account
   * @param promoterAccountOids
   * @returns
   */
  async patch(promoterOid: number, propertyOid: number, propertyObj: object): Promise<PromoterProperty | null> {
    const { status, data } = await axios.patch(`/promoter/${promoterOid}/property/${propertyOid}`, propertyObj);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Update Promoter Property error',
        message: `Error updating Promoter Property with oid of ${propertyOid} for promoter oid ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
});
