var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-link-button-dropdown',{staticClass:"select-provider-chooser",class:{'select-provider-chooser__error': _vm.hasError},attrs:{"items":_vm.decoratedItems,"item-key":_vm.selectKey,"has-search":"","placeholder":_vm.placeholder,"dropdown-style":{
    width: '280px',
  },"button-name":"ar-simple-button","button-props":{
    textWeight: 'normal',
    outlined: true,
    iconSide: 'right',
    iconName: 'arrow',
    iconProps: {
      height: '7px',
      color: _vm.$arStyle.color.skyBlueGrey700
    },
    iconDistance: 'auto',
    type: 'grey',
  },"search-placeholder":"Search or add provider","data-test-id":"select-provider-chooser"},on:{"select":_vm.handleItemSelect,"searchStringChange":_vm.handleSearchStringChange}})}
var staticRenderFns = []

export { render, staticRenderFns }