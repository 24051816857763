var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['top-create', _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max'],style:({
    backgroundColor: _vm.type === 'purple' ? _vm.$arStyle.color.purple500 : 'white'
  })},[(_vm.loading)?_c('am2-loading-section',{style:({
      width: '100px',
      height: '100%'
    })}):_c('div',{staticClass:"top-create_content"},[_c('div',{staticClass:"top-create__heading"},[(_vm.titleIcon || _vm.title)?_c('div',{staticClass:"u-display-flex u-flex-flow-column"},[_c('div',{staticClass:"top-create__title "},[(_vm.titleIcon)?_c('div',{staticClass:"icon-container"},[_c('ar-icon',_vm._b({},'ar-icon',_vm.titleIcon,false))],1):_vm._e(),_vm._v(" "),(_vm.titleIcon)?_c('am2-heading',{style:({
              color: _vm.type === 'purple' ? 'white' : null
            }),attrs:{"type":"h1","size":"md","title":_vm.title}}):_c('am2-heading',{style:({
              color: _vm.type === 'purple' ? 'white' : null
            }),attrs:{"type":"h1","size":"sm","title":_vm.title,"weight":"bold"}})],1),_vm._v(" "),(_vm.subtitle)?_c('div',{staticClass:"u-margin-top-3"},[_c('am2-heading',{style:({
              color: _vm.type === 'purple' ? 'white' : null
            }),attrs:{"type":"h1","size":"xxs","title":_vm.subtitle}})],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{class:['top-create__content', _vm.title && _vm.$slots.content && 'has-title-section']},[_vm._t("content")],2)]),_vm._v(" "),_c('div'),_vm._v(" "),_c('div',{staticClass:"top-create__exit"},[(_vm.hasAdvancedSettings)?_c('ar-link-button',{style:({
          color: _vm.type === 'purple' ? 'white' : null,
          marginRight: '30px'
        }),attrs:{"text":"Advanced Settings","data-test-id":"top-create-advanced-settings-link"},on:{"click":_vm.handleAdvancedSettingsClick}}):_vm._e(),_vm._v(" "),(_vm.showExitButton)?_c('div',[_c('ar-link-button',{style:({
            color: _vm.type === 'purple' ? 'white' : null
          }),attrs:{"text":"Exit","data-test-id":"top-create-exit-link"},on:{"click":_vm.handleExitClick}})],1):_vm._e()],1)]),_vm._v(" "),(_vm.totalSteps)?_c('am2-simple-stepper',{staticClass:"progress-bar",attrs:{"steps-count":_vm.totalSteps,"current-step":_vm.currentStep}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }