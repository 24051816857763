<template>
  <div class="ar-datepicker">
    <ar-input
      :class="[
        'u-width-100-percent',
        'u-height-100-percent',
        'ar-datepicker-input',
        isDatePickerOpened && 'focus',
      ]"
      :value="formattedDate"
      :placeholder="placeholder"
      :disabled="disabled"
      readonly
      @click.native="toggleDatePicker"
      :style="{
        maxHeight: '100%',
      }"
    />
    <div v-if="hasArrow" class="datepicker-arrow">
      <ar-icon
        name="arrow"
        :rotate="isDatePickerOpened ? 180 : 0"
        @click="toggleDatePicker"
      />
    </div>
    <div
      ref="invisible-modal"
      :style="{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        zIndex: this.$arStyle.zIndex.globalHighest,
        display: isDatePickerOpened ? 'block' : 'none',
      }"
      @click.stop.prevent="handleInvisibleModalClick"
    />
    <div
      ref="datepicker-wrapper"
      :style="{
        display: 'inline-block',
        position: 'fixed',
        top: datePickerWrapperPosition.top,
        bottom: datePickerWrapperPosition.bottom,
        left: datePickerWrapperPosition.left,
        width: datePickerWrapperPosition.width,
        height: isDatePickerOpened ? datePickerWrapperPosition.height : '0',
        opacity: isDatePickerOpened ? '1' : '0.7',
        overflow: 'hidden',
        transition: 'height 0.2s, opacity 0.2s',
        zIndex: this.$arStyle.zIndex.globalHighest + 1,
      }"
    >
      <Datepicker
        ref="datepicker"
        :value="realDateForDatePicker"
        :disabledDates="{
          to: disabledTo,
          from: disabledFrom,
        }"
        inline
        @selected="handleDateSelect"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from '@hokify/vuejs-datepicker';
import moment from 'moment';

export default {
  name: 'ARDatepicker',

  components: {
    Datepicker,
  },

  props: {
    value: {
      type: String,
      default: null,
      validator: (val) => {
        return moment(val, 'YYYY-MM-DD', true).isValid();
      },
    },
    placeholder: {
      type: String,
      default: 'Select a date',
    },
    format: {
      type: String,
      default: 'MMM Do, YYYY',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledFrom: {
      type: Date,
      default: null,
    },
    disabledTo: {
      type: Date,
      default: null,
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    noLeftPadding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      realDateForDatePicker: null,
      isDatePickerOpened: false,
      datePickerWrapperPosition: {},
    };
  },

  computed: {
    formattedDate() {
      if (!this.realDateForDatePicker) { return ''; }
      return moment(this.realDateForDatePicker).format(this.format);
    },
  },

  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.realDateForDatePicker = moment(newValue, 'YYYY-MM-DD', true).toDate();
        } else {
          this.realDateForDatePicker = null;
        }
      },
      immediate: true,
    },
    isDatePickerOpened(opened) {
      if (opened) {
        this.updateDatePickerPosition();
      }
    },
  },

  methods: {
    attachDatePickerToBody() {
      document.body.appendChild(this.$refs['invisible-modal']);
      document.body.appendChild(this.$refs['datepicker-wrapper']);
    },
    removeDatePickerFromBody() {
      document.body.removeChild(this.$refs['invisible-modal']);
      document.body.removeChild(this.$refs['datepicker-wrapper']);
    },
    updateDatePickerPosition() {
      const datePickerHt = 325;
      const datePickerWd = 344;
      const elementHt = this.$el.offsetHeight;
      const elementWd = this.$el.offsetWidth;
      const { top, left, bottom } = this.$el.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const padding = this.noLeftPadding ? 0 : 10;
      if (datePickerHt + top + elementHt > windowHeight) {
        let leftFixed;
        if (left - padding + datePickerWd > windowWidth) {
          leftFixed = windowWidth - datePickerWd - padding;
        } else {
          leftFixed = left - padding;
        }
        this.datePickerWrapperPosition = {
          bottom: `${windowHeight - top + 5 - padding}px`,
          left: `${leftFixed}px`,
          width: `${datePickerWd + padding * 2}px`,
          height: `${datePickerHt + padding * 2}px`,
          padding: `${padding}px`,
        };
      } else {
        let leftFixed;
        if (left - padding + datePickerWd > windowWidth) {
          leftFixed = windowWidth - datePickerWd - padding;
        } else {
          leftFixed = left - padding;
        }
        this.datePickerWrapperPosition = {
          top: `${top + elementHt + 5 - padding}px`,
          left: `${leftFixed}px`,
          width: `${datePickerWd + padding * 2}px`,
          height: `${datePickerHt + padding * 2}px`,
          padding: `${padding}px`,
        };
      }
    },
    // This method is being used by some components, please don't take it out
    open() {
      this.isDatePickerOpened = true;
    },
    handleDateSelect(event) {
      if (event === null) return;
      this.realDateForDatePicker = event;
      this.$emit('input', moment(event).format('YYYY-MM-DD'));
      this.isDatePickerOpened = false;
    },
    handleInvisibleModalClick() {
      this.isDatePickerOpened = false;
    },
    toggleDatePicker() {
      this.isDatePickerOpened = !this.isDatePickerOpened;
    },
  },

  mounted() {
    this.attachDatePickerToBody();
    this.updateDatePickerPosition();
  },
  beforeDestroy() {
    this.removeDatePickerFromBody();
  },
};
</script>

<style lang="scss">
@import "@hokify/vuejs-datepicker/dist/vuejs-datepicker.css";
.vdp-datepicker__calendar {
  width: 344px;
  border-radius: 4px;
  padding: 10px;

  .cell {
    border-radius: 4px;
    line-height: 38px;
    &:not(.blank):not(.disabled).year:hover { border: 1px solid $purple500; }
    &:not(.blank):not(.disabled).month:hover { border: 1px solid $purple500; }
    &:not(.blank):not(.disabled).day:hover { border: 1px solid $purple500; }
    &.selected {
      background: $purple500;
      color: white;
      &:hover {
        background: $purple600;
      }
    }
    &:hover {
      background: $purple100;
      border: 1px solid $purple100;
    }
  }

  .today {
    background: $purple100;
  }

  .day__month_btn, .month__year_btn, .next, .prev {
    &:hover {
      background: #f4f0fa !important;
    }
  }
}

.ar-datepicker {
  min-height: 40px;
  position: relative;
  max-height: 100%;
  .ar-datepicker-input {
    &.focus {
      border: 1px solid $green500;
      box-shadow: 0 0 0 3px $green200;
    }
  }
  .datepicker-arrow {
    position: absolute;
    height: 6px;
    width: 9px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    transition: transform 0.3s linear;
    color: $blueGrey700;
  }
}
</style>
