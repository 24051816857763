<template>
  <div
    :class="{
      'rate-container': true,
      'multiple': multiple,
      'link': link,
    }"
    :data-test-id="rateTestId"
  >
    <ar-text
      class="header"
      size="30px"
      :text="`${rate}%`"
      multiple-lines
    />
    <ar-text
      size="16px"
      :text="formattedValue"
      weight="normal"
      multiple-lines
      :style="{
        color: $arStyle.color.skyBlueGrey700,
      }"
    />
    <ar-text
      class="u-margin-top-3"
      size="16px"
      :text="title"
      weight="normal"
      multiple-lines
      :style="{
        color: $arStyle.color.skyBlueGrey700,
      }"
    />
  </div>
</template>

<script>
import { toSlug } from '@/utils/helpers';
import accounting from 'accounting';

export default  {
  name: 'PercentageCard',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    rate() {
      const percentage = this.total === 0 ? 0 : (this.value / this.total) * 100;
      return percentage < 10 && percentage > 0 ? percentage.toFixed(1) : Math.trunc(percentage);
    },

    formattedValue() {
      return accounting.formatNumber(this.value)
    },

    rateTestId() {
      return `overview-rate-${toSlug(this.title)}`;
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
.rate-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
  