<template>
  <div
    class="settings-users"
  >
    <am2-user-account-modal
      :is-show="isUserAccountModalOpen"
      @cancel="closeEditModal"
      :data="accountBeingModified"
      @handleUserRoleFilterInput="handleUserRoleFilterInput"
      @confirm="handleUpdateUserAccount"
    />
    <am2-invite-account-modal
      :is-show="isInviteAccountModalOpen"
      @cancel="closeInviteModal"
      @handleUserRoleFilterInput="handleUserRoleFilterInput"
      @confirm="handleInviteUserAccount"
    />
    <div
      class="headings u-margin-bottom-7"
    >
      <div>
        <ar-text
          size="md"
          text="User management"
          class="u-margin-bottom-2"
        />


      <ar-text
        size="xs"
        text="Create, edit and delete users"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
        />
      </div>
      <div>
        <ar-simple-button
          text="New User"
          icon-name="circle-plus"
          icon-distance="10px"
          :style="{width: '120px'}"
          @click="handleInviteUserButtonPress"
        />
      </div>
    </div>
    <am2-table
      ref="table"
      class="users-table"
      :heads="decoratedHead"
      :rows="users"
      :loading="isFetchingUsers"
      has-sticky-header
    >
      <div
        slot="user"
        slot-scope="{ data: { firstName, lastName, emailAddress } }"
        class="cell"
      >
        <div class="subtitle">
          <ar-text
            v-if="firstName || lastName"
            class="name-text u-margin-bottom-2"
            size="xs"
            :text="`${firstName || ''} ${lastName || ''}`"
          />
          <ar-text
            class="name-text"
            :size="firstName || lastName ? 'xxs' : 'xs'"
            :text="`${emailAddress}`"
            :style="{
              color: $arStyle.color.skyBlueGrey700
            }"
          />
        </div>
      </div>
      <div
        slot="role"
        slot-scope="{ data: { role, isInvite, inviteStatus } }"
        class="cell"
      >
        <am2-tag
          v-if="!!isInvite"
          class="tag"
          type="purple"
          :text="inviteStatus"
          :style="{
             textTransform: 'capitalize',
             marginRight: '16px'
          }"
        />
        <ar-text
          size="xs"
          :text="`${role}`"
          align="right"
        />
      </div>
      <div
        slot="menu"
        slot-scope="{ data }"
        :class="['menu-cell']"
      >
        <am2-icon-button-dropdown
          v-if="!data.isInvite || isUserManagementPermitted"
          :icon-props="{
            name: 'menu',
          }"
          :items="menuOptions(data)"
          @select="(item) => handleUserActionSelect(item.eventName, data)"
        />
      </div>
    </am2-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { sanitizeHtmlChild } from '@/utils/html-element/';
import { defaultSettingsPath } from "@/utils/helpers";



const tableHeadings = [
  {
    name: 'User',
    key: 'user',
    format: 'user',
    attributeKeys: 'user',
  },
  {
    name: 'Role',
    key: 'role',
    format: 'role',
    attributeKeys: 'role',
  },
  {
    name: '',
    key: 'menu',
    format: 'menu',
  },
  // Not yet supported for now, but will be in the future.
  // {
  //   name: 'Last active',
  //   key: 'lastActive',
  //   format: 'lastActive',
  //   attributeKeys: 'lastActive',
  // },
];

export default {

  data() {
    return {
      isUserAccountModalOpen: false,
      isInviteAccountModalOpen: false,
      accountBeingModified: null,
    };
  },

  computed: {
    ...mapState({
      currentUserAccount: state => state.auth.account,
      promoterUsers: state => state.auth.users,
      invitees: state => state.auth.invitees,
      isFetchingUsers: state => state.auth.isFetchingUsers,
      promoterUserRoles: state => state.auth.promoterUserRoles,
      defaultUserRoles: state => state.auth.defaultUserRoles,
    }),
    ...mapGetters({
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    decoratedHead() {
      return tableHeadings.map((item, index) => {
        let width;
        if (item.key === 'name') {
          width = 230;
        } else if (item.key === 'role') {
          width = 230;
        } else if (item.key === 'menu') {
          width = 30;
        } else {
          width = 136;
        }
        return {
          ...item,
          width,
          align: index == 0 ? 'left' : 'right',
        };
      });
    },

    users() {
      if (!this.promoterUsers) {
        return [];
      }

      const mappedUsers = this.promoterUsers.map((promoterUser) => {
        const role = this.extractRole(promoterUser.defaultUserRoleOid, promoterUser.promoterUserRoleOid)
        return {
          ...promoterUser,
          isInvite: false,
          inviteStatus: null,
          role: role ? role.name : null,
        }
      });
      const mappedInvitees = this.invitees?.map(invitee => {
        const role = this.extractRole(invitee.defaultUserRoleOid, invitee.promoterUserRoleOid)
        return {
          ...invitee,
          firstName: '',
          lastName: '',
          isInvite: true,
          inviteStatus: invitee.status,
          role: role ? role.name : null,
        }
      })

      if (!mappedInvitees || mappedInvitees.length === 0) return mappedUsers;
      return mappedUsers.concat(mappedInvitees)
    },

    isUserManagementPermitted() {
      return this.isPermissionEnabled(['user-management'])
    },

    isEditOwnerPermitted() {
      return this.isPermissionEnabled(['edit-owner'])
    },
  },

  async created() {
    const success = await this['auth/FETCH_PERMISSIONS']()

    if (!!success) {
      if (!this.isUserManagementPermitted) {
        this.$router.push({
          path: defaultSettingsPath(this.currentUserAccount.oid),
        });
      }
    }

    this['auth/FETCH_DEFAULT_USER_ROLES']({});
    this['auth/FETCH_PROMOTER_USER_ROLES']({});
  },

  async mounted() {
    if (!!this.defaultUserRoles) {
      const arepAdminRole = this.defaultUserRoles.find(role => role.name === 'AREP Admin')
      this['auth/FETCH_PROMOTER_ACCOUNTS']({ arepAdminOid: arepAdminRole ? arepAdminRole.oid : null});
    }
    this['auth/FETCH_INVITEES']()
  },

  watch: {
    defaultUserRoles (val) {
      if (!!val) {
        const arepAdminRole = val.find(role => role.name === 'AREP Admin')
        this['auth/FETCH_PROMOTER_ACCOUNTS']({ arepAdminOid: arepAdminRole ? arepAdminRole.oid : null});
      }
    },
  },

  methods: {
    ...mapActions([
      'auth/FETCH_PROMOTER_ACCOUNTS',
      'auth/FETCH_DEFAULT_USER_ROLES',
      'auth/FETCH_PROMOTER_USER_ROLES',
      'OPEN_DELETE_MODAL',
      'auth/UPDATE_PROMOTER_ACCOUNT',
      'auth/DELETE_PROMOTER_ACCOUNT',
      'auth/FETCH_PERMISSIONS',
      'auth/SEND_ACCOUNT_INVITE',
      'auth/FETCH_INVITEES',
      'auth/DELETE_INVITE',
      'auth/RESEND_INVITE',
    ]),

    extractRole(defaultUserRoleOid, promoterUserRoleOid) {
      let role = null

      if (!defaultUserRoleOid && !!this.promoterUserRoles) {
        role = this.promoterUserRoles.find((promoterUserRole) => {
          return promoterUserRole.oid === promoterUserRoleOid
        })
      } else if (!promoterUserRoleOid && !!this.defaultUserRoles) {
        role = this.defaultUserRoles.find((defaultUserRole) => {
          return defaultUserRole.oid === defaultUserRoleOid
        })
      }

      return role
    },

    determineName(fName, lName) {
      let finalName = `${fName}${fName && lName ? ' ' : ''}${lName}`;
      return sanitizeHtmlChild(finalName || '');
    },

    menuOptions(item) {
      if (item.isInvite) {
        return [
          {
            name: 'Resend',
            eventName: 'resend-invite',
            disabled: !this.isUserManagementPermitted
          },
          {
            name: 'Delete',
            eventName: 'delete-invite',
            disabled: !this.isUserManagementPermitted,
            typography: {
              style: {
                color: this.$arStyle.color.red500,
              },
            }
          }
        ]
      } else {
        let menu = [
          {
            name: 'Edit',
            eventName: 'edit',
            disabled: item.role === 'Owner' && !this.isEditOwnerPermitted
          },
        ]
        if (item.role !== 'Owner' && item.oid !== this.currentUserAccount.oid && this.isUserManagementPermitted) {
          menu.push({
            name: 'Delete',
            eventName: 'delete',
            typography: {
              style: {
                color: this.$arStyle.color.red500,
              },
            },
          })
        }
        return menu
      }

    },

    async handleUserActionSelect(action, data) {
      this.accountBeingModified = data
      if (action === 'edit') {
        this.isUserAccountModalOpen = true;
      } else if (action === 'delete') {
        const ans = await this['OPEN_DELETE_MODAL']({
          title: `Are you sure you want to delete ${this.determineName(this.accountBeingModified.firstName, this.accountBeingModified.lastName)}?`,
          messageHtml: `Deleting this user will revoke access to Audience Republic.`,
          confirmButtonText: 'Delete',
          validation: {
            question: 'Type DELETE',
            answer: 'DELETE',
          },
          deleteText: 'Delete user',
          hideCancelButton: true,
        });

        if (ans) {
          this.deletePromoterAccount()
        }
      } else if (action === 'resend-invite') {
        const res = await this['auth/RESEND_INVITE'](data.emailAddress);
        if (res) {
          this.$arNotification.push({ type: 'success', message: `A new invite has been sent to ${data.emailAddress}` });
        }
      } else if (action === 'delete-invite') {
        await this['auth/DELETE_INVITE']({
          inviteOid: data.oid
        });
        this['auth/FETCH_INVITEES']();
        this.$arNotification.push({ type: 'success', message: `Invitation has been deleted` });
      }
    },

    closeEditModal() {
      this.isUserAccountModalOpen = false;
      this.accountBeingModified = null;
    },

    handleUserRoleFilterInput(searchString) {
      this['auth/FETCH_DEFAULT_USER_ROLES']({ searchString });
      this['auth/FETCH_PROMOTER_USER_ROLES']({ searchString });
    },

    handleUpdateUserAccount(data) {
      this.isUserAccountModalOpen = false;
      const success = this['auth/UPDATE_PROMOTER_ACCOUNT']({
        accountOid: data.oid,
        promoterAccountObj: data,
      })

      if (success) {
        this.$arNotification.push({ type: 'success', message: `Account updated successfully.` });
      }
    },
    async deletePromoterAccount() {
      await this['auth/DELETE_PROMOTER_ACCOUNT']({
        accountOid: this.accountBeingModified.oid,
      })
      const arepAdminRole = this.defaultUserRoles.find(role => role.name === 'AREP Admin')
      this['auth/FETCH_PROMOTER_ACCOUNTS']({ arepAdminOid: arepAdminRole ? arepAdminRole.oid : null});
    },
    handleInviteUserButtonPress() {
      this.isInviteAccountModalOpen = true;
    },
    closeInviteModal() {
      this.isInviteAccountModalOpen = false;
    },
    async handleInviteUserAccount(val) {
      this.isInviteAccountModalOpen = false;
      const { emailAddress, defaultUserRoleOid, promoterUserRoleOid } = val;
      await this['auth/SEND_ACCOUNT_INVITE']({
        emailAddress,
        defaultUserRoleOid,
        promoterUserRoleOid
      })
      this['auth/FETCH_INVITEES']();
    }
  },
};
</script>

<style lang="scss" scoped>
.settings-users {
  max-width: 800px;
  margin: 59px auto 0;

  .headings {
    display: flex;
    justify-content: space-between;
  }

  .users-table {

    .cell {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 12px;
      justify-content: flex-end;

      &-left {
        justify-content: flex-start;
      }

      .subtitle {
        flex-direction: column;
        width: 100%;
      }
    }



    .menu-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .preview-button {
        visibility: hidden;
      }
    }



    .name-text {
      flex-grow: 1;
      max-width: 100%;
      overflow: hidden;
      >>> a {
        color: $blueGrey800;
      }
    }


  }

}
</style>
