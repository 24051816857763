<template>
  <div class="copy-input">
    <ar-input
      ref="input"
      :value="fullLink"
      :style="decoratedInputStyles"
      readonly/>
    <div class="copy-input__button" @click="handleInputLabelClick" role="button">
      <ar-text
        :text="copyLinkLabel"
        size="xs"
        weight="bold"
        :style="{
          color: hasCopied ? $arStyle.color.green500 : $arStyle.color.purple500,
        }"
      />
    </div>
  </div>
</template>


<script>
import { copyToClipboard } from '@/utils/html-element';

export default {
  name: 'CopyInput',

  props: {
    value: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    inputStyles: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      realVal: this.value,
      hasCopied: false,
    }
  },

  computed: {
    fixedPrefix() {
      if (this.size === 'small') {
        return this.prefix.replace('https://', '');
      } else {
        return this.prefix;
      }
    },
    copyLinkLabel() {
      if (this.hasCopied) {
        return 'Copied';
      } else {
        return 'Copy Link';
      }
    },
    fullLink() {
      return `${this.prefix || ''}${this.realVal || ''}`;
    },

    decoratedInputStyles() {
      return {
        backgroundColor: this.$arStyle.color.skyBlueGrey300,
        ...this.inputStyles,
      };
    },
  },

  watch: {
    value: {
      handler(val) {
        this.realVal = val;
      },
      immediate: true,
    },
    realVal() {
      this.hasCopied = false;
    },
  },

  methods: {

    handleInputLabelClick() {
      copyToClipboard(this.fullLink);
      this.hasCopied = true;

      // Reset copy status
      setTimeout(() => {
        this.hasCopied = false;
      }, 5000);
    },
  },
}
</script>

<style lang="scss" scoped>
.copy-input {
  display: flex;
  align-items: center;
  max-width: 470px;
  width: 100%;

  &__button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -4px;
    padding: 0 16px;
    border-radius: 0 4px 4px 0;
    border: 1px solid $skyBlueGrey500;
    background: #FFFFFF;
    cursor: pointer;
  }
}
</style>
