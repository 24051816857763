<template>
  <article class="title-value-list-container">
    <div
      ref="invisible-modal"
      class="invisible-modal"
      :style="{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        zIndex: $arStyle.zIndex.globalRegular,
        display: !noActiveIndex ? 'block' : 'none',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      }"
      @click.stop.prevent="handleInvisibleModalClick"
    />
    <am2-heading
      type="h1"
      size="md"
      class="section-heading"
      :title="title"
    />
    <div
      v-if="!items || items.length === 0"
    >

      <ar-text
        size="xs"
        text="No custom fields"
        data-test-id="title-value-list-no-custom-fields"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
          padding: '0 20px 0 20px'
        }"
      />

    </div>
    <am2-column-simple-form
      ref="simple-form"
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :icon-name="item.icon"
      :placeholder="item.modifiable ? `Add ${item.title}` : `No ${item.title}`"
      :parsed-data="returnParsedData(item)"
      :title="item.title"
      :disabled="!item.modifiable"
      :opened="activeFormIndex === itemIndex"
      @open="handleFormOpen(itemIndex)"
      @submit="handleFormSubmit(item, itemIndex)"
      @cancel="handleSimpleFormCancel()"
      :style="{
        position: 'relative',
        zIndex: !noActiveIndex ? $arStyle.zIndex.globalRegular + 1 : null,
      }"
    >
      <div v-if="activeFormIndex === itemIndex" slot="fields">
        <ar-input
          v-if="item.input.type === 'String'"
          type="text"
          :placeholder="item.input.placeholder"
          input-font-size="xs"
          :id="item.input.name"
          :autocomplete="item.input.name"
          :key="itemIndex + item.input.name"
          :style="{
              height: '42px',
            }"
          :value="temporaryValues[itemIndex]"
          @input="(val) => modifyData(item.input.name, val, itemIndex)"
        />
        <ar-input
          v-else-if="item.input.type === 'Number'"
          type="number"
          :placeholder="item.input.placeholder"
          input-font-size="xs"
          :id="item.input.name"
          :autocomplete="item.input.name"
          :key="itemIndex + item.input.name"
          :style="{
              height: '42px',
            }"
          :value="temporaryValues[itemIndex]"
          @input="(val) => modifyData(item.input.name, val, itemIndex)"
        />
        <ar-field
          v-else-if="item.input.type === 'Date'"
          class="u-width-100-percent"
          :error-messages="[veeErrors.first(item.input.name)]"
        >
          <ar-date-input
            v-model="temporaryValues[itemIndex]"
            v-validate="'date-of-birth'"
            :data-vv-name="item.input.name"
            data-vv-as="date"
            :is-birthday="false"
          />
        </ar-field>
        <ar-simple-select
          v-else-if="item.input.type === 'Select' && !!item.input.options"
          :items="item.input.options"
          placeholder="Select One"
          :style="{
              height: '42px',
              width: '100%',
            }"
          enable-clear
          :default-select-index="defaultSelectIndexes[itemIndex]"
          :key="itemIndex + item.input.name"
          @select="selection => { modifyData(item.input.name, selection.name, itemIndex) }"
          @clear="() => { modifyData(item.input.name, null, itemIndex) }"
        />
        <ar-multi-select
          v-else-if="item.input.type === 'Multiselect' && !!item.input.options"
          :items="temporaryInputOptions[itemIndex].options"
          placeholder="Select multiple"
          :key="itemIndex + item.input.name"
          search-placeholder="Search options"
          :default-value="temporaryValues[itemIndex] || []"
          @selectedValues=" (selection) => { modifyMultiSelect(item.input.name, selection, itemIndex) }"
        />
      </div>
    </am2-column-simple-form>
    <ar-link-button
      v-if="showExpandableLinkButton"
      class="u-margin-left-5 u-margin-top-2"
      :text="showMoreItems ? 'View less' : 'View more'"
      @click="toggleItemDisplay"
    />
  </article>
</template>

<script>
// For displaying a flat list of data
// Optionally allows editing of that data

import { countryList } from "@/utils/countries";
import moment from 'moment';
import { mapActions } from 'vuex';
import {clone} from "~/utils/helpers";

export default {
  name: 'TitleValueList',
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    deleteData: {
      type: Function,
      default: () => {},
    },
    saveData: {
      type: Function,
      default: () => {},
    },
    showMoreItems: {
      type: Boolean,
      default: false,
    },
    toggleCutoff: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      activeFormIndex: null, // Index of form currently being edited
      temporaryValues: {}, // Temporary values for field currently being edited
      temporaryInputOptions: {},
      defaultSelectIndexes: [], // Indexes for all selects
    }
  },

  watch: {
    items: {
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.calculateDefaultSelectIndexes();
          this.calculateTemporaryInputOptions();
        }
      },
      immediate: true,
    },
    temporaryValues: {
      handler(val, oldVal) {
        if (oldVal !== val) {
          this.calculateDefaultSelectIndexes();
          this.calculateTemporaryInputOptions();
        }
      }
    }
  },

  computed: {
    noActiveIndex() {
      return this.activeFormIndex === null || this.activeFormIndex === false;
    },
    showExpandableLinkButton() {
      return this.items && this.items.length >= this.toggleCutoff
    },
  },

  mounted() {
    this.refreshTemporaryValues();
  },

  beforeDestroy() {
    this.removeInvisibleModalFromBody();
  },

  methods: {
    ...mapActions([
      'SHOW_MULTIPLE_BUTTON_MODAL',
    ]),
    calculateDefaultSelectIndexes() {
      this.defaultSelectIndexes = this.items.map ( item => {
        const { options, type, savedData: savedValues } = item.input;

        if (!savedValues || savedValues.length === 0) return -1;
        if (type === "Select") {
          return options.findIndex (option => option.name === savedValues[0]);
        } else if (type === "MultiSelect")  {
          return options
            .map((option, idx) => ({ value: option.name, index: idx }))
            .filter(option => savedValues.includes(option.value))
            .map(option => option.index);
        } else {
          return -1;
        }
      })
    },
    calculateTemporaryInputOptions() {
      const inputOptionsByCf = this.items.map( (item, idx) => {
        const input = item.input;
        if (input.type !== "Multiselect") return [];

        const defaultItems = clone(input.options);
        const tempValues = this.temporaryValues[idx] || [];
        const tempInputOptions = defaultItems.map(defaultItem => ({
          ...defaultItem,
          selected: tempValues.includes(defaultItem.name)
        }));

        return {
          ...input,
          options: tempInputOptions
        }
      })
      this.temporaryInputOptions = inputOptionsByCf;
    },
    // Just attach the dialog to body level, remove it when we don't need it anymore
    attachInvisibleModalToBody() {
      document.body.appendChild(this.$refs['invisible-modal']);
    },
    removeInvisibleModalFromBody() {
      if (!!this.$refs['invisible-modal'] && this.$refs['invisible-modal']?.parentElement?.nodeName === 'BODY') {
        document.body.removeChild(this.$refs['invisible-modal']);
      }
    },

    // Assume that each "item" will only have one input to assign to it, rather than a collection of them.
    refreshTemporaryValues() {
      if (!this.items) return;
      const newItems = this.items.map( item => {
        const firstInput = item.input;
        return firstInput.savedData || null;
      });
      this.temporaryValues = newItems;
    },

    // Formats a "DD/MM/YYYY" into "DD MMM YYYY (XX years old)" format
    // We validate the date before users save data, so we can say savedData is either null or complete
    returnPrettyDate(field) {
      if (!field.input.savedData) { return null; }
      const [dobYear, dobMonth, dobDay] = field.input.savedData.split('-');
      // Note - JS starts months at 0, but we store with Jan as 01 (obviously), so lets cater for that little dilemma by reducing value of month by 1
      const [dobYearInt, dobMonthInt, dobDayInt] = [parseInt(dobYear), parseInt(dobMonth) - 1, parseInt(dobDay)];

      return new Date(dobYearInt, dobMonthInt, dobDayInt).toDateString()
    },

    // Combines multiple inputs into a single space-separated string
    returnPrettyString(field) {
      if(!field) return null;
      if(!field.input) return null;
      const current = field.input;
      let string = ''
      string += current.savedData ? current.savedData.toString() : '';
      return string;
    },

    // Returns the data selected by a select field
    returnPrettySelectData(field) {
      if(!field?.input) return null;
      const current = field.input;

      const { savedData, options } = current
      const selectedValue = savedData && savedData[0].length > 0 ? savedData[0] : ''
      return options?.some( item => item.name === selectedValue) ? selectedValue.toString() : '';
    },

    // Returns the data selected by a multiselect field
    returnPrettyMultiselectData(field) {
      if(!field) return null;
      if(!field.input) return null;
      const current = field.input;
      return current.options.filter((currentOption) => {
        return current.savedData?.includes(currentOption.name)
      }).map((currentOption) => {
        return currentOption.name
      }).join(', ')
    },

    returnParsedData(field) {
      if(!field) return null;
      switch(field.outputAs) {
        case 'Date':
          return this.returnPrettyDate(field);
          break;
        case 'Select':
          return this.returnPrettySelectData(field);
          break;
        case 'Multiselect':
          return this.returnPrettyMultiselectData(field);
          break;
        default:
          return this.returnPrettyString(field);
          break;
      }
    },

    modifyData(keyName, newValue, index) {
      const tempVals = clone(this.temporaryValues);
      tempVals[index] = newValue;
      this.temporaryValues = tempVals;
      this.calculateDefaultSelectIndexes();
    },

    modifyMultiSelect(keyName, allVals, index) {
      const tempVals = clone(this.temporaryValues);
      const selected = allVals.filter(item => !!item.selected).map(item => item.name)
      tempVals[index] = selected;
      this.temporaryValues = tempVals;
      this.calculateDefaultSelectIndexes();
      this.calculateTemporaryInputOptions();
    },

    hasActiveFormUpdated() {
      if (this.activeFormIndex === null) {
        return false;
      }
      const item = this.items[this.activeFormIndex];
      let hasChanged = false;
      const input = this.items[this.activeFormIndex].input;
      const value = input.savedData;
      if (this.temporaryValues[this.activeFormIndex] !== value) hasChanged = true;
      // Sometimes values are stored as null, othertimes stored as "", so easier to just check for falsiness.
      if (!this.temporaryValues[this.activeFormIndex] && !value) hasChanged = false;
      return hasChanged;
    },

    async promptToCheckFormSave() {
      const shouldSave = await this.SHOW_MULTIPLE_BUTTON_MODAL({
        title: `Do you want to save changes?`,
        buttons: [
          {
            props: {
              text: 'Discard changes',
              outlined: true,
              style: {
                width: '196px',
              },
            },
            clickHandler: () => {
              return false;
            },
          },
          {
            props: {
              text: 'Save',
              outlined: false,
              style: {
                width: '196px',
              },
            },
            clickHandler: () => {
              return true;
            },
          }
        ],
      });
      if (shouldSave) {
        this.handleFormSubmit(this.items[this.activeFormIndex], this.activeFormIndex);
      } else {
        this.handleSimpleFormCancel();
      }
    },

    async handleInvisibleModalClick() {
      if (this.noActiveIndex || !this.items[this.activeFormIndex]) {
        this.removeInvisibleModalFromBody();
        return;
      }
      const hasChanged = this.hasActiveFormUpdated();
      if (!hasChanged) {
        this.handleSimpleFormCancel();
      } else {
        this.promptToCheckFormSave();
      }
    },

    handleFormOpen(formIndex) {
      if (this.hasActiveFormUpdated()) {
        this.promptToCheckFormSave();
        return;
      }
      this.activeFormIndex = formIndex;
      this.refreshTemporaryValues();
      this.attachInvisibleModalToBody();
    },

    handleSimpleFormCancel() {
      this.activeFormIndex = null;
      this.refreshTemporaryValues();
      this.removeInvisibleModalFromBody();
    },

    async handleFormSubmit(item, indexValue) {
      const isValid = await this.$validator.validate();
      if (!isValid) { return; }

      this.activeFormIndex = null;
      const selectedTempValue = this.temporaryValues[indexValue];
      this.saveData(item, selectedTempValue);
      this.removeInvisibleModalFromBody();
      this.calculateDefaultSelectIndexes();
    },

    toggleItemDisplay() {
      this.$emit('toggleItemDisplay')
    }
  }
};
</script>

<style lang="scss" scoped>
.title-value-list-container {
  padding: 32px 12px;

  .section-heading {
    margin-bottom:24px;
    padding: 0 20px;
  }
}
</style>
