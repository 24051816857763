var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-role-select"},[_c('ar-select',{ref:"select",style:({
      height: '100%',
    }),attrs:{"items":_vm.selectItems,"enable-filter":"","filter-placeholder":_vm.filterPlaceholder,"data-test-id":"user-role-select-dropdown","layer":"global-highest","defaultSelectIndex":_vm.selectedUserRoleIndex,"disabled":_vm.disabled},on:{"select":_vm.handleSelect,"close":function () { _vm.$emit('close') },"filterStringInput":_vm.handleFilterStringInput},scopedSlots:_vm._u([{key:"item",fn:function(itemScope){return [(itemScope.item.isAction)?_c('div',{staticClass:"user-role-item",style:({
          background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
        }),attrs:{"data-test-id":("user-role-item-" + (itemScope.item.name))}},[_c('ar-icon',{staticClass:"plus-icon",attrs:{"name":"circle-plus","color":_vm.$arStyle.color.purple500,"height":"16px","width":"16px"}}),_vm._v(" "),_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.purple500,
            marginLeft: '8px',
          }),attrs:{"size":"xs","text":itemScope.item.name,"weight":"bold"}})],1):_c('div',{staticClass:"user-role-item u-margin-top-3 u-margin-bottom-3",style:({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '55px',
          padding: '0 15px',
          cursor: 'pointer',
          background: itemScope.index === _vm.selectedUserRoleIndex ? _vm.$arStyle.color.purple100 : null,
        }),attrs:{"data-test-id":("user-role-item-" + (itemScope.item.name))}},[_c('div',{style:({
            display: 'flex',
            alignItems: 'center',
            flexGrow: '1',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'flext-start',
            flexDirection: 'column',
          })},[_c('ar-text',{style:({
              width: 'auto',
              color: itemScope.index === _vm.selectedUserRoleIndex ? _vm.$arStyle.color.purple500 : _vm.$arStyle.color.blueGrey800,
            }),attrs:{"size":"xs","text":itemScope.item.name,"weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-2",style:({
              width: 'auto',
              color: _vm.$arStyle.color.blueGrey700,
            }),attrs:{"size":"xs","text":itemScope.item.description,"weight":"normal"}})],1)])]}}])},[_c('template',{slot:"selected-item"},[_c('div',{style:({
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        }),attrs:{"data-test-id":"user-role-select-out-of-scope-selected-item"}},[_c('ar-text',{style:({
            color: _vm.selectedUserRoleName ? _vm.$arStyle.color.blueGrey800 : _vm.$arStyle.color.blueGrey600,
          }),attrs:{"size":"xs","text":_vm.selectedUserRoleName || 'Select user role'}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }