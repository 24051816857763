<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Sent to"
      width="800px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="view-filters-modal"
    >
      <div slot="body" class="body">
        <div class="header">
          <div class="header-icon">
            <SendMessage
              height="32px"
              width="32px"
              :fill="$arStyle.color.purple500"
            />
          </div>
          <div class="heading-columns">
            <div class="heading-column left">
              <ar-text
                class="subheading"
                text="Sent to"
                size="14px"
                :style="{
                  color: `${$arStyle.color.skyBlueGrey700}`,
                }"
              />
              <ar-text
                class="heading"
                :text="messageListName"
                size="16px"
              />
            </div>
            <div class="heading-column right">
              <ar-text
                class="subheading"
                text="Filtered subscribers"
                size="14px"
                :style="{
                  color: `${$arStyle.color.skyBlueGrey700}`,
                }"
              />
              <ar-text
                class="heading"
                :text="recipientTotal"
                size="16px"
              />
            </div>
          </div>
        </div>
        <div class="filters">
          <am2-tag
            v-for="(block, blockIndex) in displayedFilters"
            :key="blockIndex"
            :type="block.type === 'condition' ? 'purple' : 'grey'"
            shape="rectangle"
            class="filter-tag"
            :text="block.text"
            v-tooltip="{
              content: block.text,
            }"
          />
        </div>
        <ar-link-button
          v-if="filterBlocks && filterBlocks.length > MIN_ITEMS_DISPLAYED"
          class="u-margin-top-4"
          :text="areFiltersTruncated ? 'View all' : 'View less'"
          @click="toggleViewAllLink"
        />
      </div>
      <div slot="footer" class="footer">
        <ar-link-button
          text="Go to list"
          @click="handleOpenList"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button text="Use filters" @click="handleViewFilteredAudience" />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import PencilIcon from '~/assets/icons/pencil.svg?inline';
import MailIcon from '~/assets/icons/mail.svg?inline';
import SendMessage from '~/assets/icons/send-message.svg?inline'
import { mapGetters, mapMutations } from 'vuex';
import accounting from 'accounting';

export default {
  name: 'ViewFiltersModal',
  components: {
    PencilIcon,
    MailIcon,
    SendMessage,
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    messageListName: {
      type: String,
      default: null
    },
    fansTotal: {
      type: Number,
      default: null
    },
    filters: {
      type: Object,
      default: null
    },
    messageOid: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      areFiltersTruncated: true,
      MIN_ITEMS_DISPLAYED: 12,
    };
  },

  watch: {
    opened(val) {
      if (val) {
        if (!this.filters?.conditions) {
          return;
        }

        this['segment/SET_SCRATCH_SEGMENT_FROM_FILTER']({
          changed: true,
          messageSegmentOid: this.messageOid,
          filter: this.filters
        });
      } else {
        this['segment/RESET_SCRATCH_SEGMENT']()
      }
    }
  },
  computed: {
    ...mapGetters({
      getSegmentConditionExpression: 'segment/getSegmentConditionExpression',
    }),

    filterBlocks() {
      if (!this.filters?.conditions) return [];

      let blocks = [];
      const filterConditionBlocks = this.filters.conditions.map(condition  => {
        return {
          ...condition,
          text: this.getSegmentConditionExpression(condition),
          type: 'condition',
        }
      });

      const filterLogicBlocks = this.filters.logic.map(logic => ({
        ...logic,
        text: logic,
        type: 'logic',
      }));

      for (let i = 0; i < filterConditionBlocks.length; i += 1) {
        blocks.push(filterConditionBlocks[i]);
        if (filterLogicBlocks[i]) { blocks.push(filterLogicBlocks[i]); }
      }

      return blocks;
    },

    displayedFilters() {
      if (!this.filterBlocks || this.filterBlocks.length === 0) {
        return []
      }

      return this.areFiltersTruncated ? this.filterBlocks.slice(0, this.MIN_ITEMS_DISPLAYED) : this.filterBlocks
    },

    recipientTotal() {
      return accounting.formatNumber(this.fansTotal || 0)
    }
  },

  methods: {
    ...mapMutations([
      'segment/SET_SCRATCH_SEGMENT_FROM_FILTER',
      'segment/RESET_SCRATCH_SEGMENT',
    ]),

    handleModalClose() {
      this.$emit('close');
    },
    handleOpenList() {
      this.$emit('listClick');
    },
    handleViewFilteredAudience() {
      this.$router.push({ path: '/audience' });
    },
    toggleViewAllLink() {
      this.areFiltersTruncated = !this.areFiltersTruncated
    },
  },

  created() {
    this['segment/RESET_SCRATCH_SEGMENT']()
  },
};
</script>

<style lang="scss" scoped>
.view-filters-modal {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px 32px;


    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;


      .header-icon {
        width: 72px;
        height: 72px;
        background-color: $skyBlueGrey300;
        border-radius: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
  
      }

      .heading-columns {
        display: flex;
        width: calc(100% - 72px - 24px);
        margin-left: 24px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .heading-column {
          display: flex;
          flex-direction: column;

          .heading {
            margin-top: 6px;
          }
  
          &.right {
            justify-content: flex-end;
            align-items: flex-end;
          }
        }
      }

    }

    .filters {
      max-width: 100%;
      margin-top: 32px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 8px;
      column-gap: 6px;
      flex-wrap: wrap;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    padding: 0 30px;
  }
}
.icon {
  color: #8e97a6;
}
.sub-heading {
  color: #8e97a6;
}
</style>
