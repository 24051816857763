<template>
  <div>
    <div class="input-search-wrapper input-container">
      <div :class="['input-arrowhead-wrapper']">
        <div
          class="input-selection-container"
          v-click-outside="handleClickOutsideEventsListContainer"
        >
          <div v-if="isDropdownOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
          <ar-input
            ref="senderLinkListInput"
            class="message-input send-to-input"
            :style="{
                  paddingLeft: isDropdownOpen ? '23px' : '0px'
                }"
            :placeholder="computedPlaceholder"
            @focus="handleInputFocus"
            @input="handleSearchInput"
            :value="inputValue"
          />
          <div class="link-arrowhead-wrapper" @click="handleLinkArrowClick">
            <ArrowHead
              class="send-to-input-dropdown-arrowhead"
              :class="isDropdownOpen && 'rotate'"
              height="8px"
              width="10px"
              :fill="$arStyle.color.skyBlueGrey700"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isDropdownOpen"
      :class="['list-items-wrapper event-items-wrapper', filteredList.length > 4 ? 'overflow' : '']"
    >
      <div
        v-infinite-scroll="loadMoreData"
        infinite-scroll-disabled="isInfiniteScrollDisabled"
        infinite-scroll-distance="10">
        <div
          v-for="item in filteredList"
          :id="`listItem-${item.oid}`"
          :key="item.oid"
          :class="[
              'link-list-item',
              'list-item',
            ]"
          @click="handleItemSelect(item)"
          :data-test-id="`link-list-item-${item.oid}`"
        >
          <ar-checkbox
            :ref="`search-multi-select-checkbox-${item.oid}`"
            v-model="selectedIdsLocal[item.oid]"
            class="dropdown-item-checkbox"
            :data-test-id="`search-multi-select-${item.oid}`"
          />
          <div class="dropdown-copy-wrapper">
            <slot :item="item"></slot>
          </div>
        </div>

      </div>

      <div v-if="isLoading" class="list-items-wrapper__loader">
        <am2-loading-spinner size="48px" />
      </div>
      <div v-if="!isEmptyResultsSearch" class="events-apply-deselect-wrapper">
        <div class="deselect-link-wrapper" @click="handleDeselectAll" :data-test-id="`deselect-all-events-link`">
          <ar-link-button class="deselect-link" text="Deselect" has-underline />
        </div>
        <div
          class="apply-button-wrapper"
          @click="handleApply"
          data-test-id="`apply-events-button`"
        >
          <ar-simple-button
            class="apply-button"
            text="Apply"
            weight="bold"
            :style="{
                  padding: '10px 16px 11px',
                  height: 'unset'
                }"
          />
        </div>
      </div>
      <div v-if="isEmptyResultsSearch" class="link-list-item list no-match">
        <ar-text
          class="list-name"
          :style="{ margin: '0 16px' }"
          size="xs"
          text="No matching results found"
          multiple-lines
          :max-lines="1"
          line-height="20px"
          weight="normal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import SearchIcon from '~/assets/icons/search.svg?inline'
import { debounce } from 'debounce'

export default {
  name: 'SearchMultiSelect',
  components: {SearchIcon, ArrowHead},

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    itemsList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select options',
    },
  },

  data() {
    return {
      isDropdownOpen: false,
      selectedIdsLocal: {},
      searchText: '',
    }
  },

  watch: {
    value(newVal) {
      if (newVal) {
        this.selectedIdsLocal = newVal.reduce((acc, oid) => {
          acc[oid] = true
          return acc
        }, {})
      }
    },
  },

  computed: {
    computedPlaceholder() {
      return this.placeholder
    },

    selectedIdsLocalArray() {
      return Object.keys(this.selectedIdsLocal).filter((key) => this.selectedIdsLocal[key])
    },

    selectionText() {
      if (!this.selectedIdsLocal) {
        return ''
      }

      if (this.selectedIdsLocalArray.length === 1) {
        return '1 option selected';
      } else if (this.selectedIdsLocalArray.length > 1) {
        return `${this.selectedIdsLocalArray.length} options selected`;
      } else {
        return '';
      }
    },

    inputValue() {
      return this.isDropdownOpen ? this.searchText : this.selectionText;
    },

    filteredList() {
      return this.itemsList
    },

    isEmptyResultsSearch() {
      return this.searchText.length !== 0 && this.filteredList.length === 0 && !this.isLoading
    },

    isInfiniteScrollDisabled() {
      return this.isLoading
    },
  },

  methods: {
    handleClickOutsideEventsListContainer(e) {
      if (!this.isDropdownOpen) return

      if (e.target.id === 'linkInput') return;

      let safeClasses = [
        'deselect-link-wrapper',
        'link-list-item list-item',
        'events-apply-deselect-wrapper',
        'apply-button-wrapper',
        'link-arrowhead-wrapper'
      ];
      if (safeClasses.includes(e.target.className)) return;

      this.isDropdownOpen = false;
    },

    handleDeselectAll() {
      this.selectedIdsLocal = {}
    },

    handleInputFocus() {
      this.isDropdownOpen = true;
    },

    handleSearchInput: debounce(function(text) {
      this.searchText = text;
      this.$emit('search', text)
    }, 300),

    handleLinkArrowClick() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.$refs.senderLinkListInput.focus();
      }
    },

    handleItemSelect(event) {
      this.$set(this.selectedIdsLocal, event.oid, !this.selectedIdsLocal[event.oid])
    },

    handleApply() {
      this.isDropdownOpen = false;
      this.$emit('select', this.selectedIdsLocalArray)
    },

    loadMoreData() {
      this.$emit('load-more')
    },
  }
}
</script>

<style lang="scss" scoped>
.input-search-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  .fake-input-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 17px 19px 17px 15px;
    border: 1px solid $blueGrey500;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    &.input-container {
      flex-flow: row wrap;

      .subheading {
        width: 100%;
        margin-top: 8px;
      }
    }

    &.invalid-warning {
      border: 1px solid $red500;
      box-shadow: 0px 0px 0px 3px $red400;
    }

    .fake-text-area-wapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }

    .arrowhead-wrapper {
      position: absolute;
      right: 0;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 20px;
      top: 0;

      & > * {
        pointer-events: none;
      }

      .send-to-input-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .input-arrowhead-wrapper {
    width: 100%;
    position: relative;
    cursor: pointer;

    .search-icon-wrapper {
      z-index: 1;
      height: 100%;
      position: absolute;
      left: 13px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .search-icon {
        opacity: 0.4;
      }
    }

    .tag-wrapper {
      border: 1px solid $skyBlueGrey500;
      padding: 11px 12px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .arrowhead-wrapper {
      position: absolute;
      right: 0;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 20px;
      top: 0;

      & > * {
        pointer-events: none;
      }

      .send-to-input-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    .search-icon-wrapper {
      z-index: 1;
      height: 100%;
      position: absolute;
      left: 13px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .search-icon {
        opacity: 0.4;
      }
    }

    .sender-arrowhead-wrapper,
    .link-arrowhead-wrapper {
      position: absolute;
      right: 0;
      height: 50px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 20px;
      top: 0;

      & > * {
        pointer-events: none;
      }

      .send-to-input-dropdown-arrowhead {
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .filter-button-wrapper {
    position: relative;

    .filter-count-circle-wrapper {
      position: absolute;
      top: 1px;
      right: 1px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      background: $purple500;
      transform: translateX(50%) translateY(-50%);
      color: white;
      z-index: $zIndexHigh;

      .filter-recipient-count {
        position: relative;
        font-size: 10px;
        line-height: 10px;
        top: -1.5px;
        left: 0.2px;
      }
    }
  }
}

.list-items-wrapper {
  border: 1px solid $blueGrey500;
  border-radius: 4px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
  /*width: calc(100% - 100px);*/
  max-width: 710px;
  background: white;
  position: relative;
  top: 0px;
  z-index: 1;
  max-height: 181px;
  overflow: auto;

  &.event-items-wrapper {
    max-height: 232px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 32px 0;
  }

  .list-item {
    padding: 12px 20px;
    border-bottom: 1px solid $blueGrey500;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background: #f5f1ff;
    }

    &.no-match {
      cursor: default;
    }

    &:last-child {
      border-bottom: unset;
    }

    & > * {
      pointer-events: none;
    }
  }

  .link-list-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: #f5f1ff;
    }

    &:first-child {
      border-top: unset;
    }

    &.no-match {
      cursor: default;

      &:hover {
        background: white;
      }
    }

    & > * {
      pointer-events: none;
    }

    .dropdown-item-checkbox {
      margin-top: 4px;
      margin-left: 16px;
    }

    .dropdown-copy-wrapper {
      pointer-events: none;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin-left: 15px;

      .event-date-location-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .list-date {
          color: $skyBlueGrey700;
          margin-right: 5px;
        }
        .list-location {
          color: $skyBlueGrey700;
        }
      }

      &.tag-copy-wrapper {
        width: 100%;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 15px;
      }
    }

    &.selected {
      background: #f5f1ff;
    }
  }

  .events-apply-deselect-wrapper {
    position: sticky;
    bottom: 0;
    padding: 16px 12px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dcdee4;
    background: white;

    .deselect-link-wrapper {
      cursor: pointer;

      & > * {
        pointer-events: none;
      }
    }

    .apply-button-wrapper {
      cursor: pointer;

      & > * {
        pointer-events: none;
      }
    }
  }
}

.event-items-wrapper {
  max-height: 329px;
}

.events-apply-deselect-wrapper {
  position: sticky;
  bottom: 0;
  padding: 16px 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dcdee4;
  background: white;

  .deselect-link-wrapper {
    cursor: pointer;

    & > * {
      pointer-events: none;
    }
  }

  .apply-button-wrapper {
    cursor: pointer;

    & > * {
      pointer-events: none;
    }
  }
}
</style>

