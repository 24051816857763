<template>
  <section 
    class="u-margin-top-6"
    data-test-id="company-page-events"
    v-infinite-scroll="fetchEvents"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="50"
  >
    <div
      v-if="isFetchingEvents && events.length === 0"
      class="loading-container"
    >
      <am2-loading-spinner size="48px" />
    </div>
    <div v-else-if="events.length === 0">
      <ar-text
        size="xs"
        text="No events found"
        weight="bold"
      />
    </div>
    <div v-else>

        <div class="overall-details">
          <ar-text
            v-if="eventsCount"
            size="xs"
            :text="eventsShowingString"
            allow-html
          />
        </div>
        <div
          :class="[
            'cards-container',
            $arMediaQuery.pageContent.minWidth('sm') ? eventsFilterGroupButton : 'tiles',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
        >
          <am2-event-card
            v-for="event in events"
            :key="event.oid"
            :event="event"
            layout="admin"
            :show-as="setShowAs"
            :integration-is-possible="false"
          />
        </div>
      </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import accounting from 'accounting';

export default {
  name: 'CompanyEvents',

  computed: {
    ...mapState({
      events: state => state.event.events,
      eventsCount: state => state.event.totalEventsCount,
      isFetchingEvents: state => state.event.isFetchingEvents,

      eventsFilterGroupButton: state => state.event.eventsFilterGroupButton,
    }),

    setShowAs() {
      return this.$arMediaQuery.pageContent.maxWidth('xs') ? 'tiles' : this.eventsFilterGroupButton
    },

    formattedEventsLength() {
      return this.eventsCount ? accounting.format(this.events?.length || 0) : 0;
    },

    formattedTotalEventsCount() {
      return this.eventsCount ? accounting.format(this.eventsCount) : 0;
    },

    eventsShowingString() {
      if (this.events.length === 0 || this.eventsCount === 0) return '';
      return `Showing <b>${ this.formattedEventsLength }</b> of <b>${ this.formattedTotalEventsCount }</b> ${ this.events.length > 1 ? ' events' : ' event'}`
    },
  },

  mounted() {
    this.fetchEvents(true)
  },

  beforeDestroy() {
    this['event/RESET_EVENTS']
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
      'event/FETCH_EVENT_COUNT',
    ]),

    ...mapMutations([
      'event/RESET_EVENTS',
    ]),

    async fetchEvents(reload=false) {
      this['event/FETCH_MORE_EVENTS']({
        reload,
        orderBy: 'datecreated',
        promoterOid: this.$route.params.oid,
      })
      this['event/FETCH_EVENT_COUNT']({
        promoterOid: this.$route.params.oid,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cards-container {
  display: grid;
  grid-template-columns: 380px 380px 380px;
  grid-gap: 30px;
  width: -moz-max-content;
  width: max-content;
}

.overall-details {
  margin-bottom: 30px;
}
</style>