<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="message-list-settings-modal"
      :header="editSenderHeader"
      width="600px"
      :has-back-link="!senderOnlyMode && showEmailSenderForm"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
      @back="handleBackFromSenderEdit"
    >
      <div slot="body" class="body">
        <div
          :class="[
            'modal-content',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >

          <!-- Filter info -->
          <div v-if="isAutoList" class="u-padding-top-1 u-padding-bottom-7">
            <div class="header-section">
              <am2-tag
                class="tag"
                text="Dynamic"
                type="purple"
                v-tooltip.top="{
                  content: 'Existing and future contacts that match your segment will automatically be added to this list',
                }"
              />
              <ar-text
                class="u-margin-left-2"
                size="sm"
                allow-html
                multiple-lines
                :text="filterName"
              />
            </div>
          </div>

          <ar-text
            v-if="!senderOnlyMode"
            size="xs"
            class="u-margin-bottom-3 u-margin-top-1"
            text="Which messaging channels should be available for this list?"
          />

          <!-- Email -->
          <div
            v-if="arEnableEmailSending"
            class="settings-card"
            data-test-id="settings-card-email"
            @click="emailSettingsCardClicked"
            :style="{
              cursor: 'pointer',
            }"
          >
            <div class="header-section">
              <ar-checkbox
                v-if="!senderOnlyMode"
                ref="emailCheckbox"
                :value="editEmailEnabled"
                @input="value => {
                  editEmailEnabled = value
                }"
                :style="{
                  pointerEvents: 'none',
                }"
              />
              <ar-icon
                name="email"
                width="18.5px"
                class="u-margin-left-5"
                :color="$arStyle.color.email"
              />
              <ar-text
                size="xs"
                text="Email"
                weight="bold"
                :style="{
                  marginLeft: '16.33px',
                }"
              />
            </div>

            <!-- Email Sender Selection Menu -->
            <div
              class="additional-info-section"
              v-if="editEmailEnabled && !showEmailSenderForm"
              @click="event => event.stopPropagation()"
            >
              <div class="input-section">
                <div class="input">
                  <am2-message-sender-select
                    :value="senderOid || null"
                    emit-edit
                    data-test-id="email-message-sender-select"
                    @select="handleSenderSelect"
                    @editClick="handleSenderEdit"
                    @createClick="handleSenderCreateNew"
                  />

                  <section
                    v-if="currentlySelectedEmailMessageSender && !currentlySelectedEmailMessageSender.verified"
                    class="email-unverified"
                  >
                    <!-- Your From Email is awaiting verification, you Rebel scum -->
                    <ar-snackbar
                      type="general"
                      message="Please verify this email address. <a>Resend verification email</a>."
                      @anchorClick="retryEmailVerification"
                      :style="{ width: '100%' }"
                    />
                  </section>
                </div>
              </div>
            </div>
            <!-- Email Sender Editing Menu -->
            <div
              class="edit-sender-section"
              v-if="showEmailSenderForm"
              @click="event => event.stopPropagation()"
              :style="{
                cursor: 'default'
              }"
            >
              <div
                class="input-section"
              >
                <ar-text
                  class="label"
                  size="xs"
                  text="From Email"
                  weight="bold"
                />
                <div class="input">
                  <ar-input
                    :value="scratchEmailSender.property"
                    @input="(val) => { handleScratchEmailSenderPatch({ property: val }) }"
                    :disabled="showEmailSenderForm && !creatingNewSender"
                    placeholder=""
                    v-validate="{
                      required: true,
                      email: true
                    }"
                    autocomplete="email"
                    data-vv-name="emailAddress"
                    data-vv-as="email address"
                  />
                  <ar-state-message
                    v-if="veeErrors.has('emailAddress')"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    :text="veeErrors.first('emailAddress')"
                  />
                  <ar-state-message
                    v-if="restrictedDomainError"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    text="Email domain is not supported. Please use a different email address."
                  />
                </div>
              </div>
              <div
                class="input-section"
              >
                <ar-text
                  class="label"
                  size="xs"
                  text="From Name"
                  weight="bold"
                />
                <div class="input">
                  <ar-input
                    :value="scratchEmailSender.additionalInfo.senderName"
                    @input="(val) => { handleScratchEmailSenderPatch({ additionalInfo: { senderName: val } }) }"
                    placeholder=""
                    v-validate="`required`"
                    data-vv-name="senderName"
                    autocomplete="organization"
                    data-vv-as="sender name"
                  />
                  <ar-state-message
                    v-if="veeErrors.has('senderName')"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    :text="veeErrors.first('senderName')"
                  />
                </div>
              </div>
              <div class="input-section">
                <ar-text
                  class="label"
                  size="xs"
                  text="Business Address"
                  weight="bold"
                />
                <div class="input">
                  <ar-input
                    :value="scratchEmailSender.additionalInfo.businessAddress"
                    @input="(val) => { handleScratchEmailSenderPatch({ additionalInfo: { businessAddress: val } }) }"
                    placeholder=""
                    v-validate="`required`"
                    auotfill="address"
                    data-vv-name="businessAddress"
                    data-vv-as="business address"
                  />
                  <ar-state-message
                    v-if="veeErrors.has('businessAddress')"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    :text="veeErrors.first('businessAddress')"
                  />
                </div>
              </div>

              <div
                v-if="showEmailSenderForm && !creatingNewSender"
                class="u-display-flex u-justify-content-center u-align-items-center u-padding-bottom-3"
              >
                <ar-icon
                  name="exclamation-mark"
                  width="14px"
                  :color="$arStyle.color.purple500"
                  :wrapper-style="{
                    border: `1px solid ${$arStyle.color.purple500}`,
                    padding: '1px',
                    borderRadius: '50%',
                    marginRight: '8px',
                  }"
                />
                <ar-text
                  class="label"
                  size="xs"
                  allow-html
                  text="<strong>Note:</strong> Updates are applied to all lists where email address is used"
                  weight="normal"
                />
              </div>

              <div class="u-display-flex u-justify-content-flex-end u-margin-top-4">
                <ar-link-button
                  v-if="!isPatchingMessageSenders && !isCreatingMessageSender && !isDeletingMessageSender"
                  text="Cancel"
                  color="purple"
                  class="u-margin-right-4"
                  @click="handleEditCancel" />
                <ar-simple-button
                  v-if="!senderOnlyMode && showEmailSenderForm && !creatingNewSender"
                  text="Delete"
                  type="red"
                  outlined
                  :style="{ width: '120px' }"
                  text-weight="bold"
                  class="u-margin-right-3"
                  :custom-style-attributes="{
                    height: '40px',
                  }"
                  :disabled="isPatchingMessageSenders"
                  :loading="isDeletingMessageSender"
                  @click="handleSenderDelete"
                />
                <ar-simple-button
                  v-if="showEmailSenderForm && !creatingNewSender"
                  text="Update"
                  outlined
                  :style="{ width: '120px' }"
                  text-weight="bold"
                  :custom-style-attributes="{
                    height: '40px',
                  }"
                  :disabled="!isScratchEmailSenderComplete || isDeletingMessageSender"
                  :loading="isPatchingMessageSenders"
                  @click="handleSenderPatch"
                />
                <ar-simple-button
                  v-if="showEmailSenderForm && creatingNewSender"
                  text="Create"
                  outlined
                  :style="{ width: '120px' }"
                  text-weight="bold"
                  :custom-style-attributes="{
                    height: '40px',
                  }"
                  :disabled="!isScratchEmailSenderComplete"
                  :loading="isCreatingMessageSender"
                  @click="handleSenderPost"
                />
              </div>
            </div>
          </div>

          <!-- SMS -->
          <div
            v-if="!senderOnlyMode"
            class="settings-card"
            :style="{
              cursor: 'pointer',
            }"
            data-test-id="settings-card-sms"
            @click="$refs.smsCheckbox.toggle()"
          >
            <div class="header-section">
              <ar-checkbox
                ref="smsCheckbox"
                :value="editSmsEnabled"
                @input="value => {
                  editSmsEnabled = value
                }"
                :style="{
                  pointerEvents: 'none'
                }"
              />
              <ar-icon
                name="sms"
                width="18px"
                class="u-margin-left-5"
                :color="$arStyle.color.sms"
              />
              <ar-text
                size="xs"
                text="SMS"
                weight="bold"
                :style="{
                  marginLeft: '16.33px',
                }"
              />
            </div>
          </div>
        </div>

      </div>
      <div v-if="!senderOnlyMode" slot="footer" :class="[
        'footer',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-3',
      ]">
        <ar-simple-button
          text="Save"
          :disabled="saveButtonDisabled"
          :loading="isUpdatingMessageList"
          @click="handleConfirmClick"
          :style="{ width: '130px' }"
          data-test-id="edit-list-save-button"
          v-tooltip="{
            content: noEnabledChannels ? 'You must select a message channel before continuing' : null,
          }"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { clone } from '@/utils/helpers';

export default {
  name: 'MessageListSettingsModal',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    messageList: {
      type: Object,
      required: true,
    },
    sender: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      editEmailEnabled: false,
      editSmsEnabled: false,
      arEnableEmailSending: process.env.arEnableEmailSending,

      creatingNewSender: false,
      showEmailSenderForm: false,
      senderOid: null,
    };
  },

  computed: {
    ...mapState({
      isUpdatingMessageList: state => state.messageList.isUpdatingMessageList,
      isPatchingMessageSenders: state => state.messageSender.isPatchingMessageSenders,
      isFetchingMessageSenders: state => state.messageSender.isFetchingMessageSenders,
      isCreatingMessageSender: state => state.messageSender.isCreatingMessageSender,
      isDeletingMessageSender: state => state.messageSender.isDeletingMessageSender,
      messageSenders: state => state.messageSender.messageSenders,
      scratchEmailSender: state => state.messageSender.scratchEmailSender,
      restrictedDomainError: state => state.messageSender.restrictedDomainError,
    }),
    ...mapGetters({
      isScratchEmailSenderComplete: 'messageSender/isScratchEmailSenderComplete',
    }),
    editSenderHeader() {
      return this.senderOnlyMode ? 'Sender settings' : 'List settings'
    },
    senderOnlyMode() {
      return !!this.sender && !!Object.keys(this.sender)?.length
    },
    currentlySelectedEmailMessageSender() {
      if (!this.senderOid) { return null; }
      return this.messageSenders.find(sender => sender.oid === this.senderOid) || null;
    },
    isAutoList() {
      return this.messageList && this.messageList.filterGroupOid;
    },
    filterName() {
      if (!this.messageList || !this.messageList.filterGroup) {
        return '';
      }
      return this.messageList.filterGroup.name || '<strong>N/A</strong>';
    },
    emailVerified() {
      if (this.editEmailEnabled && this.currentlySelectedEmailMessageSender) {
        return this.currentlySelectedEmailMessageSender.verified;
      }

      return false;
    },
    noEnabledChannels() {
      return !this.editEmailEnabled && !this.editSmsEnabled;
    },
    isEmailSettingChanged() {
      if (this.editEmailEnabled !== this.messageList.email) {
        return true;
      }
      if ((!this.messageList.meta.email || !this.messageList.meta.email.promoterPropertyOid) && !!this.senderOid) {
        return true;
      }
      if (this.messageList.meta.email && this.senderOid !== this.messageList.meta.email.promoterPropertyOid) {
        return true;
      }
      return this.editEmailEnabled;
    },
    isSmsSettingChanged() {
      return this.editSmsEnabled !== this.messageList.sms;
    },
    saveButtonDisabled() {
      if (
        this.editEmailEnabled
        && !this.senderOid
      ) {
        return true;
      }
      // Nothing changed
      if (
        !this.isSmsSettingChanged
        && !this.isEmailSettingChanged
      ) {
        return true;
      }

      // If all channels are not enabled
      if (this.noEnabledChannels) {
        return true;
      }

      return false;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.initializeEditValues();
      }

      if (!val) {
        this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
      }
    },
    editEmailEnabled(val) {
      if(!val) {
        this.showEmailSenderForm = false;
      }
    },
  },

  mounted() {
    if (this.messageSenders.length === 0 && !this.isFetchingMessageSenders) {
      this['messageSender/FETCH_MESSAGE_SENDERS']();
    }
    this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
  },

  methods: {
    ...mapActions([
      'messageSender/FETCH_MESSAGE_SENDERS',
      'messageList/UPDATE_MESSAGE_LIST',
      'messageSender/RESEND_EMAIL_VERIFICATION',
      'messageSender/UPDATE_MESSAGE_SENDER',
      'messageSender/DELETE_MESSAGE_SENDER',
      'messageSender/ADD_MESSAGE_SENDER',
    ]),
    ...mapMutations([
      'messageSender/SET_SCRATCH_EMAIL_SENDER',
      'messageSender/RESET_SCRATCH_EMAIL_SENDER',
      'messageSender/PATCH_SCRATCH_EMAIL_SENDER',
      'messageSender/RESET_RESTRICTED_DOMAIN_ERROR',
    ]),
    handleEditCancel() {
      if (this.senderOnlyMode) {
        this.$emit('close')     
      } else {
        this.handleBackFromSenderEdit()
      }
    },
    initializeEditValues() {
      if (this.senderOnlyMode) {
        this.handleSenderEdit(this.sender)
      } else {
        this.editEmailEnabled = this.messageList.email;
        this.editSmsEnabled = this.messageList.sms;
        this.showEmailSenderForm = false;
        this.creatingNewSender = false;
        this.senderOid = this.messageList.meta.email && this.messageList.meta.email.promoterPropertyOid ? this.messageList.meta.email.promoterPropertyOid : null;
      }
    },

    // TODO - This needs work
    retryEmailVerification() {
      if (!this.senderOid) return;
      this['messageSender/RESEND_EMAIL_VERIFICATION'](this.senderOid);
    },

    async handleConfirmClick() {
      if (this.isUpdatingMessageList) return;
      const messageListMeta = this.messageList.meta ? {
        email: clone(this.messageList.meta.email) || {},
      } : {
        email: {},
      };
      const changes = {
        sms: this.editSmsEnabled,
        email: this.editEmailEnabled,
        meta: messageListMeta,
      };

      if (this.editEmailEnabled) {
        changes.meta.email.promoterPropertyOid = this.currentlySelectedEmailMessageSender ? this.currentlySelectedEmailMessageSender.oid : null;
      } else {
        delete changes.meta.email.promoterPropertyOid;
      }

      const succeed = await this['messageList/UPDATE_MESSAGE_LIST']({
        oid: this.messageList.oid,
        changes,
      });
      if (succeed) {
        this.$emit('update', changes);
      }
    },

    handleCloseClick() {
      if (this.isUpdatingMessageList) return;
      this.$emit('close');
    },

    emailSettingsCardClicked() {
      this.$refs.emailCheckbox.toggle();
    },

    // ----------------------------
    // Sender modification methods
    // ----------------------------
    handleSenderSelect(sender) {
      this.showEmailSenderForm = false;
      this.senderOid = sender.oid;
    },

    handleSenderEdit(sender) {
      this['messageSender/SET_SCRATCH_EMAIL_SENDER'](sender);
      this.showEmailSenderForm = true;
      this.creatingNewSender = false;
    },

    handleScratchEmailSenderPatch(changes) {
      this['messageSender/PATCH_SCRATCH_EMAIL_SENDER'](changes);
    },

    handleSenderCreateNew() {
      this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
      this['messageSender/RESET_SCRATCH_EMAIL_SENDER']();
      this.creatingNewSender = true;
      this.showEmailSenderForm = true;
    },

    async handleSenderDelete() {
      const oidToDelete = this.scratchEmailSender.oid;
      const succeed = await this['messageSender/DELETE_MESSAGE_SENDER'](oidToDelete);
      if (succeed) {
        if (oidToDelete === this.senderOid) {
          this.senderOid = null;
        }
        this.handleBackFromSenderEdit();
      }
    },

    async handleSenderPatch() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const succeed = await this['messageSender/UPDATE_MESSAGE_SENDER'](this.scratchEmailSender);
      if (succeed) {
        if (this.senderOnlyMode) {
          let updatedSender = this.messageSenders.find(item => item.oid === this.sender.oid)
          this.$emit('update', updatedSender)
        } else {
          this.handleBackFromSenderEdit();
        }
      }
    },

    async handleSenderPost() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const succeed = await this['messageSender/ADD_MESSAGE_SENDER'](this.scratchEmailSender);
      if (succeed) {
        this.handleBackFromSenderEdit();
        this.senderOid = succeed.oid;
      }
    },

    goToIntegrationsPage() {
      this.$emit('close');
      this.$router.push({
        path: `/settings/integrations`,
      });
    },

    handleBackFromSenderEdit() {
      this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
      this.showEmailSenderForm = false;
      this.creatingNewSender = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-list-settings-modal {
  .header {
    font-size: 20px;
  }
  .body {
    .modal-content {
      padding: 29px 30px;
      min-height: 500px;

      .header-section {
        display: flex;
        justify-content: flex-start;
        flex-wrap:wrap;
        align-items: center;

        .title-section {
          display: inline-flex;
          align-items: center;
        }

        .link-button {
          line-height:40px;
          padding: 10px 0;
          &.enabled {
            color: $blueGrey700;
          }
        }
      }

      .additional-info-section {
        margin: 24px 0 4px 40px;

        .input-section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .input {
            width: 100%;
          }
        }
      }

      .edit-sender-section {
        padding: 24px 0 6px 40px;
        .input-section {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          padding-top: 10px;

          .label {
            margin-bottom: 12px;
          }

          .input {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }

      .email-unverified {
        padding-top: 10px;
      }

      .settings-card {
        padding: 16px;
        border: 1px solid $blueGrey500;
        margin: 16px 0;
        border-radius: 3px;
      }

    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 25px;
  }
}
</style>
