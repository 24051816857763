<template>
  <div
    :class="[
      'ar-button-switch',
      disabled && 'disabled',
    ]"
  >
    <div
      v-for="item in items"
      :class="[
        'button-tab',
        item.key === selectedValue && 'selected'
      ]"
      @click="() => handleItemSelect(item)"
    >
      <ar-text
        :text="item.name"
        size="xs"
        :style="{
          color: item.key === selectedValue ? $arStyle.color.purple500 : null,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonSwitch',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      selectedValue: this.value,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.selectedValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleItemSelect(item) {
      this.selectedValue = item.key
      this.$emit('update', item.key)
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-button-switch {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .button-tab {
    padding: 13px 16px;
    border: 1px solid $blueGrey500;
    border-radius: 4px;
    cursor: pointer;

    &.selected {
      border: 1px solid $purple500;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
