<template>
  <article class="data-list-container">
    <div
      ref="invisible-modal"
      class="invisible-modal"
      :style="{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        zIndex: $arStyle.zIndex.globalRegular,
        display: !noActiveIndex ? 'block' : 'none',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      }"
      @click.stop.prevent="handleInvisibleModalClick"
    />
    <am2-heading
      type="h1"
      size="md"
      class="section-heading"
      :title="title"
    />
    <ar-simple-form
      ref="simple-form"
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :icon-name="item.icon"
      :placeholder="`Add ${item.title}`"
      :parsed-data="returnParsedData(item)"
      :disabled="!item.modifiable"
      :opened="activeFormIndex === itemIndex"
      @open="handleFormOpen(itemIndex)"
      @submit="handleFormSubmit(item, itemIndex)"
      @cancel="handleSimpleFormCancel()"
      :style="{
        position: 'relative',
        zIndex: !noActiveIndex ? $arStyle.zIndex.globalRegular + 1 : null,
      }"
    >
      <div v-if="activeFormIndex === itemIndex" slot="fields">
        <div
          v-for="(input, itemInputIndex) in item.inputs"
          :key="itemInputIndex"
          :class="[
            'single-input',
            !!item.inputs[itemInputIndex + 1] && 'u-margin-bottom-1',
          ]"
        >
          <ar-input
            v-model="temporaryValues[itemIndex][input.name]"
            v-if="input.type === 'String'"
            type="text"
            :placeholder="input.placeholder"
            input-font-size="xs"
            :id="input.name"
            :autocomplete="input.name"
            :key="itemIndex + itemInputIndex + input.savedData"
            :style="{
              height: '42px',
            }"
          />
          <ar-input
            v-model="temporaryValues[itemIndex][input.name]"
            v-else-if="input.type === 'Number'"
            type="number"
            :placeholder="input.placeholder"
            input-font-size="xs"
            :id="input.name"
            :autocomplete="input.name"
            :key="itemIndex + itemInputIndex + input.savedData"
            :style="{
              height: '42px',
            }"
          />
          <ar-field
            v-else-if="input.type === 'Date'"
            class="u-width-100-percent"
            :error-messages="[veeErrors.first(input.name)]"
          >
            <ar-date-input
              v-model="temporaryValues[itemIndex][input.name]"
              v-validate="'date-of-birth'"
              :data-vv-name="input.name"
              data-vv-as="date"
            />
          </ar-field>
          <ar-field
            v-else-if="input.type === 'Mobile'"
            class="u-width-100-percent"
            :error-messages="[veeErrors.first(input.name)]"
          >
            <ar-mobile-input
              v-validate="'mobileNumber'"
              :data-vv-name="input.name"
              data-vv-as="mobile number"
              v-model="temporaryValues[itemIndex][input.name]"
              autocomplete="tel-national"
              :key="itemIndex + itemInputIndex + input.savedData"
              input-font-size="xs"
              enable-clear
              multiple-lines
            />
          </ar-field>
          <ar-simple-select
            v-else-if="input.type === 'Select'"
            :items="input.options"
            placeholder="Select One"
            :style="{
              height: '42px',
              width: '100%',
            }"
            enable-clear
            :default-select-index="input.options.findIndex( item => item.name === temporaryValues[itemIndex][input.name])"
            :key="itemIndex + itemInputIndex + input.savedData"
            @select="selection => { modifyData(input.name, selection.name, itemIndex) }"
            @clear="() => { modifyData(input.name, null, itemIndex) }"
          />
          <ar-simple-select
            v-else-if="input.type === 'Gender'"
            :items="input.options"
            placeholder="Select gender..."
            :style="{
              height: '42px',
              width: '100%',
            }"
            enable-clear
            :default-select-index="input.options.findIndex( item => item.name === temporaryValues[itemIndex][input.name])"
            :key="itemIndex + itemInputIndex + input.savedData"
            @select="selection => { selection.key !== null ? modifyData(input.name, selection.name, itemIndex) : modifyData(input.name, null, itemIndex) }"
            @clear="() => { modifyData(input.name, null, itemIndex) }"
          />
          <ar-state-select
            v-else-if="input.type === 'State' && stateShouldUseSelect"
            :value="temporaryValues[itemIndex][input.name]"
            :country-iso-code="temporaryValues[itemIndex]['country'] ? temporaryValues[itemIndex]['country'] : null"
            :style="{
              width: '100%',
              height: '42px',
            }"
            :key="`state-${temporaryValues[itemIndex].country}`"
            enable-clear
            @select="selection => { modifyData(input.name, selection.name, itemIndex ) }"
            @clear="() => { modifyData(input.name, null, itemIndex) }"
          />
          <ar-input
            v-model="temporaryValues[itemIndex][input.name]"
            v-else-if="input.type === 'State' && !stateShouldUseSelect"
            type="text"
            :placeholder="input.placeholder"
            input-font-size="xs"
            :id="input.name"
            autocomplete="address-level1"
            :style="{
              height: '42px',
            }"
            :key="`state-${temporaryValues[itemIndex].country}`"
          />
          <ar-country-select
            v-else-if="input.type === 'Country'"
            :value="temporaryValues[itemIndex][input.name]"
            value-type="iso"
            :style="{
              width: '100%',
              height: '42px',
            }"
            enable-clear
            @select="selection => { modifyData(input.name, selection.iso, itemIndex) }"
            @clear="() => { modifyData(input.name, null, itemIndex) }"
            />
        </div>
      </div>
    </ar-simple-form>
  </article>
</template>

<script>
  // For displaying a flat list of data
  // Optionally allows editing of that data

  import { countryList } from "@/utils/countries";
  import moment from 'moment';
  import { mapActions } from 'vuex';

  export default {
    name: 'DataList',
    props: {
      title: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
      deleteData: {
        type: Function,
        default: () => {},
      },
      saveData: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        activeFormIndex: null, // Index of form currently being edited
        temporaryValues: {}, // Temporary values for field currently being edited
        countriesWithStateSelect: ['au', 'us'],
      }
    },

    computed: {
      stateShouldUseSelect() {
        const locationItemIndex = this.items.findIndex( item => item.title === 'Location');
        if (!this.temporaryValues) return false;
        if (!this.temporaryValues[locationItemIndex]) return false;
        if (!this.temporaryValues[locationItemIndex].country) return false;

        const tempCountryVal = this.temporaryValues[locationItemIndex].country;
        return this.countriesWithStateSelect.indexOf(tempCountryVal) > -1;
      },
      noActiveIndex() {
        return this.activeFormIndex === null || this.activeFormIndex === false;
      },
    },

    mounted() {
      this.refreshTemporaryValues();
    },

    beforeDestroy() {
      this.removeInvisibleModalFromBody();
    },

    methods: {
      ...mapActions([
        'SHOW_MULTIPLE_BUTTON_MODAL',
      ]),
      // Just attach the dialog to body level, remove it when we don't need it anymore
      attachInvisibleModalToBody() {
        document.body.appendChild(this.$refs['invisible-modal']);
      },
      removeInvisibleModalFromBody() {
        if (!!this.$refs['invisible-modal'] && this.$refs['invisible-modal']?.parentElement?.nodeName === 'BODY') {
          document.body.removeChild(this.$refs['invisible-modal']);
        }
      },

      refreshTemporaryValues() {
        if (!this.items) return;
        const newItems = this.items.map( item => {
          const inputsObject = {};
          item.inputs.forEach( input => {
            inputsObject[input.name] = input.savedData || null;
          });
          return inputsObject;
        });
        this.temporaryValues = newItems;
      },

      // Formats a "DD/MM/YYYY" into "DD MMM YYYY (XX years old)" format
      // We validate the date before users save data, so we can say savedData is either null or complete
      returnPrettyDob(field) {
        if (!field.inputs[0].savedData) { return null; }
        const [dobYear, dobMonth, dobDay] = field.inputs[0].savedData.split('-');
        // Note - JS starts months at 0, but we store with Jan as 01 (obviously), so lets cater for that little dilemma by reducing value of month by 1
        const [dobYearInt, dobMonthInt, dobDayInt] = [parseInt(dobYear), parseInt(dobMonth) - 1, parseInt(dobDay)];

        const monthString = new Date(dobYearInt, dobMonthInt, dobDayInt).toLocaleString('default', { month : 'short' });
        const currentDate = new Date();
        const birthDate = new Date(dobYearInt, dobMonthInt, dobDayInt);
        const yearsDiff = moment(currentDate).diff(moment(birthDate), 'years');

        let string = '';
        string += dobDay ? dobDay : ''
        string += monthString ? ` ${monthString} ` : ''
        string += dobYear ? dobYear : ''
        if (dobYear) {
          string += ` (${yearsDiff} years old)`;
        }

        return string;
      },

      // Returns a nicely formatted location
      returnPrettyLocation(field) {
        if(!field) return null;
        if(!field.inputs || field.inputs.length == 0) return null;

        // If we're returning a State value, then we need to know if there's a corresponding country field, and if so, what its value is.
        const countryIndex = field.inputs.findIndex( input => input.name === 'country');
        const countryName = countryIndex > -1 ? field.inputs[countryIndex].savedData : false;

        let locationString = '';

        let streetAddressString = '';
        let cityString = '';
        let stateString = '';
        let postcodeString = '';
        let countryString = '';

        field.inputs.forEach( input => {
          if (input.name === 'streetAddress') {
            streetAddressString = input.savedData ? input.savedData.toString() : ''
          } else if (input.name === 'city') {
            cityString = input.savedData ? input.savedData.toString() : ''
          } else if (input.name === 'postcode') {
            postcodeString = input.savedData ? input.savedData.toString() : ''
          } else if (input.name === 'state') {
            stateString = input.savedData ? input.savedData.toString() : '';
          } else if (input.name === 'country') {
            const savedCountry = input.savedData?.toLowerCase() || '';
            const relevantCountry = countryList.find(item => item.iso === savedCountry);
            countryString = relevantCountry && relevantCountry.name ? relevantCountry.name : '';
          }
        });

        const locationParts = [];
        if (streetAddressString.length > 0) {
          locationParts.push(streetAddressString);
        }
        if (cityString.length > 0) {
          locationParts.push(cityString);
        }
        if (postcodeString.length > 0 || stateString.length > 0) {
          locationParts.push(`${stateString} ${postcodeString}`);
        }
        if (countryString.length > 0) {
          locationParts.push(countryString);
        }
        locationString = locationParts.join(`<br/>`);

        return locationString;
      },

      // Returns a +61 444 444 444 phone number
      returnPrettyPhone(field) {
        if(!field) return null;
        if(!field.inputs || field.inputs.length == 0) return null;

        let string = field.inputs[0].savedData ? field.inputs[0].savedData.toString() : '';
        string = string.length >= 9 ? `${string.slice(0,3)} ${string.slice(3,6)} ${string.slice(6,9)} ${string.slice(9)} ` : string;

        return string;

      },

      // Combines multiple inputs into a single space-separated string
      returnPrettyString(field) {
        if(!field) return null;
        if(!field.inputs || field.inputs.length == 0) return null;

        return field.inputs.reduce( (string, current, currentIndex, arr) => {
          string += current.savedData ? current.savedData.toString() : '';

          if(string && current.savedData && current.savedData.toString().length > 0) {
            string += currentIndex === arr.length - 1 ? '' : ' ';
          }
          return string;
        }, '');
      },

      // Returns the data selected by a select field
      returnPrettySelectData(field) {
        if(!field) return null;
        if(!field.inputs || field.inputs.length == 0) return null;

        const mainString = field.inputs.reduce( (string, current, currentIndex, arr) => {
          string += current.options.find( item => item.name === current.savedData) ? current.savedData.toString() : '';

          if(string && current.savedData && current.savedData.toString().length > 0) {
            string += currentIndex === arr.length - 1 ? '' : ' ';
          }
          return string;
        }, '');

        return mainString;
      },

      returnParsedData(field) {
        if(!field) return null;
        switch(field.outputAs) {
          case 'DateOfBirth':
            return this.returnPrettyDob(field);
            break;
          case 'Location':
            return this.returnPrettyLocation(field);
            break;
          case 'Phone':
            return this.returnPrettyPhone(field);
            break;
          case 'Select':
            return this.returnPrettySelectData(field);
            break;
          default:
            return this.returnPrettyString(field);
            break;
        }
      },

      modifyData(keyName, newValue, index) {
        this.$set(this.temporaryValues[index], keyName, newValue);
        if(keyName === 'country' && (newValue === 'au' || newValue === 'us')) {
          this.$set(this.temporaryValues[index], 'state', null);
        }
      },

      hasActiveFormUpdated() {
        if (this.activeFormIndex === null) {
          return false;
        }
        const item = this.items[this.activeFormIndex];
        let hasChanged = false;
        this.items[this.activeFormIndex].inputs.forEach( input => {
          const name = input.name;
          const value = input.savedData;
          if (this.temporaryValues[this.activeFormIndex][name] !== value) hasChanged = true;
          if (!this.temporaryValues[this.activeFormIndex][name] && !value) hasChanged = false; // Sometimes values are stored as null, othertimes stored as "", so easier to just check for falsiness.
        });
        return hasChanged;
      },

      async promptToCheckFormSave() {
        const shouldSave = await this.SHOW_MULTIPLE_BUTTON_MODAL({
          title: `Do you want to save changes?`,
          buttons: [
            {
              props: {
                text: 'Discard changes',
                outlined: true,
                style: {
                  width: '196px',
                },
              },
              clickHandler: () => {
                return false;
              },
            },
            {
              props: {
                text: 'Save',
                outlined: false,
                style: {
                  width: '196px',
                },
              },
              clickHandler: () => {
                return true;
              },
            }
          ],
        });
        if (shouldSave) {
          this.handleFormSubmit(this.items[this.activeFormIndex], this.activeFormIndex);
        } else {
          this.handleSimpleFormCancel();
        }
      },

      async handleInvisibleModalClick() {
        if (this.noActiveIndex || !this.items[this.activeFormIndex]) {
          this.removeInvisibleModalFromBody();
          return;
        }
        const hasChanged = this.hasActiveFormUpdated();
        if (!hasChanged) {
          this.handleSimpleFormCancel();
        } else {
          this.promptToCheckFormSave();
        }
      },

      handleFormOpen(formIndex) {
        if (this.hasActiveFormUpdated()) {
          this.promptToCheckFormSave();
          return;
        }
        this.activeFormIndex = formIndex;
        this.refreshTemporaryValues();
        this.attachInvisibleModalToBody();
      },

      handleSimpleFormCancel() {
        this.activeFormIndex = null;
        this.refreshTemporaryValues();
        this.removeInvisibleModalFromBody();
      },

      async handleFormSubmit(item, indexValue) {
        const isValid = await this.$validator.validate();
        if (!isValid) { return; }

        this.activeFormIndex = null;
        const selectedTempValues = this.temporaryValues[indexValue];
        this.saveData(item, selectedTempValues, indexValue);
        this.removeInvisibleModalFromBody();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .data-list-container {
    padding: 32px 12px;

    .section-heading {
      margin-bottom:24px;
      padding: 0 20px;
    }
  }
</style>
