<template>
  <div class="ar-timezone-select">
    <ar-simple-select
      :items="timezones"
      :default-select-index="timezoneSelectIndex"
      enable-filter
      :placeholder="placeholder"
      class="select"
      :has-error="hasError"
      @select="handleSelectInput"
      :disabled="disabled"
      :enable-clear="enableClear"
      @clear="clearSelection"
    />

      <!--  <div v-for="timezone in timezones" :key="timezone.name">
      <div class="name">
        {{ timezone.displayName }}
      </div>
      <div class="utcoffset">
        {{ timezone.UTCOffset }}
      </div>
    </div> -->
  </div>
</template>

<script>
import timezones from '@/utils/timezones';

export default {
  name: 'TimezonePicker',
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Search for a timezone",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableClear: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      timezones,
      timezoneSelectIndex: null,
    };
  },
  mounted() {
    this.setSelectedIndex(this.value)
  },
  watch: {
    value(val) {
      this.setSelectedIndex(val)
    }
  },
  methods: {
    queryValidator(query) {
      if (this.filter(query).length > 0 && !query.match(/[;:*?"<>|&'\\.]/g)) {
        return true;
      }
      return false;
    },
    inputValidator(query) {
      return (
        this.timezones.findIndex(timeZone => {
          const timezoneDisplayFormat = timeZone.value
            .replace(/[;:*?"<>|&'\\()]/g, '')
            .replace(' ', '_');
          if (query === timezoneDisplayFormat) return true;
          return false;
        }) >= 0
      );
    },
    filter(query) {
      return this.timezones.filter(timezone => {
        const search = new RegExp(query.replace(/[;:*?"<>|&'\\()]/g, '').replace(' ', '_'), 'gi');
        return timezone.value.match(search) !== null;
      });
    },
    handleSelectInput(selected) {
      if (typeof selected === 'string') {
        this.$emit('input', selected);
      } else {
        this.$emit('input', selected ? selected.value : null);
      }
    },
    clearSelection() {
      this.timezoneSelectIndex = null;
      this.$emit('input', null);
    },
    setSelectedIndex(val) {
      this.timezoneSelectIndex = this.timezones.findIndex(({ value }) => value === val);
    }
  },
};
</script>

<style lang="scss">
.ar-timezone-select {
  width: 100%;
  height: 100%;

  .select {
    width: 100%;
  }
}
</style>
