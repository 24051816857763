<template>
  <div
    :class="['top-create', $arMediaQuery.pageContent.maxWidth('md') && 'md-max']"
    :style="{
      backgroundColor: type === 'purple' ? $arStyle.color.purple500 : 'white'
    }"
  >
    <am2-loading-section
      v-if="loading"
      :style="{
        width: '100px',
        height: '100%'
      }"
    />
    <div v-else class="top-create_content">
      <div class="top-create__heading">
        <div v-if="titleIcon || title" class="u-display-flex u-flex-flow-column">
          <div class="top-create__title ">
            <div v-if="titleIcon" class="icon-container"><ar-icon v-bind="titleIcon" /></div>
            <am2-heading
              v-if="titleIcon"
              type="h1"
              size="md"
              :title="title"
              :style="{
                color: type === 'purple' ? 'white' : null
              }"
            />
            <am2-heading
              v-else
              type="h1"
              size="sm"
              :title="title"
              weight="bold"
              :style="{
                color: type === 'purple' ? 'white' : null
              }"
            />
          </div>
          <div v-if="subtitle" class="u-margin-top-3">
            <am2-heading
              type="h1"
              size="xxs"
              :title="subtitle"
              :style="{
                color: type === 'purple' ? 'white' : null
              }"
            />
          </div>
        </div>
        <div :class="['top-create__content', title && $slots.content && 'has-title-section']">
          <slot name="content" />
        </div>
      </div>

      <div></div>
      <div class="top-create__exit">
        <ar-link-button
          v-if="hasAdvancedSettings"
          @click="handleAdvancedSettingsClick"
          text="Advanced Settings"
          :style="{
            color: type === 'purple' ? 'white' : null,
            marginRight: '30px'
          }"
          data-test-id="top-create-advanced-settings-link"
        />
        <div v-if="showExitButton">
          <ar-link-button
            text="Exit"
            @click="handleExitClick"
            :style="{
              color: type === 'purple' ? 'white' : null
            }"
            data-test-id="top-create-exit-link"
          />
        </div>
      </div>
    </div>
    <am2-simple-stepper v-if="totalSteps" class="progress-bar" :steps-count="totalSteps" :current-step="currentStep" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'TopCreate',

  props: {
    totalSteps: {
      type: Number,
      default: null
    },
    currentStep: {
      type: Number,
      default: 1
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    exitPath: {
      type: String,
      required: true
    },
    titleIcon: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'white',
      validator: value => ['white', 'purple'].indexOf(value) !== -1
    },
    hasAdvancedSettings: {
      type: Boolean,
      default: false
    },
    handleAdvancedSettingsClick: {
      type: Function,
      default: null
    },
    showExitButton: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    progressPercent() {
      return this.progress * 100 + '%';
    }
  },

  methods: {
    handleExitClick() {
      this.$router.push({
        path: this.exitPath
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-create {
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid $skyBlueGrey500;
  height: 90px;
  width: 100%;
  padding: 0 32px;
  z-index: $zIndexHigh;

  &.md-max {
    height: 68px;
    padding: 0 24px;
  }

  &_content {
    height: 100%;
    display: flex;
    flex-grow: 1;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: calc(100% - 40px);

    .top-create__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 27px;

      .icon-container {
        border: 2px #ebebeb solid;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        margin-right: 12px;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }

    .top-create__content {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;

      & > div {
        max-width: 100%;
      }

      &.has-title-section {
        padding-left: 21px;
        border-left: 1px solid $skyBlueGrey500;
      }
    }
  }

  .progress-bar {
    pointer-events: none;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    transition: width 0.25s;
    transition-delay: 0.6s;
  }

  &__exit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40px;
    height: 100%;
  }
}
</style>
