<template>
  <AuthenticateFrame>
    <ar-text
      text="Your invitation link has expired"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '16px',
      }"
    />
    <ar-text
      text="Please contact your manager to request a new link"
      size="sm"
      multiple-lines
      :style="{
        color: $arStyle.color.skyBlueGrey700,
        marginBottom: '40px',
      }"
    />
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AuthenticateFrame from '../authenticate-frame';

export default {
  name: 'invite-expired',
  
  layout: 'split',
  components: {
    AuthenticateFrame,
  },
  computed: {
    ...mapState({
    }),
  },
  data() {
    return {
    }
  },
  async created() {
    
  },
  mounted() {
    // Update layout options
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);
  },
  methods: {
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>
