import {PowerupActionType} from "~/types/resources/powerups";
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

// Calculate days before or after date
export const calculateDaysBetweenTwoDates = (
  actionType: PowerupActionType,
  scheduleMinutesOffset: number,
  date1?: string,
  date2?: string,
  timezone?: string,
  durationDays?: number,
): number => {
  if (actionType === 'event-day') return 0

  if (!date1 || !date2 || !timezone) {
    return Math.abs(Math.ceil(scheduleMinutesOffset / 60 / 24))
  }

  const dateObj1 = dayjs.utc(date1).tz(timezone).set('hour', 0).set('minute', 0).set('second', 0)
  const dateObj2 = dayjs.utc(date2).tz(timezone).set('hour', 0).set('minute', 0).set('second', 0)

  let result = Math.abs(dateObj1.diff(dateObj2, 'day'))

  if (actionType === 'after-event' && durationDays) {
    result = result - durationDays
  }
  return result
};

export const getMessageNameForPowerup = ({
    actionType,
    eventName,
    days,
  }: {
  actionType: PowerupActionType,
  eventName: string,
  days: number,
}): string => {
  if (actionType === 'event-day') return 'Event Day ' + eventName

  const daysText = days === 1 ? 'day' : `days`
  const beforeOrAfter = actionType === 'before-event' ? 'before' : 'after'

  return `${days} ${daysText} ${beforeOrAfter} ${eventName}`
};
