<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]"
  >
    <am2-ask-event-genius-access-token-modal
      :is-show="showAskEventGeniusAccessTokenModal"
      :loading="isCreatingEventGeniusIntegration"
      @confirm="handleEventGeniusAccessTokenConfirm"
      @cancel="handleEventGeniusAccessTokenCancel"
    />
    <am2-step-link
      data-test-id="event-genius-back-button"
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Event Genius"
          description="Automatically sync your ticket sales, events & attendees from Event Genius"
          :icon-props="{
            name: 'event-genius',
            color: $arStyle.color.eventGenius,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingEventGeniusIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingEventGeniusIntegration && eventGeniusIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Event Genius',
              value: 'goToEventGeniusSite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>


    <section v-if="isFetchingEventGeniusIntegration" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <section v-if="integrationFailed" class="integration-failed-message">
        <ar-snackbar
          type="warning"
          message="Your Event Genius connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
          @anchorClick="handleReconnectIntegration"
          :style="{ width: '100%' }"
        />
      </section>
      <SyncBlockContainer
        v-else
        class="sync-block-container"
        :is-fetching-sync-task="isFetchingSyncTask"
        :sync-status="syncStatus"
        :sync-time="syncTime"
        :sync-copies="{
          'in-progress': 'Syncing data from Event Genius',
          'pending': 'The data sync is still pending',
          'completed': 'Your Event Genius account is currently connected',
          'cancelled': 'Your Event Genius account connection is cancelled',
          'failed': syncErrorMessage,
          'default': 'Syncing tasks not found',
        }"
        @startSync="handleStartSync"
        @stopSync="handleStopSync"
      />
    </section>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';

export default {
  name: 'EventGeniusIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    showAskEventGeniusAccessTokenModal: false,
    isReconnectingAccount: false,
  }),

  computed: {
    ...mapState({
      eventGeniusIntegration: state => state.eventGeniusIntegration.integration,
      isCreatingEventGeniusIntegration: state => state.eventGeniusIntegration.isCreatingIntegration,
      isFetchingEventGeniusIntegration: state => state.eventGeniusIntegration.isFetchingIntegration,
      isFetchingSyncTask: state => state.eventGeniusIntegration.isFetchingSyncTask,
      syncTask: state => state.eventGeniusIntegration.syncTask,
      syncStatus: state => state.eventGeniusIntegration.syncStatus,
    }),

    eventGeniusIntegrationEnabled() {
      return process.env.arEnableEventGeniusIntegration;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || null;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.eventGeniusIntegration) return null;
      return this.eventGeniusIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          name: i.integration?.user?.username,
          email: i.integration?.user?.username,
        }));
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.eventGeniusIntegration || this.eventGeniusIntegration.length < 1 || !this.eventGeniusIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.eventGeniusIntegration[this.currentlySelectedIntegrationIndex];
    },
  },

  async mounted() {
    if (!this.eventGeniusIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['eventGeniusIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'eventGeniusIntegration/CREATE_EVENT_GENIUS_INTEGRATION',
      'eventGeniusIntegration/FETCH_INTEGRATION',
      'eventGeniusIntegration/DELETE_INTEGRATION',
      'eventGeniusIntegration/SYNC_START',
      'eventGeniusIntegration/SYNC_STOP',
      'eventGeniusIntegration/FETCH_SYNC_TASK',
    ]),
    ...mapMutations([
      'eventGeniusIntegration/RESET_INTEGRATION',
    ]),
    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },
    async fetchIntegration() {
      await this['eventGeniusIntegration/FETCH_INTEGRATION']();
      if (!this.eventGeniusIntegration || this.eventGeniusIntegration.length === 0) {
        this.$router.push({
          path: '/settings/integrations',
        });
      }
    },

    async fetchTasks() {
      await this['eventGeniusIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
      }
    },

    connectToEventGenius() {
      this.showAskEventGeniusAccessTokenModal = true;
    },

    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
      this.fetchTasks();
    },

    async handleAddNewIntegration() {
      this.connectToEventGenius();
    },

    async handleStartSync() {
      await this['eventGeniusIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    handleStopSync() {
      this['eventGeniusIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
    },

    async handleReconnectIntegration() {
      this.isReconnectingAccount = true;
      this.connectToEventGenius();
    },

    handleEventGeniusAccessTokenCancel() {
      if (this.isReconnectingAccount) {
        this.isReconnectingAccount = false;
      }
      this.showAskEventGeniusAccessTokenModal = false;
    },

    async handleEventGeniusAccessTokenConfirm({ username, password }) {
      if (this.isReconnectingAccount && username !== this.currentIntegration.integration.user.username) {
        this.$arNotification.push({
          type: 'error',
          message: 'The username you put here is different to the username of the account you are trying to reconnect.',
        });
        return;
      }
      let succeed;
      if (this.isReconnectingAccount) {
        succeed = await this['eventGeniusIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
        if (!succeed) {
          return;
        }
      }
      succeed = await this['eventGeniusIntegration/CREATE_EVENT_GENIUS_INTEGRATION']({
        username,
        password,
      });
      if (succeed) {
        await this.fetchIntegration();
        this.currentlySelectedIntegrationIndex = this.eventGeniusIntegration.length - 1;
        this.showAskEventGeniusAccessTokenModal = false;
        this.startFetchTasksPolling();
      } else {
        this.currentlySelectedIntegrationIndex = 0;
      }
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the EventGenius integration will stop syncing your orders and customer data from EventGenius.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['eventGeniusIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
            if (this.eventGeniusIntegration.length > 0) {
              this.currentlySelectedIntegrationIndex = 0;
              await this.fetchIntegration();
              this.startFetchTasksPolling();
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value === 'goToEventGeniusSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://www.eventgenius.live/', '_blank');
        win.focus();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }

  .sync-block-container {
    margin-top:64px;
  }
}
</style>
