<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="select-dynamic-tags-modal"
      :header="modalTitle"
      :hide-footer="true"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div
        slot="body"
        class="body"
      >
        <div
          class="dynamic-tag-item"
          v-for="item in dynamicTags"
          :key="item.name"
          @click="() => {handleDynamicTagClick(item)}"
        >
          {{ item.name }}
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'SelectDynamicTagsModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    dynamicTags: {
      type: Array,
      default: null,
    },
  },

  computed: {
    modalTitle() {
      return 'Select a dynamic tag';
    },
  },

  methods: {
    handleClose() {
      this.$emit('cancel');
    },
    async handleDynamicTagClick(item) {
      this.$emit('select', item)
    },
  },
};
</script>

<style lang="scss" scoped>
.select-dynamic-tags-modal {
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    overflow-y: auto;
    max-height: 400px;

    .dynamic-tag-item {
      padding: 10px 0 10px 0;
      width: 100%;
      text-align: center;
      border-radius: 5px;

      &:hover {
        background-color: $blueGrey500;
        cursor: pointer;
      }
    }
  }
}
</style>
