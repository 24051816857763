var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'rate-container': true,
    'multiple': _vm.multiple,
    'link': _vm.link,
  },attrs:{"data-test-id":_vm.rateTestId}},[_c('ar-text',{staticClass:"header",attrs:{"size":"30px","text":(_vm.rate + "%"),"multiple-lines":""}}),_vm._v(" "),_c('ar-text',{style:({
      color: _vm.$arStyle.color.skyBlueGrey700,
    }),attrs:{"size":"16px","text":_vm.formattedValue,"weight":"normal","multiple-lines":""}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
      color: _vm.$arStyle.color.skyBlueGrey700,
    }),attrs:{"size":"16px","text":_vm.title,"weight":"normal","multiple-lines":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }