<template>
  <section>
    <am2-navbar
      v-if="canBeViewed"
      :menu="menu"
      v-ar-sticky-top="{
      priority: 1,
    }"
    >
    </am2-navbar>

    <nuxt-child
      v-if="canBeViewed"
      class="admin-content"
    />
  </section>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  export default {
    name: 'Admin',
    layout: 'default',

    computed: {
      ...mapState({
        adminState: state => state.admin,
      }),
      ...mapGetters({
        isAdminAccount: 'auth/isAdminAccount',
      }),
      menu() {
        return {
          overview: {
            name: 'Admin Dashboard',
            to: `/admin/dashboard`,
          },
          companies: {
            name: 'Companies',
            to: `/admin/companies`,
          },
          users: {
            name: 'Users',
            to: `/admin/users`,
          },
          fixes: {
            name: 'Account Fixes',
            to: `/admin/fixes`,
          },
          purchases: {
            name: 'Message Tasks',
            to: `/admin/message-tasks`,
          },
          csvImport: {
            name: 'CSV Import',
            to: `/admin/csv-import`,
          },
          sms: {
            name: 'SMS',
            to: `/admin/sms`,
          }
        };
      },
      canBeViewed() {
        return this.isAdminAccount;
      },
    },

    beforeDestroy() {
      this['admin/RESET_ADMIN']();
    },

    created() {
      if (!this.canBeViewed) {
        this.$router.push({
          path: '/audience',
        });
      }
    },

    mounted() {
      this['admin/RESET_ADMIN']();
    },

    methods: {
      ...mapMutations(['admin/RESET_ADMIN']),
    }

  };
</script>

<style lang="scss" scoped>
  .admin-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px 0;
  }
</style>
