var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"am2-message-setup-page"},[_c('div',{class:[
      'header-section',
      _vm.displayMobilePreview && 'not-visible' ]},[(_vm.displayUnlockPrompt)?_c('am2-unlock-prompt',{attrs:{"title":_vm.unlockPromptTitle,"subtitle":_vm.unlockPromptSubtitle,"size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'},on:{"click":_vm.handleGoToPlansClick}}):_vm._e(),_vm._v(" "),_c('am2-top-create',{style:({
        position: 'relative',
      }),attrs:{"title":_vm.headerText,"exit-path":_vm.exitPath,"title-icon":_vm.headerIconProps}})],1),_vm._v(" "),_c('div',{class:[
      'body-section',
      _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('div',{class:[
        'edit-message-section',
        _vm.hasNoSpaceForPreview && 'full-width' ]},[_c('div',{class:[
          'edit-message-form-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"page-content"},[_c('nuxt-child',{ref:"message-edit-page",attrs:{"cost-accepted":_vm.costAccepted,"no-contacts":_vm.noContactsInMessageList,"recipient-count-mismatch":_vm.recipientsDoesNotMatchListCount,"message-list":_vm.currentMessageList || null,"facebook-page-is-purchased":_vm.facebookPageIsPurchased},on:{"cost-decision-change":function (val) { return _vm.costAccepted = val; },"facebookPageUpdate":_vm.handleFacebookPageUpdate,"forceCostRecount":_vm.forceCostRecount,"selected-from-key":function (val) { return _vm.selectedFromKey = val; }}})],1)]),_vm._v(" "),_c('div',{class:[
          'edit-message-navigation-section',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[_c('div',{staticClass:"navigation-left-section"},[(_vm.$arMediaQuery.window.minWidth('sm'))?_c('ar-simple-button',{style:({ width: '120px' }),attrs:{"text":"Cancel","outlined":"","data-test-id":"cancel-button"},on:{"click":_vm.cancelChanges}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"navigation-right-section"},[(_vm.currentSelectedMessageScheduleCountdownCopy)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.currentSelectedMessageScheduleCountdownCopy,
            }),expression:"{\n              content: currentSelectedMessageScheduleCountdownCopy,\n            }"}],staticClass:"u-margin-right-2",attrs:{"name":"notification-warning","color":_vm.$arStyle.color.purple500}}):_vm._e(),_vm._v(" "),(_vm.currentSelectedMessageUiStatus !== 'scheduled')?_c('ar-link-button',{class:[
              'save-link',
              _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ],attrs:{"text":_vm.isNewMessage ? 'Save as draft' : 'Save draft',"disabled":_vm.isSaveAsADraftButtonDisabled,"data-test-id":"save-draft-button"},on:{"click":_vm.saveAsDraft}}):_vm._e(),_vm._v(" "),(_vm.hasNoSpaceForPreview)?_c('ar-simple-button',{style:({
              marginRight: '12px',
              width: _vm.previewButtonWidth,
            }),attrs:{"text":_vm.mobilePreviewIsToggledUp ? 'Edit' : 'Preview',"outlined":"","icon-name":_vm.previewButtonIconName,"icon-props":{
              height: '26px',
              color: _vm.$arStyle.color.purple200,
            },"icon-distance":"10px","data-test-id":"view-preview-button"},on:{"click":_vm.handlePreviewClick}}):_vm._e(),_vm._v(" "),(_vm.isMessageFeatureEnabled)?_c('ar-simple-button',{directives:[{name:"ar-hotkey",rawName:"v-ar-hotkey",value:({
              general: ['Control', 'Enter'],
              mac: ['Meta', 'Enter'],
              disabled: !_vm.enableSendMessageButton,
              action: _vm.sendMessage,
            }),expression:"{\n              general: ['Control', 'Enter'],\n              mac: ['Meta', 'Enter'],\n              disabled: !enableSendMessageButton,\n              action: sendMessage,\n            }"}],attrs:{"text":_vm.sendMessageButtonCopy,"disabled":!_vm.enableSendMessageButton,"loading":_vm.isSendingOutMessage,"type":"purple","data-test-id":"send-message-button"},on:{"click":_vm.sendMessage}}):_vm._e(),_vm._v(" "),(!_vm.isMessageFeatureEnabled)?_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.lockedTooltipMessage
            }),expression:"{\n              content: lockedTooltipMessage\n            }",modifiers:{"top":true}}],style:({ width: _vm.$arMediaQuery.window.minWidth('sm') ? '150px' : '100px' }),attrs:{"text":_vm.sendMessageButtonCopy,"icon-name":"lock","disabled":"","data-test-id":"send-message-button"},on:{"click":_vm.handleGoToPlansClick}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{class:[
        'preview-section',
        _vm.hasNoSpaceForPreview && 'go-on-top-of-edit-section',
        _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max',
        _vm.displayMobilePreview && 'is-visible' ]},[_c('div',{staticClass:"preview-box"},[_c('div',{staticClass:"preview"},[_c('MessageMobileContainer',{attrs:{"display-paginator":_vm.displayCsvPreviewPaginator,"contacts-count":_vm.csvPreviewContacts ? _vm.csvPreviewContacts.length : 0,"contact-index":_vm.selectedCsvPreviewContactIndex},on:{"contactChange":_vm.handleCsvPreviewContactsIndexChange}},[(_vm.messageType === 'sms')?_c('am2-sms-preview',{attrs:{"sender":_vm.smsMessagePreview.sender,"message-body":_vm.smsMessagePreview.body,"message-image":_vm.smsMessagePreview.image,"original-text":_vm.scratchMessageBody,"preview-contact":_vm.selectedCsvPreviewContact}}):_vm._e(),_vm._v(" "),(_vm.messageType === 'facebook')?_c('am2-facebook-messenger-preview',{attrs:{"page-name":_vm.facebookPageObject && _vm.facebookPageObject.name,"page-image-src":_vm.facebookPageObject && _vm.facebookPageObject.imageSrc,"message-body":_vm.messagePreview,"original-text":_vm.scratchMessageBody,"preview-contact":_vm.selectedCsvPreviewContact}}):_vm._e()],1)],1),_vm._v(" "),(_vm.csvPreviewContacts)?_c('ar-link-button',{style:({
            marginTop: '22px',
          }),attrs:{"text":_vm.displayCsvPreviewContacts ? 'Hide live dynamic tags' : 'Preview dynamic tags',"data-test-id":"toogle-dynamic-tags-preview-link"},on:{"click":_vm.handleDisplayCsvPreviewContactsToggle}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }