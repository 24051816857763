<template>
  <div class="events-messages"
       v-infinite-scroll="loadMoreData"
       :infinite-scroll-disabled="isFetchMoreMessagesDisabled"
       :infinite-scroll-distance="10">
    <div class="events-messages__side">
      <am2-menu-vertical
        title="View by"
        :items="viewByOptions"
        :selected-key="activeFilter"
        @select="handleRecipientsMenuSelect"
        custom-padding="16px 16px"
        custom-head-padding="5px 5px 16px 0"
        min-width="188px"
      />
    </div>
    <div class="events-messages__content">
      <div :class="[{'md-max': $arMediaQuery.pageContent.maxWidth('sm')}, 'events-messages__top-controls']">
        <am2-search
          class="search"
          v-model="searchString"
          placeholder="Search messages"
          @enter="() => serverSideSearch(searchString)"
          @clear="() => serverSideSearch(searchString)"
          :style="{width: searchBoxWidth}"
          data-test-id="top-search-input"
        />

        <div class="events-messages__type-selector">
          <MessageTypeSelector
            :selected="currentMessageType"
            @onSelect="handleMessageTypeSelection"
          />
        </div>
      </div>

      <events-overview-tables
        v-if="isOverviewPage"
        :search-string="overviewSearch"
        :current-message-type="currentMessageType"
        :linked-event="eventOid"
        @setMessageFilter="setMessageFilter"
        @cancelMessage="handleMessageCancelled"
        @cloneMessage="handleMessageAdded"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
      <MessagesTable
        v-else
        :active-filter="activeFilter"
        @cloneMessage="handleMessageAdded"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />

      <MessagePreviewCombined
        :message-prop="messagePreviewProp"
        @close="messagePreviewProp = null"
      />
    </div>
  </div>
</template>

<script>
import EventsOverviewTables from '~/pages/events/components/events-overview-tables.vue'
import MessageTypeSelector from '~/pages/message-center/messages/components/message-type-selector.vue'
import {mapActions, mapMutations, mapState} from 'vuex'
import MessagesTable from '~/pages/message-center/messages/messages-table.vue'
import MessagePreviewCombined from '~/components/modals/message/meesage-preview-combined/MessagePreviewCombined.vue'

export default {
  name: 'EventMessages',
  components: {MessagePreviewCombined, MessagesTable, MessageTypeSelector, EventsOverviewTables},
  data() {
    return {
      searchString: '',
      currentMessageType: 'all',
      overviewSearch: '',
      activeFilter: 'all',
      viewByOptions: [
        {
          value: 'Overview',
          key: 'all'
        },
        {
          value: 'Drafts',
          key: 'draft',
        },
        {
          value: 'Scheduled',
          key: 'scheduled',
        },
        {
          value: 'Sent',
          key: 'completed',
        },
      ],
      messagePreviewProp: null,
    }
  },
  computed: {
    ...mapState({
      isFetchingMessages: state => state.message.isFetchingMessages,
      isNoMoreMessages: state => state.message.isNoMoreMessages,
      hasFetchMessagesFailed: state => state.message.hasFetchMessagesFailed,
      isFetchingOverviewMessages: state => state.message.isFetchingOverviewMessages,
      hasFetchOverviewMessagesFailed: state => state.message.hasFetchOverviewMessagesFailed,
      messageLists: state => state.messageList.messageLists,
      messagesOverview: state => state.message.messagesOverview,
    }),
    eventOid() {
      return Number(this.$route.params.oid)
    },
    searchBoxWidth() {
      if (this.$arMediaQuery.pageContent.minWidth('md')) {
        return '330px';
      } else if (this.$arMediaQuery.pageContent.minWidth('sm')) {
        return 'calc(100% - 160px)'
      } else {
        return 'calc(100% - 80px)'
      }
    },

    isOverviewPage() {
      return this.activeFilter === 'all';
    },

    isFetchMoreMessagesDisabled() {
      return this.isOverviewPage ||
        this.isNoMoreMessages ||
        this.hasFetchMessagesFailed ||
        this.isFetchingMessages;
    },

    drafts() {
      return this.addMessageList(this.messagesOverview['draft']?.rows || []);
    },

    sent() {
      return this.addMessageList(this.messagesOverview['completed']?.rows || []);
    },

    inProgress() {
      return this.addMessageList(this.messagesOverview['in-progress']?.rows || []);
    },

    scheduled() {
      return this.addMessageList(this.messagesOverview['scheduled']?.rows || []);
    },

    noMessages() {
      return !this.isFetchingOverviewMessages
        && (this.hasFetchOverviewMessagesFailed ||
          ( this.drafts.length === 0
            && this.sent.length === 0
            && this.inProgress.length === 0
            && this.scheduled.length === 0 )
        );
    },
  },
  beforeRouteLeave (to, from, next) {
    if ((from.path.includes('view/messages') && to.path.includes('edit/basic')) ||
        (from.path.includes('view/messages') && to.path.startsWith('/message-center/messages/'))) {
      // Modify meta data if coming to a message page, to allow back navigation
      to.meta.backPage = this.$route.path;
    }
    next();
  },
  beforeDestroy() {
    this['message/RESET_OVERVIEW_LINKED_EVENT']();
  },
  methods: {
    ...mapActions([
      'message/FETCH_MORE_MESSAGES',
      'message/CANCEL_MESSAGES_FETCHING',
      'message/CANCEL_MESSAGES_FETCHING',
    ]),
    ...mapMutations([
      'message/RESET_MESSAGES',
      'message/RESET_OVERVIEW_LINKED_EVENT'
    ]),
    async serverSideSearch(search) {
      this.searchString = search;
      this.overviewSearch = search;
      await this.reloadData();
    },

    async reloadData() {
      await this.loadMoreData(true);
    },

    async loadMoreData(reload = false) {
      const status = this.activeFilter === 'all' ? null : this.activeFilter;
      const provider = this.currentMessageType === 'all' ? null : this.currentMessageType;

      this['message/FETCH_MORE_MESSAGES']({
        status,
        provider,
        reload,
        search: this.searchString,
      });
    },

    handleMessageTypeSelection(option) {
      this.currentMessageType = option;
      this['message/CANCEL_MESSAGES_FETCHING']();
      this.reloadData();
    },

    handleRecipientsMenuSelect(item) {
      this.setMessageFilter(item.key);
      this['message/RESET_MESSAGES']();
    },

    setMessageFilter(keyword) {
      this.activeFilter = keyword;
      this['message/CANCEL_MESSAGES_FETCHING']();
      this.reloadData();
    },

    async handleMessageAdded(message) {
      /**
       * New message can be in any kind, so let's reset filter in case
       * our promoters not see it.
       */
      await this.reloadData();
      this.setMessageFilter('all');

      // Let's scroll to top to see the new message
      window.scrollTo(0, 0);
    },

    async handleMessageCancelled() {
      await this.reloadData();
    },

    async openMessagePreviewModal(data) {
      this.messagePreviewProp = data;
    },

    addMessageList(messages) {
      return messages.map((msg) => {
        const messageListOid = msg.meta.messageListOid;
        const messageList = this.messageLists.find(ml => ml.oid === messageListOid);

        if (messageList)
          return { ...msg, messageList };
        return msg;
      });
    },
  }
}
</script>

<style lang="scss">
.events-messages {
  display: flex;
  width: 100%;

  &__top-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    flex-grow: 1;
    overflow-x: auto;
    max-width: 1084px;
    transition: 0.3s all;
    margin-left: 0;
    margin-bottom: 50px;
    padding-left: 24px;
  }

  &__side {
    max-width: 212px;
  }
}
</style>
