import { ActionTree } from 'vuex'
import { RootState } from '@/store/modules/types';
import { AdminState } from './types';
import { isErrorStatus } from '@/api/utils';
import { CustomApiError } from '@/api/types';

export const adminActions: ActionTree<AdminState, RootState> = {
  async FETCH_MORE_PROMOTERS(
    { state, rootState, rootGetters, commit },
    {
      top = 50,
      select = 'name,oid,emailAddress',
      orderby = 'name asc',
      count = false,
      filterString = null,
      reload = false,
    },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    if (reload) {
      commit('RESET_PROMOTERS');
    }

    try {
      commit('SET_IS_FETCHING_PROMOTERS', true);

      let filter;
      if (!!filterString) {
        // Cannot check for oid and string in a query
        if (/^\d+$/.test(filterString))
          filter = `sysActivep AND oid = ${filterString}`
        else
          filter = `sysActivep AND (name ILIKE "%${filterString}%" OR emailAddress ILIKE "%${filterString}%")`
      }

      const params = {
        $top: top,
        $skip: state.promoters.length,
        $select: select || null,
        $orderby: orderby || null,
        $count: count || null,
        $filter: filter || null,
      };

      const { data } = await this.$axios.get('/promoter/', { params })

      if (count) {
        commit('SET_TOTAL_PROMOTERS_COUNT', data.count)
        commit('SET_PROMOTERS', data.rows);
      } else {
        commit('SET_PROMOTERS', data);
      }

    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoters',
      });
    } finally {
      commit('SET_IS_FETCHING_PROMOTERS', false);
    }
  },

  async FETCH_PROMOTER(
    { rootState, rootGetters, commit },
    { oid },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      commit('SET_IS_FETCHING_CURRENT_PROMOTER_ACCOUNT', true);

      const { data } = await this.$axios.get(`/promoter/${oid}`)

      commit('SET_CURENT_PROMOTER_ACCOUNT', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoters',
      });
    } finally {
      commit('SET_IS_FETCHING_CURRENT_PROMOTER_ACCOUNT', false);
    }
  },

  async UPDATE_PROMOTER(
    { rootState, rootGetters, dispatch },
    { oid, promoterObj }
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$axios.patch(`/promoter/${oid}`, promoterObj)
      dispatch('FETCH_PROMOTER', { oid })
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to update promoter',
      });
      return false
    }
  },

  async DELETE_PROMOTER(
    { rootState, rootGetters },
    { oid }
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$axios.delete(`/promoter/${oid}`)

      this.$arNotification.push({
        type: 'success',
        message: 'Promoter has been deleted'
      })
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to delete promoter',
      })
      return false
    }
  },

  async FETCH_MORE_PROMOTER_ACCOUNTS(
    { state, rootState, rootGetters, commit },
    {
      top = 50,
      select = 'oid,promoterOid,emailAddress,firstName,lastName,sysCtime',
      orderby = 'name asc',
      count = false,
      filterString = null,
      reload = false,
    },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    if (reload) {
      commit('RESET_PROMOTER_ACCOUNTS');
    }

    try {
      commit('SET_IS_FETCHING_PROMOTER_ACCOUNTS', true);

      let filter;
      if (!!filterString) {
        // Cannot check for oid and string in a query
        if (/^\d+$/.test(filterString))
          filter = `sysActivep AND oid = ${filterString}`
        else
          filter = `sysActivep AND (firstName ILIKE "%${filterString}%" OR lastName ILIKE "%${filterString}%" OR emailAddress ILIKE "%${filterString}%")`
      }

      const params = {
        $top: top,
        $skip: state.promoterAccounts.length,
        $select: select || null,
        $orderby: orderby || null,
        $count: count || null,
        $filter: filter || null,
      };

      const { data } = await this.$axios.get('/promoter/-418/account', { params })

      if (count) {
        commit('SET_TOTAL_PROMOTER_ACCOUNTS_COUNT', data.count)
        commit('SET_PROMOTER_ACCOUNTS', data.rows);
      } else {
        commit('SET_PROMOTER_ACCOUNTS', data);
      }

    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoters',
      });
    } finally {
      commit('SET_IS_FETCHING_PROMOTER_ACCOUNTS', false);
    }
  },

  async UPDATE_PROMOTER_ACCOUNT(
    { rootState, rootGetters, dispatch },
    { oid, accountOid, promoterAccountObj }
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$axios.patch(`/promoter/${oid}/manage-account/${accountOid}`, promoterAccountObj)
      dispatch('FETCH_PROMOTER', { oid })
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to update accont',
      })
      return false
    }
  },

  async DELETE_PROMOTER_ACCOUNT(
    { rootState, rootGetters, dispatch },
    { oid, accountOid }
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$axios.delete(`/promoter/${oid}/account/${accountOid}`)
      this.$arNotification.push({
        type: 'success',
        message: 'Account has been deleted'
      })
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to delete accont',
      })
      return false
    }
  },

  async FETCH_PROMOTER_ACCOUNT_OWNER(
    { rootState, rootGetters, commit },
    oid: number,
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const promoterAccount = await this.$api.promoterAccounts.get(oid);

      commit('SET_PROMOTER_ACCOUNT_OWNER', promoterAccount);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch account promoter owner',
      });
    }
  },

  async UPDATE_PROMOTER_ACCOUNT_OWNER(
    { rootState, rootGetters, commit },
    { oid, accountObj }
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const promoterAccount = await this.$api.promoterAccounts.patch(oid, accountObj);

      commit('SET_PROMOTER_ACCOUNT_OWNER', promoterAccount);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch account promoter owner',
      });
    }
  },

  async FETCH_MASQUERADER_PROMOTER_ACCOUNTS(
    { rootState, rootGetters, commit },
    oids: number[],
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const masqueraderPromoterAccounts = await this.$api.promoterAccounts.queryByOids(oids);

      commit('SET_MASQUERADER_PROMOTER_ACCOUNTS', masqueraderPromoterAccounts);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch masquerader promoter accounts',
      });
    }
  },

  async FETCH_ADMIN_MESSAGE_TASKS(
    { state, rootState, commit, rootGetters },
    { searchQuery = '', append = false, top = 50, types = ['completed', 'in-progress', 'failed', 'scheduled'], orderby = 'oid desc' },
  ) {
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { promoterOid } = rootState.auth.account;

    try {
      commit('SET_IS_FETCHING_ADMIN_FAN_MESSAGE_TASKS', true);

      const uri = `/promoter/${promoterOid}/task`;
      const skip = state.fanMessageTasks && append ? state.fanMessageTasks.length : 0;

      let filterString = `name = fan-message`;
      if (types.length > 0) {
        filterString += ` AND (status =`
        filterString += types.join(` OR status = `);
        filterString += `)`;
      }

      if (searchQuery && searchQuery.length > 0) {
        if (isNaN(searchQuery)) {
          filterString += ` AND (promoterAccount[emailAddress] ILIKE "%${searchQuery}%" OR promoterAccount[firstName] ILIKE "%${searchQuery}%" OR promoterAccount[lastName] ILIKE "%${searchQuery}%")`
        } else {
          const searchQueryInt = parseInt(searchQuery);
          filterString += ` AND (promoterOid = ${searchQueryInt} OR oid = ${searchQueryInt} OR promoterAccount[emailAddress] ILIKE "%${searchQuery}%" OR promoterAccount[firstName] ILIKE "%${searchQuery}%" OR promoterAccount[lastName] ILIKE "%${searchQuery}%")`;
        }
      }

      const { data } = await this.$axios.get(uri, {
        params: {
          $top: top,
          $count: true,
          $skip: skip,
          $orderby: orderby,
          $filter: filterString,
          $select: 'promoterAccount,promoterOid,oid,statusDetails,status,meta,started,scheduledAt,provider'
        }
      });

      if (data.rows.length > 0) {
        if (append) {
          commit('CONCAT_ADMIN_FAN_MESSAGE_TASKS', data.rows);
        } else {
          commit('SET_ADMIN_FAN_MESSAGE_TASKS', data.rows);
        }
      } else {
        commit('SET_NO_MORE_ADMIN_FAN_MESSAGE_TASKS', true);
      }

    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch message tasks' });
      commit('SET_HAS_FAILED_FETCHING_ADMIN_FAN_MESSAGE_TASKS', true);
    } finally {
      commit('SET_IS_FETCHING_ADMIN_FAN_MESSAGE_TASKS', false);
    }
  },

  async FETCH_PROMOTER_MESSAGE_TASKS_OVERVIEW(
    { state, rootState, commit, rootGetters },
    {
      promoterOid = null,
      searchQuery = '',
      limit = { 'in-progress': 'all', completed: 3, scheduled: 3, failed: 3 },
    },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;
    if (state.isFetchingPromoterMessageTasks) return;

    const { promoterOid: adminPromoterOid } = rootState.auth.account;

    try {
      commit('SET_HAS_FAILED_FETCHING_PROMOTER_MESSAGE_TASKS', false);
      commit('SET_IS_FETCHING_PROMOTER_MESSAGE_TASKS', true);

      const uri = `/promoter/${adminPromoterOid}/task`;
      const messages = {};

      const requests = Object.entries(limit).map(([statusKey, top]) => {
        const status = String(statusKey);

        let order;
        if (status === "scheduled")
          order = "scheduled_at ASC";
        else if (status === "in-progress")
          order = "sys_ctime DESC";
        else if (status === "completed")
          order = "started DESC NULLS LAST";
        else if (status === "failed")
        order = "started DESC NULLS LAST";

        let filterString = `name = fan-message AND status = ${status} AND promoter-oid = ${promoterOid}`;

        if (searchQuery && searchQuery.length > 0) {
          filterString += `AND (meta->>'message-body' ILIKE '%${searchQuery}%' OR customer_name ILIKE '%${searchQuery}%')`;
        }

        const params = {
          $top: top,
          $count: true,
          $skip: 0,
          $orderby: order || 'sys_ctime DESC',
          $filter: filterString,
          $select: 'promoterAccount,promoterOid,oid,statusDetails,status,meta,started,scheduledAt,provider',
        };

        return this.$axios.get(uri, { params })
          .then(({ data }) => {
            (messages as any)[statusKey] = data;
          });
      });

      await Promise.all(requests);

      commit('SET_PROMOTER_MESSAGE_TASKS', messages);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({ type: 'error', message: 'Failed to fetch promoter messages' });
      commit('SET_HAS_FAILED_FETCHING_PROMOTER_MESSAGE_TASKS', true);
    } finally {
      commit('SET_IS_FETCHING_PROMOTER_MESSAGE_TASKS', false);
    }
  },

  async FETCH_ADMIN_STATS(
    { state, rootState, commit, rootGetters },
  ) {
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;
    const { promoterOid } = rootState.auth.account;

    const stats = {
      scheduledMessageCount: 0,
      sendingMessageCount: 0,
      failedMessageCount: 0,
    };

    const typeGroups = [
      {
        types: ['scheduled'],
        stat: 'scheduledMessageCount',
      },
      {
        types: ['in-progress'],
        stat: 'sendingMessageCount',
      },
      {
        types: ['failed'],
        stat: 'failedMessageCount',
      },
    ];

    commit('SET_IS_FETCHING_ADMIN_STATS', true);

    const actions = typeGroups.map(typeGroup => {
      const uri = `/promoter/${promoterOid}/task`;
      let filterString = `name = fan-message`;

      if (typeGroup.types.length > 0) {
        filterString += ` AND (status =`
        filterString += typeGroup.types.join(` OR status = `);
        filterString += `)`;
      }

      return this.$axios.get(uri, {
        params: {
          $top: 0,
          $count: true,
          $filter: filterString,
        }
      });
    });

    await Promise.all(actions).then(responses => {
      responses.forEach((response, index) => {
        if (!response.data) return;
        if (index === 0) {
          stats.scheduledMessageCount = response.data.count;
        } else if (index === 1) {
          stats.sendingMessageCount = response.data.count;
        } else if (index === 2) {
          stats.failedMessageCount = response.data.count;
        }
      });
    });

    commit('SET_TOTAL_ADMIN_STATS', stats);
    commit('SET_IS_FETCHING_ADMIN_STATS', false);
  },


  async RETRY_ADMIN_MESSAGE_TASK(
    { state, rootState, commit, rootGetters },
    { taskOid },
  ) {
    if (!taskOid || isNaN(taskOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { promoterOid } = rootState.auth.account;

    this.$arNotification.push({ type: 'error', message: `TODO - Add a RETRY method. Oid: ${taskOid}` });

  },

  async CANCEL_ADMIN_MESSAGE_TASK(
    { state, rootState, commit, rootGetters },
    { taskOid },
  ) {
    if (!taskOid || isNaN(taskOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { promoterOid } = rootState.auth.account;
    const uri = `/promoter/${promoterOid}/task/${taskOid}`;

    try {
      await this.$axios.patch(uri, {
        status: 'cancelled',
      });
      this.$arNotification.push({
        type: 'success',
        message: `Successfully cancelled message`,
      });
      commit('REMOVE_MESSAGE_FROM_ADMIN_FAN_MESSAGE_TASKS', taskOid);
    } catch (error: any) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: `Failed to cancel message: ${error.message}`,
      });
    }
  },

  async ARCHIVE_ADMIN_MESSAGE_TASK(
    { state, rootState, commit, rootGetters },
    { taskOid },
  ) {
    if (!taskOid || isNaN(taskOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { promoterOid } = rootState.auth.account;
    const uri = `/promoter/${promoterOid}/task/${taskOid}`;

    try {
      await this.$axios.patch(uri, {
        status: 'archived',
      });

      this.$arNotification.push({
        type: 'success',
        message: `Successfully archived message`,
      });
      commit('REMOVE_MESSAGE_FROM_ADMIN_FAN_MESSAGE_TASKS', taskOid);
    } catch (error: any) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: `Failed to archive message: ${error.message}`,
      });
    }
  },

  async FORCE_REFRESH_PROMOTER_SALES_STATS(
    { state, rootState, commit, rootGetters },
    { targetPromoterOid },
  ) {
    if (!targetPromoterOid || isNaN(targetPromoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { promoterOid } = rootState.auth.account;
    const uri = `/promoter/${promoterOid}/sales-stats-refresh-all?promoterOid=${targetPromoterOid}`;

    try {
      await this.$axios.post(uri);
      this.$arNotification.push({
        type: 'success',
        message: `Started refresh of sales stats snapshots for promoter ${targetPromoterOid}`,
      });
    } catch (error) {
      this.$arNotification.push({
        type: 'error',
        message: `Failed to refresh sales stats snapshots for promoter ${targetPromoterOid}`,
      });

    }
  },
  async FETCH_MESSAGE_LIST(
    { state, rootState, commit, rootGetters },
    { promoterOid },
  ) {
    if (!promoterOid || isNaN(promoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;


    const { data, status } = await this.$axios.get(`/promoter/${promoterOid}/message-list`, {
      params: {
         $top: 'all',
         $select: 'name,promoterOid',
         $filter: `promoter_oid=${promoterOid}`,
      }
    });
    return data;

  },
  async FETCH_CAMPAIGN_LIST(
    { state, rootState, commit, rootGetters },
    { promoterOid },
  ) {
    if (!promoterOid || isNaN(promoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;


    const { data, status } = await this.$axios.get(`/promoter/${promoterOid}/campaign`, {
      params: {
         $top: 'all',
         $select: 'name,promoterOid',
         $filter: `promoter_oid=${promoterOid}`
      }
    });
    return data;

  },
  async FETCH_EVENT_LIST(
    { state, rootState, commit, rootGetters },
    { promoterOid },
  ) {
    if (!promoterOid || isNaN(promoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { data, status } = await this.$axios.get(`/promoter/${promoterOid}/event`, {
      params: {
         $top: 'all',
         $select: 'name,promoterOid',
         $filter: `promoter_oid=${promoterOid}`
      }
    });
    return data;

  },
  async FETCH_TAG_LIST(
    { state, rootState, commit, rootGetters },
    { promoterOid },
  ) {
    if (!promoterOid || isNaN(promoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { data, status } = await this.$axios.get(`/promoter/${promoterOid}/tag`, {
      params: {
         $top: 'all',
         $select: 'name,promoterOid',
         $filter: `promoter_oid=${promoterOid}`
      }
    });
    return data;

  },
  async FETCH_TASK_LIST(
    { state, rootState, commit, rootGetters },
    { promoterOid },
  ) {
    if (!promoterOid || isNaN(promoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { data, status } = await this.$axios.get(`/promoter/${promoterOid}/task`, {
      params: {
         $top: 'all',
         $select: 'name,promoterOid',
         $filter: `promoter_oid=${promoterOid}`
      }
    });
    return data;

  },
  async FORCE_REFRESH_STATS(
    { state, rootState, commit, rootGetters },
    { targetPromoterOid, refreshType, statOid },
  ) {
    if (!targetPromoterOid || isNaN(targetPromoterOid)) return;
    if (state.isFetchingFanMessageTasks) return;
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    const { promoterOid } = rootState.auth.account;
    let typeUrl = '';
    switch(refreshType.key){
      case 'messageList': {
        typeUrl = 'message-list'
        break;
      }
      case 'campaginStats': {
        typeUrl = 'campaign'
        break;
      }
      case 'eventTicketStats': {
        typeUrl = 'event'
        break;
      }
      case 'tagCountUpdate': {
        typeUrl = 'tag'
        break;
      }
      case 'taskStats': {
        typeUrl = 'task'
        break;
      }
    }

    const uri = `/promoter/${promoterOid}/${typeUrl}/${statOid}/stat-refresh/?promoterOid=${targetPromoterOid}`;

    try {
      await this.$axios.post(uri);
      this.$arNotification.push({
        type: 'success',
        message: `Started refresh of ${refreshType.name} for promoter ${targetPromoterOid}`,
      });
    } catch (error) {
      this.$arNotification.push({
        type: 'error',
        message: `Failed to refresh ${refreshType.name} for promoter ${targetPromoterOid}`,
      });

    }
  },
  // Default user roles
  async FETCH_DEFAULT_USER_ROLES(
    { rootState, rootGetters, commit },
    { searchString }
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const { data, status } = await this.$axios.get(`/user-role`, {
        params: {
          $filter: searchString ? `name ILIKE "%${searchString}%"` : null
        }
      })
      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetching default user roles error',
          message: `Error fetching default user roles`,
          status,
        };
        throw apiError;
      }

      commit('SET_DEFAULT_USER_ROLES', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch default user roles',
      });
    }
  },
  // Promoter user roles
  async FETCH_PROMOTER_USER_ROLES(
    { rootState, rootGetters, commit },
    { oid, searchString },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const { data, status } = await this.$axios.get(`/promoter/${oid}/user-role`, {
        params: {
          $filter: searchString ? `name ILIKE "%${searchString}%"` : null
        }
      })
      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetching promoter user roles error',
          message: `Error fetching promoter roles for OID ${oid}`,
          status,
        };
        throw apiError;
      }

      commit('SET_PROMOTER_USER_ROLES', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoter user roles',
      });
    }
  },
  // Fetch Promoter region settings
  async FETCH_PROMOTER_REGION_SETTINGS(
    { rootState, rootGetters, commit },
    { oid },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const data = await this.$api.promoterProperty.get(oid, 'promoter-region-settings')

      commit('SET_PROMOTER_REGION_SETTINGS', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoter region settings',
      });
    }
  },
  // Create new Promoter region settings
  async CREATE_PROMOTER_REGION_SETTINGS(
    { rootState, rootGetters, commit },
    { oid, property },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const data = await this.$api.promoterProperty.post(oid, {
        type: 'promoter-region-settings',
        property: property.defaultTimezone,
        allowMany: false,
        promoterOid: oid,
        additional_info: property
      })

      commit('SET_PROMOTER_REGION_SETTINGS', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new promoter region settings',
      });
    }
  },
  // Update new Promoter region settings
  async UPDATE_PROMOTER_REGION_SETTINGS(
    { rootState, rootGetters, commit },
    { oid, propertyOid, property },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const data = await this.$api.promoterProperty.patch(oid, propertyOid, {
        type: 'promoter-region-settings',
        property: property.defaultTimezone,
        allowMany: false,
        promoterOid: oid,
        additional_info: property
      })

      commit('SET_PROMOTER_REGION_SETTINGS', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new promoter region settings',
      });
    }
  },
  async FETCH_TWILIO_SUBACCOUNTS(
    { rootState, state, commit },
    { promoterOid }
  ) {
    if (!rootState.auth.account) { return null; }

    commit('SET_IS_FETCHING_TWILIO_SUBACCOUNTS', true)
    try {
      const data = await this.$api.twilioSubAccounts.get(promoterOid);

      commit('SET_TWILIO_SUBACCOUNTS', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_TWILIO_SUBACCOUNTS', false)
    }
  },
  // Create new Twilio Sub-account
  async CREATE_TWILIO_SUBACCOUNT(
    { rootState, rootGetters, commit },
    { promoterOid, twilioId, phoneServiceSid },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const data = await this.$api.twilioSubAccounts.post(promoterOid, {
        promoterOid,
        twilioId,
        phoneServiceSid,
      })

      commit('SET_TWILIO_SUBACCOUNTS', [data]);

      this.$arNotification.push({
        type: 'success',
        message: 'Successfully added Twilio Sub-account',
      });

      return data
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new Twilio Sub-account',
      });
      return false
    }
  },
  // Update existing Twilio Sub-account
  async UPDATE_TWILIO_SUBACCOUNT(
    { rootState, rootGetters, commit },
    { promoterOid, twilioSubaccountId, twilioId, phoneServiceSid },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$api.twilioSubAccounts.patch(promoterOid, twilioSubaccountId, {
        twilioId,
        phoneServiceSid
      })

      commit('SET_TWILIO_SUBACCOUNT', {
        twilioSubaccountId,
        twilioSubAccountData: {
          twilioId,
          phoneServiceSid,
        }
      });

      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new Twilio Sub-account',
      });
      return false
    }
  },
  // Delete Twilio Sub-account
  async DELETE_TWILIO_SUBACCOUNT(
    { rootState, rootGetters, commit },
    items
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$api.twilioSubAccounts.delete(items.promoterOid, items.twilioSubaccountId)

      commit('RESET_TWILIO_SUBACCOUNTS');

      this.$arNotification.push({
        type: 'success',
        message: 'Successfully deleted Twilio Sub-account',
      });

      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to delete Twilio Sub-account',
      });
      return false
    }
  },
  async FETCH_ALPHANUMERIC_SENDER(
    { rootState, state, commit },
    { promoterOid }
  ) {
    if (!rootState.auth.account) { return null; }

    commit('SET_IS_FETCHING_SMS_SENDER_IDS', true)
    try {
      const data = await this.$api.twilioSenderIds.get(promoterOid);

      commit('SET_SMS_SENDER_IDS', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_SMS_SENDER_IDS', false)
    }
  },
  // Create new alphanumeric sender id
  async CREATE_ALPHANUMERIC_SENDER(
    { rootState, rootGetters, commit },
    { promoterOid, twilioSubaccountOid, token, friendlyName, messageServiceSid, countries },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      const data = await this.$api.twilioSenderIds.post(promoterOid, {
        token,
        friendlyName,
        promoterOid,
        twilioSubaccountOid,
        messageServiceSid,
        countries
      })

      this.$arNotification.push({
        type: 'success',
        message: 'Successfully added Twilio Alphanumeric SMS Sender',
      });
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new Twilio Sub-account',
      });
      return false
    }
  },
  // Update existing Twilio Sub-account
  async UPDATE_ALPHANUMERIC_SENDER(
    { rootState, rootGetters, commit },
    { promoterOid, twilioSubaccountOid, smsSenderOid, token, friendlyName, messageServiceSid, countries },
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$api.twilioSenderIds.patch(promoterOid, smsSenderOid, {
        promoterOid,
        twilioSubaccountOid,
        token,
        friendlyName,
        messageServiceSid,
        countries
      })

      commit('SET_SMS_SENDER_ID', {
        smsSenderOid,
        twilioSmsSenderData: {
          promoterOid,
          twilioSubaccountOid,
          token,
          friendlyName,
        }
      });

      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to update Twilio SMS Sender',
      });
      return false
    }
  },
  // Delete Alphanumeric Sender ID
  async DELETE_ALPHANUMERIC_SENDER(
    { rootState, rootGetters, commit },
    items
  ) {
    if (!rootState.auth.account) return;
    if (!rootGetters['auth/isAdminAccount']) return;

    try {
      await this.$api.twilioSenderIds.delete(items.promoterOid, items.smsSenderOid)

      commit('REMOVE_FROM_SMS_SENDER_IDS', items.smsSenderOid);

      this.$arNotification.push({
        type: 'success',
        message: 'Successfully deleted Twilio SMS Sender ID',
      });

      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new Twilio SMS Sender',
      });
      return false
    }
  },
  async FETCH_SMS_ENABLED_COMPANIES(
    { rootState, state, commit },
  ) {
    if (!rootState.auth.account) { return null; }
  
    commit('SET_IS_FETCHING_SMS_ENABLED_COMPANIES', true)
    try {
      const data = await this.$api.twilioSmsCompanies.get();

      commit('SET_SMS_ENABLED_COMPANIES', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_SMS_ENABLED_COMPANIES', false)
    }
  },
}
