<template>
  <div
    class="select-dynamic-tags-section"
  >
    <div
      class="dynamic-tag-item"
      v-for="item in dynamicTags"
      :key="item.name"
      @click="() => {handleDynamicTagClick(item)}"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectDynamicTagsSection',

  props: {
    dynamicTags: {
      type: Array,
      default: null,
    },
  },

  methods: {
    async handleDynamicTagClick(item) {
      this.$emit('select', item)
    },
  },
};
</script>

<style lang="scss" scoped>
.select-dynamic-tags-section {
  background: #f3f6fa;
  padding: 30px 40px 50px;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  .dynamic-tag-item {
    padding: 10px 0 10px 0;
    width: 100%;
    text-align: center;
    border-radius: 5px;

    &:hover {
      background-color: $blueGrey500;
      cursor: pointer;
    }
  }
}
</style>
