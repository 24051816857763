var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutside),expression:"handleClickOutside"}],staticClass:"line-annotation-module",style:({ left: (_vm.tooltipLeftX + "px") }),on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[(_vm.activeAnnotation && _vm.isTooltipShowing)?_c('div',{staticClass:"line-annotation"},[(_vm.activeAnnotation.provider === 'custom')?[(_vm.isLineAnnotationEditing)?_c('div',[_c('ar-textarea',{staticClass:"line-annotation__textarea line-annotation__textarea--title",attrs:{"placeholder":'Custom title',"rows":2},model:{value:(_vm.titleValue),callback:function ($$v) {_vm.titleValue=$$v},expression:"titleValue"}}),_vm._v(" "),_c('ar-textarea',{staticClass:"line-annotation__textarea",attrs:{"placeholder":'Add a note',"rows":3},model:{value:(_vm.bodyValue),callback:function ($$v) {_vm.bodyValue=$$v},expression:"bodyValue"}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"line-annotation__save-button",attrs:{"type":"purple","text":"Save"},on:{"click":_vm.handleSaveEdit}}),_vm._v(" "),_c('div',{staticClass:"line-annotation__delete-button-wrapper"},[_c('ar-link-button',{staticClass:"line-annotation__delete-button",attrs:{"text":"Delete","has-underline":true,"color":_vm.$arStyle.color.red500},on:{"click":_vm.handeDeleteAnnotation}})],1)],1):_c('div',[_c('div',{staticClass:"line-annotation__custom-header"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.activeAnnotation.title,
            }),expression:"{\n              content: activeAnnotation.title,\n            }",modifiers:{"top":true}}],attrs:{"size":"14px","weight":"bold","multipleLines":"","maxLines":3,"text":_vm.activeAnnotation.title ? _vm.activeAnnotation.title : 'Custom annotation'}}),_vm._v(" "),_c('div',{staticClass:"line-annotation__custom-edit-btn",on:{"click":_vm.handleEdit}},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.purple500,
            textDecoration: 'underline',
            cursor: 'pointer',
            userSelect: 'none',
            marginLeft: 'auto',
          }),attrs:{"size":"xxs","text":"Edit"}})],1)],1),_vm._v(" "),_c('ar-text',{staticClass:"line-annotation__desc",attrs:{"size":"12px","multipleLines":"","maxLines":3,"text":_vm.activeAnnotation.body ? _vm.activeAnnotation.body : 'Add a note'}})],1)]:_vm._e(),_vm._v(" "),(_vm.activeAnnotation.provider === 'campaign')?[_c('div',{staticClass:"line-annotation__header"},[_c('div',{staticClass:"line-annotation__icon"},[_c('ar-icon',{attrs:{"name":"campaign-circles","size":"24px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('div',{staticClass:"line-annotation__link",on:{"click":_vm.handleViewCampaign}},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.purple500,
            textDecoration: 'underline',
            cursor: 'pointer',
            userSelect: 'none',
          }),attrs:{"text":"View campaign","size":"xs"}})],1)]),_vm._v(" "),_c('div',{staticClass:"line-annotation__content"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.activeAnnotation.body,
          }),expression:"{\n            content: activeAnnotation.body,\n          }",modifiers:{"top":true}}],staticClass:"line-annotation__title",attrs:{"text":_vm.activeAnnotation.body,"size":"14px","weight":"bold","multipleLines":"","maxLines":3}}),_vm._v(" "),_c('ar-text',{staticClass:"line-annotation__desc",attrs:{"text":_vm.handleGetFormattedDate(),"size":"12px","multipleLines":"","maxLines":3}})],1)]:_vm._e(),_vm._v(" "),(_vm.activeAnnotation.provider === 'email' || _vm.activeAnnotation.provider === 'sms')?[_c('div',{staticClass:"line-annotation__header"},[_c('div',{staticClass:"line-annotation__icon"},[_c('ar-icon',{attrs:{"name":_vm.activeAnnotation.provider === 'email' ? 'email' : 'sms',"size":"24px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('div',{staticClass:"line-annotation__link",on:{"click":_vm.handleViewMessage}},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.purple500,
            textDecoration: 'underline',
            cursor: 'pointer',
            userSelect: 'none',
          }),attrs:{"text":"View message","size":"xs"}})],1)]),_vm._v(" "),_c('div',{staticClass:"line-annotation__content"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.activeAnnotation.body,
          }),expression:"{\n            content: activeAnnotation.body,\n          }"}],staticClass:"line-annotation__title",attrs:{"text":_vm.activeAnnotation.body,"size":"14px","weight":"bold","multipleLines":"","maxLines":3}}),_vm._v(" "),_c('ar-text',{staticClass:"line-annotation__desc",attrs:{"text":_vm.handleGetFormattedDate(),"size":"12px","multipleLines":"","maxLines":3}})],1),_vm._v(" "),(_vm.isConversionEnabled)?[_c('ar-divider',{staticClass:"line-annotation__divider"}),_vm._v(" "),_c('div',{staticClass:"line-annotation__footer"},[_c('ar-text',{staticClass:"line-annotation__footer-text",attrs:{"text":_vm.getFormattedRevenue(),"size":"xs"}})],1)]:_vm._e()]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }