<template>
  <portal to="modal" :disabled="!isOpen" :style="{ position: 'absolute' }">
    <ar-modal
      class="custom-field-modal"
      :is-open="isOpen"
      :header="`${isEditMode ? 'Edit' : 'Add'} Custom Field`"
      width="600px"
      height="700px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
      data-test-id="custom-field-modal"
    >
      <div slot="body" :class="['body', $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3']">
        <div class="body-container">
          <ar-text
            text="Choose an extisting custom field or create a new one."
            size="xs"
          />
          <ar-field label="Existing Custom Fields (optional)" class="u-margin-top-8">
            <ar-select
              class="field-type-select"
              :items="displayedCustomFields"
              placeholder="Select existing custom field"
              :hide-arrow="false"
              showEmptyState
              data-test-id="existing-custom-fields"
              @select="handleExistingCustomFieldSelect"
            >
              <template slot="selected-item" slot-scope="selectedItemScope">
                <div
                  v-if="selectedItemScope.item"
                  :style="{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                  }"
                  :data-test-id="`message-list-item-${selectedItemScope.item}`"
                >
                  <ar-text
                    size="xs"
                    v-if="selectedItemScope.item"
                    :text="selectedItemScope.item.name"
                    :style="{
                      width: 'auto',
                      marginLeft: '10px',
                      color: $arStyle.color.blueGrey800,
                    }"
                    weight="normal"
                  />
                </div>
                <div
                  v-else
                  :style="{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '0 14px',
                  }"
                >
                  <ar-text
                    size="xs"
                    text="Select list type"
                    :style="{
                      color: $arStyle.color.blueGrey600,
                    }"
                  />
                </div>
              </template>
              <template slot="item" slot-scope="itemScope">
                <div
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    height: '55px',
                    padding: '0 15px',
                    cursor: 'pointer',
                    background: itemScope.active ? $arStyle.color.purple100 : null,
                    borderBottom: '1px solid #dcdee4',
                  }"
                  :data-test-id="`message-list-item-${itemScope.item.name}`"
                >
                  <ar-text
                    size="xs"
                    :text="itemScope.item.name"
                    :style="{
                      width: 'auto',
                      color: itemScope.active ? '#7344c0' : '#43516b',
                    }"
                    :weight="itemScope.selected ? 'bold' : 'normal'"
                  />
                </div>
              </template>
            </ar-select>
          </ar-field>
          <ar-text
            v-if="visibility === 'profile'"
            size="xs"
            multiple-lines
            text="Profile fields cannot be edited within campaigns"
            :style="{
              color: $arStyle.color.blueGrey600,
              marginTop: '12px'
            }"
          />
          <ar-field label="Field name" :error-messages="[veeErrors.first('field-name')]" class="u-margin-top-10">
            <ar-input
              v-validate="'required|max:150'"
              autocomplete="field-name"
              data-vv-name="field-name"
              data-vv-as="field name"
              placeholder="Field name"
              type="text"
              data-test-id="field-name"
              :value="fieldName"
              :disabled="visibility === 'profile'"
              @input="handleEventNameInput"
            />
          </ar-field>
          <ar-field label="Field type" class="u-margin-top-6">
            <ar-select
              class="field-type-select"
              :items="fieldTypesAvailable"
              placeholder="Select field type"
              :hide-arrow="false"
              showEmptyState
              data-test-id="field-type"
              @select="handleSelect"
              :disabled="visibility === 'profile'"
              :defaultSelectIndex="fieldTypeDefaultSelectedIndex"
            >
              <template slot="selected-item" slot-scope="selectedItemScope">
                <div
                  v-if="selectedItemScope.item"
                  :style="{
                    display: 'flex',
                    height: '100%',
                    alignItems: 'center',
                  }"
                  :data-test-id="`message-list-item-${selectedItemScope.item}`"
                >
                  <ar-text
                    size="xs"
                    v-if="selectedItemScope.item"
                    :text="selectedItemScope.item.name"
                    :style="{
                      width: 'auto',
                      marginLeft: '10px',
                      color: $arStyle.color.blueGrey800,
                    }"
                    weight="normal"
                  />
                </div>
                <div
                  v-else
                  :style="{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: '0 14px',
                  }"
                >
                  <ar-text
                    size="xs"
                    text="Select list type"
                    :style="{
                      color: $arStyle.color.blueGrey600,
                    }"
                  />
                </div>
              </template>
              <template slot="item" slot-scope="itemScope">
                <div
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    height: '55px',
                    padding: '0 15px',
                    cursor: 'pointer',
                    background: itemScope.active ? $arStyle.color.purple100 : null,
                    borderBottom: '1px solid #dcdee4',
                  }"
                  :data-test-id="`message-list-item-${itemScope.item.name}`"
                >
                  <ar-text
                    size="xs"
                    :text="itemScope.item.name"
                    :style="{
                      width: 'auto',
                      color: itemScope.active ? '#7344c0' : '#43516b',
                    }"
                    :weight="itemScope.selected ? 'bold' : 'normal'"
                  />
                </div>
              </template>
            </ar-select>
            <ar-text
              v-if="selectFieldTypeNotValid"
              class="field-type-error u-margin-top-2"
              text="The field type is required"
              size="xs"
            />
          </ar-field>
          <ar-field
            v-if="(fieldType === 'dropdown-single' || fieldType === 'dropdown-multi') && fieldOptions.length > 0"
            label="Dropdown options"
            class="u-margin-top-6"
            :error-messages="[veeErrors.first('field-option')]"
          >
            <draggable v-model="fieldOptions">
              <div v-for="(field, idx) of fieldOptions" :key="idx">
                <div :class="['u-display-flex', 'u-align-items-center']" :style="{ height: '52px', marginTop: '5px' }">
                  <div class="field-option-container">
                    <ar-input
                      v-validate="'required|max:150'"
                      autocomplete="field-option"
                      data-vv-name="field-option"
                      data-vv-as="field option"
                      placeholder="Field option"
                      class="field-option"
                      type="text"
                      :data-test-id="`field-option-${idx}`"
                      :value="field.name"
                      :disabled="visibility === 'profile'"
                      @input="(val) => onFieldInput(val, field, idx)"
                    />
                    <ar-auto-complete-input
                      ref="input"
                      :value="tagSearchStrings[idx]"
                      @input="(value) => handleTagSearchStringInput(value, idx)"
                      @blur="handleTagInputBlur"
                      placeholder="Add tag (optional)"
                      class="add-tag-input"
                      data-test-id="tag-list-auto-complete-input"
                      new-suggestion-template="Create new tag {suggestion}"
                      :suggestions="suggestedTags"
                      :disabled="visibility === 'profile'"
                      @suggestSelect="(value) => handleSuggestionClick(value, idx)"
                    />
                  </div>
                  <am2-simple-button-dropdown
                    v-if="fieldOptions.length > 1"
                    align="left"
                    :pixel-shift="5"
                    class="dropdown-button custom-field-menu-button"
                    :disabled="visibility === 'profile'"
                    :button-props="{
                      type: 'grey',
                      outlined: true,
                      iconName: 'menu',
                      style: {
                        width: '50px',
                      },
                      iconSide: 'left',
                      iconDistance: '0px',
                      iconProps: {
                        color: $arStyle.color.blueGrey600,
                      },
                    }"
                    :data-test-id="`field-option-${idx}-menu`"
                    :items="computedFieldMenuOptions"
                    @select="(opt) => handleMenuOptionSelect(opt, field, idx)"
                  />
                </div>
              </div>
            </draggable>
            <ar-link-button v-if="visibility !== 'profile'" text="+ Add option" class="add-option-button" @click="addOption" />
          </ar-field>
        </div>
      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          text="Cancel"
          style="padding: 18px 42px; margin-right: 15px"
          class="transparent-button"
          outlined
          data-test-id="custom-fields-modal-cancel-button"
          @click="handleClose"
        />
        <ar-simple-button
          text="Save"
          style="padding: 18px 42px"
          @click="handleSaveClick"
          data-test-id="custom-fields-modal-save-button" />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from "debounce";

export default {
  name: 'CustomFieldsModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    editCustomField: {
      type: Object,
      default: null,
    },
    campaignOid: {
      type: [String, Number],
      default: null,
    },
    excludedCustomFields: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      tagSearchStrings: [''],
      validated: false,
      fieldName: null,
      fieldType: null,
      fieldOptions: [],
      fieldTypeDefaultSelectedIndex: null,
      visibility: null,
      fieldOid: null,
      fieldCampaignOid: null,
      fieldTypesAvailable: [
        { name: 'Dropdown (Single Select)', key: 'dropdown-single' },
        { name: 'Dropdown (Multi Select)', key: 'dropdown-multi' },
        { name: 'Text', key: 'text' },
        { name: 'Number', key: 'number' },
        { name: 'Date', key: 'date' },
      ],
      fieldMenuOptions: [
        { name: 'Remove', key: 'remove' },
      ],
      tagRequestController: null,
      promoterCustomFields: []
    };
  },
  mounted() {
    this.debounceFetchMoreTags = debounce((args) => {
      this['tag/FETCH_MORE_TAGS'](args);
    }, 500);
  },
  computed: {
    ...mapState({
      tags: state => state.tag.tags,
    }),
    isEditMode() {
      return !!this.editCustomField;
    },
    suggestedTags() {
      return this.tags.map(item => item.name);
    },
    computedFieldMenuOptions() {
      if (this.fieldOptions.length < 2) {
        return [];
      }
      return this.fieldMenuOptions;
    },
    selectFieldTypeNotValid() {
      return this.validated && !this.fieldType;
    },
    displayedCustomFields() {
      const excludedCustomFieldNames = this.excludedCustomFields.map( item => item.name);
      return this.promoterCustomFields.filter( item => {
        return excludedCustomFieldNames.indexOf(item.name) === -1;
      })
    },
  },
  watch: {
    isOpen(val) {
      if (!val) return;

      this.resetData();
      this.fetchPromoterCustomFields();

      if (!!this.editCustomField) {
        this.configureField(this.editCustomField.field);
      }
    },
  },
  methods: {
    ...mapActions([
      'tag/FETCH_MORE_TAGS',
      'FETCH_PROMOTER_CUSTOM_FIELDS',
    ]),
    ...mapMutations([
      'tag/RESET_TAGS',
    ]),

    async fetchPromoterCustomFields() {
      this.promoterCustomFields = await this['FETCH_PROMOTER_CUSTOM_FIELDS']();
    },
    handleTagInputBlur() {
      // Cancel axios tags request
      this.tagRequestController.cancel();
      // Cancel debounce
      this.debounceFetchMoreTags.clear();
      // Reset all tags
      this['tag/RESET_TAGS']();
      // Refetch tags
      this.fetchTags('');
    },
    configureField(item) {
      this.fieldName = item.name;
      this.fieldType = item.type || item.fieldType;
      this.fieldOptions = item.options;
      this.visibility = item.visibility;
      this.fieldOid = item.oid;
      this.fieldCampaignOid = this.campaignOid;
      const fieldType = item.type || item.fieldType;
      this.fieldTypeDefaultSelectedIndex = this.fieldTypesAvailable.findIndex( item => item.key === fieldType) || 0;
      if (item.type === 'dropdown-single' || item.type === 'dropdown-multi') {
        this.tagSearchStrings = item.options.map(opt => {
          return opt.tag || "";
        });
      } else {
        this.tagSearchStrings = null;
      }

      this.validated = true;
    },
    resetData() {
      this.validated = false;
      this.fieldName = null;
      this.fieldType = null;
      this.visibility = null;
      this.fieldOptions = [];
      this.fieldTypeDefaultSelectedIndex = null;
      this.fieldOid = null;
      this.fieldCampaignOid = null;
      this.tagSearchStrings = [''];
    },
    handleClose() {
      this.$emit('cancel');
    },
    handleEventNameInput(name) {
      this.fieldName = name;
    },
    async handleSaveClick() {
      const validation = await this.$validator.validateAll();
      this.validated = true;

      if (!validation || !this.fieldType) {
        this.$arNotification.push({
          type: 'error',
          message: `There were some errors in saving your custom field. Please review the messages.`,
        });
      } else {
        this.$emit('save', {
          type: this.fieldType,
          name: this.fieldName,
          options: this.fieldOptions,
          visibility: this.visibility,
          oid: this.fieldOid,
          campaignOid: this.fieldCampaignOid,
        });
      }
    },
    handleSelect(item) {
      this.fieldType = item;

      if (this.fieldOptions.length === 0) {
        this.addOption();
      }
    },
    handleExistingCustomFieldSelect(item) {
      this.configureField(item);
    },
    handleMenuOptionSelect(option, field, idx) {
      if (option.key === 'remove') {
        this.fieldOptions.splice(idx, 1);
        this.tagSearchStrings.splice(idx, 1);
      }
    },
    handleSuggestionClick(value, idx) {
      const existingTag = (this.tags || []).find( tag => tag.name === value)
      const trimmedValue = value.trim();
      this.fieldOptions[idx].tag = trimmedValue.length > 0 ? trimmedValue : null;
      if (existingTag) this.fieldOptions[idx].tagOid = existingTag?.oid;
      this.fetchTags('');
    },
    addOption() {
      // Reset tags suggestions
      this.fetchTags('');
      this.tagSearchStrings.push('');

      this.fieldOptions.push({
        name: '',
        tag: null,
      });
    },
    onFieldInput(value, field, idx) {
      this.fieldOptions[idx].name = value;
    },
    handleTagSearchStringInput(newSearchString, idx) {
      const trimmedTag = newSearchString.trim();
      this.tagSearchStrings[idx] = newSearchString;
      this.fieldOptions[idx].tag = trimmedTag.length > 0 ? trimmedTag : null;

      this.fetchTags(newSearchString);
    },
    fetchTags(searchString) {
      const source = this.$axios.CancelToken.source();
      this.debounceFetchMoreTags({
        top: 20,
        reload: true,
        searchString: searchString,
        cancelToken: source.token
      });
      this.tagRequestController = source;
    }
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 30px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.field-type-select {
  width: 100%;
}
.custom-field-menu-button {
  height: 100%;
  width: 50px;
  margin-left: 15px;
}
.add-option-button {
  margin-top: 20px;
}
.field-type-error {
  color: $red500;
}
.field-option {
  flex: 1;
  max-width: 50%;
  margin-right: 5px;
}
.add-tag-input {
  flex: 1;
  max-width: calc(50% - 5px);
  margin-left: 5px;
}
.field-option-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}
</style>
