export function extractRecipientsCount(messageStats, status) {
  let count = 0

  if (!!messageStats) {
    const {
      totalMessages,
      sent,
      opened,
      clicked,
      unsubscribed,
      bounced,
      failed,
      deferred,
      undelivered
    } = messageStats;
    const totalOpened = (opened || 0) + (clicked || 0) + (unsubscribed || 0);

    if (status === null) {
      count = totalMessages || 0
    } else if (status === 'opened') {
      count = totalOpened
    } else if (status === 'not-opened') {
      count = (totalMessages || 0) - totalOpened
    } else if (status === 'clicked') {
      count = (clicked || 0)
    } else if (status === 'not-clicked') {
      count = (totalMessages || 0) - (clicked || 0)
    } else if (status === 'bounced') {
      count = bounced || 0
    } else if (status === 'unsubscribed') {
      count = unsubscribed || 0
    } else if (status === 'failed') {
      count = (failed || 0) + (undelivered || 0)
    }
  }

  return count
}
