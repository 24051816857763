var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
      'wrapper',
      'content',
      'content--centered',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('am2-opt-in-settings-modal',{attrs:{"is-show":_vm.showOptinSettingsModal,"providerName":"Try Booking","message-list-oid":_vm.integrationMessageListOid},on:{"cancel":function($event){_vm.showOptinSettingsModal = false}}}),_vm._v(" "),_c('am2-ask-try-booking-credentials-modal',{attrs:{"is-show":_vm.showTryBookingCredentialsModal,"loading":_vm.isCreatingTryBookingIntegration},on:{"confirm":_vm.handleTryBookingCredentialsConfirm,"cancel":function($event){_vm.showTryBookingCredentialsModal = false}}}),_vm._v(" "),_c('div',{class:[
        'title-section',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Try Booking","description":"Sync your orders, events and attendees from Trybooking","icon-props":{
            name: 'try-booking',
            color: _vm.$arStyle.color.tryBooking,
            height: '46px',
            width: '40px',
          }}})],1),_vm._v(" "),(!_vm.isFetchingTryBookingIntegration && _vm.integrationsSummary)?_c('div',{staticClass:"title-section-right"},[_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":_vm.expandButtonItems},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),_c('am2-tabs',{staticClass:"tab-options",attrs:{"items":[
        { title: 'Notifications' },
        { title: 'Status' }
      ],"selected-tab-index":_vm.selectedTabIndex},on:{"select":function (selected, idx) { return _vm.selectedTabIndex = idx; }}}),_vm._v(" "),(_vm.selectedTabIndex === 0)?_c('div',[_c('NotificationsContainer',{attrs:{"integration":_vm.currentIntegration,"loading":_vm.isFetchingTryBookingIntegration}})],1):_vm._e(),_vm._v(" "),(_vm.selectedTabIndex === 1)?_c('div',{staticClass:"integration-status-container"},[(_vm.isAddingTryBookingIntegration || _vm.isFetchingTryBookingIntegration)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Try Booking connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"is-fetching-sync-task":_vm.isFetchingSyncTask,"sync-status":_vm.syncStatus,"sync-time":_vm.syncTime,"sync-copies":{
          'in-progress': 'Syncing data from Try Booking',
          'pending': 'The data sync is still pending',
          'completed': 'Your Try Booking account is currently connected',
          'cancelled': 'Your Try Booking account connection is cancelled',
          'failed': this.syncErrorMessage,
          'default': 'Syncing tasks not found',
        }},on:{"startSync":_vm.handleStartSync,"stopSync":_vm.handleStopSync}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }