<template>
  <form
    ar-widget-id="ar-simple-form"
    :ar-widget-meta="arWidgetMeta || computedArWidgetMeta"
    name="ar-simple-form"
    :class="[
      'ar-simple-form',
      opened && 'opened',
      disabled && 'disabled',
    ]"
    :style="{
      padding: opened ? '11px 20px' : '0 20px',
    }"
    @click="handleFormClick"
    @submit.prevent="handleFormSubmit"
  >
    <!-- <div class="ar-simple-form-left-section">
      <ar-icon
        v-if="iconName"
        :name="iconName"
        width="16px"
        :color="$arStyle.color.skyBlueGrey700"
      />
    </div> -->
    <div class="ar-simple-form-right-section">
      <!-- Before Opened -->
      <div
        :style="{
          height: opened ? 0 : null,
          overflow: 'hidden',
        }"
      >
        <div
          :style="{
            minHeight: '40px',
          }"
        >
          <ar-text
            :v-if="!opened"
            size="xxs"
            :text="title"
            multiple-lines
            :style="{
              padding: '7px 0 0 0',
              'color': $arStyle.color.skyBlueGrey700,
            }"
          />
          <div
            v-if="!disabled"
          >
            <div
              v-if="parsedData"
              :style="{
                padding: '0 0 8px 0',
              }"
            >
              <ar-text
                size="xs"
                :text="parsedData"
                multiple-lines
                allow-html
                line-height="20px"
              />
            </div>
            <ar-link-button
              v-else
              :text="`+ ${placeholder}`"
              :text-props="{
                weight: 'bold',
              }"
              :has-underline="false"
              :color="color"
              :style="{
                marginTop: '13px',
                marginBottom: '13px',
              }"
              @click="handleFormClick"
            />
          </div>
          <div
            v-else
          >
            <ar-text
              size="xs"
              :text="parsedData || placeholder"
              multiple-lines
              allow-html
              line-height="20px"
              :style="{
                padding: '7px 0 7px 0',
                'color': $arStyle.color.skyBlueGrey700,
              }"
              v-tooltip="{
                content: 'This field is read only and cannot be edited.',
              }"
            />
          </div>

        </div>
      </div>
      <!-- After Opened -->
      <div
        :style="{
          height: opened ? null : 0,
          overflow: opened ? null : 'hidden',
        }"
      >
        <div
          :style="{
            padding: '2px',
            minHeight: '40px',
          }"
        >
          <ar-text
            size="xs"
            weight="bold"
            :text="title"
            multiple-lines
            :style="{
              padding: '10px 0 12px 0',
              'color': $arStyle.color.blueGrey800,
            }"
          />
          <slot
            name="fields"
          />
          <div
            class="u-margin-top-3 u-display-flex u-align-items-center"
          >
            <ar-simple-button
              text="Save"
              :style="{
                width: '90px',
                height: '40px',
              }"
              :type="color"
              :loading="loading"
            />
            <ar-link-button
              v-if="!loading"
              class="u-margin-left-3"
              :show-arrow="false"
              show-underline
              :color="color"
              text="Cancel"
              @click="handleCancelClick"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { isHexColorCode } from '@/utils/helpers';

export default {
  name: 'ColumnSimpleForm',
  props: {
    arWidgetMeta: String,
    color: {
      type: String,
      default: 'purple',
      validator: (value) => {
        return ['purple'].indexOf(value) > -1 || isHexColorCode(value);
      },
    },
    iconName: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Add data',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    parsedData: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedArWidgetMeta() {
      return [
        `placeholder=${this.placeholder};`,
        `text=${this.parsedData};`,
      ].join('');
    },
  },
  methods: {
    handleFormClick() {
      if (this.disabled || this.opened) {
        return;
      }
      this.$emit('open');
    },
    handleCancelClick() {
      this.$emit('cancel');
    },
    handleFormSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.ar-simple-form {
  display: flex;
  cursor: pointer;
  transition: height 0.1s linear, padding 0.1s linear;

  &:hover {
    border-radius: 4px;
    background: $skyBlueGrey300;
  }
  &.opened {
    cursor: unset;
    background: $skyBlueGrey300;
  }

  .ar-simple-form-left-section {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    width: 32px;
    height: 40px;
    padding-top: 0;
    transition: padding-top 0.1s linear;
  }

  .ar-simple-form-right-section {
    flex-grow: 1;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: column;
  }
}
</style>
