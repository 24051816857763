import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Get Twilio Subaccoutns for promoter
   * @param twilioSubAccountOid 
   * @returns
   */
  async get(promoterOid: number): Promise<TwilioSubAccount[] | null> {
    const { status, data } = await axios.get(`/promoter/${promoterOid}/twilio-subaccount`, {
      params: {
        $filter: `promoterOid=${promoterOid}`
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Promoter Account error',
        message: `Error getting Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Get single Twilio Subaccount for promoter
   * @param promoterOid 
   * @returns
   */
  async getSubaccount(promoterOid: number): Promise<TwilioSubAccount | null> {
    
    const { status, data } = await axios.get(`/promoter/${promoterOid}/twilio-subaccount`);

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Promoter Account error',
        message: `Error getting Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }

    if (data.length > 0) {
      return data[0];
    } else {
      return null;
    }
  },
  /**
   * Update promoter account
   * @param promoterOid, twilioSubAccountOid
   * @returns
   */
  async patch(promoterOid: number, twilioSubAccountOid: number, accountObj: object): Promise<PromoterAccount | null> {
    const { status, data } = await axios.patch(`/promoter/${promoterOid}/twilio-subaccount/${twilioSubAccountOid}`, accountObj);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Update Promoter Account error',
        message: `Error updating Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Create Twilio subaccount 
   * @returns
   */
  async post(promoterOid: number, accountObj: object): Promise<PromoterAccount | null> {
    const { status, data } = await axios.post(`/promoter/${promoterOid}/twilio-subaccount`, accountObj);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Create Twilio Sub-account error',
        message: `Error creating a new Twilio Sub-account under Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Delete Twilio subaccount 
   * @returns
   */
  async delete(promoterOid: number, twilioSubaccountId: number): Promise<PromoterAccount | null> {
    const { status, data } = await axios.delete(`/promoter/${promoterOid}/twilio-subaccount/${twilioSubaccountId}`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Create Twilio Sub-account error',
        message: `Error deleting Twilio Sub-account ${twilioSubaccountId} under Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
});
