var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{ref:"signup-form-card",class:['signup-form-card', _vm.size],nativeOn:{"click":function($event){return _vm.handleSignupFormCardClick.apply(null, arguments)},"mouseenter":function($event){_vm.isBeingHovered = true},"mouseleave":function($event){_vm.isBeingHovered = false}}},[_c('am2-create-campaign-widget-modal',{attrs:{"is-open":_vm.displayCreateCampaignWidgetModal,"campaign":_vm.signupForm,"is-signup-form":true},on:{"cancel":function($event){_vm.displayCreateCampaignWidgetModal = false}}}),_vm._v(" "),_c('div',{class:['left-section', _vm.size]},[_c('div',{class:['left-section-content', _vm.size]},[_c('div',{staticClass:"img-wrapper"},[_c('div',{class:['img-mask', _vm.isBeingHovered && 'hovered']},[_c('div',{staticClass:"mask-button"},[_vm._v("\n            Edit Form\n          ")])]),_vm._v(" "),_c('img',{staticClass:"img",attrs:{"src":_vm.campaignImageUrl}})]),_vm._v(" "),_c('div',{staticClass:"description"},[_c('ar-text',{attrs:{"size":"sm","text":_vm.signupForm.name}}),_vm._v(" "),_c('div',{staticClass:"u-display-flex",style:({
            marginTop: '14px',
          })},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.numRegistrantsCopyMap.tooltip,
            }),expression:"{\n              content: numRegistrantsCopyMap.tooltip,\n            }"}],staticClass:"u-display-flex"},[_c('ar-icon',{attrs:{"name":"user","width":"11px","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-1",style:({
                color: _vm.$arStyle.color.blueGrey700,
              }),attrs:{"size":"xs","text":_vm.numRegistrantsCopyMap.text}})],1),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.uniqueViewsCopyMap.tooltip,
            }),expression:"{\n              content: uniqueViewsCopyMap.tooltip,\n            }"}],staticClass:"u-display-flex u-margin-left-6"},[_c('ar-icon',{attrs:{"name":"preview","width":"17px","color":_vm.$arStyle.color.blueGrey700}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-left-2",style:({
                color: _vm.$arStyle.color.blueGrey700,
              }),attrs:{"size":"xs","text":_vm.uniqueViewsCopyMap.text}})],1)])],1)]),_vm._v(" "),_c('div',{staticClass:"icon-wrapper"},[_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
          name: 'menu',
        },"dropdown-style":{
          width: '200px',
        },"dropdown-item-style":{
          height: '41px',
          padding: '0 20px',
        },"items":_vm.actions},on:{"select":_vm.handleActionSelect}})],1)]),_vm._v(" "),(_vm.hasSummaryStats)?_c('div',{class:['right-section', _vm.size]},[_c('div',{staticClass:"stats"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.emailFollowsCopyMap.tooltip,
        }),expression:"{\n          content: emailFollowsCopyMap.tooltip,\n        }"}],staticClass:"stats-group"},[_c('ar-text',{attrs:{"text":_vm.emailFollowsCopyMap.text,"width":"18px","size":"sm"}}),_vm._v(" "),_c('ar-icon',{style:({
            marginLeft: '9px',
          }),attrs:{"name":"email","width":"18px","color":_vm.$arStyle.color.email}})],1)]),_vm._v(" "),_c('div',{staticClass:"stats"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.smsFollowsCopyMap.tooltip,
        }),expression:"{\n          content: smsFollowsCopyMap.tooltip,\n        }"}],staticClass:"stats-group"},[_c('ar-text',{attrs:{"text":_vm.smsFollowsCopyMap.text,"width":"18px","size":"sm"}}),_vm._v(" "),_c('ar-icon',{style:({
            marginLeft: '9px',
          }),attrs:{"name":"sms","width":"18px","color":_vm.$arStyle.color.sms}})],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }