<template>
  <div class="email-body-section">
    <am2-design-email-modal
      :opened="displayDesignEmailModal"
      :show-html-email="showHtmlEmail"
      @designFromScratch="handleDesignFromScratch"
      @importHtml="handleImportHtmlClick"
      @template="handleFromTemplate"
      @close="handleDesignEmailClosed"
      :template-type="templateType"
    />
    <am2-import-html-email-modal
      :opened="displayHtmlEmailModal"
      @input="handleHtmlUpload"
      @back="handleImportHtmlBack"
      @close="handleImportHtmlClosed"
    />

    <div ref="wrapper" class="wrapper">
      <div>
        <!-- HTML email loading screen -->
        <div v-if="isUploadingHtmlEmail" class="edit-email-section">
          <am2-loading-bubble class="sync-bubbles" />
          <ar-text
            size="sm"
            align="center"
            texty="HTML upload in progress"
          />
        </div>
        <!-- HTML email import error -->
        <div v-else-if="htmlEmailUploadFailed" class="edit-email-section">
          <ar-icon
            name="alert-exclamation-mark"
            class="warning-exclamation-mark"
            height="70px"
            width="70px"
          />
          <p>Unable to upload HTML, <a class="try-again-link" @click="handleHtmlEmailTryAgainClick">try again</a></p>
        </div>
        <!-- HTML email screen -->
        <div v-else-if="htmlEmailActive" class="edit-email-section" :class="isAbDesign ? 'abEmail' : ''">
          <div v-if="isAbDesign" class="design-button ab-menu" :class="[ !!selectedEmailHtml && 'active' ]">
            <ar-simple-select
              :style="{ height: '40px' }"
              enable-filter
              class="selector ab-selector"
              :items="abOptions"
              @select="handleVariantSelectClick"
              :default-select-index="abSwitchIndex"
            />
            <ar-simple-button
              class="import-again-button"
              text="Import again"
              outlined
              :style="{
                margin: '0',
                height: '40px',
              }"
              @click="handleHtmlEmailImportAgainClick"
            />
          </div>
          <div v-else class="design-button" :class="[ !!selectedEmailHtml && 'active' ]">
            <ar-simple-button
              class="import-again-button"
              text="Import again"
              outlined
              :style="{
                height: '40px',
              }"
              @click="handleHtmlEmailImportAgainClick"
            />
            <ar-icon-button
              class="icon-button"
              :color="$arStyle.color.purple500"
              :icon-props="{
                name: 'swap',
              }"
              @click="handleChangeDesignOptionClick"
              v-tooltip.top="{
                content: 'Change design option'
              }"
              data-test-id="change-design-option-button"
            />
          </div>
          <div class="email" ref="emailBody" v-html="selectedEmailHtml" :style="{ zoom: computedZoom }"></div>
        </div>
        <div v-else-if="!selectedEmailHtml" class="edit-email-section" :class="isAbDesign ? 'abEmail' : ''">
          <div v-if="isAbDesign" class="design-button">
            <ar-simple-select
              :style="{ flex: 1, height: '40px' }"
              enable-filter
              class="selector"
              :items="abOptions"
              @select="handleVariantSelectClick"
              :default-select-index="abSwitchIndex"
            />
            <ar-simple-button
              icon-name="edit"
              :icon-props="{
                width: '14px'
              }"
              text="Design email"
              outlined
              :style="{
                height: '40px',
              }"
              :disabled="isLegacyEmail"
              v-tooltip.top="{
                content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
              }"
              @click="handleDesignEmailClick"
            />
          </div>
          <div class="abEmailSection__error">
            <ar-text
              v-if="isEmptyError"
              :text="'A design is required'"
              class="error-subtext"
              weight="normal"
              size="12px"
              :style="{
              'margin-left': '16px'
            }"
            />
          </div>

          <img
            src="~/assets/images/message-center/email-design-image.png"
            :style="isAbDesign && {
              position: 'absolute',
              top: '165px',
            }"
          />

          <div v-if="!isAbDesign" class="u-display-flex u-flex-flow-column">
            <ar-simple-button
              icon-name="edit"
              :icon-props="{
                width: '14px'
              }"
              :text="'Design email'"
              outlined
              :style="{
                height: '40px'
              }"
              :disabled="isLegacyEmail"
              v-tooltip.top="{
                  content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
                }"
              @click="handleDesignEmailClick"
            />
            <ar-text
              v-if="isEmptyError"
              :text="'A design is required'"
              class="error-subtext"
              weight="normal"
              size="12px"
              :style="{
                'margin-left': '16px'
              }"
            />
          </div>
        </div>
        <div v-else ref="wrapper" class="edit-email-section email-wrapper">
          <div
            v-if="isAbDesign"
            class="design-button"
            :class="[ !!selectedEmailHtml && 'active' ]"
          >
            <ar-simple-select
              :style="{ width: '300px', height: '40px' }"
              enable-filter
              class="selector"
              :items="abOptions"
              @select="handleVariantSelectClick"
              :default-select-index="abSwitchIndex"
            />
            <ar-simple-button
              icon-name="edit"
              :icon-props="{
              width: '14px'
            }"
              text="Edit email"
              outlined
              :style="{
              height: '40px',
            }"
              :disabled="isLegacyEmail"
              v-tooltip.top="{
                content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
              }"
              @click="handleDesignEmailClick"
            />
            <ar-icon-button
              class="icon-button"
              :color="$arStyle.color.purple500"
              :icon-props="{
                name: 'swap',
              }"
              @click="handleChangeDesignOptionClick"
              v-tooltip.top="{
                content: 'Change design option'
              }"
              data-test-id="change-design-option-button"
            />
          </div>
          <div
            v-else
            class="design-button"
            :class="[ !!selectedEmailHtml && 'active' ]"
          >
            <ar-simple-button
              icon-name="edit"
              :icon-props="{
                width: '14px'
              }"
              class="edit-email-button"
              text="Edit email"
              outlined
              :style="{
                height: '40px',
              }"
              :disabled="isLegacyEmail"
              v-tooltip.top="{
                  content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
                }"
              @click="handleDesignEmailClick"
            />
            <ar-icon-button
              class="icon-button"
              :color="$arStyle.color.purple500"
              :icon-props="{
                name: 'swap',
              }"
              @click="handleChangeDesignOptionClick"
              v-tooltip.top="{
                content: 'Change design option'
              }"
              data-test-id="change-design-option-button"
            />
          </div>
          <div class="email" ref="emailBody" v-html="selectedEmailHtml" :style="{ zoom: computedZoom }"></div>
        </div>
      </div>
      <am2-switch-field-card
        v-if="isAbEmailFeatureEnabled"
        class="u-margin-top-5 ab-email-selector"
        title="A/B test your email"
        :size="'normal'"
        title-size="sm"
        subtitle="Test out variations in design or message details"
        align-center
        :value="isAbEmailEnabled"
        :is-controlled-value="true"
        test-id-switch="message-details-design-block-ab-email-switch"
        @input="handleAbEmailChange"
      />
    </div>
    <AbModalOffConfirm
      :opened="isAbModalOffConfirmOpened"
      :ab-type="abType"
      @close="handleAbModalOffClose"
      @confirm="handleAbModalOffConfirm"
    />
  </div>
</template>
<script>

import AbModalOffConfirm from '~/components/modals/message/ab-modal-off-confirm'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'MessageDetailsDesignBlock',
  components: {
    AbModalOffConfirm,
  },
  props: {
    scratchEmailMessage: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      displayDesignEmailModal: false,
      displayHtmlEmailModal: false,
      isAbEmailEnabled: false,
      abOptions: [{ name: 'Design A', val: 'A' }, { name: 'Design B', val: 'B' }],
      selectedEmailHtml: '',
      locallySelectedVariant: 'A',
      isAbDesign: false,
      computedZoom: 1,
      isAbModalOffConfirmOpened: false,
      abType: '',
      isEmptyError: false,
    };
  },
  watch: {
    scratchEmailMessage: {
      handler() {
        if (this.scratchEmailMessage && this.scratchEmailMessage.abEmail) {
          this.isAbEmailEnabled = true;
        } else {
          this.isAbEmailEnabled = false;
        }
        if (
          this.scratchEmailMessage &&
          this.scratchEmailMessage.abEmail &&
          this.scratchEmailMessage.abEmailType === 'design'
        ) {
          this.isAbDesign = true;
        } else {
          this.isAbDesign = false;
        }
      },

      deep: true,
      immediate: true
    },
    'scratchEmailMessage.meta.presentation.template': {
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.handleDesignChange();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      currentMessageSender: state => state.messageSender.currentMessageSender,
      emailVariants: state => state.message.scratchEmailMessageVariants,
      scratchEmail: state => state.message.scratchEmail,
      selectedVariant: state => state.message.selectedVariant,
      isUploadingHtmlEmail: state => state.message.isUploadingHtmlEmail,
      htmlEmailUploadFailed: state => state.message.htmlEmailUploadFailed,
      htmlEmail: state => state.message.htmlEmail,
    }),
    ...mapGetters({
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      currentMessageSender: state => state.messageSender.currentMessageSender,
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    isLegacyEmail() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType === 'unlayer';
    },
    isAbEmailFeatureEnabled() {
      const templateType = this.scratchEmailMessage?.meta?.presentation?.templateType
      return (
        this.isFeatureEnabled(['ab-emails']) &&
        (templateType === 'beefree' || templateType === 'html')
      );
    },

    templateType() {
      return this.scratchEmailMessage?.meta?.presentation?.templateType || 'beefree';
    },

    abSwitchIndex() {
      return this.selectedVariant === 'A' ? 0 : 1;
    },

    htmlEmailActive() {
      if (this.isAbDesign) {
        return this.emailVariants['A']?.meta?.presentation?.templateType === 'html' ||
          this.emailVariants['B']?.meta?.presentation?.templateType === 'html';
      }
      return this.scratchEmailMessage?.meta?.presentation?.templateType === 'html';
    },

    showHtmlEmail() {
      return this.htmlEmailActive
        || !this.isAbDesign
        || (this.isAbDesign && !this.selectedEmailHtml);
    }
  },
  methods: {
    ...mapActions([
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
      'message/SWITCH_AB_EMAIL',
      'message/GENERATE_SCRATCH_EMAIL_TEMPLATE',
      'message/UPLOAD_HTML_EMAIL',
      'message/SAVE_MESSAGE_DRAFT',
    ]),
    ...mapMutations([
      'message/SWITCH_AB_EMAIL_STATE',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/RESET_SCRATCH_EMAIL_MESSAGE',
      'emailTemplate/RESET_EMAIL_TEMPLATES',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/RESET_SCRATCH_EMAIL_TEMPLATE',
      'message/SELECT_AB_EMAIL_MESSAGE',
      'message/UPDATE_AB_EMAIL_STATE',
      'message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE',
      'message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE_TYPE',
      'message/PATCH_AB_EMAIL_MESSAGE_VARIANT_RESOURCE_ID',
    ]),
    async handleDesignEmailClick() {
      if (this.scratchEmailMessage.meta.presentation.template != null) {
        this.handleDesignFromScratch();
      } else {
        await this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              templateType: 'beefree'
            }
          }
        });

        this.displayDesignEmailModal = true;
      }
    },
    handleDesignEmailClosed() {
      this.displayDesignEmailModal = false;
    },
    handleImportHtmlClosed() {
      this.displayHtmlEmailModal = false;
    },
    handleImportHtmlBack() {
      this.displayHtmlEmailModal = false;
      this.displayDesignEmailModal = true;
    },
    async handleHtmlUpload(file) {
      this.displayHtmlEmailModal = false;
      this.selectedEmailHtml = null;

      let oid = null
      if (!this.scratchEmailMessage?.oid) {
        const data = await this['message/SAVE_MESSAGE_DRAFT'](this.scratchEmailMessage)
        oid = data?.data?.oid ? data?.data?.oid : null
      }

      if(!this.scratchEmailMessage?.oid && !oid) {
        console.error('No message oid found')
      }
      const success = await this['message/UPLOAD_HTML_EMAIL']({
        messageOid: this.scratchEmailMessage?.oid ? this.scratchEmailMessage?.oid : oid,
        file
      });

      if (success) {
        const html = this.htmlEmail?.template?.content;
        this.selectedEmailHtml = html;
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](this.selectedEmailHtml);
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            // Save bucket oid into message task
            templateResourceOid: this.htmlEmail?.oid,
            // Change template type to html
            presentation: {
              template: html,
              templateType: 'html',
            }
          }
        });

        if (this.isAbDesign) {
          // Saving A/B template
          this['message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE'](html);
          this['message/PATCH_AB_EMAIL_MESSAGE_TEMPLATE_TYPE']('html');
          this['message/PATCH_AB_EMAIL_MESSAGE_VARIANT_RESOURCE_ID'](this.htmlEmail?.oid);
          this['message/SAVE_MESSAGE_DRAFT'](this.scratchEmailMessage);
        }
      }
    },
    handleHtmlEmailTryAgainClick() {
      this.displayHtmlEmailModal = true;
      this.displayDesignEmailModal = false;
    },
    handleHtmlEmailImportAgainClick() {
      this.displayHtmlEmailModal = true;
      this.displayDesignEmailModal = false;
    },
    handleChangeDesignOptionClick() {
      this.displayHtmlEmailModal = false;
      this.displayDesignEmailModal = true;
    },
    async handleDesignFromScratch() {
      this.displayDesignEmailModal = false;

      if (this.scratchEmailMessage?.meta?.presentation?.templateType !== 'beefree') {
        // Remove the template and reset the scratch email only if something else different from
        // beefree was selected before
        this['message/RESET_SCRATCH_EMAIL_TEMPLATE']();
        await this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            presentation: {
              template: null,
              templateType: 'beefree'
            }
          }
        });
      }

      this.$emit('update');
    },
    handleImportHtmlClick() {
      this.displayDesignEmailModal = false;
      setTimeout(() => {
        this.displayHtmlEmailModal = true;
      })
    },
    async handleFromTemplate() {
      await this['emailTemplate/RESET_EMAIL_TEMPLATES']();
      this.displayDesignEmailModal = false;
      this.$emit('template');
    },
    handleAbEmailChange(enabled) {
      if (enabled) {
        this.isAbEmailEnabled = true;
        this.$emit('abEmailChange', enabled);
      } else {
        if (this.emailVariants['A']) {
          this.abType = this.emailVariants['A'].abEmailType;
        }

        if (this.abType === 'design' && !this.emailVariants['B'].meta?.presentation?.template
        || this.abType === 'subject' && !this.emailVariants['B'].meta?.messageBody?.subject) {
          this.isAbEmailEnabled = false;
          this.$emit('abEmailChange', false);
          return
        }
        this.isAbEmailEnabled = true;
        this.isAbModalOffConfirmOpened = true;
      }
    },

    handleAbModalOffClose() {
      this.isAbModalOffConfirmOpened = false;
    },

    handleAbModalOffConfirm() {
      this.isAbModalOffConfirmOpened = false;
      this.isAbEmailEnabled = false;
      this['message/SELECT_AB_EMAIL_MESSAGE']('A')
      this.$emit('abEmailChange', false);
    },

    handleVariantSelectClick(item) {
      this.handleVariantChange(item.val);
    },

    handleVariantChange(variantKey) {
      this.$nextTick(() => {
        this.locallySelectedVariant = variantKey;
        this.$emit('variantChange', variantKey);
        this['message/RESET_SCRATCH_EMAIL_TEMPLATE']();
        this['message/SELECT_AB_EMAIL_MESSAGE'](variantKey);
      });
      this.$nextTick(() => {
        this.handleDesignChange();
      });
    },

    async handleDesignChange() {
      await this.$nextTick(async () => {
        const templateType = this.scratchEmailMessage?.meta?.presentation?.templateType;

        if (templateType === 'beefree') {
          if (typeof this.scratchEmailMessage.meta.presentation.template === 'string') {
            const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
              design: this.scratchEmailMessage?.meta?.presentation?.template,
              previewHtml: this.generatedHtml?.previewHtml || null,
              footerHtml: this.generatedHtml?.footerHtml || null
            });

            this.selectedEmailHtml = html;
            this.$emit('unlayerChange', html);
            this['message/SET_SCRATCH_EMAIL_TEMPLATE'](this.selectedEmailHtml);
          } else {
            this.selectedEmailHtml = '';
          }
        }
        else if (templateType === 'html') {
          const html = this.scratchEmailMessage?.meta?.presentation?.template;
          this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);
          this.selectedEmailHtml = html;
        }
        else { // unlayer
          await this['message/GENERATE_SCRATCH_EMAIL_TEMPLATE'](this.scratchEmailMessage.meta.presentation.template);

          this.selectedEmailHtml = this.injectInfoToScratchEmail(this.currentMessageSender);

          this.$emit('unlayerChange', this.selectedEmailHtml);
        }
      });

      // console.log(this.$refs);
      // const childWidth = this.$refs['emailBody'].getBoundingClientRect().x;
      // const parentWidth = 506;
      // console.log(childWidth);
      // console.log(parentWidth);
      // this.computedZoom = parentWidth / childWidth;
    },
    handleCancel() {
      this.isAbEmailEnabled = false;
    },
    handleEmptyError() {
      this.isEmptyError = true;
    },
    async handleEmptyDynamicTagsFallback() {
      await this.handleDesignEmailClick()
    }
  }
};
</script>
<style lang="scss" scoped>
.email-body-section {
  border: 1px black;
  flex: 1;
  min-width: 500px;
  display: flex;
  flex-flow: row;
  overflow: hidden;
  height: 100%;
  background: #ffffff;
  padding-right: 96px;
  padding-top: 118px;
  flex-direction: column;

  .edit-email-section {
    flex: 1;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 500px;
    max-width: 506px;
    max-height: 554px;
    min-height: 554px;
    background: #f6f9fc;
    border-radius: 4px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border: none;
    gap: 16px;
    &.full-size {
      min-width: unset;
      max-width: 100%;
    }
  }
  .try-again-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .ab-email-selector {
    border: none;
    border-top: 1px solid $blueGrey500;
    border-radius: 0px;
    margin-top: 0px;
    max-width: 506px;
  }
  .wrapper {
    border: 1px solid $blueGrey500;
    border-radius: 4px;
  }
}
.abEmailSection {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  &__main {
    display: flex;
    align-items: center;
  }
}
.edit-email-section.email-wrapper {
  justify-content: flex-start;
}
.edit-email {
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 24px;
}
.email-wrapper {
  overflow: hidden;
}
.icon-button {
  height: 40px;
  width: 40px;
  background-color: white;
  border: 1px solid $purple500;
  border-radius: 3px;

  &:hover {
    background: #f4f0fa;
  }
}
.design-button {
  display: flex;
  align-self: flex-end;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  gap: 10px;
  top: 0;
  left: 0;

  &.active {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  }
}
.ab-menu {
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.ab-selector {
  flex: 1;
}
.menu-dropdown {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: white;
  height: 40px;
  width: 40px;
}
.email {
  pointer-events: none;
}
.error-subtext {
  color: $red500;
  margin-top: 8px;
}
</style>
