<template>
  <section
    am2-provider-widget-id="am2-provider-icon"
    :am2-provider-widget-meta="arWidgetMeta || computedArWidgetMeta"
    class="am2-provider-icon-wrapper"
    :style="iconWrapperStyle"
    :title="title"
    :name="`am2-provider-${iconName}`"
    @click="handleIconClick"
  >
    <component
      :is="iconName"
      :style="iconStyle"
      :stroke-width="strokeWidth"
      :stroke-linecap="strokeLinecap"
      class="am2-provider-icon-wrapper__icon"
    />
  </section>
</template>

<script>
import ProviderIcons from './provider-icons/index.js'
import {convertToCamelCase, convertToKebabCase} from '@/utils/helpers'

export default {
  name: 'ProviderIcon',
  components: {
    ...ProviderIcons
  },

  props: {
    arWidgetMeta: String,
    name: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    // If it's not provided, then it will use 1. fixed color inside svg 2. if not, parent color
    color: {
      type: String,
      default: null,
    },
    rotate: {
      type: Number,
      default: 0,
    },
    strokeWidth: {
      type: String,
      default: undefined,
    },
    strokeLinecap: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    wrapperStyle: {
      type: Object,
      default: () => {},
    }
  },

  computed: {
    computedArWidgetMeta() {
      return [
        `name=${this.name};`,
      ].join('');
    },
    iconWrapperStyle() {
      let style = {};

      if (this.rotate) {
        style.transform = `rotate(${this.rotate}deg)`;
      }

      if (this.color) {
        style.color = this.color || 'currentColor';
      }

      if (this.wrapperStyle) {
        style = {
          ...style,
          ...this.wrapperStyle
        };
      }

      return style;
    },
    iconStyle() {
      const style = {};
      if (this.width || this.height) {
        style.width = this.width || 'unset';
        style.height = this.height || 'unset';
      }

      return style;
    },

    iconName() {
      const newName = convertToKebabCase(`icon-${this.name}`)

      const regex = /[^a-zA-Z0-9-]/
      if (regex.test(newName)) {
        console.error(`Invalid provider icon name: ${newName}`)
        return 'icon'
      }

      return newName;
    },
  },

  methods: {
    handleIconClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.am2-provider-icon-wrapper {
  display: inline-flex;
  align-items: center;
  transition: 0.3s transform;

  &__icon {
    color: currentColor;
  }
}
</style>
