<template>
  <section class="dashboard">
    <am2-loading-section
      v-if="isFetchingStats"
      :style="{
        height: '100px',
      }"
    />
    <div
      v-else
      :class="[
        'content-section',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]"
    >
      <am2-card-container class="cell">
        <OverviewBlock
          :overall="registrations.total"
          :direct="!isFreeCampaign && directRegistrations"
          :viral="!isFreeCampaign && viralRegistrations"
          :progress="registrations.increase"
          :new-registrations="leaderboardStats"
          :unique-views="uniqueViews"
          :conversion-rate="conversionRate"
          overall-title="Total registrations"
          direct-title="Direct"
          viral-title="Via Audience Republic"
        >
          <p slot="info" class="promote">
            <!-- <span class="green">+{{ registrations.increase }}%</span>
            increase in registrations -->
            <!-- Your campaign has now started!<br>
            <a href="#">Promote campaign</a> to kick start registrations! -->
          </p>
        </OverviewBlock>
      </am2-card-container>

      <am2-card-container
        class="registrations-cell"
        :style="{
          position: 'relative',
          padding: $arMediaQuery.pageContent.maxWidth('sm') ? '30px 12px' : '30px',
        }"
      >
        <div
          :style="{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            top: '30px',
            left: $arMediaQuery.pageContent.maxWidth('sm') ? '12px' : '30px',
            zIndex: '2',
          }"
        >
          <am2-signal-tag v-if="isCampaignActive" text="Active" :style="{ marginRight: '12px' }" />
          <Countdown
            v-if="campaign && campaign.endDate"
            @campaign-ended="!isCampaignActive"
            class="ends-in"
            :date="campaign.endDate"
            :time-zone="campaign.presentation.timeZone"
          />
        </div>

        <am2-multiple-column-charts
          title="Registrations"
          hide-switch-buttons
          hide-title
          :chart-height="420"
          :topics="registrationsChartData.topics"
          :topic-index="0"
          :chart-datas="registrationsChartData.chartDatas"
          :style="{
            marginTop: campaign && campaign.endDate && $arMediaQuery.pageContent.maxWidth('xs') ? '32px' : '-9px',
          }"
        />
      </am2-card-container>

      <am2-card-container
        v-if="!isFreeCampaign"
        class="cell">
        <ActionBlock :channels="shareStats" :default-order="shareDefaultOrder" title="Shares"/>
      </am2-card-container>

      <am2-card-container
        v-if="!isFreeCampaign"
        class="cell"
      >
        <ActionBlock
          :channels="followStats"
          :default-order="followDefaultOrder"
          title="Follows &amp; subscribes"
          help="Not all actions can be verified so some are estimates"
        />
      </am2-card-container>

      <am2-card-container
        v-if="!isFreeCampaign"
        class="cell"
      >
        <ActionBlock :channels="connectStats" :default-order="connectDefaultOrder" title="Connections" />
      </am2-card-container>

      <!-- <am2-card-container class="cell">
        <LeaderboardBlock :rankings="leaderboardStats" title="Leaderboard" @audienceClick="audienceClick" />
      </am2-card-container>
      -->
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import accounting from 'accounting';
import Countdown from '@/components/ui/atoms/countdown';
import { OverviewBlock, ActionBlock } from '@/components/ui/organisms/overview/';

export default {
  name: 'CampaignRegistrations',

  components: {
    Countdown,
    OverviewBlock,
    ActionBlock,
  },

  props: {
    campaign: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    registrationSeries: {},
    isFetchingStats: false,
  }),

  computed: {
    ...mapState({
      registrationStats: (state) => state.campaign.currentCampaignRegistrationStats,
      messageLists: state => state.messageList.messageLists,
    }),

    campaignOid() {
      return this.campaign?.oid;
    },

    isCampaignActive() {
      if (!this.campaign) {
        return false;
      }
      const currentTime = moment.utc();

      return moment.tz(this.campaign.endDate, this.campaign.presentation.timeZone).isAfter(currentTime);
    },

    isFreeCampaign() {
      return this.campaign && this.campaign.presentation.flow.length === 1;
    },

    registrationsChartData() {
      if (!this.campaign) {
        return {};
      }
      let chartDatas;
      const hasChartDatas = this.registrationSeries.entries && this.registrationSeries.entries.length > 0;

      const topics = [
        {
          name: 'Registrations',
          colors: ['#C96DD8', '#7344C0'],
          valueGetters: [
            {
              name: 'Viral',
              getter: ({ ts, viral }) => [moment.tz(ts, this.campaign.presentation.timeZone).format('D MMM'), viral],
            },
            {
              name: 'Direct',
              getter: ({ ts, direct }) => [moment.tz(ts, this.campaign.presentation.timeZone).format('D MMM'), direct],
            },
          ],
          format: '{value}',
          yAxisMinRange: 5,
          hasTooltip: true,
          sharedTooltip: true,
          stickyTracking: true,
          hasYLabel: true,
          hasLegend: true,
        },
      ];

      let startDate = moment.utc(this.campaign.startDate);
      let endDate = moment.utc(this.campaign.endDate);

      if (hasChartDatas) {
        chartDatas = this.registrationSeries.entries;
        const eventDaysCount = endDate.diff(startDate, 'days');
        let lastChartDate = moment.utc(chartDatas[chartDatas.length - 1].ts);

        // Keep filling datas until we hit the endDate or over 100 datas
        while (lastChartDate.isBefore(endDate)) {
          chartDatas.push({
            ts: lastChartDate.format('YYYY-MM-DD'),
            viral: 0,
            direct: 0,
          });
          lastChartDate.add(1, 'days');
        }
      } else {
        let startDate = moment.utc(this.campaign.startDate);
        let emptyData = [];

        while (startDate.isSameOrBefore(endDate)) {
          emptyData.push({
            ts: startDate.format('YYYY-MM-DD'),
            viral: 0,
            direct: 0,
          });
          startDate.add(1, 'day');
        }

        chartDatas = emptyData;
      }

      return {
        topics,
        chartDatas,
      };
    },

    shareDefaultOrder() {
      return ['facebook', 'messenger', 'copy', 'sms', 'whatsapp'];
    },
    followDefaultOrder() {
      return ['facebook', 'instagram', 'twitter', 'youtube', 'spotify'];
    },
    connectDefaultOrder() {
      return ['facebook', 'instagram', 'spotify', 'twitter'];
    },

    registrations() {
      return {
        total: this.registrationStats && accounting.formatNumber(this.registrationStats.allRegistrations),
        increase: this.registrationStats && this.registrationStats.viralUplift,
      };
    },

    directRegistrations() {
      return this.registrationStats && accounting.formatNumber(this.registrationStats.directRegistrations);
    },

    viralRegistrations() {
      return this.registrationStats && accounting.formatNumber(this.registrationStats.viralRegistrations);
    },

    shareStats() {
      if (!this.registrationStats) return {};

      const share = this.registrationStats?.share;
      const invite = this.registrationStats?.invite;

      const shareTargets = share?.targets || [];
      const inviteTargets = invite?.targets || [];

      if (!(share && shareTargets) && !(invite && inviteTargets)) return {};
      
      // Merging share and invite stats
      return {
        targets: [...shareTargets, ...inviteTargets],
        total: (share?.total || 0) + (invite?.total || 0),
      }
    },

    followStats() {
      if (this.followMessageListOids.length && this.messageLists.length) {
        const targets = (this.registrationStats?.follow?.targets || [])
          .map((target) => {
            if (target?.channel !== "email") return target
            const msgListOidStr = (target?.target || "").split(':').pop()
            const msgListOid = parseInt(msgListOidStr, 10)
            return {
              ...target,
              messageList: this.messageLists.find(lst => lst.oid === msgListOid)
            }
          })

        return {
          ...this.registrationStats?.follow,
          targets,
        }
      }
      return this.registrationStats?.follow;
    },

    connectStats() {
      return this.registrationStats?.connect;
    },

    leaderboardStats() {
      const leaderboardStats = this.registrationStats?.leaderBoard;
      if (!leaderboardStats) {
        return undefined;
      }
      return leaderboardStats.map((stat) => {
        stat.avatar = stat.profileImageUrl;
        return stat;
      });
    },

    uniqueViews() {
      return this.registrationStats && accounting.formatNumber(this.registrationStats.uniqueViews);
    },

    conversionRate() {
      if (!this.registrationStats) {
        return '0%';
      }

      return `${this.registrationStats.registrationFunnel || 0}%`;
    },

    followMessageListOids() {
      return (this.registrationStats?.follow?.targets || [])
        .filter((target) => target?.channel === "email")
        // target string is similar to `email:follow:enable:115450`
        .map((t) => (t?.target || "").split(':').pop())
        .filter(oid => oid !== "")
        .map(oid => parseInt(oid || 0, 10))
    },
  },

  async mounted() {
    this.isFetchingStats = true;
  },

  watch: {
    campaignOid: {
      handler(newValue, oldValue) {
        if (!!newValue && newValue !== oldValue) {
          this.fetchStatsAsynchronously();
        }
      },
      immediate: true,
    },
    followMessageListOids(oids) {
      if (oids.length) {
        this['messageList/FETCH_MORE_MESSAGE_LISTS']({
          reload: true,
          userDefined: true,
          oids: oids,
        })
      }
    },
  },

  methods: {
    ...mapActions([
      'FETCH_CAMPAIGN_REGISTRATIONS_SERIES',
      'campaign/FETCH_CAMPAIGN_REGISTRATION_STATS',
      'messageList/FETCH_MORE_MESSAGE_LISTS',
    ]),
    async fetchStatsAsynchronously() {
      try {
        this.isFetchingStats = true;
        await Promise.all([
          this['campaign/FETCH_CAMPAIGN_REGISTRATION_STATS'](this.campaignOid),
          this.fetchCampaignRegistrationsSeries(),
        ]);
      } catch (e) {
        console.error(e);
      } finally {
        this.isFetchingStats = false;
      }
    },
    audienceClick(page) {
      this.$router.push(`/campaigns/${this.campaignOid}/view/registrations`);
    },
    async fetchCampaignRegistrationsSeries() {
      try {
        this.registrationSeries = await this.FETCH_CAMPAIGN_REGISTRATIONS_SERIES(this.campaignOid);
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to fetch campaign registration series',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  .content-section {
    display: grid;
    grid-template-columns: calc(33.3% - 20px) calc(33.3% - 20px) calc(33.3% - 20px);
    grid-gap: 30px;

    .cell {
      grid-column: auto;
    }

    .registrations-cell {
      grid-column: 2 / span 2;
    }

    &.md-max {
      grid-gap: 12px;
      grid-template-columns: calc(33.3% - 8px) calc(33.3% - 8px) calc(33.3% - 8px);
      .cell {
        grid-column: 1 / span 3;
      }

      .registrations-cell {
        grid-column: 1 / span 3;
      }
    }
  }

  .ends-in {
    color: $blueGrey600;
    font-size: 14px;
  }
}
</style>
