<template>
  <section>
    <div class="message-list-top-left-section">
      <div v-show="!showMessageNameEdit" class="title-section" :class="disableSubForms == true ? 'disabled' : ''">
        <am2-heading
          class="title"
          type="h1"
          size="md"
          :title="scratchEmailMessage.customerName || 'Name your email...'"
          @click.native="handleEditNameInputOpen"
        />
        <ar-icon class="icon u-margin-left-10" name="edit" @click="handleEditNameInputOpen" />
        <am2-tag
          v-if="isAutoMessageList"
          class="tag"
          text="Dynamic"
          type="purple"
          v-tooltip.top="{
            content: 'Existing and future contacts that match your segment will automatically be added to this list'
          }"
        />
      </div>

      <div v-show="showMessageNameEdit" class="edit-section">
        <div class="edit-section-input">
          <input
            ref="edit-name-input"
            v-model="customerName"
            data-vv-name="messageNameEdit"
            class="edit-name-input"
            input-font-size="md"
            @blur="handleEditNameInputBlur"
            @keyup.esc="handleEditNameInputBlur"
            @keyup.enter="handleCustomerNameInput"
          />
          <ar-text
            size="sm"
            class="edit-name-input-done"
            text="<a>Done</a>"
            allow-html
            @anchorMouseDown="handleCustomerNameInput"
          />
        </div>
      </div>
    </div>
    <MessageDetailsScheduleBlock
      @edit="handleScheduleEdit"
      @cancel="handleScheduleCancel"
    />
    <MessageDetailsToBlock
      ref="ToBlock"
      @update="handleMessageListChange"
      @open="handleOpen"
      @close="handleClose"
      @closeCallback="setDiscardChangesCallback"
      channel="email"
      :is-field-expanded="disableSubForms"
      :show-advanced-targeting-module="showAdvancedTargetingModule"
    />
    <div>
      <ar-state-message
        v-if="currentCampaignIsActive && currentMessageListIsForCampaignTier"
        class="u-margin-top-5"
        :text-props="{
          weight: 'bold'
        }"
        has-icon
        disable-text-color
        type="information"
        text="Note: It's recommended to only message individual tiers once the campaign is ended."
      />
    </div>

    <MessageDetailsFromBlock
      ref="from-block"
      @update="handleFromUpdate"
      @open="handleOpen"
      @close="handleClose"
      @closeCallback="setDiscardChangesCallback"
      :is-field-expanded="disableSubForms"
    />
    <MessageDetailsSubjectPreviewBlock
      @update="handleSubjectPreviewUpdate"
      @open="handleOpen"
      @close="handleClose"
      @closeCallback="setDiscardChangesCallback"
      :subjectText="scratchEmailMessage.meta.messageBody.subject"
      :is-field-expanded="disableSubForms"
      ref="subject-block"
    />
    <div class="link-tracking-card">
      <!-- Link Tracking Card -->
      <am2-card-container
        class="u-display-flex u-align-items-center u-justify-content-space-between u-padding-y-6 u-padding-x-7"
        :class="disableSubForms == true ? 'disabled' : ''"
      >
        <div :class="['u-flex-grow-1', 'u-padding-right-3']">
          <div class="u-display-flex  u-flex-flow-row u-align-items-center">
            <ar-text size="sm" multiple-lines weight="bold" text="Link click tracking" />

            <ar-icon
              name="tooltip-question-mark"
              class="u-margin-left-2"
              :color="$arStyle.color.skyBlueGrey600"
              v-tooltip.top="{
                content:
                  'When link click tracking is turned off your message will be delivered faster, but you won\'t be able to track clicks for this message.'
              }"
            />
          </div>
          <ar-text
            size="xs"
            text="Emails send quicker with tracking off but you can't track clicks"
            multiple-lines
            :style="{
              color: $arStyle.color.blueGrey700
            }"
          />
        </div>
        <div v-tooltip.top="{
                content: scratchEmailMessage.abEmail ? 'Link click tracking cannot be disabled while A/B testing is turned on.' : ''
              }">
          <am2-switch
            :value="!!!scratchEmailMessage.meta.disableLinkTracking"
            :disabled="scratchEmailMessage.abEmail"
            @input="handleLinkTrackingToggle"
          />
        </div>
      </am2-card-container>
    </div>
    <DiscardChangesModal
      :opened="isDiscardChangesModalOpen"
      @close="handleCloseDiscardChangesModal"
      @confirm="handleConfirmDiscardChangesModal"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import accounting from 'accounting';

import { clone } from '@/utils/helpers/';
import MessageDetailsToBlock from '../../messages/view/message-details-to-block.vue';
import MessageDetailsSubjectPreviewBlock from '../../messages/view/message-details-subject-preview-block.vue';
import MessageDetailsFromBlock from '../../messages/view/message-details-from-block.vue';
import MessageDetailsScheduleBlock from '../../messages/view/message-details-schedule-block.vue';
import DiscardChangesModal from '~/components/modals/message/discard-changes-modal'

export default {
  name: 'Basic',
  components: {
    MessageDetailsToBlock,
    MessageDetailsSubjectPreviewBlock,
    MessageDetailsFromBlock,
    MessageDetailsScheduleBlock,
    DiscardChangesModal,
  },
  data() {
    return {
      arEnableMessageAdvancedTargeting: process.env.arEnableMessageAdvancedTargeting,
      showMessageNameEdit: false,
      messageNameEdit: null,
      customerName: '',
      disableSubForms: false,
      discardChangesCallback: null,
      isDiscardChangesModalOpen: false,
    };
  },

  watch: {
    scratchEmailMessage: {
      handler() {
        if (this.scratchEmailMessage && this.scratchEmailMessage.name) {
          this.customerName = this.scratchEmailMessage.customerName;
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapState({
      messageOid: state => parseInt(state.route.params.oid, 10),
      currentMessageList: state => state.messageList.currentMessageList,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      currentMessageSender: state => state.messageSender.currentMessageSender,
      useScratchSegment: state => state.audience.audienceSelection.useScratchSegment,
      isFetchingFilteredRecipientListCount: state => state.messageList.isFetchingFilteredRecipientListCount,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      filteredRecipientListCountAccuracy: state => state.messageList.filteredRecipientListCountAccuracy,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      emailVariants: state => state.message.scratchEmailMessageVariants
    }),
    ...mapGetters({
      scratchEmailMessageCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailMessageEventOid: 'message/scratchEmailMessageEventOid',
      currentCampaignIsActive: 'campaign/currentCampaignIsActive',
      currentMessageSenderNotVerified: 'messageSender/currentMessageSenderNotVerified',
      currentMessageListHasNoEmailContacts: 'messageList/currentMessageListHasNoEmailContacts',
      currentMessageListIsForAllCampaignRegistrants: 'messageList/currentMessageListIsForAllCampaignRegistrants',
      currentMessageListIsForCampaignTier: 'messageList/currentMessageListIsForCampaignTier',
      currentMessageListCanBeSchedued: 'messageList/currentMessageListCanBeSchedued',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
      prunedScratchSegment: 'segment/prunedScratchSegment',
      getSegmentConditionExpression: 'segment/getSegmentConditionExpression',
      partlySelectedAudience: 'audience/partlySelectedAudience',
      currentAdvancedTargetingIsValid: 'messageList/currentAdvancedTargetingIsValid'
    }),

    // We don't want to show the adv targeting module on campaign sends, if no message list is selected
    // or while a draft message is loading
    showAdvancedTargetingModule() {
      if (!this.arEnableMessageAdvancedTargeting) return false;
      if (this.scratchEmailMessageCampaignOid) return false;
      if (this.scratchEmailMessageEventOid) return false;
      if (this.messageOid && !this.currentSelectedMessage) return false;
      if (!this.scratchEmailMessage) return false;
      if (!this.scratchEmailMessage.meta.messageListOid && !(this.currentMessageList && this.currentMessageList.oid))
        return false;
      return true;
    },

    showFilterButton() {
      if (this.scratchEmailMessageCampaignOid) return false;
      if (this.scratchEmailMessageEventOid) return false;
      return true;
    },
    isAutoMessageList() {
      if (!this.currentMessageList) {
        return false;
      }
      return this.currentMessageList.filterGroupOid ? true : false;
    }
  },
  methods: {
    getEditNameInputElem() {
      return this.$refs['edit-name-input'];
    },
    handleEditNameInputOpen() {
      this.showMessageNameEdit = true;
      this.messageNameEdit = this.messageName;
      this.$nextTick(() => {
        this.getEditNameInputElem().focus();
      });
    },
    handleEditNameInputBlur() {
      this.showMessageNameEdit = false;
      this.handleCustomerNameInput();
    },

    ...mapActions([
      'messageSender/RESEND_EMAIL_VERIFICATION',
      'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      'segment/FETCH_SEGMENTS'
    ]),
    ...mapMutations([
      'messageSender/SET_CURRENT_MESSAGE_SENDER',
      'messageSender/RESET_CURRENT_MESSAGE_SENDER',
      'messageList/SET_CURRENT_MESSAGE_LIST',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      'message/PATCH_AB_EMAIL_MESSAGE',
      'message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE',
      'message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE',
      'layout/TOGGLE_SEGMENT_DRAWER',
      'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
      'messageList/SET_FILTERED_RECIPIENT_LIST_COUNT',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT',
      'message/SET_CONVERSIONS_SELECTED_EVENTS',
    ]),
    clearScheduleTime() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          presentation: {
            timeZone: null
          }
        },
        scheduledAt: null
      });
    },
    handleMessageSenderClear() {
      this['messageSender/RESET_CURRENT_MESSAGE_SENDER']();
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          email: {
            promoterPropertyOid: null
          }
        }
      });
    },

    handleFromUpdate(data) {
      this['messageSender/SET_CURRENT_MESSAGE_SENDER'](data.sender);
      if (data.sender && data.sender.oid) {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            email: {
              promoterPropertyOid: data.sender.oid
            },
            linkedEvents: data.linkedEvents || []
          }
        });
      } else if (data.linkedEvents && data.linkedEvents.length) {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            linkedEvents: data.linkedEvents || []
          }
        });
      }
      this['message/SET_CONVERSIONS_SELECTED_EVENTS'](data.linkedEvents)
    },
    handleCustomerNameInput() {
      let name = this.customerName;
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        name
      });
      this.scratchEmailMessage.customerName = this.customerName;
      this.showMessageNameEdit = false;
    },
    handleSubjectPreviewUpdate(data) {
      if (this.scratchEmailMessage.abEmail) {
        this['message/PATCH_AB_EMAIL_MESSAGE'](data);
      }
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          messageBody: {
            subject: data.subject || data.subjectA,
            previewText: data.preview || data.previewA,
          }
        }
      });
    },
    async handleMessageListChange(data) {
      let payload = {meta: {}};
      if (data.eventOid) {
        payload.meta.initiator = {eventOid: data.eventOid}
      }
      if (data.suggestedAudience) {
        payload.meta.suggestedAudience = data.suggestedAudience;
      }
      // Let's not save the message unless a list is present too
      if (data.list) {
        payload.meta.messageListOid = data.list.oid;
        payload.meta.recipientsCount = data.list.statsSnapshot.email

        this['message/PATCH_SCRATCH_EMAIL_MESSAGE'](payload);

        // The old dynamic tags file was mapped to old list, so we have to clean it up
        this['message/REMOVE_DYNAMIC_TAGS_IN_SCRATCH_EMAIL_MESSAGE']();
        this['messageList/SET_CURRENT_MESSAGE_LIST'](data.list);
        await this.$nextTick();

        // RESET ADVANCED FILTERING
        if (!this.currentMessageListCanBeSchedued) {
          this.clearScheduleTime();
          if (this.$refs['ToBlock']) {
            this.$refs['ToBlock'].handleAdvancedTargetingUpdate();
          }
        }
      } else {
        console.error("Cannot save the sender without a list!")
      }
    },
    handleResendVerificationEmailAnchorClick(anchorIdx) {
      if (anchorIdx === 0) {
        this['messageSender/RESEND_EMAIL_VERIFICATION'](this.currentMessageSender.oid);
      }
    },
    handleLinkTrackingToggle() {
      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          disableLinkTracking: !!!this.scratchEmailMessage.meta.disableLinkTracking
        }
      });
    },

    switchFilterSidebar() {
      this['layout/TOGGLE_SEGMENT_DRAWER']({ hideCreateSegmentButton: true, hideFilterApplyButton: true });
    },

    async handleClearAllFilters() {
      this['segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT']();
      await this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
      if (this.$refs['ToBlock']) {
        this.$refs['ToBlock'].handleAdvancedTargetingUpdate();
      }
    },
    handleOpen() {
      this.disableSubForms = true;
      this.$emit('disableFields');
      this.$emit('checkIfOutdated')
    },
    handleClose() {
      this.disableSubForms = false;
      this.$emit('disableFields');
    },
    handleScheduleEdit() {
      this.$emit('editSchedule');
    },
    handleScheduleCancel() {
      this.$emit('cancelSchedule');
    },
    setDiscardChangesCallback(callback) {
      this.discardChangesCallback = callback;
      this.isDiscardChangesModalOpen = true;
    },

    handleConfirmDiscardChangesModal() {
      this.isDiscardChangesModalOpen = false;
      this.discardChangesCallback();
    },

    handleCloseDiscardChangesModal() {
      this.isDiscardChangesModalOpen = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.link-tracking-card {
  margin-top: 30px;
}
.components-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background: white;

  .message-send-inner-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 90%;
    margin-top: 30px;
    max-width: 718px;
  }
}
.circle-hooker {
  position: relative;
  .circle {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background: $purple500;
    transform: translateX(50%) translateY(-50%);
    color: white;
    z-index: $zIndexHigh;

    span {
      position: relative;
      font-size: 10px;
      line-height: 10px;
      top: -1px;
    }
  }
}
.message-list-top-left-section {
  display: flex;
  flex-direction: row;
  min-width: 0; // default min-width is auto, we have to replace it with 0
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  flex-grow: 1;

  &.sm-min {
    margin-bottom: 0;
  }
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.title-section {
  display: flex;
  align-items: center;
  height: 40px;
  flex-direction: row;
  max-width: calc(100% - 24px);

  .title {
    cursor: pointer;
  }
  .icon {
    cursor: pointer;
    color: $blueGrey500;
    height: 16px;
  }
  .tag {
    height: 24px;
    margin-left: 19px;
    padding: 0 24px;
  }
  .recipients-count {
    background-color: $skyBlueGrey300;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    border-radius: 4px;

    .recipients-text {
      color: $blueGrey800;
    }

    .recipients-description {
      color: $blueGrey700;
    }
  }

  .edit-section {
    display: flex;
    flex-direction: column;
    width: 100%;

    .edit-section-input {
      display: flex;
      flex-direction: row;
      width: 100%;
      .edit-name-input {
        width: calc(100% - 35px);
        height: 40px;
        border: none;
        box-shadow: none;
        background: none;
        background-color: none;
        font-size: 24px;
        color: $skyBlueGrey800;
        border-bottom: 1px dashed $purple200;
        padding-bottom: 4px;
        outline: none;
      }
      .edit-name-input-done {
        color: $purple500;
        text-decoration: underline;
        cursor: pointer;
        margin: 0 0 0 12px;
        align-self: center;
      }
      .input-confirm {
        width: 35px;
        display: inline-flex;
        margin-left: 8px;
      }
    }
  }
}
.edit-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  .edit-section-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    .edit-name-input {
      width: calc(100% - 35px);
      height: 40px;
      border: none;
      box-shadow: none;
      background: none;
      background-color: none;
      font-size: 24px;
      color: $skyBlueGrey800;
      border-bottom: 1px dashed $purple200;
      padding-bottom: 4px;
      outline: none;
    }
    .edit-name-input-done {
      color: $purple500;
      text-decoration: underline;
      cursor: pointer;
      margin: 0 0 0 12px;
      align-self: center;
    }
    .input-confirm {
      width: 35px;
      display: inline-flex;
      margin-left: 8px;
    }
  }
}
</style>
