<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="ticketmaster-modal"
        header="Connect to Ticketmaster (AU/NZ)"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'ticketmaster-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
          <ar-field
            label="Account Name"
            class="u-margin-top-5"
              >
              <ar-input
                v-model="accountName"
                name="accountName"
                placeholder="Please enter your Ticketmaster (AU/NZ) account name"
                id="ticketmaster-account-name-input"
                type="text"
                data-test-id="ticketmaster-account-name-input"
              />
          </ar-field>
          <div
            class="u-display-flex u-margin-bottom-5 u-margin-top-5"
          >
            <ar-text
             size="xs"
             text="Subscribe associated contacts to list (optional)"
             weight="bold"
             :style="{
               color: $arStyle.color.blueGrey800,
             }"
            />
            <ar-icon
             v-tooltip.top="{
               content: `Automatically subscribe all attendees of events from this Ticketmaster (AU/NZ) account.`,
             }"
             name="tooltip-question-mark"
             class="u-margin-left-2"
             height="15px"
             width="15px"
             :color="$arStyle.color.purple500"
            />
          </div>
          <ar-field
            :error-messages="[
              veeErrors.first('mailingListOid')
            ]"
            :style="{
              marginTop: '15px',
            }"
          >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            v-model="mailingListOid"
            :channels="['email']"
          />
        </ar-field>
        <ar-field
          :style="{ marginTop: '15px' }"
          >
           <ar-checkbox
             label="Assign tags from Ticketmaster Minor and Major genre categories<br>to newly created fan accounts"
             key="tag-fans"
             class="u-margin-right-5"
             data-test-id="checkbox-ticketmaster-assign-tags"
             :value="tagFans"
             @input="handleTagFans"
           />
        </ar-field>
        </div>

        <div
          slot="footer"
          :class="[
            'ticketmaster-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="ticketmaster-confirm-button"
            data-test-id="ticketmaster-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>

  <script>
  import { mapActions } from 'vuex';

  export default {
    name: 'AskInputModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        providerName: "",
        mailingListOid: null,
        accountName: "",
        tagFans: false
      };
    },

    computed: {
      confirmButtonDisabled() {

        return !this.accountName;
      }
    },

    watch: {
      isShow(val) {
        if (val) {
          this.providerName = null;
          this.mailingListOid = null;
          this.accountName = null;
          this.tagFans = false;
        }
      },
    },

    methods: {
      ...mapActions([
        'ticketmasterIntegration/FETCH_INTEGRATION',
        'ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION',
      ]),
      async fetchIntegration() {
        const success = await this['ticketmasterIntegration/FETCH_INTEGRATION']()
        return success;
      },
      async handleConfirmClick() {
        this.errorMessage = null;
        this.httpStatus = null;
        const providerName = this.providerName;
        const mailingListOid = this.mailingListOid;
        const accountName = this.accountName;
        const tagFans = this.tagFans;
        const response = await this['ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION']({
          providerName,
          mailingListOid,
          accountName,
          tagFans
        });
        if (response) {
          if (this.$route.path != '/settings/integrations/ticketmaster') {
            await this.$router.push({ path: '/settings/integrations/ticketmaster' });;
          }
          else {
            this.fetchIntegration();
          }
          this.$emit('cancel');
        }
        else {
          this.errorMessage = response.message;
          this.httpStatus = response.httpStatusCode;
        }
      },
      handleCloseClick() {
        this.$emit('cancel');
      },
      toggleApplyPrefix() {
        this.applyPrefix = !this.applyPrefix;
      },
      handleTagFans(value) {
        this.tagFans = value;
      }
    },
  };
  </script>

  <style lang="scss" scoped>
  .ticketmaster-modal {
    .ticketmaster-modal-body {
      padding: 20px 30px 30px;

      &:nth-child(2) {
        border-top: 1px solid $blueGrey500;
      }
    }
    .ticketmaster-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>
