<template>
  <am2-item-multiselect
    to="card-sub-details"
    :items="messages"
    @select="handleSelect"
    :defaultValue="defaultValue"
    :data-test-id="dataTestId"
    item-label="messages"
    @updateSearchText="updateSearchText"
  />
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'MessageMultiselect',

    props: {
      defaultValue: { // Value equals to messageOid
        type: Array,
        default: null,
      },
      dataTestId: {
        type: String,
        default: null,
      }
    },

    data() {
      return {
        messages: [],
        searchString: null,
      };
    },

    mounted() {
      this.loadMessages();
    },

    computed: {
      ...mapState({
        auth: state => state.auth,
      }),
    },

    watch: {
      searchString() {
        this.loadMessages()
      }
    },

    methods: {
      handleSelect(item) {
        this.$emit('select', item);
      },

      updateSearchText(text) {
        this.searchString = text
      },

      async loadMessages() {
        const { promoterOid } = this.auth.account;
        try {
          const data = await this.$api.messages.search(promoterOid, {
            top: 50,
            skip: 0,
            orderby: 'started desc',
            searchString: this.searchString,
            messageStatus: 'completed',
            provider: this.provider
          });

          this.messages = data.map( item => {
            let name = item.name;

            if (item?.customerName) {
              name = item.customerName;
            } else if (item?.meta?.messageBody?.subject) {
              name = item.meta.messageBody.subject;
            } else if (item?.meta?.messageBody) {
              name = item.meta.messageBody;
            }
            return {
              value: item.oid,
              name: name,
            }
          });
        } catch (error) {
          this.$arNotification.push({ type: 'error', message: `Something went wrong when searching for the message.` })
          console.error('Failed MESSAGE search, with error:', error);
        }
      },
    }

  };
</script>
