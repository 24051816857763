var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"message-list-modal",attrs:{"is-open":_vm.isShow,"header":_vm.cardTitle,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'message-list-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"u-display-flex u-margin-bottom-3"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Subscribe associated contacts to list (optional)","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.tooltipTextList),expression:"tooltipTextList",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('messageListOid')
        ]}},[_c('am2-message-list-select',{attrs:{"message-list-type":"manual","show-stats":false,"channels":['email']},model:{value:(_vm.messageListOid),callback:function ($$v) {_vm.messageListOid=$$v},expression:"messageListOid"}})],1),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-margin-bottom-3 u-margin-top-5"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Pick a date to sync from (optional)","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.tooltipTextDatePicker),expression:"tooltipTextDatePicker",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('syncFrom')
        ]}},[_c('am2-datepicker',{ref:"datepicker-1",style:({
            width: '100%',
            height: '40px'
          }),attrs:{"value":_vm.syncFrom,"name":"datepicker","placeholder":"Date","data-test-id":"filter-date-input-1","no-left-padding":true},on:{"input":function($event){return _vm.handleDateSelect.apply(void 0, arguments)}}})],1)],1),_vm._v(" "),_c('div',{class:[
        'message-list-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"}),_vm._v(" "),_c('div',{class:[
        'message-list-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","loading":_vm.loading,"disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }