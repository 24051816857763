var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-modal',{staticClass:"import-data-modal",attrs:{"is-open":_vm.isVisible,"header":_vm.modalTitle,"has-back-link":_vm.step > 1,"hide-footer":_vm.step > 1,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.onClose,"back":_vm.goBack}},[(_vm.step === 1)?_c('div',{class:['import-data-modal__wrapper', _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max'],attrs:{"slot":"body"},slot:"body"},[_c('ar-text',{attrs:{"text":"Format options","size":"sm"}}),_vm._v(" "),_c('ar-divider',{style:({
        margin: '12px 0 0 0',
        height: '0.5px',
        background: _vm.$arStyle.color.$bluegrey500,
      })}),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('ar-text',{staticClass:"form-row__label",attrs:{"text":"Date format","weight":"bold","size":"xs"}}),_vm._v(" "),_c('ar-simple-select',{staticClass:"form-row__input",class:{'form-row__input--placeholder': !_vm.selectedDateFormat},attrs:{"items":_vm.dateFormatOptions,"placeholder":"Select a date format","has-error":!!_vm.errorDateSelect,"placeholder-props":{style: {color: _vm.$arStyle.color.$bluegrey500}}},on:{"select":_vm.selectDateformat}}),_vm._v(" "),(!!_vm.errorDateSelect)?_c('ar-text',{staticClass:"error-text",attrs:{"text":"Date format required","size":"xs"}}):_vm._e()],1),_vm._v(" "),_c('ar-text',{style:({ marginTop: '40px' }),attrs:{"text":"Contact options","size":"sm"}}),_vm._v(" "),_c('ar-divider',{style:({
        margin: '12px 0 0 0',
        height: '0.5px',
        background: _vm.$arStyle.color.$bluegrey500,
      })}),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('ar-text',{staticClass:"form-row__label",attrs:{"text":"Add contacts to list","weight":"bold","size":"xs"}}),_vm._v(" "),_c('div',{staticClass:"message-list-select"},[_c('am2-message-list-select',{staticClass:"form-row__input",attrs:{"channels":['email'],"message-list-type":"manual","show-stats":false,"disabled":!_vm.canChangeMessageList,"is-selected-purple":true},on:{"select":_vm.handleMessageListChange},model:{value:(_vm.messageListSettings.oid),callback:function ($$v) {_vm.$set(_vm.messageListSettings, "oid", $$v)},expression:"messageListSettings.oid"}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageListSettings.oid !== null),expression:"messageListSettings.oid !== null"}],staticClass:"channel-choices"},[_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageListSettings.listContainsEmail),expression:"messageListSettings.listContainsEmail"},{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.messageListSettings.emailCanBeEnabled
                ? null
                : "One or more of your imported contacts does not have a valid email address",
            }),expression:"{\n              content: messageListSettings.emailCanBeEnabled\n                ? null\n                : `One or more of your imported contacts does not have a valid email address`,\n            }",modifiers:{"top":true}}],staticClass:"tick-section-checkbox",attrs:{"disabled":!_vm.messageListSettings.emailCanBeEnabled,"label":"Email","icon-name":"email","icon-props":{
              width: '18px',
              color: _vm.$arStyle.color.email,
            }},model:{value:(_vm.messageListSettings.emailEnabled),callback:function ($$v) {_vm.$set(_vm.messageListSettings, "emailEnabled", $$v)},expression:"messageListSettings.emailEnabled"}}),_vm._v(" "),_c('ar-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageListSettings.listContainsSms),expression:"messageListSettings.listContainsSms"},{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.messageListSettings.smsCanBeEnabled
                ? null
                : "One or more of your imported contacts does not have a valid phone number",
            }),expression:"{\n              content: messageListSettings.smsCanBeEnabled\n                ? null\n                : `One or more of your imported contacts does not have a valid phone number`,\n            }",modifiers:{"top":true}}],staticClass:"tick-section-checkbox",attrs:{"disabled":!_vm.messageListSettings.smsCanBeEnabled,"label":"SMS","icon-name":"sms","icon-props":{
              width: '18px',
              color: _vm.$arStyle.color.sms,
            }},model:{value:(_vm.messageListSettings.smsEnabled),callback:function ($$v) {_vm.$set(_vm.messageListSettings, "smsEnabled", $$v)},expression:"messageListSettings.smsEnabled"}})],1),_vm._v(" "),(!!_vm.errorListEnabled)?_c('ar-text',{staticClass:"error-text",attrs:{"text":"List required","size":"xs"}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('ar-text',{staticClass:"form-row__label",attrs:{"text":"Add tags to contacts","weight":"bold","size":"xs"}}),_vm._v(" "),_c('am2-fan-tag-input',{staticClass:"form-row__input form-row__input--tag",attrs:{"placeholder":"Select a tag or type to create a new one","size":"sm"},model:{value:(_vm.newFanTags),callback:function ($$v) {_vm.newFanTags=$$v},expression:"newFanTags"}})],1)],1):(_vm.step === 2)?_c('div',{attrs:{"slot":"body"},slot:"body"}):_vm._e(),_vm._v(" "),(_vm.step === 1)?_c('div',{class:['import-data-modal__footer', _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3'],attrs:{"slot":"footer"},slot:"footer"},[(_vm.error)?_c('div',{staticClass:"error-message",domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e(),_vm._v(" "),_c('div',[_c('div',[_c('ar-simple-button',{style:({ float: 'right', height: '40px' }),attrs:{"text":"Continue","loading":_vm.importButtonDisabled,"disabled":_vm.nextButtonDisabled,"type":'purple'},on:{"click":_vm.handleNextStep}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }