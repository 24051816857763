<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      class="add-payment-method-modal"
      :is-open="isShow"
      hide-header
      hide-footer
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div slot="body" class="body">
        <ar-text
          size="md"
          text="Add Payment Method"
          weight="bold"
          align="center"
          class="u-margin-bottom-10"
        />
        <am2-stripe-element-form
          ref="billing-form"
          :loading="isCreatingPaymentSource"
          @submit="handlePaymentMethodSubmit"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'AddPaymentMethodModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      arEnvironment: process.env.arEnvironment,
      paymentSource: null,
    };
  },
  computed: {
    ...mapState({
      isCreatingPaymentSource: state => state.payment.isCreatingPaymentSource,
    }),
  },

  methods: {
    ...mapActions([
      'payment/CREATE_PAYMENT_SOURCE'
    ]),

    getBillingFormInstance() {
      return this.$refs['billing-form'];
    },

    handleClose() {
      this.$emit('cancel')
    },

    async handlePaymentMethodSubmit(paymentSource) {
      const newPaymentSource = await this['payment/CREATE_PAYMENT_SOURCE'](paymentSource);
      if (newPaymentSource) {
        this.$emit('create', newPaymentSource);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-payment-method-modal {
  .body {
    padding: 30px;
  }
}
</style>
