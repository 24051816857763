<template>
  <ar-modal
    :is-open="isVisible"
    class="import-data-modal"
    :header="modalTitle"
    :has-back-link="step > 1"
    :hide-footer="step > 1"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
    @back="goBack"
  >
    <!-- BODY -->
    <div
      v-if="step === 1"
      slot="body"
      :class="['import-data-modal__wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
    >
      <ar-text text="Format options" size="sm" />
      <ar-divider
        :style="{
          margin: '12px 0 0 0',
          height: '0.5px',
          background: $arStyle.color.$bluegrey500,
        }"
      />
      <div class="form-row">
        <ar-text class="form-row__label" text="Date format" weight="bold" size="xs" />
        <ar-simple-select
          class="form-row__input"
          :class="{'form-row__input--placeholder': !selectedDateFormat}"
          :items="dateFormatOptions"
          placeholder="Select a date format"
          :has-error="!!errorDateSelect"
          :placeholder-props="{style: {color: $arStyle.color.$bluegrey500}}"
          @select="selectDateformat"
        />
        <ar-text v-if="!!errorDateSelect" class="error-text" text="Date format required" size="xs" />
      </div>
      <ar-text text="Contact options" :style="{ marginTop: '40px' }" size="sm" />
      <ar-divider
        :style="{
          margin: '12px 0 0 0',
          height: '0.5px',
          background: $arStyle.color.$bluegrey500,
        }"
      />
      <div class="form-row">
        <ar-text class="form-row__label" text="Add contacts to list" weight="bold" size="xs" />

        <div class="message-list-select">
          <am2-message-list-select
            v-model="messageListSettings.oid"
            @select="handleMessageListChange"
            :channels="['email']"
            message-list-type="manual"
            class="form-row__input"
            :show-stats="false"
            :disabled="!canChangeMessageList"
            :is-selected-purple="true"
          />

          <div v-show="messageListSettings.oid !== null" class="channel-choices">
            <ar-checkbox
              v-show="messageListSettings.listContainsEmail"
              v-model="messageListSettings.emailEnabled"
              class="tick-section-checkbox"
              :disabled="!messageListSettings.emailCanBeEnabled"
              v-tooltip.top="{
                content: messageListSettings.emailCanBeEnabled
                  ? null
                  : `One or more of your imported contacts does not have a valid email address`,
              }"
              label="Email"
              icon-name="email"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.email,
              }"
            />
            <ar-checkbox
              v-show="messageListSettings.listContainsSms"
              v-model="messageListSettings.smsEnabled"
              :disabled="!messageListSettings.smsCanBeEnabled"
              v-tooltip.top="{
                content: messageListSettings.smsCanBeEnabled
                  ? null
                  : `One or more of your imported contacts does not have a valid phone number`,
              }"
              class="tick-section-checkbox"
              label="SMS"
              icon-name="sms"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.sms,
              }"
            />
          </div>

          <ar-text v-if="!!errorListEnabled" class="error-text" text="List required" size="xs" />
        </div>
      </div>
      <div class="form-row">
        <ar-text class="form-row__label" text="Add tags to contacts" weight="bold" size="xs" />
        <am2-fan-tag-input
          class="form-row__input form-row__input--tag"
          placeholder="Select a tag or type to create a new one"
          v-model="newFanTags"
          size="sm"
        />
      </div>

    </div>
    <!-- CSV Column Values -> Contacts Field mapping -->
    <div v-else-if="step === 2" slot="body">

    </div>

    <!-- FOOTER -->
    <div
      v-if="step === 1"
      slot="footer"
      :class="['import-data-modal__footer', $arMediaQuery.window.maxWidth('xs') && 'u-padding-3']"
    >
      <div v-if="error" class="error-message" v-html="error" />
      <div>
        <div>
          <ar-simple-button
            text="Continue"
            :loading="importButtonDisabled"
            :disabled="nextButtonDisabled"
            @click="handleNextStep"
            :type="'purple'"
            :style="{ float: 'right', height: '40px' }"
          />
        </div>
      </div>
    </div>
  </ar-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { useOneSchemaImporter } from "@oneschema/vue"

export default {
  name: 'ImportContactsOneschemaModal',

  data() {
    return {
      isVisible: false,
      step: 1,
      type: 'fan',
      selectedDateFormat: null,
      dateFormatOptions: [{name: 'International format (DD/MM/YYYY)', val: 'international', oneSchemaCode: 'contact_only'},
        {name: 'US format (MM/DD/YYYY)', val: 'us', oneSchemaCode: 'contact_only_US'}],
      csvHeaders: [],
      csvRows: [],
      error: '',
      rowErrors: false,
      acceptErrors: false,
      newFanTags: [],
      messageListSettings: {
        oid: null,
        smsCanBeEnabled: false, // Do all items in the contact import satisfy requirements to allow SMS?
        emailCanBeEnabled: false, // Do all items in the contact import satisfy requirements to allow Email?
        smsEnabled: false, // Has SMS been checked?
        emailEnabled: false, // Has Email been checked?
        listContainsSms: false, // Does the list even contain SMS?
        listContainsEmail: false, // Does the list even contain Email?
      },
      mouseOverCsvDropdown: false,
      canChangeMessageList: true,
      importFile: null,
      awaitingServerResponse: false,
      indexesToValidate: [], // Keep it consistent so that we don't recompute this every time the user presses Next
      goBack: () => {},
      onConfirm: () => {},
      onClose: () => {},
      csvImportTemplates: [{
        name: 'Contacts Only',
        code: 'contact_only'
      }, {
        name: 'Contacts Only (US)',
        code: 'contact_only_US'
      }],
      errorDateSelect: '',
      errorListEnabled: '',
      arEnableCustomFields: true
    };
  },
  computed: {
    ...mapState({
      isImportingAudienceCsv: (state) => state.audience.isImportingAudienceCsv,
      promoterOid: (state) => state.auth.account.promoterOid,
      customFields: (state) => state.customField.customFields,
      oneschema: (state) => state.oneschema.oneschema
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    importButtonDisabled() {
      return false;
    },
    nextButtonDisabled() {
      return !!this.errorDateSelect
    },
    modalTitle() {
      return 'Import contacts';
    },

    isCustomFieldsFeatureEnabled() {
      return this.isFeatureEnabled(['custom-fields']);
    },

  },
  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },
  mounted() {
    this['oneschema/FETCH_ONESCHEMA_JWT']();
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    ...mapActions([
      'audience/IMPORT_ONESCHEMA_CSV',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'CLOSE_IMPORT_CONTACTS_MODAL',
      'oneschema/FETCH_ONESCHEMA_JWT'
    ]),
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_IMPORT_CONTACTS_MODAL') return;

      this.startModalAction(payload);
    },
    respondModalAction(payload) {
      window.dispatchEvent(
        new CustomEvent('arModalResponse', { detail: { action: 'OPEN_IMPORT_CONTACTS_MODAL', payload } })
      );
    },
    setupMessageListSettings(messageList) {
      if (messageList) {
        this.messageListSettings = {
          oid: messageList.oid,
          emailCanBeEnabled: messageList.email,
          emailEnabled: messageList.email,
          listContainsEmail: messageList.email,

          smsCanBeEnabled: messageList.sms,
          smsEnabled: messageList.sms,
          listContainsSms: messageList.sms,
        };
      } else {
        this.messageListSettings = {
          oid: null,
          smsCanBeEnabled: false,
          emailCanBeEnabled: false,
          smsEnabled: false,
          emailEnabled: false,
          listContainsSms: false,
          listContainsEmail: false,
        };
      }
    },
    startModalAction(payload = { messageList: null, canChangeMessageList: true }) {
      this.isVisible = true;
      this.awaitingServerResponse = false;

      const { messageList, canChangeMessageList } = payload;
      this.canChangeMessageList = canChangeMessageList;

      this.setupMessageListSettings(messageList);

      this.onConfirm = () => {
        this.respondModalAction(true);
        this.step = 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.newFanTags = [];
        this.isVisible = false;
        this.error = '';
        this.rowErrors = false;
        this.acceptErrors = false;
        this.indexesToValidate = []
        this.closeModal()
      };
      this.onClose = () => {
        this.step = 1;
        this.csvHeaders = [];
        this.csvRows = [];
        this.newFanTags = [];
        this.isVisible = false;
        this.error = '';
        this.rowErrors = false;
        this.acceptErrors = false;
        this.indexesToValidate = []
        this.closeModal()

        this.respondModalAction(false);
      };
      this.goBack = () => {
        this.step -= 1;
        this.setupMessageListSettings(messageList);
      }
    },
    handleMessageListChange(messageList) {
      this.messageListSettings.oid = messageList.oid;
      this.messageListSettings.selectedChannels = [];

      this.messageListSettings.emailCanBeEnabled = messageList.email;
      this.messageListSettings.emailEnabled = messageList.email;
      this.messageListSettings.listContainsEmail = messageList.email;

      this.messageListSettings.smsCanBeEnabled = messageList.sms;
      this.messageListSettings.smsEnabled = false;
      this.messageListSettings.listContainsSms = messageList.sms;
    },
    selectDateformat(val) {
      this.errorDateSelect = ''
      this.selectedDateFormat = val;
    },

    checkErrors() {
      if (!this.selectedDateFormat) {
        this.errorDateSelect = 'Please select a date format';
        return true;
      }
      if (this.messageListSettings.oid !== null && !this.messageListSettings.emailEnabled && !this.messageListSettings.smsEnabled) {
        this.errorListEnabled = 'Please select a message list';
        return true;
      }
      return false
    },

    async uploadEmbedData(embedData) {
      let payload = {
        embedId: embedData.embed_id,
        originalFilename: embedData.sheet_metadata.original_file_name,
        tags: this.newFanTags,
        dateFormat: this.selectedDateFormat.val,
        importType: 'audience-contact'
      }


      let subscriptions = {};
      if (this.messageListSettings.smsEnabled) {
        subscriptions.sms = true;
      }

      if (this.messageListSettings.emailEnabled) {
        subscriptions.email = true;
      }


      if (this.messageListSettings.oid) {
        payload.messageList = {
          oid: this.messageListSettings.oid,
          subscriptions,
        };
      }

      await this['audience/IMPORT_ONESCHEMA_CSV'](payload);
      this.onConfirm()
    },

    updatePendingTasksInAudienceAndMessage() {
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    },

    bodyOverflowHidden(){
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
    },

    bodyOverflowReset(){
      document.getElementsByTagName("body")[0].style.overflow = "auto"
    },

    closeModal() {
      this.isVisible = false;
      this['CLOSE_IMPORT_CONTACTS_MODAL']()
    },

    async handleNextStep() {
      const isErrors = this.checkErrors();
      if (isErrors) return;

      this.bodyOverflowHidden();

      const oneSchemaFieldType = {
        date: 'DATE_DMY',
        number: 'NUMBER',
        text: 'TEXT',
        "dropdown-multi": 'PICKLIST',
        "dropdown-single": 'PICKLIST'
      }

      const fieldValidationsOpt = {
        number: {
          only_int: true,
          max_num: null,
          min_num: null,
          only_int: true,
          allow_commas: false,
          num_decimals: null
        }
      }

      const dataOverride = {
        "dropdown-multi": {
          delimiter: ","
        }
      }

      const fieldsToAdd = this.customFields
      .filter(field => {
        return field.fieldType != "dropdown-multi"
      })
      .map(field => {

        const fieldValidation = fieldValidationsOpt[field.fieldType];
        const optionsOverride = field.options.length > 0 ? {
          picklist_options: field.options?.map(opt => {
           return {value: opt.name}
        })}: {}

        return Object.assign(
          {},
          {
            key: `cf_${field.oid}`,
            label: field.name,
            data_type: oneSchemaFieldType[field.fieldType],
            validation_options: Object.assign({}, fieldValidation, optionsOverride)
          },
          dataOverride[field.fieldType]
        )
      });

      this.$osImporter.launch({
        templateKey: this.selectedDateFormat.oneSchemaCode,
        templateOverrides: {
          columns_to_add: fieldsToAdd
        },
        userJwt: this.oneschema.jwt
      });

      this.$osImporter.removeAllListeners();

      this.$osImporter.on("success", (data) => {
        this.bodyOverflowReset();

        this.uploadEmbedData(data);

        setTimeout(() => {
          this.updatePendingTasksInAudienceAndMessage();
        }, 2000);
      });

      this.$osImporter.on("cancel", () => {
        this.bodyOverflowReset();
      })

      this.$osImporter.on("error", (message) => {
        this.bodyOverflowReset();
        this.$arNotification.push({
          type: 'error',
          message: `Error importing oneschema data. Please try again`,
        });

      });

      setTimeout(() => {
        this.closeModal()
      }, 200);


    }
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  color: $red500;
  margin-top: 8px;
}

.import-data-modal {
  .mapping-instructions {
    background-color: $skyBlueGrey300;
    padding: 20px;

    &__subtitle {
      font-size: 12px;
      color: $blueGrey700;
    }
  }

  .message-list-select {
    .message-list-subtitle {
      color: $blueGrey700;
      margin-top: 5px;
    }

    .channel-choices {
      margin-top: 10px;
      display: flex;
      flex-direction: row;

      .tick-section-checkbox {
        margin-right: 25px;
      }
    }
  }

  .column-chooser-tabs {
    background-color: $skyBlueGrey300;
    display: flex;
    flex-direction: row;
    padding: 0px 20px;

    &__option {
      margin-right: 20px;
      font-size: 14px;
      padding-right: 5px;
      padding-bottom: 10px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: solid 2px $purple500;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    overflow: auto;

    &.xs-max {
      padding: 28px 12px;
    }

    &.generic {
      padding: 0px;
    }

    > .mass-import-option {
      min-height: 86px;
      border-top: 1px solid $blueGrey500;
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      > div {
        padding: 0.75rem;

        > p.heading {
          color: #43516b;
          font-size: 16px;
        }

        > p.body {
          color: #8e97a6;
        }

        &:last-child {
          position: absolute;
          right: 2.5rem;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: darken(white, 2%);
      }
    }

    p.column-text {
      color: $blueGrey700;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 13px;
      border-bottom: 1px solid $skyBlueGrey500;
      padding-bottom: 13px;

      span:last-child {
        float: right;
      }
    }

    &__import-message {
      font-size: 1.2em;
      padding-top: 1em;
    }

    &_preamble {
      margin-bottom: 1em;
    }

    .form-row {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      &__label {
        display: flex;
        align-items: center;
      }

      &__input {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 12px;
      }

      &__input--placeholder {
        ::v-deep {
          .display-section {
            section {
              color: $blueGrey600;
            }
          }
        }
      }

      &__input--tag {
        min-height: 60px;
        height: unset;
        padding: 0 10px;
        align-items: start;

        ::v-deep {
          .saved-tags {
            width: 100%;

            .tag {
              margin: 5px;
            }
          }

          .input {
            margin: 9px 0;
            height: 20px;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 24px 28px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    p {
      text-align: center;
      color: $blueGrey800;

      &.header {
        font-family: Graphik-Medium;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0;
      }

      &.body {
        font-size: 14px;
        letter-spacing: 0;
      }
    }

    div.error-message {
      border: 1px solid $orange500;
      background: $orange-lite;
      transition: all 0.3s ease;
      width: 100%;
      color: #42516b;
      border-radius: 5px;
      padding: 12px 20px;
      text-align: left;
      margin-bottom: 10px;
    }

    .tick-container {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $green500;
      display: flex;
      justify-content: center;
      align-items: center;

      g {
        fill: white;
      }
    }
  }

  &__type,
  &__action,
  &__get-started {
    width: 50%;
  }

  &__type,
  &__action,
  &__get-started {
    padding: 25px 35px;
  }

  &__type {
    background-color: $skyBlueGrey300;
    height: 100%;
  }

  &__button {
    width: 100%;
    margin-top: 42px;
  }

  &__checkbox {
    margin-top: 15px;
  }

  &__footer-wrapper {
    display: flex;
    height: 50px;
    box-shadow: 0 3px 10px;
    padding: 50px;
    align-items: center;
    justify-content: flex-end;
  }

  &__eventbrite {
    text-decoration: underline;
    margin-top: 15px;
  }

  div.ticketing-provider {
    display: flex;
    align-items: center;

    > div {
      margin-right: 5px;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .upload-area {
    &:hover {
      .icon-container {
        border: 2px solid white;
      }
    }

    .icon-container {
      background: $skyBlueGrey400;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
