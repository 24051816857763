<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="ask-try-booking-credentials-modal"
        header="Connect to TryBooking"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'ask-try-booking-credentials-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
  
          <ar-field
            label="API key"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="apiKey"
              name="apiKey"
              placeholder="Please enter your TryBooking api-key"
              id="try-booking-api-key-input"
              type="text"
              data-test-id="try-booking-api-key-input"
            />
          </ar-field>

          <ar-field
            label="Secret"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="secret"
              name="secret"
              placeholder="Please enter your TryBooking api secret"
              id="try-booking-secret-input"
              type="text"
              data-test-id="try-booking-secret-input"
            />
          </ar-field>

          <div
            class="u-display-flex u-margin-top-5"
          >
            <ar-text
              size="xs"
              text="Subscribe associated contacts to list (optional)"
              weight="bold"
              :style="{
                color: $arStyle.color.blueGrey800,
              }"
            />
            <ar-icon
              v-tooltip.top="{
                content: `Automatically subscribe all attendees of events from this account.`,
              }"
              name="tooltip-question-mark"
              class="u-margin-left-2"
              height="15px"
              width="15px"
              :color="$arStyle.color.purple500"
            />
          </div>
          <ar-field
            :error-messages="[
              veeErrors.first('messageList')
            ]"
            :style="{
              marginTop: '15px',
            }"
          >
            <am2-message-list-select
              message-list-type="manual"
              :show-stats="false"
              v-model="messageList"
              :channels="['email']"
            />
          </ar-field>


          <div 
            class="u-margin-top-5 click-to-copy-webhook-url"
            @click="clickCopyWebhookUrl"
          >
            <ar-text
              :text="`<span style='text-decoration:underline;'>Click here</span> to copy webhook URL. Paste in Try Booking Dashboard > Integration Tools > Notify URL and click save`"
              multiple-lines
              allow-html
              size="xxs"
              :has-underline="true"
              :style="{
                color: $arStyle.color.blueGrey700
              }"
            />
          </div>
        </div>
        <div
          slot="footer"
          :class="[
            'ask-try-booking-credentials-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="try-booking-confirm-button"
            data-test-id="try-booking-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import { copyToClipboard } from '@/utils/html-element';
  
  export default {
    name: 'AskInputModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        apiKey: null,
        secret: null,
        messageList: null
      };
    },
  
    computed: {
      confirmButtonDisabled() {
       
        return !this.apiKey || !this.secret;
      },
      ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    },
  
    watch: {
      isShow(val) {
        if (val) {
          this.apiKey = null;
          this.secret = null;
          this.messageList = null;
        }
      },
    },
  
    methods: {
      handleConfirmClick() {
        let params = {
            apiKey: this.apiKey,
            secret: this.secret,
            messageList: this.messageList
        }
  
        this.$emit('confirm', params);
      },
      handleCloseClick() {
        this.$emit('cancel');
      },
      toggleApplyPrefix() {
        this.applyPrefix = !this.applyPrefix;
      },
      clickCopyWebhookUrl() {
        copyToClipboard(`https://arep.co/api/v1/cn/try-booking-webhook?promoter-oid=${this.promoterOid}&sessionid=[sessionid]&customid=[cid]&amount=[transaction-amount]&tickets=[ticket-count]&bookingid=[booking-id]&status=[status]`);
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .ask-try-booking-credentials-modal {
    .ask-try-booking-credentials-modal-body {
      padding: 20px 30px 30px;

      .click-to-copy-webhook-url{
        cursor: pointer;
      }
    }
    .ask-try-booking-credentials-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>
  