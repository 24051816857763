<template>
  <section
      :class="[
        'wrapper',
        'content',
        'content--centered',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]">
    <am2-step-link
        class="step-back-link"
        text="Back to integrations"
        @click="handleBackLinkClick"/>
    <am2-opt-in-settings-modal
        :is-show="showOptinSettingsModal"
        @cancel="showOptinSettingsModal = false"
        providerName="Try Booking"
        :message-list-oid="integrationMessageListOid"
    />
    <am2-ask-try-booking-credentials-modal
        :is-show="showTryBookingCredentialsModal"
        :loading="isCreatingTryBookingIntegration"
        @confirm="handleTryBookingCredentialsConfirm"
        @cancel="showTryBookingCredentialsModal = false"
    />

    <div
        :class="[
          'title-section',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        ]">
      <div class="title-section-left">
        <am2-icon-title-section
            title="Try Booking"
            description="Sync your orders, events and attendees from Trybooking"
            :icon-props="{
              name: 'try-booking',
              color: $arStyle.color.tryBooking,
              height: '46px',
              width: '40px',
            }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingTryBookingIntegration && integrationsSummary">
        <am2-expand-button-dropdown
            align="left"
            :button-props="{ iconName: 'settings' }"
            :items="expandButtonItems"
            @select="handleSettingOptionSelect"/>
      </div>
    </div>

    <am2-tabs
        :items="[
          { title: 'Notifications' },
          { title: 'Status' }
        ]"
        :selected-tab-index="selectedTabIndex"
        class="tab-options"
        @select="(selected, idx) => selectedTabIndex = idx"
    />
    <div
        v-if="selectedTabIndex === 0"
    >
      <NotificationsContainer
          :integration="currentIntegration"
          :loading="isFetchingTryBookingIntegration"
      />
    </div>
    <div
        v-if="selectedTabIndex === 1"
        class="integration-status-container"
    >

      <section v-if="isAddingTryBookingIntegration || isFetchingTryBookingIntegration"
               class="integration-loading-container">
        <am2-loading-bubble/>
      </section>
      <section v-else>
        <section v-if="integrationFailed" class="integration-failed-message">
          <ar-snackbar
              type="warning"
              message="Your Try Booking connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
              @anchorClick="handleReconnectIntegration"
              :style="{ width: '100%' }"
          />
        </section>

        <SyncBlockContainer
            v-else
            class="sync-block-container"
            :is-fetching-sync-task="isFetchingSyncTask"
            :sync-status="syncStatus"
            :sync-time="syncTime"
            :sync-copies="{
            'in-progress': 'Syncing data from Try Booking',
            'pending': 'The data sync is still pending',
            'completed': 'Your Try Booking account is currently connected',
            'cancelled': 'Your Try Booking account connection is cancelled',
            'failed': this.syncErrorMessage,
            'default': 'Syncing tasks not found',
          }"
            @startSync="handleStartSync"
            @stopSync="handleStopSync"
        />
      </section>

    </div>

  </section>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';
import NotificationsContainer from '../components/notifications-container';

export default {
  name: 'TryBookingIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
    NotificationsContainer
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    showTryBookingCredentialsModal: false,
    showOptinSettingsModal: false,
    selectedTabIndex: 1
  }),

  computed: {
    ...mapState({
      tryBookingIntegration: state => state.tryBookingIntegration.integration,
      isFetchingTryBookingIntegration: state => state.tryBookingIntegration.isFetchingIntegration,
      isAddingTryBookingIntegration: state => state.tryBookingIntegration.isAddingIntegration,
      isCreatingTryBookingIntegration: state => state.tryBookingIntegration.isCreatingIntegration,
      isDeletingTryBookingIntegration: state => state.tryBookingIntegration.isDeletingIntegration,
      isFetchingSyncTask: state => state.tryBookingIntegration.isFetchingSyncTask,
      syncTask: state => state.tryBookingIntegration.syncTask,
      syncStatus: state => state.tryBookingIntegration.syncStatus,
    }),

    tryBookingIntegrationEnabled() {
      return true;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.tryBookingIntegration) return null;
      const summary = this.tryBookingIntegration
          .map(i => ({
            oid: i.oid,
            name: i.integration?.account?.name,
            email: i.integration?.account?.name
          }));

      return summary
    },

    currentIntegration() {
      if (!this.tryBookingIntegration) return null;
      return this.tryBookingIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationMessageListOid() {
      return this.currentIntegration ? this.currentIntegration?.meta?.messageListOid : null;
    },

    expandButtonItems() {
      let i = [];
      i.push({
        name: 'Try Booking Settings',
        value: 'openSettingsModal'
      });
      i.push({
        name: 'Remove Account',
        value: 'removeIntegration'
      })
      return i;
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },
  },

  async mounted() {
    if (!this.tryBookingIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['tryBookingIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'tryBookingIntegration/FETCH_INTEGRATION',
      'tryBookingIntegration/DELETE_INTEGRATION',
      'tryBookingIntegration/SYNC_START',
      'tryBookingIntegration/SYNC_STOP',
      'tryBookingIntegration/FETCH_SYNC_TASK',
      'tryBookingIntegration/CREATE_TRY_BOOKING_INTEGRATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'tryBookingIntegration/RESET_INTEGRATION',
    ]),
    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },
    async fetchIntegration() {
      await this['tryBookingIntegration/FETCH_INTEGRATION']();
    },

    async fetchTasks() {
      await this['tryBookingIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    async handleTryBookingCredentialsConfirm({apiKey, secret, messageList}) {
      const succeed = await this['tryBookingIntegration/CREATE_TRY_BOOKING_INTEGRATION']({
        apiKey,
        secret,
        messageList
      });
      if (succeed) {
        this.fetchIntegration();

        this.showTryBookingCredentialsModal = false;
      }
    },


    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({path: '/settings/integrations'});
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;

      this.updateCurrentMessageListOid();
      this.fetchTasks();
    },

    updateCurrentMessageListOid() {
      if (this.currentlySelectedIntegrationIndex === null || !this.integrationsSummary) {
        return null;
      }

      this.currentMessageListOid = !!this.integrationsSummary[this.currentlySelectedIntegrationIndex] ? this.integrationsSummary[this.currentlySelectedIntegrationIndex].messageListOid : null
    },

    async handleAddNewIntegration() {
      this.showTryBookingCredentialsModal = true;
    },

    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['tryBookingIntegration/SYNC_START']({oid: this.currentIntegration.oid});
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    handleStopSync() {
      this['tryBookingIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleReconnectIntegration() {
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Make sure you are logging in with the Try Booking account <strong>${this.currentIntegration.integration.account.name}</strong> on Try Booking before reconnecting it.`,
        confirmButtonText: 'Reconnect account',
      });
      if (!agreed) {
        return;
      }

      const succeed = await this['tryBookingIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
      if (!succeed) {
        return;
      }

      this.handleAddNewIntegration();
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the TryBooking integration will stop syncing your data from TryBooking.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['tryBookingIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
            this.$router.push({path: '/settings/integrations'})
          } catch (e) {
            this.$arNotification.push({type: 'error', message: 'Failed to remove integration'});
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value == 'openSettingsModal') {
        if (!this.currentIntegration) return;
        this.showOptinSettingsModal = true;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  .tab-options {
    margin: 60px 0 20px 0;
    border-bottom: 1px $skyBlueGrey500 solid;
  }

  &.sm-max {
    padding: 0 24px;
  }

  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top: 16px;
      }
    }
  }


  .sync-block-container {
    margin-top: 0px;
  }
}
</style>
