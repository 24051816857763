<template>
  <div class="automation-header-wrapper">
    <div class="automation-header-container">
      <nuxt-link to="/automation" class="planner-back-nuxt-link-wrapper" data-test-id="planner-back-button">
        <ar-link-button
          class="planner-back-button"
          text="Back"
          :has-underline="true"
          :color="$arStyle.color.$purple500"          
        />
      </nuxt-link>      
      <div class="header-inner">
        <div class="automation-name-stats-wrapper">
          <AutomationName :name="name" @update="handleNameUpdate" />
          <div class="automation-info-wrapper">
            <!-- 
            Temporarily commenting out.
            Will be part of version 2 and
            hooked up to live stats from
            the backend

            <AutomationUsers 
              :users="automationUsers"
            />
            <AutomationStatsPreview
              :stats="statsPreview"
            />
            <EyeIcon class="eye-icon" />
            -->
          </div>
        </div>

        <div class="header-options-wrapper">
          <div v-if="isPendingStatus" class="loading-bubble-wrapper">
            <am2-loading-bubble />
          </div>
          <template v-else>
            <am2-signal-tag
              :data-test-id="`automation-${signalText}`"
              v-if="signalType"
              class="signal-tag"
              :text="signalText"
              :type="signalType"
              :hide-dot="shouldHideDot"
            />
          </template>
        </div>
      </div>
      <ar-divider
        class="automation-listing-divider"
        :style="{
          marginTop: '20px',
          marginBottom: 0,
          width: '100%',
        }"
      />
    </div>
  </div>
</template>
<script>
import AutomationName from './AutomationName'
import AutomationUsers from './AutomationUsers'
import AutomationStatsPreview from './AutomationStatsPreview'
import EyeIcon from '~/assets/icons/eye-icon.svg?inline'
import { mapState, mapActions, mapGetters } from 'vuex'
import { sanitizeHtmlChild } from '@/utils/html-element/'

export default {
  name: 'AutomationHeader',
  components: {
    AutomationName,
    AutomationUsers,
    AutomationStatsPreview,
    EyeIcon,
  },
  props: {
    isPendingStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      settingsItems: [
        {
          name: 'Start',
          action: this.startAutomation,
          term: 'starting',
          disabled: false,
        },
        {
          name: 'Pause',
          action: this.pauseAutomation,
          term: 'pausing',
          disabled: false,
        },
        {
          name: 'Stop',
          action: this.stopAutomation,
          term: 'stopping',
          disabled: false,
        },
        { type: 'divider', disabled: true },
        {
          name: 'Delete',
          action: this.deleteAutomationModal,
          term: 'deleted',
          disabled: false,
          disabled: false,
        }
      ]
    }
  },
  watch: {
    status(newVal, oldVal) {
      this.adjustDropdownOptions()
      if (!!oldVal && newVal === 'error') {
        this.$arNotification.push({ type: 'error', message: `An error occured when updating the status of Automation '${this.name}'.` })
      }
    }
  },
  computed: {
    ...mapState({
      automationId: state => state.automation.id,
      name: state => state.automation.name,
      status: state => state.automation.status
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    signalText() {
      let text
      switch (this.status) {
        case 'started':
          text = 'Active'
          break
        case 'paused':
          text = 'Paused'
          break
        case 'stopped':
          text = 'Stopped'
          break
        case 'error':
          text = 'Error'
          break
      }
      return text
    },
    signalType() {
      let type
      switch (this.status) {
        case 'started':
          type = 'green'
          break
        case 'paused':
          type = 'orange'
          break
        case 'stopped':
          type = 'grey'
          break
        case 'error':
          type = 'red'
          break
      }
      return type
    },
    shouldHideDot() {
      return this.status !== 'started'
    },
    statsPreview() {
      return {
        completions: 130,
        inProgress: 12,
      }
    },
    automationUsers() {
      return [
        { src: "https://images.pexels.com/photos/3383195/pexels-photo-3383195.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/2833348/pexels-photo-2833348.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
        { src: "https://images.pexels.com/photos/6389877/pexels-photo-6389877.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
      ]
    },
  },
  methods: {
    ...mapActions({
      startAutomation: 'automation/START',
      pauseAutomation: 'automation/PAUSE',
      stopAutomation: 'automation/STOP',
      deleteAutomation: 'automation/DELETE',
      newAutomation: 'automation/NEW_AUTOMATION',
      renameAutomation: 'automation/RENAME_AUTOMATION',
      showConfirm: 'SHOW_CONFIRM',
    }),

    deleteAutomationAction() {
      this.deleteAutomation({id: this.automationId})
    },

    async deleteAutomationModal() {
      return await this.showConfirm({
        title: 'Delete this automation?',
        messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(this.name)}</b>, are you sure you want to proceed? Campaigns, lists, or tags referenced in this automation will not be deleted.`,
        confirmButtonText: 'Delete',
        validation: {
          question: 'Type DELETE to confirm',
          answer: 'DELETE',
        },
        asyncCompleteFunction: this.deleteAutomationAction,
      });
    },
   
    async handleNameUpdate(val) {
      if (this.automationId) {
        this.renameAutomation({id: this.automationId, name: val})
      } else {
        let { oid } = await this.newAutomation(val)
        let query = this.$route.query
        this.$router.push({path: this.$route.path, query: {...query, oid}})
      }
    },
    handleBack() {
      this.$router.push('/automation')
    },
    restrictMenuActions() {
      this.settingsItems = this.settingsItems.map(item => {
        if (item.name === 'Delete') return { ...item }
        
        return { ...item, disabled: true }
      })
    },
    adjustDropdownOptions() {
      if (!this.status) return
      if (this.status === 'error') {
        this.resetOptions()        
        return
      }

      let correspondingMenuOption
      switch (true) {
        case this.status.includes('stop'):
          correspondingMenuOption = 'Stop'
          break
        case this.status.includes('start'):
          correspondingMenuOption = 'Start'
          break
        case this.status.includes('paus'):
          correspondingMenuOption = 'Pause'
          break
      }

      this.settingsItems.find(option => option.name === correspondingMenuOption).disabled = true
    },
    resetOptions() {
      let i = 0
      let length = this.settingsItems.length
      while (i < length) {
        if (!this.settingsItems[i]?.type) {
          this.settingsItems[i].disabled = false
        }
        i++
      }
    }
  },
  mounted() {
    if (!this.isFeatureEnabled(['automation'])) {
      this.restrictMenuActions()
    }
  }
}
</script>
<style lang="scss" scoped>
.automation-header-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  .automation-header-container {
    width: 100%;
    margin-left: 53px;
    margin-right: 53px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;

    .planner-back-nuxt-link-wrapper {
      align-self: flex-start;
      margin-bottom: 5px;

      .planner-back-button {
        pointer-events: none;
      }
    }


    .header-inner {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      .automation-name-stats-wrapper {
        width: 70%;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        .automation-info-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          margin-top: 5px;

          .eye-icon {
            margin-left: 8px;
            margin-top: 4px;
          }
        }
      }

      .header-options-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .loading-bubble-wrapper {
          margin-right: 65px;
        }

        .settings-dropdown {

          >>> .icon-button-wrapper {
            width: 50px;
            height: 50px;
            border-radius: 3px;
            background: $skyBlueGrey400;
            margin-right: -8px;
          }
        }
      }
    }
  }
}
</style>