<template>
  <am2-card-container layout="regular">
    <div class="overview-top-links-container">
      <div class="overview-top-links-header">
        <ar-text
          text="Top links"
          size="sm"
        />
        <div class="report-link" @click="handleReportClick">
          <ar-text
            text="View report"
            size="xs"
            :style="{
              color: $arStyle.color.purple500,
            }"
            data-test-id="overview-top-links-report-link"
          />
        </div>
      </div>
      <div class="overview-top-links-body">
        <TopLinksTable 
          class="link-table"
          :rows="clicksData"
          reduced
          data-test-id="overview-top-links-table"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import TopLinksTable from './top-links-table.vue'
import { extractClicksStats } from '../utils/clickStats'

export default {
  name: 'OverviewTopLinks',
  components: { TopLinksTable },

  props: {
    oid: {
      type: Number,
      default: null,
    },
    currentSelectedMessage: {
      type: Object,
      default: () => ({}),
    },
    shortUrls: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      head: []
    }
  },

  computed: {
    clicksData() {
      return extractClicksStats(this.currentSelectedMessage?.statusDetails?.clicksSnapshot, this.shortUrls).slice(0, 5);
    }
  },

  methods: {
    handleReportClick() {
      this.$router.push({
        path: 'link-activity'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.overview-top-links {
  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    padding-bottom: 1.2em;
    border-bottom: 1px solid $skyBlueGrey500;
  }

  .report-link {
    color: $purple500;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>