var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"import-html-email-modal",attrs:{"is-open":_vm.opened,"header":"Upload your HTML","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs'),"data-test-id":"upload-html-email-modal"},on:{"back":_vm.handleModalBack,"close":_vm.handleModalClose}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"body"},[_c('ar-text',{attrs:{"text":"Your email must contain an unsubscribe link, otherwise we will add one","size":"xs","align":"center"}}),_vm._v(" "),_c('am2-dropzone',{staticClass:"u-margin-top-5",attrs:{"file-type":['text/html'],"file-size":100000000,"file-type-alias":"HTML file","force-error":_vm.failedFileType,"placeholder-icon":{
            name: 'upload',
            width: '16px',
            height: '16px',
            color: _vm.$arStyle.color.purple500,
            wrapperStyle: {
              background: _vm.mouseOverHTMLDropdown ? "white" : _vm.$arStyle.color.skyBlueGrey400,
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: _vm.mouseOverHTMLDropdown ? '2px solid white' : null,
            },
          },"ratio":0.525,"data-test-id":"import-html-email-dropzone"},on:{"upload":_vm.handleHTMLUploaded},nativeOn:{"mouseover":function($event){_vm.mouseOverHTMLDropdown = true},"mouseleave":function($event){_vm.mouseOverHTMLDropdown = false}}}),_vm._v(" "),(_vm.failedFileType)?_c('p',{staticClass:"import-html-email-modal__red-text"},[_vm._v("\n          Incorrect file type. Please check the file type and try again.\n        ")]):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }