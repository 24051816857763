<template>
  <portal
    to="modal"
    :disabled="!shouldShow"
    :style="{ position: 'absolute' }"
  >

    <ar-modal
      :is-open="shouldShow"
      :header="!!integration ? 'Connecting to ' + integration.name : 'Connecting'"
      :has-cross-icon="false"
      class="see-tickets-uk-installing-modal"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
    >
      <div
        slot="footer"
        :class="[
          'see-tickets-uk-installing-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <am2-loading-bubble />
      </div>
    </ar-modal>

  </portal>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'SeeTicketsUKModal',
  components: {},
  props: {
    shouldShow: {
      type: Boolean,
      default: false,
    },
    isWaiting: {
      type: Boolean,
      default: false,
    },
    integration: {
      type: Object,
      default: null
    },
    providerAccount: {
      type: Object,
      default: null
    }
  },

  computed: {},

  watch: {
    async shouldShow(val) {
      if (val) {
        await this.handleShow();
      }
    },
  },

  methods: {
    ...mapActions([
      'workatoIntegration/CREATE_PROVIDER_ACCOUNT'
    ]),

    async handleShow() {
      await this['workatoIntegration/CREATE_PROVIDER_ACCOUNT']({
        integration: this.integration,
        account: { accountName: 'Default Account' }
      });
      this.$emit('complete');
    },
  },
};
</script>

<style lang="scss" scoped>
.see-tickets-uk-installing-modal {
  .see-tickets-uk-installing-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
