<template>
    <section class="customer-profile-events"
         v-infinite-scroll="loadMoreEvents"
         infinite-scroll-disabled="infiniteScrollDisabled"
         :infinite-scroll-distance="10">
      <div :class="[
        'events-controls',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
      ]">
        <am2-heading
          type="h1"
          size="md"
          class="section-heading"
          title="Events"
        />
        <div :class="[
          'events-controls__rhs',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
        ]">
          <am2-search
            v-model="searchQuery"
            placeholder="Search events"
            class="search-input u-margin-right-4"
            @enter="serverSideSearch"
            @clear="serverSideSearch"
          />
          <am2-button-group-tabs
            v-if="$arMediaQuery.pageContent.minWidth('md')"
            :active-index="layout === 'list' ? 1 : 0"
            :items="groupButtonsSelectItems"
            :style="{
              width: '100px',
            }"
            @select="handleGroupButtonSelectClick"
          />
        </div>
      </div>

      <div
        :class="[
          computedLayout,
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        ]"
        :style="{
          borderRadius: !isCustomerProfileFeatureEnabled ? '5px' : null,
          overflow: !isCustomerProfileFeatureEnabled ? 'hidden' : null,
        }"
        v-ar-feature-mask="{
          show: !isCustomerProfileFeatureEnabled,
          showUpgradeOn: 'hover',
          iconName: 'audience',
          title: lockedTitle.events,
          message: 'Unlock customer profiles to see previous event attendance for each fan.',
        }"
      >
        <div class="events-container">
          <div
            :style="{
              filter: isCustomerProfileFeatureEnabled ? null : 'blur(5px)',
              padding: isCustomerProfileFeatureEnabled ? 0 : '20px 20px 6px 20px',
            }"
            class="customer-event-card-wrapper"
            :class="[
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            ]"
          >
            <am2-event-card
              v-for="event in computedEvents"
              class="customer-event-card"
              :key="event.oid"
              :event="event"
              :show-as="computedLayout"
              layout="customer"
              :integration-is-possible="false"
              :stats-tooltip="`${ customerState.customer ? customerState.customer.name : 'Customer' } has purchased ${ event.ticketStats.totalTicketsSoldFormatted } ${ getEventTotalTicketSold(event) > 1 ? 'tickets' : 'ticket'} for ${ event.ticketStats.totalTicketSalesFormatted }`"
              :on-ticket-sales-delete="() => {}"
            />
            <div
              v-if="customer && !isFetchingEvents && computedEvents.length < 1"
              class="ar-transition-group-item u-width-100-percent"
            >
              <am2-no-content-section
                v-if="searchQuery"
                header="No events found matching your criteria"
                header-size="sm"
              />
              <am2-no-content-section
                v-else
                :header="`${customerState.customer ? customerState.customer.name : 'Customer'} has not attended any events`"
                header-size="sm"
              />
            </div>
            <am2-card-skeleton
              v-for="n in isFetchingEvents ? 3 : 0"
              :key="n"
              class="base-plan-card"
              type="campaign"
              :show-as="$arMediaQuery.pageContent.minWidth('sm') ? layout : 'tiles'"
              :level="$arMediaQuery.pageContent.maxWidth('xs') || layout === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
              :style="{
                height: $arMediaQuery.pageContent.maxWidth('xs') || layout === 'tiles' ? '370px' : '163px',
                marginBottom: '10px',
              }"
            />
          </div>
        </div>
      </div>

    </section>
</template>

<script>

  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import accounting from 'accounting'
  import { dummyFanEvents } from '@/utils/audience';

  export default {
    name: 'CustomerProfileEvents',

    data() {
      return {
        searchQuery: null,
        layout: 'list',
        groupButtonsSelectItems: [
          {
            iconName: 'tile-view',
            showAs: 'tiles',
          },
          {
            iconName: 'list-view',
            showAs: 'list',
          },
        ],
      }
    },

    watch: {
      customer: {
        handler(val, oldVal) {
          if (val && val.oid && !oldVal && !this.isFetchingEvents) {
            this.fetchEvents();
          }
        },
        immediate: true,
      }
    },

    computed: {
      ...mapGetters({
        isFeatureEnabled: 'auth/isFeatureEnabled',
        getEventTotalTicketSold: 'event/getEventTotalTicketSold',
        getEventAttendees: 'event/getEventAttendees',
      }),
      ...mapState({
        customerState: state => state.customer,
        customer: state => state.customer.customer,
        isFetchingEvents: state => state.customer.isFetchingEvents,
      }),

      lockedTitle() {
        return {
          "events": this.customerState?.customer?.firstName ? `What events has ${this.customerState?.customer?.name} attended?` : `Event Attendance`,
        }
      },

      computedLayout() {
        if (this.computedEvents.length === 0) return 'list';
        if (this.$arMediaQuery.pageContent.maxWidth('sm')) return 'tiles';
        return this.layout;
      },

      isCustomerProfileFeatureEnabled() {
        return this.isFeatureEnabled(['customer-profiles']);
      },

      infiniteScrollDisabled() {
        return this.customerState.customer && ((this.customerState.actionedEventOids && this.customerState.customer.events && this.customerState.actionedEventOids.length >= this.customerState.customer.events.length) || this.isFetchingEvents || this.customerState.hasFetchCustomerEventsFailed);
      },

      computedEvents() {
        if (!this.isCustomerProfileFeatureEnabled) return dummyFanEvents();
        return this.customerState.fanEvents.map( event => {
          const overrideSales = this.customerState?.customer?.ticketSalesPerEvent && this.customerState?.customer?.ticketSalesPerEvent[event.oid] ? this.customerState.customer.ticketSalesPerEvent[event.oid] : 0;
          const overrideQuantity = this.customerState?.customer?.ticketsPerEvent && this.customerState?.customer?.ticketsPerEvent[event.oid] ? this.customerState.customer.ticketsPerEvent[event.oid] : 0;
          return {
            ...event,
            // Let's override "ticketStats"
            ticketStats: [{
              attendees: overrideQuantity,
              oid: this.getEventAttendees(this.event),
              totalTicketSales: overrideSales,
              totalTicketsSold: overrideQuantity,
              totalTicketSalesFormatted: accounting.formatMoney(overrideSales / 100, '$'),
              totalTicketsSoldFormatted: accounting.formatNumber(overrideQuantity),
            }],
          }
        });
      },
    },

    methods: {
      ...mapActions([
        'customer/FETCH_CUSTOMER_EVENTS',
      ]),
      ...mapMutations([
        'customer/RESET_CUSTOMER_EVENTS'
      ]),

      async serverSideSearch() {
        this['customer/RESET_CUSTOMER_EVENTS']();
        await this.fetchEvents();
      },

      handleGroupButtonSelectClick(item) {
        this.layout = item.showAs;
      },

      async loadMoreEvents() {
        await this.fetchEvents(true);
      },

      async fetchEvents(append = false) {
        const searchString = this.searchQuery || null;
        this['customer/FETCH_CUSTOMER_EVENTS']({searchString, append})
      },
    },

    async mounted() {
      this['customer/RESET_CUSTOMER_EVENTS']();
    },

    beforeDestroy() {
      this['customer/RESET_CUSTOMER_EVENTS']();
    },

  };
</script>

<style lang="scss" scoped>
  .customer-profile-events {
    padding: 30px 30px 100px;

    .events-controls {
      margin: 16px 0 22px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;

      &.sm-max {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
      }

      &__rhs {
        display: flex;
        flex-direction: row;

        .search-input {
          width: 270px;
        }

        &.sm-max {
          margin-top: 16px;
          width: 100%;
          .search-input {
            width: 100%;
          }
        }

        &.xs-max {
          .search-input {
            margin-right: 0;
          }
        }
      }
    }

    .tiles {
      width: 100%;
      margin-top: 40px;

      .events-container{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        .customer-event-card-wrapper {
          display: grid;
          grid-template-columns: 360px 360px 360px;
          grid-gap: 30px;

          @media screen and (max-width: 1350px) {
            grid-template-columns: 360px 360px;
          }

          &.md-max {
            grid-gap: 20px 10px;
          }

          &.sm-max {
            grid-template-columns: 360px;
          }

          .event-card {
            width: 360px;
            min-width: 360px;
          }
        }
      }
    }

    .list {
      .customer-event-card-wrapper {
        width: 100%;

        .event-card {
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }

  }
</style>
