<template>
    <am2-card-container class="notifications-container">
        <div v-infinite-scroll="fetchMoreNotifications" infinite-scroll-disabled="notificationsInfiniteScrollDisabled"
            :infinite-scroll-distance="notificationsPageSize">
            <am2-notifications-table :head="notificationTableHeadings" :body="!!notifications ? notifications : []"
                :loading="loading" empty-text="No notifications" @sortBy="handleSortChange" :default-sort-by="orderBy"
                has-sticky-header tableType="notifications" />
        </div>
    </am2-card-container>
</template>
  
<script>

const notificationsPageSize = 10;

const notificationTableHeadings = [
    {
        name: 'Type',
        key: 'notificationType',
        format: 'notificationType',
        attributeKeys: 'notificationType',
    },
    {
        name: 'Message',
        key: 'message',
        format: 'message',
        attributeKeys: 'message',
    },
    {
        name: 'Created At',
        key: 'sysCtime',
        sortKey: 'sysCtime',
        format: 'sysCtime',
        attributeKeys: 'sysCtime',
    },
];

export default {
    name: 'NotificationsContainer',

    props: {
        integration: {
            type: Object,
            default: {}
        },
        loading: {
            type: Boolean,
            default: false,
        },
        promoterOid: {
            type: Number
        }
    },
    data: () => ({
        notificationsPageSize,
        notificationTableHeadings,
        selectedTabIndex: 1,
        notifications: [],
        notificationsInfiniteScrollDisabled: false,
        orderBy: { key: 'sysCtime', order: 'desc' },
    }),
    computed: {

    },
    watch: {
        async integration(val, oldVal) {
            if (this.val !== null && val !== oldVal) {
                this.notificationsInfiniteScrollDisabled = false;
                this.notifications = [];
                await this.fetchNotifications();
            }
        },
    },

    async mounted() {
        this.notificationsInfiniteScrollDisabled = false;
        this.notifications = [];
        await this.fetchNotifications();
    },
    methods: {
        async handleSortChange(notificationObj) {
            this.orderBy = {
                key: notificationObj.key,
                order: notificationObj.order
            };
            this.notificationsInfiniteScrollDisabled = false
            this.notifications = [];
            await this.fetchNotifications();
        },
        async fetchNotifications(append = false) {
            if (this.integration.oid !== null && this.notificationsInfiniteScrollDisabled === false) {
                const offset = append ? this.notifications.length : 0;
                const notifications = await this.$api.notifications.fetchNotifications(
                    this.integration.promoterOid,
                    this.integration.oid,
                    this.notificationsPageSize,
                    offset,
                    this.orderBy);
                if (notifications.length < this.notificationsPageSize) {
                    this.notificationsInfiniteScrollDisabled = false;
                }
                this.notifications = append ? Array.from(new Set(this.notifications.concat(notifications))) : notifications;
            }
        },
        async fetchMoreNotifications() {
            if (this.integration && this.notifications.length > 0) {
                await this.fetchNotifications(true);
            }
        }
    }
};
</script>
  
<style lang="scss" scoped>
.notifications-loading-animation {
    color: $green500;
    margin-bottom: 63px;
}
.notifications-container {
    margin-bottom: 20px;
}
</style>
  