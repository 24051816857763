var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-test-id":"company-page-messages"}},[(_vm.isFetchingPromoterMessageTasks)?_c('div',{staticClass:"loading-container"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):(_vm.noMessages)?_c('div',{style:({
      marginTop: '16px',
    })},[_c('am2-no-content-section',{attrs:{"icon-name":"ar-message-in-circle","header":"No messages","body":"No upcoming/active/recently-failed messages have been sent by this promoter"}})],1):_c('div',[(_vm.inProgress.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Sending","weight":"bold"}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
          marginTop: '16px',
        }),attrs:{"head":_vm.getTableHeadings('inProgress'),"messages":_vm.inProgress,"loading":_vm.isFetchingPromoterMessageTasks && _vm.inProgress.length === 0}})],1):_vm._e(),_vm._v(" "),(_vm.scheduled.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Scheduled","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":("(" + _vm.scheduledCount + " message" + (_vm.scheduledCount > 1 ? 's' : '') + ")")}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
          marginTop: '16px',
        }),attrs:{"head":_vm.getTableHeadings('scheduled'),"messages":_vm.scheduled,"loading":_vm.isFetchingPromoterMessageTasks && _vm.scheduled.length === 0}})],1):_vm._e(),_vm._v(" "),(_vm.sent.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Recently sent","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"size":"xs","text":("(" + _vm.sentCount + " message" + (_vm.sentCount > 1 ? 's' : '') + ")")}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
          marginTop: '16px',
        }),attrs:{"head":_vm.getTableHeadings('sent'),"messages":_vm.sent,"loading":_vm.isFetchingPromoterMessageTasks && _vm.sent.length === 0}})],1):_vm._e(),_vm._v(" "),(_vm.failed.length > 0)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('ar-text',{attrs:{"size":"xs","text":"Recently failed","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"header-link",attrs:{"text":("(" + _vm.failedCount + " message" + (_vm.failedCount > 1 ? 's' : '') + ")"),"size":"xs"},nativeOn:{"click":function($event){return _vm.setMessageFilter('completed')}}})],1),_vm._v(" "),_c('am2-messages-table',{style:({
          marginTop: '16px',
        }),attrs:{"head":_vm.getTableHeadings('failed'),"messages":_vm.failed,"loading":_vm.isFetchingPromoterMessageTasks && _vm.failed.length === 0}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }