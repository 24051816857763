<template>
  <div
    :class="[
      'table-outer-wrapper',
      hasControlSection && 'top-edge-straight',
      !hideBorders && 'table-outer-wrapper-with-borders',
    ]"
    ref="table-outer-wrapper"
    @mouseleave="handleTableMouseLeave"
  >
    <!-- Right shadow -->
    <div
      ref="right-table-shadow"
      :class="['table-shadow', 'right']"
      :style="{
        boxShadow: hasRightTableShadow ? `inset -15px 0 14px -16px ${this.$arStyle.color.blueGrey700}` : null,
      }"
    />
    <!-- Left shadow -->
    <div
      ref="left-table-shadow"
      :class="['table-shadow', 'left']"
      :style="{
        left: showTicks ? tickColumnWidth + firstColumnWidth + 'px' : 0,
        borderTopLeftRadius: showTicks ? null : '5px',
        boxShadow: hasLeftTableShadow ? `inset 15px 0 14px -16px ${this.$arStyle.color.blueGrey700}` : null,
      }"
    />
    <!-- No Content -->
    <div
      v-if="isNoRows"
      class="no-content"
    >
      <ar-text
        size="xs"
        :text="emptyText"
        align="center"
        :style="{
          color: $arStyle.color.blueGrey600,
        }"
      />
    </div>

    <!-- TABLE BODY CHECKBOXES -->
    <div
      v-if="showTicks"
      class="table-body-container checkboxes-container"
      :style="{
            width: tickColumnWidth,
            position: 'absolute',
            left: '0',
            top: '52px',
            overflow: 'visible',
            zIndex: $arStyle.zIndex.regular,
          }">
      <div :class="['tbody', locked && 'locked']">
        <div
          class="tr-container"
          :style="{
                transform: `translateY(${computedTop}px)`
              }"
        >
          <div
            v-for="(row, rowIndex) of visibleRows"
            :key="row[rowKeyName]"
            class="tr-wrapper"
            :data-test-id="`row-floated-${row[rowKeyName]}`"
          >
            <div
              :class="['tr', row.class]"
              :style="{
                    cursor: enableRowClick ? 'pointer' : null,
                    background: checkRowActivity(row, rowIndex) ? $arStyle.color.purple100 : '#fff',
                    display: 'flex',
                  }"
              @mouseenter="handleRowMouseEnter(rowIndex)"
            >
              <div
                class="td"
                :style="{
                      minHeight: `${rowHeight}px`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: `${tickColumnWidth}px`,
                      cursor: 'pointer',
                      zIndex: $arStyle.zIndex.regular,
                    }"
                @click.stop="handleRowTick(row)"
              >
                <ar-checkbox
                  ref="checkbox"
                  :style="{ pointerEvents: 'none' }"
                  :value="tickedRowsMap[row[rowKeyName]]"
                />
              </div>
              <div
                class="td"
                :style="{ width: firstColumnWidth + 'px' }"
                @click.stop="handleRowClick(row)"
              >
                <slot
                  :name="'name'"
                  :data="row"
                  :index="getRowIndex(row)"
                  :active="activeRowIndex === rowIndex"
                />
              </div>
            </div>
          </div>
          <div v-if="loading">
            <div class="tr-wrapper" v-for="n in 12" :key="n">
              <div class="tr tr--skeleton-white">
                <div
                  v-if="showTicks"
                  class="td"
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: `${tickColumnWidth}px`,
                    minHeight: `${rowHeight}px`,
                    marginLeft: '1px',
                  }"
                >
                  <am2-typography-skeleton
                    size="md"
                    :level="n"
                    :style="{ width: '20px' }"
                  />
                </div>
                <div
                  class="td"
                  :style="{
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    width: `${headWidths[0] || minColumnWidth}px`,
                    minHeight: `${rowHeight}px`,
                  }"
                >
                  <am2-typography-skeleton
                    size="md"
                    :level="n"
                    :style="{ width: `45%`, height: '12px', }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- TABLE HEADER -->
    <div
      :class="[
          'table-header-outer-container',
          'thead',
          hasControlSection && 'top-edge-straight',
          showTicks && 'ticks',
          !hideBorders && 'table-header-outer-container-with-borders'
        ]"
      v-ar-sticky-top="{
            priority: 1,
          }"
      ref="thead-real"
      @scroll="handleHeaderScroll"
    >
      <div class="tr" :style="{width: tableWidth + 'px'}">
        <!-- Table Header - Checkbox -->
        <div
          v-if="showTicks"
          class="th th--checkbox th--checkbox-header"
          data-test-id="table-header-checkbox-th"
          :style="{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              width: `${tickColumnWidth}px`,
              cursor: locked ? 'not-allowed' : 'pointer',
              position: 'sticky',
              padding: '0',
            }"
          @click="handleTickAllCheckboxClick"
        >
          <ar-checkbox
            ref="tick-all-checkbox"
            data-test-id="table-header-checkbox"
            v-show="!isNoRows"
            :style="{
                pointerEvents: 'none',
              }"
            :value="tickAllRows"
          />
        </div>

        <!-- Table Header - CONTENT  -->
        <div
          class="th"
          :class="'th--' + head.sortKey"
          v-for="(head, idx) in heads"
          :key="head.key"
          :style="{
                cursor: resizingColumn !== null ? 'col-resize' : head.sortKey ? 'pointer' : null,
                width: `${headWidths[idx] || 150}px`,
                padding: customHeadPadding ? customHeadPadding : null,
              }"
          @click="event => handleSortClick(head.sortKey, event)"
          @mouseenter="handleCellMouseEnter(head)"
          @mouseleave="handleCellMouseLeave(head)"
        >
          <div class="table-cell" :style="{justifyContent: head.align === 'left' ? 'flex-start' : 'flex-end'}">
            <ar-icon
              v-if="(showTicks || idx > 0) && sortBy.key === head.sortKey && !!head.sortKey"
              class="arrow"
              name="arrow"
              :color="$arStyle.color.blueGrey700"
              :rotate="sortBy.order === 'asc' ? 180 : 0"
              :style="{
                    marginRight: `7px`
                  }"
            />
            <ar-text
              size="xs"
              :text="head.name"
              weight="bold"
              :style="{
                    paddingRight: head.align === 'right' && head.lock ? '10px' : null,
                    height: '18px',
                    top: '2px',
                    position: 'relative',
                  }"
              v-tooltip.top="{content: head.showTooltip ? head.name : '', ...tooltipOptions}"
            />
            <ar-icon
              v-if="!showTicks && sortBy.key === head.sortKey && !!head.sortKey && idx == 0"
              class="arrow"
              name="arrow"
              :color="$arStyle.color.blueGrey700"
              :rotate="sortBy.order === 'asc' ? 180 : 0"
              :style="{
                    marginLeft: `7px`,
                    marginRight: `0px`
                  }"
            />
            <ar-icon
              class="lock"
              v-if="head.lock"
              name="lock"
              :color="$arStyle.color.blueGrey600"
              height="14px"
            />
          </div>
          <div
            v-if="enableColumnResize"
            class="table-header-edge"
            @mousedown="event => handleTableResizeMouseDown(event, idx)"
            @touchstart="event => handleTableResizeMouseDown(event, idx)"
            @dblclick="showContentColumnWidth(idx)"
          >
            <div class="table-header-edge__border" />
            <div
              class="table-header-edge__resizer"
              :class="{'table-header-edge__resizer--active': resizingColumn === idx}"
              :style="getResizeStyles(idx)"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-wrapper"
      ref="table-wrapper"
      :style="{
        height: totalHeight + 'px',
      }"
    >
    <!-- Table Container -->
    <table
      ref="table-container"
      :style="{
        borderBottom: loading ? 'none' : null,
        width: tableWidth + 'px',
        height: 'unset',
        transform: `translateY(${computedTop}px)`
      }"
      class="table-container">


      <!-- Fake Table Header (For resizing columns) -->
      <thead
        :style="{
          width: '100%',
          visibility: 'hidden',
        }"
      >
        <tr>
          <th
            v-if="showTicks"
            :style="{
                  width: `${tickColumnWidth}px`,
                }"
          >
          </th>

          <th
            v-for="(head, idx) in heads"
            :key="head.key"
            :style="{
              width: `${headWidths[idx] || 150}px`,
            }"
          >
          </th>
          <th
            v-if="hasMenuButton"
            :style="{
              width: `${menuButtonWidth}px`,
            }"
          >
          </th>
        </tr>
      </thead>


      <!-- Table Body -->
      <tbody class="tbody"
             ref="tbody"
             @mouseleave="handleRowMouseLeave()"
      >
        <tr
          class="tr"
          v-for="(row, rowIndex) of visibleRows"
          :key="row[rowKeyName]"
          :style="{
            cursor: enableRowClick ? 'pointer' : null,
            background: checkRowActivity(row, rowIndex) ? $arStyle.color.purple100 : null,
          }"
          :data-test-id="`row-${row[rowKeyName]}`"
          @mouseenter="handleRowMouseEnter(rowIndex)"
          @click.stop="handleRowClick(row)"
        >
          <td
            v-if="showTicks"
            class="td"
            :style="{ width: `${tickColumnWidth}px`, minHeight: `${rowHeight}px` }"
          />
          <td
            v-for="(head, idx) in heads"
            :class="[
              'td',
              head.lock && 'blur'
            ]"
            :key="head.key"
            @mouseenter="handleCellMouseEnter(head)"
            @mouseleave="handleCellMouseLeave(head)"
            :style="{
              width: `${headWidths[idx] || 150}px`,
            }"
          >
            <!-- Let user decide style of each column -->
            <slot
              :name="head.format"
              :data="row"
              :index="getRowIndex(row)"
              :head="head"
              :active="activeRowIndex === rowIndex"
            />
          </td>
          <td
            v-if="hasMenuButton"
            class="td"
            :style="{
              width: `${menuButtonWidth}px`,
            }"
          >
            <am2-icon-button-dropdown
              :icon-props="{
                name: 'menu',
              }"
              :items="menuButtonActions"
              @select="(event) => handleMenuButtonSelect(event, row)"
            />
          </td>
        </tr>

        <template v-if="loading">
          <tr class="tr" v-for="n in 12" :key="n">
            <td
              v-if="showTicks"
              class="td"
              :style="{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: `${tickColumnWidth}px`,
                    minHeight: `${rowHeight}px`,
                    marginLeft: '1px',
                  }"
            >
            </td>
            <!-- We use the same value for the column width as that of the head -->
            <td
              class="td"
              v-for="(head, idx) in heads"
              :key="head.key"
              :style="{
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    width: `${headWidths[idx] || 150}px`,
                    minHeight: `${rowHeight}px`,
                  }"
            >
              <am2-typography-skeleton
                size="md"
                :level="n"
                :style="{ width: `45%`, height: '12px', float: head.align === 'left' ? 'left' : 'right', }"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  </div>
</template>

<script>
import { debounce } from "debounce";

export default {
  name: 'Table',
  props: {
    heads: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    tickAllRows: {
      type: Boolean,
      default: false,
    },
    tickedRowsMap: {
      type: Object,
      default: () => ({}),
    },
    rowKeyName: {
      type: String,
      default: 'oid',
    },
    rowHeight: {
      type: Number,
      default: 62,
    },
    emptyText: {
      type: String,
      default: 'No data',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: {
      type: Object,
      default: () => null,
    },
    enableRowTick: {
      type: Boolean,
      default: false,
    },
    enableRowClick: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
    hasControlSection: {
      type: Boolean,
      default: false,
    },
    customHeadPadding: {
      type: String,
      default: null,
    },
    enableColumnResize: {
      type: Boolean,
      default: false,
    },
    windowWidth: {
      type: Number,
      default: 0,
    },
    hideBorders: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hasMenuButton: {
      type: Boolean,
      default: false,
    },
    menuButtonActions: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      key: null,
      sortBy: this.defaultSortBy || {
        key: null,
        order: null,
      },
      tickColumnWidth: 54,
      menuButtonWidth: 34,
      hasRightTableShadow: false,
      hasLeftTableShadow: false,
      activeRowIndex: null,
      windowScrollTop: 0,
      visibleRowsCount: 60,
      resizingColumn: null,
      resizeStart: null,
      resizingXPosition: null,
      headWidths: [],
      tableWidth: 0,
      minColumnWidth: 160,
      isScrolling: null,
      preventHorizontalScroll: false,
      tooltipOptions: {
        delay: { show: 800, hide: 0 },
      }
    };
  },

  computed: {
    rowHeightWithBorder() {
      return this.rowHeight + 1;
    },
    computedTop() {
      return this.startIndex * this.rowHeightWithBorder
    },
    totalHeight() {
      const loadingHeight = this.loading ? this.rowHeightWithBorder * 12 : 0
      const minHeight = !this.loading && !this.rows.length ? 50 : 0
      return (this.rows.length * this.rowHeightWithBorder) + loadingHeight + minHeight
    },
    startIndex() {
      let startNode = Math.floor((this.windowScrollTop - 640) / this.rowHeightWithBorder)
      return Math.max(0, startNode - 10)
    },
    visibleRows() {
      return this.rows.slice(this.startIndex, this.startIndex + this.visibleRowsCount)
    },
    showTicks() {
      return this.enableRowTick;
    },
    isNoRows() {
      return this.rows.length === 0 && !this.loading;
    },
    firstColumnWidth() {
      return this.headWidths.length ? this.headWidths[0] : this.minColumnWidth
    },
  },

  watch: {
    async heads() {
      // Whenever column changes, check if we need shadow again
      await this.$nextTick();
      this.horizontalScrollHandler(true);
      this.syncHeaderAndBody();
      this.headWidths = this.heads.map( head => head.width || this.minColumnWidth );
      this.handleWindowResize();
      this.syncTableWidth()
      this.syncHeadWidthsToWindow()
    },
    defaultSortBy(val) {
      this.sortBy = val;
    },
  },

  created() {
    // Define none-reactive data
    this.handleTableResizeMouseMoveDebounce = debounce(this.handleTableResizeMouseMove, 8);
  },

  mounted() {
    window.addEventListener('resize', this.handlerWindowResize);
    window.addEventListener('scroll', this.handleWindowScroll);
    this.getTableWrapper().addEventListener('scroll', this.syncHeaderAndBody);
    this.headWidths = this.heads.map( head => head.width || this.minColumnWidth );
    this.initializeTable();
  },
  // Remember to clear all listeners
  beforeDestroy() {
    window.removeEventListener('resize', this.handlerWindowResize);
    window.removeEventListener('scroll', this.handleWindowScroll);
    document.removeEventListener('mouseup', this.handleTableResizeMouseUp);
    document.removeEventListener('mousemove', this.handleTableResizeMouseMoveDebounce);
    this.getTableWrapper().removeEventListener('scroll', this.syncHeaderAndBody);
    document.removeEventListener('touchend', this.handleTableResizeMouseUp);
    document.removeEventListener('touchmove', this.handleTableResizeMouseMoveDebounce);
  },

  methods: {
    getTableHeaderContainer() {
      return this.$refs['thead-real'];
    },
    getTbodyElement() {
      return this.$refs.tbody;
    },
    getTableWrapper() {
      return this.$refs['table-wrapper']
    },
    getRowIndex(row) {
      return this.rows.findIndex(item => item === row);
    },
    // Table might not be ready instantly, so we just have a poll to make sure we do first 'handleWindowResize' when it's ready
    initializeTable() {
      let count = 0;
      this.initializeTablePoll = setInterval(() => {
        count += 1;
        if (this.$el.offsetWidth || count > 20) {
          clearInterval(this.initializeTablePoll);
          if (this.windowWidth !== window.innerWidth) this.handleWindowResize();
        }
      }, 50);

      this.syncTableWidth();
      if (this.windowWidth !== window.innerWidth) this.handleWindowResize();
    },

    syncTableWidth() {
      this.tableWidth = this.headWidths.reduce( (sum, item) => {
        return sum + item
      }, 0);

      if (this.fullWidth) {
        this.tableWidth = this.getTableWrapper().clientWidth;
      }

      if (this.showTicks) {
        this.tableWidth += this.tickColumnWidth;
      }

      if (this.hasMenuButton) {
        this.tableWidth += this.menuButtonWidth;
      }
    },

    // Updates headWidths so that the sum of each item is never less than the table width
    syncHeadWidthsToWindow() {
      let tableWidth = this.getTableWrapper().clientWidth;
      if (!tableWidth) return;

      const combinedWidth = this.headWidths.reduce((sum, item) => sum + item, 0);
      const combinedMinimalWidth = this.headWidths.length * this.minColumnWidth;

      if (this.showTicks) {
        tableWidth -= this.tickColumnWidth;
      }

      if (this.hasMenuButton) {
        tableWidth -= this.menuButtonWidth;
      }

      const totalCustomWidths = this.heads.reduce((acc, head) => {
        if (typeof head.width === 'number') {
          return acc + head.width
        }
        return acc
      }, 0)

      // Customize the table width and allocate the remaining space to the columns without a specified width.
      if (totalCustomWidths > 0 && totalCustomWidths < tableWidth) {
        const customWidthsCount = this.heads.filter((head) => typeof head.width === 'number').length;
        const defaultWidth = (tableWidth - totalCustomWidths) / (this.heads.length - customWidthsCount);
        this.headWidths = this.heads.map((head) => {
          if (typeof head.width === 'number') {
            return head.width;
          }
          return defaultWidth < this.minColumnWidth ? this.minColumnWidth : defaultWidth;
        });
      } 
      // Distribute the available space equally among the columns
      else if (combinedMinimalWidth < tableWidth) {
        this.headWidths = this.headWidths.map(() => 
          this.headWidths.length > 0 ? tableWidth / this.headWidths.length : this.minColumnWidth
        );
      } else {
        this.headWidths = this.headWidths.map((item) => {
          const percentage = item / combinedWidth;
          return percentage * tableWidth < this.minColumnWidth ? this.minColumnWidth : percentage * tableWidth;
        });
      }

      this.syncTableWidth();
    },

    syncHeaderAndBody() {
      if (this.preventHorizontalScroll) {
        this.preventHorizontalScroll = false
        return
      }

      const header = this.getTableHeaderContainer();
      const body = this.getTableWrapper();
      this.preventHorizontalScroll = true
      if (header) header.scrollLeft = body.scrollLeft;
      this.horizontalScrollHandler();
    },
    handleHeaderScroll() {
      if (this.preventHorizontalScroll) {
        this.preventHorizontalScroll = false
        return
      }

      const header = this.getTableHeaderContainer();
      const body = this.getTableWrapper();
      this.preventHorizontalScroll = true
      if (body) body.scrollLeft = header.scrollLeft;
      this.horizontalScrollHandler()
    },
    checkRowActivity(row, rowIndex) {
      if (this.enableRowClick && this.activeRowIndex === rowIndex) {
        return true;
      }
      if (this.enableRowTick && this.tickedRowsMap[row[this.rowKeyName]]) {
        return true;
      }
      return false;
    },
    horizontalScrollHandler() {
      if (!this.getTableWrapper()) return;

      const tbodyOffsetWidth = this.getTbodyElement().offsetWidth
      const tableWrapperOffsetWidth = (this.getTableWrapper().scrollLeft + this.getTableWrapper().offsetWidth)
      this.hasRightTableShadow = tbodyOffsetWidth > tableWrapperOffsetWidth;
      this.hasLeftTableShadow = this.getTableWrapper().scrollLeft > 5;
    },
    handlerWindowResize(event) {
      if (this.windowWidth !== event.target.innerWidth) this.handleWindowResize();
    },
    handleWindowResize() {
      this.horizontalScrollHandler();
      this.syncHeadWidthsToWindow();
    },
    handleWindowScroll(e) {
      window.requestAnimationFrame(() => {
        this.windowScrollTop = window.scrollY;
      })
    },
    handleTableContentOnWheel(e) {
      this.horizontalScrollHandler();
    },

    handleTickAllCheckboxClick() {
      this.$emit('tickAllRows', !this.tickAllRows);
    },

    handleSortClick(sortKey, event) {
      if (!sortKey) {
        return;
      }
      if (this.heads.find( item => item.sortKey === sortKey).lock) {
        return;
      }
      if (this.resizingColumn !== null) {
        return;
      }

      this.sortBy.key = sortKey;
      this.sortBy.order = this.sortBy.order === 'asc' ? 'desc' : 'asc';

      this.$emit('sortBy', this.sortBy);
    },

    handleRowTick(row) {
      const newValue = !this.tickedRowsMap[row[this.rowKeyName]];
      this.$emit('rowTick', row, newValue);
    },

    handleRowClick(row) {
      if (!this.enableRowClick) {
        return;
      }
      this.$emit('rowClick', row);
    },

    handleRowMouseEnter(rowIndex) {
      if (!this.enableRowClick) { return; }
      this.activeRowIndex = rowIndex;
    },

    handleRowMouseLeave() {
      this.activeRowIndex = null;
    },

    handleCellMouseEnter(head) {
      this.$emit('cellMouseEnter', head);
    },
    handleCellMouseLeave(head) {
      this.$emit('cellMouseLeave', head);
    },
    handleTableMouseLeave() {
      this.$emit('tableMouseLeave');
    },

    showContentColumnWidth(index) {
      const paddingWidth = 30
      let longestText = ''
      let columnName = this.heads[index].sortKey
      if (columnName === 'firstName') columnName = 'name'
      this.visibleRows.forEach(col => {
        if (col[columnName].length > longestText.length) longestText = col[columnName];
      })

      const font = "normal 14px Graphik"
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const {
        width
      } = context.measureText(longestText);

      let calculatedWidth = width + paddingWidth
      if (columnName === 'name') calculatedWidth += 50;
      calculatedWidth = calculatedWidth < this.minColumnWidth ? this.minColumnWidth : calculatedWidth;

      this.$set(this.headWidths, index, calculatedWidth);
      this.syncTableWidth()
    },

    createResizeListeners() {
      document.addEventListener('mouseup', this.handleTableResizeMouseUp);
      document.addEventListener('mousemove', this.handleTableResizeMouseMoveDebounce);
      document.addEventListener('touchend', this.handleTableResizeMouseUp);
      document.addEventListener('touchmove', this.handleTableResizeMouseMoveDebounce);
    },

    getResizeStyles(index) {
      if (this.resizingColumn === index && this.resizingXPosition) {
        return {
          transform: `translateX(${-this.resizingXPosition}px)`,
        }
      }
      return {}
    },

    handleTableResizeMouseUp() {
      if (this.resizingColumn === null) return;

      this.$emit('columnResize', this.headWidths);

      document.removeEventListener('mouseup', this.handleTableResizeMouseUp);
      document.removeEventListener('mousemove', this.handleTableResizeMouseMoveDebounce);
      document.removeEventListener('touchend', this.handleTableResizeMouseUp);
      document.removeEventListener('touchmove', this.handleTableResizeMouseMoveDebounce);

      setTimeout(() => {
        this.resizingColumn = null;
      }, 0)

      this.syncTableWidth();
    },
    handleTableResizeMouseMove(event) {
      const x = event.type === 'touchmove' ? event.touches[0].clientX : event.x

      const positionDifference = x - this.resizeStart;
      let proposedHeadWidth = this.headWidths[this.resizingColumn] + positionDifference;
      if (proposedHeadWidth < 100) proposedHeadWidth = 100;

      // don't change last column if table width would be less than container
      if ((positionDifference < 0) && (this.tableWidth < this.getTableWrapper().offsetWidth)
        && (this.resizingColumn === this.headWidths.length - 1)
      ) {
        return
      }

      this.$set(this.headWidths, this.resizingColumn, proposedHeadWidth);
      this.resizeStart = x;

      this.syncTableWidth();
      if (this.getTableWrapper().clientWidth > this.tableWidth) this.syncHeadWidthsToWindow();
    },
    handleTableResizeMouseDown(event, idx) {
      const x = event.type === 'touchstart' ? event.touches[0].clientX : event.x

      event.stopPropagation();
      this.resizingXPosition = 0;
      this.resizingColumn = idx;
      this.resizeStart = x;
      this.createResizeListeners(idx);
    },
    handleMenuButtonSelect(menuButton, rowData) {
      this.$emit('handleMenuButtonSelect', menuButton, rowData)
    }
  },
};
</script>

<style lang="scss" scoped>
$table-radius: 5px;

.table-outer-wrapper {
  display: flex; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
  flex-flow: column; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
  position: relative;
  z-index: $zIndexLow;
  background: white;

  &-with-borders {
    border-radius: $table-radius;
    border-left: 1px solid $skyBlueGrey500;
    border-right: 1px solid $skyBlueGrey500;
    border-bottom: 1px solid $skyBlueGrey500;
  }

  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table-shadow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    z-index: $zIndexHighest;

    &.right {
      right: 0;
      border-top-right-radius: 5px;
    }
  }

  .no-content {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    z-index: $zIndexHigh;
  }

  .table-header-outer-container {
    min-height: 50px;
    z-index: $zIndexHigh;

    overflow-y: hidden;
    overflow-x: auto !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    &-with-borders {
      border-top: 1px solid $skyBlueGrey500;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
  }

  .thead {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: white;
    user-select: none;
    overflow: hidden;

    .tr {
      display: flex;
      border-bottom: 1px solid $skyBlueGrey500;

      .th {
        position: relative;
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 12px;
        min-width: 0;

        .arrow {
          height: 5px;
        }
        .lock {
          position: absolute;
          right: 4px;
        }

        .table-cell {
          display: flex;
          align-items: center;
          width: 100%;
        }

        &--firstName {
          padding-left: 83px;
          position: sticky;
          top: 0;
          background: #FFFFFF;
          z-index: 5;
        }

      }
      .th:first-child, .th:last-child {

        .lock {
          position: absolute;
          right: 9px;
        }
      }

      .table-cell {
        overflow: hidden;
        .ar-text {
          overflow: hidden;
        }
      }
    }

    &.ticks {
      .tr {
        .th--firstName {
          padding-left: 62px;
          left: 54px;
        }
      }
    }
  }

  .table-container {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    position: relative;
    top: -2px; // fake header cant hide on safari
    table-layout: fixed;
    border-collapse: collapse;
    display: table;

    .tbody {
      height: 100%;

      &.locked {
        filter: blur(3px);

        .gradient-mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%);
        }
      }

        &::-webkit-scrollbar {
          display: none;
        }

        .tr {
          background: white;
          border-bottom: 1px solid $skyBlueGrey500;
          height: 62px;
          width: 100%;

          &:last-child {
            border-bottom: none;
          }

          &.deleted {
            background-color: #F6F9FC;
          }

          .td {
            min-width: 0;

            &.blur>div {
              filter: blur(3px);
              user-select: none;
            }
          }

          .td:first-child, .td:last-child {
            .cell {
              padding: 0px 17px;
            }
          }
        }

        .tr-wrapper:last-child {
          .tr {
            border-bottom: 0px;
          }
        }
    }

  }
}

.checkboxes-container {
  .tr-wrapper {
    &:last-child {
      .tr {
        border-bottom: none;
      }
    }
  }

  .tr {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8dde3;
  }

  .tr--skeleton-white {
    background: #FFFFFF;
  }

  .td {
    display: flex;
    align-items: center;
  }
}

.th--checkbox {
  padding: 15px 17px;
  background: #fff;
}

.th--checkbox-header {
  left: 0;
  top: 0;
  background: #FFFFFF;
  z-index: 5;
}

.table-header-edge {
  height: 100%;
  width: 8px;
  background: #FFFFFF;
  cursor: col-resize;
  position:absolute;
  top: 1px;
  right:-4px;
  z-index:$zIndexHigh;
  border-bottom: 1px solid #d8dde3;

  &:hover {
    .table-header-edge__resizer {
      opacity: 1;
    }
  }

  &__border {
    width: 1px;
    height: 100%;
    background: #d8dde3;;
    position: absolute;
    right: 0;
  }

  &__resizer {
    width: 5px;
    height: 100%;
    background: #4d90fe;
    position: absolute;
    right: 0;
    opacity: 0;
    z-index: $zIndexHigh;

    &--active {
      opacity: 1;
    }
  }
}

.table-header-outer-container {
  transition: top 0.2s;
}

.top-edge-straight {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.tr:hover {
  .tag {
    border: 1px solid white;
  }
}
</style>
