var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"new-sub-account-modal",attrs:{"is-open":_vm.isShow,"header":"New Sub-account","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[(!!_vm.currentData)?_c('div',{class:[
        'new-sub-account-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"new-sub-account-modal-body-row u-display-flex u-flex-flow-row"},[_c('ar-field',{attrs:{"label":"Sub-account ID","error-messages":[
            _vm.veeErrors.first('sub-account-id') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|alphanumeric'),expression:"'required|alphanumeric'"}],attrs:{"name":"sub-account-id","data-vv-name":"sub-account-id","data-vv-as":"sub account ID","placeholder":"ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"},model:{value:(_vm.currentData.twilioId),callback:function ($$v) {_vm.$set(_vm.currentData, "twilioId", $$v)},expression:"currentData.twilioId"}}),_vm._v(" "),_c('ar-text',{staticClass:"description",style:({
              color: _vm.$arStyle.color.blueGrey700,
              marginTop: '8px',
            }),attrs:{"size":"xs","text":"Copy and paste the sub-account ID here","multiple-lines":""}})],1)],1),_vm._v(" "),_c('ar-field',{staticClass:"new-sub-account-modal-body-row",attrs:{"label":"Phone Message Service SID","error-messages":[
          _vm.veeErrors.first('message-service-sid') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('twilioMessageServiceSid|required'),expression:"'twilioMessageServiceSid|required'"}],attrs:{"name":"mobile-number","data-vv-name":"mobile-number","data-vv-as":"mobile number","placeholder":"MGXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"},model:{value:(_vm.currentData.phoneServiceSid),callback:function ($$v) {_vm.$set(_vm.currentData, "phoneServiceSid", $$v)},expression:"currentData.phoneServiceSid"}}),_vm._v(" "),_c('ar-text',{staticClass:"description",style:({
            color: _vm.$arStyle.color.blueGrey700,
            marginTop: '8px',
          }),attrs:{"size":"xs","text":"Copy and paste the phone sending messaging service SID here","multiple-lines":""}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{class:[
        'new-sub-account-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-link-button',{style:({
          height: '40px',
          padding: '0 20px'
        }),attrs:{"text":"Cancel"},on:{"click":_vm.handleCloseClick}}),_vm._v(" "),_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }