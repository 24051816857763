import { OneSchemaImporterClass } from "@oneschema/importer";

import { oneschemaConfig } from '@/api/oneschema/config'


export default ({ app }, inject) => {
  const importer = new OneSchemaImporterClass(oneschemaConfig);

  inject('osImporter', importer);
}

