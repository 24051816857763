<template>
  <section class="ar-segment-dropdown">
    <am2-edit-segment-or-tag-modal
      :is-show="displayEditSegmentModal"
      title="Edit Segment"
      input-label="Segment name"
      edit-button-text="Rename Segment"
      delete-button-text="Delete Segment"
      :default-name="savedFilterToEdit ? savedFilterToEdit.name : ''"
      :is-deleting="isDeletingSegment"
      :is-renaming="isPatchingSegment"
      @close="displayEditSegmentModal = false"
      @delete="handleDeleteSegment(savedFilterToEdit)"
      @rename="handleSegmentRename(savedFilterToEdit, ...arguments)"
    />
    <am2-edit-segment-or-tag-modal
      :is-show="displayEditTagModal"
      title="Edit Tag"
      input-label="Tag name"
      edit-button-text="Rename Tag"
      delete-button-text="Delete Tag"
      :default-name="tagToEdit ? tagToEdit.name : ''"
      :is-deleting="isDeletingTag"
      :is-renaming="isPatchingTag"
      @close="displayEditTagModal = false"
      @delete="handleDeleteTag(tagToEdit)"
      @rename="handleTagRename(tagToEdit, ...arguments)"
    />
    <am2-link-button-dropdown
      :items="items"
      :loading="isDropdownLoading"
      :tabs="tabs"
      :tab-key="selectedTab.key"
      @tabSelect="handleTabSelect"
      :placeholder="placeholder"
      :dropdown-style="{
        width: size === 'small' ? '300px' : '400px',
      }"
      :search-placeholder="`Search ${selectedTab.key}`"
      has-search
      :no-option-text="noOptionText"
      @select="handleFilterSelect"
      @searchStringChange="handleSearchStringChange"
      @controlClick="handleControlClick"
      data-test-id="segments-link-button-dropdown"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { debounce } from "debounce";

export default {
  name: 'SegmentDropdown',
  props: {
    size: {
      type: String,
      default: 'large',
      validator: (val) => ['small', 'large'].indexOf(val) > -1,
    },
  },
  data() {
    const tabs = [
      {
        name: 'Segments',
        key: 'segments',
      },
      {
        name: 'Tags',
        key: 'tags',
      },
    ];
    return {
      tabs,
      selectedTab: tabs[0],
      filterString: '',
      displayEditSegmentModal: false,
      displayEditTagModal: false,
      savedFilterToEdit: null,
      tagToEdit: null,
    };
  },
  computed: {
    ...mapState({
      tags: state => state.tag.tags,
      isFetchingTags: state => state.tag.isFetchingTags,
      isPatchingTag: state => state.tag.isPatchingTag,
      isDeletingTag: state => state.tag.isDeletingTag,
      savedSegmentList: state => state.segment.savedSegmentList,
      scratchSegmentInfo: state => state.segment.scratchSegmentInfo,
      isPatchingSegment: state => state.segment.isPatchingSegment,
      isDeletingSegment: state => state.segment.isDeletingSegment,
    }),
    ...mapGetters({
      activeSavedSegment: 'segment/activeSavedSegment',
      scratchSegmentHasCompleteConditions: 'segment/scratchSegmentHasCompleteConditions',
      bubbleUpFavoriteSegments: 'segment/bubbleUpFavoriteSegments',
      bubbleUpFavoriteTags: 'tag/bubbleUpFavoriteTags',
    }),
    filterItems() {
      const sortedFilterList = this.bubbleUpFavoriteSegments(this.savedSegmentList);
      return sortedFilterList
        .filter(filter => {
          return filter.name.toLowerCase().indexOf(this.filterString.toLowerCase()) > -1;
        }).map(filter => ({
          name: filter.name,
          key: filter.oid,
          controls: [
            {
              iconName: 'star',
              key: 'favourite',
              pinned: filter.favourite,
            },
            {
              iconName: 'edit',
              key: 'edit',
              closeOnClick: true,
            },
          ],
        }));
    },
    tagItems() {
      const sortedTags = this.bubbleUpFavoriteTags(this.tags);
      return sortedTags.map(tag => {
        const isTagEditable = this.$arUtils.tag.isTagEditable(tag);
        const controls = [];
        if (isTagEditable) {
          controls.push({
            iconName: 'star',
            key: 'favourite',
            pinned: tag.favourite,
          });
          controls.push({
            iconName: 'edit',
            key: 'edit',
            closeOnClick: true,
          });
        }
        return {
          name: tag.name,
          key: tag.oid,
          controls,
        };
      });
    },
    items() {
      if (this.selectedTab.key === 'segments') {
        return this.filterItems;
      } else if (this.selectedTab.key === 'tags') {
        return this.tagItems;
      }
    },
    noOptionText() {
      if (this.selectedTab.key === 'segments') {
        return 'No segments found';
      } else if (this.selectedTab.key === 'tags') {
        return 'No tags found';
      }
    },
    placeholder() {
      if (this.activeSavedSegment && this.scratchSegmentHasCompleteConditions) {
        return this.activeSavedSegment.name;
      } else {
        return this.selectedTab.name;
      }
    },
    isDropdownLoading() {
      if (this.selectedTab.key === 'tags' && this.isFetchingTags) {
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedTab: {
      handler(val) {
        if (val.key === 'tags') {
          this.filterString = '';
          this['tag/FETCH_MORE_TAGS']({ searchString: this.filterString, reload: true });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'tag/FETCH_MORE_TAGS',
      'segment/UPDATE_SAVED_SEGMENT',
      'segment/DELETE_SEGMENT',
      'tag/PATCH_TAG',
      'tag/DELETE_TAG',
    ]),
    ...mapMutations([
      'tag/RESET_TAGS',
      'segment/SET_SCRATCH_SEGMENT_BY_SAVED_SEGMENT',
      'segment/SET_SCRATCH_SEGMENT_BY_AUDIENCE_TAG',
    ]),
    handleTabSelect(tab) {
      this.selectedTab = tab;
    },
    handleFilterSelect(item) {
      if (this.selectedTab.key === 'segments') {
        this['segment/SET_SCRATCH_SEGMENT_BY_SAVED_SEGMENT'](item.key);
      } else if (this.selectedTab.key === 'tags') {
        const tagOid = item.key;
        this['segment/SET_SCRATCH_SEGMENT_BY_AUDIENCE_TAG'](tagOid);
      }
    },
    handleSearchStringChange(newFilterString) {
      if (this.filterString === newFilterString) return;

      this.filterString = newFilterString;
      if (this.selectedTab.key === 'tags') {
        this.fetchMoreTagsDebouncing(newFilterString);
      }
    },
    handleControlClick(item, control) {
      if (this.selectedTab.key === 'segments') {
        this.savedFilterToEdit = this.savedSegmentList.filter(filter => item.key === filter.oid)[0];
        if (control.key === 'edit') {
          this.displayEditSegmentModal = true;
        } else if (control.key === 'favourite') {
          this['segment/UPDATE_SAVED_SEGMENT']({
            oid: this.savedFilterToEdit.oid,
            editFilter: {
              favourite: !this.savedFilterToEdit.favourite,
            },
          });
        }
      } else if (this.selectedTab.key === 'tags') {
        this.tagToEdit = this.tags.filter(tag => item.key === tag.oid)[0];
        if (control.key === 'edit') {
          this.displayEditTagModal = true;
        } else if (control.key === 'favourite') {
          this['tag/PATCH_TAG']({
            oid: this.tagToEdit.oid,
            editTag: {
              favourite: !this.tagToEdit.favourite,
            },
          });
        }
      }
    },
    async handleDeleteSegment(filter) {
      const deleted = await this['segment/DELETE_SEGMENT'](filter);
      if (deleted) {
        this.displayEditSegmentModal = false;
      }
    },
    async handleSegmentRename(filter, name) {
      const patched = await this['segment/UPDATE_SAVED_SEGMENT']({
        oid: filter.oid,
        editFilter: { name },
      });
      if (patched) {
        this.displayEditSegmentModal = false;
      }
    },
    async handleDeleteTag(tag) {
      const deleted = await this['tag/DELETE_TAG'](tag.oid);
      if (deleted) {
        this.displayEditTagModal = false;
      }
    },
    async handleTagRename(tag, name) {
      const patched = await this['tag/PATCH_TAG']({
        oid: tag.oid,
        editTag: {
          name,
        },
      });
      if (patched) {
        this.displayEditTagModal = false;
      }
    },
  },
  created() {
    this.fetchMoreTagsDebouncing = debounce((filterString) => {
      this['tag/FETCH_MORE_TAGS']({ searchString: filterString, reload: true });
    }, 250);

    if (this.scratchSegmentInfo.source === 'tag') {
      this.selectedTab = this.tabs[1];
    }
  },
  beforeDestroy() {
    this['tag/RESET_TAGS']();
  },
}
</script>

<style lang="scss" scoped>
.ar-segment-dropdown {
  display: inline-block;
}
</style>
