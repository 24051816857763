<template>
  <section>
    <am2-table
      ref="table"
      :heads="decoratedHead"
      :rows="body"
      :loading="loading"
      @sortBy="handleSortByChange"
      class="notifications-table"
      :empty-text="emptyText"
      :has-sticky-header="hasStickyHeader"
      has-control-section
      :default-sort-by="defaultSortBy"
    >
      <div
        slot="notificationType"
        class="cell"
        slot-scope="{ data: { notificationType } }"
      >
        <div :class="[
          'notification-type'
        ]">
          <ar-text
            size="xs"
            :text="notificationType"
            :style="{
              color: getColorBasedOnNotificationType(notificationType)
            }"
            weight="bold"
          />
        </div>
      </div>
      <div
        slot="message"
        slot-scope="{ data: { message } }"
        class="cell"
      >
        <ar-text
          class="message-text"
          size="xxs"
          :text="message"
          :style="{

          }"
        />
      </div>
      <div
        slot="sysCtime"
        slot-scope="{ data: { sysCtime } }"
        class="cell sysctime-date"
      >
        <ar-text
          size="xxs"
          :text="getSysCtimeFormatted(sysCtime)"
          :style="{

          }"
        />
      </div>
    </am2-table>
  </section>
</template>

<script>
import { getFormattedDateTimeWithTimezone } from '@/utils/date/';

export default {
  name: 'NotificationsTable',

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'No data',
    },
    hasStickyHeader: {
      type: Boolean,
      default: true,
    },
    defaultSortBy: {
      type: Object,
      default: () => null,
    }
  },
  computed: {
    decoratedHead() {
      return this.head.map((item, index) => {
        let width;
        if (item.key === 'message') {
          width = 230;
        } else if (item.key === 'notificationType') {
          width = 50;
        } else if (item.key === 'sysCtime') {
          width = 150;
        } else {
          width = 136;
        }
        return {
          ...item,
          width,
          align: this.headAlign(index)
        };
      });
    },
  },

  methods: {

    handleSortByChange(sortBy) {
      this.$emit('sortBy', sortBy);
    },

    getColorBasedOnNotificationType(notificationType) {
      let color;
      if (notificationType === 'info') {
        color = '#22bb33';
      } else if (notificationType === 'error') {
        color = '#bb2124';
      } else if (notificationType === 'warning') {
        color = '#f0ad4e';
      } else {
        color = '#aaaaaa';
      }

      return color;
    },

    headAlign (index) {
      if (index == 0) {
        return 'left'
      }
      else if (index == 1) {
        return 'left';
      } else {
        return 'right';
      }
    },

    getSysCtimeFormatted(sysCtime) {
      if (!sysCtime) {
        return null;
      }
      const date = new Date(sysCtime)
      return date ? getFormattedDateTimeWithTimezone(date) : "";
    },

  },
};
</script>

<style lang="scss" scoped>
.notifications-table {

  .cell {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 12px;
    justify-content: flex-start;
  }

  .sysctime-date {
    justify-content: flex-end !important;
  }

  .message-text {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    >>> a {
      color: $blueGrey800;
    }
  }

}
</style>
