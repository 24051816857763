import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { clone } from '@/utils/helpers';
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { NewUser } from './types';

export default (axios: NuxtAxiosInstance) => ({
  async registerToWaveApp(user: NewUser): Promise<string> {
    const body = clone(user);
    body.waveRegistration = true;
    const { headers, status } = await axios.post('/signup', body);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Register to Wave error',
        message: `Error registering Wave app.`,
        status,
      };
      throw apiError;
    }
    return headers['success-redirect-uri'];
  },

  async fetchPromoterAccountPermissions(promoterOid: number): Promise<string> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/account-permissions`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch User Permissions error',
        message: `Error fetching User permissions `,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async sendUserInvite(userData: object): Promise <any> {
    const { promoterOid, emailAddress, defaultUserRoleOid, promoterUserRoleOid }: any = userData
    const res = await axios.post(`promoter/${promoterOid}/user-invite`, {
      emailAddress,
      defaultUserRoleOid,
      promoterUserRoleOid
    })
    if (isErrorStatus(res.status)) {
      const apiError: CustomApiError = {
        name: 'Failed to generate invite!',
        message: `The server could not generate an invite. Please try again.`,
        status: res.status,
      };
      throw apiError;
    }
    return res
  },

  async deleteUserInvite(promoterOid: number, inviteOid: number): Promise <any> {
    if (!promoterOid || !inviteOid) {
      return;
    }
    const { status } = await axios.delete(`promoter/${promoterOid}/user-invite/${inviteOid}`)
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to delete invite!',
        message: `The server could not revoke the selected invite. Please try again.`,
        status,
      };
      throw apiError;
    }
    return true
  },

  async validateInvite(userData: object): Promise <any> {
    const { promoterOid, hash, id}: any = userData
    const { data, status } = await axios.post(`validate-user-invite`, {
      promoterOid,
      inviteCode: hash,
      inviteOid: id,
    })

    if (!data || data.length <= 0 || isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Invite is not valid',
        message: `Invite is not valid for promoter ${promoterOid} with invite code '${hash}' and invite id ${id}`,
        status,
      };
      throw apiError;
    }

    return data
  },

  async finishInvite(userData: object): Promise <any> {
    const { firstName, lastName, promoterOid, email, hash, password, id}: any = userData
    const { data, status } = await axios.post(`promoter/${promoterOid}/register-to-company`, {
      firstName,
      lastName,
      emailAddress: email,
      promoterOid,
      code: hash,
      password,
      id
    })

    if (!data || data.length <= 0 || isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Cannot complete invited user registration to company',
        message: `${firstName} ${lastName} (${email}) cannot finish registration to ${promoterOid}`,
        status,
      };
      throw apiError;
    }

    return data
  },

  async fetchInvitees(promoterOid: number): Promise <any> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/user-invite`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch User Permissions error',
        message: `Error fetching User permissions `,
        status,
      };
      throw apiError;
    }
    return {data, status};
  },

  async resendInvite(email: string): Promise <any> {
    const response = await axios.get(`resend-account-invite`, {
      params: {
        emailAddress: email
      }
    })

    if (isErrorStatus(response.status)) {
      const apiError: CustomApiError = {
        name: 'Invite cannot be resent',
        message: `Invite cannot be resent for ${email}`,
        status: response.status,
      };
      throw apiError;
    }

    return response
  },

  async fetchDomains(promoterOid: number): Promise <any> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/authenticated-domain`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch Domains error',
        message: `Error fetching Domains`,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async createDomainAuth(domainData: object): Promise <any> {
    const { promoterOid, domain }: any = domainData
    const { data, status } = await axios.post(`promoter/${promoterOid}/manage-authenticated-domain`, {
      domain,
    })

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to create domain authentication',
        message: `The server could not create the domain authentication`,
        status: status,
      };
      throw apiError;
    }
    return data
  },


  async deleteDomainAuth(promoterOid:number, domainOid: number): Promise <any> {
    if (!promoterOid || !domainOid) return;
    const { data, status } = await axios.delete(`/promoter/${promoterOid}/manage-authenticated-domain/${domainOid}`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to delete domain',
        message: `The server could not delete the selected domain`,
        status: status,
      };
      throw apiError;
    }
    return true
  },

  async verifyDomainAuth(domainData: object): Promise <any> {
    const { promoterOid, domain }: any = domainData
    const { data, status } = await axios.get(`promoter/${promoterOid}/validate-authenticated-domain?domain=${domain}`)
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to verify domain authentication',
        message: `The server could not verify the domain authentication`,
        status: status,
      };
      throw apiError;
    }
    return data
  },

  async fetchTimeToValue(promoterOid: number): Promise <any> {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/time-to-value`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch Time to Value error',
        message: `Error fetching Time to Value `,
        status,
      };
      throw apiError;
    }
    return {data, status};
  },

  async createTimeToValue(promoterOid: number, column: string): Promise <any> {
    const { data, status } = await axios.post(`promoter/${promoterOid}/time-to-value`, {
      column,
    })

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to create time to value item',
        message: `The server could not create the time to value item`,
        status: status,
      };
      throw apiError;
    }
    return data
  },

  async requestAutomationInsightsRefresh(promoterOid: number, chartOid: number) {
    // The server returns an object if the update request is accepted. Else it returns null
    const { status, data } = await axios.post(`promoter/${promoterOid}/automation-stats/${chartOid}/update`)

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to request automation insights refresh',
        message: `The server could not refresh the automation insights`,
        status: status,
      };
      throw apiError;
    }
    return !!data
  },

  async requestSetMfaForceForPromoter(promoterOid: string, promoterAccountOid: string, value: boolean) {
    const { status, data } = await axios.post(`promoter/${promoterOid}/mfa-force`, {
      'force': value,
      'target-promoter-account-oid': promoterAccountOid,
    })

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to set MFA force for promoter',
        message: `The server could not set MFA force for promoter`,
        status: status,
      };
      throw apiError;
    }
    return data
  },

  async deleteMfaForPromoter(promoterOid: string, promoterAccountOid: string) {
    const { status, data } = await axios.delete(`promoter/${promoterOid}/mfa-configure/${promoterAccountOid}`)

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to disable MFA for promoter',
        message: `The server could not disable MFA for promoter`,
        status: status,
      };
      throw apiError;
    }
    return data
  },

  async deleteMfa(promoterOid: number, promoterAccountOid: number, otp: number) {
    const { status, data } = await axios.delete(`promoter/${promoterOid}/mfa-configure/${promoterAccountOid}`, {
      params: {
        'otp-1': otp,
      }
    })

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to disable MFA',
        message: `The server could not disable MFA`,
        status: status,
      };
      throw apiError;
    }
    return data
  },

  async getMfaConfigure(promoterOid: number) {
    const { data, status } = await axios.get(`/promoter/${promoterOid}/mfa-configure`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch MFA Configure error',
        message: `Error fetching MFA Configure `,
        status,
      };
      throw apiError;
    }
    return data;
  },

  async setMfaConfigure(promoterOid: number, otp1: number, otp2: number) {
    const { data, status } = await axios.post(`promoter/${promoterOid}/mfa-configure`, {
      'otp-1': otp1,
      'otp-2': otp2,
    })

    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Failed to set MFA Configure',
        message: `The server could not set MFA Configure`,
        status: status,
      };
      throw apiError;
    }
    return data
  }
});
