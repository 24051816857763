
<template>
  <div
    class="simple-button-filtered-dropdown"
  >
    <div class="custom-field-button">
      <ar-simple-button
        :icon-name="iconName"
        :text="text"
        outlined
        class="tier-type-btn"
        @click="toggleDropdown"
      />
      <div
        class="filtered-dropdown-wrapper"
        @click.native="handleClickOutside"
      >
        <div v-if="isDropdownOpen" class="filtered-search-wrapper">
          <am2-search
            class="add-to-list-search"
            v-model="filterSearchText"
            :placeholder="placeholder"
            data-test-id="add-to-list-search"
          />

        
          <div class="message-list-wrapper">
            <template v-for="list in filteredItemList">
              <a
                :key="list.oid"
                class="message-list-link"
                @click="() => handleItemSelect(list)"
                :data-test-id="`add-to-list-anchor-${list.oid}`"
              >
                <div class="item-wrapper">
                  <ar-text
                    :class="[
                      'list-name',
                      list.oid === selectedListOid && 'selected'
                    ]"
                    size="xs"
                    :text="list.name"
                    multiple-lines
                    :max-lines="1"
                    line-height="20px"
                    :weight="list.oid === selectedListOid ? 'bold' : 'normal'"
                  />
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SimpleButtonFilteredDropdown',
  props: {
    text: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    itemName: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: [],
    },
  },
  computed: {
    placeholder() {
      return `Search ${this.itemName || 'items'}`
    },
    offsetFromBottom() {
      return '150px';
    }
  },
  data() {
    return {
      isDropdownOpen: false,
      filterSearchText: null,
      selectedListOid: null,
      filteredItemList: [],
    }
  },
  watch: {
    filterSearchText(val) {
      this.filterSearch(val)
    },
    items(val) {
      this.filteredItemList = val
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    handleClickOutside(e) {
      if (!this.isDropdownOpen) return

      let clickOutsideClasses = ['content-wrapper', 'header-wrapper', 'heading-close-icon-wrapper', 'footer-wrapper', 'add-to-list-container']
      if (clickOutsideClasses.includes(e.target.className)) {
        this.isDropdownOpen = false
        this.filterSearchText = ''
      }
    },
    handleItemSelect(item) {
      this.$emit('select', item);
      this.toggleDropdown()
    },
    filterSearch(text) {
      this.filteredItemList = this.items.filter(item => this.$arFuzzySearch(item.name, text));
    }
  },
  mounted() {
    this.filteredItemList = this.items
  }
}
</script>
<style lang="scss" scoped>

.custom-field-button {
  position: relative;
  display: inline-block;
  width: 384px;
  
  .tier-type-btn {
    width: 223px;
  }
}

.filtered-dropdown-wrapper {
  position: absolute;
  z-index: 10;
  bottom: 100%;
  width: 384px;

  .filtered-search-wrapper {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 #e4e5e7;
    border: solid 1px #d4d8e1;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 10px;
    max-height: 334px;
    width: 384px;

    .add-to-list-search {
      width: 100%;
      height: 48px;
      padding: 9px 12px 0px 9px;
    }

    .message-list-wrapper {
      width: 100%;
      margin-top: 9px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      max-height: 260px;
      overflow: auto;
      padding-bottom: 10px;

      .message-list-link {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-left: 8px;
        cursor: pointer;

        &:hover {
          background: #f2edff;
        }

        &.no-list-found-wrapper:hover {
          background: unset;
          cursor: default;
        }

        .item-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          padding: 12px;
          
          .list-name {
            &.selected {
              color: $purple500;
            }
          }
        }
      }
    }
  }

}
</style>

