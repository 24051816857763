import { Module } from "vuex";
import { clone, mergeObjects } from '@/utils/helpers/';
import { RootState } from '@/store/modules/types';
import { CustomField, CustomFieldState, CustomFieldChanges } from './types'
import { customFieldActions } from './actions';

export const initialCustomFieldStates = (): CustomFieldState => ({
  isFetchingCustomFields: false,
  customFields: [],
  isCreatingCustomField: false,
});

const customFieldModule: Module<CustomFieldState, RootState> = {
  namespaced: true,
  state: initialCustomFieldStates,
  actions: customFieldActions,
  mutations: {
    SET_CUSTOM_FIELDS(state, customFields: CustomField[]) {
      state.customFields = clone(customFields);
    },
    SET_IS_FETCHING_CUSTOM_FIELDS(state, isFetching: boolean) {
      state.isFetchingCustomFields = isFetching;
    },
    SET_IS_CREATING_CUSTOM_FIELD(state, isCreating: boolean) {
      state.isCreatingCustomField = isCreating;
    },
    RESET_CUSTOM_FIELDS(state) {
      const {
        customFields,
        isFetchingCustomFields,
        isCreatingCustomField,
      } = initialCustomFieldStates();
      state.customFields = customFields;
      state.isFetchingCustomFields = isFetchingCustomFields;
      state.isCreatingCustomField = isCreatingCustomField;
    },
    ADD_TO_CUSTOM_FIELDS(state, customField: CustomField) {
      state.customFields = clone([customField, ...state.customFields]);
    },
    PATCH_IN_CUSTOM_FIELDS(state, {
      oid,
      changes
    }: { oid: number, changes: CustomFieldChanges }) {
      state.customFields = state.customFields.map(item => {
        if (item.oid !== oid) {
          return item;
        }
        return mergeObjects(item, changes);
      });
    },
    REMOVE_FROM_CUSTOM_FIELDS(state, oid: number) {
      state.customFields = state.customFields.filter(item => {
        return item.oid !== oid;
      });
    },
  },
  getters: {},
};

export default customFieldModule;
