var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wrapper content content--centered"},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('div',{class:[
    'title-section',
    _vm.$arMediaQuery.pageContent.minWidth('lg') && 'lg-min',
    _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Google Custom Audiences","description":"Create custom audiences to target with Google Ads","text-size":_vm.$arMediaQuery.pageContent.minWidth('md') ? 'lg' : 'md',"multiple-lines-title":_vm.$arMediaQuery.pageContent.minWidth('md') ? false : true,"icon-props":{
    name: 'google',
  }}})],1),_vm._v(" "),(!_vm.integrationFailed)?_c('div',{staticClass:"title-section-right"},[_c('ar-simple-select',{staticClass:"select",style:({ maxWidth: 'calc(100vw - 80px)', }),attrs:{"enable-filter":"","placeholder":"Select google ad account","items":_vm.adAccountItems,"default-select-index":_vm.selectedAdAccountIndex},on:{"select":_vm.handleAdAccountSelect}}),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
    {
      name: 'Open Google',
      value: 'GO_TO_GOOGLE_ADS',
    },
    {
      name: 'Reconnect Account',
      value: 'RECONNECT_INTEGRATION',
    },
    {
      name: 'Remove Account',
      value: 'DELETE_CUSTOM_INTEGRATION',
    } ]},on:{"select":_vm.handleSettingsOptionSelect}})],1):_vm._e()]),_vm._v(" "),(_vm.integrationFailed)?_c('section',{style:({ paddingTop: '55px' })},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Google Custom Audience connection is no longer valid. This means you won't be able to sync your Custom Audiences with filters <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.reconnectIntegration}})],1):_c('section',[_c('am2-tabs',{staticClass:"tabs",attrs:{"items":[
        { title: 'Notifications'},
        {
          iconName: 'hourglass',
          title: 'Segments'
        }
      ],"selected-tab-index":_vm.selectedTabIndex},on:{"select":function (selected, idx) { return _vm.selectedTabIndex = idx; }}}),_vm._v(" "),(_vm.selectedTabIndex === 0)?_c('div',[_c('NotificationsContainer',{attrs:{"integration":_vm.integration,"loading":_vm.isPollingIntegrationResource}})],1):_vm._e(),_vm._v(" "),(_vm.selectedTabIndex === 1)?_c('div',{staticClass:"filter-cards-section-container"},[_c('section',{class:[
      'search-section',
      _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-min' ]},[_c('span',{staticClass:"display-filter-count"},[_vm._v("Showing "),_c('b',[_vm._v(_vm._s(_vm.filtersByName.length)+" segments")])]),_vm._v(" "),_c('am2-search',{staticClass:"search",attrs:{"placeholder":"Search segments"},on:{"blur":function($event){_vm.searchString = _vm.searchString.trim()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),_vm._v(" "),_c('section',{staticClass:"filter-cards-section"},_vm._l((_vm.filtersByName),function(filter){return _c('FilterIntegrationCard',{key:filter.oid,staticClass:"filter-card",attrs:{"title":filter.name,"action-items":_vm.getFilterActionItems(filter.oid),"sync-status":_vm.getCustomAudienceSyncStatus(filter.oid),"synced-date":_vm.getCustomAudienceAttribute(filter.oid, 'lastSyncDate') && _vm.generateTimeInformation(_vm.getCustomAudienceAttribute(filter.oid, 'lastSyncDate')),"synced-error":_vm.getCustomAudienceAttribute(filter.oid, 'error') && _vm.getCustomAudienceAttribute(filter.oid, 'error').errorUserTitle,"synced-error-details":_vm.getCustomAudienceAttribute(filter.oid, 'error') && _vm.getCustomAudienceAttribute(filter.oid, 'error').errorUserMsg,"disabled":_vm.selectedAdAccount ? false : true,"card-size":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'regular' : 'xs',"data-test-id":("filter card - " + (filter.name))},on:{"buttonClick":function($event){return _vm.handleSyncFilterIntegrationButtonClick(filter)},"itemSelect":function($event){
    var i = arguments.length, argsArray = Array(i);
    while ( i-- ) argsArray[i] = arguments[i];
_vm.handleFilterIntegrationItemSelect.apply(
      void 0, [ filter.oid,
      _vm.getCustomAudienceAttribute(filter.oid, 'customAudienceId') ].concat( argsArray )
    )}}})}),1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }