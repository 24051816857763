var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'ar-button-switch',
    _vm.disabled && 'disabled' ]},_vm._l((_vm.items),function(item){return _c('div',{class:[
      'button-tab',
      item.key === _vm.selectedValue && 'selected'
    ],on:{"click":function () { return _vm.handleItemSelect(item); }}},[_c('ar-text',{style:({
        color: item.key === _vm.selectedValue ? _vm.$arStyle.color.purple500 : null,
      }),attrs:{"text":item.name,"size":"xs"}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }