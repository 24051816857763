<template>
  <section
    class="fan-purchases-container"
  >
    <am2-tabs
      data-test-id="purchases-tabs"
      :items="[
        { title: 'Ticket Purchases' },
        { title: 'POS Purchases' },
      ]"
      :selected-tab-index="selectedTabIndex"
      class="tab-options"
      @select="(selected, idx) => selectedTabIndex = idx"
    />
    <CustomerProfileTicketPurchases
      v-if="selectedTabIndex == 0"
    />
    <CustomerProfilePosPurchases
      v-if="selectedTabIndex == 1"
    />
  </section>
</template>

<script>

import CustomerProfileTicketPurchases from './ticketPurchases.vue'
import CustomerProfilePosPurchases from './posPurchases.vue'
import {mapActions, mapGetters, mapState} from "vuex";


export default {
  name: 'CustomerProfilePurchases',

  data() {
    return {
      selectedTabIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
  },
  mounted() {
    this.fetchPermissionsAndMaybeReroute();
    if (localStorage.purchasesTabSelectedIndex) {
      // the sorted value is string (?)
      this.selectedTabIndex = typeof(localStorage.purchasesTabSelectedIndex) === 'string' ? parseInt(localStorage.purchasesTabSelectedIndex):localStorage.purchasesTabSelectedIndex;
    } else {
      localStorage.purchasesTabSelectedIndex = 0;
    }
  },
  watch: {
    selectedTabIndex(val) {
      localStorage.purchasesTabSelectedIndex = val;
    }
  },

  methods: {
    ...mapActions([
      'auth/FETCH_PERMISSIONS',
    ]),

    async fetchPermissionsAndMaybeReroute() {
      await this['auth/FETCH_PERMISSIONS']();
      if (!this.isPermissionEnabled(['view-sales'])) {
        this.$router.push({
          path: `/audience/${this.$route.params.oid}/view/overview`,
        });
      }
    },
  },

  components: {
    CustomerProfileTicketPurchases,
    CustomerProfilePosPurchases,
  },
};
</script>

<style lang="scss">
/* LEADERBOARD ------------------------ */
.event-orders-container {
  padding: 0 0;
  margin-bottom: 30px !important;

  .orders-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .filter-wrapper {
      border-left: 1px solid #DCE0E4;
      height: 30px;
      padding-left: 15px;
    }
  }

  table {
    td:nth-child(1) {
      width: 60%;
    }
  }
}
</style>
