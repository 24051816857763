<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      class="create-messaging-list-modal"
      :is-open="isShow"
      :header="title"
      :has-back-link="hasBackLink"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
      @back="handleBackPress"
    >
      <div
        slot="body"
        class="body"
        :style="{
          margin: $arMediaQuery.pageContent.maxWidth('xs') ? '20px 12px' : '30px',
        }">
        <div v-show="step === 1">
          <ar-text
            size="xs"
            text="List Name"
          />
          <ar-input
            ref="name-input"
            v-model="messageListName"
            auotfill="list-name"
            placeholder="Name your list..."
            data-test-id="create-message-list-name"
            :style="{
                marginTop: '16px',
            }"
            :formatter="messageListNameFormatter"
          />
          <ar-text
            size="xs"
            text="Note: List name will be visible to your recipients"
            class="u-margin-top-4"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-state-message
            v-if="hasNameError"
            type="error"
            text="This name is used by another list"
            :style="{ marginBottom: '12px' }"
          />
          <div class="u-display-flex u-margin-top-7">
            <ar-checkbox
              v-model="isDynamicList"
              label="Make this a dynamic list"
            />
            <ar-icon
              v-tooltip.top="{
                content: `A dynamic list is based on a Segment you've created. The list will update automatically based on the filters used to create the Segment. Note you will not be able to create a Signup Form, or manually add contacts to a dynamic list.`,
              }"
              name="tooltip-question-mark"
              class="u-margin-left-2"
              height="15px"
              width="15px"
              :color="$arStyle.color.purple500"
            />
          </div>
          <ar-simple-select
            v-if="isDynamicList"
            :items="savedSegmentList"
            class="u-margin-top-3 segment-select u-width-100-percent"
            :default-select-index="selectedFilterIndex"
            @select="handleFilterSelect"
            enable-filter
            placeholder="Select segment..."
          />
          <ar-state-message
            v-if="hasFilterGroupError"
            class="u-margin-top-2"
            type="error"
            text="This segment is already used in another list"
          />
        </div>


        <div v-show="step === 2">
          <ar-text
            v-if="!showEmailSenderForm"
            class="u-margin-bottom-5"
            size="xs"
            text="Which messaging channels should be available for this list?"
          />

          <!-- Email settings -->
          <div
            v-if="arEnableEmailSending"
            class="tick-section"
            data-test-id="tick-section-email"
            @click="emailEnabled = !emailEnabled"
            :style="{
              cursor: 'pointer',
            }"
          >
            <ar-checkbox
              ref="emailCheckbox"
              :value="emailEnabled"
              @input="val => { emailEnabled = val }"
              class="tick-section-checkbox"
              label="Email"
              :label-typography-props="{
                weight: 'bold',
              }"
              icon-name="email"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.email,
              }"
              :style="{
                pointerEvents: 'none',
              }"
            />

            <!-- Email Sender Selection Menu -->
            <div
              v-if="emailEnabled && !showEmailSenderForm"
              @click="event => event.stopPropagation()"
              :class="[
                'details-section',
                $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
              ]">
              <div class="input">
                <am2-message-sender-select
                  class="u-margin-bottom-1"
                  :value="senderOid || null"
                  emit-edit
                  data-test-id="email-message-sender-select"
                  @select="handleSenderSelect"
                  @editClick="handleSenderEdit"
                  @createClick="handleSenderCreateNew"
                />

                <section
                  v-if="senderOid && currentlySelectedEmailMessageSender && !currentlySelectedEmailMessageSender.verified"
                  class="email-unverified u-padding-top-2"
                >
                  <!-- Your From Email is awaiting verification, you Rebel scum -->
                  <ar-snackbar
                    type="general"
                    message="Please verify this email address. <a>Resend verification email</a>."
                    @anchorClick="retryEmailVerification"
                    :style="{ width: '100%' }"
                  />
                </section>
              </div>
            </div>
            <!-- Email Sender Editing Menu -->
            <div
              class="edit-sender-section"
              v-if="showEmailSenderForm"
              @click="event => event.stopPropagation()"
              :style="{
                cursor: 'default'
              }"
            >
              <div
                class="input-section"
              >
                <ar-text
                  class="label"
                  size="xs"
                  text="From Email"
                  weight="bold"
                />
                <div class="input">
                  <ar-input
                    :value="scratchEmailSender.property"
                    @input="(val) => { handleScratchEmailSenderPatch({ property: val }) }"
                    :disabled="showEmailSenderForm && !creatingNewSender"
                    placeholder=""
                    v-validate="{
                      required: true,
                      email: true
                    }"
                    autocomplete="email"
                    data-vv-name="emailAddress"
                    data-vv-as="email address"
                  />
                  <ar-state-message
                    v-if="veeErrors.has('emailAddress')"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    :text="veeErrors.first('emailAddress')"
                  />
                  <ar-state-message
                    v-if="restrictedDomainError"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    text="Email domain is not supported. Please use a different email address."
                  />
                </div>
              </div>
              <div
                class="input-section"
              >
                <ar-text
                  class="label"
                  size="xs"
                  text="From Name"
                  weight="bold"
                />
                <div class="input">
                  <ar-input
                    :value="scratchEmailSender.additionalInfo.senderName"
                    @input="(val) => { handleScratchEmailSenderPatch({ additionalInfo: { senderName: val } }) }"
                    placeholder=""
                    v-validate="`required`"
                    auotfill="organization"
                    data-vv-name="senderName"
                    data-vv-as="sender name"
                  />
                  <ar-state-message
                    v-if="veeErrors.has('senderName')"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    :text="veeErrors.first('senderName')"
                  />
                </div>
              </div>
              <div class="input-section">
                <ar-text
                  class="label"
                  size="xs"
                  text="Business Address"
                  weight="bold"
                />
                <div class="input">
                  <ar-input
                    :value="scratchEmailSender.additionalInfo.businessAddress"
                    @input="(val) => { handleScratchEmailSenderPatch({ additionalInfo: { businessAddress: val } }) }"
                    placeholder=""
                    v-validate="`required`"
                    autocomplete="address"
                    data-vv-name="businessAddress"
                    data-vv-as="business address"
                  />
                  <ar-state-message
                    v-if="veeErrors.has('businessAddress')"
                    :style="{ marginTop: '8px' }"
                    type="error"
                    :text="veeErrors.first('businessAddress')"
                  />
                </div>
              </div>

              <div
                v-if="showEmailSenderForm && !creatingNewSender"
                class="u-display-flex u-justify-content-center u-align-items-center u-padding-bottom-3"
              >
                <ar-icon
                  name="exclamation-mark"
                  width="14px"
                  :color="$arStyle.color.purple500"
                  :wrapper-style="{
                      border: `1px solid ${$arStyle.color.purple500}`,
                      padding: '1px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }"
                />
                <ar-text
                  class="label"
                  size="xs"
                  allow-html
                  text="<strong>Note:</strong> Updates are applied to all lists where email address is used"
                  weight="normal"
                />
              </div>

              <div class="u-display-flex u-justify-content-flex-end u-margin-top-4">
                <ar-link-button
                  v-if="!isPatchingMessageSenders && !isCreatingMessageSender && !isDeletingMessageSender"
                  text="Cancel"
                  color="purple"
                  class="u-margin-right-4"
                  @click="handleBackFromSenderEdit"
                />
                <ar-simple-button
                  v-if="showEmailSenderForm && !creatingNewSender"
                  text="Delete"
                  type="red"
                  outlined
                  :style="{ width: '120px' }"
                  text-weight="bold"
                  class="u-margin-right-3"
                  :custom-style-attributes="{
                      height: '40px',
                    }"
                  :disabled="isPatchingMessageSenders"
                  :loading="isDeletingMessageSender"
                  @click="handleSenderDelete" />
                <ar-simple-button
                  v-if="showEmailSenderForm && !creatingNewSender"
                  text="Update"
                  outlined
                  :style="{ width: '120px' }"
                  text-weight="bold"
                  :custom-style-attributes="{
                      height: '40px',
                    }"
                  :disabled="!isScratchEmailSenderComplete || isDeletingMessageSender"
                  :loading="isPatchingMessageSenders"
                  @click="handleSenderPatch"
                />
                <ar-simple-button
                  v-if="showEmailSenderForm && creatingNewSender"
                  text="Create"
                  outlined
                  :style="{ width: '120px' }"
                  text-weight="bold"
                  :custom-style-attributes="{
                      height: '40px',
                    }"
                  :disabled="!isScratchEmailSenderComplete"
                  :loading="isCreatingMessageSender"
                  @click="handleSenderPost"
                />
              </div>
            </div>
          </div>

          <!-- SMS Settings -->
          <div
            class="tick-section"
            data-test-id="tick-section-sms"
            @click="smsEnabled = !smsEnabled"
            :style="{
              cursor: 'pointer',
            }"
          >
            <ar-checkbox
              ref="smsCheckbox"
              :value="smsEnabled"
              @input="val => { smsEnabled = val }"
              class="tick-section-checkbox"
              label="SMS"
              :label-typography-props="{
                weight: 'bold',
              }"
              icon-name="sms"
              :icon-props="{
                width: '18px',
                color: $arStyle.color.sms,
              }"
              :style="{
                pointerEvents: 'none'
              }"
            />
          </div>
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'footer',
          $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        ]">
        <ar-simple-button
          v-if="step === 1"
          text="Next"
          :disabled="disableNextButton"
          v-tooltip.top="{
            content: disableNextButton && disableReason ? disableReason : null
          }"
          :style="{ width: '108px' }"
          :loading="isValidatingNewMessageList"
          data-test-id="create-list-next-step-button"
          @click="handleNextStep"
        />
        <ar-simple-button
          v-if="step === 2"
          text="Save List"
          :disabled="disableSaveListButton"
          v-tooltip.top="{
            content: disableSaveListButton && disableReason ? disableReason : null
          }"
          :loading="isCreatingMessageList"
          :style="{ width: '108px' }"
          data-test-id="create-list-save-button"
          @click="handleSaveListClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { clone } from '@/utils/helpers';

const createScratchEmailSender = () => {
  return {
    property: null,
    additionalInfo: {
      senderName: null,
      businessAddress: null,
    }
  }
}

export default {
  name: 'CreateMessagingListModal',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isAutomation: {
      type: Boolean, default: false
    },
  },

  data() {
    return {
      isDynamicList: false,
      messageListName: '',
      step: 1,
      emailEnabled: false,
      facebookMessengerEnabled: false,
      smsEnabled: false,
      facebookPages: [],
      facebookPageSelection: null,
      filters: [],
      filterGroupOid: null,
      arEnableEmailSending: process.env.arEnableEmailSending,
      showEmailSenderForm: false,
      senderOid: null, // The oid we'll be attaching to the message list
      creatingNewSender: false,
    };
  },

  computed: {
    ...mapState({
      savedSegmentList: state => state.segment.savedSegmentList,
      newMessageListValidateResult: state => state.messageList.newMessageListValidateResult,
      isValidatingNewMessageList: state => state.messageList.isValidatingNewMessageList,
      isPatchingMessageSenders: state => state.messageSender.isPatchingMessageSenders,
      isCreatingMessageSender: state => state.messageSender.isCreatingMessageSender,
      isDeletingMessageSender: state => state.messageSender.isDeletingMessageSender,
      messageSenders: state => state.messageSender.messageSenders,
      scratchEmailSender: state => state.messageSender.scratchEmailSender,
      isCreatingMessageList: state => state.messageList.isCreatingMessageList,
      restrictedDomainError: state => state.messageSender.restrictedDomainError,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      isScratchEmailSenderComplete: 'messageSender/isScratchEmailSenderComplete',
    }),
    currentlySelectedEmailMessageSender() {
      if (!this.senderOid) { return null; }
      return this.messageSenders.find( sender => sender.oid === this.senderOid) || null;
    },
    selectedFilterIndex() {
      return this.savedSegmentList.findIndex(({ value }) => {
        return value === this.filterGroupOid;
      });
    },
    facebookPageId() {
      return this.facebookPageSelection ? this.facebookPageSelection.id : null;
    },
    disableReason() {
      if (this.step === 1) {
        if (!this.messageListName) {
          return 'Your list must have a name before continuing';
        }
        if (this.isDynamicList && !this.filterGroupOid) {
          return 'You must select a segment before continuing';
        }
      } else if (this.step === 2) {
        if (this.emailEnabled) {
          if (
            !this.senderOid
          ) {
            return `You must select a sender before continuing`;
          }
        }
        if (this.facebookMessengerEnabled && !this.facebookPageId) {
          return `You must select a Facebook Page before continuing`;
        }
        if (!this.facebookMessengerEnabled && !this.emailEnabled && !this.smsEnabled) {
          return `You must select a message channel before continuing`;
        }
      }

      return null;
    },
    disableNextButton() {
      if (!this.messageListName) {
        return true;
      }
      if (this.isDynamicList && !this.filterGroupOid) {
        return true;
      }
      return false;
    },
    disableSaveListButton() {
      if (
        this.emailEnabled
        && !this.senderOid
      ) {
        return true;
      }
      if (this.facebookMessengerEnabled && !this.facebookPageId) {
        return true;
      }
      if (!this.facebookMessengerEnabled && !this.emailEnabled && !this.smsEnabled) {
        return true;
      }
      return false;
    },
    hasBackLink() {
      return this.step > 1 ? true : false;
    },
    title() {
      return this.step === 1 ? 'Create a new list' : 'List settings';
    },
    hasNameError() {
      return this.newMessageListValidateResult.duplicatedName;
    },
    hasFilterGroupError() {
      return this.newMessageListValidateResult.duplicatedFilterGroupOid;
    },
  },

  watch: {
    isShow(newIsShow) {
      if (newIsShow) {
        this.resetModalSettings();
      }

      if (!newIsShow) {
        this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
      }
    },
    isDynamicList(newIsDynamicList) {
      if (!newIsDynamicList) {
        this.filterGroupOid = null;
      }
    },
    emailEnabled(val) {
      if(!val) {
        this.showEmailSenderForm = false;
      }
    },
  },

  mounted() {
    this['messageSender/FETCH_MESSAGE_SENDERS']();
    this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
  },

  methods: {
    ...mapMutations([
      'messageList/RESET_NEW_MESSAGE_LIST_VALIDATE_RESULT',
      'messageSender/SET_SCRATCH_EMAIL_SENDER',
      'messageSender/RESET_SCRATCH_EMAIL_SENDER',
      'messageSender/PATCH_SCRATCH_EMAIL_SENDER',
      'messageSender/RESET_RESTRICTED_DOMAIN_ERROR',
    ]),
    ...mapActions([
      'FETCH_INTEGRATIONS',
      'segment/FETCH_SEGMENTS',
      'messageList/CREATE_MESSAGE_LIST',
      'messageList/VALIDATE_NEW_MESSAGE_LIST',
      'messageSender/UPDATE_MESSAGE_SENDER',
      'messageSender/ADD_MESSAGE_SENDER',
      'messageSender/DELETE_MESSAGE_SENDER',
      'messageSender/FETCH_MESSAGE_SENDERS',
      'messageSender/RESEND_EMAIL_VERIFICATION',
      'automation/CREATE_MESSAGE_LIST',
    ]),

    handleClose() {
      this.$emit('close');
    },

    messageListNameFormatter(input) {
      let oldVal = input.value || '';
      let newVal = oldVal;
      let startPosition = input.selectionStart || 0;
      newVal = oldVal.replace(new RegExp('"', "g"), "").replace(new RegExp(",", "g"), "");

      let newPosition = input ? startPosition : null;
      if (input && oldVal.length > newVal.length) {
        // If oldLength > newLength
        // Cursor position should be startPosition - 1
        newPosition = startPosition - 1;
      } else if (input && oldVal.length < newVal.length) {
        // Else if oldLength < newLength
        // New position should be startPosition + 1
        newPosition = startPosition + 1;
      }

      return {
        value: newVal,
        position: newPosition
      };
    },

    async resetModalSettings() {
      this.messageListName = '';
      this.filterGroupOid = null;
      this.emailEnabled = false;
      this.smsEnabled = false;
      this.facebookMessengerEnabled = false;
      this.step = 1;
      this.messageListType = null;
      this.showEmailSenderForm = false;
      this.creatingNewSender = false;
      this.senderOid = null;

      this.fetchFacebookPageItems();
      this['segment/FETCH_SEGMENTS']({
        fromPlace: 2
      });
      this['messageList/RESET_NEW_MESSAGE_LIST_VALIDATE_RESULT']();

      // Auto focus name input
      await this.$nextTick();
      this.$refs['name-input'].focus();
    },

    async fetchFacebookPageItems() {
      // only fetch FBM pages when feature is enabled

      const facebookMessengerIntegrations = await this.FETCH_INTEGRATIONS({
        top: 1,
        filters: {
          expressions: [{
            key: 'provider',
            value: 'facebook',
            operator: '=',
          }, {
            key: 'app',
            value: 'messenger',
            operator: '=',
          }, {
            key: 'status',
            value: 'completed',
            operator: '=',
          }],
          logicalOperators: ['AND', 'AND'],
        },
        orderby: "sysMtime desc",
      });

      if (!facebookMessengerIntegrations[0]) {
        this.facebookPages = [];
        return;
      }

      const pages = Object.entries(
        facebookMessengerIntegrations[0].integration.pages
      ).map(([id, pageObject]) => ({
        id,
        name: pageObject.name,
        imageSrc: pageObject.profileImageUrl
      }));

      if (pages.length === 1) {
        this.facebookPages = pages;
        this.facebookPageSelection = pages[0];
        return;
      } if(pages.length > 1) {
        this.facebookPages = pages;
        return;
      } else {
        this.facebookPages = [];
      }
    },

    handleFilterSelect(item) {
      this.filterGroupOid = item.oid;
    },

    handleFacebookPageSelect(item) {
      this.facebookPageSelection = item;
    },

    async handleNextStep() {
      const validationCompleted = await this['messageList/VALIDATE_NEW_MESSAGE_LIST']({
        name: this.messageListName,
        filterGroupOid: this.filterGroupOid,
      });
      if (!validationCompleted) {
        return;
      }

      if (this.newMessageListValidateResult.duplicatedName || this.newMessageListValidateResult.duplicatedFilterGroupOid) {
        return;
      }
      this.step = 2;
    },

    async handleSaveListClick() {
      const isValid = await this.$validator.validateAll();
      if(!isValid) return;

      const payload = {
        name: this.messageListName,
        sms: this.smsEnabled,
        email: this.emailEnabled,
        facebookMessenger: this.facebookMessengerEnabled,
        meta: {},
        filterGroupOid: this.filterGroupOid,
        isAutomation: this.isAutomation,
      };
      if (this.emailEnabled) {
        payload.meta.email = {
          promoterPropertyOid: this.senderOid,
        };
      }
      if (this.facebookMessengerEnabled) {
        payload.meta.facebookMessenger = {
          pageId: this.facebookPageSelection.id,
          pageName: this.facebookPageSelection.name,
          imageSrc: this.facebookPageSelection.imageSrc,
        };
      }
      if(this.isAutomation){
          this['automation/CREATE_MESSAGE_LIST'](payload);
      } else {
        this['messageList/CREATE_MESSAGE_LIST'](payload);
      }
    },


    async retryEmailVerification() {
      if (!this.senderOid) return;
      this['messageSender/RESEND_EMAIL_VERIFICATION'](this.senderOid);
    },

    handleBackPress() {
      if (this.showEmailSenderForm) {
        this.handleBackFromSenderEdit()
      } else {
        this.step -= 1;
      }
    },


    // ----------------------------
    // Sender modification methods
    // ----------------------------
    handleSenderSelect(sender) {
      this.showEmailSenderForm = false;
      this.senderOid = sender.oid;
    },

    handleSenderEdit(sender) {
      this['messageSender/SET_SCRATCH_EMAIL_SENDER'](sender);
      this.showEmailSenderForm = true;
      this.creatingNewSender = false;
    },

    handleSenderCreateNew() {
      this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
      this['messageSender/RESET_SCRATCH_EMAIL_SENDER']();
      this.showEmailSenderForm = true;
      this.creatingNewSender = true;
    },

    handleScratchEmailSenderPatch(changes) {
      this['messageSender/PATCH_SCRATCH_EMAIL_SENDER'](changes);
    },

    async handleSenderDelete() {
      const oidToDelete = this.scratchEmailSender.oid;
      const succeed = await this['messageSender/DELETE_MESSAGE_SENDER'](oidToDelete);
      if (succeed) {
        if (oidToDelete === this.senderOid) {
          this.senderOid = null;
        }
        this.senderOid = null;
        this.handleBackFromSenderEdit();
      }
    },

    async handleSenderPatch() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const succeed = await this['messageSender/UPDATE_MESSAGE_SENDER'](this.scratchEmailSender);
      if (succeed) {
        this.handleBackFromSenderEdit();
      }
    },

    async handleSenderPost() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }
      const succeed = await this['messageSender/ADD_MESSAGE_SENDER'](this.scratchEmailSender);
      if (succeed) {
        this.handleBackFromSenderEdit();
        this.senderOid = succeed.oid;
      }
    },

    handleBackFromSenderEdit() {
      this['messageSender/RESET_RESTRICTED_DOMAIN_ERROR']();
      this.showEmailSenderForm = false;
      this.creatingNewSender = false;
    },

  },
}
</script>

<style lang="scss" scoped>
.create-messaging-list-modal {
  .body {
    padding-bottom: 10px;

    .tick-section {
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      margin-bottom: 10px;

      .tick-section-checkbox {
        height: 50px;
        padding-left: 15px;
      }

      .details-section {
        margin-left: 54px;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 8px;

        &.xs-max {
          margin-left:12px;
          margin-right:12px;
          margin-bottom: 16px;
        }
      }

      .edit-sender-section {
        padding: 12px 18px 18px 56px;
        .input-section {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          padding-top: 10px;

          .label {
            margin-bottom: 12px;
          }

          .input {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;

    &.xs-max {
      padding: 12px;
      height: 74px;
    }
  }
}
</style>
