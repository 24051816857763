<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-moshtix-access-token-modal"
      header="Connect To Moshtix"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-moshtix-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div
          class="u-display-flex u-margin-bottom-3"
        >
          <ar-text
            size="xs"
            text="Subscribe associated contacts to list (optional)"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-icon
            v-tooltip.top="{
              content: `Automatically subscribe all attendees of events from this Moshtix account.`,
            }"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-field
          :error-messages="[
            veeErrors.first('messageList')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            v-model="messageList"
            :channels="['email']"
          />
        </ar-field>
        <ar-field
          label="Username"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="username"
            name="username"
            :placeholder="usernamePasswordDisabled ? '' : 'Please enter your Moshtix username'"
            :disabled="usernamePasswordDisabled"
            @input="onUsernameInput"
          />
        </ar-field>
        <ar-field
          label="Password"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="password"
            name="password"
            type="password"
            :placeholder="usernamePasswordDisabled ? '' : 'Please enter your Moshtix password'"
            :disabled="usernamePasswordDisabled"
            @input="onPasswordInput"
          />
        </ar-field>
      </div>
      <div
        slot="body"
        :class="[
          'ask-moshtix-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
         <ar-text
            class="u-margin-top-2"
          size="xs"
          text="Or instead"
          weight="bold"
          :style="{
            color: $arStyle.color.blueGrey800,
          }"
        />
        <ar-field
          label="Access Token"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="accessToken"
            name="accessToken"
            type="password"
            :placeholder="accessTokenDisabled ? '' : 'Please enter your Moshtix access token'"
            :disabled="accessTokenDisabled"
            @input="onAccessTokenInput"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'ask-moshtix-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: null,
      password: null,
      accessToken: null,
      messageList: null
    };
  },

  computed: {
    confirmButtonDisabled() {
      if (this.accessToken) {
        return false;
      }
      else {
        return !this.username || !this.password;
      }
    },
    usernamePasswordDisabled() {
      return !!this.accessToken && this.accessToken.length > 0;
    },
    accessTokenDisabled() {
      return (!!this.username && this.username.length > 0) || (!!this.password && this.password.length > 0);
    }
  },

  watch: {
    isShow(val) {
      if (val) {
        this.username = null;
        this.password = null;
        this.accessToken = null;
        this.messageList = null;
      }
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', { 
        username: this.username,
        password: this.password,
        accessToken: this.accessToken,
        messageList: this.messageList
      });
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    onUsernameInput(input) {
      if (!!input) {
        this.accessToken = null;
      }
    },
    onPasswordInput(input) {
      if (!!input) {
        this.accessToken = null;
      }
    },
    onAccessTokenInput(input) {
      if (!!input) {
        this.username = null;
        this.password = null;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.ask-moshtix-access-token-modal {
  .ask-moshtix-access-token-modal-body {
    padding: 20px 30px 30px;
  }
  .ask-moshtix-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
