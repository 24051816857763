import { ActionTree } from 'vuex';
import moment from 'moment';
import { RootState } from '@/store/modules/types';
import { getCookie } from '../../../utils/cookie'
import { isErrorStatus } from '@/api/utils';
import { CustomApiError } from '@/api/types';
import { fixedEncodeURIComponent } from '@/utils/helpers'
import mfa from "~/pages/settings/mfa";

export const authActions: ActionTree<AuthState, RootState> = {
  async REGISTER_TO_WAVE({ commit }, { emailAddress, password, firstName, lastName, company, mobileNumber, country }) {
    try {
      commit('SET_IS_DOING_SIGNUP', true);
      const successRedirectUri = await this.$api.auth.registerToWaveApp({ emailAddress, password, firstName, lastName, company, mobileNumber, country });
      window.location.href = successRedirectUri;
    } catch(e: any) {
      console.error(e);
      if (e.response && e.response.status === 409) {
        this.$arNotification.push({
          type: 'error',
          message: 'There is already an account with this email address,',
          link: 'Login here',
        });
      } else {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to create an account',
        });
      }
      return false;
    } finally {
      commit('SET_IS_DOING_SIGNUP', false);
    }
  },
  async SIGNUP(
    { commit },
    { emailAddress, password, firstName, lastName, company, mobileNumber, country, companyCategory }
    ) {
    try {
      commit('SET_IS_DOING_SIGNUP', true);

      let pardotCookie = null;

      if (!!process.env.arSalesforceID) {
        pardotCookie = getCookie(`visitor_id${process.env.arSalesforceID}`);
      }

      const { headers, status, data } = await this.$axios.post('/signup', {
        emailAddress,
        password,
        firstName,
        lastName,
        company,
        mobileNumber,
        country,
        companyCategory,
        pardotCookie
      });

      if (status !== 201) {
        throw new Error("Failed to signup");
      }

      commit('SET_PROMOTER_ACCOUNT', data.account);
      commit('SET_X_AUTH_TOKEN', headers["x-auth-token"]);
      window.sessionStorage.setItem('x-auth-token', headers["x-auth-token"]);

      return true;
    } catch(e: any) {
      console.error(e);
      if (e.response && e.response.status === 409) {
        this.$arNotification.push({
          type: 'error',
          message: 'There is already an account with this email address,',
          link: 'Login here',
        });
      } else if (e.response && e.response.status === 422) {
        this.$arNotification.push({
          type: 'error',
          message: 'This email address has already been previously invited.',
        });
        this.$router.push(`/authenticate/invite-sent?email=${fixedEncodeURIComponent(emailAddress)}`);
      } else {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to create an account',
        });
      }
      return false;
    } finally {
      commit('SET_IS_DOING_SIGNUP', false);
    }
  },
  async LOGIN({ getters, commit }, { emailAddress, password, targetAccountEmailAddress, masquerade, mfaCode }) {
    try {
      commit('SET_IS_DOING_LOGIN', true);

      let url;
      let payload;
      let pardotCookie = null;

      if (!!process.env.arSalesforceID) {
        pardotCookie = getCookie(`visitor_id${process.env.arSalesforceID}`);
      }

      if (masquerade) {
        url = '/session-masquerade';
        payload = { emailAddress, password, targetAccountEmailAddress, pardotCookie };
      } else {
        url = '/session';
        payload = { emailAddress, password, pardotCookie, otpToken: mfaCode };
      }

      const { headers, status, data } = await this.$axios.post(url, payload);

      if (status !== 201) {
        throw new Error("Failed to login");
      }

      commit('SET_PROMOTER_ACCOUNT', data.account);
      commit('SET_SAMPLING', data.account.promoterOid === 18);
      commit('SET_X_AUTH_TOKEN', headers["x-auth-token"]);
      window.sessionStorage.setItem('x-auth-token', headers["x-auth-token"])

      if (data.account?.mfaForce && !data.account?.mfaEnabled) {
        if (this.$router.currentRoute.path !== '/authenticate/mfa-setup') this.$router.push(`/authenticate/mfa-setup`);
        return true
      }

      if (getters.isAdminAccount) {
        this.$router.push(`/admin/dashboard`);
      } else {
        this.$router.push(`/audience`);
      }
      return true
    } catch (e: any) {
      console.error(e);
      if (e.response && e.response.status === 401 && e.response?.data?.result === 'Missing MFA token') {
        return 'require-otp-code'
      }
      if (e.response && e.response.status === 403 && e.response?.data?.result === 'Setup MFA device') {
        this.$arNotification.push({ type: 'error', message: 'Finish MFA', timeout: 5000});
        return 'mfa-setup'
      }
      if (e.response && e.response.status === 429) {

        // The server says we've tried too many times; the server will return the time to wait
        // in seconds before we can start the next request
        const { nextAllowedLoginAttemptTime } = e.response.data.message.details;
        commit('SET_NEXT_LOGIN_RETRY_TIME', moment.utc(nextAllowedLoginAttemptTime).toDate().getTime());
        this.$arNotification.push({ type: 'error', message: 'Too many login attempts', timeout: 5000});
      } else if (e.response && e.response.status === 402) {
        if (e.response?.data?.result === 'Your MFA code is invalid, contact support@audiencerepublic.com') {
          this.$arNotification.push({ type: 'error', message: 'Your MFA code is invalid, contact support@audiencerepublic.com'});
          return false
        }
        this.$arNotification.push({ type: 'error', message: 'Your account has been disabled, contact support@audiencerepublic.com'});
      } else {
        const { remainingAttempts } = e.response.data.result
        this.$arNotification.push({ type: 'error', message: `Failed to login, remaining attempts ${remainingAttempts}`});
      }
      return false
    } finally {
      commit('SET_IS_DOING_LOGIN', false);
    }
  },
  async LOGOUT({ commit, rootGetters }) {
    window.sessionStorage.clear();
    commit('RESET_STATE', null, { root: true });
    this.$router.push({
      path: '/authenticate/login',
    });
  },
  async VERIFY_TOKEN({ commit, getters }) {
    try {
      const { data, headers } = await this.$axios.get('/session');
      commit('SET_PROMOTER_ACCOUNT', data);
      commit('SET_X_AUTH_TOKEN', window.sessionStorage.getItem('x-auth-token'));

      if (data.mfaForce && !data.mfaEnabled) {
        if (this.$router.currentRoute.path !== '/authenticate/mfa-setup') this.$router.push(`/authenticate/mfa-setup`);
        return true
      }

      if (this.$router.currentRoute.path === '/authenticate/login' || this.$router.currentRoute.path === '/authenticate/mfa-setup') {
        if (getters.isAdminAccount) {
          this.$router.push(`/admin/dashboard`);
        } else {
          this.$router.push(`/audience`);
        }
      }
      return true;
    } catch (e) {
      console.error(e);
      // Important, if x-auth-token is not valid, remove it otherwise it will be an infinite loop
      window.sessionStorage.clear();
      this.$router.push('/authenticate/login');

      return false;
    }
  },
  async CHANGE_PASSWORD({ commit, state }, { oldPassword, password }) {
    if (!state.account) { return null; }
    const { promoterOid, oid } = state.account;
    try {
      commit('SET_IS_CHANING_PASSWORD', true);
      await this.$axios.post(`/promoter/${promoterOid}/account/${oid}/password-change`, {
        oldPassword,
        password,
      });
      this.$arNotification.push({
        type: 'success',
        message: 'Successfully changed password',
      });
      return true;
    } catch (e: any) {
      console.error(e);
      if (e.response && e.response.status === 403) {
        this.$arNotification.push({
          type: 'error',
          message: 'Password change failed. Please ensure details are correct',
        });
      } else {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to change password',
        });
      }
      return false;
    } finally {
      commit('SET_IS_CHANING_PASSWORD', false);
    }
  },
  async SEND_RESET_PASSWORD_EMAIL({ commit }, emailAddress) {
    try {
      commit('SET_IS_SENDING_RESET_PASSWORD_EMAIL', true);
      const { data: { nextResetRequestTime } } = await this.$axios.post('/request-password-reset', { emailAddress });
      // Start to count down for availability of next request
      commit('SET_NEXT_SEND_RESET_PASSWORD_EMAIL_RETRY_TIME', moment.utc(nextResetRequestTime).toDate().getTime());

      this.$arNotification.push({
        type: 'success',
        message: 'You will receive an email with instructions about how to reset your password in a few minutes.',
      });
    } catch (e: any) {
      console.error(e);
      if (e.response && e.response.status === 429) {
        // Edge case, if user skip our mechanism to send request before it's available
        commit('SET_NEXT_SEND_RESET_PASSWORD_EMAIL_RETRY_TIME', moment.utc(e.response.data.message.details.nextResetRequestTime).toDate().getTime());
      } else {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to send reset instructions',
        });
      }
    } finally {
      commit('SET_IS_SENDING_RESET_PASSWORD_EMAIL', false);
    }
  },
  async RESET_FORGOTTEN_PASSWORD({ commit, getters }, { password, verifyToken }) {
    try {
      commit('SET_IS_RESETTING_PASSWORD', true);
      const { headers, data } = await this.$axios.post('/password-reset', {
        password,
        verifyToken,
      });

      commit('SET_PROMOTER_ACCOUNT', data.account);
      commit('SET_X_AUTH_TOKEN', headers["x-auth-token"]);
      window.sessionStorage.setItem('x-auth-token', headers["x-auth-token"]);

      if (getters.isAdminAccount) {
        this.$router.push(`/admin/dashboard`);
      } else {
        this.$router.push(`/audience`);
      }
      this.$arNotification.push({
        type: 'success',
        message: 'Success! Your password has been reset',
      });
    } catch (e) {
      console.error(e);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to reset password',
      });
    } finally {
      commit('SET_IS_RESETTING_PASSWORD', false);
    }
  },
  async VERIFY_EMAIL_ADDRESS({ commit, getters }, { emailAddress, verifyToken }) {
    try {
      commit('SET_IS_VERIFYING_EMAIL_ADDRESS', true);
      const { headers, data } = await this.$axios.post('/email-verify', { emailAddress, verifyToken });

      commit('SET_PROMOTER_ACCOUNT', data.account);
      commit('SET_X_AUTH_TOKEN', headers["x-auth-token"]);
      window.sessionStorage.setItem('x-auth-token', headers["x-auth-token"]);

      // Let's always to go to message center if you're normal promoter
      if (getters.isAdminAccount) {
        this.$router.push(`/admin/dashboard`);
      } else {
        this.$router.push(`/message-center/messages`);
      }

      this.$arNotification.push({
        type: 'success',
        message: 'Success! Your email address has been verified',
      });
    } catch (e: any) {
      console.error(e);
      if (e.response && e.response.status === 500) {
        // It it's 500, that means unexpected error happened on server side
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to verify your email',
        });
      } else if (e.response && e.response.status === 403) {
        // It it's 500, that means unexpected error happened on server side
        this.$arNotification.push({
          type: 'error',
          message: 'The verify token is invalid',
        });
      } else {
        this.$arNotification.push({
          type: 'error',
          message: 'Your verification link has expired. You need to log and resend the verification email',
          timeout: 10000,
        });
        this.$router.push({
          path: '/authenticate/login',
          query: { emailAddress },
        });
      }
    } finally {
      commit('SET_IS_VERIFYING_EMAIL_ADDRESS', false);
    }
  },

  // Fetch Promoter region settings
  async FETCH_REGION_SETTINGS(
    { commit, state },
  ) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      const data = await this.$api.promoterProperty.get(promoterOid, 'promoter-region-settings')

      commit('SET_REGION_SETTINGS', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoter region settings',
      });
    }
  },
  // Create new Promoter region settings
  async CREATE_REGION_SETTINGS(
    { commit, state },
    { property },
  ) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      commit('SET_IS_UPDATING_REGION_SETTINGS', true)

      const data = await this.$api.promoterProperty.post(promoterOid, {
        type: 'promoter-region-settings',
        property: property.defaultTimezone,
        allowMany: false,
        promoterOid,
        additional_info: property
      })

      commit('SET_REGION_SETTINGS', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new promoter region settings',
      });
    } finally {
      this.$arNotification.push({
        type: 'success',
        message: 'Successfully updated region settings',
      });
      commit('SET_IS_UPDATING_REGION_SETTINGS', false)
    }
  },
  // Update new Promoter region settings
  async UPDATE_REGION_SETTINGS(
    { commit, state },
    { propertyOid, property },
  ) {
    if (!state.account) return;
    const { promoterOid } = state.account;
    const { regionSettings } = state;

    try {
      commit('SET_IS_UPDATING_REGION_SETTINGS', true)

      const data = await this.$api.promoterProperty.patch(promoterOid, propertyOid, {
        type: 'promoter-region-settings',
        property: property.defaultTimezone,
        allowMany: false,
        promoterOid,
        additional_info: property
      })

      // Set the store manually because patch doesn't return anything
      const newRegionSettings = {
        ...regionSettings,
        property: property.defaultTimezone,
        additionalInfo: {
          ...regionSettings.additionalInfo,
          defaultTimezone: property.defaultTimezone,
          currency: property.currency,
        }
      }

      commit('SET_REGION_SETTINGS', newRegionSettings);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new promoter region settings',
      });
    } finally {
      this.$arNotification.push({
        type: 'success',
        message: 'Successfully updated region settings',
      });
      commit('SET_IS_UPDATING_REGION_SETTINGS', false)
    }
  },

  async FETCH_PROMOTER (
    { rootState, commit }
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;

    try {
      const { data } = await this.$axios.get(`/promoter/${promoterOid}`);

      commit('SET_PROMOTER', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoter',
      });
    }
  },

  async FETCH_PROMOTER_ACCOUNTS(
    { rootState, commit },
    { top = 20, skip = 0, searchString = '', orderByString = 'oid%20asc', arepAdminOid = null },
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;

    const selectString = "firstName,lastName,emailAddress,sysCtime,promoterOid,defaultUserRoleOid,promoterUserRoleOid"
    const filterString = fixedEncodeURIComponent(`sysActivep AND (emailAddress ILIKE "%${searchString}%" OR firstName ILIKE "%${searchString}%" OR emailAddress ILIKE "%${searchString}%" OR firstName ILIKE "%${searchString}%")`)
    const arepAdminFilter = arepAdminOid ? fixedEncodeURIComponent(` AND defaultUserRoleOid != ${arepAdminOid}`) : ''

    try {
      commit('SET_IS_FETCHING_USERS', true)
      const { data } = await this.$axios.get(`/promoter/${promoterOid}/account?$select=${selectString}&$top=${top}&$skip=${skip}&$orderby=${orderByString}&$filter=${filterString}${arepAdminFilter}`);

      commit('SET_USERS', data);

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_USERS', false)
    }
  },
  async FETCH_INVITEES(
    {rootState, commit}
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;
    if (!promoterOid) return;
    try {
      commit('SET_IS_FETCHING_INVITEES', true)
      const { data, status } = await this.$api.auth.fetchInvitees(promoterOid)
      commit('SET_INVITEES', data)
    } catch (e:any ) {
      console.error(e);
    } finally {
      commit('SET_IS_FETCHING_INVITEES', false)
    }
  },
  async DELETE_INVITE(
    {rootState, commit},
    {inviteOid}
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;
    if (!promoterOid) return;
    if (!inviteOid) return;

    try {
      const { data, status } = await this.$api.auth.deleteUserInvite(promoterOid, inviteOid)
      return data;
    } catch (e) {
      console.error(e);
    }
  },
  // Default user roles
  async FETCH_DEFAULT_USER_ROLES(
    { rootState, commit },
    { searchString = null }
  ) {
    if (!rootState.auth.account) return;

    try {
      const { data, status } = await this.$axios.get(`/user-role`, {
        params: {
          $filter: searchString ? `name ILIKE "%${searchString}%" AND name != "AREP Admin"` : null
        }
      })
      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetching default user roles error',
          message: `Error fetching default user roles`,
          status,
        };
        throw apiError;
      }

      commit('SET_DEFAULT_USER_ROLES', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch default user roles',
      });
    }
  },
  // Promoter user roles
  async FETCH_PROMOTER_USER_ROLES(
    { rootState, commit },
    { searchString = null },
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;

    try {
      const { data, status } = await this.$axios.get(`/promoter/${promoterOid}/user-role`, {
        params: {
          $filter: searchString ? `name ILIKE "%${searchString}%"` : null
        }
      })
      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetching promoter user roles error',
          message: `Error fetching promoter roles for OID ${promoterOid}`,
          status,
        };
        throw apiError;
      }

      commit('SET_PROMOTER_USER_ROLES', data);
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch promoter user roles',
      });
    }
  },
  async UPDATE_PROMOTER_ACCOUNT(
    { rootState, dispatch, commit },
    { accountOid, promoterAccountObj }
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;

    try {
      const updatedData = {
        firstName: promoterAccountObj.firstName,
        lastName: promoterAccountObj.lastName,
        defaultUserRoleOid: promoterAccountObj.defaultUserRoleOid,
        promoterUserRoleOid: promoterAccountObj.promoterUserRoleOid,
      }
      await this.$axios.patch(`/promoter/${promoterOid}/manage-account/${accountOid}`, updatedData)
      commit('SET_PROMOTER_ACCOUNT_DATA', {
        ...updatedData,
        oid: accountOid,
      })
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to update accont',
      })
      return false
    }
  },
  async DELETE_PROMOTER_ACCOUNT(
    { rootState, commit },
    { accountOid }
  ) {
    if (!rootState.auth.account) return;
    const { promoterOid } = rootState.auth.account;

    try {
      await this.$axios.delete(`/promoter/${promoterOid}/manage-account/${accountOid}`)
      this.$arNotification.push({
        type: 'success',
        message: 'Account has been deleted'
      })
      commit('DELETE_PROMOTER_ACCOUNT', accountOid)
      return true
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to delete account',
      })
      return false
    }
  },
  async SEND_ACCOUNT_INVITE({ commit, getters, state }, { emailAddress, defaultUserRoleOid, promoterUserRoleOid }) {
    if (!state.account) return null
    const { promoterOid } = state.account;
    if (!promoterOid) return null
    try {
      const isSent =  await this.$api.auth.sendUserInvite({
        promoterOid,
        emailAddress,
        defaultUserRoleOid,
        promoterUserRoleOid
      })
      if (!!isSent) {
        this.$arNotification.push({
          type: 'success',
          message: `An invitation has been sent to ${emailAddress}`,
        });
      } else {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to send invite',
        });
      }
    } catch (e: any) {
      console.error(e)
      const internalErrorCode = e?.response?.data['internal-code'];
      const internalErrorDetails = e?.response?.data?.message?.details;
      const internalErrorSummary = e?.response?.data?.message?.summary;

      if (internalErrorCode === 'record-exists') {
        this.$arNotification.push({
          type: 'error',
          message: `Failed to send invite - ${internalErrorDetails}`,
        });
        return
      }
      this.$arNotification.push({
        type: 'error',
        message: `Failed to send invite - ${internalErrorSummary}`,
      });
    }
  },
  // Fetch and populate permissions for the currently logged in promoter_account
  async FETCH_PERMISSIONS({ commit, getters, state }) {
    if (!state.account) { return null; }
    const { promoterOid } = state.account;

    try {
      const data = await this.$api.auth.fetchPromoterAccountPermissions(promoterOid)
      commit('SET_PERMISSIONS', data)
      return data;
    } catch (e: any) {
      console.error(e);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch permissions',
      });
    }
  },
  async FINISH_INVITE({commit, state, getters}, validationField) {
    try {
      commit('SET_IS_FINISHING_INVITE', true);
      const session = await this.$api.auth.finishInvite(validationField)

      commit('SET_PROMOTER_ACCOUNT', session.account);
      commit('SET_X_AUTH_TOKEN', session.accessToken);
      window.sessionStorage.setItem('x-auth-token', session.accessToken);

      if (this.$router.currentRoute.path === '/authenticate/invite') {
        if (getters.isAdminAccount) {
          this.$router.push(`/admin/dashboard`);
        } else {
          this.$router.push(`/audience`);
        }
      }

      return session
    } catch (e: any) {
      console.error(e);
      // If the invited user hasn't finished registration successfully, we need to make sure that session storage is clear
      // Otherwise, the system will have an infinite loop if x-auth-token remains there
      window.sessionStorage.clear();
      console.error(e);
      this.$arNotification.push({
        type: 'error',
        message: 'We are unable to complete registration.',
        timeout: 10000,
      });

      return false;
    } finally {
      commit('SET_IS_FINISHING_INVITE', false);
    }
  },
  async VALIDATE_INVITE({commit, state}, validationField) {
    try {
      commit('SET_IS_VALIDATING_INVITE', true);
      const data = await this.$api.auth.validateInvite(validationField)
      return data
    } catch (e) {
      console.error(e);
      this.$arNotification.push({
        type: 'error',
        message: 'Your invitation link is no longer valid.',
        timeout: 10000,
      });
      return false;
    } finally {
      commit('SET_IS_VALIDATING_INVITE', false);
    }
  },
  async RESEND_INVITE({commit, state}, email) {
    if (!email) {
      console.error("No email present - cannot resend email")
      this.$arNotification.push({
        type: 'error',
        message: `Invite cannot be resent`,
        timeout: 10000,
      });
      return;
    }
    try {
      commit('SET_IS_VALIDATING_INVITE', true);
      const response = await this.$api.auth.resendInvite(email)

      return true
    } catch (e: any) {
      if (e.response.status === 429) {
        this.$arNotification.push({
          type: 'error',
          message: `Invite cannot be resent for ${email}. Try again after 5 minutes.`,
          timeout: 10000,
        });
        return false
      } else if (e.response.status === 404) {
        this.$arNotification.push({
          type: 'error',
          message: `Cannot find a matching invite. Please refresh the page and try again.`,
          timeout: 10000,
        });
        return false
      } else {
        this.$arNotification.push({
          type: 'error',
          message: e.message || `Cannot resend the invite to ${email}.`,
          timeout: 10000,
        });
      }

      return false;
    } finally {
      commit('SET_IS_VALIDATING_INVITE', false);
    }
  },

  async FETCH_TWILIO_SUBACCOUNT(
    { rootState, state, commit },
  ) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = state.account;

    commit('SET_IS_FETCHING_TWILIO_SUBACCOUNT', true)
    try {
      const data = await this.$api.twilioSubAccounts.getSubaccount(promoterOid);

      commit('SET_TWILIO_SUBACCOUNT', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_TWILIO_SUBACCOUNT', false)
    }
  },
  async FETCH_ALPHANUMERIC_SENDER(
    { rootState, state, commit },
  ) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = state.account;

    commit('SET_IS_FETCHING_SMS_SENDER_IDS', true)
    try {
      const data = await this.$api.twilioSenderIds.get(promoterOid);

      commit('SET_SMS_SENDER_IDS', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_SMS_SENDER_IDS', false)
    }
  },
  async FETCH_DOMAINS(
    { rootState, state, commit },
  ) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = state.account;

    commit('SET_IS_FETCHING_DOMAINS', true)
    try {
      const data = await this.$api.auth.fetchDomains(promoterOid);

      commit('SET_DOMAINS', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_DOMAINS', false)
    }
  },
  async DELETE_DOMAIN(
    { rootState, state, commit },
    { domainOid },
  ) {
    if (!rootState.auth.account) { return null; }
    if (!domainOid) return null
    const { promoterOid } = state.account;

    try {
      const deleted = await this.$api.auth.deleteDomainAuth(promoterOid, domainOid);

      const data = await this.$api.auth.fetchDomains(promoterOid);
      commit('SET_DOMAINS', data)

      return data
    } catch (error) {
      console.error(error);
    }
  },
  // Create new domain authentication
  async CREATE_DOMAIN_AUTH(
    { commit, state },
    { domain },
  ) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      commit('SET_IS_CREATING_DOMAIN_AUTH', true)

      const data = await this.$api.auth.createDomainAuth({
        promoterOid,
        domain,
      })

      commit('ADD_TO_DOMAINS', data);

      return data
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create new domain authentication',
      });
    } finally {
      this.$arNotification.push({
        type: 'success',
        message: 'Successfully created domain authentication',
      });
      commit('SET_IS_CREATING_DOMAIN_AUTH', false)
    }
  },
  // Create new domain authentication
  async VERIFY_DOMAIN_AUTH(
    { commit, state },
    { domain },
  ) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      commit('SET_IS_VALIDATING_DOMAIN', true)
      const data = await this.$api.auth.verifyDomainAuth({
        promoterOid,
        domain,
      })
      return data
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to verify new domain authentication',
      });
    } finally {
      commit('SET_IS_VALIDATING_DOMAIN', false)
      this.$arNotification.push({
        type: 'success',
        message: 'Domain submitted, verification may take up to 48 hours',
      });
    }
  },
  // Get Time to Value data
  async FETCH_TIME_TO_VALUE(
    { rootState, state, commit },
  ) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = state.account;

    commit('SET_IS_FETCHING_TIME_TO_VALUE', true)
    try {
      const data = await this.$api.auth.fetchTimeToValue(promoterOid);

      commit('SET_TIME_TO_VALUE', data)

      return data

    } catch (error) {
      console.error(error);
    } finally {
      commit('SET_IS_FETCHING_TIME_TO_VALUE', false)
    }
  },
  // Create new domain authentication
  async CREATE_TIME_TO_VALUE(
    { commit, state },
    { column },
  ) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      const data = await this.$api.auth.createTimeToValue(
        promoterOid,
        column
      )
      return data
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to create or update Time to Value item',
      });
    } finally {
      this.$arNotification.push({
        type: 'success',
        message: 'Time to Value successfully created/updated',
      });
    }
  },

  async SET_MFA_FORCE({}, { promoterOid, promoterAccountOid, value }) {
    try {
      await this.$api.auth.requestSetMfaForceForPromoter(promoterOid, promoterAccountOid, value )
      this.$arNotification.push({ type: 'success', message: 'MFA force settings saved' });
      return true
    } catch (e) {
      this.$arNotification.push({ type: 'error', message: 'Failed to save MFA force settings' });
      console.error(e)
      return false
    }
  },

  async DISABLE_MFA_FOR_PROMOTER({}, { promoterOid, promoterAccountOid }) {
    try {
      await this.$api.auth.deleteMfaForPromoter(promoterOid, promoterAccountOid )
      this.$arNotification.push({ type: 'success', message: 'MFA disabled' });
      return true
    } catch (e) {
      this.$arNotification.push({ type: 'error', message: 'Failed to disable MFA' });
      console.error(e)
      return false
    }
  },

  async DISABLE_MFA({ rootState, state }, { otp }) {
    if (!state.account) return;
    const { promoterOid, oid  } = state.account;

    try {
      await this.$api.auth.deleteMfa(promoterOid, oid, otp)
      this.$arNotification.push({ type: 'success', message: 'MFA disabled' });
      return true
    } catch (e) {
      this.$arNotification.push({ type: 'error', message: 'Failed to disable MFA' });
      console.error(e)
      return false
    }
  },

  async FETCH_MFA_CONFIGURE({ rootState, state, commit }) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      const data = await this.$api.auth.getMfaConfigure(promoterOid)
      return data
    } catch (e) {
      this.$arNotification.push({ type: 'error', message: 'Failed to get QR' });
      console.error(e)
      return false
    }
  },

  async SET_MFA_CONFIGURE({ rootState, state, commit }, { otp1, otp2 }) {
    if (!state.account) return;
    const { promoterOid } = state.account;

    try {
      const data = await this.$api.auth.setMfaConfigure(promoterOid, otp1, otp2)
      this.$arNotification.push({ type: 'success', message: 'MFA enabled' });
      return data
    } catch (e) {
      this.$arNotification.push({ type: 'error', message: 'Failed to enable MFA for this promoter. Please contact support@audiencerepublic.com for assistance.' });
      console.error(e)
      return false
    }
  }

};
