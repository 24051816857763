<template>
  <div v-if="links && links.length">
    <SelectSearchDropdown
        :default-label="placeholder"
        :selected-ids="selectedOid"
        :options="showedLinks"
        :is-loading="isFetchingMessageLinks"
        :is-empty="selectedLinks.length === 0"
        off-search
        @finish="handleClickFinish"
        @clickSelect="handleClickSelect"
        @clear="handleClickClear"
        @close="handleClose"
        @open="handeOpenDropdown"
        @load-more="fetchMessageLinksOptions"
    >
      <div class="select-link-dropdown-option" slot="item" slot-scope="{ option }">
        <div class="select-link-dropdown-option__wrapper">
          <ar-text
              class="select-link-dropdown-option__name"
              size="xs"
              :text="option.targetUrl || ''"
              multiple-lines
              :max-lines="1"
              line-height="18.2px"
          />
          <div @click="handleClickLink($event, option.targetUrl)" class="select-link-dropdown-option__link">
            <ar-icon
              name="external-link-bold"
              width="16px"
              height="16px"
            />
          </div>
        </div>
      </div>
    </SelectSearchDropdown>
  </div>
</template>

<script>
import SelectSearchDropdown from '../select-search-dropdown/SelectSearchDropdown.vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {displayDateRangeUSNoDay} from "~/utils/date";
import dayjs from "dayjs";
export default {
  name: 'SelectLinkDropdown',
  components: {
    SelectSearchDropdown,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      selectedOid: {},
      showedLinks: [],
    }
  },
  watch: {
    value: {
      handler: function () {
        this.restoreSelectedIdsFromValue()
      },
    }
  },
  mounted() {
    this['message/SET_MESSAGE_LINKS']([])
    this['message/SET_IS_NO_MORE_MESSAGE_LINKS'](false)
    this.fetchMessageLinksOptions();
  },

  computed: {
    ...mapState({
      links: state => state.message.messageLinks,
      isFetchingMessageLinks: state => state.message.isFetchingMessageLinks,
      selectedLinks: state => state.message.conversionSelectedLinks,
    }),

    placeholder() {
      if (this.selectedLinks.length === 1) return '1 link'
      return this.selectedLinks.length ? `${this.selectedLinks.length} links` : 'Select links...'
    },
  },

  methods: {
    ...mapMutations([
      'message/SET_MESSAGE_LINKS',
      'message/SET_IS_NO_MORE_MESSAGE_LINKS',
    ]),
    ...mapActions({
      fetchMessageLinks: 'message/FETCH_MESSAGE_LINKS',
    }),

    async fetchMessageLinksOptions() {
      await this.fetchMessageLinks(this.$route.params.oid)
      this.sortLinksBySelected()
    },

    handleClickFinish() {
      const oidArray = Object.entries(this.selectedOid)
        .filter(([key, value]) => value === true)
      const linkArray = []
      this.showedLinks.forEach((link) => {
        if (this.selectedOid[link.oid]) {
          linkArray.push(link)
        }
      })
      this.$emit('changeSelectedOids', linkArray)
    },

    handleClickSelect({ key, newValue }) {
      this.$set(this.selectedOid, key, newValue)
    },

    handleClickClear() {
      this.selectedOid = {}
    },

    handleClose() {
      this.restoreSelectedIdsFromValue()
    },

    restoreSelectedIdsFromValue() {
      this.selectedOid = {}
      this.value.forEach((link) => {
        this.$set(this.selectedOid, link.oid, true)
      })
    },

    handeOpenDropdown() {
      this.sortLinksBySelected()
    },

    sortLinksBySelected() {
      const mergedArray = [...this.selectedLinks]
      this.links.forEach((arr2Item) => {
        if (!mergedArray.find((arr1Item) => arr1Item.oid === arr2Item.oid)) {
          mergedArray.push(arr2Item);
        }
      });

      this.showedLinks = mergedArray
    },

    handleClickLink(event, link) {
      event.stopPropagation()
      window.open(link, '_blank');
    },
  },
}
</script>

<style lang="scss" scoped>
.select-link-dropdown-option {
  width: 100%;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link {
    margin-left: 12px;
    display: flex;
    align-items: center;
  }
}
</style>
