<template>
    <div class="message-constraint">
      <am2-message-select
        :value="value"
        @select="handleSelectClick"
        @clear="handleClear"
      />
    </div>
</template>

<script>
  export default {
    name: 'MessageConstraint',

    props: {
      constraintCondition: {
        type: String,
        default: 'all',
      },
      constraintValues: {
        type: Array,
        default: () => [],
      },
      filterConditionName: {
        type: String,
        default: null,
      },
      constraintDefaultOptions: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      value() {
        if (this.constraintValues && this.constraintValues.length > 0) {
          return this.constraintValues[0]
        }
        return null
      },
    },

    methods: {
      handleSelectClick(item) {
        const data = {
          key: 'true_to_all',
          values: [item.oid],
        };
        this.$emit('constraintUpdate', data);
      },

      handleClear() {
        const data = {
          key: 'true_to_all',
          values: [],
        };
        this.$emit('constraintUpdate', data);
      },
    }

  };
</script>

<style lang="scss" scoped>
  .message-constraint {
    width: 100%;
    display: flex;
    max-height: 40px;
    flex-direction: column;

    .select {
      height: 40px;
    }
  }
</style>
