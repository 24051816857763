<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="new-sub-account-modal"
      :header="`${title} Alphanumeric ID`"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'new-sub-account-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
        v-if="!!currentData"
      >
        <!--
          31 Oct 2023 ADR
          These will be used on a later date so we are to keep them for now.
        -->
        <!--
        <div
          class="new-sub-account-modal-body-row u-display-flex u-flex-flow-row"
        ><ar-field
            label="Alphanumeric Sender ID"
            :error-messages="[
              veeErrors.first('alphanumeric-sender-id'),
            ]"
          >
            <ar-input
              v-validate="'required|alphanumericSpace'"
              v-model="currentData.token"
              name="alphanumeric-sender-id"
              data-vv-name="alphanumeric-sender-id"
              data-vv-as="alphanumeric sender id"
              placeholder="Example SID"
            />

            <ar-text
              class="description"
              size="xs"
              text="Copy and paste the ID from Twilio"
              multiple-lines
              :style="{
                color: $arStyle.color.blueGrey700,
                marginTop: '8px',
              }"
            />
          </ar-field>
        </div> -->

        <ar-field
          label="Friendly name"
          class="new-sub-account-modal-body-row"
          :error-messages="[
            veeErrors.first('friendly-name'),
          ]"
        >
          <ar-input
            v-model="currentData.friendlyName"
            v-validate="'required|alphanumericSpace|notAllNumbers'"
            name="friendly-name"
            data-vv-name="friendly-name"
            data-vv-as="friendly name"
            placeholder="e.g Audience Republic"
            :maxLength="11"
          />

          <ar-text
            v-show="!veeErrors.first('friendly-name')"
            class="description"
            size="xs"
            text="Up to 11 characters long, upper and lower-case letters, numbers 0 through 9, and the space character. They cannot be numbers only."
            multiple-lines
            :style="{
              color: $arStyle.color.blueGrey700,
              marginTop: '8px',
            }"
          />
        </ar-field>
        <ar-field
          label="Alpha Sending Service SID"
          class="new-sub-account-modal-body-row"
          :error-messages="[
            veeErrors.first('message-service-sid'),
          ]"
        >
          <ar-input
            v-model="currentData.messageServiceSid"
            v-validate="'required'"
            name="alpha-send-service-sid"
            data-vv-name="alpha-send-service-sid"
            data-vv-as="alpha send service sid"
            placeholder="MGXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
          />

          <ar-text
            class="description"
            size="xs"
            text="Copy and paste the phone sending alpha send service SID here"
            multiple-lines
            :style="{
              color: $arStyle.color.blueGrey700,
              marginTop: '8px',
            }"
          />
        </ar-field>
        <ar-field
          label="Countries"
          class="new-sub-account-modal-body-row"
          :error-messages="[
            veeErrors.first('countries'),
          ]"
         >
          <div
            v-for="(country, index) in countries"
            :key="index"
          >
            <div class="u-display-flex u-margin-bottom-3">
              <ar-country-select
                :value="country"
                value-type="iso"
                :style="{
                width: '100%',
                height: '42px',
              }"
                @select="selection => { handleSelectCountry(selection.iso, index) }"
              />
              <ar-icon
                class="delete-button u-margin-left-2"
                name="discard"
                height="16px"
                :style="{cursor: 'pointer'}"
                :color="$arStyle.color.blueGrey600"
                @click="() => handleDeleteCountryButton(index)"
              />
            </div>
          </div>

          <ar-simple-button
            icon-name="plus"
            :icon-props="{
              width: '16px',
            }"
            outlined
            :style="{
              width: '100%',
            }"
            @click="handleAddCountryButton"
          />

          <ar-text
            class="description"
            size="xs"
            text="Pick the country or countries supported by this alpha sender"
            multiple-lines
            :style="{
              color: $arStyle.color.blueGrey700,
              marginTop: '8px',
            }"
          />
        </ar-field>
        <ar-snackbar
          v-if="mode === 'edit'"
          type="warning"
          message="Editing these details WILL NOT change what a customer sees. They MUST be edited in Twilio first."
        />
      </div>
      <div
        slot="footer"
        :class="[
          'new-sub-account-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-link-button
          text="Cancel"
          @click="handleCloseClick"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button
          text="Confirm"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>

import {clone, uniqueValuesFromArray} from "~/utils/helpers";

export default {
  name: 'TwilioSubAccountModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: false,
    },
  },
  data() {
    return {
      currentData: !!this.data ? { ...this.data } : {},
      countries: [],
    };
  },

  computed: {
    confirmButtonDisabled() {
      if (!this.currentData || !this.data) {
        return;
      }

      return (!this.currentData.friendlyName || this.currentData.friendlyName === '') ||
        (!this.currentData.messageServiceSid || this.currentData.messageServiceSid === '') ||
        this.countries.length === 0
    },
    title() {
      if (this.mode === 'edit') {
        return 'Edit'
      } else {
        return 'New'
      }
    }
  },

  watch: {
    data(val) {
      const countries = val?.countries.map(country => country.country) || []
      this.currentData = {
        ...val,
        countries
      }
      this.countries = clone(countries);
    }
  },

  methods: {
    async handleConfirmClick() {
      const isValidated = await this.$validator.validateAll();
      const uniqueCountries = uniqueValuesFromArray(this.countries)

      if (isValidated) {
        this.$emit('confirm', {
          ...this.currentData,
          countries: uniqueCountries
        });
        this.resetCurrentData()
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.resetCurrentData()
    },
    handleUserRoleFilterInput(searchString) {
      this.$emit('handleUserRoleFilterInput', searchString)
    },
    handleAddCountryButton() {
      this.countries.push('au')
    },
    handleSelectCountry(iso, index) {
      this.countries[index] = iso
    },
    handleDeleteCountryButton(index) {
      this.countries.splice(index, 1)
    },
    resetCurrentData() {
      this.currentData = {
        token: null,
        friendlyName: null,
        messageServiceSid: null
      }
      this.countries = []
    }
  },
};
</script>

<style lang="scss" scoped>
.new-sub-account-modal {
  .new-sub-account-modal-body {
    display: flex;
    gap: 24px;
    padding: 48px 32px;
    flex-direction: column;

    &-row {
      width: 100%;
      gap: 24px;

      [ar-widget-id=ar-field] {
        width: 100%;
      }
    }
  }

  .new-sub-account-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
