var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditableFrame',{staticClass:"birthday-trigger-wrapper",attrs:{"title":"Edit Trigger","subtitle":_vm.item.name,"has-save-button":"","save-button-disabled":_vm.savedButtonDisabled},on:{"close":function($event){return _vm.$emit('close')},"save":_vm.handleSave},nativeOn:{"click":function($event){return _vm.handleClickOutside.apply(null, arguments)}}},[_c('ar-text',{staticClass:"birthday-trigger-heading",attrs:{"size":"13px","text":"BIRTHDAY TRIGGER","weight":"bold","multiple-lines":"","max-lines":1,"letter-spacing":"1px"}}),_vm._v(" "),_c('div',{staticClass:"birthday-trigger-copy-wrapper"},[_c('ar-text',{staticClass:"birthday-trigger-copy",attrs:{"size":"13px","text":"When added to an automation, steps below this trigger will begin on the day of a fan's birthday.","weight":"normal","multiple-lines":"","letter-spacing":"1px"}}),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-8",attrs:{"label":"When will this trigger activate?"}},[_c('ar-select',{staticClass:"select-when-wrapper",attrs:{"items":_vm.selectableWhenOptions,"default-select-index":_vm.selectedWhenIndex ? _vm.selectedWhenIndex : 0,"hide-arrow":false,"dropdown-width":300},on:{"select":_vm.handleWhenSelect,"actionClick":_vm.handleWhenAction},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [_c('div',{class:[
              'u-display-flex',
              'u-align-items-center',
              'u-padding-x-4' ]},[_c('ar-text',{class:[
                'u-margin-left-2' ],attrs:{"text":selectedItemScope.item.name,"size":"xs"}})],1)]}},{key:"item",fn:function(itemScope){return [(itemScope.item.isAction)?_c('div',{class:[
              'u-display-flex',
              'u-align-items-center',
              'u-padding-x-4' ],style:({
              cursor: 'pointer',
              height: '50px',
              background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
            })},[_c('ar-text',{class:[
                'u-margin-left-2' ],style:({
                lineHeight: 1,
              }),attrs:{"text":itemScope.item.name,"size":"xs"}})],1):_vm._e()]}}])})],1),_vm._v(" "),(_vm.showTimeSelector)?_c('ar-field',{staticClass:"u-margin-top-8",attrs:{"label":_vm.selectTimeLabel}},[_c('ar-select',{staticClass:"select-time-wrapper",attrs:{"items":_vm.selectableTimeOptions[_vm.selectableWhenOptions[_vm.selectedWhenIndex].key],"default-select-index":_vm.selectedTimeIndex ? _vm.selectedTimeIndex : 0,"hide-arrow":false,"dropdown-width":300},on:{"select":_vm.handleTimeSelect,"actionClick":_vm.handleTimeAction},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [_c('div',{class:[
              'u-display-flex',
              'u-align-items-center',
              'u-padding-x-4' ]},[_c('ar-text',{class:[
                'u-margin-left-2' ],attrs:{"text":selectedItemScope.item.name,"size":"xs"}})],1)]}},{key:"item",fn:function(itemScope){return [(itemScope.item.isAction)?_c('div',{class:[
              'u-display-flex',
              'u-align-items-center',
              'u-padding-x-4' ],style:({
              cursor: 'pointer',
              height: '50px',
              background: itemScope.active ? _vm.$arStyle.color.purple100 : null,
            })},[_c('ar-text',{class:[
                'u-margin-left-2' ],style:({
                lineHeight: 1,
              }),attrs:{"text":itemScope.item.name,"size":"xs"}})],1):_vm._e()]}}],null,false,1129165872)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }