<template>
  <section
    class="event-audiences-container"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
  >
    <am2-add-fan-to-message-list-modal
      :is-open="displayAddFanToMessageListModal"
      :search-string="searchString"
      :filter="currentFilter"
      @add="handleAddFanToMessageListComplete"
      @cancel="handleAddFanToMessageListCancel"
    />
    <div class="top-statistics">
      <div
        class="statistic"
        v-for="(statistic, statisticIndex) of statistics"
        :key="`statistic-${statisticIndex}`"
        @click="() => { setSelectedStatistic(statisticIndex) }"
        :data-test-id="`statistic-${statistic.cleanName}`"
      >
        <div
          :class="[
            'statistic-wrapper',
            (statisticIndex === selectedStatisticIndex) && 'active'
          ]"
        >
          <ar-text
            multiple-lines
            :text="statistic.totalAudience"
            size="30px"
            line-height="39px"
            :style="{
              color: (statisticIndex === selectedStatisticIndex) ? null : $arStyle.color.blueGrey700,
            }"
          />
          <ar-text
            multiple-lines
            weight="bold"
            :text="statistic.cleanName"
            size="16px"
            line-height="20.8px"
            :style="{
              color: (statisticIndex === selectedStatisticIndex) ? $arStyle.color.purple500 : $arStyle.color.blueGrey700,
            }"
          />
        </div>
      </div>
    </div>
    <div class="page">
      <div class="sidebar-wrapper" v-if="(tableBody && tableBody.length > 0 && isFirstCalculationFinished) || !!isSuggestedAudiencesLocked">
        <div
          :class="[
            'sidebar',
            isSuggestedAudiencesLocked && 'locked'
          ]">
          <div
            v-if="!!eventAudienceStats && !!eventAudienceStats[selectedStatisticIndex]"
            class="icons"
          >
            <div
              class="action-count-card"
              v-tooltip.top="{
                content: 'Subscribed to Email'
              }"
            >
              <div
                class="icon"
              >
                <ar-icon
                  name="email"
                  width="18px"
                  :color="$arStyle.color.email"
                />
              </div>
              <ar-text
                multiple-lines
                :text="eventAudienceStats[selectedStatisticIndex].optInEmail"
                weight="bold"
                size="14px"
                :style="{
                  marginTop: '12px',
                }"
              />
            </div>

            <div
              class="action-count-card"
              v-tooltip.top="{
                content: 'Subscribed to SMS'
              }"
            >
              <div
                class="icon"
              >
                <ar-icon
                  name="sms"
                  width="18px"
                  :color="$arStyle.color.sms"
                />
              </div>
              <ar-text
                multiple-lines
                :text="eventAudienceStats[selectedStatisticIndex].optInSms"
                weight="bold"
                size="14px"
                :style="{
                  marginTop: '12px',
                }"
              />
            </div>

            <div
              class="action-count-card"
              v-tooltip.top="{
                content: 'Not subscribed to any lists'
              }"
            >
              <div
                class="icon"
              >
                <Unsubscribed
                />
              </div>

              <ar-text
                multiple-lines
                :text="eventAudienceStats[selectedStatisticIndex].notSubscribed"
                weight="bold"
                size="14px"
                :style="{
                  marginTop: '12px',
                }"
              />
            </div>
          </div>

          <div
            class="action"
          >
            <am2-split-dropdown
              class="action-button"
              text="Actions"
              toggle-dropdown-on-button-click
              :items="actionButtonOptions"
              :style="{
                width: '216px',
                height: '50px',
                marginBottom: '24px'
              }"
              @select="(item) => item.action()"
            />
          </div>

          <ar-text
            v-if="updatedText"
            multiple-lines
            :text="updatedText"
            weight="normal"
            size="12px"
            allow-html
            :style="{marginBottom: '14px'}"
            @anchorClick="refreshClick"
          />

          <ar-divider />
          <div
            v-if="!!statistics && !!statistics[selectedStatisticIndex] && !!criteriaDescriptions[statistics[selectedStatisticIndex].audience]"
            :style="{
              marginTop: '24px',
            }"
          >
            <ar-text
              multiple-lines
              :text="criteriaDescriptions[statistics[selectedStatisticIndex].audience].title"
              weight="bold"
              size="14px"
            />
            <ar-text
              v-if="!!criteriaDescriptions[statistics[selectedStatisticIndex].audience].description"
              multiple-lines
              :text="criteriaDescriptions[statistics[selectedStatisticIndex].audience].description"
              size="12px"
              :style="{
                color: $arStyle.color.skyBlueGrey700,
              }"
            />
            <div
              v-for="(item, itemIndex) of criteria"
              :key="item.name">
              <ar-text
                v-if="item && !!item.name && item.items.length > 0"
                multiple-lines
                :text="item.name"
                :style="{
                  marginTop: '16px',
                }"
                size="14px"
              />
              <div class="criteria-items"
                   :style="{maxWidth: '100%'}"
                   v-if="!!item.items">
                <div
                  v-for="(tagItem, index) of item.items"
                  :style="{maxWidth: '100%'}"
                  :key="`criteria-${itemIndex}-item-${tagItem}-${index}`">
                  <am2-tag
                    :text="tagItem"
                    :style="{maxWidth: '100%'}"
                    type="purple"
                    shape="rectangle"
                  />
                </div>
              </div>
            </div>
            <div>
              <ar-text
                multiple-lines
                v-if="!!criteriaDescriptions[statistics[selectedStatisticIndex].audience].exit"
                :text="criteriaDescriptions[statistics[selectedStatisticIndex].audience].exit"
                size="12px"
                :style="{
                  marginTop: '16px',
                  color: $arStyle.color.skyBlueGrey700,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="table" v-if="(tableBody && tableBody.length > 0 && isFirstCalculationFinished) || !!isSuggestedAudiencesLocked">
        <am2-table-control-section
          v-ar-sticky-top="{
            priority: 1,
          }"
          :style="{
            backgroundColor: '#f6f9fc',
          }"
          :fans-search="{
            action: serverSideSearch,
          }"
          :loading="(isFetchingAudience) && audience.length === 0"
          show-checked-rows-options-bar
          show-filter-and-search-bar
          :showFilterButton="false"
          :csvExport="isPermissionEnabled(['export-all']) && !isSuggestedAudiencesLocked ? {
            action: handleExportClick,
          } : null"
          :column-settings="{
            selectedColumnKeys: selectedColumnKeys,
            disabled: true,
          }"
        />

        <am2-fan-table
          :head="displayedTableHeadings"
          :body="tableBody"
          :loading="isFetchingAudience"
          has-index
          empty-text="No attendee data"
          @sortBy="handleSortChange"
          :enable-row-click="customerProfilesEnabled"
          @rowClick="handleRowClick"
          has-sticky-header
          has-control-section
          :disable-sorting="isSuggestedAudiencesLocked"
          :locked="isSuggestedAudiencesLocked"
          :use-dummy-fans="isSuggestedAudiencesLocked"
          v-ar-feature-mask="{
            show: isSuggestedAudiencesLocked,
            showUpgradeOn: 'always',
            showUpgradeNowButton: false,
            showPremiumTag: true,
            title: 'Generate a suggested audience',
            message: 'Generated based on event criteria from your contacts and linked campaigns.',
            margin: {
              top: 50
            },
          }"
          :style="{
            minHeight: isSuggestedAudiencesLocked ? '380px' : null,
          }"
        />

      </div>
    </div>
    <!-- Empty State -->
    <div v-if="(!tableBody || tableBody.length === 0) && isFirstCalculationFinished && !isSuggestedAudiencesLocked" class="page">
      <am2-no-content-section
        class="suggested-audience-no-content"
        header="Generate your suggested audience to get started"
        body="Generated based on event criteria from your contacts and linked campaigns."
        button-text="Generate audience"
        @buttonClick="handleGenerateAudienceButtonPress"
        :button-props="{
          outlined: true,
          height: '30px',
          iconName: 'magic'
        }"
        icon-name="person-plus"
        icon-wrapper-enabled
        :icon-props="{
          width: '24px',
          height: '24px',
          color: $arStyle.color.purple500,
        }"
      />
    </div>

    <!-- First Load state -->
    <div v-if="!!statistics && !isFirstCalculationFinished" class="page">
      <am2-card-container
        class="suggested-audience-loading-first"
      >
        <div class="suggested-audience-loading-first__loader-wrapper">
          <am2-loading-bubble :size="'16px'" />
        </div>
        <ar-text
          class="suggested-audience-loading-first__text"
          multiple-lines
          text="Your suggested audience is loading. Please check back in a couple of minutes or refresh the page"
        />
      </am2-card-container>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { magicDownload } from '@/utils/html-element/';
import Unsubscribed from '~/assets/icons/unsubscribed.svg?inline'
import dayjs from 'dayjs';

const initialOrderBy = { key: 'sysMtime', order: 'desc' };

const lockColumnReason = 'Contact sales for access.';
const tableHeadings = [
  {
    name: 'Name',
    sortKey: 'firstName',
    key: 'firstName',
    format: 'name',
    attributeKeys: ['firstName','lastName'],
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Email',
    key: 'emailAddress',
    format: 'emailAddress',
    sortKey: 'emailAddress',
    attributeKeys: 'emailAddress',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Mobile',
    key: 'mobileNumber',
    format: 'text',
    sortKey: 'mobileNumber',
    attributeKeys: 'mobileNumber',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Events Purchased',
    key: 'totalEventCount',
    format: 'number',
    sortKey: 'totalEventCount',
    attributeKey: 'totalEventCount',
    requiredPermissions: {event: ["view-sales"]},
    attributeKeys: 'totalEventCount',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
  {
    name: 'Total Ticket Sales',
    key: 'totalTicketSales',
    format: 'currency',
    sortKey: 'totalTicketSales',
    attributeKey: 'totalTicketSales',
    requiredPermissions: {event: ["view-sales"]},
    attributeKeys: 'totalTicketSales',
    lockIfDisabled: true,
    lockReason: lockColumnReason,
  },
];

export default {
  name: 'EventAudience',
  components: {
    Unsubscribed,
  },

  data() {
    return {
      tableHeadings,
      orderBy: initialOrderBy,
      searchString: '',
      displayedColumnKeys: tableHeadings.map(heading => heading.key),
      exportKeys: ['emailAddress', 'firstName', 'lastName', 'mobileNumber', 'dob', 'streetAddress', 'postcode', 'city', 'state', 'country', 'ticketSales', 'tickets', 'totalEventCount', 'totalTicketSales'],
      selectedColumnKeys: [],
      displayAddFanToMessageListModal: false,
      events: [],
      availableColumns: [
        {
          name: 'Name',
          value: 'firstName',
          disabled: true,
        },
        {
          name: 'Email',
          value: 'emailAddress',
        },
        {
          name: 'Mobile',
          value: 'mobileNumber',
        },
        {
          name: 'Events Purchased',
          value: 'totalEventCount',
        },
        {
          name: 'Total ticket sales',
          value: 'totalTicketSales',
        },
      ],
      selectedStatisticIndex: 0,
      hasFetchedExternalResources: false,

      criteriaDescriptions: {
        "suggested": {
          title: 'Contacts enter when',
          description: 'Meets any of the following criteria...',
          exit: 'Exits when a ticket is purchased',
        },
        "not-contacted": {
          title: 'Suggested enter when',
          description: 'Meets all of the following criteria...',
          exit: 'Exits when sent a linked message',
        },
        "not-engaged": {
          title: 'Suggested enter when',
          description: 'Meets all of the following criteria...',
          exit: 'Exits when engagement increases or a ticket is purchased',
        },
        "engaged": {
          title: 'Suggested enter when',
          description: 'Meets all of the following criteria...',
          exit: 'Exits when a ticket is purchased',
        },
        "highly-engaged": {
          title: 'Suggested enter when',
          description: 'Meets all of the following criteria...',
          exit: 'Exits when a ticket is purchased',
        },
        "purchased": {
          title: 'Contacts enter when',
          description: null,
          exit: null,
        },
      },

      emptyStatistics: [{
          cleanName: 'Suggested',
          totalAudience: 0,
        },{
          cleanName: 'Not Contacted',
          totalAudience: 0,
        },{
          cleanName: 'Not Engaged',
          totalAudience: 0,
        },{
          cleanName: 'Engaged',
          totalAudience: 0,
        },{
          cleanName: 'Purchased',
          totalAudience: 0,
        },]
    };
  },

  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      audience: state => state.audience.audience,
      totalAudienceCount: state => state.audience.totalAudienceCount,
      isFetchingAudience: state => state.audience.isFetchingAudience,
      isNoMoreAudience: state => state.audience.isNoMoreAudience,
      eventAudienceStats: state => state.event.eventAudienceStats,
      isFetchingEventAudienceStats: state => state.event.isFetchingEventAudienceStats,
      tags: state => state.tag.tags,
      signupForms: state => state.signupForm.signupForms,
      campaigns: state => state.campaign.campaigns,
      messages: state => state.message.messages,
      currentRSVPForm: state => state.eventRSVPForm.currentEventRSVPForm,
      messageLists: state => state.messageList.messageLists,
    }),
    ...mapGetters({
      isPermissionEnabled: 'auth/isPermissionEnabled',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    infiniteScrollDisabled() {
      return this.isFetchingAudience || this.isNoMoreAudience;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    fanAttributeKeys() {
      let res = [];

      for (let i = 0; i < this.tableHeadings.length; i += 1) {
        const heading = this.tableHeadings[i];

        if (this.displayedColumnKeys.includes(heading.key) && heading.attributeKeys) {
          if (Array.isArray(heading.attributeKeys)) {
            res = res.concat(heading.attributeKeys);
          } else {
            res.push(heading.attributeKeys);
          }
        }
      }

      return res;
    },

    tableBody() {
      if (!this.audience) {
        return [];
      }

      return this.audience.map(attendee => {
        attendee.avatar = attendee.profileImageUrl;

        return attendee;
      });
    },

    displayedTableHeadings() {
      const headings = this.tableHeadings

      return headings.filter(heading => {
        if (this.selectedColumnKeys && this.selectedColumnKeys.includes(heading.key)) {
          return heading;
        }
        return null;
      });
    },


    actionButtonOptions() {
      const options = [];
      const emailEnabled = true;

      options.push({
        name: 'New email',
        disabled: this.currentAudience?.meta?.audienceFilterValid === false,
        action: this.handleNewEmailMessageClick,
      });

      options.push({
        name: 'New SMS',
        disabled: this.currentAudience?.meta?.audienceFilterValid === false,
        action: this.handleNewSmsMessageClick,
      });

      options.push({
        name: 'Add to list',
        disabled: this.currentAudience?.meta?.audienceFilterValid === false,
        action: this.handleAddToListClick,
      });

      return options;
    },

    criteriaSuggested() {
      if (this.isSuggestedAudiencesLocked && (!this.tableBody || this.tableBody?.length === 0)) {
        return [{
          name: 'Tag:',
          items: [ 'Hello World', 'Test Data', 'Hardcoded']
        }]
      } else {

        return [
          {
            name: 'Tag:',
            items: this.suggestedAudienceTagNames
          },
          {
            name: 'Purchased:',
            items: this.suggestedAudienceEventNames
          },
          {
            name: 'Campaign:',
            items: this.suggestedAudienceCampaignNames
          },
          {
            name: 'Sign-up form:',
            items: this.suggestedAudienceSignupFormNames
          },
          {
            name: 'RSVP page:',
            items: this.suggestedAudienceRSVPFormNames
          },
        ]
      }
    },
    criteriaNotContacted() {
      return [
        {
          name: 'Not sent:',
          items: this.suggestedAudienceMessageNames.length === 0 ?
            ["Any linked messages"] :
            this.suggestedAudienceMessageNames
        },
      ]
    },
    criteriaNotEngaged() {
      return [
        {
          name: 'Sent:',
          items: this.suggestedAudienceMessageNames.length === 0 ?
            ["Any linked messages"] :
            this.suggestedAudienceMessageNames
        },
        {
          name: 'Hasn\'t opened or clicked:',
          items: this.suggestedAudienceMessageNames.length === 0 ?
            ["Any linked messages"] :
            this.suggestedAudienceMessageNames
        },
      ]
    },
    criteriaEngaged() {
      return [
        {
          name: 'Sent:',
          items: this.suggestedAudienceMessageNames.length === 0 ?
            ["Any linked messages"] :
            this.suggestedAudienceMessageNames
        },
        {
          name: 'Has opened or clicked:',
          items: this.suggestedAudienceMessageNames.length === 0 ?
            ["Any linked messages"] :
            this.suggestedAudienceMessageNames
        },
      ]
    },
    criteriaPurchased() {
      return [
        {
          name: 'Purchased ticket:',
          items: [this.currentEvent.name]
        },
      ]
    },
    currentAudience() {
      return this.eventAudienceStats[this.selectedStatisticIndex];
    },
    criteria() {
      const audienceName = this.currentAudience.audience;
      switch(audienceName) {
        case 'suggested':
          return this.criteriaSuggested
        case 'not-contacted':
          return this.criteriaNotContacted
        case 'not-engaged':
          return this.criteriaNotEngaged
        case 'engaged':
          return this.criteriaEngaged
        case 'purchased':
          return this.criteriaPurchased
        default:
          return [];
      }
    },

    statistics() {
      if (this.isFetchingEventAudienceStats || !this.eventAudienceStats || this.eventAudienceStats.length <= 0) {
        return this.emptyStatistics
      };

      return this.eventAudienceStats
    },

    currentFilter() {
      if (!this.eventAudienceStats || this.eventAudienceStats.length === 0) return {}
      if (this.selectedStatisticIndex < 0) return {}
      const filterGroup = this.currentAudience.filter
      return filterGroup ? filterGroup.filter : {}
    },

    suggestedAudienceEventOids() {
      return this.currentEvent?.presentation?.suggestedAudience?.eventOids || []
    },
    suggestedAudienceEventNames() {
      return this.events.map(item => item.name);
    },

    suggestedAudienceTagOids() {
      return this.currentEvent?.presentation?.suggestedAudience?.tagOids || []
    },
    suggestedAudienceTagNames() {
      return this.tags.map(item => item.name);
    },

    suggestedAudienceSignupFormOids() {
      return this.currentEvent?.presentation?.suggestedAudience?.signupFormOids || []
    },
    suggestedAudienceSignupFormNames() {
      return this.signupForms.map( item => item.name);
    },

    suggestedAudienceCampaignNames() {
      return this.campaigns.map(item => item.name);
    },

    suggestedAudienceMessageNames() {
      return this.messages.map( item => {
        return item.customerName || item.meta?.messageBody?.subject || 'Untitled message'
      })
    },

    suggestedAudienceRSVPFormNames() {
      return this.currentRSVPForm ? [this.currentRSVPForm.name] : []
    },

    currentMessageList() {
      if (!this.messageLists || this.messageLists.length < 1) {
        return null;
      }
      return this.messageLists[0];
    },

    isSuggestedAudiencesLocked() {
      return !this.isFeatureEnabled(['suggested-audience'])
    },
    updatedText() {
      if (!this.currentAudience) return null;
      const lastUpdated = this.currentAudience.updateFinishedDate;
      if (!lastUpdated) return null;
      const timeAgo = dayjs(lastUpdated).fromNow()
      return `Updated ${timeAgo} <a style="font-size:12px;">Refresh</a>`
    },

    isFirstCalculationFinished() {
      if (!this.hasSuggetedAudienceCriteria) {
        return true
      }

      if (!this.statistics) return false

      for (let i = 0; i < this.statistics.length; i++) {
        if (!this.statistics[i].updateFinishedDate) {
          return false;
        }
      }

      return true
    },

    hasSuggetedAudienceCriteria() {
      if (!this.currentEvent) {
        return false
      }

      if (!!this.currentEvent?.presentation?.suggestedAudience) {
        return !!this.suggestedAudienceTagOids || !!this.suggestedAudienceSignupFormOids || !!this.suggestedAudienceEventOids
      } else {
        return false
      }
    }
  },

  created() {
    this.$store.registerModule("event-view-child", {
      mutations: {
        FORCED_EVENT_UPDATE(state) {
          this.loadMoreData();
        }
      }
    });
    this.selectedColumnKeys = this.availableColumns.reduce((keys, { value }) => {
      keys.push(value);
      return keys;
    }, [])

    this['auth/FETCH_PERMISSIONS']()


  },

  watch: {
    currentEvent(newVal, oldVal) {
      if (newVal?.oid === null) return;
      if (this.hasFetchedExternalResources) return;
      const linkedEventOids = newVal?.presentation?.suggestedAudience?.eventOids || [];
      const linkedTagOids = newVal?.presentation?.suggestedAudience?.tagOids || [];
      const linkedSignupFormOids = newVal?.presentation?.suggestedAudience?.signupFormOids || [];
      this.fetchLinkedResources(linkedEventOids, linkedTagOids, linkedSignupFormOids)

      if (!!this.hasSuggetedAudienceCriteria) {
        this['event/FETCH_EVENT_AUDIENCE_STATS']({eventOid: this.$route.params.oid})
      }
    },
    selectedStatisticIndex(newVal, oldVal) {
      if (oldVal === newVal) return
      this.reloadData();
    },
    eventAudienceStats(newVal, oldVal) {
      if (newVal.length > 0 && oldVal.length === 0) {
        this.reloadData();
      }
    },
  },

  beforeDestroy() {
    this['audience/RESET_AUDIENCE']();
    this['event/RESET_EVENT_AUDIENCE_STATS']();
  },

  methods: {
    ...mapActions([
      'auth/FETCH_PERMISSIONS',
      'audience/FETCH_MORE_AUDIENCE',
      'audience/FETCH_ZOOM_ATTENDANCE',
      'audience/EXPORT_AUDIENCE_CSV',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'event/FETCH_EVENT_AUDIENCE_STATS',
      'event/FETCH_EVENTS_BY_OIDS',
      'tag/FETCH_MORE_TAGS',
      'campaign/FETCH_CAMPAIGNS_BY_EVENT_OID',
      'message/FETCH_MORE_MESSAGES',
      'signupForm/FETCH_SIGNUP_FORMS',
      'eventRSVPForm/FETCH_CURRENT_EVENT_RSVP_FORM',
      'messageList/FETCH_SUGGESTED_AUDIENCE_LISTS',
      'event/REFRESH_EVENT_AUDIENCE_STATS',
    ]),
    ...mapMutations([
      'audience/RESET_AUDIENCE',
      'audience/SET_AUDIENCE_COUNT',
      'event/RESET_EVENT_AUDIENCE_STATS',
    ]),

    async handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      this.orderBy = {
        key,
        order,
      };
      this.reloadData()
    },

    loadMoreData() {
      if (!this.currentFilter.conditions || this.currentFilter.conditions.length === 0) {
        this['audience/RESET_AUDIENCE']()
        return
      }
      this['audience/FETCH_MORE_AUDIENCE']({
        searchString: this.searchString,
        filter: this.currentFilter,
        orderBy: this.orderBy,
        selectKeys: this.fanAttributeKeys,
      });
    },

    async serverSideSearch(searchString) {
      if (this.isSuggestedAudiencesLocked) {
        return;
      }

      this.searchString = searchString;
      this.reloadData();
    },

    reloadData() {
      if (this.isFetchingAudience) return; // Don't reload if we're already fetching
      if (!this.currentFilter.conditions || this.currentFilter.conditions.length === 0) {
        this['audience/RESET_AUDIENCE']()
        return
      }

      this['audience/FETCH_MORE_AUDIENCE']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeKeys,
        filter: this.currentFilter,
        orderBy: this.orderBy,
        reload: true,
        skipCount: false,
      });
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.oid) {
        this.$router.push({
          path: `/audience/${target.oid}/view/overview`,
        });
      }
    },

    handleExportClick() {
      if (!this.currentFilter.conditions || this.currentFilter.conditions.length === 0) {
        this.$arNotification.push({ type: 'warning', message: 'Your selected audience is empty' });
        return
      }
      this['audience/EXPORT_AUDIENCE_CSV'](
        {
          filter: this.currentFilter,
          searchString: this.searchString,
          selectKeys: this.exportKeys,
          orderBy: this.orderBy || { key: 'ticketSales', order: 'desc' },
        }
      );
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({
          reload: true,
        });
      }, 1250);
    },

    handleSelectedColumnKeysUpdate(columnKeys) {
      this.selectedColumnKeys = columnKeys;
      this.reloadData();
    },

    setSelectedStatistic(index) {
      this.selectedStatisticIndex = index
    },

    handleGenerateAudienceButtonPress() {
      return this.$router.push(`/events/${this.$route.params.oid}/edit?from=audience`);
    },

    handleNewEmailMessageClick() {
      const eventOid = this.currentEvent.oid;
      this.$router.push({
        path: `/message-center/messages/email/new/basic`,
        query: {
          eventOid,
          suggestedAudience: this.currentAudience.oid,
        },
      });
    },

    handleNewSmsMessageClick() {
      const eventOid = this.currentEvent.oid;
      this.$router.push({
        path: `/message-center/messages/sms/new`,
        query: {
          eventOid,
          suggestedAudience: this.currentAudience.oid,
        },
      });
    },

    handleAddToListClick() {
      this.displayAddFanToMessageListModal = true;
    },

    // TODO - change this to run all Promises at the same time and await resolution of all three,
    // rather than just doing them one by one.
    async fetchLinkedResources(eventOids, tagOids, signupFormOids) {
      if (!this.currentEvent) {
        return;
      }

      if (eventOids && eventOids.length > 0) {
        this.events = await this['event/FETCH_EVENTS_BY_OIDS']({
          oids: eventOids,
        })
      } else {
        this.events = [];
      }
      if (tagOids && tagOids.length > 0) {
        await this['tag/FETCH_MORE_TAGS']({
          oids: tagOids,
          reload: true,
        })
      }
      await this['campaign/FETCH_CAMPAIGNS_BY_EVENT_OID']({
        eventOid: this.currentEvent.oid,
        reload: true,
      })
      await this['message/FETCH_MORE_MESSAGES']({
        reload: true,
        linkedEvent: this.currentEvent.oid,
        status: 'completed',
        top: 'all'
      })
      await this['eventRSVPForm/FETCH_CURRENT_EVENT_RSVP_FORM'](
        this.currentEvent.oid
      )
      await this['messageList/FETCH_SUGGESTED_AUDIENCE_LISTS']({
        eventOid: this.currentEvent.oid
      })
      if (signupFormOids && signupFormOids.length > 0) {
        await this['signupForm/FETCH_SIGNUP_FORMS']({
          reload: true,
          oids: signupFormOids,
          top: 'all'
        })
      }

      this.hasFetchedExternalResources = true;
    },

    handleAddFanToMessageListComplete() {
      this.displayAddFanToMessageListModal = false;
      this.startPollingPendingTasks();
    },

    handleAddFanToMessageListCancel() {
      this.displayAddFanToMessageListModal = false;
    },

    // Add a slight timeout so that the server has time to process the task and add it to the DB.
    startPollingPendingTasks() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({
          reload: true,
        });
      }, 1250);
    },

    async refreshClick() {
      if (!this.currentEvent.oid) return;
      if (this.updateRequested) {
        this.$arNotification.push({ type: 'warning', message: `Please wait. Your data will be available soon.`});
      }
      const lastUpdated = this.currentAudience?.updateFinishedDate;
      if (lastUpdated) {
        const diff =  dayjs().diff(dayjs(lastUpdated), 'second');
        if (diff < 180) {
          this.$arNotification.push({ type: 'warning', message: `Stats updated recently. Please wait a few minutes and try again.`});
          return;
        }
      }


      await this['event/REFRESH_EVENT_AUDIENCE_STATS']({eventOid: this.currentEvent.oid});
      this.updateRequested = true;
      setTimeout(() => {
        this.updateRequested = false
      }, 15000)
      this.$arNotification.push({ type: 'success', message: `Suggested audiences are updating. Your data will be available soon.`});
    },
  },
};
</script>

<style lang="scss">
.event-audiences-container {
  padding: 0 10px;
  margin-bottom: 30px !important;

  .registrants-top-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 27px;

    .registrants-top {
      width: calc(100% - 30px);
      margin: 0;
    }

    .registrants-menu-button {
      background: $skyBlueGrey400;
      top: 0;

      .registrants-menu-button-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        width: 30px;
        height: 30px;
      }

      .registrants-menu-button-text {
        display: inline-block;
        vertical-align: middle;
        top: 2px;
      }
    }
  }

  .attendees-integration-wrapper {
    display: flex;
    align-items: center;

    .attendees-integration-logo {
      width: 26px;
      height: 26px;
      background-color: white;
      border: 1px solid $blueGrey500;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 10px 0 rgba(67,81,107,0.2);
    }
  }

  table {
    td:nth-child(1) {
      width: 60%;
    }
  }

  .top-statistics {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;

    .statistic {
      border: 1px solid $skyBlueGrey500;
      height: 88px;
      width: 100%;
      border-right: 0px;

      &:hover {
        cursor: pointer;
      }

      .statistic-wrapper {
        padding: 12px 16px;
        height: 100%;

        &.active {
          border-bottom: 4px solid $purple500;
          padding: 12px 16px 0 16px;
        }
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .active {
          border-bottom-left-radius: 5px;
        }
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 1px solid $skyBlueGrey500;

        .active {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .page {
    display: flex;
    flex-direction: row;
    width: 100%;

    .sidebar-wrapper {
      width: 248px;
      padding: 24px 16px;
      margin-right: 24px;
      border-radius: 4px;
      border: 1px solid $skyBlueGrey500;
      background: #FFF;
      height: 100%;

      .sidebar {

        .icons {
          display: grid;
          grid-template-columns: repeat(3, 1fr);

          .action-count-card {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            .icon {
              display: flex;

              border: 2px solid $skyBlueGrey400;
              border-radius: 33px;
              width: 40px;
              height: 40px;
              align-items: center;
              justify-content: center;
            }

            /*&:last-child {
              padding-right: 0px;
            }*/
          }
        }

        .action {
          margin-top: 16px;
        }

        .criteria-items {
          display: flex;
          flex-direction: row;
          margin-top: 4px;
          gap: 10px;
          flex-wrap: wrap;
          max-width: 100%;
        }

        &.locked {
          filter: blur(5px);
          pointer-events: none;
          user-select: none;
        }
      }
    }

    .table {
      width: 100%;
    }

    .suggested-audience-no-content {
      width: 100%;
    }
  }
}

.suggested-audience-loading-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px;

  &__text {
    margin-top: 16px;
  }

  &__loader-wrapper {
    height: 64px;
    display: flex;
    align-items: center;
  }
}
</style>
