var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"title-value-list-container"},[_c('div',{ref:"invisible-modal",staticClass:"invisible-modal",style:({
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: _vm.$arStyle.zIndex.globalRegular,
      display: !_vm.noActiveIndex ? 'block' : 'none',
      '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleInvisibleModalClick.apply(null, arguments)}}}),_vm._v(" "),_c('am2-heading',{staticClass:"section-heading",attrs:{"type":"h1","size":"md","title":_vm.title}}),_vm._v(" "),(!_vm.items || _vm.items.length === 0)?_c('div',[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
        padding: '0 20px 0 20px'
      }),attrs:{"size":"xs","text":"No custom fields","data-test-id":"title-value-list-no-custom-fields"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.items),function(item,itemIndex){return _c('am2-column-simple-form',{key:itemIndex,ref:"simple-form",refInFor:true,style:({
      position: 'relative',
      zIndex: !_vm.noActiveIndex ? _vm.$arStyle.zIndex.globalRegular + 1 : null,
    }),attrs:{"icon-name":item.icon,"placeholder":item.modifiable ? ("Add " + (item.title)) : ("No " + (item.title)),"parsed-data":_vm.returnParsedData(item),"title":item.title,"disabled":!item.modifiable,"opened":_vm.activeFormIndex === itemIndex},on:{"open":function($event){return _vm.handleFormOpen(itemIndex)},"submit":function($event){return _vm.handleFormSubmit(item, itemIndex)},"cancel":function($event){return _vm.handleSimpleFormCancel()}}},[(_vm.activeFormIndex === itemIndex)?_c('div',{attrs:{"slot":"fields"},slot:"fields"},[(item.input.type === 'String')?_c('ar-input',{key:itemIndex + item.input.name,style:({
            height: '42px',
          }),attrs:{"type":"text","placeholder":item.input.placeholder,"input-font-size":"xs","id":item.input.name,"autocomplete":item.input.name,"value":_vm.temporaryValues[itemIndex]},on:{"input":function (val) { return _vm.modifyData(item.input.name, val, itemIndex); }}}):(item.input.type === 'Number')?_c('ar-input',{key:itemIndex + item.input.name,style:({
            height: '42px',
          }),attrs:{"type":"number","placeholder":item.input.placeholder,"input-font-size":"xs","id":item.input.name,"autocomplete":item.input.name,"value":_vm.temporaryValues[itemIndex]},on:{"input":function (val) { return _vm.modifyData(item.input.name, val, itemIndex); }}}):(item.input.type === 'Date')?_c('ar-field',{staticClass:"u-width-100-percent",attrs:{"error-messages":[_vm.veeErrors.first(item.input.name)]}},[_c('ar-date-input',{directives:[{name:"validate",rawName:"v-validate",value:('date-of-birth'),expression:"'date-of-birth'"}],attrs:{"data-vv-name":item.input.name,"data-vv-as":"date","is-birthday":false},model:{value:(_vm.temporaryValues[itemIndex]),callback:function ($$v) {_vm.$set(_vm.temporaryValues, itemIndex, $$v)},expression:"temporaryValues[itemIndex]"}})],1):(item.input.type === 'Select' && !!item.input.options)?_c('ar-simple-select',{key:itemIndex + item.input.name,style:({
            height: '42px',
            width: '100%',
          }),attrs:{"items":item.input.options,"placeholder":"Select One","enable-clear":"","default-select-index":_vm.defaultSelectIndexes[itemIndex]},on:{"select":function (selection) { _vm.modifyData(item.input.name, selection.name, itemIndex) },"clear":function () { _vm.modifyData(item.input.name, null, itemIndex) }}}):(item.input.type === 'Multiselect' && !!item.input.options)?_c('ar-multi-select',{key:itemIndex + item.input.name,attrs:{"items":_vm.temporaryInputOptions[itemIndex].options,"placeholder":"Select multiple","search-placeholder":"Search options","default-value":_vm.temporaryValues[itemIndex] || []},on:{"selectedValues":function (selection) { _vm.modifyMultiSelect(item.input.name, selection, itemIndex) }}}):_vm._e()],1):_vm._e()])}),_vm._v(" "),(_vm.showExpandableLinkButton)?_c('ar-link-button',{staticClass:"u-margin-left-5 u-margin-top-2",attrs:{"text":_vm.showMoreItems ? 'View less' : 'View more'},on:{"click":_vm.toggleItemDisplay}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }