var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.columnSettings && !_vm.withCustomFields)?_c('am2-multi-select-modal',{attrs:{"title":"Edit columns","is-show":_vm.showColumnSwitchModal,"items":_vm.columnSettings.availableColumns,"value":_vm.columnSettings.selectedColumnKeys,"enable-reset-button":true,"reset-default-keys":_vm.resetDefaultColumns},on:{"input":_vm.handleMultiSelectModalConfirm,"close":function($event){_vm.showColumnSwitchModal = false}}}):(_vm.columnSettings && _vm.withCustomFields)?_c('am2-multi-select-with-menu-modal',{attrs:{"title":"Edit columns","is-show":_vm.showColumnSwitchModal,"items":_vm.columnSettings.availableColumns,"value":_vm.columnSettings.selectedColumnKeys,"enable-reset-button":true,"reset-default-keys":_vm.resetDefaultColumns},on:{"input":_vm.handleMultiSelectModalConfirm,"close":function($event){_vm.showColumnSwitchModal = false}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"table-control-section"},[(_vm.showCountAndOptionsBar)?_c('div',{staticClass:"bar count-and-options-bar"},[(_vm.initialLoadFinished)?_c('ar-text',{attrs:{"size":"sm","allow-html":"","text":_vm.fansCountText}}):_vm._e(),_vm._v(" "),(_vm.dropdownItems.length > 0)?_c('am2-simple-button-dropdown',{staticClass:"dropdown-button",attrs:{"button-props":{
            text: '...',
            type: 'grey',
            outlined: true,
            style: { height: '40px', width: '100%' }
          },"items":_vm.dropdownItems,"dropdown-max-height":_vm.$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)',"dropdown-style":{
            width: '200px',
          }},on:{"select":_vm.actionSelect}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showFilterAndSearchBar)?_c('div',{class:[
        'bar',
        'filter-and-search-bar',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ]},[(_vm.showFilterButton)?_c('div',{staticClass:"circle-hooker item"},[(_vm.filterIsNotEmpty)?_c('div',{staticClass:"circle"},[_c('span',[_vm._v(_vm._s(_vm.prunedScratchSegment.filter.conditions.length))])]):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticClass:"filter-btn",style:({
            height: '40px',
          }),attrs:{"icon-name":"filter-alt","side-length":"40px","icon-props":{
            height: '16px',
          },"text":"Filter","type":_vm.filterIsNotEmpty ? 'purple' : 'grey',"outlined":"","data-test-id":"audience-table-filter-button"},on:{"click":_vm.switchFilterSidebar}})],1):_vm._e(),_vm._v(" "),(_vm.fansSearch)?_c('am2-search',{staticClass:"item fans-search",style:({
          height: '40px',
        }),attrs:{"placeholder":_vm.searchPlaceholder},on:{"enter":_vm.handleFansSearchEnter,"clear":_vm.handleFansSearchClear},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e(),_vm._v(" "),(_vm.dropdownItems.length > 0 && !_vm.showCountAndOptionsBar)?_c('am2-simple-button-dropdown',{staticClass:"item dropdown-button",attrs:{"button-props":{
            type: 'grey',
            outlined: true,
            style: {
              height: '40px',
              width: '100%'
            },
            iconName: 'ellipsis',
            iconDistance: '0px',
            iconProps: {
              height: '4px',
              width: '18px',
              color: _vm.$arStyle.color.skyBlueGrey800,
            },
            customStyleAttributes: {
              padding: '0 10px',
            },
          },"items":_vm.dropdownItems,"dropdown-max-height":_vm.$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)',"dropdown-style":{
            width: '180px',
          }},on:{"select":_vm.actionSelect}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showFilterOptionsBar && _vm.filterBlocks.length > 0)?_c('div',{ref:"filter-options-bar",staticClass:"bar filter-options-bar"},[_c('div',{ref:"scroller",staticClass:"scroller",style:({
          right: (_vm.filterScrollerPosition + "px"),
        })},_vm._l((_vm.filterBlocks),function(block,blockIndex){return _c('am2-tag',{key:blockIndex,staticClass:"filter-block",style:({
            padding: block.type === 'logic' ? '6px 8px' : null,
            cursor: block.type === 'logic' ? 'pointer' : null,
          }),attrs:{"type":block.type === 'condition' ? 'purple' : 'grey',"shape":"rectangle","has-cross":block.type === 'condition',"remove-button-style":{
            background: 'none',
            color: block.type === 'condition' ? _vm.$arStyle.color.purple500 : _vm.$arStyle.color.blueGrey700,
          },"text":block.text},on:{"remove":function () { return _vm.handleRemoveFilter(block, blockIndex); }},nativeOn:{"click":function($event){return (function () { return _vm.handleLogicClick(block, blockIndex); }).apply(null, arguments)}}})}),1),_vm._v(" "),(_vm.filterScrollerPosition > 0)?_c('div',{staticClass:"scroller-handle handle-left",on:{"click":_vm.handleScrollerLeft}},[_c('ar-icon',{attrs:{"name":"slender-arrow","width":"14px","color":_vm.$arStyle.color.blueGrey500,"rotate":180}})],1):_vm._e(),_vm._v(" "),(_vm.filterScrollerShowRightArrow)?_c('div',{staticClass:"scroller-handle handle-right",on:{"click":_vm.handleScrollerRight}},[_c('ar-icon',{staticClass:"handle-arrow",attrs:{"name":"slender-arrow","width":"14px","color":_vm.$arStyle.color.blueGrey500}})],1):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.showCheckedRowsOptionsBar)?_c('div',{class:[
        'bar',
        'checked-rows-options-bar',
        _vm.tickedFansCount !== 0 && 'is-visible' ]},[_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"selected-count-container u-margin-right-2",on:{"click":_vm.handleSelectAllClick}},[_c('ar-checkbox',{ref:"semiCheckbox",staticClass:"u-margin-right-2",style:({
              pointerEvents: 'none',
            }),attrs:{"isSemi":_vm.tickedFansCount !== _vm.totalAudienceCount && _vm.tickedFansCount > 0,"value":_vm.useScratchSegment}}),_vm._v(" "),_c('ar-text',{attrs:{"text":_vm.selectedCountText,"size":"xs"}})],1),_vm._v(" "),(_vm.checkedRowActions.length > 0 && _vm.$arMediaQuery.pageContent.maxWidth('md'))?_c('am2-simple-button-dropdown',{staticClass:"item dropdown-button u-margin-right-2",attrs:{"button-props":{
            type: 'grey',
            outlined: true,
            text: _vm.$arMediaQuery.window.maxWidth('xs') ? 'Action' : 'Actions',
            textWeight: 'normal',
            style: { height: '30px', width: '100%' },
            iconName: 'arrow',
            iconSide: 'right',
            iconProps: {
              width: '9px',
              height: '5px',
              color: _vm.$arStyle.color.skyBlueGrey800,
            },
          },"items":_vm.checkedRowActions,"align":"right","dropdown-max-height":_vm.$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)',"dropdown-style":{
            width: '210px',
          }},on:{"select":_vm.actionSelect}}):_c('div',[_c('ar-simple-button',{style:({
              height: '30px',
            }),attrs:{"text":"Add to list","outlined":""},on:{"click":_vm.handleAddFanToMessageList}}),_vm._v(" "),_c('am2-simple-button-dropdown',{attrs:{"button-props":{
              text: 'Tags',
              outlined: true,
              style: {
                height: '30px',
              }
            },"dropdown-style":{
              width: '132px',
            },"items":[
              {
                name: 'Add',
                action: _vm.handleTagAdd
              },
              {
                name: 'Remove',
                action: _vm.handleTagRemove
              }
            ]},on:{"select":_vm.actionSelect}}),_vm._v(" "),_c('ar-simple-button',{style:({
              height: '30px',
            }),attrs:{"text":"Edit","outlined":""},on:{"click":_vm.handleFanEdit}}),_vm._v(" "),_c('ar-simple-button',{style:({
              height: '30px',
            }),attrs:{"text":"Delete","outlined":""},on:{"click":_vm.handleFanDelete}})],1)],1),_vm._v(" "),_c('ar-link-button',{attrs:{"text":_vm.$arMediaQuery.window.maxWidth('xs') ? 'Deselect' : 'Clear Selection',"has-underline":"","color":"purple"},on:{"click":_vm.handleDeselectAll}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }