<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      hide-header
      hide-footer
      width="unset"
      background="none"
      cross-icon-position="outside"
      @close="handleModalClose"
      class="message-preview-modal"
      :closeOnBgClick="true"
    >
      <div slot="body" class="body">
        <am2-mobile-container
          :has-footer="false"
          size="fullHeight"
        >
          <am2-email-preview
            v-if="provider === 'email'"
            :sender-name="senderName"
            :subject="subject"
            :preview-text="previewText"
            :email-template="emailTemplate"
            :scheduled-at="scheduledAt"
            opened
            class="preview"
          />
          <am2-sms-preview
            v-else-if="provider === 'sms'"
            :message-body="messageBody"
            :message-image="messageImage"
            :original-text="messageBody"
            class="preview"
          />
          <am2-facebook-messenger-preview
            v-else-if="provider === 'facebook'"
            :page-name="facebookPageName"
            :page-image-src="facebookPageImageSrc"
            :message-body="messageBody"
            :original-text="messageBody"
            class="preview"
          />
        </am2-mobile-container>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'MessagePreviewModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: String,
      validator: (val) => ['email', 'sms', 'facebook'].indexOf(val) > -1,
    },
    messageBody: {
      type: String,
      default: null,
    },
    messageImage: { // For MMS message
      type: String,
      default: null,
    },
    emailTemplate: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: null,
    },
    senderName: {
      type: String,
      default: null,
    },
    previewText: {
      type: String,
      default: null,
    },
    scheduledAt: {
      type: String,
      default: null,
    },
    facebookPageName: {
      type: String,
      default: null,
    },
    facebookPageImageSrc: {
      type: String,
      default: null,
    },
  },

  methods: {
    handleModalClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.message-preview-modal {
  .body {
    height: calc(100vh - 100px);
    max-height: 780px;
  }
}
</style>
