<template>
  <section
      :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
        class="step-back-link"
        text="Back to integrations"
        @click="handleBackLinkClick"/>
    <div
        :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
            title="Eventix"
            description="Automatically sync your members from Eventix"
            :icon-props="{
            name: 'eventix',
            color: $arStyle.color.eventix,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingEventixIntegration && integrationsSummary">
        <am2-integration-account-select
            v-if="!isFetchingEventixIntegration && eventixIntegration && currentIntegration"
            :default-selected-integration-oid="currentIntegration.oid"
            :available-integrations="integrationsSummary"
            @select="handleIntegrationChange"
            @new-account="handleAddNewIntegration"
            :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
            align="left"
            :button-props="{ iconName: 'settings' }"
            :items="[
            {
              name: 'Open Eventix',
              value: 'goToEventixSite',
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
            @select="handleSettingOptionSelect"/>
      </div>
    </div>

    <am2-tabs
        :items="[
          { title: 'Notifications' },
          { title: 'Status' }
        ]"
        :selected-tab-index="selectedTabIndex"
        class="tab-options"
        @select="(selected, idx) => selectedTabIndex = idx"
    />
    <div
        v-if="selectedTabIndex === 0"
    >
      <NotificationsContainer
          :integration="currentIntegration"
          :loading="isFetchingEventixIntegration"
      />
    </div>
    <div
        v-if="selectedTabIndex === 1"
        class="integration-status-container"
    >
      <section v-if="isAddingEventixIntegration || isFetchingEventixIntegration" class="integration-loading-container">
        <am2-loading-bubble/>
      </section>
      <section v-else>
        <section v-if="integrationFailed" class="integration-failed-message">
          <ar-snackbar
              type="warning"
              message="Your Eventix connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
              @anchorClick="handleReconnectIntegration"
              :style="{ width: '100%' }"
          />
        </section>

        <SyncBlockContainer
            v-else
            class="sync-block-container"
            :is-fetching-sync-task="isFetchingSyncTask"
            :sync-status="syncStatus"
            :sync-time="syncTime"
            :sync-copies="{
          'in-progress': 'Syncing data from Eventix',
          'pending': 'The data sync is still pending',
          'completed': 'Your Eventix account is currently connected',
          'cancelled': 'Your Eventix account connection is cancelled',
          'failed': this.syncErrorMessage,
          'default': 'Syncing tasks not found',
        }"
            @startSync="handleStartSync"
            @stopSync="handleStopSync"
        />
      </section>
    </div>

  </section>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';
import NotificationsContainer from '../components/notifications-container';

export default {
  name: 'EventixIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
    NotificationsContainer
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    selectedTabIndex: 1
  }),

  computed: {
    ...mapState({
      eventixIntegration: state => state.eventixIntegration.integration,
      isFetchingEventixIntegration: state => state.eventixIntegration.isFetchingIntegration,
      isAddingEventixIntegration: state => state.eventixIntegration.isAddingIntegration,
      isDeletingEventixIntegration: state => state.eventixIntegration.isDeletingIntegration,
      isFetchingSyncTask: state => state.eventixIntegration.isFetchingSyncTask,
      syncTask: state => state.eventixIntegration.syncTask,
      syncStatus: state => state.eventixIntegration.syncStatus,
    }),

    eventixIntegrationEnabled() {
      return process.env.arEnableEventixIntegration;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.eventixIntegration) return null;
      return this.eventixIntegration
          .filter(i => i.status !== 'failed')
          .map(i => ({
            oid: i.oid,
            name: i.oauthToken?.info?.user?.name,
            email: i.oauthToken?.info?.user?.email,
            company: i.oauthToken?.info?.companies?.[0]?.name,
          }));
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.eventixIntegration || this.eventixIntegration.length < 1 || !this.eventixIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.eventixIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },
  },

  async mounted() {
    if (!this.eventixIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['eventixIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'eventixIntegration/FETCH_INTEGRATION',
      'eventixIntegration/DELETE_INTEGRATION',
      'eventixIntegration/SYNC_START',
      'eventixIntegration/SYNC_STOP',
      'eventixIntegration/FETCH_SYNC_TASK',
      'CONNECT_TO_INTEGRATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'eventixIntegration/RESET_INTEGRATION',
    ]),
    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },
    async fetchIntegration() {
      await this['eventixIntegration/FETCH_INTEGRATION']();
      if (this.eventixIntegration.length === 0) {
        this.$router.push({
          path: '/settings/integrations',
        });
      }
    },

    async fetchTasks() {
      await this['eventixIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    async connectToEventix() {
      try {
        await this.CONNECT_TO_INTEGRATION({
          provider: 'eventix',
          app: 'eventix'
        });
        await this.fetchIntegration();
        this.currentlySelectedIntegrationIndex = this.eventixIntegration.length - 1;
        this.startFetchTasksPolling();
      } catch (e) {
        // If it's closed by user, then it's all good
        if (e.name === 'USER_CLOSE') {
          return;
        }

        this.$arNotification.push({type: 'error', message: `Failed to connect to Eventix: ${e.message}`});
      }
    },

    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({path: '/settings/integrations'});
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;
      this.fetchTasks();
    },

    async handleAddNewIntegration() {
      this.connectToEventix();
    },

    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['eventixIntegration/SYNC_START']({oid: this.currentIntegration.oid});
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    handleStopSync() {
      this['eventixIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleReconnectIntegration() {
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Make sure you are logging in with the Eventix account <strong>${this.currentIntegration.integration.user.attributes.email}</strong> on Eventix before reconnecting it.`,
        confirmButtonText: 'Reconnect account',
      });
      if (!agreed) {
        return;
      }

      const succeed = await this['eventixIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
      if (!succeed) {
        return;
      }

      this.connectToEventix();
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Eventix integration will stop syncing your orders and customer data from Eventix.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['eventixIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
            if (this.eventixIntegration.length > 0) {
              this.currentlySelectedIntegrationIndex = 0;
              await this.fetchIntegration();
              this.startFetchTasksPolling();
            } else {
              this.$router.push({path: '/settings/integrations'});
            }
          } catch (e) {
            this.$arNotification.push({type: 'error', message: 'Failed to remove integration'});
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value === 'goToEventixSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://dashboard.eventix.io', '_blank');
        win.focus();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  .tab-options {
    margin: 60px 0 20px 0;
    border-bottom: 1px $skyBlueGrey500 solid;
  }

  &.sm-max {
    padding: 0 24px;
  }

  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }

  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top: 16px;
      }
    }
  }

  .sync-block-container {
    margin-top: 0px;
  }
}
</style>
