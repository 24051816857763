<template>
  <section data-test-id="companies-page" class="company-page">
    <div
      class="u-margin-top-6"
      v-infinite-scroll="fetchMoreData"
      infinite-scroll-disabled="disableFetchMoreMessages"
      :infinite-scroll-distance="10"
      :style="{
        width: '100%',
      }"
    >
      <am2-top
        :search="{
          action: serverSideSearch,
          placeholder: 'Search promoters by email, name or oid',
          value: searchString,
          style: {
            width: '350px',
          }
        }"
  
        title="Companies"
        :class="[
          'top-wrapper',
          'u-margin-bottom-8',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
      >
        <template v-slot:button>
          <ar-simple-button
            text="Create company"
            icon-name="circle-plus"
            icon-side="left"
            icon-distance="12px"
            :style="{
              width: '180px'
            }"
            data-test-id="create-new-promoter-account"
            @click="handleCreatePromoterClick"
            v-tooltip.bottom="{
              content: 'This should only be used for testing and does not link to chargebee',
            }"
          />
        </template>
      </am2-top>
  
      <div
        v-if="isFetchingPromoters && promoters.length === 0"
        class="loading-container"
      >
        <am2-loading-spinner size="48px" />
      </div>
      <am2-table
        v-else
        :heads="tableHeadings"
        class="message-table"
        :rows="promoters"
        has-sticky-header
        enable-row-click
        has-control-section
        :default-sort-by="sortBy"
        @rowClick="handlePromoterRowClick"
        @sortBy="handleSortBy"
      >
        <div
          slot="oid"
          slot-scope="{ data }"
          class="number-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.oid"
            />
          </div>
        </div>
        <div
          slot="emailAddress"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.emailAddress"
            />
          </div>
        </div>
        <div
          slot="name"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.name"
            />
          </div>
        </div>
        <div
          slot="country"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.country"
            />
          </div>
        </div>
        <div
          slot="companyCategory"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.companyCategory"
            />
          </div>
        </div>
        <div
          slot="sysCtime"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="formattedDate(data.sysCtime)"
            />
          </div>
        </div>
        <div
          slot="menu"
          slot-scope="{ data }"
          class="menu-cell"
        >
          <div class="action-container">
            <am2-icon-button-dropdown
              v-if="!data.hideActions"
              :icon-props="{
                name: 'menu',
              }"
              :items="messageOptions"
              @select="(item) => handleMessageOptionSelect(item.eventName, data)"
            />
          </div>
        </div>
      </am2-table>
    </div>

  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { generateMessageCenterDateCopy } from '@/utils/date';
import { sanitizeHtmlChild } from '@/utils/html-element/';

export default {
  name: 'Companies',
  layout: 'default',

  data: () => ({
    searchString: '',
    sortBy: { key: 'name', order: 'asc' },
  }),

  computed: {
    ...mapState({
      promoters: state => state.admin.promoters,
      isFetchingPromoters: state => state.admin.isFetchingPromoters,
    }),

    ...mapGetters ({
      isAdminAccount: 'auth/isAdminAccount'
    }),

    tableHeadings() {
      return [
        {
          name: 'OID',
          key: 'oid',
          format: 'oid',
          align: 'left',
          width: 75,
          sortKey: 'oid'
        },
        {
          name: 'Company Name',
          key: 'name',
          format: 'name',
          align: 'left',
          width: 162,
          sortKey: 'name'
        },
        {
          name: 'Email',
          key: 'emailAddress',
          format: 'emailAddress',
          align: 'left',
          width: 233,
          sortKey: 'emailAddress'
        },
        {
          name: 'Region',
          key: 'country',
          format: 'country',
          align: 'left',
          width: 75,
          sortKey: 'country'
        },
        {
          name: 'Type',
          key: 'companyCategory',
          format: 'companyCategory',
          align: 'left',
          width: 162,
          sortKey: 'companyCategory'
        },
        {
          name: 'Created',
          key: 'sysCtime',
          format: 'sysCtime',
          align: 'left',
          width: 190,
          sortKey: 'sysCtime'
        },
        {
          key: 'menu',
          format: 'menu',
          align: 'left',
          width: 32,
        }
      ];
    },

    messageOptions() {
      return [
        {
          name: 'Permissions',
          eventName: 'permissions',
        },
        {
          name: 'Users',
          eventName: 'users',
        },
        {
          name: 'Messages',
          eventName: 'messages',
        },
        {
          name: 'Events',
          eventName: 'events',
        },
        {
          name: 'Account Fixes',
          eventName: 'accountFixes',
        },
        {
          name: 'Edit',
          eventName: 'edit',
        },
        {
          name: 'Delete',
          eventName: 'delete',
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          },
        }
      ]
    },
  },

  mounted() {
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }
  },

  methods: {
    ...mapActions([
      'admin/FETCH_MORE_PROMOTERS',
      'admin/DELETE_PROMOTER',
      'SHOW_CONFIRM',
    ]),

    async handleDeleteClick(promoter) {
      await this.SHOW_CONFIRM({
        title: 'Delete this promoter?',
        messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(promoter.name)}</b>. Are you sure you want to proceed?`,
        confirmButtonText: 'Delete',
        validation: {
          question: `Type ${promoter.emailAddress} to confirm`,
          answer: promoter.emailAddress,
        },
        asyncCompleteFunction: async () => {
          await this['admin/DELETE_PROMOTER']({ oid: promoter.oid })
          this.fetchPromoters(true)
        },
      })
    },

    handleMessageOptionSelect(event, promoter) {
      const goTo = (route) => {
        this.$router.push({
          path: `/admin/companies/${promoter.oid}/edit/${route}`,
        })
      }

      switch (event) {
        case 'permissions':
          goTo('permissions') 
          break
        case 'users':
          goTo('accounts') 
          break
        case 'messages':
          goTo('messages') 
          break
        case 'events':
          goTo('events')
          break
        case 'campaigns':
          goTo('campaigns')
          break
        case 'edit':
          goTo('overview')
          break
        case 'accountFixes':
          this.$router.push({
            path: `/admin/fixes?promoter=${promoter.oid}`,
          })
          break
        case 'delete':
          this.handleDeleteClick(promoter)
          break
      }
    },

    handleSortBy(sortBy) {
      this.sortBy = sortBy
      this.fetchPromoters(true)
    },

    handleCreatePromoterClick() {
      this.$router.push({
        path: `/admin/companies/new/`
      });
    },

    formattedDate(date) {
      return generateMessageCenterDateCopy(date)
    },

    handlePromoterRowClick(promoter) {
      this.$router.push({
        path: `/admin/companies/${promoter.oid}/edit/overview`
      });
    },

    fetchMoreData() {
      if (!this.isFetchingPromoters) {
        this.fetchPromoters()
      }
    },

    serverSideSearch(search) {
      this.searchString = search;
      this.fetchPromoters(true)
    },

    fetchPromoters(reload=false) {
      const { key, order } = this.sortBy

      this['admin/FETCH_MORE_PROMOTERS']({
        select: 'name,oid,emailAddress,country,companyCategory,sysCtime',
        orderby: `${key} ${order}`,
        reload,
        count: reload,
        filterString: this.searchString,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.company-page {
  width: 100%;
  max-width: 100%;
}

.top-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.message-cell, .number-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-cell {
  padding: 0 12px;
  overflow: hidden;
  justify-content: flex-start;
}

.number-cell {
  padding: 0 17px;
}

.menu-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.action-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>