var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-email-template-wrapper"},[_c('am2-tabs',{staticClass:"email-template-tabs",attrs:{"id":"email-template-tabs","selected-tab-index":_vm.selectedTemplateSourceIndex,"items":_vm.templateSourceItems},on:{"select":_vm.handleTemplateSourceSelect}}),_vm._v(" "),_c('ar-divider',{staticClass:"template-tabs-divider"}),_vm._v(" "),(_vm.hasNoEmailTemplates)?_c('am2-no-content-section',{staticClass:"template-no-content-wrapper",style:({
      marginTop: '22px',
    }),attrs:{"id":"email-template-no-content","header":"No templates found","header-size":"sm"}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreTemplates),expression:"loadMoreTemplates"}],class:[
      'templates-wrapper',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"id":"email-templates-wrapper","infinite-scroll-distance":10}},[_vm._l((_vm.emailTemplates),function(emailTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTemplateSource === 'bucket'),expression:"selectedTemplateSource === 'bucket'"}],key:emailTemplate.oid,ref:"template-card",refInFor:true,class:[
        'template-card',
        _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"id":("email-template-card-" + (emailTemplate.oid)),"data-test-id":("email-template-card-" + (emailTemplate.oid)),"name":!!emailTemplate.meta ? emailTemplate.meta.name : '',"description":!!emailTemplate.meta ? emailTemplate.meta.description : '',"template":!!emailTemplate.meta ? emailTemplate.meta.template : null,"template-html":_vm.getEmailTemplateHtml(emailTemplate),"thumbnail-url":emailTemplate.meta ? emailTemplate.meta.thumbnailUrl : null,"type":emailTemplate.meta ? emailTemplate.meta.templateType : null,"has-dropdown":false,"disabled":!_vm.getEmailTemplateHtml(emailTemplate)},on:{"click":function($event){_vm.handleTemplateCardClick(emailTemplate, _vm.getEmailTemplateHtml(emailTemplate))}}})}),_vm._v(" "),_vm._l((_vm.unlayerTemplates),function(emailTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTemplateSource === 'unlayer'),expression:"selectedTemplateSource === 'unlayer'"}],key:emailTemplate.id,ref:"template-card",refInFor:true,class:[
        'template-card',
        _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"id":("unilayer-template-card-" + (emailTemplate.id)),"data-test-id":("unilayer-template-card-" + (emailTemplate.id)),"name":emailTemplate.name,"template":emailTemplate.design,"template-html":_vm.getUnlayerHtml(emailTemplate.design),"type":"unlayer","has-dropdown":false,"disabled":!_vm.getUnlayerHtml(emailTemplate.design)},on:{"click":function($event){_vm.handleUnlayerTemplateCardClick(emailTemplate, _vm.getUnlayerHtml(emailTemplate.design))}}})}),_vm._v(" "),_vm._l((_vm.beefreeTemplates),function(emailTemplate){return _c('am2-template-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTemplateSource === 'beefree'),expression:"selectedTemplateSource === 'beefree'"}],key:emailTemplate.id,ref:"template-card",refInFor:true,class:[
        'template-card',
        _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"id":("beefree-template-card-" + (emailTemplate.id)),"data-test-id":("beefree-template-card-" + (emailTemplate.id)),"name":emailTemplate.name,"template":emailTemplate.design,"template-html":emailTemplate.url,"thumbnailUrl":emailTemplate.thumbnailUrl,"type":"beefree","has-dropdown":false,"disabled":!(emailTemplate.design || emailTemplate.url)},on:{"click":function($event){return _vm.handleBeefreeTemplateCardClick(emailTemplate, emailTemplate.url)}}})}),_vm._v(" "),_vm._l((_vm.skeletonCardNumber),function(n){return _c('am2-card-skeleton',{key:n,class:[
        'template-card',
        _vm.$arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"type":"email-template"}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }