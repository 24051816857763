<template>
    <section
      :class="[
        'wrapper',
        'content',
        'content--centered',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]">
      <am2-step-link
        class="step-back-link"
        text="Back to integrations"
        @click="handleBackLinkClick" />
      <am2-ticketmaster-modal
        :is-show="showTicketmasterCredentialsModal"
        :loading="isCreatingTicketmasterIntegration"
        @confirm="handleTicketmasterCredentialsConfirm"
        @cancel="showTicketmasterCredentialsModal = false"
      />
      <am2-opt-in-settings-modal
        :is-show="showOptinSettingsModal"
        @cancel="showOptinSettingsModal = false"
        providerName="Ticketmaster (AU/NZ)"
        :message-list-oid="currentMessageListOid"
        :accountName="currentAccountName"
      />
      <div
        :class="[
          'title-section',
          $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        ]">
        <div class="title-section-left">
          <am2-icon-title-section
            title="Ticketmaster (AU/NZ)"
            description="Automatically sync your data from Ticketmaster (AU/NZ)"
            :icon-props="{
              name: 'ticketmaster',
              color: $arStyle.color.tryBooking,
              height: '46px',
              width: '40px',
            }"
          />
        </div>
        <div class="title-section-right" v-if="!isFetchingTicketmasterIntegration && integrationsSummary">
          <am2-integration-account-select
            v-if="!isFetchingTicketmasterIntegration && ticketmasterIntegration && currentIntegration"
            :default-selected-integration-oid="currentIntegration.oid"
            :available-integrations="integrationsSummary"
            @select="handleIntegrationChange"
            @new-account="handleAddNewIntegration"
            :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
          />
          <am2-expand-button-dropdown
            align="left"
            :button-props="{ iconName: 'settings' }"
            :items="[
              {
                name: 'Connection Settings',
                value: 'openSettingsModal',
              }
            ]"
            @select="handleSettingOptionSelect" />
        </div>
      </div>
      <am2-tabs
        :items="[
          { title: 'Notifications' },
          { title: 'Status' }
        ]"
        :selected-tab-index="selectedTabIndex"
        class="tab-options"
        @select="(selected, idx) => selectedTabIndex = idx"
      />
      <div
        v-if="selectedTabIndex === 0"
      >
        <NotificationsContainer
          :integration="currentIntegration"
          :loading="isFetchingTicketmasterIntegration"
        />
      </div>
      <div
        v-if="selectedTabIndex === 1"
        class="integration-status-container"
      >
        <section v-if="isCreatingTicketmasterIntegration || isFetchingTicketmasterIntegration" class="integration-loading-container">
          <am2-loading-bubble />
        </section>
        <section v-else>
          <section v-if="integrationFailed" class="integration-failed-message">
            <ar-snackbar
              type="warning"
              message="Your Ticketmaster (AU/NZ) connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
              @anchorClick="handleReconnectIntegration"
              :style="{ width: '100%' }"
            />
          </section>

          <SyncBlockContainer
            v-else
            class="sync-block-container"
            :is-fetching-sync-task="isFetchingSyncTask"
            :sync-status="calculatedSyncStatus"
            :sync-time="syncTime"
            :sync-copies="{
              'in-progress': 'Syncing data from Ticketmaster (AU/NZ)',
              'pending': 'The data sync is still pending',
              'completed': 'Your Ticketmaster (AU/NZ) account is currently connected',
              'cancelled': 'Your Ticketmaster (AU/NZ) account connection is cancelled',
              'failed': this.syncErrorMessage,
              'pending-setup': 'Please contact Audience Republic via support@audiencerepublic.com to finish connecting your Ticketmaster (AU/NZ) account.',
              'default': 'Syncing tasks not found',
            }"
            :copy-size="'sm'"
            @startSync="handleStartSync"
            @stopSync="handleStopSync"
          />
        </section>
      </div>

    </section>
  </template>

  <script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import SyncBlockContainer from '../components/sync-block-container';
  import NotificationsContainer from '../components/notifications-container';

  export default {
    name: 'TicketmasterIntegration',
    layout: 'default',
    components: {
      SyncBlockContainer,
      NotificationsContainer
    },

    data: () => ({
      currentlySelectedIntegrationIndex: 0,
      syncStatusPoller: null,
      showTicketmasterCredentialsModal: false,
      currentMessageListOid: null,
      showOptinSettingsModal: false,
      selectedTabIndex: 1
    }),

    computed: {
      ...mapState({
        ticketmasterIntegration: state => state.ticketmasterIntegration.integration,
        isFetchingTicketmasterIntegration: state => state.ticketmasterIntegration.isFetchingIntegration,
        isAddingTicketmasterIntegration: state => state.ticketmasterIntegration.isAddingIntegration,
        isCreatingTicketmasterIntegration: state => state.ticketmasterIntegration.isCreatingIntegration,
        isDeletingTicketmasterIntegration: state => state.ticketmasterIntegration.isDeletingIntegration,
        isFetchingSyncTask: state => state.ticketmasterIntegration.isFetchingSyncTask,
        syncTask: state => state.ticketmasterIntegration.syncTask,
        syncStatus: state => state.ticketmasterIntegration.syncStatus,
      }),

      ticketmasterIntegrationEnabled() {
        return process.env.arEnableTicketmasterIntegration;;
      },

      syncErrorMessage() {
        return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
      },

      syncTime() {
        return this.syncTask?.sysMtime || null;
      },

      integrationsSummary() {
        if (!this.ticketmasterIntegration) return null;
        const summary = this.ticketmasterIntegration
          .map(i => ({
            oid: i.oid,
            name: i.integration.accountName || 'Default Account',
            email: i.accountId,
            mailingListOid: i.integration?.mailingListOid
          }));

        if (this.currentlySelectedIntegrationIndex != null) {
          this.currentMessageListOid = summary[this.currentlySelectedIntegrationIndex].mailingListOid;
        }
        return summary;
      },

      currentIntegration() {
        if (this.currentlySelectedIntegrationIndex === null) return null;
        if (!this.ticketmasterIntegration || this.ticketmasterIntegration.length < 1 || !this.ticketmasterIntegration[this.currentlySelectedIntegrationIndex]) return null;
        return this.ticketmasterIntegration[this.currentlySelectedIntegrationIndex];
      },

      integrationFailed() {
        if (!this.currentIntegration) return false;
        return this.currentIntegration.status === 'failed';
      },

      calculatedSyncStatus() {
        return this.currentIntegration && this.currentIntegration.status == 'pending-setup' ? 'pending-setup' : this.syncStatus;
      },

      currentAccountName() {
        return this.currentIntegration?.integration?.accountName || 'Default Account'
      }

    },

    async mounted() {
      if (!this.ticketmasterIntegrationEnabled) {
        this.handleBackLinkClick();
      }
      await this.fetchIntegration();
      this.startFetchTasksPolling();
    },

    beforeDestroy() {
      this['ticketmasterIntegration/RESET_INTEGRATION']();

      if (this.syncStatusPoller) {
        clearInterval(this.syncStatusPoller);
      }
    },

    methods: {
      ...mapActions([
        'SHOW_CONFIRM',
        'ticketmasterIntegration/FETCH_INTEGRATION',
        'ticketmasterIntegration/DELETE_INTEGRATION',
        'ticketmasterIntegration/SYNC_START',
        'ticketmasterIntegration/SYNC_STOP',
        'ticketmasterIntegration/FETCH_SYNC_TASK',
        'ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION',
        'promoterTasks/START_POLLING_PENDING_TASKS',
      ]),
      ...mapMutations([
        'ticketmasterIntegration/RESET_INTEGRATION',
      ]),
      startFetchTasksPolling() {
        if (this.currentIntegration?.oid && this.currentIntegration.status !== 'pending-setup' && !this.isFetchingSyncTask) {
          this.fetchTasks();
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },
      async fetchIntegration() {
        await this['ticketmasterIntegration/FETCH_INTEGRATION']();
      },

      async fetchTasks() {
        await this['ticketmasterIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

        if (this.currentIntegration && this.currentIntegration.status !== 'pending-setup' && this.hasExistingTask && this.syncStatus !== "in-progress") {
          window.clearInterval(this.syncStatusPoller);
          this.syncStatusPoller = null;
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }
      },

      async handleTicketmasterCredentialsConfirm({ providerName, mailingListOid }) {
        const succeed = await this['ticketmasterIntegration/CREATE_TICKETMASTER_INTEGRATION']({
            providerName,
            mailingListOid
        });
        if (succeed) {
          this.fetchIntegration();

          this.showTicketmasterCredentialsModal = false;
        }
      },


      hasExistingTask() {
        return this.syncTask !== null;
      },

      handleBackLinkClick() {
        this.$router.push({ path: '/settings/integrations' });
      },

      handleIntegrationChange(integration, index) {
        this.currentlySelectedIntegrationIndex = index;
        this.fetchTasks();
      },

      async handleAddNewIntegration() {
        this.showTicketmasterCredentialsModal = true;
      },

      async handleStartSync() {
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
        await this['ticketmasterIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
        if (this.syncStatus !== 'completed') {
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },

      handleStopSync() {
        this['ticketmasterIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
      },

      async handleReconnectIntegration() {
        const agreed = await this.SHOW_CONFIRM({
          messageHtml: `Make sure you are logging in with the Ticketmaster account <strong>${this.currentIntegration.accountId}</strong> on Ticketmaster (AU/NZ) before reconnecting it.`,
          confirmButtonText: 'Reconnect account',
        });
        if (!agreed) {
          return;
        }

        const succeed = await this['ticketmasterIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
        if (!succeed) {
          return;
        }

        this.handleAddNewIntegration();
      },

      async handleSettingOptionSelect(item) {
        if (item.value === 'removeIntegration') {
          const answer = await this.SHOW_CONFIRM({
            messageHtml: 'Removing the Ticketmaster integration will stop syncing your data from Ticketmaster (AU/NZ).',
            confirmButtonText: 'Remove integration',
            iconName: 'alert-question-mark',
            title: 'Are you sure?',
          });
          if (answer) {
            try {
              await this['ticketmasterIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
              if (this.ticketmasterIntegration.length > 0) {
                this.currentlySelectedIntegrationIndex = 0;
                await this.fetchIntegration();
                this.startFetchTasksPolling();
              } else {
                this.$router.push({ path: '/settings/integrations' });
              }
            } catch (e) {
              this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
            }
          }
        } else if (item.value === 'reconnectIntegration') {
          this.handleReconnectIntegration();
        } else if (item.value == 'openSettingsModal') {
          if (!this.currentIntegration) return;

          this.showOptinSettingsModal = true;
        }
      },
    }
  };
  </script>

  <style lang="scss" scoped>
  .wrapper {

    .tab-options {
      margin: 60px 0 20px 0;
      border-bottom: 1px $skyBlueGrey500 solid;
    }

    &.sm-max {
      padding: 0 24px;
    }
    &.xs-max {
      padding: 0 12px;
    }

    .integration-loading-container {
      display: flex;
      justify-content: center;
      margin-top: 65px;
    }


    .step-back-link {
      margin-top: 48px;
    }

    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 48px;

      .title-section-left {
        display: inline-flex;
        align-items: flex-start;

        .tag {
          position: relative;
          margin-left: 10px;
          top: 4px;
        }
      }

      .title-section-right {
        display: inline-flex;
        align-items: center;

        .select {
          width: 300px;
          margin-right: 10px;
        }
      }

      &.md-max {
        flex-direction: column;
        align-items: flex-start;

        .title-section-right {
          margin-top:16px;
        }
      }
    }

    .sync-block-container {
      margin-top:0px;
    }
  }
  </style>
