var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"verify-domain-modal",attrs:{"is-open":_vm.isShow,"header":"Domain authentication","width":"964px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'verify-domain-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[(!!_vm.data)?_c('div',{staticClass:"domain-table-section"},[_c('table',{staticClass:"domain-table"},[_c('tr',{staticClass:"header"},[_c('th',{staticClass:"table-heading"},[_vm._v("Type")]),_vm._v(" "),_c('th',{staticClass:"table-heading"},[_vm._v("Host")]),_vm._v(" "),_c('th',{staticClass:"table-heading"},[_vm._v("Points to")]),_vm._v(" "),_c('th',{staticClass:"table-heading"})]),_vm._v(" "),_vm._l((_vm.data.hostRecords),function(item,key){return _c('tr',{key:key,staticClass:"domain"},[_c('td',[_c('ar-text',{style:({
                  textTransform: 'uppercase',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '60px',
                }),attrs:{"size":"xs","text":item.type}})],1),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-data"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                    content: item.host,
                    classes: ['tooltip']
                  }),expression:"{\n                    content: item.host,\n                    classes: ['tooltip']\n                  }",modifiers:{"top":true}}],style:({
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '228px',
                  }),attrs:{"size":"xs","text":item.host}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-left-3",style:({
                      height: '32px',
                      width: '83px',
                    }),attrs:{"outlined":true,"text":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['hostCopy']) ? 'Copied' : 'Copy',"type":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['hostCopy']) ? 'green' : 'purple'},on:{"click":function () { return _vm.copyItem(key, 'hostCopy', item.host); }}})],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-data"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                    content: item.data,
                  }),expression:"{\n                    content: item.data,\n                  }",modifiers:{"top":true}}],style:({
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '228px',
                  }),attrs:{"size":"xs","text":item.data}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-left-3",style:({
                    height: '32px',
                    width: '83px',
                  }),attrs:{"outlined":true,"text":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['dataCopy']) ? 'Copied' : 'Copy',"type":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['dataCopy']) ? 'green' : 'purple'},on:{"click":function () { return _vm.copyItem(key, 'dataCopy', item.data); }}})],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-data"},[(!!item.valid)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: 'Record has been verified'}),expression:"{content: 'Record has been verified'}",modifiers:{"top":true}}],style:({
                      border: ("2px solid " + (_vm.$arStyle.color.green500)),
                      borderRadius: "50%",
                      padding: "2px",
                      width: '23px',
                      height: '23px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    })},[_c('ar-icon',{attrs:{"name":"check","height":"11px","color":_vm.$arStyle.color.green500}})],1):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: 'Record not verified'}),expression:"{content: 'Record not verified'}",modifiers:{"top":true}}],style:({
                      border: "2px solid #FF8B25",
                      borderRadius: "50%",
                      padding: "2px",
                      width: '23px',
                      height: '23px'
                    })},[_c('ar-text',{style:({color: '#FF8B25', cursor: 'default'}),attrs:{"size":"15px","text":"!","weight":"bold","align":"center"}})],1)])])])})],2)]):_vm._e(),_vm._v(" "),_c('div',{style:({textAlign: 'end'})},[_c('ar-simple-button',{staticClass:"u-margin-left-3",style:({
            height: '32px',
            width: '106px',
            marginTop: '10px',
            marginLeft: 0,
            float: 'right',
          }),attrs:{"outlined":true,"type":_vm.copyAllText === 'Copied' ? 'green' : 'purple',"text":_vm.copyAllText},on:{"click":_vm.handleCopyAllClick}})],1)]),_vm._v(" "),_c('div',{class:[
        'verify-domain-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"connection-guide"},[_c('ar-link-button',{attrs:{"text":"View our connection guide"},on:{"click":function () { return _vm.connectionGuideClick(); }}}),_vm._v(" "),_c('ar-icon',{staticClass:"u-margin-left-1",attrs:{"name":"external-link-bold","width":"16px","color":_vm.$arStyle.color.purple500}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }