import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Get Twilio SMS Sender for promoter
   * @param promoterOid
   * @returns
   */
  async get(promoterOid: number): Promise<TwilioSMSSender[] | null> {
    const { status, data } = await axios.get(`/promoter/${promoterOid}/sms-sender`, {
      params: {
        $filter: `promoterOid=${promoterOid}`
      }
    });
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Twilio SMS Sender ID error',
        message: `Error getting Twilio SMS Sender ID with for promoter ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Update Twilio SMS Sender
   * @param promoterOid, smsSenderOid
   * @returns
   */
  async patch(promoterOid: number, smsSenderOid: number, accountObj: object): Promise<PromoterAccount | null> {
    const { status, data } = await axios.post(`/promoter/${promoterOid}/sms-sender-management`, accountObj);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Update Twilio SMS Sender ID error',
        message: `Error updating Twilio SMS Sender ID for promoter ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Create Twilio SMS Sender
   * @returns
   */
  async post(promoterOid: number, accountObj: object): Promise<PromoterAccount | null> {
    const { status, data } = await axios.post(`/promoter/${promoterOid}/sms-sender-management`, accountObj);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Create Twilio SMS Sender ID error',
        message: `Error creating a new Twilio Sender Id under Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
  /**
   * Delete Twilio SMS Sender
   * @returns
   */
  async delete(promoterOid: number, smsSenderOid: number): Promise<PromoterAccount | null> {
    const { status, data } = await axios.delete(`/promoter/${promoterOid}/sms-sender-management/${smsSenderOid}`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Delete Twilio SMS Sender ID error',
        message: `Error deleting Twilio Sender ID ${smsSenderOid} under Promoter Account with oid of ${promoterOid}`,
        status,
      };
      throw apiError;
    }
    return data;
  },
});
