<template>
  <div :class="['additional-constraints-wrapper']">
    <CampaignConstraint
      v-if="hasCampaignConstraint"
      :constraint-condition="campaignConstraintCondition"
      :constraint-values="campaignConstraintValues"
      :constraint-predicates="campaignConstraintPredicates"
      :filter-condition-name="filterCondition.name"
      @constraintUpdate="item => handleConstraintUpdate('campaign', item)"
    />
    <EventConstraint
      v-if="hasEventConstraint"
      :constraint-condition="eventConstraintCondition"
      :constraint-values="eventConstraintValues"
      :constraint-predicates="eventConstraintPredicates"
      :filter-condition-name="filterCondition.name"
      @constraintUpdate="item => handleConstraintUpdate('event', item)"
    />
    <MessageConstraint
      v-if="hasMessageConstraint"
      :constraint-condition="messageConstraintCondition"
      :constraint-values="messageConstraintValues"
      :filter-condition-name="filterCondition.name"
      :constraint-default-options="messageConstraintDefaultOptions"
      @constraintUpdate="item => handleConstraintUpdate('message', item)"
    />
    <PeriodConstraint
      v-if="hasPeriodConstraint"
      :constraint-condition="periodConstraintCondition"
      :constraint-values="periodConstraintValues"
      :constraint-predicates="periodConstraintPredicates"
      @constraintUpdate="item => handleConstraintUpdate('period', item)"
    />
    <LoyaltyProgramConstraint
      v-if="hasLoyaltyProgramConstraint"
      :constraint-condition="loyaltyProgramConstraintCondition"
      :constraint-values="loyaltyProgramConstraintValues"
      :constraint-default-options="loyaltyProgramConstraintDefaultOptions"
      :filter-condition-name="filterCondition.name"
      @constraintUpdate="item => handleConstraintUpdate('loyaltyProgram', item)"
    />
    <LoyaltyTierConstraint
      v-if="hasLoyaltyTierConstraint"
      :constraint-value="loyaltyTierConstraintValue"
      :loyalty-membership-oids="loyaltyProgramConstraintValues"
      @constraintUpdate="item => handleConstraintUpdate('loyaltyTier', item)"
    />
  </div>
</template>

<script>
import CampaignConstraint from './CampaignConstraint'
import EventConstraint from './EventConstraint'
import LoyaltyProgramConstraint from './LoyaltyProgramConstraint'
import LoyaltyTierConstraint from './LoyaltyTierConstraint'
import MessageConstraint from './MessageConstraint'
import PeriodConstraint from './PeriodConstraint'


export default {
  name: 'AdditionalConstraintRenderer',
  components: {
    CampaignConstraint,
    EventConstraint,
    MessageConstraint,
    PeriodConstraint,
    LoyaltyProgramConstraint,
    LoyaltyTierConstraint,
  },

  props: {
    filterConstraints: {
      type: Object,
      default: null
    },
    filterCondition: {
      type: Object,
      default: () => {}
    },
    mandatoryConstraints: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    // Campaign
    hasCampaignConstraint() {
      if (this.mandatoryConstraints && !this.filterConstraints?.campaign?.mandatory) return false;
      if (!this.mandatoryConstraints && this.filterConstraints?.campaign?.mandatory) return false;
      return this.filterConstraints?.campaign?.enabled;
    },
    campaignConstraintCondition() {
      return this.filterCondition?.constraints?.campaign?.condition;
    },
    campaignConstraintValues() {
      return this.filterCondition?.constraints?.campaign?.values;
    },
    campaignConstraintPredicates() {
      return this.filterConstraints?.campaign?.predicates || [];
    },

    // Event
    hasEventConstraint() {
      if (this.mandatoryConstraints && !this.filterConstraints?.event?.mandatory) return false;
      if (!this.mandatoryConstraints && this.filterConstraints?.event?.mandatory) return false;
      return this.filterConstraints?.event?.enabled;
    },
    eventConstraintCondition() {
      return this.filterCondition?.constraints?.event?.condition;
    },
    eventConstraintValues() {
      return this.filterCondition?.constraints?.event?.values;
    },
    eventConstraintPredicates() {
      return this.filterConstraints?.event?.predicates || [];
    },

    // Message
    hasMessageConstraint() {
      if (this.mandatoryConstraints && !this.filterConstraints?.message?.mandatory) return false;
      if (!this.mandatoryConstraints && this.filterConstraints?.message?.mandatory) return false;
      return this.filterConstraints?.message?.enabled;
    },
    messageConstraintCondition() {
      return this.filterCondition?.constraints?.message?.condition;
    },
    messageConstraintValues() {
      return this.filterCondition?.constraints?.message?.values;
    },
    messageConstraintDefaultOptions() {
      return this.filterConstraints?.message?.data || [];
    },

    // Period
    hasPeriodConstraint() {
      if (this.mandatoryConstraints && !this.filterConstraints?.period?.mandatory) return false;
      if (!this.mandatoryConstraints && this.filterConstraints?.period?.mandatory) return false;
      return this.filterConstraints?.period?.enabled;
    },
    periodConstraintCondition() {
      return this.filterCondition?.constraints?.period?.condition;
    },
    periodConstraintValues() {
      return this.filterCondition?.constraints?.period?.values;
    },
    periodConstraintPredicates() {
      return this.filterConstraints?.period?.predicates || [];
    },

    // Loyalty Program
    hasLoyaltyProgramConstraint() {
      if (this.mandatoryConstraints && !this.filterConstraints?.loyaltyProgram?.mandatory) return false;
      if (!this.mandatoryConstraints && this.filterConstraints?.loyaltyProgram?.mandatory) return false;
      return this.filterConstraints?.loyaltyProgram?.enabled;
    },
    loyaltyProgramConstraintCondition() {
      return this.filterCondition?.constraints?.loyaltyProgram?.condition;
    },
    loyaltyProgramConstraintValues() {
      return this.filterCondition?.constraints?.loyaltyProgram?.values;
    },
    loyaltyProgramConstraintDefaultOptions() {
      return this.filterConstraints?.loyaltyProgram?.data || [];
    },

    // Loyalty Tier
    hasLoyaltyTierConstraint() {
      if (this.mandatoryConstraints && !this.filterConstraints?.loyaltyTier?.mandatory) return false;
      if (!this.mandatoryConstraints && this.filterConstraints?.loyaltyTier?.mandatory) return false;
      return this.filterConstraints?.loyaltyTier?.enabled;
    },
    loyaltyTierConstraintValue() {
      if (this.filterCondition?.constraints?.loyaltyTier?.values && this.filterCondition?.constraints?.loyaltyTier?.values.length > 0) {
        return this.filterCondition?.constraints?.loyaltyTier?.values[0]
      }
      return 'all';
    },
  },

  methods: {
    handleConstraintUpdate(constraint, data) {
      const newData = {
        constraint,
        key: data.key,
        values: data.values,
      };
      this.$emit('constraintUpdate', newData)
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
