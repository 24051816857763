var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-modal',{staticClass:"delete-modal",attrs:{"is-open":_vm.showModal,"width":"500px","hide-header":"","hide-footer":"","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
      'delete-modal-body',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"exclamation-mark"},[_c('ar-icon',_vm._b({},'ar-icon',{
          name: 'discard',
          color: this.$arStyle.color.red500,
          width: '34px',
          height: '34px',
          wrapperStyle: {
            backgroundColor: this.$arStyle.color.red100,
            borderRadius: '50%',
            width: '70px',
            height: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
        },false))],1),_vm._v(" "),(_vm.title)?_c('ar-text',{staticClass:"delete-modal-title",attrs:{"text":_vm.title,"multiple-lines":"","weight":"bold","align":"center","size":"md"}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"warning-modal-message",style:({
        marginBottom: _vm.messageHtml ? '25px' : '8px',
        color: '#8E97A6'
      }),attrs:{"size":"xs","text":_vm.messageHtml,"allow-html":"","multiple-lines":"","line-height":"25px","align":"center"}}),_vm._v(" "),(_vm.validation)?_c('div',{staticClass:"validation-section"},[_c('ar-input',{staticClass:"input",attrs:{"placeholder":_vm.validation.question,"data-test-id":"warning-modal-validation-input"},model:{value:(_vm.validationAnswer),callback:function ($$v) {_vm.validationAnswer=$$v},expression:"validationAnswer"}})],1):_vm._e(),_vm._v(" "),(_vm.validation)?_c('div',{class:['delete-modal-button-wrapper', _vm.$arMediaQuery.window.maxWidth('xs') && 'xs-max'],style:({
        flexDirection: _vm.cancelType === 'link' ? 'column' : 'row',
        alignItems: _vm.cancelType === 'link' ? 'center' : null,
      })},[_c('ar-simple-button',_vm._b({attrs:{"loading":_vm.loading,"disabled":!_vm.isValidationConfirmButtonValid},on:{"click":_vm.handleConfirmClick}},'ar-simple-button',_vm.confirmButtonPropsComputed,false)),_vm._v(" "),(_vm.cancelType === 'button')?_c('ar-simple-button',_vm._b({class:_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'u-margin-left-2' : 'u-margin-top-3',attrs:{"loading":_vm.loading},on:{"click":_vm.handleCloseClick}},'ar-simple-button',_vm.cancelButtonPropsComputed,false)):(_vm.cancelType === 'link')?_c('ar-link-button',_vm._b({staticClass:"u-margin-top-5",attrs:{"disabled":_vm.loading},on:{"click":_vm.handleCloseClick}},'ar-link-button',_vm.cancelButtonPropsComputed,false)):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }