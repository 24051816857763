<template>
  <AuthenticateFrame>
    <ar-text
      text="This email is linked to an account"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '16px',
      }"
    />
    <ar-text
      text="If you did not receive an email click the link below"
      size="sm"
      multiple-lines
      :style="{
        color: $arStyle.color.skyBlueGrey700,
        marginBottom: '40px',
      }"
    />
    <ar-simple-button
      type="gradient-purple"
      shape="pill"
      text="Resend invite"
      :style="{
        width: '100%',
        marginBottom: '20px',
      }"
      @click="resendInvite"
      :disabled="!canResend"
    />
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AuthenticateFrame from '../authenticate-frame';

export default {
  name: 'invite-sent',
  
  layout: 'split',
  components: {
    AuthenticateFrame,
  },
  computed: {
    ...mapState({
      email: state => state.route.query.email
    }),
  },
  data() {
    return {
      canResend: true,
      MILLISECOND_THRESHOLD: 300000,
    }
  },
  mounted() {
    // Update layout options
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);

    // Check if 5 minutes has passed from the invite timestamp in localStorage
    const inviteTimestamps = window.localStorage.getItem('_ar_resend_invite_timestamp_')
    const parsedTimestamp = inviteTimestamps ? JSON.parse(inviteTimestamps) : null
    const timestampNow = new Date()

    let diffTimestamps = null

    if (!!parsedTimestamp) {
      const selectedTimestampIndex = this.findEmailAmongTimestampObjects(parsedTimestamp)

      if (!!selectedTimestampIndex && selectedTimestampIndex >= 0) {
        const selectedTimestamp = new Date(parsedTimestamp[selectedTimestampIndex].timestamp)
        // getTime() returns milliseconds
        diffTimestamps = timestampNow.getTime() - selectedTimestamp.getTime()
      }
    }

    if (!this.email || (diffTimestamps && (diffTimestamps < this.MILLISECOND_THRESHOLD))) {
      this.canResend = false;
    }
  },
  methods: {
    ...mapActions([
      'auth/RESEND_INVITE',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
    async resendInvite() {
      const success = await this['auth/RESEND_INVITE'](this.$route.query.email)

      if (success) {
        this.$arNotification.push({ type: 'success', message: `An invite has been sent to ${this.$route.query.email}.` });
        this.beginCounter()
      }
    },
    beginCounter() {
      this.canResend = false;

      // Timestamp is stored in local storage to prevent access to the reset button 
      window.localStorage.setItem('_ar_resend_invite_timestamp_', JSON.stringify(this.arrangeTimestampObject()));

      setTimeout(() => {
        this.canResend = true;
      }, this.MILLISECOND_THRESHOLD);
    },
    arrangeTimestampObject() {
      const currentTimestamp = new Date()
      const inviteTimestamps = window.localStorage.getItem('_ar_resend_invite_timestamp_')
      const parsedTimestamps = JSON.parse(inviteTimestamps)

      if (!inviteTimestamps) {
        return [{
          email: this.email,
          timestamp: currentTimestamp
        }]
      } else {
        return parsedTimestamps?.map((parsedTimestamp) => {
          if (parsedTimestamp.email === this.email) {
            return {
              email: this.email,
              timestamp: currentTimestamp,
            }
          } else {
            return parsedTimestamp
          }
        })
      }
    },
    findEmailAmongTimestampObjects(timestamps) {
      return timestamps?.findIndex((timestamp) => {
        return timestamp.email === this.email
      })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
