<template>
  <am2-mobile-container
    :website-url="previewURL"
    :size="size"
    :has-footer="hasFooter"
  >
    <!-- src CANNOT BE CHANGED, THAT WILL BREAK OUR C2 previeBridge LOGIC! -->
    <am2-loading-section
      v-if="!previewIsReady"
      :style="{
        height: '100%',
      }"
    />
    <iframe
      v-if="initialPreviewData"
      ref="previewiframe"
      width="100%"
      height="100%"
      :src="`${c2ClientDomain}/preview-page/campaign${initialPreviewData.previewPage}?preview=true`" frameborder="0">
    </iframe>
  </am2-mobile-container>
</template>

<script>
import { campaignBaseUri } from '@/utils/campaign';
import { clone } from '@/utils/helpers';
import {mapState} from "vuex";

let updateTimeout;

export default {
  name: 'CampaignPreview',

  props: {
    hasAccount: {
      type: Boolean,
      default: false,
    },
    hasFan: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    previewPage: {
      type: String,
      default: '',
    },
    campaign: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'fixed',
      validator: (val) =>
        ['fixed', 'fullHeight', 'fullWidth'].indexOf(val) > -1,
    },
  },
  data() {
    return {
      c2ClientDomain: process.env.arCampaignClientDomain,
      initialPreviewData: null,
      previewIsReady: false,
    };
  },

  computed: {
    ...mapState({
      auth: state => state.auth,
    }),
    previewData() {
      return {
        hasAccount: this.hasAccount,
        hasFan: this.hasFan,
        previewPage: this.previewPage,
        campaign: this.campaign,
      };
    },
    previewURL() {
      if (!this.campaign) {
        return;
      }
      return `${campaignBaseUri(this.campaign.type)}${this.campaign.urlSlug}`;
    },
    previewScale() {
      const previewSize = 780;
      const margin = 160;
      const offset = 90;
      const totalPreviewFrameSize = previewSize + margin + offset;
      // diff

      if(document.documentElement.clientHeight >= totalPreviewFrameSize) {
        return 1;
      }

      return  document.documentElement.clientHeight / totalPreviewFrameSize
    },
  },
  watch: {
    previewData: {
      handler(val) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
          if (!this.$refs.previewiframe) {
            return;
          }
          val.xAuthToken = this.auth.xAuthToken;
          this.$refs.previewiframe.contentWindow.postMessage(JSON.stringify(val), this.c2ClientDomain);
        }, 500);
      },
      deep: true
    },
  },
  methods: {
    /**
     * Our Preview mechanism requires AM2 to get Campaign data ready in advance to previewing Campaign,
     * So whoever uses ar-campaign-preview should take in charge of calling this method after Campaign data's ready.
     */
    setupInitialPreviewData() {
      this.previewIsReady = true;
      this.initialPreviewData = clone(this.previewData);
    },

    loadedListenerResponse(event) {
      const previewData = clone(this.previewData)
      previewData.xAuthToken = this.auth.xAuthToken;
      this.$refs.previewiframe.contentWindow.postMessage(JSON.stringify(previewData), this.c2ClientDomain)
    },
  },
  onDestroy() {
    window.removeEventListener("message", this.loadedListenerResponse)
  },
  mounted() {
    window.addEventListener("message", this.loadedListenerResponse)
    setTimeout(() => {
      if (this.previewData) {
        const previewData = clone(this.previewData)
        previewData.xAuthToken = this.auth.xAuthToken;
        this.$refs.previewiframe.contentWindow.postMessage(JSON.stringify(previewData), this.c2ClientDomain)
      }
    }, 2250)
  }
};
</script>

