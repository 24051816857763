var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
  'events-sales',
  _vm.$arMediaQuery.pageContent.minWidth('md') && 'md-max' ]},[(_vm.arEnableTicketSalesFilter)?_c('am2-custom-date-range-modal',{attrs:{"title":"Custom date range","is-show":_vm.showCustomDateRangeModal},on:{"close":_vm.closeCustomDateRangeClick,"input":_vm.handleCustomDateRangeModalConfirm}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"events-sales__top-nav"},[_c('div',{staticClass:"events-sales__import"},[(_vm.currentEvent == null)?_c('am2-typography-skeleton',{style:({
          width: '124px',
          marginTop: '8px',
          marginBottom: '6px',
        }),attrs:{"size":"sm"}}):_vm._e(),_vm._v(" "),(_vm.hasAnyIntegration)?_c('am2-integration-indicator',{attrs:{"integration":_vm.currentEvent.provider,"icon-color":_vm.$arStyle.color[_vm.convertToCamelCase(_vm.currentEvent.provider)]}}):_vm._e(),_vm._v(" "),((_vm.hasAnyIntegration) && _vm.lastDataImport)?_c('div',{class:['sync-banner', _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max']},[_c('ar-text',{style:({
            display: 'inline-block',
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xs","text":_vm.lastSyncMessage}}),_vm._v(" "),(_vm.isEventSyncing)?_c('div',{staticClass:"inner-sync"},[_c('ar-icon',{staticClass:"syncing-icon",attrs:{"name":"refresh"}})],1):(_vm.hasIntegrationEventSync)?_c('ar-link-button',{style:({
            margin: '0 0 0 5px',
          }),attrs:{"data-test-id":"sync-ticket-sales-button","text":"Sync ticket sales"},on:{"click":_vm.syncEvent}}):_vm._e()],1):(_vm.lastDataImport)?_c('div',{staticClass:"sync-banner",style:({
            marginTop: '8px',
            marginBottom: '8px',
          })},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xs","text":("Last ticket sales data import was on " + (_vm.lastDataImport.format('Do MMM YYYY')) + ".")}}),_vm._v(" "),_c('ar-link-button',{style:({
            margin: '0 0 0 5px',
          }),attrs:{"text":"Import ticket sales"},on:{"click":_vm.openImportModal}})],1):(_vm.currentEvent != null)?_c('div',{staticClass:"sync-banner"},[_c('ar-text',{style:({
            display: 'inline-block',
            marginTop: '8px',
            marginBottom: '8px',
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xs","text":'No data has been imported.'}}),_vm._v(" "),_c('ar-link-button',{style:({
            margin: '0 0 0 5px',
          }),attrs:{"text":"Import ticket orders"},on:{"click":_vm.openImportModal}})],1):_vm._e()],1),_vm._v(" "),(_vm.arEnableTicketSalesFilter)?_c('div',{staticClass:"filter-sidebar"},[_c('div',{staticClass:"event-filter-dropdowns"},[_c('FilterSalesDropdown',{attrs:{"data-test-id":"event-sales-filter-button","has-search":"","placeholder":"Provider","dropdown-style":{
            width: '500px',
          },"align":"left"},on:{"submit":_vm.handleFilterSales}}),_vm._v(" "),_c('ar-simple-select',{style:({
            minWidth: '200px',
          }),attrs:{"data-test-id":"event-sales-date-dropdown","items":_vm.dropdown.items,"placeholder":"Date range","align":"right","value":_vm.selectedDateItem,"default-select-index":_vm.selectedDateItem},on:{"select":function (item, key) { return _vm.handleSelectDate(item, key); }}})],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"event-filter-text-container",attrs:{"data-test-id":"event-filter-text-container"}},[(_vm.getFilterText)?_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.getFilterText,
      }),expression:"{\n        content: getFilterText,\n      }",modifiers:{"top":true}}],staticClass:"filter-text",style:({
        marginRight: '5px',
      }),attrs:{"size":"xs","text":_vm.getFilterText}}):_vm._e(),_vm._v(" "),(_vm.getFilterText)?_c('ar-link-button',{staticClass:"comparing-to-clear-button",attrs:{"text":"clear","has-underline":true,"color":_vm.$arStyle.color.$purple500,"data-test-id":"event-filter-clear"},on:{"click":_vm.handleClearFilter}}):_vm._e()],1),_vm._v(" "),_c('am2-card-container',{style:({
      marginBottom: _vm.$arMediaQuery.pageContent.minWidth('md') ? '30px' : '12px',
    }),attrs:{"layout":"soft"}},[_c('am2-overview-bar',{attrs:{"data-test-id":"event-ticket-stats-bar","values":[
        {
          label: 'Total ticket sales',
          type: _vm.eventTicketStats ? 'CURRENCY' : 'LOADING',
          amount: _vm.eventTicketStats && _vm.eventTicketStats.totalTicketSales,
          percentChange: undefined,
        },
        {
          label: 'Average order value',
          type: _vm.eventTicketStats ? 'CURRENCY' : 'LOADING',
          amount: _vm.eventTicketStats && _vm.eventTicketStats.averageOrderSize,
          percentChange: undefined,
        },
        {
          label: 'Average tickets per order',
          type: _vm.eventTicketStats ? 'FLOAT' : 'LOADING',
          amount: _vm.eventTicketStats && _vm.eventTicketStats.averageTicketsPerOrder,
          percentChange: undefined,
        },
        {
          label: 'Total quantity sold',
          type: _vm.eventTicketStats ? 'PROGRESSION' : 'LOADING',
          amount: _vm.eventTicketStats && _vm.eventTicketStats.totalTicketsSold,
          maximum: parseInt(_vm.eventTicketStats && _vm.eventTicketStats.capacity),
          layout: 'status-bar',
        } ],"loading":_vm.isFetchingEventTicketStats,"layout":"spacious"}})],1),_vm._v(" "),_c('RevenueByMessageStats',{style:({
      marginBottom: _vm.$arMediaQuery.pageContent.minWidth('md') ? '30px' : '12px',
    })}),_vm._v(" "),_c('div',{class:[
      'graph-section',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('TicketSalesChartSection',{attrs:{"filter":_vm.currentFilter}}),_vm._v(" "),(_vm.hasMultipleTicketPlatforms && _vm.$arMediaQuery.pageContent.minWidth('sm'))?_c('am2-card-container',{staticClass:"full-width-cell",style:({ padding: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '30px' : '30px 10px' }),attrs:{"layout":"soft"}},[(_vm.ticketPlatformsData)?_c('am2-multiple-bar-charts',{attrs:{"data-test-id":"event-ticket-platforms-graph","title":"Ticket platforms","topic-index":_vm.ticketPlatformChartIndex,"loading":_vm.isFetchingEventTicketStats,"topics":_vm.ticketPlatformsData.topics,"chart-datas":_vm.ticketPlatformsData.chartDatas,"custom-tab-width":"200px"},on:{"topicChange":function (topic) { _vm.ticketPlatformsTopic = topic.name }}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('sm'))?_c('am2-card-container',{staticClass:"full-width-cell",style:({ padding: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '30px' : '30px 10px', minHeight: '300px' }),attrs:{"layout":"soft"}},[(_vm.isFetchingEventTicketStats || _vm.ticketTypesData)?_c('am2-multiple-bar-charts',{style:({ height: !_vm.eventTicketStats ? '200px' : null }),attrs:{"data-test-id":"event-ticket-types-graph","title":"Ticket types","topic-index":_vm.ticketTypesChartIndex,"loading":_vm.isFetchingEventTicketStats,"topics":_vm.ticketTypesData? _vm.ticketTypesData.topics : null,"chart-datas":_vm.ticketTypesData? _vm.ticketTypesData.chartDatas : null,"custom-tab-width":"200px"},on:{"topicChange":function (topic) { _vm.ticketTypesTopic = topic.name }}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-align-items-center u-justify-content-center",style:({
          width: '100%',
          marginTop: '-15px',
          minHeight: '30px'
        })},[(_vm.filteredAndSortedTicketTypesData && _vm.filteredAndSortedTicketTypesData && _vm.filteredAndSortedTicketTypesData.length > 7)?_c('ar-link-button',{attrs:{"data-test-id":"event-ticket-types-see-more-toggle","text":!_vm.ticketTypeSeeMore ? 'See more' : 'See less'},on:{"click":_vm.toggleTicketTypesSeeAll}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }