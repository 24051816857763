<template>
  <div class="selector-container">
    <div 
      v-if="enableAll"
      :class="['selector-option', selected === 'all' && 'selected']"
      @click="handleOptionClick('all')"
      data-test-id="message-filter-all"
    >
      <ar-text
        size="xs"
        weight="bold"
        text="All"
      />
    </div>
    <div 
      :class="['selector-option', selected === 'email' && 'selected']"
      @click="handleOptionClick('email')"
      data-test-id="message-filter-email"
    >
      <ar-icon
        name="email-inverted"
      />
    </div>
    <div 
      :class="['selector-option', selected === 'sms' && 'selected']"
      @click="handleOptionClick('sms')"
      data-test-id="message-filter-sms"
    >
      <ar-icon
        name="dialog"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageTypeSelector",

  props: {
    selected: {
      type: String,
      default: 'all',
      required: true,
    },
    enableAll: {
      type: Boolean,
      default: true,
    }
  },

  methods: {
    handleOptionClick(option) {
      this.$emit('onSelect', option);
    },
  }
}
</script>

<style lang="scss" scoped>
.selector {
  &-container {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;

    box-shadow: inset 0 0 0 1px $blueGrey500;
    border-radius: 4px;
  }

  &-option {
    width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blueGrey600;
    cursor: pointer;

    border: 1px solid $blueGrey500;
    border-left-color: transparent;
    border-right-color: transparent;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left-color: $blueGrey500;
      margin-right: -1px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right-color: $blueGrey500;
      margin-left: -1px;
    }
    
    &:hover, &.selected {
      border: 1px solid $purple600;
      background-color: $purple100;
      color: $purple500;

      .ar-text {
        color: $purple500 !important;
      }
    }

    .ar-text {
      color: $blueGrey600 !important;
    }
  }
}
</style>