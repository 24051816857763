<template>
  <div
    class="message-strip-wrapper"
    :class="disabled == true ? 'disabled' : ''"
    :style="{
      background: status !== 'complete' ? '#fff' : '#f6f9fc',
      border: isOpen ? `1px solid ${$arStyle.color.purple500}` : `1px solid ${$arStyle.color.skyBlueGrey500}`,
      'box-shadow': isOpen ? `0px 2px 2px rgba(0, 0, 0, 0.1)` : ''
    }"
  >
    <am2-import-dynamic-tags-modal
      :is-show="showImportDynamicTagsModal"
      :message-list-oid="scratchEmailMessage.meta.messageListOid"
      channel="email"
      @import="handleDynamicTagsImport"
      @cancel="showImportDynamicTagsModal = false"
    />
    <div :class="['message-strip-inner', status === 'complete' ? 'complete' : '']">
      <div :class="['icon-wrapper', status !== 'complete' ? 'new-icon-wrapper' : 'edit-icon-wrapper']">
        <EditPencilCircle v-if="status !== 'complete'" :fill="`${$arStyle.color.skyBlueGrey300}`" />
        <GreenTickCircle v-else />
      </div>
      <div class="message-content-wrapper">
        <div v-if="status === 'complete'" class="message-content-top complete">
          <div v-if="isAbEmail" class="ab-email-enabled"><am2-tag type="purple500" shape="rectangle" text="A/B" text-size="10px" /></div>
          <ar-text class="content-title" multiple-lines text="Message details" weight="bold" size="16px" />
          <ar-link-button
            class="content-link"
            text="Edit"
            has-underline
            @click="handleEdit"
            :data-test-id="`message-content-${contentButtonText}-button`"
          />
        </div>
        <div v-else class="message-content-top new">
          <div v-if="isAbEmail" class="ab-email-enabled"><am2-tag type="purple500" shape="rectangle" text="A/B" text-size="10px" /></div>
          <ar-text class="content-title" multiple-lines text="Message details" weight="bold" size="16px" />
          <ar-simple-button
            v-if="!isOpen"
            class="content-button"
            text="Add"
            outlined
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleAdd"
            :data-test-id="`message-content-${contentButtonText}-button`"
          />
        </div>
        <div class="message-content-middle">
          <div :class="['message-content-middle-container', status === 'complete' ? 'complete' : '']">
            <ar-text
              v-if="status !== 'complete'"
              class="left-subheading"
              multiple-lines
              text="What's the subject and preview line?"
              weight="normal"
              size="14px"
            />
            <div v-else :class="['complete-send-details-wrapper', longSubjectLine ? 'column' : '']">
              <div v-if="isAbEmail" class="complete-wrapper">
                <div class="complete-send-left">
                  <ar-text class="left-subheading" multiple-lines text="Subject A" weight="normal" size="14px" />
                  <ar-text class="left-copy" :text="subjectTextA" weight="normal" size="16px" />
                </div>
                <div class="complete-send-right">
                  <ar-text
                    class="left-subheading"
                    multiple-lines
                    text="Preview line A (Optional)"
                    weight="normal"
                    size="14px"
                  />

                  <ar-text class="left-copy" :text="previewTextA" weight="normal" size="16px" />
                </div>
                <div v-if="isAbEmail" class="complete-wrapper">
                  <div class="complete-send-left">
                    <ar-text class="left-subheading" multiple-lines text="Subject B" weight="normal" size="14px" />
                    <ar-text class="left-copy" :text="subjectTextB" weight="normal" size="16px" />
                  </div>

                  <div class="complete-send-right">
                    <ar-text
                      class="left-subheading"
                      multiple-lines
                      text="Preview line B (Optional)"
                      weight="normal"
                      size="14px"
                    />
                    <ar-text class="left-copy" :text="previewTextB" weight="normal" size="16px" />
                  </div>
                </div>
              </div>
              <div v-if="!isAbEmail" class="complete-wrapper">
                <div class="complete-send-left">
                  <ar-text class="left-subheading" multiple-lines text="Subject" weight="normal" size="14px" />
                  <ar-text class="left-copy" :text="subjectText" weight="normal" size="16px" />
                </div>
                <div class="complete-send-right">
                  <ar-text
                    class="left-subheading"
                    multiple-lines
                    text="Preview line (Optional)"
                    weight="normal"
                    size="14px"
                  />
                  <ar-text class="left-copy" :text="previewText" weight="normal" size="16px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen && !isAbEmail" class="inner-details-wrapper">
      <div class="inner-details-container">
        <div class="inner-details-heading">
          <ar-text class="subheading" multiple-lines text="Subject" weight="bold" size="14px" />
          <div
            class="insert-dynamic-tag-wrapper"
            @click="handleShowTagsDropdown"
            :data-test-id="`message-details-add-dynamic-tag-link`"
          >
            <ar-link-button class="content-link" text="Insert dynamic tag" has-underline />
          </div>
        </div>
        <div v-if="showTagDropdown" class="tags-dropdown-wrapper" v-click-outside="handleClickOutside">
          <div class="triangle" :style="{}">
            <div class="triangle-outer"></div>
            <div class="triangle-inner"></div>
          </div>
          <div
            v-for="item in availableDynamicTagsAll"
            :key="item"
            class="tags-dropdown-item"
            @click="addTagToSubjectLine(item, 'subject-textarea')"
          >
            <ar-text class="item-text" :text="item" weight="normal" size="14px" />
          </div>
          <div
            v-if="isPermissionEnabled(['export-all'])"
            class="add-tags-wrapper"
            @click="handleAddTags"
            :data-test-id="`add-dynamic-tag-link`">
            <ar-link-button class="add-tags-link" text="Add dynamic tags" has-underline />
          </div>
        </div>
        <SimpleMessageEditor
          ref="subject-textarea"
          :class="['subject-dynamic-input', showNeedSubjectLineWarning ? 'invalid-warning' : '']"
          :styles="{
            height: 'unset',
            lineHeight: '21px'
          }"
          :dynamic-tags="availableDynamicTagsAll"
          :value="subjectText"
          @input="handleSubjectInput"
          @focus="handleFocus"
          data-vv-name="subject-textarea"
        />
        <ar-text
          v-if="showNeedSubjectLineWarning"
          class="no-subject-line-copy"
          text="Subject line required"
          weight="normal"
          size="12px"
        />
        <div class="inner-details-heading">
          <ar-text class="subheading" multiple-lines text="Preview line (optional)" weight="bold" size="14px" />
        </div>
        <ar-input
          class="message-input preview-input"
          :style="{
            height: '40px'
          }"
          :value="previewText"
          @input="handlePreviewInputChange"
        />
        <div class="action-buttons-wrapper">
          <ar-link-button
            class="cancel-link"
            text="Cancel"
            has-underline
            @click="handleCancel"
            data-test-id="`message-content-cancel-link`"
          />
          <ar-simple-button
            class="save-button"
            text="Save"
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleSave"
            data-test-id="`message-content-save-button`"
          />
        </div>
      </div>
    </div>
    <div v-if="isOpen && isAbEmail" class="inner-details-wrapper">
      <div class="inner-details-container">
        <div class="inner-details-heading">
          <ar-text class="subheading" multiple-lines text="Subject A" weight="bold" size="14px" />
          <div
            class="insert-dynamic-tag-wrapper"
            @click="handleShowTagsDropdownA"
            :data-test-id="`message-details-add-dynamic-tag-link`"
          >
            <ar-link-button class="content-link" text="Insert dynamic tag" has-underline />
          </div>
        </div>
        <div v-if="showTagDropdownA" class="tags-dropdown-wrapper" v-click-outside="handleClickOutside">
          <div class="triangle" :style="{}">
            <div class="triangle-outer"></div>
            <div class="triangle-inner"></div>
          </div>
          <div
            v-for="item in availableDynamicTagsAll"
            :key="item"
            class="tags-dropdown-item"
            @click="addTagToSubjectLine(item, 'subject-textarea-a')"
          >
            <ar-text class="item-text" :text="item" weight="normal" size="14px" />
          </div>
          <div class="add-tags-wrapper" @click="handleAddTags" :data-test-id="`add-dynamic-tag-link`">
            <ar-link-button class="add-tags-link" text="Add dynamic tags" has-underline />
          </div>
        </div>
        <SimpleMessageEditor
          ref="subject-textarea-a"
          :class="['subject-dynamic-input', !subjectTextA && showNeedSubjectLineWarning ? 'invalid-warning' : '']"
          :styles="{
            height: 'unset',
            lineHeight: '21px'
          }"
          :dynamic-tags="availableDynamicTagsAll"
          :value="subjectTextA"
          @input="handleSubjectAInput"
          @focus="handleFocus"
          data-vv-name="subject-textarea"
        />
        <ar-text
          v-if="!subjectTextA && showNeedSubjectLineWarning"
          class="no-subject-line-copy"
          text="Subject line required"
          weight="normal"
          size="12px"
        />
        <div class="inner-details-heading">
          <ar-text class="subheading" multiple-lines text="Preview line A (optional)" weight="bold" size="14px" />
        </div>
        <ar-input
          class="message-input preview-input"
          :style="{
            height: '40px'
          }"
          :value="previewTextA"
          @input="handlePreviewInputAChange"
        />
        <hr />
        <div class="inner-details-heading">
          <ar-text class="subheading" multiple-lines text="Subject B" weight="bold" size="14px" />
          <div
            class="insert-dynamic-tag-wrapper"
            @click="handleShowTagsDropdownB"
            :data-test-id="`message-details-add-dynamic-tag-link`"
          >
            <ar-link-button class="content-link" text="Insert dynamic tag" has-underline />
          </div>
        </div>
        <div v-if="showTagDropdownB" class="tags-dropdown-wrapper test" v-click-outside="handleClickOutside">
          <div class="triangle" :style="{}">
            <div class="triangle-outer"></div>
            <div class="triangle-inner"></div>
          </div>
          <div
            v-for="item in availableDynamicTagsAll"
            :key="item"
            class="tags-dropdown-item"
            @click="addTagToSubjectLine(item, 'subject-textarea-b')"
          >
            <ar-text class="item-text" :text="item" weight="normal" size="14px" />
          </div>
          <div class="add-tags-wrapper" @click="handleAddTags" :data-test-id="`add-dynamic-tag-link`">
            <ar-link-button class="add-tags-link" text="Add dynamic tags" has-underline />
          </div>
        </div>
        <SimpleMessageEditor
          ref="subject-textarea-b"
          :class="['subject-dynamic-input', !subjectTextB && showNeedSubjectLineWarning ? 'invalid-warning' : '']"
          :styles="{
            height: 'unset',
            lineHeight: '21px'
          }"
          :dynamic-tags="availableDynamicTagsAll"
          :value="subjectTextB"
          @input="handleSubjectBInput"
          @focus="handleFocus"
          data-vv-name="subject-textarea"
        />
        <ar-text
          v-if="!subjectTextB && showNeedSubjectLineWarning"
          class="no-subject-line-copy"
          text="Subject line required"
          weight="normal"
          size="12px"
        />
        <div class="inner-details-heading">
          <ar-text class="subheading" multiple-lines text="Preview line B (optional)" weight="bold" size="14px" />
        </div>
        <ar-input
          class="message-input preview-input"
          :style="{
            height: '40px'
          }"
          :value="previewTextB"
          @input="handlePreviewInputBChange"
        />
        <div class="action-buttons-wrapper">
          <ar-link-button
            class="cancel-link"
            text="Cancel"
            has-underline
            @click="handleCancel"
            data-test-id="`message-content-cancel-link`"
          />
          <ar-simple-button
            class="save-button"
            text="Save"
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleSave"
            data-test-id="`message-content-save-button`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GreenTickCircle from '~/assets/icons/green-tick-circle.svg?inline';
import EditPencilCircle from '~/assets/icons/edit-pencil-circle.svg?inline';
import SimpleMessageEditor from '@/components/editors/simple-message-editor';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'MessageDetailsSubjectPreviewBlock',
  components: {
    EditPencilCircle,
    GreenTickCircle,
    SimpleMessageEditor
  },
  props: {
    isFieldExpanded: Boolean
  },
  data() {
    return {
      isOpen: false,
      previewText: '',
      subjectText: '',
      tags: null,
      showTagDropdown: false,
      showTagDropdownA: false,
      showTagDropdownB: false,
      subjectCharLimit: 90,
      actualSubject: '',
      ellipsesSubject: '',
      showImportDynamicTagsModal: false,
      showNeedSubjectLineWarning: false,
      subjectTextA: '',
      subjectTextB: '',
      previewTextA: '',
      previewTextB: '',
      disabled: false,
    };
  },
  watch: {
    isOpen() {
      if (!this.isOpen || this.status === 'complete') return;
      this.showNeedSubjectLineWarning = !!this.previewText.length && !this.subjectText.length;
    },
    scratchEmailMessage: {
      async handler() {
        if (this.scratchEmailMessage.meta.messageBody.subject) {
          this.subjectText = this.scratchEmailMessage.meta.messageBody.subject;
        }
        if (this.scratchEmailMessage.meta.messageBody.previewText) {
          this.previewText = this.scratchEmailMessage.meta.messageBody.previewText;
        }
        await this.$nextTick(() => {
          if (this.scratchEmailMessage.abEmail && this.scratchEmailMessage.abEmailType === 'subject') {
            if (this.emailA?.meta.messageBody.subject) this.subjectTextA = this.emailA?.meta.messageBody.subject ?? '';
            if (this.emailA?.meta.messageBody.previewText ) this.previewTextA = this.emailA?.meta.messageBody.previewText ?? '';
            if (this.emailB?.meta.messageBody.subject) this.subjectTextB = this.emailB?.meta.messageBody.subject ?? '';
            if (this.emailB?.meta.messageBody.previewText) this.previewTextB = this.emailB?.meta.messageBody.previewText ?? '';
          }
        });
      },
      immediate: true
    },
    isFieldExpanded: {
      handler() {
        this.disabled = this.isFieldExpanded == false || this.isOpen ? false : true;
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      scratchEmail: state => state.message.scratchEmail,
      emailVariants: state => state.message.scratchEmailMessageVariants
    }),
    ...mapGetters({
      availableDynamicTagsAll: 'message/availableDynamicTagsAll',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    longSubjectLine() {
      if (this.scratchEmailMessage.abEmail) {
        return this.subjectTextA.length > 34 || this.subjectTextB.length > 34;
      }
      return this.subjectText.length > 34;
    },
    status() {
      if (this.scratchEmailMessage.abEmail && this.scratchEmailMessage.abEmailType === 'subject') {
        if (
          this.subjectTextA &&
          !!this.subjectTextA.length &&
          this.subjectTextB &&
          !!this.subjectTextB.length &&
          !this.isOpen
        ) {
          return 'complete';
        }
      } else {
        if (this.subjectText && !!this.subjectText.length && !this.isOpen) {
          return 'complete';
        } else if (this.subjectText && !this.subjectText.length && !!this.previewText.length) {
          return 'partial';
        }
        return 'new';
      }
      return 'new';
    },
    contentButtonText() {
      return this.status === 'new' ? 'Add' : 'Edit';
    },
    isAbEmail() {
      if (this.scratchEmailMessage.abEmail && this.scratchEmailMessage.abEmailType === 'subject') {
        return true;
      }
      return false;
    },
    emailA() {
      return this.emailVariants['A'];
    },
    emailB() {
      return this.emailVariants['B'];
    }
  },
  mounted() {
    this.fetchPermissions()
  },
  methods: {
    ...mapMutations({
      setCSVPreviewContacts: 'message/SET_CSV_PREVIEW_CONTACTS',
      patchScratchEmailMessage: 'message/PATCH_SCRATCH_EMAIL_MESSAGE'
    }),
    ...mapActions({
      fetchDynamicTags: 'message/FETCH_ADDITIONAL_DYNAMIC_TAGS',
      renewDynamicTags: 'message/RENEW_AVAILABLE_FALLBACK_DYNAMIC_TAGS_FOR_EMAIL_MESSAGE',
      fetchPermissions: 'auth/FETCH_PERMISSIONS',
    }),
    handleFocus(data) {
      this.showNeedSubjectLineWarning = false;
    },
    handleAddTags() {
      this.showImportDynamicTagsModal = true;
    },
    addTagToSubjectLine(item, refString) {
      this.$refs[refString].insertDynamicTag(item);
      this.showTagDropdown = false;
      this.showTagDropdownA = false;
      this.showTagDropdownB = false;
    },
    handleSubjectInput(val) {
      this.subjectText = val;
    },
    handleSubjectAInput(val) {
      this.subjectTextA = val;
    },
    handleSubjectBInput(val) {
      this.subjectTextB = val;
    },
    async handleDynamicTagsImport({ dynamicTagHeaders, dynamicTagRows, tagsResourceOid, tagMaxWidthMap }) {
      console.log('work')
      this.setCSVPreviewContacts({
        headers: dynamicTagHeaders,
        rows: dynamicTagRows
      });
      this.patchScratchEmailMessage({
        meta: {
          dynamicTagHeaders,
          tagsResourceOid,
          tagMaxWidthMap
        }
      });

      await this.$nextTick();
      this.fetchDynamicTags();
      this.showImportDynamicTagsModal = false;
    },
    handleClickOutside(e) {
      if (!this.isOpen) return;

      // let i = 0;
      // let length = e.path.length;
      // while (i < length) {
      //   if (e.path[i].className === 'vue-portal-target') return;

      //   i++;
      // }

      let safeClasses = [
        'tags-dropdown-item',
        'add-tags-wrapper',
        'insert-dynamic-tag-wrapper',
        'inner-details-container'
      ];
      if (safeClasses.includes(e.target.className)) return;

      this.showTagDropdown = false;
      this.showTagDropdownA = false;
      this.showTagDropdownB = false;
    },
    handleSave() {
      if (this.isAbEmail) {
        if (!this.subjectTextA || !this.subjectTextB) {
          this.showNeedSubjectLineWarning = true;
          return;
        }
        this.$emit('update', {
          subjectA: this.subjectTextA,
          previewA: this.previewTextA,
          subjectB: this.subjectTextB,
          previewB: this.previewTextB
        });
      } else {
        if (!this.subjectText) {
          this.showNeedSubjectLineWarning = true;
          return;
        }
        this.$emit('update', { subject: this.subjectText, preview: this.previewText });
      }
      this.$emit('close');
      this.isOpen = false;
      this.renewDynamicTags(this.scratchEmail);
    },
    handleCancel() {
      if (!this.isAbEmail && (this.scratchEmailMessage?.meta?.messageBody?.subject !== this.subjectText
        || this.scratchEmailMessage?.meta?.messageBody?.previewText !== this.previewText)) {
        this.$emit('closeCallback', this.restoreDefaultAndClose)
      } else if (this.isAbEmail && (this.subjectTextA !== this.emailVariants['A']?.meta?.messageBody?.subject
        || this.previewTextA !== this.emailVariants['A']?.meta?.messageBody?.previewText
        || this.subjectTextB !== this.emailVariants['B']?.meta?.messageBody?.subject
        || this.previewTextB !== this.emailVariants['B']?.meta?.messageBody?.previewText)) {
        this.$emit('closeCallback', this.restoreDefaultAndClose)
      } else {
        this.isOpen = false;
        this.$emit('close');
      }
    },
    restoreDefaultAndClose() {
      this.subjectText = this.scratchEmailMessage?.meta?.messageBody?.subject || '';
      this.previewText = this.scratchEmailMessage?.meta?.messageBody?.previewText || '';

      if (this.isAbEmail) {
        this.subjectTextA = this.emailVariants['A']?.meta?.messageBody?.subject || '';
        this.previewTextA = this.emailVariants['A']?.meta?.messageBody?.previewText || '';
        this.subjectTextB = this.emailVariants['B']?.meta?.messageBody?.subject || '';
        this.previewTextB = this.emailVariants['B']?.meta?.messageBody?.previewText || '';
      }
      this.isOpen = false;
      this.$emit('close');
    },
    handlePreviewInputChange(text) {
      this.previewText = text;
    },
    handlePreviewInputAChange(text) {
      this.previewTextA = text;
    },
    handlePreviewInputBChange(text) {
      this.previewTextB = text;
    },
    handleShowTagsDropdown() {
      this.showTagDropdown = !this.showTagDropdown;
    },
    handleShowTagsDropdownA() {
      this.showTagDropdownA = !this.showTagDropdownA;
    },
    handleShowTagsDropdownB() {
      this.showTagDropdownB = !this.showTagDropdownB;
    },
    handleAdd() {
      this.isOpen = !this.isOpen;
      this.$emit('open');
    },
    handleEdit() {
      this.isOpen = true;
      this.$emit('open');
    },
    // triggered by ref in modify/email/index
    handleEmptyError() {
      this.isOpen = true;
      setTimeout(() => {
        this.showNeedSubjectLineWarning = true
      }, 0)
    },
  },
  fetch() {
    this.fetchDynamicTags();
  }
};
</script>
<style lang="scss" scoped>
.message-strip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-top: 30px;
  border-width: 1px;
  border-radius: 4px;
  &.disabled {
    pointer-events: none; // disable all pointer events
    opacity: 0.5;
  }
  .message-strip-inner {
    min-height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: auto;

    .icon-wrapper {
      margin-right: 24px;

      &.new-icon-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &.edit-icon-wrapper {
        margin-bottom: auto;
      }
    }

    .message-content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      flex-grow: 1;
      width: 35rem;

      .message-content-top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;

        &.complete {
          margin-bottom: 21px;
        }

        .content-title {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .content-button {
          padding: 10px 16px 11px;
          border: 1px solid $purple500;
          border-radius: 4px;
          position: absolute;
          right: 0;
          top: 5px;
        }
      }

      .message-content-middle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .message-content-middle-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &.complete {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
          }

          .left-subheading {
            color: $skyBlueGrey700;
          }

          .complete-send-details-wrapper {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            &.column {
              flex-flow: column nowrap;
              align-items: flex-start;

              .complete-wrapper {
                width: 100%;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: flex-start;

                .complete-send-left {
                  width: 100%;
                  padding-right: 30px;
                  margin-bottom: 16px;
                }
                .complete-send-right {
                  width: 100%;
                  padding-right: 30px;
                }
              }
            }

            .complete-wrapper {
              width: 100%;
              display: flex;
              flex-flow: row;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
              .complete-send-left {
                width: 50%;
                padding-right: 15px;
              }

              .left-subheading {
                color: $skyBlueGrey700;
                margin-bottom: 6px;
              }

              .complete-send-right {
                width: 50%;
                padding-right: 30px;
              }

              .left-copy {
                color: $blueGrey800;
              }
            }
          }
        }
      }
    }
  }

  .inner-details-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .inner-details-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      position: relative;

      .inner-details-heading {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 26px;

        .insert-dynamic-tag-wrapper {
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }
      }

      .tags-dropdown-wrapper {
        background: white;
        width: 200px;
        position: absolute;
        right: 0;
        top: 52px;
        z-index: 2;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .triangle {
          position: absolute;
          width: 10px;
          top: -5px;
          right: 7px;

          .triangle-outer {
            position: absolute;
            top: 0;
            left: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $skyBlueGrey500;
          }

          .triangle-inner {
            position: absolute;
            top: 1px;
            left: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid white;
            transform: translateY(10%);
          }
        }

        .tags-dropdown-item {
          padding: 14px 16px;
          cursor: pointer;
          border-top: 1px solid $blueGrey500;

          &:hover {
            background: #f5f1ff;
          }

          &:first-child {
            border-top: unset;
          }

          & > * {
            pointer-events: none;
          }

          .item-text {
            color: $blueGrey800;
          }
        }

        .add-tags-wrapper {
          padding: 14px 16px;
          border-top: 1px solid $blueGrey500;
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }
      }

      .tags-dropdown-wrapper.test {
        top: 252px;
      }
      .subject-dynamic-input {
        width: 100%;
        height: unset;
        margin-top: 12px;

        &.invalid-warning {
          border: 1px solid $red500;
          box-shadow: 0px 0px 0px 3px $red400;
          border-radius: 4px;
        }

        .am2-rich-text-editor {
          border-radius: 4px;
        }
      }

      .no-subject-line-copy {
        color: $red500;
        margin-top: 8px;
        margin-right: auto;
      }

      .message-input {
        width: 100%;
        margin-top: 12px;
      }

      .action-buttons-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        margin-top: 32px;

        .cancel-link {
          margin-right: 16px;
        }
      }
    }
  }
  .ab-email-enabled {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #7344c0;
    border-radius: 3px;
    color: white;
    margin-right: 12px;
    min-width: 22px;
    min-height: 16px;
    .ar-text {
      color: white;
      padding: 2px 4px 2px 4px;
    }
  }
}
</style>
