var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"input-search-wrapper input-container"},[_c('div',{class:['input-arrowhead-wrapper']},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutsideEventsListContainer),expression:"handleClickOutsideEventsListContainer"}],staticClass:"input-selection-container"},[(_vm.isDropdownOpen)?_c('div',{staticClass:"search-icon-wrapper"},[_c('SearchIcon',{staticClass:"search-icon"})],1):_vm._e(),_vm._v(" "),_c('ar-input',{ref:"senderLinkListInput",staticClass:"message-input send-to-input",style:({
                paddingLeft: _vm.isDropdownOpen ? '23px' : '0px'
              }),attrs:{"placeholder":_vm.computedPlaceholder,"value":_vm.inputValue},on:{"focus":_vm.handleInputFocus,"input":_vm.handleSearchInput}}),_vm._v(" "),_c('div',{staticClass:"link-arrowhead-wrapper",on:{"click":_vm.handleLinkArrowClick}},[_c('ArrowHead',{staticClass:"send-to-input-dropdown-arrowhead",class:_vm.isDropdownOpen && 'rotate',attrs:{"height":"8px","width":"10px","fill":_vm.$arStyle.color.skyBlueGrey700}})],1)],1)])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDropdownOpen),expression:"isDropdownOpen"}],class:['list-items-wrapper event-items-wrapper', _vm.filteredList.length > 4 ? 'overflow' : '']},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],attrs:{"infinite-scroll-disabled":"isInfiniteScrollDisabled","infinite-scroll-distance":"10"}},_vm._l((_vm.filteredList),function(item){return _c('div',{key:item.oid,class:[
            'link-list-item',
            'list-item' ],attrs:{"id":("listItem-" + (item.oid)),"data-test-id":("link-list-item-" + (item.oid))},on:{"click":function($event){return _vm.handleItemSelect(item)}}},[_c('ar-checkbox',{ref:("search-multi-select-checkbox-" + (item.oid)),refInFor:true,staticClass:"dropdown-item-checkbox",attrs:{"data-test-id":("search-multi-select-" + (item.oid))},model:{value:(_vm.selectedIdsLocal[item.oid]),callback:function ($$v) {_vm.$set(_vm.selectedIdsLocal, item.oid, $$v)},expression:"selectedIdsLocal[item.oid]"}}),_vm._v(" "),_c('div',{staticClass:"dropdown-copy-wrapper"},[_vm._t("default",null,{"item":item})],2)],1)}),0),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"list-items-wrapper__loader"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):_vm._e(),_vm._v(" "),(!_vm.isEmptyResultsSearch)?_c('div',{staticClass:"events-apply-deselect-wrapper"},[_c('div',{staticClass:"deselect-link-wrapper",attrs:{"data-test-id":"deselect-all-events-link"},on:{"click":_vm.handleDeselectAll}},[_c('ar-link-button',{staticClass:"deselect-link",attrs:{"text":"Deselect","has-underline":""}})],1),_vm._v(" "),_c('div',{staticClass:"apply-button-wrapper",attrs:{"data-test-id":"`apply-events-button`"},on:{"click":_vm.handleApply}},[_c('ar-simple-button',{staticClass:"apply-button",style:({
                padding: '10px 16px 11px',
                height: 'unset'
              }),attrs:{"text":"Apply","weight":"bold"}})],1)]):_vm._e(),_vm._v(" "),(_vm.isEmptyResultsSearch)?_c('div',{staticClass:"link-list-item list no-match"},[_c('ar-text',{staticClass:"list-name",style:({ margin: '0 16px' }),attrs:{"size":"xs","text":"No matching results found","multiple-lines":"","max-lines":1,"line-height":"20px","weight":"normal"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }