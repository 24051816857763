var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{style:({ padding: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '20px' : '24px 30px' }),attrs:{"layout":"soft"}},[_c('section',{class:[
    'domain',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[_c('div',{class:[
      'left-section',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      _vm.$arMediaQuery.pageContent.minWidth('sm') && 'sm-min' ]},[_c('div',{staticClass:"left-section-item u-align-items-center"},[_c('div',{staticClass:"context",style:({
            height: _vm.$arMediaQuery.pageContent.minWidth('lg') && '44px',
          })},[(_vm.defaultDomain)?_c('ar-icon',{staticClass:"u-margin-right-3",attrs:{"width":"20px","height":"16px","name":"lock"}}):_vm._e(),_vm._v(" "),_c('am2-heading',{attrs:{"type":"h1","size":"sm","title":_vm.data.domain,"weight":"bold","align":_vm.$arMediaQuery.pageContent.minWidth('sm') ? 'left' : 'center'}})],1)])]),_vm._v(" "),_c('div',{class:[
      'right-section',
      _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.matrix[_vm.status].tooltip ? _vm.matrix[_vm.status].tooltip : null
        }),expression:"{\n          content: matrix[status].tooltip ? matrix[status].tooltip : null\n        }",modifiers:{"top":true}}],class:[
          'status-tag',
          _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'u-display-none' ],attrs:{"text":_vm.matrix[_vm.status].title,"type":_vm.matrix[_vm.status].tagColor}}),_vm._v(" "),_c('am2-icon-button-dropdown',{staticClass:"menu-dropdown",attrs:{"dropdown-style":{
          width: '128px',
        },"items":_vm.actionItems},on:{"select":_vm.handleActionSelect}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }