var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"tixr-modal",attrs:{"is-open":_vm.isShow,"header":"Connect To Tixr","has-cross-icon":!_vm.loading,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'tixr-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-field',{attrs:{"label":"Public Key"}},[_c('ar-input',{attrs:{"name":"clientPartnerKey","placeholder":"Please enter your Tixr public key","disabled":_vm.loading},model:{value:(_vm.clientPartnerKey),callback:function ($$v) {_vm.clientPartnerKey=$$v},expression:"clientPartnerKey"}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '20px',
        }),attrs:{"label":"Secret Key"}},[_c('ar-input',{attrs:{"name":"privateKey","type":"password","placeholder":"Please enter your Tixr secret key","disabled":_vm.loading},model:{value:(_vm.privateKey),callback:function ($$v) {_vm.privateKey=$$v},expression:"privateKey"}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '20px',
        }),attrs:{"label":"Account Name"}},[_c('ar-input',{attrs:{"name":"accountName","placeholder":"Please enter your Tixr account name","disabled":_vm.loading},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})],1)],1),_vm._v(" "),_c('div',{class:[
        _vm.loading ? 'tixr-waiting-modal-footer' : 'tixr-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[(_vm.errorMessage)?_c('div',{class:'error-container'},[_c('span',{class:{'error-message': _vm.errorMessage}},[_vm._v("\n          "+_vm._s('* ' + _vm.errorMessage)+"\n        ")]),_vm._v(" "),_c('div',{class:'connection-guide'},[_c('a',{attrs:{"target":"_blank","href":"https://audiencerepublic.freshdesk.com/support/solutions/articles/51000337460-how-to-connect-to-tixr"}},[_vm._v("\n            View our connection guide\n          ")]),_vm._v(" "),_c('ar-icon',{staticStyle:{"vertical-align":"text-bottom","padding-left":"5px"},attrs:{"name":"external-link","width":"24px","height":"24px"}})],1)]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('am2-loading-bubble'):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","loading":_vm.loading,"disabled":_vm.confirmButtonDisabled,"id":"tixr-modal-confirm-button","data-test-id":"tixr-modal-confirm-button"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }