var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"optin-settings-modal",attrs:{"is-open":_vm.isShow,"header":_vm.header,"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'optin-settings-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[(_vm.accountName)?_c('ar-field',{staticClass:"u-margin-bottom-5",attrs:{"label":"Account Name"}},[_c('ar-input',{attrs:{"name":"accountName","disabled":""},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-margin-bottom-3"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Subscribe associated contacts to list","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.content,
          }),expression:"{\n            content: content,\n          }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('messageList')
        ]}},[_c('am2-message-list-select',{attrs:{"message-list-type":"manual","show-stats":false,"channels":['email'],"disabled":"","value":_vm.messageListOid}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
            color: _vm.$arStyle.color.blueGrey700,
          }),attrs:{"size":"xxs","text":"Changing message list is currenly not allowed"}})],1),_vm._v(" "),(_vm.syncFrom)?_c('ar-field',{staticClass:"u-margin-bottom-5 u-margin-top-5",attrs:{"label":"Syncing data from"}},[_c('ar-input',{attrs:{"name":"syncFrom","disabled":""},model:{value:(_vm.syncFrom),callback:function ($$v) {_vm.syncFrom=$$v},expression:"syncFrom"}})],1):_vm._e(),_vm._v(" "),(_vm.tagFansIsEnabled)?_c('ar-text',{staticClass:"u-margin-top-4",style:({
          color: _vm.$arStyle.color.blueGrey800,
        }),attrs:{"size":"xs","text":"More Settings","weight":"bold"}}):_vm._e(),_vm._v(" "),(_vm.providerName === 'DICE')?_c('am2-switch-field-card',{staticClass:"u-margin-top-3",attrs:{"title":"Automatically apply tags to fans imported from DICE","subtitle":"Tags will be customised per artist, genre & venue","size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'normal',"value":_vm.tagFansIsEnabled,"display-field":_vm.tagFansIsEnabled,"disabled":""}},[_c('div',{staticClass:"u-margin-top-5"},[_c('ar-field',[_c('ar-text',{style:({
                color: _vm.$arStyle.color.blueGrey700,
              }),attrs:{"size":"xs","text":"Enable customised tag names on","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-justify-content-flex-start u-margin-top-3"},[_c('ar-checkbox',{key:"artists",staticClass:"u-margin-right-5",attrs:{"label":"Artists","v-model":!!_vm.tagFans ? _vm.tagFans.artists : null,"disabled":"","data-test-id":"dice-automatic-tag-artists","ticked-value":!!_vm.tagFans ? _vm.tagFans.artists : null}}),_vm._v(" "),_c('ar-checkbox',{key:"genres",staticClass:"u-margin-right-5",attrs:{"label":"Genres","v-model":!!_vm.tagFans ? _vm.tagFans.genres : null,"disabled":"","data-test-id":"dice-automatic-tag-genres","ticked-value":!!_vm.tagFans ? _vm.tagFans.genres : null}}),_vm._v(" "),_c('ar-checkbox',{key:"venues",attrs:{"label":"Venues","disabled":"","v-model":!!_vm.tagFans ? _vm.tagFans.venues : null,"data-test-id":"dice-automatic-tag-venues","ticked-value":!!_vm.tagFans ? _vm.tagFans.venues : null}})],1)],1),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-margin-bottom-3 u-margin-top-5"},[_c('ar-text',{style:({
                color: _vm.$arStyle.color.blueGrey700,
              }),attrs:{"size":"xs","text":"Tag prefix (optional)","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: _vm.diceTagNameTooltip,
              }),expression:"{\n                content: diceTagNameTooltip,\n              }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-input',{attrs:{"placeholder":"No tag prefix entered","value":_vm.tagFans ? _vm.tagFans.prefix : null,"autocomplete":"organization","data-vv-name":"tagName","data-vv-as":"tag name","type":"text","disabled":"","data-test-id":"dice-automatic-tag-prefix"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
              color: _vm.$arStyle.color.blueGrey700,
            }),attrs:{"size":"xxs","text":"For example, if you set ‘DICE’ as the prefix, the tags imported into audience republic for the genre ‘Rock’ will appear as dice-rock against the fan record.","multiple-lines":""}})],1)]):_vm._e(),_vm._v(" "),(_vm.providerName === 'DICE')?_c('ar-text',{staticClass:"u-margin-top-3",style:({
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"size":"xxs","text":"Changing apply tag settings is currenly not allowed"}}):_vm._e(),_vm._v(" "),(_vm.providerName === 'Ticketek')?_c('ar-field',{style:({
          margin: '15px 0 12px 0',
        })},[_c('ar-checkbox',{key:"tag-fans",staticClass:"u-margin-right-5",attrs:{"label":"Assign tags from the &ldquo;product-marketing-type&rdquo; field<br>to newly created fan accounts.","data-test-id":"checkbox-ticketek-assign-tags","value":_vm.isTicketekTagFans},on:{"input":_vm.handleTicketekTagFansChange}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }