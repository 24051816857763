<template>
  <ar-modal
    id="oneschema-event-import-modal"
    :is-open="isVisible"
    class="oneschema-event-import-modal"
    :header="header"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="onClose"
  >
    <!-- BODY -->
    <div slot="body" class="oneschema-event-import-modal__fields-options">
      <EventsImportFields
        :type-of-import="typeOfImportModal"
        :eventOid="eventOid"
        @close="onClose"
      />
    </div>
  </ar-modal>
</template>

<script>
import EventsImportFields from '~/components/modals/event/import-event-data-modal/EventsImportFields.vue'
import {mapActions} from 'vuex'

export default {
  name: 'OneschemaEventImportModal',
  data() {
    return {
      eventOid: '',
      typeOfImportModal: '',
      isVisible: false,
    }
  },
  components: { EventsImportFields },
  computed: {
    header() {
      switch (this.typeOfImportModal) {
        case 'pos_orders_single_event':
          return 'Import POS orders';
        case 'ticket_orders_single_event':
          return 'Import ticket orders';
        default:
          return 'Mass POS data import';
      }
    },
  },
  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },
  methods: {
    ...mapActions([
      'CLOSE_ONESCHEMA_EVENT_IMPORT_MODAL',
      ]),
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'ONESCHEMA_EVENT_IMPORT_MODAL') return;

      this.typeOfImportModal = payload.typeOfImport;
      this.eventOid = payload.eventOid;

      this.isVisible = true;
    },
    onClose() {
      this.isVisible = false
      this['CLOSE_ONESCHEMA_EVENT_IMPORT_MODAL']()
    },
  },
}
</script>
