var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],staticClass:"event-audiences-container",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":"10"}},[_c('am2-add-fan-to-message-list-modal',{attrs:{"is-open":_vm.displayAddFanToMessageListModal,"search-string":_vm.searchString,"filter":_vm.currentFilter},on:{"add":_vm.handleAddFanToMessageListComplete,"cancel":_vm.handleAddFanToMessageListCancel}}),_vm._v(" "),_c('div',{staticClass:"top-statistics"},_vm._l((_vm.statistics),function(statistic,statisticIndex){return _c('div',{key:("statistic-" + statisticIndex),staticClass:"statistic",attrs:{"data-test-id":("statistic-" + (statistic.cleanName))},on:{"click":function () { _vm.setSelectedStatistic(statisticIndex) }}},[_c('div',{class:[
          'statistic-wrapper',
          (statisticIndex === _vm.selectedStatisticIndex) && 'active'
        ]},[_c('ar-text',{style:({
            color: (statisticIndex === _vm.selectedStatisticIndex) ? null : _vm.$arStyle.color.blueGrey700,
          }),attrs:{"multiple-lines":"","text":statistic.totalAudience,"size":"30px","line-height":"39px"}}),_vm._v(" "),_c('ar-text',{style:({
            color: (statisticIndex === _vm.selectedStatisticIndex) ? _vm.$arStyle.color.purple500 : _vm.$arStyle.color.blueGrey700,
          }),attrs:{"multiple-lines":"","weight":"bold","text":statistic.cleanName,"size":"16px","line-height":"20.8px"}})],1)])}),0),_vm._v(" "),_c('div',{staticClass:"page"},[((_vm.tableBody && _vm.tableBody.length > 0 && _vm.isFirstCalculationFinished) || !!_vm.isSuggestedAudiencesLocked)?_c('div',{staticClass:"sidebar-wrapper"},[_c('div',{class:[
          'sidebar',
          _vm.isSuggestedAudiencesLocked && 'locked'
        ]},[(!!_vm.eventAudienceStats && !!_vm.eventAudienceStats[_vm.selectedStatisticIndex])?_c('div',{staticClass:"icons"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'Subscribed to Email'
            }),expression:"{\n              content: 'Subscribed to Email'\n            }",modifiers:{"top":true}}],staticClass:"action-count-card"},[_c('div',{staticClass:"icon"},[_c('ar-icon',{attrs:{"name":"email","width":"18px","color":_vm.$arStyle.color.email}})],1),_vm._v(" "),_c('ar-text',{style:({
                marginTop: '12px',
              }),attrs:{"multiple-lines":"","text":_vm.eventAudienceStats[_vm.selectedStatisticIndex].optInEmail,"weight":"bold","size":"14px"}})],1),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'Subscribed to SMS'
            }),expression:"{\n              content: 'Subscribed to SMS'\n            }",modifiers:{"top":true}}],staticClass:"action-count-card"},[_c('div',{staticClass:"icon"},[_c('ar-icon',{attrs:{"name":"sms","width":"18px","color":_vm.$arStyle.color.sms}})],1),_vm._v(" "),_c('ar-text',{style:({
                marginTop: '12px',
              }),attrs:{"multiple-lines":"","text":_vm.eventAudienceStats[_vm.selectedStatisticIndex].optInSms,"weight":"bold","size":"14px"}})],1),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'Not subscribed to any lists'
            }),expression:"{\n              content: 'Not subscribed to any lists'\n            }",modifiers:{"top":true}}],staticClass:"action-count-card"},[_c('div',{staticClass:"icon"},[_c('Unsubscribed')],1),_vm._v(" "),_c('ar-text',{style:({
                marginTop: '12px',
              }),attrs:{"multiple-lines":"","text":_vm.eventAudienceStats[_vm.selectedStatisticIndex].notSubscribed,"weight":"bold","size":"14px"}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"action"},[_c('am2-split-dropdown',{staticClass:"action-button",style:({
              width: '216px',
              height: '50px',
              marginBottom: '24px'
            }),attrs:{"text":"Actions","toggle-dropdown-on-button-click":"","items":_vm.actionButtonOptions},on:{"select":function (item) { return item.action(); }}})],1),_vm._v(" "),(_vm.updatedText)?_c('ar-text',{style:({marginBottom: '14px'}),attrs:{"multiple-lines":"","text":_vm.updatedText,"weight":"normal","size":"12px","allow-html":""},on:{"anchorClick":_vm.refreshClick}}):_vm._e(),_vm._v(" "),_c('ar-divider'),_vm._v(" "),(!!_vm.statistics && !!_vm.statistics[_vm.selectedStatisticIndex] && !!_vm.criteriaDescriptions[_vm.statistics[_vm.selectedStatisticIndex].audience])?_c('div',{style:({
            marginTop: '24px',
          })},[_c('ar-text',{attrs:{"multiple-lines":"","text":_vm.criteriaDescriptions[_vm.statistics[_vm.selectedStatisticIndex].audience].title,"weight":"bold","size":"14px"}}),_vm._v(" "),(!!_vm.criteriaDescriptions[_vm.statistics[_vm.selectedStatisticIndex].audience].description)?_c('ar-text',{style:({
              color: _vm.$arStyle.color.skyBlueGrey700,
            }),attrs:{"multiple-lines":"","text":_vm.criteriaDescriptions[_vm.statistics[_vm.selectedStatisticIndex].audience].description,"size":"12px"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.criteria),function(item,itemIndex){return _c('div',{key:item.name},[(item && !!item.name && item.items.length > 0)?_c('ar-text',{style:({
                marginTop: '16px',
              }),attrs:{"multiple-lines":"","text":item.name,"size":"14px"}}):_vm._e(),_vm._v(" "),(!!item.items)?_c('div',{staticClass:"criteria-items",style:({maxWidth: '100%'})},_vm._l((item.items),function(tagItem,index){return _c('div',{key:("criteria-" + itemIndex + "-item-" + tagItem + "-" + index),style:({maxWidth: '100%'})},[_c('am2-tag',{style:({maxWidth: '100%'}),attrs:{"text":tagItem,"type":"purple","shape":"rectangle"}})],1)}),0):_vm._e()],1)}),_vm._v(" "),_c('div',[(!!_vm.criteriaDescriptions[_vm.statistics[_vm.selectedStatisticIndex].audience].exit)?_c('ar-text',{style:({
                marginTop: '16px',
                color: _vm.$arStyle.color.skyBlueGrey700,
              }),attrs:{"multiple-lines":"","text":_vm.criteriaDescriptions[_vm.statistics[_vm.selectedStatisticIndex].audience].exit,"size":"12px"}}):_vm._e()],1)],2):_vm._e()],1)]):_vm._e(),_vm._v(" "),((_vm.tableBody && _vm.tableBody.length > 0 && _vm.isFirstCalculationFinished) || !!_vm.isSuggestedAudiencesLocked)?_c('div',{staticClass:"table"},[_c('am2-table-control-section',{directives:[{name:"ar-sticky-top",rawName:"v-ar-sticky-top",value:({
          priority: 1,
        }),expression:"{\n          priority: 1,\n        }"}],style:({
          backgroundColor: '#f6f9fc',
        }),attrs:{"fans-search":{
          action: _vm.serverSideSearch,
        },"loading":(_vm.isFetchingAudience) && _vm.audience.length === 0,"show-checked-rows-options-bar":"","show-filter-and-search-bar":"","showFilterButton":false,"csvExport":_vm.isPermissionEnabled(['export-all']) && !_vm.isSuggestedAudiencesLocked ? {
          action: _vm.handleExportClick,
        } : null,"column-settings":{
          selectedColumnKeys: _vm.selectedColumnKeys,
          disabled: true,
        }}}),_vm._v(" "),_c('am2-fan-table',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
          show: _vm.isSuggestedAudiencesLocked,
          showUpgradeOn: 'always',
          showUpgradeNowButton: false,
          showPremiumTag: true,
          title: 'Generate a suggested audience',
          message: 'Generated based on event criteria from your contacts and linked campaigns.',
          margin: {
            top: 50
          },
        }),expression:"{\n          show: isSuggestedAudiencesLocked,\n          showUpgradeOn: 'always',\n          showUpgradeNowButton: false,\n          showPremiumTag: true,\n          title: 'Generate a suggested audience',\n          message: 'Generated based on event criteria from your contacts and linked campaigns.',\n          margin: {\n            top: 50\n          },\n        }"}],style:({
          minHeight: _vm.isSuggestedAudiencesLocked ? '380px' : null,
        }),attrs:{"head":_vm.displayedTableHeadings,"body":_vm.tableBody,"loading":_vm.isFetchingAudience,"has-index":"","empty-text":"No attendee data","enable-row-click":_vm.customerProfilesEnabled,"has-sticky-header":"","has-control-section":"","disable-sorting":_vm.isSuggestedAudiencesLocked,"locked":_vm.isSuggestedAudiencesLocked,"use-dummy-fans":_vm.isSuggestedAudiencesLocked},on:{"sortBy":_vm.handleSortChange,"rowClick":_vm.handleRowClick}})],1):_vm._e()]),_vm._v(" "),((!_vm.tableBody || _vm.tableBody.length === 0) && _vm.isFirstCalculationFinished && !_vm.isSuggestedAudiencesLocked)?_c('div',{staticClass:"page"},[_c('am2-no-content-section',{staticClass:"suggested-audience-no-content",attrs:{"header":"Generate your suggested audience to get started","body":"Generated based on event criteria from your contacts and linked campaigns.","button-text":"Generate audience","button-props":{
        outlined: true,
        height: '30px',
        iconName: 'magic'
      },"icon-name":"person-plus","icon-wrapper-enabled":"","icon-props":{
        width: '24px',
        height: '24px',
        color: _vm.$arStyle.color.purple500,
      }},on:{"buttonClick":_vm.handleGenerateAudienceButtonPress}})],1):_vm._e(),_vm._v(" "),(!!_vm.statistics && !_vm.isFirstCalculationFinished)?_c('div',{staticClass:"page"},[_c('am2-card-container',{staticClass:"suggested-audience-loading-first"},[_c('div',{staticClass:"suggested-audience-loading-first__loader-wrapper"},[_c('am2-loading-bubble',{attrs:{"size":'16px'}})],1),_vm._v(" "),_c('ar-text',{staticClass:"suggested-audience-loading-first__text",attrs:{"multiple-lines":"","text":"Your suggested audience is loading. Please check back in a couple of minutes or refresh the page"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }