<template>
  <div
      class="coming-soon-wrapper"
      layout="regular"
    >
      <div class="coming-soon-container">
        <am2-tag
          type="gold"
          shape="round"
          text="Premium"
          text-size="xxxs"
          :style="{
            textTransform: 'uppercase',
          }"
        />
        <span class="space-vertical">
          <ar-text
            text="Revenue tracking"
            size="sm"
            weight="bold"
            align="center"
          />
          <ar-text
            text="Track revenue attributed to this message send for single or multiple events (e.g. ticket sales, merch)"
            size="xs"
            align="center"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
            multiple-lines
          />
        </span>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ConversionComingSoon',
}
</script>

<style lang="scss" scoped>
.coming-soon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #EDF1F5;
  border-radius: 8px;
}
.coming-soon-container {
  max-width: 412px;
  word-break: break-all;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 12px;
}
.space-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
</style>
