<template>
  <div
    class="message-strip-wrapper"
    :class="disabled ? 'disabled' : ''"
    :style="{
      background: status !== 'complete' ? '#fff' : '#f6f9fc',
      border: isOpen ? `1px solid ${$arStyle.color.purple500}` : `1px solid ${$arStyle.color.skyBlueGrey500}`,
      'box-shadow': isOpen ? `0px 2px 2px rgba(0, 0, 0, 0.1)` : ''
    }"
  >
    <div :class="['message-strip-inner', status === 'complete' ? 'complete' : '']">
      <div :class="['icon-wrapper', status !== 'complete' ? 'new-icon-wrapper' : 'edit-icon-wrapper']">
        <EditPencilCircle v-if="status !== 'complete'" />
        <GreenTickCircle v-else />
      </div>
      <div class="message-content-wrapper">
        <div v-if="status === 'complete'" class="message-content-top complete">
          <ar-text class="content-title" multiple-lines text="From" weight="bold" size="16px" />
          <ar-link-button
            class="content-link"
            text="Edit"
            has-underline
            @click="handleEdit"
            :data-test-id="`message-content-${contentButtonText}-button`"
          />
        </div>
        <div v-else class="message-content-top new">
          <ar-text class="content-title" multiple-lines text="From" weight="bold" size="16px" />
          <ar-simple-button
            v-if="!isOpen"
            class="content-button"
            text="Add"
            outlined
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleAdd"
            :data-test-id="`message-content-${contentButtonText}-button`"
          />
        </div>
        <div class="message-content-middle">
          <div :class="['message-content-middle-container', status === 'complete' ? 'complete' : '']">
            <ar-text
              v-if="status !== 'complete'"
              class="left-subheading"
              multiple-lines
              text="Who is sending this email?"
              weight="normal"
              size="14px"
            />
            <div v-else class="complete-details-wrapper">
              <div class="complete-left">
                <ar-text class="left-subheading" multiple-lines text="Sender name" weight="normal" size="14px" />
                <ar-text
                  class="left-copy"
                  :text="`${selectedSender.additionalInfo.senderName} (${selectedSender.property})`"
                  weight="normal"
                  size="16px"
                />
              </div>
              <div class="complete-right">
                <ar-text class="left-subheading" text="Linked events" align="right" weight="normal" size="14px" />
                <ar-text
                  class="left-copy"
                  :text="eventSelectionsPreviewText"
                  align="right"
                  weight="normal"
                  size="16px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen" class="inner-details-wrapper">
      <div class="inner-details-container">
        <ar-text class="subheading" multiple-lines text="From" weight="bold" size="14px" />
        <div class="input-search-wrapper">
          <div
            v-if="!showInput"
            class="fake-input-wrapper"
            :class="[(showPlaceholder && isEmptyError) ? 'invalid-warning' : '']"
            @click="handleFakeInputClick">
            <div class="fake-text-area-wapper">
              <ar-text
                v-if="showPlaceholder"
                class="placeholder"
                text="Select a sender..."
                weight="normal"
                size="14px"
                :style="{
                  color: `${$arStyle.color.blueGrey600}`
                }"
              />
              <template v-else>
                <ar-text class="list-name" :text="selectedSenderName" weight="normal" size="14px" />
                <ar-text
                  class="list-name"
                  :text="selectedSenderEmail"
                  weight="normal"
                  size="14px"
                  :style="{
                    color: `${$arStyle.color.blueGrey700}`,
                    marginLeft: '4px'
                  }"
                />
              </template>
            </div>
            <ArrowHead
              class="send-to-input-dropdown-arrowhead"
              :class="senderListOpen && 'rotate'"
              height="8px"
              width="10px"
              :fill="$arStyle.color.skyBlueGrey700"
            />
          </div>
          <div v-else :class="['input-arrowhead-wrapper', senderListOpen ? 'active' : '']">
            <div class="input-selection-container sender-container" v-click-outside="handleClickOutsideSenderContainer">
              <div v-if="senderListOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
              <ar-input
                :id="senderInputId"
                ref="senderListInput"
                class="message-input send-to-input"
                :style="{
                  paddingLeft: senderListOpen ? '23px' : '0px'
                }"
                :placeholder="sendToPlaceholder"
                @focus="handleSenderFocus"
                @input="handleSenderSearchInput"
                :value="senderInputValue"
              />
              <div class="sender-arrowhead-wrapper" @click="handleSenderArrowClick">
                <ArrowHead
                  class="send-to-input-dropdown-arrowhead"
                  :class="senderListOpen && 'rotate'"
                  height="8px"
                  width="10px"
                  :fill="$arStyle.color.skyBlueGrey700"
                />
              </div>
            </div>
          </div>
        </div>
        <ar-text
          v-if="showPlaceholder && isEmptyError"
          class="error-subtext"
          text="A sender is required, please select one"
          weight="normal"
          size="12px"
        />
        <div v-if="senderListOpen" :class="['list-items-wrapper', filteredSenderList.length > 4 ? 'overflow' : '']">
          <div v-for="item in filteredSenderList" :key="item.oid" class="sender-list-item list-item">
            <div class="sender-list-item-copy-wrapper" @click="handleSenderSelect(item)">
              <ar-text
                class="list-name"
                multiple-lines
                :text="item.additionalInfo.senderName"
                :weight="selectedSender && selectedSender.oid === item.oid ? 'bold' : 'normal'"
                size="14px"
              />
              <ar-text
                class="list-email"
                multiple-lines
                :text="`(${item.property})`"
                :weight="selectedSender && selectedSender.oid === item.oid ? 'bold' : 'normal'"
                size="14px"
              />
            </div>
            <div
              class="edit-sender-wrapper"
              @click="handleEditSender(item)"
              :data-test-id="`edit-sender-${item.property}-link`"
            >
              <ar-link-button class="edit-sender-link" text="Edit" has-underline />
            </div>
          </div>
          <div v-if="noMatchingSenderLists" class="sender-list-item no-match">
            <ar-text
              class="list-name"
              :style="{ marginRight: '4px' }"
              size="xs"
              text="No matching senders found"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              weight="normal"
            />
          </div>

          <div class="sender-add-new-email-wrapper" @click="handleAddNewEmail">
            <div class="add-new-email-wrapper" :data-test-id="`sender-add-email-link`">
              <ar-link-button class="edit-sender-link" text="Add new email" has-underline />
            </div>
          </div>
          <am2-message-sender-modal
            :is-show="isAddingNewEmail"
            mode="create"
            header="Add new email"
            @create="handleSenderCreate"
            @cancel="handleMessageSenderClose"
          />
          <am2-message-list-settings-modal
            title="List Settings"
            :sender="senderObject"
            :message-list="senderObject"
            :is-show="isEditingSender"
            @update="handleUpdateSender"
            @close="handleCloseEditSenderModal"
          />
        </div>
        <ar-snackbar
          v-if="emailAddressIsRestricted"
          type="warning"
          message="<b>Domains from email providers like @gmail.com are invalid. <a>Please create a new authenticated domain</a>.</b>"
          class="u-margin-top-4"
          @anchorClick="handleDomainAuthClick"
        />
        <ar-snackbar
          v-if="domainIsRestricted"
          type="warning"
          message="<b>Your sender domain is not authenticated. <a>Please authenticate this domain</a>.</b>"
          class="u-margin-top-4"
          @anchorClick="handleDomainAuthClick"
        />
        <div v-if="!selectedEmailVerified" class="verify-email-wrapper">
          <div class="verify-email-warning-container">
            <div class="verify-left">
              <ErrorCircle class="error-circle-icon" :color="$arStyle.color.orange500" />
              <ar-text
                class="verify-copy"
                multiple-lines
                text="Please verify this email address"
                weight="bold"
                size="14px"
              />
            </div>
            <ar-text
              class="verify-copy-link"
              multiple-lines
              text="Resend verification email"
              weight="normal"
              size="14px"
              @click.native="retryEmailVerification"
            />
          </div>
        </div>
        <div class="inner-details-container">
          <ar-text class="subheading" multiple-lines text="Link to an event" weight="bold" size="14px" />
          <am2-select-search-events
            class="linked-event-dropdown"
            :value="selectedEvents"
            :default-label="linkedEventsDropdownPlaceholder"
            style-like-select
            :pre-selected-events="preSelectedEvents"
            @changeSelectedOids="handleChangeOids"
          />
        </div>
        <div class="action-buttons-wrapper">
          <ar-link-button
            class="cancel-link"
            text="Cancel"
            has-underline
            @click="handleCancel"
            data-test-id="`message-content-cancel-link`"
          />
          <ar-simple-button
            class="save-button"
            text="Save"
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleSave"
            data-test-id="`message-content-save-button`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GreenTickCircle from '~/assets/icons/green-tick-circle.svg?inline';
import EditPencilCircle from '~/assets/icons/edit-pencil-circle.svg?inline';
import ErrorCircle from '~/assets/icons/error-circle.svg?inline';
import ArrowHead from '~/assets/icons/arrow-head.svg?inline';
import SearchIcon from '~/assets/icons/search.svg?inline';
import { mapState, mapActions, mapGetters } from 'vuex';
import { displayDateRangeUSNoDay } from '@/utils/date/';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
export default {
  name: 'MessageDetailsFromBlock',
  components: {
    EditPencilCircle,
    GreenTickCircle,
    ErrorCircle,
    ArrowHead,
    SearchIcon
  },
  props: {
    isFieldExpanded: Boolean
  },
  data() {
    return {
      isOpen: false,
      senderListOpen: false,
      senderListSearchText: '',
      eventsListSearchText: '',
      selectedSender: null,
      filteredSenderList: [],
      senderInputId: 'senderInput',
      isAddingNewEmail: false,
      isEditingSender: false,
      senderObject: {},
      showInput: false,
      disabled: false,
      isEmptyError: false,
      eventFromParams: null,
      preSelectedEvents: [],
    };
  },
  watch: {
    messageSenders() {
      if (this.currentSender) {
        this.senderObject = this.currentSender;
      }
      this.filteredSenderList = this.messageSenders;
    },
    currentSender: {
      handler() {
        if (this.currentSender) {
          this.selectedSender = this.currentSender;
        }
      },
      immediate: true
    },
    isFieldExpanded: {
      handler() {
        this.disabled = this.isFieldExpanded == false || this.isOpen ? false : true;
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled'
    }),
    ...mapState({
      messageSenders: state => state.messageSender.messageSenders,
      currentSender: state => state.messageSender.currentMessageSender,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
      emailDomainRestricted: state => state.message.emailDomainRestricted,
      domainRestricted: state => state.message.domainRestricted,
      promoterOid: state => state.auth.account.promoterOid,
    }),
    selectedEvents() {
      if (this.scratchEmailMessage?.meta?.linkedEvents && this.scratchEmailMessage?.meta?.linkedEvents.length) {
        return this.scratchEmailMessage?.meta?.linkedEvents
      }
      return []
    },
    showPlaceholder() {
      return !this.selectedSender;
    },
    selectedEmailVerified() {
      if (!this.selectedSender) return true;
      return this.selectedSender.verified;
    },
    status() {
      if (this.selectedSender && !this.isOpen && this.selectedEmailVerified) {
        return 'complete';
      } else if (!!(!this.selectedSender || !this.selectedEmailVerified)) {
        return 'partial';
      } else {
        return 'new';
      }
    },
    selectedSenderName() {
      return this.selectedSender?.additionalInfo?.senderName;
    },
    selectedSenderEmail() {
      return `(${this.selectedSender?.property})`;
    },
    senderInputValue() {
      return this.senderListOpen ? this.senderListSearchText : this.senderSelectionsText;
    },
    senderSelectionsText() {
      return !!this.selectedSender
        ? `${this.selectedSender.additionalInfo.senderName} (${this.selectedSender.property})`
        : '';
    },
    eventSelectionsPreviewText() {
      return this.selectedEvents.length | 0;
    },
    sendToPlaceholder() {
      return this.senderListOpen ? 'Search senders' : 'Select a sender...';
    },
    contentButtonText() {
      return this.status === 'new' ? 'Add' : 'Edit';
    },
    noMatchingSenderLists() {
      return !this.filteredSenderList.length;
    },
    emailAddressIsRestricted() {
      return this.emailDomainRestricted;
    },
    domainIsRestricted() {
      return this.domainRestricted;
    },
    linkedEventsDropdownPlaceholder() {
      if (this.selectedEvents.length === 1) return '1 event selected'
      return this.selectedEvents.length ? `${this.selectedEvents.length} events selected` : 'Select events...'
    },
  },
  methods: {
    ...mapActions({
      fetchMessageSenders: 'messageSender/FETCH_MESSAGE_SENDERS',
      fetchEvents: 'FETCH_EVENTS',
      resendEmailVerification: 'messageSender/RESEND_EMAIL_VERIFICATION',
      fetchEventsByOid: 'FETCH_EVENTS_BY_OID_AND_SEARCH',
      checkEmailDomainRestricted: 'message/CHECK_EMAIL_DOMAIN_RESTRICTED',
    }),
    async handleChangeOids(oids) {
      this.$emit('update', { sender: this.selectedSender, linkedEvents: oids });
      this.fetchPreSelectedEvents()
    },
    async handleFakeInputClick() {
      this.showInput = true;
      this.senderListOpen = false;
      await this.$nextTick();
      this.$refs.senderListInput.focus();
    },
    handleUpdateSender(data) {
      if (this.selectedSender?.oid === data?.oid) {
        this.selectedSender = data;
      }
      this.isEditingSender = false;
    },
    retryEmailVerification() {
      this.resendEmailVerification(this.selectedSender.oid);
    },
    handleCloseEditSenderModal() {
      this.isEditingSender = false;
    },
    handleSenderCreate() {
      this.isAddingNewEmail = false;
    },
    handleMessageSenderClose() {
      this.isAddingNewEmail = false;
    },
    filterSendersList(text) {
      let filteredList = [];
      let i = 0;
      while (i < this.messageSenders.length) {
        if (
          this.$arFuzzySearch(this.messageSenders[i]?.additionalInfo?.senderName, text) ||
          this.$arFuzzySearch(this.messageSenders[i]?.property, text)
        ) {
          filteredList.push(this.messageSenders[i]);
        }

        i++;
      }

      this.filteredSenderList = filteredList;
    },
    handleSenderSelect(sender) {
      this.selectedSender = sender;
      this.filteredSenderList = this.messageSenders;
      this.senderListSearchText = '';
      this.senderListOpen = false;
      this.showInput = false;
      this.isEmptyError = false;
      this.checkEmailDomainRestricted(sender?.property);
    },
    handleClickOutsideSenderContainer(e) {
      if (!this.senderListOpen || !!this.isAddingNewEmail || !!this.isEditingSender) return;
      let clickElementId = e.target.id;

      let i = 0;
      let length = e.path ? e.path.length : 0;
      while (i < length) {
        if (e.path[i].className === 'vue-portal-target') return;

        i++;
      }

      let safeIds = ['senderInput', 'senderEmail', 'senderName', 'senderBusinessAddress'];
      if (safeIds.includes(clickElementId)) return;

      let safeClasses = [
        'sender-list-item list-item',
        'sender-add-new-email-wrapper',
        'add-new-email-wrapper',
        'sender-list-item-copy-wrapper',
        'edit-sender-wrapper',
        'text-section__inner',
        'sender-arrowhead-wrapper'
      ];
      if (safeClasses.includes(e.target.className)) return;

      this.showInput = false;
      this.senderListSearchText = '';
      this.filteredSenderList = this.messageSenders;
      this.senderListOpen = false;
    },
    handleSenderSearchInput(text) {
      this.senderListSearchText = text;
      if (!text.length) {
        this.filteredSenderList = this.messageSenders;
      } else {
        this.filterSendersList(text);
      }
    },

    async initLinkEventsFromParams() {
      if (!this.$route.query.eventOid) return

      const eventOid = Number(this.$route.query.eventOid)
      const data = await this.fetchEventsByOid({oids: [eventOid]})

      if (data.length === 0) return

      this.preSelectedEvents.push(data[0])

      this.$emit('update', { sender: this.selectedSender, linkedEvents: [...new Set([...this.selectedEvents, eventOid])] });
    },
    handleAddNewEmail() {
      this.isAddingNewEmail = true;
    },
    handleEditSender(sender) {
      this.senderObject = sender;
      this.isEditingSender = true;
    },
    handleCancel() {
      const savedSender = this.scratchEmailMessage?.meta?.email?.promoterPropertyOid
      if (savedSender !== this.selectedSender?.oid && typeof savedSender === 'number' && typeof this.selectedSender?.oid === 'number') {
        this.selectedSender = this.scratchEmailMessage?.meta?.email?.promoterPropertyOid
          ? this.messageSenders.find(sender => sender.oid === this.scratchEmailMessage?.meta?.email?.promoterPropertyOid)
          : null;
        this.$emit('closeCallback', this.restoreDefaultAndClose)
      } else {
        this.isOpen = false;
        this.$emit('close');
      }
    },
    restoreDefaultAndClose() {
      this.selectedSender = this.scratchEmailMessage?.meta?.email?.promoterPropertyOid
        ? this.messageSenders.find(sender => sender.oid === this.scratchEmailMessage?.meta?.email?.promoterPropertyOid)
        : null;
      this.isOpen = false;
      this.$emit('close');
    },
    handleSave() {
      if (!this.selectedSender || !this.selectedSender.verified) {
        this.isEmptyError = true;
        return;
      }
      this.$emit('update', { sender: this.selectedSender, linkedEvents: this.selectedEvents });
      this.isOpen = false;
      this.$emit('close');
    },
    handleSenderArrowClick() {
      this.senderListOpen = !this.senderListOpen;
      if (this.senderListOpen) {
        this.$refs.senderListInput.focus();
      } else {
        if (!this.selectedSender) this.senderListSearchText = '';
      }
    },
    handleSenderFocus() {
      this.senderListOpen = true;
    },
    handleAdd() {
      this.isOpen = !this.isOpen;
      this.$emit('open');
    },
    handleEdit() {
      this.isOpen = true;
      this.$emit('open');
    },
    // triggered by ref in modify/email/index
    handleEmptyError() {
      this.isOpen = true;
      this.isEmptyError = true;
    },
    handleDomainAuthClick() {
      window.open('https://audiencerepublic.freshdesk.com/support/solutions/articles/51000309976-domain-authentication', '_blank');
    },
    async fetchPreSelectedEvents() {
      if (this.selectedEvents.length === 0) {
        this.preSelectedEvents = []
        return
      }

      const data = await this.$axios.get(`/promoter/${this.promoterOid}/event/`, {
        params:{
          $top: 'all',
          $filter: `(oid=${this.selectedEvents.join(' OR oid=')})`,
        }
      })
      if (data.data && Array.isArray(data.data)) {
        this.preSelectedEvents = data.data
      }
    }
  },
  fetch() {
    this.fetchMessageSenders();
  },

  mounted() {
    this.initLinkEventsFromParams()
    this.fetchPreSelectedEvents()
  }
};
</script>
<style lang="scss" scoped>
.message-strip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-top: 30px;
  border: 1px solid $skyBlueGrey500;
  border-radius: 4px;
  &.disabled {
    pointer-events: none; // disable all pointer events
    opacity: 0.5;
  }
  .message-strip-inner {
    min-height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: auto;

    &.complete {
      align-items: flex-start;
    }

    .icon-wrapper {
      margin-right: 24px;

      &.new-icon-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &.edit-icon-wrapper {
        margin-bottom: auto;
      }
    }

    .message-content-wrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      flex-grow: 1;
      width: 35rem;
    }

    .message-content-top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &.complete {
        margin-bottom: 21px;
      }
      .content-title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .content-button {
        padding: 10px 16px 11px;
        border: 1px solid $purple500;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }

    .message-content-middle {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .message-content-middle-container {
        &.complete {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
        }

        .left-subheading {
          color: $skyBlueGrey700;
        }

        .complete-details-wrapper {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          .complete-left {
            width: 70%;
            padding-right: 15px;
          }

          .complete-right {
            margin-left: auto;

            .left-copy {
              color: $blueGrey800;
            }
          }

          .left-subheading {
            color: $skyBlueGrey700;
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  .inner-details-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .inner-details-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-top: 26px;
      position: relative;

      .input-search-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 12px;

        &.link-input-container {
          flex-flow: row wrap;

          .subheading {
            width: 100%;
            margin-top: 8px;
          }
        }

        .fake-input-wrapper {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 19px 17px 15px;
          border: 1px solid $blueGrey500;
          border-radius: 4px;
          cursor: pointer;

          &.invalid-warning {
            border: 1px solid $red500;
            box-shadow: 0px 0px 0px 3px $red400;
          }

          .fake-text-area-wapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }
        }

        .input-arrowhead-wrapper {
          width: 100%;
          position: relative;
          cursor: pointer;

          .search-icon-wrapper {
            z-index: 1;
            height: 100%;
            position: absolute;
            left: 13px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .search-icon {
              opacity: 0.4;
            }
          }

          .sender-arrowhead-wrapper,
          .link-arrowhead-wrapper {
            position: absolute;
            right: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            top: 0;

            & > * {
              pointer-events: none;
            }

            .send-to-input-dropdown-arrowhead {
              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .verify-email-wrapper {
        width: 100%;
        background: $orange200;
        border: 1px solid $orange500;
        border-radius: 4px;
        margin-top: 15px;
        padding: 11px 16px;

        .verify-email-warning-container {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .verify-left {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .error-circle-icon {
              margin-top: -2px;
            }

            .verify-copy {
              color: $blueGrey800;
              margin-left: 9px;
            }
          }

          .verify-copy-link {
            color: $orange500;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .list-items-wrapper {
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
      width: 100%;
      background: white;
      position: absolute;
      top: 88px;
      z-index: 1;
      max-height: 232px;
      overflow: auto;

      .sender-list-item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid $blueGrey500;
        width: 100%;
        cursor: pointer;

        &:hover {
          background: #f5f1ff;
        }

        &:first-child {
          border-top: unset;
        }

        &.no-match {
          cursor: default;
          padding: 12px 20px;
          background: white;
        }

        .sender-list-item-copy-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 12px 0px 12px 20px;

          & > * {
            pointer-events: none;
          }

          .list-name {
            margin-right: 3px;
          }

          .list-email {
            color: $skyBlueGrey700;
          }
        }

        .edit-sender-wrapper {
          cursor: pointer;
          padding: 12px 20px 12px 0px;

          & > * {
            pointer-events: none;
          }

          .edit-sender-link {
            padding-left: 10px;
          }
        }
      }

      .sender-add-new-email-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px 16px;
        border-bottom: 1px solid $blueGrey500;
        border-top: 1px solid $blueGrey500;
        width: 100%;
        cursor: pointer;
        position: sticky;
        bottom: 0;
        background: white;

        .add-new-email-wrapper {
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }
      }

      .link-list-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px 20px;
        border-top: 1px solid $blueGrey500;
        width: 100%;
        cursor: pointer;

        &:hover {
          background: #f5f1ff;
        }

        &:first-child {
          border-top: unset;
        }

        &.no-match {
          cursor: default;

          &:hover {
            background: white;
          }
        }

        & > * {
          pointer-events: none;
        }

        .linked-events-checkbox {
          margin-top: 4px;
        }

        .event-copy-wrapper {
          pointer-events: none;
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          margin-left: 15px;

          .event-date-location-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .list-date {
              color: $skyBlueGrey700;
              margin-right: 5px;
            }
            .list-location {
              color: $skyBlueGrey700;
            }
          }
        }
      }

      .events-apply-deselect-wrapper {
        position: sticky;
        bottom: 0;
        padding: 16px 12px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #dcdee4;
        background: white;

        .deselect-link-wrapper {
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }

        .apply-button-wrapper {
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }
      }
    }

    .event-items-wrapper {
      max-height: 329px;
    }

    .action-buttons-wrapper {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-top: 32px;

      .cancel-link {
        margin-right: 16px;
      }
    }
  }

  .linked-event-dropdown {
    width: 100%;
    margin-top: 12px;

    ::v-deep {
      .select-search-dropdown__button {
        justify-content: space-between;
      }
    }
  }

  .error-subtext {
    color: $red500;
    margin-top: 8px;
  }
}
</style>
