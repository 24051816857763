var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-table',{ref:"message-table",staticClass:"message-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.tableRows,"loading":_vm.loading,"row-height":74,"empty-text":"No messages available","enable-row-click":"","has-sticky-header":_vm.hasStickyHeader,"custom-head-padding":"0 22px"},on:{"rowClick":_vm.handleMessageRowClick},scopedSlots:_vm._u([{key:"oid",fn:function(ref){
var data = ref.data;
var head = ref.head;
return _c('div',{class:[
      'percentage-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"percentage-cell__container"},[(!!data[head.key])?[_c('ar-text',{staticClass:"value",attrs:{"size":"xs","text":data[head.key],"align":"left"}})]:_c('span',{staticClass:"empty-dash"})],2)])}},{key:"message",fn:function(ref){
    var data = ref.data;
return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
      content: _vm.getError(data) || null,
    }),expression:"{\n      content: getError(data) || null,\n    }",modifiers:{"top":true}}],staticClass:"message-cell",style:({
      padding: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '17px 14.5px 16.5px 22px' : '10px 14.5px 10px 22px',
    })},[_c('div',{staticClass:"type-container"},[_c('ar-icon',{staticClass:"icon",attrs:{"name":_vm.getIcon(data.provider, data).name,"color":_vm.getIcon(data.provider, data).color,"width":"19px"}})],1),_vm._v(" "),_c('div',{staticClass:"body-container"},[_c('div',{staticStyle:{"display":"flex"}},[(_vm.isAbEmail(data))?_c('am2-tag',{staticClass:"ab-tag",attrs:{"type":"purple500","shape":"rectangle","text":"A/B","text-size":"10px"}}):_vm._e(),_vm._v(" "),(data.provider === 'email')?_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.generateEmailMessageName(data) ? _vm.generateEmailMessageName(data) : null,
            delay: { show: 800, hide: 0 }
          }),expression:"{\n            content: generateEmailMessageName(data) ? generateEmailMessageName(data) : null,\n            delay: { show: 800, hide: 0 }\n          }",modifiers:{"top":true}}],class:['main', _vm.getMessageUiStatus(data) === 'completed' && 'sent'],attrs:{"size":"xs","text":_vm.generateEmailMessageName(data),"weight":"bold"}}):_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: _vm.generateSimpleMessageName(data) ? _vm.generateSimpleMessageName(data) : null,
            delay: { show: 800, hide: 0 }
          }),expression:"{\n            content: generateSimpleMessageName(data) ? generateSimpleMessageName(data) : null,\n            delay: { show: 800, hide: 0 }\n          }",modifiers:{"top":true}}],class:['main', _vm.getMessageUiStatus(data) === 'completed' && 'sent'],attrs:{"size":"xs","text":_vm.generateSimpleMessageName(data),"weight":"bold"}})],1),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null
        }),expression:"{\n          content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null\n        }",modifiers:{"top":true}}],staticClass:"subtitle",attrs:{"size":"xxxs","text":data.lastActionTimeText,"multiple-lines":"","max-lines":1,"line-height":"16px"}})],1)])}},{key:"recipients",fn:function(ref){
        var data = ref.data;
        var head = ref.head;
return _c('div',{class:[
      'percentage-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"percentage-cell__container"},[(!!data[head.key])?[_c('ar-text',{staticClass:"value",attrs:{"size":"xs","text":data[head.key],"align":"right"}})]:_c('span',{staticClass:"empty-dash"})],2)])}},{key:"opens",fn:function(ref){
    var data = ref.data;
    var head = ref.head;
return _c('div',{class:[
      'percentage-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"percentage-cell__container"},[(data[head.key] > 0 && data.provider !== 'sms')?[_c('ar-text',{staticClass:"value inactive-only",attrs:{"size":"xs","text":((data[head.key]) + "%"),"align":"right","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"value active-only",attrs:{"size":"xs","text":data.opensRaw,"align":"right","weight":"bold"}})]:_c('span',{staticClass:"empty-dash"})],2)])}},{key:"clicks",fn:function(ref){
    var data = ref.data;
    var head = ref.head;
return _c('div',{class:[
      'percentage-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"percentage-cell__container"},[(data[head.key] > 0)?[_c('ar-text',{staticClass:"value inactive-only",attrs:{"size":"xs","text":((data.clicks) + "%"),"align":"right","weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"value active-only",attrs:{"size":"xs","text":("" + (data.clicksRaw)),"align":"right","weight":"bold"}})]:_c('span',{staticClass:"empty-dash"})],2)])}},{key:"edited",fn:function(ref){
    var data = ref.data;
    var head = ref.head;
return _c('div',{class:[
      'message-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"text-cell-container"},[_c('div',{staticClass:"text-cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: data.editedText ? data.editedText : null
          }),expression:"{\n            content: data.editedText ? data.editedText : null\n          }",modifiers:{"top":true}}],staticClass:"main",attrs:{"size":"xs","text":data.editedText}})],1)])])}},{key:"sent",fn:function(ref){
          var data = ref.data;
          var head = ref.head;
return _c('div',{class:[
      'message-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"text-cell-container"},[_c('div',{staticClass:"text-cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: data.startedText ? data.startedText : null
          }),expression:"{\n            content: data.startedText ? data.startedText : null\n          }",modifiers:{"top":true}}],staticClass:"main",attrs:{"size":"xs","text":data.startedText}})],1)])])}},{key:"delivers",fn:function(ref){
          var data = ref.data;
          var head = ref.head;
return _c('div',{class:[
      'message-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"text-cell-container"},[_c('div',{staticClass:"text-cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: data.deliversText ? data.deliversText : null
          }),expression:"{\n            content: data.deliversText ? data.deliversText : null\n          }",modifiers:{"top":true}}],staticClass:"main",attrs:{"size":"xs","text":data.deliversText}})],1)])])}},{key:"status",fn:function(ref){
          var data = ref.data;
          var head = ref.head;
return _c('div',{class:[
      'message-cell',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
    ]},[_c('div',{staticClass:"status-action-container"},[_c('div',{staticClass:"status"},[_c('div',[_c('am2-tag',{style:({
            border: '1px solid white'
          }),attrs:{"text":_vm.generateStatusCopy(data.uiStatus),"type":_vm.statusToTagColor(data.uiStatus)}})],1)])])])}},{key:"menu",fn:function(ref){
          var data = ref.data;
          var head = ref.head;
return _c('div',{class:['menu-cell']},[_c('div',{staticClass:"preview-action-container"},[(data.uiStatus === 'completed')?_c('ar-icon-button',{staticClass:"preview-button",attrs:{"data-test-id":("message-preview-button-" + (data.oid)),"icon-props":{
          name: 'preview',
          width: '16px',
        }},on:{"click":function () { return _vm.preview(data); }}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"action-container"},[(!data.hideActions)?_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
          name: 'menu',
        },"items":_vm.messageOptions(data)},on:{"select":function (item) { return _vm.handleMessageOptionSelect(item.eventName, data); }}}):_vm._e()],1)])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }