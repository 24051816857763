<template>
  <div
    class="tree-branch-wrapper"
  >
    <div
      v-for="(readonlyItem, index) of eventsList"
      :key="index"
      class="tree-branch-section"
    >
      <ar-icon
        name="tree-branch"
        width="7px"
        height="11px"
        class="branch-icon"
      />
      <div
        class="tree-branch"
      >
        <span class='tree-branch-text'>{{ readonlyItem.name }}</span>
        <span v-if="readonlyItem.datestr" class="tree-branch-date">
          {{ readonlyItem.datestr }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import InfoBranch from '../utils/InfoBranch'
export default {
  name: 'EventDate',
  components: {
    InfoBranch
  },
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  computed: {
    eventsList(){
      if (this.item?.config?.cached?.length) {
        return this.item?.config?.cached
      } else {
        return [{name: 'Any events'}]
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.tree-branch-wrapper{
  padding: 0 10px;
  max-height: 260px;
  overflow: auto;

  .tree-branch-section {
    display: flex;
    align-items: top;
    font-size: 14px;
    color: $blueGrey800;
    letter-spacing: 0;
    line-height: 1em;
    margin-top: 12px;
    min-height: 20px;

    .branch-icon {
      position: relative;
      margin-top: 4px;
      align-items: flex-start;
    }

    .tree-branch {
      margin-left: 8px;

      .tree-branch-text {
        display: block;
        font-size: 14px;
        color: $blueGrey800;
      }

      .tree-branch-date {
        display: block;
        font-size: 12px;
        color: $blueGrey700;
        letter-spacing: 0;
      }
    }
  }
}
</style>