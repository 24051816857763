var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RendererFrame',{attrs:{"is-readonly":!_vm.disableReadMode && _vm.isReadOnly,"title":_vm.filterCriteria.title,"filter-tooltip":_vm.filterTooltip,"subtitle":_vm.subtitleText,"predicate-as-value":_vm.predicateAsValue,"readonly-items":_vm.readonlyItems,"loading":_vm.isFetchingItems,"apply-disabled":!_vm.isEditConditionComplete,"condition-button-text":_vm.conditionButtonText,"hide-button":_vm.hideButton},on:{"confirm":_vm.handleConfirmClick,"cancel":_vm.handleCancelClick,"delete":_vm.handleDeleteClick,"openChangeMode":function($event){_vm.isReadOnly = false}}},[_c('div',{staticClass:"custom-field-picker",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"function-wrapper"},[_c('div',{staticClass:"select-section custom-field-select-section"},[_c('ar-simple-select',{staticClass:"select",style:({
            height: '40px'
          }),attrs:{"items":_vm.customFieldItems,"default-select-index":_vm.customFieldSelectedIndex,"placeholder":"Select custom field...","data-test-id":"filter-select-custom-field"},on:{"select":_vm.handleCustomFieldSelect}})],1),_vm._v(" "),_c('div',{staticClass:"select-section predicate-select-section"},[(_vm.customFieldSelectedIndex > -1 && _vm.customFieldIsAllowedType)?_c('ar-simple-select',{ref:"predicate",staticClass:"select",style:({
            height: '40px',
            marginTop: '10px'
          }),attrs:{"items":_vm.predicateItems,"default-select-index":_vm.predicateItemsSelectedIndex,"placeholder":"Select your type...","data-test-id":"filter-predicate-selector"},on:{"select":_vm.handlePredicateSelect}}):_vm._e()],1)]),_vm._v(" "),(_vm.inputsCategoryToDisplay === 'text')?_c('div',[(_vm.editFilterCondition.data.condition !== 'is_known' && _vm.editFilterCondition.data.condition !== 'is_unknown')?_c('ar-input',{ref:"input-text",staticClass:"input",style:({
          height: '40px'
        }),attrs:{"value":_vm.editFilterCondition.data.values[0],"auto-focus":_vm.shouldAutoFocus,"placeholder":"Enter search term...","data-test-id":"filter-text-input"},on:{"input":_vm.handleTextInputChange,"enter":_vm.handleEnterPress}}):_vm._e()],1):(_vm.inputsCategoryToDisplay === 'number')?_c('div',[(_vm.editFilterCondition.data.condition === 'is_between')?_c('div',{staticClass:"number-input-section two-input-section"},[_c('div',{staticClass:"small-input"},[_c('ar-input',{staticClass:"input",style:({
            height: '40px'
          }),attrs:{"auto-focus":_vm.shouldAutoFocus,"value":_vm.editFilterCondition.data.values[0],"placeholder":"Quantity","type":"number","min":0,"data-test-id":"filter-number-input-1"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleNumberInputChange.apply(void 0, [ 0 ].concat( argsArray ))},"enter":_vm.handleEnterPress}})],1),_vm._v(" "),_c('div',{staticClass:"small-input"},[_c('ar-input',{staticClass:"input",style:({
            height: '40px'
          }),attrs:{"auto-focus":_vm.shouldAutoFocus,"value":_vm.editFilterCondition.data.values[0],"placeholder":"Quantity","type":"number","min":0,"data-test-id":"filter-number-input-1"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleNumberInputChange.apply(void 0, [ 1 ].concat( argsArray ))},"enter":_vm.handleEnterPress}})],1)]):(!!_vm.editFilterCondition.data.condition)?_c('div',{staticClass:"number-input-section big-input"},[_c('ar-input',{staticClass:"input",style:({
            height: '40px'
          }),attrs:{"auto-focus":_vm.shouldAutoFocus,"value":_vm.editFilterCondition.data.values[0],"placeholder":"Quantity","type":"number","data-test-id":"filter-number-input-1"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleNumberInputChange.apply(void 0, [ 0 ].concat( argsArray ))},"enter":_vm.handleEnterPress}})],1):_vm._e()]):(_vm.inputsCategoryToDisplay === 'date')?_c('div',[(_vm.editFilterCondition.data.condition === 'is_between')?_c('div',{staticClass:"date-picker-date-section with-dash"},[_c('div',{staticClass:"date-picker-small-datepicker"},[_c('am2-datepicker',{ref:"datepicker-1",style:({
              width: '100%',
              height: '40px',
            }),attrs:{"value":_vm.editFilterCondition.data.values[0],"name":"datepicker","placeholder":"Date","data-test-id":"filter-date-input-1"},on:{"input":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDateSelect.apply(void 0, [ 0 ].concat( argsArray ))}}})],1),_vm._v(" "),_c('div',{staticClass:"date-picker-small-datepicker"},[_c('am2-datepicker',{ref:"datepicker-2",style:({
            width: '100%',
            height: '40px',
          }),attrs:{"value":_vm.editFilterCondition.data.values[1],"name":"datepicker","placeholder":"Date","data-test-id":"filter-date-input-2"},on:{"input":function($event){
          var i = arguments.length, argsArray = Array(i);
          while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDateSelect.apply(void 0, [ 1 ].concat( argsArray ))}}})],1)]):(!!_vm.editFilterCondition.data.condition)?_c('div',{staticClass:"date-picker-date-section"},[_c('am2-datepicker',{ref:"datepicker-1",staticClass:"date-picker-date",style:({
          width: '100%',
          height: '40px',
        }),attrs:{"value":_vm.editFilterCondition.data.values[0],"name":"datepicker","has-arrow":"","data-test-id":"filter-date-input"},on:{"input":function($event){
        var i = arguments.length, argsArray = Array(i);
        while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDateSelect.apply(void 0, [ 0 ].concat( argsArray ))}}})],1):_vm._e()]):((_vm.inputsCategoryToDisplay === 'dropdown-multi' || _vm.inputsCategoryToDisplay === 'dropdown-single') && !!_vm.editFilterCondition.data.condition)?_c('div',_vm._l((_vm.dropdownCustomFieldOptions),function(item){return _c('div',{key:item.oid,staticClass:"checkbox-section",attrs:{"data-test-id":("filter-csp-item-" + (item.safeName))}},[_c('ar-checkbox',{ref:("checkbox-" + (item.oid)),refInFor:true,staticClass:"checkbox",attrs:{"data-test-id":("filter-csp-checkbox-" + (item.safeName))},on:{"change":function($event){return _vm.handleCheckBoxChange(item.oid, item)}},model:{value:(_vm.itemTickedMap[item.oid]),callback:function ($$v) {_vm.$set(_vm.itemTickedMap, item.oid, $$v)},expression:"itemTickedMap[item.oid]"}}),_vm._v(" "),_c('div',{staticClass:"checkbox-label-section",on:{"click":function($event){return _vm.handleCheckboxLabelClick(item.oid)}}},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:((item.name || 'Has been removed' | _vm.capitalize).length > 26 ? {
              content: item.name || 'Has been removed' | _vm.capitalize
            } : null),expression:"(item.name || 'Has been removed' | capitalize).length > 26 ? {\n              content: item.name || 'Has been removed' | capitalize\n            } : null",modifiers:{"top":true}}],style:({
              width: 'calc(100% - 60px)',
              color: !item.name ? _vm.$arStyle.color.red500 : null,
              margin: '4px 8px 4px 0',
            }),attrs:{"size":"xs","text":item.name || 'Has been removed'}})],1)],1)}),0):_vm._e(),_vm._v(" "),(_vm.customFieldSelectedIndex > -1 && !_vm.customFieldIsAllowedType)?_c('div',[_c('ar-text',{attrs:{"size":"xs","text":"This custom field type cannot be filtered at this time","multiple-lines":""}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }