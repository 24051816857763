<template>
  <div class="powerups">
    <div class="pre-event powerups-container">
      <div class="powerups__header">
        <ar-text text="Pre event" size="24px" />
        <ar-simple-button
          class="powerups__new-button"
          icon-name="plus"
          text="Add Power Up"
          @click="clickNewPowerUp"
          data-list-id="add-power-up-button"
        />
      </div>

      <template v-if="isFetchingPowerUps">
          <am2-typography-skeleton
            v-for="n in 3"
            :key="n"
            size="xl"
            class="powerups__skeleton"
            :level="2 * n - 1"
            :style="{
              width: '100%',
              height: '92px',
          }"
          />
      </template>
      <template v-else>
        <powerup-item
          v-for="(item) in preEventItems"
          :key="getId(item)"
          :item="item"
          @selectOption="handleOptionSelect"
          @createPowerUp="handleCreatePowerUp"
          @goToMessageEdit="handleGoToMessageEdit"
        />
      </template>
    </div>

    <div class="post-event powerups-container">
      <div class="powerups__header" :style="{marginTop: '32px'}">
        <ar-text text="Post event" size="24px" />
      </div>
  
      <template v-if="isFetchingPowerUps">
        <am2-typography-skeleton
          v-for="n in 3"
          :key="`${n}-post`"
          size="xl"
          class="powerups__skeleton"
          :level="2 * n - 1"
          :style="{
               width: '100%',
               height: '92px',
          }"
        />
      </template>
      <template v-else>
        <powerup-item
          v-for="item in postEventItems"
          :key="getId(item)"
          :item="item"
          @selectOption="handleOptionSelect"
          @createPowerUp="handleCreatePowerUp"
          @goToMessageEdit="handleGoToMessageEdit"
        />
      </template>
    </div>

    <PowerUpCustomModal
      :is-show="showEditModal"
      :edited-power-up="editedPowerUp"
      @close="closeEditModal"
      @create="handleCreatePowerUp"
      @edit="handleEditPowerUp"
    />
  </div>
</template>

<script>
import PowerUpCustomModal from '~/components/modals/event/power-up-custom/PowerUpCustomModal.vue'
import PowerupItem from '~/pages/events/view/components/powerupItem.vue'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import {calculateDaysBetweenTwoDates} from '~/utils/powerups'
dayjs.extend(utc)
dayjs.extend(timezone)

const dayInMinutes = 24 * 60
export default {
  name: 'PowerUps',
  components: {PowerUpCustomModal, PowerupItem},
  data () {
    return {
      showEditModal: false,
      editedPowerUp: null,
      defaultEventItems: [
        {
          title: '7 days before',
          desc: 'Email your ticket buyers any event specific details or upsell opportunities',
          actionType: 'before-event',
          provider: 'email',
          scheduleMinutesOffset: dayInMinutes * 7,
          days: 7,
        },
        {
          title: '1 day before event',
          desc: 'Email your ticket buyers any event specific details such as set times, last minute ticket deals and directions to the event',
          actionType: 'before-event',
          provider: 'email',
          scheduleMinutesOffset: dayInMinutes,
          days: 1,
        },
        {
          title: 'Day of event',
          desc: 'Email your ticket buyers any event specific details such as set times, directions and/or any last minute changes',
          actionType: 'event-day',
          provider: 'email',
          days: 0,
        },
        {
          title: 'Day after event',
          desc: 'Email you ticket buyers a thank you message and ask for feedback on your event',
          actionType: 'after-event',
          provider: 'email',
          scheduleMinutesOffset: dayInMinutes,
          days: 1,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      eventOid: state => parseInt(state.route.params.oid, 10),
      existingPowerUps: state => state.event.existingPowerUps,
      isFetchingPowerUps: state => state.event.isFetchingPowerUps,
      currentEvent: state => state.event.currentEvent,

    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    eventDurationInDays() {
      if (!this.currentEvent?.endDate || !this.currentEvent?.startDate || !this.currentEvent?.timeZone) return 0
      return calculateDaysBetweenTwoDates('', 0, this.currentEvent?.startDate, this.currentEvent?.endDate, this.currentEvent?.timeZone)
    },

    allCombinedPowerUps() {
      const map = new Map();

      const resultArray = this.existingPowerUps.map(item => {
        const actionType = this.getActionType(item);
        const days = calculateDaysBetweenTwoDates(actionType, item.scheduleMinutesOffset, this.currentEvent?.startDate, item?.task?.scheduledAt, this.currentEvent?.timeZone, this.eventDurationInDays);
        const title = this.getTitle(actionType, days);

        const uniqKey = days + item.actionType + item.provider;
        map.set(uniqKey, true);

        return {
          ...item,
          title,
          actionType,
          days,
        }
      })

      this.defaultEventItems.forEach(item => {
        const days = item.days;
        const actionType = item.actionType;
        const title = this.getTitle(actionType, days);
        const uniqKey = days + item.actionType + item.provider;
        if (!map.has(uniqKey)) {
          resultArray.push({
            ...item,
            title,
            days,
            actionType,
          })
        }
      })

      return resultArray;
    },


    preEventItems() {
      return [...this.allCombinedPowerUps.filter(item => item.actionType === 'before-event').sort((a, b) => b.days - a.days),
        ...this.allCombinedPowerUps.filter(item => item.actionType === 'event-day') ];
    },

    postEventItems() {
      return this.allCombinedPowerUps.filter(item => item.actionType === 'after-event').sort((a, b) => a.days - b.days);
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.isFeatureEnabled(['powerups'])) {
        next('/events')
      }
    })
  },
  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'event/FETCH_POWERUP_MESSAGES',
      'event/CREATE_POWERUP_MESSAGE',
      'event/DELETE_POWERUP_MESSAGE',
      'event/UPDATE_POWERUP_MESSAGE',
      'message/CANCEL_MESSAGE',
    ]),

    ...mapMutations({
      clearPowerUps: 'event/CLEAR_POWER_UPS'
    }),

    getId(item) {
      return item.oid || item.actionType + item.provider + item.scheduleMinutesOffset
    },

    clickNewPowerUp () {
      this.showEditModal = true
    },

    closeEditModal() {
      this.showEditModal = false
      this.editedPowerUp = null
    },

    getTitle(actionType, days,) {
      if (actionType === 'event-day' || days === 0) {
        return `Day of event`;
      } else if (actionType === 'before-event') {
        return `${days} ${days === 1 ? 'day' : 'days'} before event`;
      } else  if (actionType === 'after-event') {
        return `${days} ${days === 1 ? 'day' : 'days'} after event`;
      }
    },

    handleOptionSelect(payload) {
      const { eventName, item } = payload
      if (eventName === 'settings') {
        this.editedPowerUp = item
        this.showEditModal = true
      } else if (eventName === 'delete') {
        this.SHOW_CONFIRM({
          title: 'Are you sure you want to DELETE this custom power up?',
          messageHtml: `Deleting this power up will remove it and the message associated with it `,
          confirmButtonText: 'Delete field',
          validation: {
            question: 'Type DELETE to confirm',
            answer: 'DELETE',
          },
          asyncCompleteFunction: async () => {
            await this.deletePowerUp(item.oid)
            await this.fetchPowerUpMessages()
          },
          hideCancelButton: true,
          hasCrossIcon: true,
          iconColor: 'red',
        })
      } else if (eventName === 'view') {
        this.handleGoToMessageEdit(item)
      } else if (eventName === 'cancel') {
        this.SHOW_CONFIRM({
          title: 'Are you sure you want to cancel?',
          messageHtml: `Cancelling a power up put it in a draft state`,
          confirmButtonText: 'Yes, cancel',
          cancelButtonText: 'No, remain scheduled',
          asyncCompleteFunction: async () => {
            await this.cancelMessage(item)
          },
          iconName: 'schedule-cancel',
          iconProps: {
            height: '40px',
            wrapperStyle: {
              backgroundColor: this.$arStyle.color.purple100,
              borderRadius: '50%',
              width: '70px',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }
          },
          hideCancelButton: false,
          hasCrossIcon: true,
        })
      }
    },

    async handleCreatePowerUp(payload) {
      if (this.isDateInThePast(payload)) {
        this.$arNotification.push({ type: 'error', message: 'You cannot create a Power Up in the past' })
        return
      }

      let additionalPostDays = 0
      if (payload.actionType === 'after-event') {
        additionalPostDays = this.eventDurationInDays
      }

      const newPayload = {
        ...payload,
        eventOid: this.eventOid,
        timeZone: this.currentEvent?.timeZone,
        startDate: this.currentEvent?.startDate,
        scheduleMinutesOffset: payload.scheduleMinutesOffset + additionalPostDays * dayInMinutes,
      }

      const res = await this['event/CREATE_POWERUP_MESSAGE'](newPayload)

      this.showEditModal = false
      if (res?.data) this.handleGoToMessageEdit(res.data)
    },

    handleGoToMessageEdit(payload) {
      if (payload.task.provider === 'sms') {
        this.$router.push(`/message-center/messages/sms/${payload.task.oid}/edit`);
      } else if (payload.task.provider === 'email') {
        this.$router.push(`/message-center/messages/email/${payload.task.oid}/edit/basic`);
      }
    },

    async deletePowerUp(oid) {
      const newPayload = {
        eventOid: this.eventOid,
        oid,
      }

      await this['event/DELETE_POWERUP_MESSAGE'](newPayload)
    },

    async fetchPowerUpMessages() {
      await this['event/FETCH_POWERUP_MESSAGES'](this.eventOid)
    },

    async handleEditPowerUp(payload) {
      const res = await this['event/UPDATE_POWERUP_MESSAGE'](payload)
      await this.fetchPowerUpMessages()
      this.showEditModal = false
    },

    isDateInThePast(payload) {
      const { scheduleMinutesOffset, actionType } = payload
      const now = dayjs().utc().valueOf()
      const finalDate = dayjs.utc(this.currentEvent?.startDate).valueOf()
      const offset = scheduleMinutesOffset * 60 * 1000

      if (!finalDate) {
        return true
      }

      if (actionType === 'before-event') {
        return now > finalDate - offset
      } else if (actionType === 'after-event') {
        return now > finalDate + offset
      } else if (actionType === 'event-day') {
        return now > finalDate
      }
      return now > offset
    },

    async cancelMessage(item) {
      await this['message/CANCEL_MESSAGE']({ taskOid: item.task.oid, isResetScheduledAt: false })
      await this.fetchPowerUpMessages()
    },

    getActionType(item) {
      if (!item?.task?.scheduledAt || !this.currentEvent?.timeZone || !this.currentEvent?.startDate) return item.actionType

      const startDate = dayjs.utc(this.currentEvent.startDate).tz(this.currentEvent.timeZone)
      const itemDate = dayjs.utc(item.task.scheduledAt).tz(this.currentEvent.timeZone)


      if (this.currentEvent.endDate) {
        const endDate = dayjs.utc(this.currentEvent.endDate).tz(this.currentEvent.timeZone)
        if (itemDate.isAfter(endDate, 'day')) {
          return 'after-event'
        } else if (itemDate.isBefore(startDate, 'day')) {
          return 'before-event'
        }
        return 'event-day'
      }

      if (itemDate.isSame(startDate, 'day')) {
        return 'event-day'
      } else if (itemDate.isBefore(startDate, 'day')) {
        return 'before-event'
      } else if (itemDate.isAfter(startDate, 'day')) {
        return 'after-event'
      }
    }
  },
  mounted () {
    this['event/FETCH_POWERUP_MESSAGES'](this.eventOid)
  },
  beforeDestroy () {
    this.clearPowerUps()
  }
}

</script>

<style scoped lang="scss">
.powerups {
  display: flex;
  padding: 56px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .powerups-container {
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
  }

  &__new-button {
    margin-left: auto;
    height: 40px;
  }

  &__skeleton {
    height: 82px;
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
