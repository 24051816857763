<template>
  <div class="events-import-fields">
    <div class="events-import-fields__main">
      <div class="form-row">
        <ar-text class="form-row__label" text="Date format" weight="bold" size="xs" />
        <ar-simple-select
          class="form-row__input"
          :class="{'form-row__input--placeholder': !selectedDateFormat}"
          :items="dateFormatOptions"
          placeholder="Select a date format"
          :has-error="errors.dateSelect"
          :placeholder-props="{style: {color: $arStyle.color.$bluegrey500}}"
          @select="selectDateformat"
        />
        <ar-text v-if="errors.dateSelect" class="error-text" text="Date format required" size="xs" />
      </div>

      <div class="form-row">
        <ar-text class="form-row__label" text="Timezone" weight="bold" size="xs" />
        <am2-timezone-select
          v-model="selectedTimezone"
          placeholder="Select a timezone"
          class="form-row__input"
          :class="{'form-row__input--placeholder': !selectedTimezone}"
          :has-error="errors.timezoneSelect"
          @input="handleSelectTimezone"
        />
        <ar-text v-if="errors.timezoneSelect" class="error-text" text="Timezone required" size="xs" />
      </div>

      <div v-if="(this.typeOfImport != 'events')" class="form-row">
        <ar-text class="form-row__label" text="Currency" weight="bold" size="xs" />
        <ar-simple-select
          class="form-row__input"
          :class="{'form-row__input--placeholder': !selectedCurrency}"
          :default-select-index="selectedCurrencyIndex"
          :items="currencyOptions"
          placeholder="Select a currency"
          :has-error="errors.currencySelect"
          :placeholder-props="{style: {color: $arStyle.color.$bluegrey500}}"
          @select="handleSelectCurrency"
        />
        <ar-text v-if="errors.currencySelect" class="error-text" text="Currency is required" size="xs" />
      </div>

      <div class="form-row" v-if="isPosProvider">
        <ar-text class="form-row__label" text="POS provider" weight="bold" size="xs" />
        <SelectProviderChooser
          class="form-row__input"
          :class="{'form-row__input--placeholder': !selectedProvider}"
          :items="availableProviders"
          :default-selected-index="selectProviderIndex"
          :has-error="errors.providerSelect"
          placeholder="Select a POS provider or create a new one"
          @select="selectProvider"
          @create-provider="createProvider"
        />
        <ar-text v-if="errors.providerSelect" class="error-text" text="POS provider required" size="xs" />
      </div>

      <div class="form-row" v-else>
        <ar-text class="form-row__label" text="Ticket provider" weight="bold" size="xs" />
        <SelectProviderChooser
          class="form-row__input"
          :class="{'form-row__input--placeholder': !selectedProvider}"
          :items="availableProviders"
          :default-selected-index="selectProviderIndex"
          :has-error="errors.providerSelect"
          placeholder="Select a ticket provider or create a new one"
          @select="selectProvider"
          @create-provider="createProvider"
        />
        <ar-text v-if="errors.providerSelect" class="error-text" text="Ticket provider required" size="xs" />
      </div>

    </div>

    <ar-divider class="events-import-fields__divider" />

    <div class="events-import-fields__footer">
      <ar-simple-button
        :text="'Continue'"
        :disabled="isButtonDisabled"
        class="events-import-fields__button-finish"
        @click="handleClickContinue"
      />
    </div>
  </div>
</template>

<script>
import SelectProviderChooser from './SelectProviderChooser.vue'
import oneschemaImporter from '@oneschema/importer'
import { oneschemaConfig } from '@/api/oneschema/config'
import {capitalizeFirstLetter, clone} from '~/utils/helpers'
import {mapActions, mapState} from 'vuex'
import {defaultTicketingProviders, defaultProductProviders, CURRENCY_CODES} from '~/utils/constants'

const importer = oneschemaImporter(oneschemaConfig)

export default {
  name: 'EventsImportFields',
  props: {
    typeOfImport: {
      type: String,
      default: null,
      validator: function (value) {
        return ['events', 'events and orders', 'POS-line-items', 'pos_orders_single_event',
          'ticket_orders_single_event'].indexOf(value) !== -1;
      },
    },
    eventOid: String
  },
  components: {
    SelectProviderChooser,
  },

  data() {
    return {
      dateFormatOptions: [{name: 'International format (DD/MM/YYYY)', val: 'international'}, {name: 'US format (MM/DD/YYYY)', val: 'us'}],
      selectedDateFormat: null,
      selectedTimezone: null,
      selectedCurrency: null,
      errors: {
        dateSelect: false,
        timezoneSelect: false,
        providerSelect: false,
        currencySelect: false
      },
      currencyOptions: CURRENCY_CODES.map((c) => ({ name: c.code })),

      // Providers
      availableProviders: [],
      selectedProvider: null,
    }
  },

  mounted() {
    this.getProviders();
    this.selectedTimezone = this.eventTimeZone || this.currentRegionSettings?.additionalInfo?.defaultTimezone || null

    if (!this.selectedCurrency && !!this.currentRegionSettings?.additionalInfo?.currency) {
      this.selectedCurrency = {name: this.currentRegionSettings?.additionalInfo?.currency}
    }

    this['oneschema/FETCH_ONESCHEMA_JWT']();
  },

  computed: {
    ...mapState({
      customFields: (state) => state.customField.customFields,
      currentRegionSettings: (state) => state.auth.regionSettings,
      oneschema: (state) => state.oneschema.oneschema
    }),

    selectedCurrencyIndex() {
      if (!this.selectedCurrency) {
        return null
      }

      const currencyCode = this.selectedCurrency.name;
      const index = this.currencyOptions.findIndex((c) => c.name === currencyCode);
      return (index === -1) ? null : index;
    },

    isButtonDisabled() {
      return this.errors.dateSelect || this.errors.timezoneSelect || this.errors.providerSelect 
      || (this.typeOfImport != 'events' && this.errors.currencySelect)
    },
    selectProviderIndex() {
      // NOTE/RK: don't do `index = null;`
      // doing `index = -1` avoids having to do a null check
      // in the return statement since null >= 0 is true
      let index = -1;
      if (this.selectedProvider) {
        index = this.availableProviders.findIndex((a) => a.name.toLowerCase() === this.selectedProvider.toLowerCase());
      }

      return index >= 0 ? index : null;
    },
    defaultProviders() {
      return (this.typeOfImport === 'POS-line-items' || this.typeOfImport === 'pos_orders_single_event')
        ? clone(defaultProductProviders())
        : clone(defaultTicketingProviders())
    },
    isPosProvider() {
      return this.typeOfImport === 'POS-line-items' || this.typeOfImport === 'pos_orders_single_event'
    },
  },
  methods: {
    ...mapActions([
      'FETCH_CUSTOM_PROVIDERS',
      'CREATE_CUSTOM_PROVIDER',
      'promoterTasks/START_POLLING_PENDING_TASKS',
      'event/IMPORT_ONESCHEMA_CSV',
      'oneschema/FETCH_ONESCHEMA_JWT'
    ]),
    selectDateformat(val) {
      this.errors.dateSelect = false;
      this.selectedDateFormat = val;
    },
    selectProvider(val) {
      this.errors.providerSelect = false;

      this.selectedProvider = val.name.toLowerCase();
    },
    handleSelectTimezone(val) {
      if (val) {
        this.errors.timezoneSelect = false;
      }
    },
    handleSelectCurrency(val) {
      if (val) {
        this.errors.currencySelect = false;
        this.selectedCurrency = val;
      }
    },
    areSomeFieldsEmpty() {
      if (!this.selectedDateFormat) {
        this.errors.dateSelect = true;
      }
      if (!this.selectedTimezone) {
        this.errors.timezoneSelect = true;
      }
      if (!this.selectedProvider) {
        this.errors.providerSelect = true;
      }
      if (!this.selectedCurrency) {
        this.errors.currencySelect = true;
      }

      return this.errors.dateSelect || this.errors.timezoneSelect || this.errors.providerSelect 
      || (this.typeOfImport != 'events' && this.errors.currencySelect)
    },

    getTemplateKey(isUS = false) {
      if (this.typeOfImport === 'events') {
        return isUS ? 'events_only_US' : 'events_only'
      } else if (this.typeOfImport === 'events and orders') {
        return isUS ? 'multi_events_and_tickets_US' : 'multi_events_and_tickets'
      } else if (this.typeOfImport === 'POS-line-items') {
        return isUS ? 'multi_event_pos_orders_US' : 'multi_event_pos_orders'
      } else if (this.typeOfImport === 'pos_orders_single_event') {
        return isUS ? 'POS_orders_single_event_US' : 'POS_orders_single_event'
      } else if (this.typeOfImport === 'ticket_orders_single_event') {
        return isUS ? 'ticket_orders_single_event_US' : 'ticket_orders_single_event'
      }

      throw new Error('Invalid type of import oneSchema')
    },

    updatePendingTasksInAudienceAndMessage() {
      this['promoterTasks/START_POLLING_PENDING_TASKS']({
        reload: true,
      });
    },

    async uploadEmbedData(embedData) {

      const importType = {
        "events": "event",
        "events and orders": "event-and-audience",
        "ticket_orders_single_event": "audience-sales",
        "POS-line-items": "event-and-product-order",
        "pos_orders_single_event": "product-order"
      }

      let payload = {
        embedId: embedData.embed_id,
        originalFilename: embedData.sheet_metadata.original_file_name,
        provider: this.selectedProvider,
        timeZone: this.selectedTimezone,
        dateFormat: this.selectedDateFormat.val,
        importType: importType[this.typeOfImport],
        eventOid: this.eventOid,
        currencyCode: this.selectedCurrency
      }

      await this['event/IMPORT_ONESCHEMA_CSV'](payload);
    },

    bodyOverflowHidden(){
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
    },

    bodyOverflowReset(){
      document.getElementsByTagName("body")[0].style.overflow = "auto"
    },

    async handleClickContinue() {
      if (this.areSomeFieldsEmpty()) return;

      this.bodyOverflowHidden();

      const templateKey = this.getTemplateKey(this.selectedDateFormat.val === 'us');

      const oneSchemaFieldType = {
        date: 'DATE_DMY',
        number: 'NUMBER',
        text: 'TEXT',
        "dropdown-multi": 'PICKLIST',
        "dropdown-single": 'PICKLIST'
      }

      const fieldValidationsOpt = {
        number: {
          only_int: true,
          max_num: null,
          min_num: null,
          only_int: true,
          allow_commas: false,
          num_decimals: null
        }
      }

      const dataOverride = {
        "dropdown-multi": {
          delimiter: ","
        }
      }

      const fieldMapping = defaultTicketingProviders().find((item) =>
       item.name.toLowerCase() === this.selectedProvider.toLowerCase());


      let fieldsToAdd = [];

      (fieldMapping?.customMappings || [])
        .filter(
          (item) => item.enabledFor.indexOf(this.typeOfImport) > -1
        )
        .map((item) => {

          fieldsToAdd.push({
            key: item.value,
            label: item.value,
            data_type: oneSchemaFieldType[item.columnType],
            is_required: item.required,
            must_exist: item.required
          });

        }
      );

      this.customFields
      .filter(field => {
        return field.fieldType != "dropdown-multi"
      })
      .map(field => {

        const fieldValidation = fieldValidationsOpt[field.fieldType];
        const optionsOverride = field.options.length > 0 ? {
          picklist_options: field.options?.map(opt => {
           return {value: opt.name}
        })}: {}

        fieldsToAdd.push(
          Object.assign(
            {},
            {
              key: `cf_${field.oid}`,
              label: field.name,
              data_type: oneSchemaFieldType[field.fieldType],
              validation_options: Object.assign({}, fieldValidation, optionsOverride)
            },
            dataOverride[field.fieldType]
          ))
      });

      this.$osImporter.launch({
        templateKey: templateKey,
        templateOverrides: {
          columns_to_add: fieldsToAdd
        },
        userJwt: this.oneschema.jwt
      });

      this.$osImporter.removeAllListeners();

      this.$osImporter.on("success", (data) => {
        this.bodyOverflowReset();

        this.uploadEmbedData(data);

        setTimeout(() => {
          this.updatePendingTasksInAudienceAndMessage();
        }, 2000);
      });

      this.$osImporter.on("cancel", () => {
        this.bodyOverflowReset();
      });

      this.$osImporter.on("error", (message) => {
        this.bodyOverflowReset();
        this.$arNotification.push({
          type: 'error',
          message: `Error importing oneschema data. Please try again`,
        });
      });

      setTimeout(() => {
        this.$emit("close");
      }, 1000);
    },

    async getProviders() {
      if (this.defaultProviders.length > 0) {
        this.defaultProviders.unshift({
          name: 'Default providers',
          type: 'header',
        });
      }
      // Strip out any custom providers which share a name with default providers
      const customProviders = (await this.FETCH_CUSTOM_PROVIDERS())
        .map((p) => {
          return {
            name: capitalizeFirstLetter(p.provider),
            key: p.provider.toLowerCase(),
            value: p.oid,
            columnMapping: p.meta.columnMap || null,
          };
        })
        .filter((item) => {
          return (
            this.defaultProviders
              .map((defaultProvider) => defaultProvider.name.toLowerCase())
              .indexOf(item.name.toLowerCase()) === -1
          );
        });
      if (customProviders.length > 0) {
        customProviders.unshift({
          name: 'Custom providers',
          type: 'header',
        });
      }
      this.availableProviders = this.defaultProviders.concat(customProviders);
    },

    async createProvider(provider) {
      const providerName = provider.toLowerCase();
      const payload = {
        provider: providerName,
      };

      if (this.defaultProviders.some((item) => item.name.toLowerCase() === providerName)) {
        this.$arNotification.push({
          type: 'warning',
          message: 'Default provider already exists with this name',
          link: null,
          timeout: 5000,
        });
        return;
      }

      const result = await this.CREATE_CUSTOM_PROVIDER(payload);
      if (result) {
        this.availableProviders.push({
          name: capitalizeFirstLetter(result.provider),
          key: result.provider.toLowerCase(),
          value: result.oid,
          columnMapping: result.meta.columnMap || null,
        });

        this.selectedProvider = result.provider;
        this.errors.providerSelect = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.error-text {
  color: $red500;
  margin-top: 8px;
}

.form-row {
  margin-bottom: 24px;

  &__label:not(:last-child) {
    margin-bottom: 12px;
  }

  &__input {
    height: 40px;

    ::v-deep {
      .ar-select {
        height: 100%;
      }
    }
  }

  &__input--placeholder {
    ::v-deep {
      .text-section {
        color: $blueGrey600 !important;
      }
    }
  }
}

.events-import-fields {
  &__main {
    padding: 32px;
  }

  &__footer {
    width: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button-finish {
    height: 40px;
  }
}
</style>
