var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'rate-container': true,
    'multiple': _vm.multiple,
    'link': _vm.link,
  },attrs:{"data-test-id":_vm.rateTestId},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":_vm.onClick}},[_c('div',{staticClass:"rate-header"},[_c('ar-text',{style:(_vm.link && _vm.hover && {
        color: _vm.$arStyle.color.purple500,
      }),attrs:{"text":(_vm.rate + "%"),"size":"lg","weigth":"bold"}}),_vm._v(" "),(_vm.enableValue)?_c('ar-text',{style:(!(_vm.link && _vm.hover) && {
        color: _vm.$arStyle.color.blueGrey700,
      }),attrs:{"text":(_vm.formattedValue + "/" + _vm.formattedTotalSent),"size":"xs"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"rate-body"},[_c('div',{staticClass:"rate-title-container"},[_c('ar-text',{staticClass:"rate-type",style:(!(_vm.link && _vm.hover) && {
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"text":_vm.title,"size":"xs"}}),_vm._v(" "),(!!_vm.tooltip && _vm.tooltip.length > 1)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: _vm.tooltip,
            classes: 'align-left',
          }),expression:"{\n            content: tooltip,\n            classes: 'align-left',\n          }",modifiers:{"bottom":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.purple500}}):_vm._e()],1),_vm._v(" "),(_vm.enableGauge)?_c('am2-gauge',{staticClass:"rate-gauge",attrs:{"fill-color":"#7344c0","bar-height":10,"border-radius":10,"data-test-id":_vm.gaugeTestId,"value":_vm.rate,"max":100,"track-color":_vm.gaugeTrackColor}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }