var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-select',{ref:"select",attrs:{"enable-filter":"","items":_vm.computedMessages,"default-select-index":_vm.selectedMessageIndex,"placeholder":_vm.messagePlaceholder,"filter-placeholder":_vm.filterPlaceholder,"disabled":_vm.disabled,"data-test-id":"message-select"},on:{"select":_vm.handleSelect,"clear":function () { _vm.$emit('clear') },"close":function () { _vm.$emit('close') },"filter-string-input":_vm.handleSearch},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: ((_vm.itemName(selectedItemScope)) + " - " + (selectedItemScope.item.dateTooltip))
      }),expression:"{\n          content: `${itemName(selectedItemScope)} - ${selectedItemScope.item.dateTooltip}`\n      }",modifiers:{"top":true}}],style:({
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'center',
        padding: '0 14px',
        width: '100%'
      }),attrs:{"data-test-id":("message-item-" + (_vm.itemName(selectedItemScope)))}},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey800,
          maxWidth: '75%',
        }),attrs:{"size":"xs","text":_vm.itemName(selectedItemScope)}}),_vm._v(" "),_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.blueGrey700,
            maxWidth: '25%',
          }),attrs:{"size":"xs","text":("" + (selectedItemScope.item.subtitle)),"weight":"normal"}})],1):_c('div',{style:({
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0 14px',
      })},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey600,
        }),attrs:{"size":"xs","text":_vm.messagePlaceholder}})],1)]}},{key:"item",fn:function(itemScope){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: ((_vm.itemName(itemScope)) + " - " + (itemScope.item.dateTooltip))
      }),expression:"{\n          content: `${itemName(itemScope)} - ${itemScope.item.dateTooltip}`\n      }",modifiers:{"top":true}}],style:({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '46px',
        padding: '0 15px',
        cursor: 'pointer',
        background: itemScope.active || (_vm.isSelectedPurple && itemScope.selected) ? _vm.$arStyle.color.purple100 : null,
      }),attrs:{"data-test-id":("message-item-" + (_vm.convertListNameToKebabCase(itemScope.item.name)))}},[_c('ar-text',{style:({
          width: 'auto',
          color: itemScope.active || (_vm.isSelectedPurple && itemScope.selected) ? '#7344c0' : '#43516b',
          maxWidth: '75%',
        }),attrs:{"size":"xs","text":_vm.itemName(itemScope),"weight":itemScope.selected ? 'bold' : 'normal'}}),_vm._v(" "),_c('ar-text',{style:({
            width: 'auto',
            color: _vm.$arStyle.color.blueGrey700,
            maxWidth: '25%',
          }),attrs:{"size":"xs","text":("" + (itemScope.item.subtitle)),"weight":"normal"}})],1)]}}])},[_vm._v(" "),_vm._v(" "),_c('template',{slot:"no-item"},[_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        padding: '0 15px',
      })},[_c('ar-text',{attrs:{"size":"xs","text":'No available messages'}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }