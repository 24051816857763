<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="see-tickets-us-modal"
        header="Connect To See Tickets US"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'see-tickets-us-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
          <ar-field
            label="Client ID"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="clientId"
              name="clientId"
              placeholder="Please enter your See Tickets US Client ID"
              id="see-tickets-us-client-id-input"
              type="text"
              data-test-id="see-tickets-us-client-id-input"
            />
          </ar-field>
          <ar-field
            label="API Key"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="apiKey"
              name="apiKey"
              placeholder="Please enter your See Tickets US API key"
              id="see-tickets-us-api-key-input"
              type="password"
              data-test-id="see-tickets-us-api-key-input"
            />
          </ar-field>
          <ar-field
            label="API Secret"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="apiSecret"
              name="apiSecret"
              placeholder="Please enter your See Tickets US API secret"
              id="see-tickets-us-api-secret-input"
              type="password"
              data-test-id="see-tickets-us-api-secret-input"
            />
          </ar-field>
          <div
            class="u-display-flex u-margin-bottom-3 u-margin-top-5"
          >
            <ar-text
              size="xs"
              text="Subscribe associated contacts to list (optional)"
              weight="bold"
              :style="{
                color: $arStyle.color.blueGrey800,
              }"
            />
            <ar-icon
              v-tooltip.top="{
                content: `Automatically subscribe all contacts who purchased tickets to events from this See Tickets US account.`,
              }"
              name="tooltip-question-mark"
              class="u-margin-left-2"
              height="15px"
              width="15px"
              :color="$arStyle.color.purple500"
            />
          </div>

          <ar-field
            :error-messages="[
              veeErrors.first('messageList')
            ]"
            :style="{
              marginTop: '15px',
            }"
          >
            <am2-message-list-select
              message-list-type="manual"
              :show-stats="false"
              v-model="messageList"
              :channels="['email']"
            />
          </ar-field>
        </div>
        <div
          slot="footer"
          :class="[
            'see-tickets-us-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="see-tickets-us-modal-confirm-button"
            data-test-id="see-tickets-us-modal-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>

  <script>
  import { mapActions } from 'vuex';
  export default {
    name: 'SeeTicketsUsModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        clientId: null,
        apiKey: null,
        apiSecret: null,
        messageList: null
      };
    },

    computed: {
      confirmButtonDisabled() {
        return !this.clientId || !this.apiKey || !this.apiSecret;;
      }
    },

    watch: {
      isShow(val) {
        if (val) {
          this.messageList = null;
          this.apiKey = null;
          this.apiSecret = null;
          this.clientId = null;
        }
      },
    },

    methods: {
      ...mapActions([
        'seeTicketsUsIntegration/CREATE_SEE_TICKETS_US_INTEGRATION',
        'seeTicketsUsIntegration/FETCH_INTEGRATION'
      ]),
      async fetchIntegration() {
        const success = await this['seeTicketsUsIntegration/FETCH_INTEGRATION']()
        return success;
      },

      async handleConfirmClick() {
        this.errorMessage = null;
        this.httpStatus = null;
        const clientId = this.clientId;
        const apiKey = this.apiKey;
        const apiSecret = this.apiSecret;
        const messageListOid = this.messageList;
        const response = await this['seeTicketsUsIntegration/CREATE_SEE_TICKETS_US_INTEGRATION']({
          clientId,
          apiKey,
          apiSecret,
          messageListOid
        });
        if (response) {
          if (this.$route.path != '/settings/integrations/see-tickets-us') {
            await this.$router.push({ path: '/settings/integrations/see-tickets-us' });;
          }
          else {
            this.fetchIntegration();
          }
          this.$emit('cancel');
        }
        else {
          this.errorMessage = response.message;
          this.httpStatus = response.httpStatusCode;
        }
      },
      handleCloseClick() {
        this.$emit('cancel');
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .see-tickets-us-modal {
    .see-tickets-us-modal-body {
      padding: 20px 30px 30px;

      &:nth-child(2) {
        border-top: 1px solid $blueGrey500;
      }
    }
    .see-tickets-us-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>
