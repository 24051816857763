<template>
  <div class="mfa-section">
    <div class="mfa-section__block">
      <div class="mfa-section__line">
        <ar-text
          text="1."
          weight="bold"
          size="sm"
          class="mfa-section__step-number"
          style="margin-top: 4px;"
        />
        <ar-text
          text="Install a compatible application such as Google Authenticator, Duo Mobile, or Authy app on your mobile device or computer."
          size="sm"
          multiple-lines
        />
      </div>
      <div class="mfa-section__line u-margin-top-2">
        <ar-text
          text="1."
          weight="bold"
          size="sm"
          class="mfa-section__step-number"
          style="margin-top: 4px;visibility: hidden;"
        />
        <div>
          <ar-link-button
            v-if="!isShowMore"
            text="Learn More"
            :has-underline="true"
            :color="$arStyle.color.purple500"
            @click="handleLearnMore"
          />
          <div v-if="isShowMore">
            <p>Virtual authenticator apps implement the time-based one-time password (TOTP) algorithm and support multiple tokens on a single device.</p>
            <p>You can install apps for your smartphone from the app store that is specific to your type of smartphone. Some app providers also have web and desktop applications available. See the following table for examples</p>

            <div class="mobile-apps u-margin-top-2">
              <div class="mobile-apps__row">
                <div class="mobile-apps__column">
                  <ar-text
                    text="IOS"
                    class="u-margin-right-2"
                    size="sm"
                    weight="bold"
                    :style="{marginTop: '6px'}"
                  />
                </div>
                <div class="mobile-apps__column">
                  <div v-for="(link, index) in iosLinks" :key="link.link" class="mobile-apps__link-wrapper">
                    <a :href="link.link" target="_blank" class="u-margin-right-1">{{ link.name }}<template v-if="index !== iosLinks.length - 1">, </template></a>
                  </div>
                </div>
              </div>
              <div class="mobile-apps__row">
                <div class="mobile-apps__column">
                  <ar-text
                    text="Android"
                    class="u-margin-right-2"
                    size="sm"
                    weight="bold"
                    :style="{marginTop: '6px'}"
                  />
                </div>
                <div class="mobile-apps__column">
                  <div v-for="(link, index) in androidLinks" :key="link.link" >
                    <a :href="link.link" target="_blank" class="u-margin-right-1">{{ link.name }}<span v-if="index !== androidLinks.length - 1">,</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mfa-section__block">
      <div class="mfa-section__line">
        <ar-text
          text="2."
          weight="bold"
          size="sm"
          class="mfa-section__step-number"
          style="margin-top: 4px;"
        />
        <ar-text
          text="Open your authenticator app, choose Show QR code on this page, then use the app to scan the code."
          multiple-lines
          size="sm"
        />
      </div>
      <div class="mfa-section__line">
        <div class="mfa-section__qr-wrapper">
          <ar-simple-button
            v-if="!otpUrl"
            text="Show QR"
            class="mfa-section__qr-button"
            :loading="isQrLoading"
            @click="showQr"
          />
          <qrcode-vue
            class="mfa-section__qr"
            :class="[otpUrl ? 'mfa-section__qr--active' : '']"
            :value="otpUrl ? otpUrl : 'otpauth://totp/AudienceRepublic:hellow@world?secret=testing234234ldfsd'"
            :size="200"
            level="H"
          />
        </div>
      </div>
    </div>
    <div class="mfa-section__block">
      <div class="mfa-section__line" >
        <ar-text
          text="3."
          weight="bold"
          size="sm"
          class="mfa-section__step-number"
        />
        <ar-text
          text="Type two consecutive codes below."
          size="sm"
        />
      </div>
      <ar-field
        label="Enter the 6-digit code from your authenticator app"
        class="mfa-section__field"
      >
        <ar-input
          v-model="otp1"
          type="number"
          placeholder="Code 1"
          class="input-number-without-arrows"
          v-validate="'required|digits:6'"
          data-vv-name="Code 1"
        />
        <ar-state-message
          v-show="veeErrors.has('Code 1')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('Code 1')"
        />
      </ar-field>
      <ar-field
        label="Wait 30 seconds and enter a second code entry"
        class="mfa-section__field"
      >
        <ar-input
          v-model="otp2"
          type="number"
          placeholder="Code 2"
          class="input-number-without-arrows"
          v-validate="'required|digits:6'"
          data-vv-name="Code 2"
        />
        <ar-state-message
          v-show="veeErrors.has('Code 2')"
          :style="{ marginTop: '8px' }"
          type="error"
          :text="veeErrors.first('Code 2')"
        />
      </ar-field>
    </div>
    <ar-simple-button text="Save" :loading="loading || isSaving" @click="handleSave" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import QrcodeVue from 'qrcode.vue'
import {sanitizeHtmlChild} from '~/utils/html-element'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowQr: false,
      otpUrl: '',
      otp1: '',
      otp2: '',
      isQrLoading: false,
      isSaving: false,
      isShowMore: false,
      androidLinks: [
        {
          "name": "Duo Mobile",
          "link": "https://play.google.com/store/apps/details?id=com.duosecurity.duomobile"
        },
        {
          "name": "Microsoft Authenticator",
          "link": "https://play.google.com/store/apps/details?id=com.azure.authenticator"
        },
        {
          "name": "Google Authenticator",
          "link": "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
        },
        {
          "name": "Symantec VIP",
          "link": "https://m.vip.symantec.com/home.v#searchwebsite"
        },
      ],
      iosLinks: [
        {
          "name": "Duo Mobile",
          "link": "https://apps.apple.com/us/app/duo-mobile/id422663827"
        },
        {
          "name": "Microsoft Authenticator",
          "link": "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
        },
        {
          "name": "Google Authenticator",
          "link": "https://apps.apple.com/us/app/google-authenticator/id388497605"
        },
        {
          "name": "Symantec VIP",
          "link": "https://m.vip.symantec.com/home.v#searchwebsite"
        }
      ]
    }
  },
  components: {
    QrcodeVue,
  },

  methods: {
    ...mapActions(['auth/FETCH_MFA_CONFIGURE', 'auth/SET_MFA_CONFIGURE']),
    async showQr() {
      this.isQrLoading = true
      const data = await this['auth/FETCH_MFA_CONFIGURE']()

      if (data && data['otp-url']) {
        this.otpUrl = data['otp-url']
      }
      this.isQrLoading = false
    },
    async handleSave() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      this.isSaving = true
      const data = await this['auth/SET_MFA_CONFIGURE']({
        otp1: Number(this.otp1),
        otp2: Number(this.otp2),
      })

      if (data) {
        this.$emit('success')
      }

      this.isSaving = false
    },

    handleLearnMore() {
      this.isShowMore = true
    }
  },
}
</script>

<style scoped lang="scss">
.mfa-section {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__step-number {
    margin-right: 10px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  &__line {
    display: flex;
    align-items: flex-start;
  }

  &__field {
    margin-top: 16px;
  }

  &__qr-wrapper {
    margin-top: 16px;
    position: relative;
    padding: 20px;
    border: 1px solid $purple400;

    &__active {
      filter: none;
    }
  }

  &__qr {
    filter: blur(6px);
    &--active {
      filter: none;
    }
  }

  &__qr-button {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    z-index: 10;
  }
}

.mobile-apps {
  &__row {
    display: flex;
    margin-bottom: 8px;
  }

  &__column {
    display: flex;
    flex-wrap: wrap;
    &:first-child {
      width: 100px;
    }
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
    line-height: 20px;
  }
}
</style>
