<template>
  <div class="settings-region">
    <div
      class="input-heading"
    >
      <ar-text
        size="md"
        text="Region Settings"
        class="u-margin-bottom-2"
      />
      <ar-text
        size="xs"
        text="Set a timezone and currency"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
    </div>
    <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
      <ar-field
        label="Default timezone"
        class="input-section"
      >
        <am2-timezone-select
          placeholder="Please select default timezone..."
          v-model="regionSettings.defaultTimezone"
          ref="defaultTimezone"
          data-vv-name="defaultTimezone"
          data-vv-as="timezone"
          class="input--block"
          :enable-clear="true"
        />
        <ar-state-message
          v-if="veeErrors.has('defaultTimezone')"
          type="error"
          :text="veeErrors.first('defaultTimezone')"
          :style="{ marginTop: '8px' }"
        />
      </ar-field>
    </div>
    <div :class="['input-group', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']">
      <ar-field
        label="Currency"
        class="input-section"
      >
        <ar-simple-select
          ref="currency"
          enable-filter
          name="currency"
          data-vv-as="currency"
          :items="currencyOptions"
          placeholder="Please select currency..."
          enable-clear
          @clear="() => selectCurrency(null)"
          @select="selectCurrency"
          :default-select-index="selectedCurrencyIndex"
        />
      </ar-field>
    </div>
    <div
      class="functions-section"
    >
      <ar-simple-button
        text="Update"
        :style="{
          width: '100px',
        }"
        :loading="isUpdatingRegionSettings"
        @click="handleSaveChangeClick"
        :disabled="isUpdateButtonDisabled"
        v-tooltip="{
          content: isUpdateButtonDisabled ? 'A value must be changed to update' : null,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { CURRENCY_CODES } from '@/utils/constants';
import { clone } from '@/utils/helpers';

export default {
  data() {
    return {
      regionSettings: {
        defaultTimezone: null,
        currency: null,
      },
      currencyOptions: CURRENCY_CODES.map((c) => ({ name: c.code })),
    };
  },

  computed: {
    ...mapState({
      isUpdatingRegionSettings: state => state.auth.isUpdatingRegionSettings,
      currentRegionSettings: state => state.auth.regionSettings,
    }),


    selectedCurrencyIndex() {
      if (!this.regionSettings.currency) {
        return null;
      }
      const currencyCode = this.regionSettings.currency;
      const index = this.currencyOptions.findIndex((c) => c.name === currencyCode);
      return index === -1 ? null : index;
    },

    isUpdateButtonDisabled() {
      if (!this.currentRegionSettings) {
        return (!this.regionSettings.defaultTimezone && !this.regionSettings.currency)
      }
      return !(this.currentRegionSettings.additionalInfo.defaultTimezone != this.regionSettings.defaultTimezone ||
        this.currentRegionSettings.additionalInfo?.currency != this.regionSettings.currency)
    }
  },

  watch: {
    currentRegionSettings(val) {
      const currentPromoterRegionSettigns = clone(val)
      this.$set(this.regionSettings, 'defaultTimezone', currentPromoterRegionSettigns?.additionalInfo?.defaultTimezone || null)
      this.$set(this.regionSettings, 'currency', currentPromoterRegionSettigns?.additionalInfo?.currency || null)
    }
  },

  mounted() {
    this['auth/FETCH_REGION_SETTINGS']();
  },

  methods: {
    ...mapActions([
      'auth/FETCH_REGION_SETTINGS',
      'auth/CREATE_REGION_SETTINGS',
      'auth/UPDATE_REGION_SETTINGS',
    ]),

    resetEditPassword() {
      this.regionSettings = {
        defaultTimezone: null,
        currency: null,
      };
    },

    async handleSaveChangeClick() {
      
      if (!this.currentRegionSettings) {
        // Check region settings
        // If default timezone and currency are both empty, there is no POST that will happen
        // since the region settings property doesn't need to be created if it's still not being updated despite the edit
        if (this.regionSettings.defaultTimezone != null || this.regionSettings.currency != null) {
          this['auth/CREATE_REGION_SETTINGS']({
            property: this.regionSettings
          })
        }
      } else {
        this['auth/UPDATE_REGION_SETTINGS']({
          propertyOid: this.currentRegionSettings.oid,
          property: this.regionSettings
        })
      }
    },

    selectCurrency(val) {
      this.regionSettings.currency = val?.name || null;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/base/mixins';
.settings-region {
  max-width: 800px;
  margin: 59px auto 0;

  .input-heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
  }

  .input-group {
    display: flex;
    margin-bottom: 30px;
    
    .input-section {
      flex-grow: 1;

      .input {
        width: 100%;
      }
    }

    &.sm-max {
      flex-direction: column;
      .label-section {
        flex-basis: 32px;
      }
    }
  }

  .functions-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 39px;
  }
}
</style>
