<template>
  <div>
    <div v-if="!startLoading" class="automation-sidebar-element-wrapper">
      <div class="sidebar-header-wrapper">
        <ar-text
          size="20px"
          :text="headerText"
          weight="bold"
          multiple-lines
          line-height="24px"
        />
        <ar-text
          class="sidebar-header-info"
          size="14px"
          :text="sidebarHeaderInfo"
          weight="normal"
          multiple-lines
        />

        <div class="sidebar-action-wrapper">
          <ar-simple-button
            :text="actionButton"
            :style="{
              height: '32px',
            }"
            @click="() => handleOptionSelect(actionAutomationSettings)"
          />
          <ar-simple-button
            v-if="status != 'new' && promoterIsInAutomationWhitelist"
            :text="isEditButtonVisible ? 'Edit' : 'Insights'"
            outlined
            :style="{
              height: '32px',
            }"
            @click="handleEditButton"
            :disabled="isEditButtonVisible && isEditDisabled"
            v-tooltip.bottom="{
              content: isEditButtonVisible && isEditDisabled ? 'Automations must be paused or stopped in order to edit a trigger or action' : null,
            }"
          />
          <am2-icon-button-dropdown
            class="settings-dropdown"
            :items="settingsItemsFiltered"
            :icon-props="{
              name: 'menu',
            }"
            @select="(item) => handleOptionSelect(item)"
          />
        </div>
      </div>

      <div v-show="isEditEnabled || status === 'new' || !promoterIsInAutomationWhitelist" class="sidebar-editable-section">

        <div class="sidebar-tabs-wrapper">
          <am2-elegant-tabs
            layout="wide"
            class="tabs"
            :items="blockTypes"
            :tab-key="selectedSidebarTab"
            @select="selectSidebarTab"
          />
          <am2-search
            tabindex="0"
            ref="timzoneSearchInput"
            class="sidebar-search"
            v-model="sidebarSearchText"
            :placeholder="`Search ${selectedSidebarTab}`"
            data-test-id="sidebar-search-text"
          />
        </div>
        <div class="sidebar-draggable-wrapper">
          <component
            :is="selectedSidebarTab"
            :options="filteredSidebarOptions"
          />
        </div>
      </div>
      <div v-show="!(isEditEnabled || status === 'new') && promoterIsInAutomationWhitelist" class="sidebar-insights-section">
        <div v-if="!!automationInsights" class="sidebar-insights-refresh-section">
          <ar-text
            size="14px"
            :text="lastUpdatedTime"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-link-button
            class="u-margin-left-3"
            text="Refresh"
            :disabled="refreshLinkDisabled"
            @click="handleRefreshButton"
          />
        </div>
        <div v-else class="sidebar-insights-empty">
          <am2-loading-bubble />

          <ar-text
            class="u-margin-top-4"
            size="14px"
            text="Insights loading"
            multiple-lines
          />
        </div>
        <automationInsights />
      </div>
    </div>
    <div v-else class="loading automation-sidebar-element-wrapper">
      <am2-loading-bubble />

      <ar-text
        size="20px"
        text="Automation starting"
        multiple-lines
        line-height="24px"
      />
    </div>
  </div>
</template>
<script>
import Triggers from './AutoTriggers'
import Actions from './AutoActions'
import AutomationInsights from '../AutomationInsights'
import { mapState, mapActions, mapGetters } from 'vuex'
import { sanitizeHtmlChild } from '@/utils/html-element/'
import { debounce } from "debounce";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(utc)

export default {
  name: 'SidebarElements',
  components: {
    triggers: Triggers,
    actions: Actions,
    automationInsights: AutomationInsights,
  },
  data() {
    return {
      sidebarSearchText: '',
      filteredSidebarOptions: null,
      settingsItems: [
        {
          name: 'Start',
          action: this.startAutomation,
          term: 'starting',
          disabled: false,
        },
        {
          name: 'Pause',
          action: this.pauseAutomation,
          term: 'pausing',
          disabled: false,
        },
        {
          name: 'Stop',
          action: this.stopAutomation,
          term: 'stopping',
          disabled: false,
        },
        {
          name: 'Duplicate',
          action: this.duplicateAutomation,
          term: 'duplicated',
          disabled: false,
        },
        {
          name: 'Delete',
          action: this.deleteAutomationModal,
          term: 'deleted',
          disabled: false,
        }
      ],
      blockTypes: [{
        name: "Triggers",
        key: "triggers"
      }, {
        name: "Actions",
        key: "actions"
      }],
      isEditClicked: false,
      refreshRequestedTime: null,
    }
  },
  mounted() {
    if (!this.isFeatureEnabled(['automation'])) {
      this.restrictMenuActions()
      this.updateEditEnabled(this.status === 'new')
    }
  },
  watch: {
    sidebarSearchText() {
      this.filterSidebarOptions(this.sidebarSearchText)
    },
    selectedSidebarTab: {
      immediate: true,
      handler() {
        this.sidebarSearchText = ''
        if (this.selectedSidebarTab === 'triggers') {
          this.filteredSidebarOptions = this.getFilteredTriggerOptions('')
        } else if (this.selectedSidebarTab === 'actions') {
          this.filteredSidebarOptions = this.getFilteredActionOptions('')
        } else {
          this.filteredSidebarOptions = this.getFilteredActionOptions('')
        }
      }
    },

    status(newVal, oldVal) {
      if (newVal === 'error') {
        this.resetOptions()
      }

      this.updateEditEnabled(newVal === 'new')
    },

    automationInsights(val) {
      this.pollAutomationInsights(val)
    },

    automationId(val, oldVal) {
      if (!oldVal && !!val) {
        this.pollAutomationInsights(this.automationInsights)
      }
    },
  },
  computed: {
    ...mapState({
      selectedSidebarTab: state => state.automation.selectedSidebarTab,
      status: state => state.automation.status,
      isEditEnabled: state => state.automation.isEditEnabled,
      automationId: state => state.automation.id,
      startLoading: state => state.automation.startLoading,
      automationInsights: state => state.automation.automationInsights,
      promoter: state => state.auth.account,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    triggerItems() {
      return {
        purchase: {
          icon: 'credit-card',
          text: 'Purchase',
          children: [
            {
              key: 'purchased.ticket-to-event',
              name: 'Purchased ticket',
              icon: 'credit-card',
              type: 'trigger',
              config: null,
              meta: {},
            },
            {
              key: 'purchased.product-from-store',
              name: 'Purchased product',
              icon: 'credit-card',
              type: 'trigger',
              config: null,
              meta: {},
            },
          ]
        },
        campaign: {
          icon: 'target',
          text: 'Campaign',
          children: [
            {
              key: 'campaign.registered',
              name: 'Registers for campaign',
              icon: 'target',
              type: 'trigger',
              config: {},
              meta: {},
            },
          ]
        },
        contact: {
          icon: 'contacts',
          text: 'Contact',
          children: [
            {
              key: 'contact.tag.added',
              name: 'Tag added',
              icon: 'contacts',
              type: 'trigger',
              config: {},
              meta: {},
            },
            {
              key: 'contact.tag.removed',
              name: 'Tag removed',
              icon: 'contacts',
              type: 'trigger',
              config: {},
              meta: {},
            },
            {
              key: 'contact.birthday',
              name: 'Birthday',
              icon: 'birthday',
              type: 'trigger',
              config: {},
              meta: {},
            },
          ]
        },
        messaging: {
          icon: 'message',
          text: 'Messaging',
          children: [
            {
              key: 'messaging.list.subscribed',
              name: 'Subscribes to list',
              icon: 'message',
              type: 'trigger',
              config: {},
              meta: {},
            },
            {
              key: 'messaging.list.unsubscribed',
              name: 'Unsubscribes from list',
              icon: 'message',
              type: 'trigger',
              config: {},
              meta: {},
            },
            // {
            //   key: 'messaging.link.visited',
            //   name: 'Clicks a link',
            //   icon: 'message',
            //   type: 'trigger',
            //   config: {},
            //   meta: {},
            // },
          ]
        },
        other: {
          icon: 'other',
          text: 'Other',
          children: [
            {
              key: 'short-url.visited',
              name: 'Clicks on short URL',
              icon: 'other',
              type: 'trigger',
              config: {},
              meta: {},
            },
            ...(!this.isProduction ? [{
              key: 'webhook',
              name: 'Webhook',
              icon: 'other',
              type: 'trigger',
              config: {},
              meta: {},
            }] : []),
            {
              key: 'event-date',
              name: 'Event Date',
              icon: 'calendar',
              type: 'trigger',
              config: {},
              meta: {},
            }
          ],
        }
      }
    },
    actionItems() {
      return {
        messaging: {
          icon: 'message',
          text: 'Messaging',
          children: [
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'send.email',
                name: 'Send email',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'send.sms',
                name: 'Send SMS',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'list.add',
                name: 'Add to List',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'list.remove',
                name: 'Remove from List',
                icon: 'message',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
          ]
        },
        contact: {
          icon: 'contacts',
          text: 'Contact',
          children: [
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'tag.add',
                name: 'Add a Tag',
                icon: 'contacts',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'tag.remove',
                name: 'Remove a Tag',
                icon: 'contacts',
                type: 'action',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
          ]
        },
        conditions: {
          icon: 'branch',
          text: 'Conditions',
          children: [
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'if-else',
                name: 'If/Else',
                icon: 'branch',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'delay-by',
                name: 'Delay by',
                icon: 'clock',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'wait-until',
                name: 'Wait until',
                icon: 'calendar',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
            {
              id: null,
              parentOutBoxId: null,
              block: {
                id: null,
                key: 'unique',
                name: 'Unique',
                icon: 'fingerprint',
                type: 'control',
                parentOutBoxId: null,
                config: {},
                meta: {}
              },
            },
          ]
        },
      }
    },
    isProduction() {
      let subdomain = window.location.hostname.split('.').slice(0, -2).join('.')
      return subdomain === 'app'
    },
    infoText() {
      return this.selectedSidebarTab === 'triggers' ? 'a trigger' : 'an action'
    },
    actionButton() {
      switch(this.status) {
        case 'new':
        case 'stopped':
        case 'paused':
        case 'error':
          return 'Start';
        case 'started':
          return 'Pause';
        default:
          return '';
      }
    },
    headerText() {
      if (this.status === 'new') {
        return 'Create'
      } else {
        if (this.isEditEnabled) {
          return 'Edit'
        } else {
          return 'Insights'
        }
      }
    },
    isEditDisabled() {
      return this.status === 'started'
    },
    isEditButtonVisible() {
      return !this.isEditEnabled && this.promoterIsInAutomationWhitelist
    },
    sidebarHeaderInfo() {
      if (this.isEditButtonVisible) {
        return `Select a trigger or action on the canvas to view  insights specific to your selection.`
      } else {
        return `Drag and drop ${this.infoText} into the canvas.`
      }
    },
    settingsItemsFiltered() {
      return this.settingsItems.filter((item) => {
        if (this.status === 'paused') {
          return item.name !== 'Start' && item.name !== 'Pause'
        } else if (this.status === 'stopped') {
          return item.name !== 'Start' && item.name !== 'Pause' && item.name !== 'Stop'
        } else if (this.status === 'started') {
          return item.name !== 'Start' && item.name !== 'Pause'
        } else if (this.status === 'new') {
          return item.name === 'Delete'
        } else {
          return true
        }
      })
    },
    actionAutomationSettings() {
      return this.settingsItems.find((item) => {
        return item.name === this.actionButton
      })
    },
    lastUpdatedTime() {
      if (!this.automationInsights?.updateFinishedDate) {
        return 'Has never been updated'
      }

      const lastUpdatedDate = dayjs(this.automationInsights.updateFinishedDate).fromNow()
      return `Updated ${lastUpdatedDate}`
    },
    refreshLinkDisabled() {
      if (!this.automationInsights?.updateRequestedDate) {
        return false //'Has never been updated' or 'Has been manually updated from the database'
      }

      if (!this.automationInsights?.updateFinishedDate) {
        return true // 'Update on-going'
      }

      const updateFinishedDate = this.automationInsights.updateFinishedDate
      const updateRequestedDate = this.automationInsights.updateRequestedDate


      // Check if update is still on-going
      return dayjs(updateFinishedDate).isBefore(updateRequestedDate)
    },

    promoterIsInAutomationWhitelist() {
      if (this.promoter?.emailAddress?.endsWith('@arep.co')) return true;
      if (this.promoter?.emailAddress?.endsWith('@audiencerepublic.com')) return true;
      return false;
    },
  },

  beforeDestroy() {
    if (!!this.initialiseAutomationInsightsPoll) {
      clearInterval(this.initialiseAutomationInsightsPoll)
    }
  },

  methods: {
    ...mapActions({
      setSidebarTab: 'automation/SET_SIDEBAR_TAB',
      startAutomation: 'automation/START',
      pauseAutomation: 'automation/PAUSE',
      stopAutomation: 'automation/STOP',
      deleteAutomation: 'automation/DELETE',
      newAutomation: 'automation/NEW_AUTOMATION',
      renameAutomation: 'automation/RENAME_AUTOMATION',
      showConfirm: 'SHOW_CONFIRM',
      updateEditEnabled: 'automation/UPDATE_EDIT_ENABLED',
      duplicateAutomation: 'automation/DUPLICATE',
      setErrors: 'automation/SET_ERRORS',
      updateAutomationInsights: 'automation/REQUEST_AUTOMATION_INSIGHTS_UPDATE',
      fetchAutomationInsights: 'automation/FETCH_AUTOMATION_INSIGHTS',
    }),
    filterSidebarOptions(val) {
      let filteredOptions
      if (this.selectedSidebarTab === 'triggers') {
        filteredOptions = this.getFilteredTriggerOptions(val)
      } else {
        filteredOptions = this.getFilteredActionOptions(val)
      }
      this.filteredSidebarOptions = filteredOptions
    },
    getFilteredTriggerOptions(val) {
      let filteredSidebarOptionsArr = []

      let options = Object.entries(this.triggerItems)
      let i = 0
      while (i < options.length) {
        let filteredSidebarOptions = {}
        let key = options[i][0]
        let { icon, text, children } = options[i][1]

        let matchedActions = []
        let j = 0
        while (j < children.length) {
          if (this.$arFuzzySearch(children[j].name, val)) {
            matchedActions.push(children[j])
          }

          j++
        }

        if (!!matchedActions.length) {
          filteredSidebarOptions[key] = {
            icon,
            text,
            children: matchedActions,
          }
          filteredSidebarOptionsArr.push(filteredSidebarOptions)
        }

        i++
      }
      return filteredSidebarOptionsArr
    },
    getFilteredActionOptions(val) {
      let filteredSidebarOptionsArr = []

      let options = Object.entries(this.actionItems)
      let i = 0
      while (i < options.length) {
        let filteredSidebarOptions = {}
        let key = options[i][0]
        let { icon, text, children } = options[i][1]

        let matchedActions = []
        let j = 0
        while (j < children.length) {
          if (this.$arFuzzySearch(children[j].block.name, val)) {
            matchedActions.push(children[j])
          }

          j++
        }

        if (!!matchedActions.length) {
          filteredSidebarOptions[key] = {
            icon,
            text,
            children: matchedActions,
          }
          filteredSidebarOptionsArr.push(filteredSidebarOptions)
        }

        i++
      }
      return filteredSidebarOptionsArr
    },
    selectSidebarTab(type) {
      this.setSidebarTab(type.key)
    },

    async handleOptionSelect(item) {
      if (item.name === 'Delete') {
        try {
          const deleteModalAnswer = await item.action({id: this.$route.query.oid, skipListFilter: true})

          if (deleteModalAnswer) {
            this.$arNotification.push({ type: 'success', message: `Automation has been deleted.` })
            this.$router.push('/automation')
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        this.settingsItems.find(option => {
          if (option.term === item.term && !option?.type) {
            option.disabled = true
          } else {
            option.disabled = false
          }
        })

        try {
          const response = await item.action()
          this.$arNotification.push({ type: 'success', message: `Automation is ${item.term}.` })
        } catch (error) {
          let errObj = Object.assign({}, error)
          if (errObj?.['response']?.data?.message?.details) {
            this.handleErr(errObj['response'].data.message.details)
          }

          console.error(error)
        }
      }
    },

    resetOptions() {
      let i = 0
      let length = this.settingsItems.length
      while (i < length) {
        if (!this.settingsItems[i]?.type) {
          this.settingsItems[i].disabled = false
        }
        i++
      }
    },
    restrictMenuActions() {
      this.settingsItems = this.settingsItems.map(item => {
        if (item.name === 'Delete') return { ...item }

        return { ...item, disabled: true }
      })
    },

    handleEditButton() {
      this.updateEditEnabled(this.isEditButtonVisible)
    },

    handleErr(msg) {
      if (typeof msg === 'string') {
        if (msg.includes("Configuration has errors")) {
          this.$arNotification.push({ type: 'error', message: `One or more items in this Automation is missing data.` })
        }
        if (msg.includes("Add blocks first")) {
          this.$arNotification.push({ type: 'error', message: `Sidebar - Atleast one action must exist within your Automation.` })
        }
      } else {
        const blockOid = msg?.blockOid

        if (!!blockOid) {
          this.setErrors(msg)
          this.$arNotification.push({ type: 'error', message: `Your automation isn’t ready to start, please complete or remove the action/trigger highlighted red` })
        }
      }
    },

    deleteAutomationAction() {
      this.deleteAutomation({id: this.automationId})
    },

    async deleteAutomationModal() {
      return await this.showConfirm({
        title: 'Delete this automation?',
        messageHtml: `You’re about to delete <b>${sanitizeHtmlChild(this.name)}</b>, are you sure you want to proceed? Campaigns, lists, or tags referenced in this automation will not be deleted.`,
        confirmButtonText: 'Delete',
        validation: {
          question: 'Type DELETE to confirm',
          answer: 'DELETE',
        },
        asyncCompleteFunction: this.deleteAutomationAction,
      });
    },

    handleRefreshButton() {
      if (!this.refreshRequestedTime) {
        this.updateAndPollAutomationInsights()
        return;
      }
      if (!!this.refreshRequestedTime && dayjs().isAfter(this.refreshRequestedTime.add(10, 'second'))) {
        this.updateAndPollAutomationInsights()
      } else {
        this.$arNotification.push({ type: 'warning', message: `Please wait a few minutes and try again` })
      }
    },

    async updateAndPollAutomationInsights() {
      if (!this.automationId) {
        return;
      }
      this.refreshRequestedTime = dayjs()

      const updateResponse = await this.updateAutomationInsights({ automationOid: this.automationId })

      if (!!updateResponse) {
        // Retrieve automation insights every 10 seconds until the finished date is more recent than or the same as the requested date
        this.initialiseAutomationInsightsPoll = setInterval(async () => {
          const insights = await this.fetchAutomationInsights({ automationOid: this.automationId || this.$route.query.oid })

          const updateFinishedDate = insights.updateFinishedDate
          const updateRequestedDate = insights.updateRequestedDate

          if (!dayjs(updateFinishedDate).isBefore(updateRequestedDate)) {
            clearInterval(this.initialiseAutomationInsightsPoll);
            console.log("Stopped polling automation insights after refresh request")
          }
        }, 10000);
      }

    },

    pollAutomationInsights(insight) {
      // Only generate insights when an automation has been
      // started, paused, stopped, or has an error
      if (!(this.status == 'started' || this.status == 'paused' || this.status == 'stopped' || this.status == 'error')) {
        return;
      }

      // Automation insights has not been generated
      if (!insight) {
        this.updateAndPollAutomationInsights()
      } else {
        // Automation insights has been previously generated
        const updateFinishedDate = insight.updateFinishedDate
        const updateRequestedDate = insight.updateRequestedDate
        let difference = 0
        const MINUTE_THRESHOLD = 10

        const updateOnGoing = dayjs(updateFinishedDate).isBefore(updateRequestedDate)
        if (!updateRequestedDate) {
          // There is no update requested date, so let's just reload the insights anyway
          difference = MINUTE_THRESHOLD
        } else {
          difference = dayjs(updateFinishedDate).diff(updateRequestedDate, 'minute')
        }

        if (!updateOnGoing && difference >= MINUTE_THRESHOLD) {
          this.updateAndPollAutomationInsights()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.automation-sidebar-element-wrapper {
  width: 100%;
  background: white;
  padding: 32px 22px 0 22px;
  height: 100vh;
  display: block;

  &.loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
  }

  .settings-dropdown {
    border-radius: 4px;
    background: #EDF1F5;
  }

  .sidebar-tabs-wrapper {
    margin-bottom: 24px;

    .tabs {
      width: 312px;
      margin-top: 24px;
      margin-bottom: 14px;
    }


    .sidebar-search {
      width: 312px;
      margin-bottom: 33px;
    }
  }

  .sidebar-header-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .sidebar-action-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 12px;
    }

    .sidebar-link-buttons {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      .sidebar-tab-link {
        margin-right: 14px;
        color: $blueGrey800;
        opacity: 0.5;
        cursor: pointer;

        &.active {
          opacity: 1;
        }
      }
    }

    .sidebar-header-info {
      color: $blueGrey700;
      margin-bottom: 24px;
      margin-top: 4px;
    }
  }

  .sidebar-editable-section {
    height: calc(100% - 120px);
    /*max-height: calc(100% - 500px);*/

    .sidebar-draggable-wrapper {
      max-height: calc(100% - 150px);
      overflow: scroll;
      margin-top: 24px;
    }

  }

  .sidebar-insights-empty {
    margin-top: 24px;
    border: 1px solid $skyBlueGrey500;
    border-radius: 8px;
    padding: 32px 20px 20px 20px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sidebar-insights-section {
    height: calc(100% - 194px);

    .sidebar-insights-refresh-section {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

  }

 }
</style>
