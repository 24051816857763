let clientId;
switch(process.env.arEnvironment) {
  // oneschema dashboard
  case "production":
    clientId = "22ce1d32-714f-4fe0-8fee-7626901be476"
    break;
  case "staging":
    clientId = "5615c3ff-935c-4662-b0b1-b826320187a4";
    break
  default: // development
    clientId = "46351ddc-ea6a-45f9-a734-920cb19fe272"
    break;
}

export const oneschemaConfig = {
  clientId: clientId,
  importConfig: { type: "local", metadataOnly: true },
  devMode: process.env.arEnvironment === "development",
  styles: {
  width: '100vw',
    height: '100vh',
    border: 'none',
    position: 'absolute',
    right: '0',
    top: '0',
    'z-index': '1000',
  }
}
