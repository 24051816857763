<template>
  <am2-card-container
    :style="{ padding: $arMediaQuery.pageContent.maxWidth('sm') ? '20px' : '24px 30px' }"
    layout="soft"
  >
    <section :class="[
      'domain',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
    ]">
      <div :class="[
        'left-section',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        $arMediaQuery.pageContent.minWidth('sm') && 'sm-min',
      ]">
        <div class="left-section-item u-align-items-center">
          <div
            class="context"
            :style="{
              height: $arMediaQuery.pageContent.minWidth('lg') && '44px',
            }"
          >
            <ar-icon
              v-if="defaultDomain"
              width="20px"
              height="16px"
              name="lock"
              class="u-margin-right-3"
            />
            <am2-heading
              type="h1"
              size="sm"
              :title="data.domain"
              weight="bold"
              :align="$arMediaQuery.pageContent.minWidth('sm') ? 'left' : 'center'"
            />
          </div>
        </div>

      </div>
      <div :class="[
        'right-section',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
      ]">
        <am2-tag
          :text="matrix[status].title"
          :class="[
            'status-tag',
            $arMediaQuery.pageContent.maxWidth('sm') && 'u-display-none',
          ]"
          :type="matrix[status].tagColor"
          v-tooltip.top="{
            content: matrix[status].tooltip ? matrix[status].tooltip : null
          }"
        />
        <am2-icon-button-dropdown
          :dropdown-style="{
            width: '128px',
          }"
          class="menu-dropdown"
          :items="actionItems"
          @select="handleActionSelect"
        />
      </div>
    </section>
  </am2-card-container>
</template>

<script>

import dayjs from 'dayjs';

export default {
  name: 'DomainCard',

  props: {
    data: {
      type: Object,
      default: {}
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultDomain: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      matrix: {
        default: {
          title: 'Default',
          tagColor: 'purple'
        },
        verified: {
          title: 'Verified',
          tagColor: 'green'
        },
        unverified: {
          title: 'Verification required',
          tooltip: 'Please check that these records have been added to your DNS settings and try again',
          tagColor: 'orange',
        },
        pending: {
          title: 'Verification pending',
          tooltip: 'Your domain is being validated, please try again if this does not update soon',
          tagColor: 'light-grey',
        },
      }
    }
  },

  computed: {
    status() {
      const verified = this.data.verified;
      const allHostRecordsValid = Object.values(this.data.hostRecords).every((hr) => !!hr.valid);
      if (!!verified && allHostRecordsValid) {
        return 'verified'
      } else if (!!this.data.lastVerificationAttempt) {
        const lastVerifiedPlus = dayjs(this.data.lastVerificationAttempt).add('5', 'minutes')
        if (dayjs().isAfter(lastVerifiedPlus)) {
          return 'unverified'
        }
        return 'pending'
      } else {
        return 'unverified'
      }
    },

    actionItems() {
      const defaultOptions = [
        {
          name: 'Delete',
          key: 'delete',
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          },
        }]
      if (this.status === 'unverified') {
        return [
          {
            name: 'Verify',
            key: 'verify',
          },
          ...defaultOptions,
        ]
      } else {
        return [
          {
            name: 'View details',
            key: 'view',
          },
          ...defaultOptions
        ];
      }
    },
  },

  methods: {
    handleActionSelect({key}) {
      if (key === 'view') {
        this.$emit('viewDomain')
      } else if (key === 'delete') {
        this.$emit('deleteDomain')
      } else if (key === 'verify') {
        this.$emit('verifyDomain')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/base/mixins';
.domain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.center-align {
    align-items: center;
  }

  &.sm-max {
    flex-direction: column;
  }

  &.sm-min {
    flex-direction: row;
  }

  .right-section {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .status-tag {
      margin: 0 14px;
    }

    .icon {
      color: $blueGrey700;
    }

    &.sm-max {
      max-width: calc(100% - 50px);
      margin-top:16px;
      align-self: flex-end;
    }

    &.xs-max {
      align-self:center;
    }
  }

  .left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .left-section-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-shrink: 1;
      max-width: calc(100%);
      justify-content: space-between;
    }

    .icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-right: 29px;
    }

    .context {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.xs-max {
      flex-direction:column;
      align-items: center;
      align-self: center;
      .icon {
        margin-right:0;
        margin-bottom:16px;
      }
      .context {
        max-width: calc(100% - 50px);
      }
    }

    &.sm-max {
      max-width:100%;
      .context {
        max-width: calc(100% - 50px);
      }

      .left-section-item {
        margin-bottom: 5px;
      }
    }

    &.sm-min {
      max-width: calc(100% - 100px);
    }
  }
}
</style>
