<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Create an A/B test"
      width="472px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="ab-email-modal"
    >
      <div slot="body" class="body">
        <div>
          <ar-text size="sm" weight="bold" :text="'What would you like to test?'" />
          <ar-text
            multiple-lines
            size="xs"
            class="sub-heading"
            text="Select a test type, create a variation and determine which variation performs better with your subscriber list."
          />
          <div class="ab-designs">
            <div class="ab-type" :class="{ selected: isDesignSelected }" @click="handleDesignClick" data-test-id="ab-modal-select-design">
              <PencilIcon class="icon" />
              <ar-text size="sm" weight="bold" text="Designs" />
              <ar-text multiple-lines size="xs" class="sub-heading" text="Test click rates" />
            </div>

            <div class="ab-type" :class="{ selected: isSubjectSelected }" @click="handleSubjectClick" data-test-id="ab-modal-select-subject">
              <MailIcon class="icon" />
              <ar-text size="sm" weight="bold" text="Subject Lines" />
              <ar-text multiple-lines size="xs" class="sub-heading" text="Test open rates" />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <ar-link-button
          text="Cancel"
          @click="handleModalClose"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button text="Create" @click="handleCreateAbEmailClick" />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import PencilIcon from '~/assets/icons/pencil.svg?inline';
import MailIcon from '~/assets/icons/mail.svg?inline';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'AbEmailModal',
  components: {
    PencilIcon,
    MailIcon
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDesignSelected: false,
      isSubjectSelected: false,
      template: null,
      emailHtml: null,
      showSelectEmailTemplateSection: false
    };
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.initEmailEditor();
      }
    }
  },

  methods: {
    ...mapMutations({
      createAbEmail: 'message/CREATE_AB_EMAIL_MESSAGE'
    }),
    initEmailEditor() {},
    handleModalClose() {
      this.$emit('cancel');
      this.isSubjectSelected = false;
      this.isDesignSelected = false;
    },
    handleCreateAbEmailClick() {
      if (this.isDesignSelected) {
        this.$emit('create', 'design');
      }
      if (this.isSubjectSelected) {
        this.$emit('create', 'subject');
      }
      this.createAbEmail();
      this.isSubjectSelected = false;
      this.isDesignSelected = false;
    },
    handleDesignClick() {
      this.isDesignSelected = true;
      this.isSubjectSelected = false;
    },
    handleSubjectClick() {
      this.isSubjectSelected = true;
      this.isDesignSelected = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.ab-email-modal {
  .body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 48px;
    .ab-type {
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      width: 188px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      padding: 12px 16px;
      cursor: pointer;
    }
    .ab-type.selected {
      background-color: #f4f0fa;
      color: #7344c0;
      border: 1px solid #7344c0;
      .ar-text {
        color: #7344c0;
      }
      .icon {
        color: #7344c0;
      }
    }
    .ab-type:hover {
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
    }
  }
  .ab-designs {
    display: flex;
    gap: 32px;
    margin-top: 24px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    padding: 0 30px;
  }
}
.icon {
  color: #8e97a6;
}
.sub-heading {
  color: #8e97a6;
}
</style>
