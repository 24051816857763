<template>
  <div
    :class="[
      'table-outer-wrapper',
      hasControlSection && 'top-edge-straight'
    ]"
    @mouseleave="handleTableMouseLeave"
  >
    <!-- Right shadow -->
    <div
      ref="right-table-shadow"
      :class="['table-shadow', 'right']"
      :style="{
        boxShadow: hasRightTableShadow ? `inset -15px 0 14px -16px ${this.$arStyle.color.blueGrey700}` : null,
      }"
    />
    <!-- Left shadow -->
    <div
      ref="left-table-shadow"
      :class="['table-shadow', 'left']"
      :style="{
        left: showTicks ? '48px' : 0,
        borderTopLeftRadius: showTicks ? null : '5px',
        boxShadow: hasLeftTableShadow ? `inset 15px 0 14px -16px ${this.$arStyle.color.blueGrey700}` : null,
      }"
    />
    <!-- No Content -->
    <div
      v-if="isNoRows"
      class="no-content"
    >
      <ar-text
        size="xs"
        :text="emptyText"
        align="center"
        :style="{
          color: $arStyle.color.blueGrey600,
        }"
      />
    </div>


    <!-- Table Container -->
    <div
      ref="table-container"
      :style="{
        borderBottom: loading ? 'none' : null,
      }"
      class="table-container">


      <!-- Table Header -->
      <div
        :class="[
          'table-header-outer-container',
          hasControlSection && 'top-edge-straight'
        ]"
        :style="{
          width: '100%',
          transition: 'top 0.2s',
        }"
        v-ar-sticky-top="{
          disabled: !hasStickyHeader,
          priority: 1,
        }"
      >

        <!-- Table Header - CHECKBOXES -->
        <div
          v-if="showTicks"
          :class="[
            `table-header-container`,
            `checkboxes-container`,
          ]"
          :style="{
            width: `50px`,
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: $arStyle.zIndex.highest,
          }">
          <thead
            ref="theadCheckboxes"
            :style="{
              width: `39px`,
            }"
            class="thead">
            <!--  :style="{borderRight: `1px solid #d8dde3`}" -->
            <div class="tr">
              <div
                class="th"
                data-test-id="table-header-checkbox-th"
                :style="{
                  display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                  width: `${tickColumnWidth}px`,
                  cursor: locked ? 'not-allowed' : 'pointer',
                }"
                @click="handleTickAllCheckboxClick"
              >
                <ar-checkbox
                  ref="tick-all-checkbox"
                  data-test-id="table-header-checkbox"
                  v-show="!isNoRows"
                  :style="{
                    pointerEvents: 'none',
                  }"
                  :value="tickAllRows"
                />
              </div>
            </div>
          </thead>
        </div>

        <!-- Table Header - EVERYTHING ELSE -->
        <div
          :class="[
            `table-header-container`,
            hasControlSection && `spacious`
          ]"
          ref="table-header-container"
          :style="{
            width: '100%',
          }">
          <thead
            ref="thead"
            :style="{
              minWidth: `${minTableWidth}px`,
            }"
            class="thead">
            <div class="tr">
              <div
                v-if="showTicks"
                class="th"
                :style="{
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                  width: `${tickColumnWidth}px`,
                  cursor: locked ? 'not-allowed' : 'pointer',
                }"
              >
                <!-- EMPTY -->
              </div>
              <div
                class="th"
                v-for="(head, idx) in heads"
                :key="head.key"
                :style="{
                  cursor: resizingColumn !== null ? 'col-resize' : head.sortKey ? 'pointer' : null,
                  width: `${headWidths[idx] || 150}px`,
                  justifyContent: head.align === 'left' ? 'flex-start' : 'flex-end',
                  padding: customHeadPadding ? customHeadPadding : null,
                }"
                v-tooltip.top="{
                  content: head.lock && head.lockReason ? head.lockReason : null,
                }"
                @click="event => handleSortClick(head.sortKey, event)"
                @mouseenter="handleCellMouseEnter(head)"
                @mouseleave="handleCellMouseLeave(head)"
              >
                <ar-icon
                  v-if="(showTicks || idx > 0) && sortBy.key === head.sortKey && !!head.sortKey"
                  class="arrow"
                  name="arrow"
                  :color="$arStyle.color.blueGrey700"
                  :rotate="sortBy.order === 'asc' ? 180 : 0"
                  :style="{
                    marginLeft: `-16px`,
                    marginRight: `7px`
                  }"
                />
                <ar-text
                  size="xs"
                  :text="head.name"
                  weight="bold"
                  :style="{
                    paddingRight: head.align === 'right' && head.lock ? '10px' : null,
                  }"
                />
                <ar-icon
                  v-if="!showTicks && sortBy.key === head.sortKey && !!head.sortKey && idx == 0"
                  class="arrow"
                  name="arrow"
                  :color="$arStyle.color.blueGrey700"
                  :rotate="sortBy.order === 'asc' ? 180 : 0"
                  :style="{
                    marginLeft: `7px`,
                    marginRight: `0px`
                  }"
                />
                <ar-icon
                  class="lock"
                  v-if="head.lock"
                  name="lock"
                  :color="$arStyle.color.blueGrey600"
                  height="14px"
                />
                <!-- Temporarily disable table resize
                  @mousedown="event => handleTableResizeMouseDown(event, idx)" -->
                <div
                  class="table-header-edge"
                  :style="{
                    backgroundColor: resizingXPosition !== null && idx === resizingColumn ? $arStyle.color.blueGrey800 : null,
                    right: resizingXPosition !== null && idx === resizingColumn ? `${resizingXPosition}px` : null,
                  }"
                >

                </div>
              </div>
            </div>
          </thead>
        </div>
      </div>

      <!-- Table Body -->
      <div @mouseleave="handleRowMouseLeave()">
        <!-- Table Body - CHECKBOXES -->
        <div
          v-if="showTicks"
          class="table-body-container checkboxes-container"
          :style="{
            width: '49px',
            position: 'absolute',
            left: '0',
            top: '52px',
            overflow: 'visible',
            zIndex: $arStyle.zIndex.regular,
            marginLeft: '2px',
          }">
          <div :class="['tbody', locked && 'locked']">
            <div
              class="tr-container"
              :style="{
                paddingTop: `${topHiddenRowsCount * rowHeight}px`,
                paddingBottom: `${bottomHiddenRowsCount * rowHeight}px`
              }"
            >
              <div
                v-for="(row, rowIndex) of visibleRows"
                :key="rowIndex"
                class="tr-wrapper"
              >
                <div
                  :class="['tr', row.class]"
                  :style="{
                    cursor: enableRowClick ? 'pointer' : null,
                    background: checkRowActivity(row, rowIndex) ? $arStyle.color.purple100 : null,
                  }"
                  @mouseenter="handleRowMouseEnter(rowIndex)"
                >
                  <div
                    class="td"
                    :style="{
                      minHeight: `${rowHeight}px`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: `${tickColumnWidth}px`,
                      cursor: 'pointer',
                      zIndex: $arStyle.zIndex.regular,
                    }"
                    @click.stop="handleRowTick(row)"
                  >
                    <ar-checkbox
                      ref="checkbox"
                      :style="{ pointerEvents: 'none' }"
                      :value="tickedRowsMap[row[rowKeyName]]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Table Body - EVERYTHING ELSE -->
        <div
          :class="[
          `table-body-container`,
          hasControlSection && `spacious`,
          ]"
          ref="table-body-container"
          :style="{
            width: '100%',
          }">
          <div
            ref="tbody"
            :style="{ minWidth: `${minTableWidth}px` }"
            :class="['tbody', locked && 'locked']"
          >
            <div class="gradient-mask" />
            <div
              v-if="!loading && isNoRows"
              class="tr-container"
              :style="{ minHeight: '50px' }"
            >
              <div class="tr-wrapper">
                <div class="tr"
                  :style="{ minHeight: '50px' }"
                />
              </div>
            </div>
            <div
              class="tr-container"
              v-if="!isNoRows"
              :style="{
                paddingTop: `${scrollPaddingTop}px`,
                paddingBottom: `${scrollPaddingBottom}px`
              }"
            >
              <div
                v-for="(row, rowIndex) of visibleRows"
                :key="rowIndex"
                class="tr-wrapper"
              >
                <div
                  :class="['tr', row.class]"
                  :style="{
                    cursor: enableRowClick ? 'pointer' : null,
                    background: checkRowActivity(row, rowIndex) ? $arStyle.color.purple100 : null,
                  }"
                  @mouseenter="handleRowMouseEnter(rowIndex)"
                  @click="handleRowClick(row)"
                >
                  <div
                    v-if="showTicks"
                    class="td"
                    :style="{ width: `${tickColumnWidth}px`, minHeight: `${rowHeight}px` }"
                  />
                  <div
                    v-for="(head, idx) in heads"
                    :class="[
                      'td',
                      head.lock && 'blur'
                    ]"
                    :key="head.key"
                    :style="{
                      width: `${headWidths[idx] || 150}px`,
                      minHeight: `${rowHeight}px`,
                    }"
                    @mouseenter="handleCellMouseEnter(head)"
                    @mouseleave="handleCellMouseLeave(head)"
                  >
                    <!-- Let user decide style of each column -->
                    <slot
                      :name="head.format"
                      :data="row"
                      :index="getRowIndex(row)"
                      :head="head"
                      :active="activeRowIndex === rowIndex"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Loading State -->
            <div v-if="loading">
              <div class="tr" v-for="n in 12" :key="n">
                <div
                  v-if="showTicks"
                  class="td"
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: `${tickColumnWidth}px`,
                    minHeight: `${rowHeight}px`,
                    marginLeft: '1px',
                  }"
                >
                  <am2-typography-skeleton
                    size="md"
                    :level="n"
                    :style="{ width: '20px' }"
                  />
                </div>
                <div
                  class="td"
                  v-for="(head, idx) in heads"
                  :key="head.key"
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: head.align === 'left' ? 'flex-start' : 'flex-end',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    width: `${headWidths[idx] || 150}px`,
                    minHeight: `${rowHeight}px`,
                  }"
                >
                  <am2-typography-skeleton
                    size="md"
                    :level="n"
                    :style="{ width: `45%`, height: '12px', }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Scroll sync guide
// https://uxdesign.cc/position-stuck-96c9f55d9526

import { objectsEqual } from '@/utils/helpers';
import { debounce } from "debounce";

export default {
  name: 'Table',

  props: {
    heads: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    tickAllRows: {
      type: Boolean,
      default: false,
    },
    tickedRowsMap: {
      type: Object,
      default: () => ({}),
    },
    rowKeyName: {
      type: String,
      default: 'oid',
    },
    rowHeight: {
      type: Number,
      default: 62,
    },
    emptyText: {
      type: String,
      default: 'No data',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: {
      type: Object,
      default: () => null,
    },
    enableRowTick: {
      type: Boolean,
      default: false,
    },
    enableRowClick: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
    hasControlSection: {
      type: Boolean,
      default: false,
    },
    customHeadPadding: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      key: null,
      sortBy: this.defaultSortBy || {
        key: null,
        order: null,
      },
      tickColumnWidth: 49,
      hasRightTableShadow: false,
      hasLeftTableShadow: false,
      activeRowIndex: null,
      windowScrollTop: 0,
      visibleRowsCount: 30,
      resizingColumn: null,
      resizeStart: null,
      resizingXPosition: null,
      headWidths: [],
      distanceFromTopToTable: 0,
    };
  },

  computed: {
    visibleRows() {
      return this.rows.slice(this.topHiddenRowsCount, this.topHiddenRowsCount + this.visibleRowsCount);
    },
    topHiddenRowsCount() {
      if (!this.$refs['table-container'] && this.windowScrollTop) return 0;
      if (this.windowScrollTop - this.distanceFromTopToTable - 200 < 0) return 0;
      return parseInt((this.windowScrollTop - this.distanceFromTopToTable - 200) / this.rowHeight, 10);
    },
    bottomHiddenRowsCount() {
      if (this.rows.length <= (this.visibleRowsCount + this.topHiddenRowsCount)) {
        return 0;
      }
      return this.rows.length - (this.visibleRowsCount + this.topHiddenRowsCount);
    },
    showTicks() {
      return this.enableRowTick;
    },
    isNoRows() {
      return this.rows.length === 0 && !this.loading;
    },
    minTableWidth() {
      const totalHeadsWidth = this.headWidths.reduce((sum, width) => {
        return sum + (width ? parseInt(width, 10) : 150);
      }, 0);

      return this.showTicks ? totalHeadsWidth + this.tickColumnWidth : totalHeadsWidth;
    },
    scrollPaddingTop() {
      return this.topHiddenRowsCount * this.rowHeight;
    },
    scrollPaddingBottom() {
      return this.bottomHiddenRowsCount * this.rowHeight;
    },
  },

  watch: {
    async heads() {
      // Whenever column changes, check if we need shadow again
      await this.$nextTick();
      this.horizontalScrollHandler(true);
      this.syncHeaderAndBody();
      this.headWidths = this.heads.map( head => head.width || 100 );
      this.handleWindowResize();
    },
    defaultSortBy(val) {
      this.sortBy = val;
    },
    rows() {
      this.$nextTick(() => {
        this.calculateDistanceFromTopToTable();
      })
    },
  },

  created() {
    // Define none-reactive data
    this._tableBodyScrollTop = 0;
    this._tbodyElementScrollLeft = 0;
    this.handleTableResizeMouseMoveDebounce = debounce(this.handleTableResizeMouseMove, 8);
  },

  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleWindowScroll);
    this.getTbodyElement().addEventListener('wheel', this.handleTableContentOnWheel);
    this.getTableBodyContainer().addEventListener('scroll', this.syncHeaderAndBody);
    this.headWidths = this.heads.map( head => head.width || 100 );
    this.initializeTable();
  },
  // Remember to clear all listeners
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleWindowScroll);
    this.getTbodyElement().removeEventListener('wheel', this.handleTableContentOnWheel);
    this.getTableBodyContainer().removeEventListener('scroll', this.syncHeaderAndBody);
    document.removeEventListener('mouseup', this.handleTableResizeMouseUp);
    document.removeEventListener('mousemove', this.handleTableResizeMouseMoveDebounce);
  },

  methods: {
    getTableHeaderContainer() {
      return this.$refs['table-header-container'];
    },
    getTbodyElement() {
      return this.$refs.tbody;
    },
    getTableBodyContainer() {
      return this.$refs['table-body-container'];
    },
    getRowIndex(row) {
      return this.rows.findIndex(item => item === row);
    },
    // Table might not be ready instantly, so we just have a poll to make sure we do first 'handleWindowResize' when it's ready
    initializeTable() {
      let count = 0;
      this.initializeTablePoll = setInterval(() => {
        count += 1;
        if (this.$el.offsetWidth || count > 20) {
          clearInterval(this.initializeTablePoll);
          this.handleWindowResize();
        }
      }, 50);

      this.calculateDistanceFromTopToTable();
    },
    // Updates headWidths so that the sum of each item is never less than the table width
    syncHeadWidthsToWindow() {
      if (!this.$refs['table-container']) return;
      const tableWidth = this.$refs['table-container'].clientWidth;
      if (!tableWidth)
        return;
      let combinedWidth = this.headWidths.reduce((sum, item) =>  sum + item, 0);

      if (this.showTicks) {
        combinedWidth += this.tickColumnWidth;
      }
      if (combinedWidth >= tableWidth) 
        return;
      
      const newWidths = [];

      this.headWidths.forEach(item => {
        const percentage = item / combinedWidth;
        const newWidth = percentage * tableWidth;
        newWidths.push(newWidth);
      });
      
      this.headWidths = newWidths;
    },
    syncHeaderAndBody() {
      const header = this.getTableHeaderContainer();
      const body = this.getTableBodyContainer();
      header.scrollLeft = body.scrollLeft;
      this.handleTableContentOnWheel();
    },
    checkRowActivity(row, rowIndex) {
      if (this.enableRowClick && this.activeRowIndex === rowIndex) {
        return true;
      }
      if (this.enableRowTick && this.tickedRowsMap[row[this.rowKeyName]]) {
        return true;
      }
      return false;
    },
    horizontalScrollHandler() {
      if (!this.getTbodyElement()) return;
      if (!this.getTableBodyContainer()) return;

      this.hasRightTableShadow =  this.getTbodyElement().offsetWidth > (this.getTableBodyContainer().scrollLeft + this.getTableBodyContainer().offsetWidth);
      this.hasLeftTableShadow = this.getTableBodyContainer().scrollLeft > 0;
    },
    handleWindowResize() {
      this.horizontalScrollHandler();
      this.syncHeadWidthsToWindow();
    },

    calculateDistanceFromTopToTable() {
      const rect = this.$refs['table-container'].getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      this.distanceFromTopToTable = rect.top + scrollTop;
    },

    handleWindowScroll() {
      this.windowScrollTop = window.scrollY;
    },
    handleTableContentOnWheel(e) {
      this._tbodyElementScrollLeft = this.getTableBodyContainer().scrollLeft;
      this.horizontalScrollHandler();
    },

    handleTickAllCheckboxClick() {
      this.$emit('tickAllRows', !this.tickAllRows);
    },

    handleSortClick(sortKey, event) {
      if (!sortKey) {
        return;
      }
      if (this.heads.find( item => item.sortKey === sortKey).lock) {
        return;
      }
      if (this.resizingColumn !== null) {
        return;
      }

      this.sortBy.key = sortKey;
      this.sortBy.order = this.sortBy.order === 'asc' ? 'desc' : 'asc';
      this.$emit('sortBy', this.sortBy);
    },

    handleRowTick(row) {
      const newValue = !this.tickedRowsMap[row[this.rowKeyName]];
      this.$emit('rowTick', row, newValue);
    },

    handleRowClick(row) {
      if (!this.enableRowClick) {
        return;
      }
      this.$emit('rowClick', row);
    },

    handleRowMouseEnter(rowIndex) {
      if (!this.enableRowClick) { return; }
      this.activeRowIndex = rowIndex;
    },

    handleRowMouseLeave() {
      this.activeRowIndex = null;
    },

    handleCellMouseEnter(head) {
      this.$emit('cellMouseEnter', head);
    },
    handleCellMouseLeave(head) {
      this.$emit('cellMouseLeave', head);
    },
    handleTableMouseLeave() {
      this.$emit('tableMouseLeave');
    },

    createResizeListeners() {
      document.addEventListener('mouseup', this.handleTableResizeMouseUp);
      document.addEventListener('mousemove', this.handleTableResizeMouseMoveDebounce);
    },
    handleTableResizeMouseUp(event) {
      event.stopPropagation();
      if (this.resizingColumn === null) return;

      const resizeEnd = event.x;
      const difference = resizeEnd - this.resizeStart;
      let newHeadWidth = this.headWidths[this.resizingColumn] + difference;
      if (newHeadWidth < 100) newHeadWidth = 100;

      // We never want the combined cell width to be less than the totalTableWidth, so let's find the minimum viable
      // width of the selected cell, assuming all other cells stay the same...
      const tableWidth = this.$refs['table-container'].clientWidth || 0;
      let combinedWidth = this.headWidths.reduce( (sum, item, idx) => {
        if (idx === this.resizingColumn) return sum + newHeadWidth;
        return sum + item
      }, 0);
      if (this.showTicks) combinedWidth += this.tickColumnWidth;
      const totalDifference = tableWidth - combinedWidth;
      if (totalDifference > 0) {
        newHeadWidth += totalDifference;
      }

      this.$set(this.headWidths, this.resizingColumn, newHeadWidth);
      this.$emit('columnResize', this.headWidths);

      document.removeEventListener('mouseup', this.handleTableResizeMouseUp);
      document.removeEventListener('mousemove', this.handleTableResizeMouseMoveDebounce);

      this.resizingXPosition = null;

      setTimeout( () => {
        this.resizingColumn = null;
      }, 50);

    },
    handleTableResizeMouseMove(event) {
      const tableWidth = this.$refs['table-container'].clientWidth || 0;

      const xPosition = event.x;
      const positionDifference = xPosition - this.resizeStart;
      let proposedHeadWidth = this.headWidths[this.resizingColumn] + positionDifference;
      if (proposedHeadWidth < 100) proposedHeadWidth = 100;

      let combinedWidth = this.headWidths.reduce( (sum, item, idx) => {
        if (idx === this.resizingColumn) return sum + proposedHeadWidth;
        return sum + item
      }, 0);

      if (this.showTicks) combinedWidth += this.tickColumnWidth;

      const totalDifference = tableWidth - combinedWidth;

      if (totalDifference <= 0 && this.headWidths[this.resizingColumn] + positionDifference >= 100) {
        this.resizingXPosition = (this.resizeStart - xPosition) - 4;
      } else {
        if (totalDifference < 0 && this.headWidths[this.resizingColumn] + positionDifference < 100) {
          const leftBoundPositionOfHeader = this.resizeStart - this.headWidths[this.resizingColumn];
          const proposedX = leftBoundPositionOfHeader + proposedHeadWidth;
          this.resizingXPosition = this.resizeStart - proposedX - 4;
        } else if (totalDifference > 0) {
          const minimumViable = proposedHeadWidth + totalDifference;
          const leftBoundPositionOfHeader = this.resizeStart - this.headWidths[this.resizingColumn];
          const proposedX = leftBoundPositionOfHeader + minimumViable;
          this.resizingXPosition = this.resizeStart - proposedX - 4;
        }
      }

    },
    handleTableResizeMouseDown(event, idx) {
      event.stopPropagation();
      this.resizingColumn = idx;
      this.resizeStart = event.x;
      this.createResizeListeners(idx);
    }
  },
};
</script>

<style lang="scss" scoped>
$table-radius: 5px;

.table-outer-wrapper {
  display: flex; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
  flex-flow: column; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
  position: relative;
  border-radius: $table-radius;
  border-left: 1px solid $skyBlueGrey500;
  border-right: 1px solid $skyBlueGrey500;
  border-bottom: 1px solid $skyBlueGrey500;
  z-index: $zIndexLow;
  background: white;

  .table-shadow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    z-index: $zIndexHighest;

    &.right {
      right: 0;
      border-top-right-radius: 5px;
    }
  }

  .no-content {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    z-index: $zIndexHigh;
  }


  .table-container {
    display: flex; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
    flex-flow: column; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
    -webkit-overflow-scrolling: touch;
    height: 100%;
    position: relative;

    .table-header-outer-container {
      min-height: 50px;
      z-index: $zIndexHigh;
      border-top: 1px solid $skyBlueGrey500;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      overflow: hidden;
    }

    .table-header-container {
      min-height: 51px;
      overflow: hidden;
      position: relative;

      .thead {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        user-select: none;
        overflow: hidden;

        .tr {
          display: flex;
          border-bottom: 1px solid $skyBlueGrey500;

          .th {
            position: relative;
            display: inline-flex;
            align-items: center;
            height: 50px;
            padding: 0 12px;
            min-width: 0;

            .arrow {
              height: 5px;
            }
            .lock {
              position: absolute;
              right: 4px;
            }
            .table-header-edge {
              height: 100%;
              width: 2px;
              padding-left: 3px;
              padding-right: 3px;
              // Put this back when we add in column resizing again
              // cursor: col-resize;
              position:absolute;
              right:-4px;
              z-index:$zIndexHigh;
            }
          }
          .th:first-child, .th:last-child {
            padding: 10px 17px;

            .lock {
              position: absolute;
              right: 9px;
            }
          }
        }
      }
    }

    .table-body-container {
      display: flex; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
      flex-flow: column; // Necessary, if we want to set height for ar-table meanwhile allowing table-body scrollable
      position: relative;
      border-spacing: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: auto;

      &.checkboxes-container {
        overflow: hidden;
        height: calc(100% - 50px);
        .tbody {
          border-left: none;
          border-bottom: none;
        }
      }

      .tbody {
        position: relative;
        display: block;
        height: 100%;

        &.locked {
          filter: blur(3px);

          .gradient-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%);
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }

        .tr {
          display: flex;
          background: white;
          border-bottom: 1px solid $skyBlueGrey500;

          &.deleted {
            background-color: #F6F9FC;
          }

          .td {
            display: inline-flex;
            min-width: 0;

            &.blur>div {
              filter: blur(3px);
              user-select: none;
            }
          }

          .td:first-child, .td:last-child {
            .cell {
              padding: 0px 17px !important;
            }
          }
        }

        .tr-wrapper:last-child {
          .tr {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.top-edge-straight {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.tr:hover {
  .tag {
    border: 1px solid white;
  }
}
</style>
