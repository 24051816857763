<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="axs-modal"
      header="Connect to AXS"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'axs-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Account Name"
          class="u-margin-top-5"
            >
            <ar-input
              v-model="accountName"
              name="accountName"
              placeholder="Please enter your AXS account name"
              id="axs-account-name-input"
              type="text"
              data-test-id="axs-account-name-input"
            />
        </ar-field>
        <div
          class="u-display-flex u-margin-bottom-5 u-margin-top-5"
        >
          <ar-text
           size="xs"
           text="Subscribe associated contacts to list (optional)"
           weight="bold"
           :style="{
             color: $arStyle.color.blueGrey800,
           }"
          />
          <ar-icon
           v-tooltip.top="{
             content: `Automatically subscribe all attendees of events from this AXS account.`,
           }"
           name="tooltip-question-mark"
           class="u-margin-left-2"
           height="15px"
           width="15px"
           :color="$arStyle.color.purple500"
          />
        </div>
        <ar-field
          :error-messages="[
            veeErrors.first('mailingListOid')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            v-model="mailingListOid"
            :channels="['email']"
          />
        </ar-field>
        <ar-field
          label="Marketing Opt-in Checkbox ID"
          class="u-margin-top-5"
            >
            <ar-input
              v-model="marketingOptInCheckboxIds"
              name="marketingOptInCheckboxIds"
              placeholder="Please enter the marketing opt-in checkbox ID"
              id="axs-marketing-opt-in-checkbox-id-input"
              type="text"
              data-test-id="axs-marketing-opt-in-checkbox-id-input"
            />
        </ar-field>
      </div>

      <div
        slot="footer"
        :class="[
          'axs-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
          id="axs-confirm-button"
          data-test-id="axs-confirm-button"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AxsModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      providerName: "",
      mailingListOid: null,
      accountName: "",
      marketingOptInCheckboxIds: null
    };
  },

  computed: {
    confirmButtonDisabled() {

      return !this.accountName;
    }
  },

  watch: {
    isShow(val) {
      if (val) {
        this.providerName = null;
        this.mailingListOid = null;
        this.accountName = null;
        this.marketingOptInCheckboxIds = null;
      }
    },
  },

  methods: {
    ...mapActions([
      'axsIntegration/CREATE_AXS_INTEGRATION',
      'axsIntegration/FETCH_INTEGRATION'
    ]),
    async fetchIntegration() {
      const success = await this['axsIntegration/FETCH_INTEGRATION']()
      return success;
    },
    async handleConfirmClick() {
      this.errorMessage = null;
      this.httpStatus = null;
      const providerName = this.providerName;
      const mailingListOid = this.mailingListOid;
      const accountName = this.accountName;
      const marketingOptInCheckboxIds = this.marketingOptInCheckboxIds;
      const response = await this['axsIntegration/CREATE_AXS_INTEGRATION']({
        providerName,
        mailingListOid,
        accountName,
        marketingOptInCheckboxIds
      });
      if (response) {
        if (this.$route.path != '/settings/integrations/axs') {
          await this.$router.push({ path: '/settings/integrations/axs' });
        }
        else {
          this.fetchIntegration();
        }
        this.$emit('cancel');
      }
      else {
        this.errorMessage = response.message;
        this.httpStatus = response.httpStatusCode;
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    toggleApplyPrefix() {
      this.applyPrefix = !this.applyPrefix;
    }
  }
};
</script>

<style lang="scss" scoped>
.axs-modal {
  .axs-modal-body {
    padding: 20px 30px 30px;
    &:nth-child(2) {
      border-top: 1px solid $blueGrey500;
    }
  }
  .axs-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
