<template>
  <div class="am2-email-editor">
    <RichTextEmailEditor
      v-if="type === 'rich-text'"
      ref="rich-text-email-editor"
      :value="richTextEmailHtml"
      :dynamic-tags="dynamicTags"
      @input="handleRichTextTemplateInput"
    />
    <UnlayerEmailEditor
      v-else-if="type === 'unlayer'"
      ref="unlayer-email-editor"
      :dynamic-tags="dynamicTags"
      :show-dynamic-tags-tool="showDynamicTagsTool"
      @outputDesign="handleTemplateUpdate"
      @outputHtml="handleHtmlUpdate"
      @addDynamicTagsToolClick="handleAddImportDynamicTagsToolClick"
      @addDynamicTagsToolNotFound="handleAddImportDynamicTagsToolNotFound"
    />
    <BeefreeEmailEditor
      v-else-if="type === 'beefree'"
      ref="beefree-email-editor"
      :dynamic-tags="dynamicTags"
      :show-dynamic-tags-tool="showDynamicTagsTool"
      @outputDesign="handleTemplateUpdate"
      @outputHtml="handleHtmlUpdate"
      @addDynamicTagsToolClick="handleAddImportDynamicTagsToolClick"
      @addDynamicTagsToolNotFound="handleAddImportDynamicTagsToolNotFound"
      @setBeefreeShowPreview="setBeefreeShowPreview"
      @setBeefreeShowStructure="setBeefreeShowStructure"
      @setBeefreeShowPreviewDynamicTags="setBeefreeShowPreviewDynamicTags"
      :location="location"
      @selectEventClick="selectEventClick"
      @selectDynamicTag="selectDynamicTag"
    />
  </div>
</template>

<script>
import UnlayerEmailEditor from '../unlayer-email-editor';
import RichTextEmailEditor from '../rich-text-email-editor';
import BeefreeEmailEditor from '../beefree-email-editor';

/**
 * This is the interface that encapsulates the logic for handling different types of emails,
 * so we don't have to worry about switch email types repeatedly.
 */
export default {
  name: 'NewEmailEditor',
  components: {
    RichTextEmailEditor,
    UnlayerEmailEditor,
    BeefreeEmailEditor,
  },
  props: {
    type: {
      type: String,
      validator: (val) => ['unlayer', 'rich-text', 'custom', 'beefree'].indexOf(val) > -1,
    },
    dynamicTags: {
      type: Array,
      default: () => [],
    },
    showDynamicTagsTool: {
      type: Boolean,
      default: true,
    },
    location: {
      type: String,
      default: 'message-center',
      validator: (val) => ['message-center', 'automation'].indexOf(val) > -1,
    }
  },
  data() {
    return {
      richTextEmailHtml: '',
    };
  },
  methods: {
    // Don't remove this, it's exported to parent components
    async setDesign(template) {
      await this.$nextTick();
      if (this.type === 'unlayer') {
        if (!this.$refs['unlayer-email-editor']) {
          return;
        }
        this.$refs['unlayer-email-editor'].setDesign(template);
      } else if (this.type === 'beefree') {
        if (!this.$refs['beefree-email-editor']) {
          return;
        }
        this.$refs['beefree-email-editor'].setDesign(template);
      } else if (this.type === 'rich-text') {
        this.richTextEmailHtml = template;
        this.handleRichTextTemplateInput(template);
      }
    },
    handleTemplateUpdate(template) {
      this.$emit('templateUpdate', template);
    },
    handleHtmlUpdate(emailHtml) {
      this.$emit('emailHtmlUpdate', emailHtml);
    },
    handleRichTextTemplateInput(template) {
      this.$emit('templateUpdate', template);
      this.$emit('emailHtmlUpdate', template);
    },
    handleAddImportDynamicTagsToolClick() {
      this.$emit('addDynamicTagsToolClick');
    },
    handleAddImportDynamicTagsToolNotFound() {
      this.$emit('addDynamicTagsToolNotFound');
    },
    preview() {
      this.$refs["beefree-email-editor"].preview()
    },
    toggleStructureOutlines() {
      this.$refs["beefree-email-editor"].toggleStructureOutlines()
    },
    previewDynamicTags() {
      this.$refs["beefree-email-editor"].previewDynamicTags()
    },
    setEventInBeefree(event) {
      this.$refs["beefree-email-editor"].setEventInBeefree(event)
    },
    handleDynamicTagsSelect(dynamicTags) {
      this.$refs["beefree-email-editor"].handleDynamicTagsSelect(dynamicTags)
    },
    setBeefreeShowPreview(showPreview) {
      this.$emit('setBeefreeShowPreview', showPreview);
    },
    setBeefreeShowPreviewDynamicTags(showPreviewDynamicTags) {
      this.$emit('setBeefreeShowPreviewDynamicTags', showPreviewDynamicTags);
    },
    setBeefreeShowStructure(showStructure) {
      this.$emit('setBeefreeShowStructure', showStructure);
    },
    selectEventClick() {
      this.$emit('selectEventClick')
    },
    selectDynamicTag() {
      this.$emit('selectDynamicTag')
    },
    handleSelectEventCancel() {
      this.$refs["beefree-email-editor"].handleSelectEventCancel()
    },
    handleDynamicTagsCancel() {
      this.$refs["beefree-email-editor"].handleDynamicTagsCancel()
    },
  },
};
</script>

<style lang='scss' scoped>
.am2-email-editor {
  width: 100%;
  height: 100%;
}
</style>
