<template>
  <div class="conversion-circle-chart">
    <div class="conversion-circle-chart__chart-wrapper">
      <div v-if="!isConversionEnabled" class="conversion-circle-chart__blur">
        <conversion-coming-soon class="conversion-circle-chart__blur-wrapper" />
      </div>

      <div class="conversion-circle-chart__top">
        <ar-text
          class="conversion-circle-chart__title"
          size="md"
          text="Conversions"
          :max-lines="1"
          line-height="24px"
          />


        <div @click="handleReportClick">
          <ar-text
            text="View report"
            class="conversion-circle-chart__link"
            size="xs"
            :style="{
              color: $arStyle.color.purple500,
            }"
          />
        </div>
      </div>

      <div class="conversion-circle-chart__chart-container">
        <div class="conversion-circle-chart__center-circle">
          <div v-if="isConversionEnabled && selectedEvents.length" class="conversion-circle-chart__center-circle-text">
            <span class="conversion-circle-chart__revenue-number">{{ revenueFormatted }}</span>
            <span class="conversion-circle-chart__revenue-text">Revenue</span>
          </div>

          <div v-else class="conversion-circle-chart__center-circle-button">
            <ar-simple-button
              class="conversion-circle-chart__center-circle-button"
              text="Link event"
              :style="{
                background: $arStyle.color.purple500,
                color: '#fff',
                height: '40px',
              }"
              @click="handleReportClick"
            />
          </div>
        </div>

        <div class="conversion-circle-chart__graph-hover"></div>

        <apexcharts
          class="conversion-circle-chart__chart"
          type="radialBar"
          ref="chart-conversion"
          :options="chartOptions"
          :series="[chartProgressPercent]"
        />
      </div>
      <div class="conversion-circle-chart__legend-wrapper">
        <div class="conversion-circle-chart__legend">
          <div class="conversion-circle-chart__legend-circle" :style="{background: chartMainColor}"></div>
          <div class="conversion-circle-chart__legend-text">Purchased ticket</div>
        </div>
        <div class="conversion-circle-chart__legend">
          <div class="conversion-circle-chart__legend-circle" :style="{background: chartLightColor}"></div>
          <div class="conversion-circle-chart__legend-text">Yet to purchase</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConversionComingSoon from "~/pages/message-center/messages/view/components/conversions-coming-soon.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

const activeColor = '#7344C0';
const activeColorLight = '#F4F0FA';

const defaultColor = '#EDF1F5';
const defaultColorLight = '#F6F9FC';

export default {
  name: 'ConversionCircleChart',
  components: { ConversionComingSoon },
  data() {
    return {
      chartProgressPercent: 0,
      chartMainColor: defaultColor,
      chartLightColor: defaultColorLight,
      chartOptions: {
        chart: {
          width: '100%',
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            track: {
              background: defaultColorLight,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
          }
        },
        fill: {
          type: "color",
          colors: [defaultColor],
        },
        responsive: [{
          breakpoint: undefined,
          options: {},
        }]
      },
      revenue: 100000,
    }
  },

  computed: {
    ...mapState({
      conversionStats: state => state.message.conversionStats,
      selectedEvents: state => state.message.conversionSelectedEvents,
      oid: state => state.route.params.oid,
      conversionTableCount: state => state.message.conversionTableCount,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isCalculationsFinished: state => state.message.isCalculationsFinished,
    }),

    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),

    isConversionEnabled() {
      return this.isFeatureEnabled(['conversions'])
    },

    revenueFormatted() {
      let revenue = this.conversionStats.reduce((acc, item) => {
        return acc + (item.indicators.revenue ? item.indicators.revenue : 0)
      }, 0) || 0
      revenue = revenue / 100
      return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
  watch: {
    isCalculationsFinished: function (val, oldVal) {
      if (val && !oldVal) {
        this['message/FETCH_CONVERSION_RECIPIENTS'](this.oid).then(() => {
          this.calculateProgressBar();
        });
      }
    },
  },
  methods: {
    ...mapActions([
      'message/FETCH_CONVERSION_STATISTICS',
      'message/FETCH_CONVERSION_RECIPIENTS',
    ]),

    ...mapMutations([
      'message/SET_CONVERSIONS_SELECTED_EVENTS',
    ]),

    updateChart() {
      this.chartOptions.fill.colors = [this.chartMainColor];
      this.chartOptions.plotOptions.radialBar.track.background = this.chartLightColor;

      if (this.$refs['chart-conversion']) this.$refs['chart-conversion'].updateOptions(this.chartOptions);
    },

    handleReportClick() {
      this.$router.push({
        path: 'conversions',
        query: { method: 'open-select-events' }
      });
    },

    calculateProgressBar() {
      const { statusDetails } = this.currentSelectedMessage
      let result = this.conversionTableCount / statusDetails.totalMessages * 100
      if (result > 100) result = 100
      if (result < 0) result = 0

      this.chartProgressPercent = result
    },
  },

  mounted() {
    if (this.isConversionEnabled) {
      this['message/FETCH_CONVERSION_STATISTICS']({ messageOid: this.oid, isSetFromSaved: true }).then(() => {
        if (this.selectedEvents.length) {
          this.chartMainColor = activeColor;
          this.chartLightColor = activeColorLight;
          this.updateChart();
          this.calculateProgressBar();
        }
      });

      this['message/FETCH_CONVERSION_RECIPIENTS'](this.oid).then(() => {
        this.calculateProgressBar();
      });
    } else {
      this.chartProgressPercent = 75;
    }
  },

  beforeDestroy() {
    this['message/SET_CONVERSIONS_SELECTED_EVENTS']([]);
  },
}
</script>

<style lang="scss">
.conversion-circle-chart {
  &__chart {
    svg {
      transform: scale(-1,1);
    }
  }

  &__chart-wrapper {
    position: relative;
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #DCDEE4;
    border-radius: 4px;
  }

  &__legend-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__legend {
    display: flex;
    align-items: center;
  }

  &__legend-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $blueGrey800;
    margin-right: 4px;
  }

  &__legend-text {
    font-size: 12px;
    color: $blueGrey800;
  }

  &__chart-container {
    width: 100%;
    position: relative;
  }

  &__center-circle {
    width: 100px;
    height: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 52;
  }

  &__revenue-number {
    font-size: 30px;
    color: $blueGrey800;
  }

  &__revenue-text {
    font-size: 16px;
    color: $blueGrey700;
    text-align: center;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding: 24px 24px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }

  &__center-circle-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__blur {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(4px);
    z-index: 55;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__blur-wrapper {
    margin: 0 16px;
  }

  &__graph-hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    z-index: 50;
  }
}
</style>
