<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >

    <ar-modal
      :is-open="isShow"
      header="Connect To Tixr"
      :has-cross-icon="!loading"
      class="tixr-modal"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >

      <div
        slot="body"
        :class="[
          'tixr-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Public Key"
        >
          <ar-input
            v-model="clientPartnerKey"
            name="clientPartnerKey"
            placeholder="Please enter your Tixr public key"
            :disabled="loading"
          />
        </ar-field>

        <ar-field
          label="Secret Key"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="privateKey"
            name="privateKey"
            type="password"
            placeholder="Please enter your Tixr secret key"
            :disabled="loading"
          />
        </ar-field>

        <ar-field
          label="Account Name"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            v-model="accountName"
            name="accountName"
            placeholder="Please enter your Tixr account name"
            :disabled="loading"
          />
        </ar-field>
      </div>

      <div
        slot="footer"
        :class="[
          loading ? 'tixr-waiting-modal-footer' : 'tixr-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <div v-if="errorMessage" :class="'error-container'">
          <span v-bind:class="{'error-message': errorMessage}">
            {{ '* ' + errorMessage }}
          </span>
          <div
            :class="'connection-guide'">
            <a
              target="_blank"
              href="https://audiencerepublic.freshdesk.com/support/solutions/articles/51000337460-how-to-connect-to-tixr">
              View our connection guide
            </a>
            <ar-icon
              name="external-link"
              width="24px"
              height="24px"
              style="vertical-align: text-bottom; padding-left: 5px;"
            />
          </div>
          
        </div>

        <am2-loading-bubble v-if="loading"/>
        <ar-simple-button
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          @click="handleConfirmClick"
          :style="{ width: '108px' }"
          id="tixr-modal-confirm-button"
          data-test-id="tixr-modal-confirm-button"
        />
      </div>

    </ar-modal>

  </portal>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'TixrModalNew',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      clientPartnerKey: null,
      privateKey: null,
      accountName: null,
      errorMessage: null,
    };
  },

  computed: {
    confirmButtonDisabled() {
      return !this.clientPartnerKey || !this.privateKey || !this.accountName;
    }
  },

  watch: {
    async isShow(val) {
      if (val) {
        this.clientPartnerKey = null;
        this.privateKey = null;
        this.accountName = null;
        this.errorMessage = null;
      }
    },
  },

  methods: {
    ...mapActions([
      'tixrIntegration/CREATE_TIXR_INTEGRATION',
      'tixrIntegration/FETCH_INTEGRATION'
    ]),
    async fetchIntegration() {
      const success = await this['tixrIntegration/FETCH_INTEGRATION']()
      return success;
    },

    async handleConfirmClick() {
      this.errorMessage = null;
      this.httpStatus = null;
      const clientPartnerKey = this.clientPartnerKey;
      const privateKey = this.privateKey;
      const accountName = this.accountName;
      const response = await this['tixrIntegration/CREATE_TIXR_INTEGRATION']({
        clientPartnerKey,
        privateKey,
        accountName
      });
      if (response) {
        if (this.$route.path != '/settings/integrations/tixr-new') {
          await this.$router.push({ path: '/settings/integrations/tixr-new' });;
        }
        else {
          this.fetchIntegration();
        }
        this.$emit('cancel');
      }
      else {
        this.errorMessage = response.message;
        this.httpStatus = response.httpStatusCode;
      }
    },

    handleCloseClick() {
      this.$emit('cancel');
    },
  }
};
</script>

<style lang="scss" scoped>
.tixr-modal {
  .tixr-modal-body {
    padding: 20px 30px 30px;
  }

  .tixr-modal-footer {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    height: 100px;
  }
}

.tixr-waiting-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 30px;
  height: 100px;
}

.error-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
}

.connection-guide {
  padding-top: 5px;
  text-decoration: underline;
  width: fit-content;
  color: #7344c0;
  cursor: pointer;
}

.error-border {
  border: 1px solid red;
  box-shadow: 0 0 0 3px #f7a1a7;
}

.error-message {
  color: red;
  font-weight: 12px;
}

</style>
