<template>
  <div class="gauge-container">
    <div 
      class="track" 
      :class="trackClass"
      :style="trackFillStyle"
    >
      <div
        v-if="value !== null"
        :style="barFillStyle({ value, color: fillColor })"
        class="fill"
      />
      <template v-else>
        <div
          class="fill"
          v-for="(fill, index) of fillValues"
          :key="index"
          :style="barFillStyle(fill)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { isNumber } from 'util';
export default {
  name: 'Gauge',
  props: {
    max: {
      type: Number,
      default: null
    },
    value: {
      type: Number,
      default: null,
    },
    barHeight: {
      type: Number,
      default: null,
    },
    borderRadius: {
      type: Number,
      default: null,
    },
    fillColor: {
      type: String,
      validator: (val) => {
        return /#([0-9a-f]{6}|[0-9a-f]{8})/i.test(val);
      },
      default: '#2dc26a'
    },
    fillValues: {
      type: Array,
      validator: (array) => {
        return array === null || array.every(val => /#([0-9a-f]{6}|[0-9a-f]{8})/i.test(val.color) && isNumber(val.value));
      },
      default: null
    },
    trackColor: {
      type: String,
      default: null,
    }
  },
  computed: {
    trackClass() {
      return (this.value > this.max) ? 'red' : 'grey';
    },
    greenBarFillStyle() {
      const percentage = (this.value > this.max) ? ((this.max/this.value) * 100) : ((this.value/this.max) * 100);
      return  {
        width : `${percentage}%`,
        'background-color': this.fillColor
      };
    },
    trackFillStyle() {
      return {
        ...(!!this.barHeight && {height: `${this.barHeight}px`}),
        ...(!!this.trackColor && { 'background-color': this.trackColor }),
      };
    }
  },
  methods: {
    barFillStyle(fillGroup) {
      const percentage = (fillGroup.value > this.max) ? ((this.max/fillGroup.value) * 100) : ((fillGroup.value/this.max) * 100);

      // floor percentage values to avoid rounding errors
      const style = {
        width : `${Math.floor(percentage)}%`,
        backgroundColor: fillGroup.color,
        ...(!!this.barHeight && {height: `${this.barHeight}px`}),
        ...(!!this.borderRadius && {'border-radius': this.borderRadius}),
      };

      return style;
    }
  },
  
};
</script>

<style lang="scss" scoped>
.gauge-container {
  .track {
    display: flex;
    flex-direction: row;
    &.red {
      background-color: $red500;
    }
    &.grey {
      background-color: $shadow;
    }
    &.white {
      background-color: #FFF;
    }
    height: 5px;
    border-radius: 5px;
  }
  .fill {
    height: 5px;
    background-color: $green500;
    border-radius: 5px;
  }
}
</style>
