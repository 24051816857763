var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('am2-ask-input-modal',{attrs:{"is-show":_vm.showCreateNewFilterModal,"max-width":'590px',"title":"Save segment","input-title":"Segment name","input-placeholder":"Enter name here...","data-test-id":"filter-save-segment-button"},on:{"confirm":_vm.createFilter,"close":function($event){_vm.showCreateNewFilterModal = false}}}),_vm._v(" "),_c('div',{staticClass:"filter-section-content"},[_c('div',{staticClass:"filter-section-title-section"},[(_vm.$arMediaQuery.window.maxWidth('sm'))?_c('am2-step-link',{staticClass:"filter-section-function-back-text",attrs:{"text":"Back"},on:{"click":_vm.handleExtendedSideBar}}):_vm._e(),_vm._v(" "),_c('am2-heading',{attrs:{"type":"h1","size":"sm","weight":"bold","title":"Filter"}}),_vm._v(" "),_c('ar-icon',{staticClass:"close-filter-icon",attrs:{"name":"cross-thick","height":"16px"},nativeOn:{"click":function($event){return _vm.handleCrossClick.apply(null, arguments)}}})],1),_vm._v(" "),_c('ar-divider',{staticClass:"u-margin-top-6 u-margin-bottom-7"}),_vm._v(" "),_c('div',{staticClass:"filter-section-filters-panel"},[_c('div',{staticClass:"filter-section-dropdown-button-wrapper"},[_c('am2-simple-button-dropdown',{staticClass:"dropdown-button",attrs:{"button-props":{
            iconName: 'circle-plus',
            iconProps: { height: '16px' },
            text: 'Add filter',
            outlined: true,
            style: { height: '40px', width: '100%' }
          },"items":_vm.segmentCriteriaItems,"dropdown-max-height":_vm.$arMediaQuery.window.maxWidth('xs') ? '50vh' : 'calc(100vh - 207px)',"dropdown-style":{
            width: _vm.$arMediaQuery.window.maxWidth('xs') ? 'calc(100vw - 30px)' : '330px',
          },"has-search":"","search-placeholder":"Search filter","data-test-id":"filter-sidebar-add-filter-button"},on:{"select":_vm.handleCriteriaSelect,"searchStringChange":_vm.handleSearchStringChange}})],1),_vm._v(" "),_c('div',{ref:"filters-wrapper",class:[
          'filter-section-filters-wrapper',
          _vm.$arMediaQuery.window.maxWidth('sm') && 'sm-max' ]},[(_vm.isFetchingFilter)?_c('am2-loading-section',{staticClass:"filter-section-loading"}):(!!_vm.scratchSegment && !!_vm.scratchSegment.filter && !!_vm.scratchSegment.filter.conditions && _vm.scratchSegment.filter.conditions.length > 0)?_c('div',{ref:"filters-content",staticClass:"filters-content"},_vm._l((_vm.scratchSegment.filter.conditions),function(condition,conditionIndex){return _c('div',{key:_vm.scratchSegmentConditionHashMap[conditionIndex],ref:"condition-renderer",refInFor:true},[_c('div',{on:{"click":function($event){return _vm.handleConditionClick(conditionIndex)}}},[_c('am2-filter-condition-renderer',{attrs:{"filter-criteria":_vm.segmentCriteriaMap[condition.name],"filter-condition":condition,"disable-review":_vm.disableReview,"hide-button":_vm.hideFilterApplyButton},on:{"apply":function ($event) { return _vm.applyCondition(conditionIndex, $event); },"delete":function($event){return _vm.deleteCondition(conditionIndex)},"resourcesUpdate":_vm.handleResourcesUpdate}})],1),_vm._v(" "),(_vm.scratchSegment.filter.logic[conditionIndex])?_c('div',{staticClass:"filter-section-logic-section",on:{"click":function($event){return _vm.updateLogic(conditionIndex)}}},[_c('div',{staticClass:"filter-section-logic-divider"}),_vm._v(" "),_c('div',{staticClass:"filter-section-logic-box"},[_vm._v("\n                "+_vm._s(_vm.scratchSegment.filter.logic[conditionIndex])+"\n              ")])]):_vm._e()])}),0):_c('ar-text',{staticClass:"filter-section-no-filter-paragraph",attrs:{"size":"xs","text":_vm.emptyFilterText,"allow-html":"","multiple-lines":"","align":"center"}})],1)]),_vm._v(" "),_c('div',{class:[
        'filter-section-functions-section',
        (_vm.scratchSegmentInfo && _vm.scratchSegmentInfo.source === 'message-segment') && 'message-segment'
      ]},[(_vm.hideCreateSegmentButton)?[(!_vm.isAudienceFeatureEnabled)?_c('am2-lock-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: !_vm.isAudienceFeatureEnabled ? 'Save segments so you can target specific segments within your audience.<br><br>Upgrade your plan to get access.' : null,
            classes: ['align-left'],
          }),expression:"{\n            content: !isAudienceFeatureEnabled ? 'Save segments so you can target specific segments within your audience.<br><br>Upgrade your plan to get access.' : null,\n            classes: ['align-left'],\n          }",modifiers:{"top":true}}],style:({ width: 'calc(50% - 30px)', height: '50px' }),attrs:{"text":"Save segment","type":"purple","outlined":"","data-test-id":"filter-disabled-save-button"},on:{"click":_vm.handleDisabledSaveClick}}):_vm._e()]:(_vm.showFilterDeleteButton)?_c('ar-simple-button',{style:({ width: 'calc(50% - 30px)', height: '50px' }),attrs:{"loading":_vm.isDeletingSegment,"text":"Delete segment","outlined":"","data-test-id":"filter-delete-button"},on:{"click":_vm.handleSavedFilterDelete}}):(_vm.showFilterSaveButton && !(_vm.scratchSegmentInfo && _vm.scratchSegmentInfo.source === 'message-segment'))?_c('ar-simple-button',{style:({ width: 'calc(50% - 30px)', height: '50px' }),attrs:{"loading":_vm.isPatchingSegment || _vm.isCreatingSegment,"text":"Save segment","outlined":"","data-test-id":"filter-save-button"},on:{"click":_vm.handleSaveClick}}):_vm._e(),_vm._v(" "),(!_vm.isAudienceFeatureEnabled || _vm.showFilterClearButton)?_c('ar-simple-button',{staticClass:"clear-button",style:({
          height: '50px',
          width: _vm.hideCreateSegmentButton ? '90%' : 'calc(50% - 30px)',
          marginLeft: _vm.hideCreateSegmentButton ? 'unset' : '20px'
        }),attrs:{"text":"Clear","outlined":"","data-test-id":"filter-clear-button"},on:{"click":_vm.handleClearFilterClick}}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }