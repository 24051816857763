<template>
  <am2-no-content-section
    v-if="!tableBody.length && !isFetchingOrders"
    icon-name="upload"
    header="Import POS orders"
    body="Import sales data from your POS provider"
    :icon-props="{
        width: '32px',
        height: '32px',
        color: $arStyle.color.blueGrey700,
       }"
    :style="{ marginTop: '20px' }"
    :button-text="'Import POS orders'"
    :button-props="{ iconName: '' }"
    @buttonClick="handleClickImportPosOrders"
  />
  <section
    v-else
    class="event-orders-container"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="10"
  >
    <div class="filter-wrapper">
      <am2-link-button-dropdown
        :items="dropdown"
        :item-key="activeDropdownFilter"
        :style="{
          marginTop: '8px',
        }"
        @select="handleDropdownItemSelect"
        :data-test-id="`pos-orders-button-dropdown`"
      />
    </div>

    <am2-table-control-section
      v-ar-sticky-top="{
        priority: 1,
      }"
      :style="{
        marginTop: '8px',
        backgroundColor: '#f6f9fc',
      }"
      :column-settings="{
        selectedColumnKeys: selectedColumnKeys,
        availableColumns: availableColumns,
        action: handleSelectedColumnKeysUpdate,
      }"
      :fans-search="{
        action: serverSideSearch,
      }"
      :loading="isFetchingOrders && !!orders && orders.length === 0"
      show-checked-rows-options-bar
      show-filter-and-search-bar
      :showFilterButton="false"
      fan-appellation="order"
      :data-test-id="`pos-orders-table-control-section`"
    />

    <am2-pos-orders-table
      :head="displayedTableHeadings"
      :body="tableBody"
      :loading="isFetchingOrders && !!orders && tableBody.length === 0"
      empty-text="No order data"
      @sortBy="handleSortChange"
      :enable-row-click="customerProfilesEnabled"
      @rowClick="handleRowClick"
      has-sticky-header
      tableType="orders"
      :data-test-id="`pos-orders-table`"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import checkLastCompletedTask from '@/mixins/checkLastCompletedTask'

const initialOrderBy = { key: 'orderDate', order: 'desc' };

const tableHeadings = [
  {
    name: 'Name',
    key: 'name',
    format: 'name',
    attributeKeys: 'firstName',
  },
  {
    name: 'Qty',
    key: 'sourceInfo',
    format: 'qty',
    sortKey: 'totalTickets',
    attributeKeys: 'sourceInfo',
  },
  {
    name: 'Order Total',
    key: 'cost',
    format: 'orderTotal',
    attributeKeys: 'cost',
  },
  {
    name: 'Order ID',
    key: 'puid',
    sortKey: 'puid', // puid is the orderId in pos data
    format: 'orderId', // format will be used in slot of PosOrderTable
    attributeKeys: 'puid',
  },
  {
    name: 'Source',
    key: 'source',
    sortKey: 'provider',
    format: 'source',
    attributeKeys: 'provider',
  },
  {
    name: 'Status',
    key: 'status',
    sortKey: 'status',
    format: 'status',
    attributeKeys: 'status',
  },
  {
    name: 'Order Date',
    key: 'orderTime',
    sortKey: 'orderDate',
    format: 'orderTime',
    attributeKeys: 'orderDate',
  },
];

export default {
  name: 'POSOrders',
  mixins: [checkLastCompletedTask],

  data() {
    return {
      tableHeadings,
      orderBy: initialOrderBy,
      searchString: '',
      availableColumns: [
        {
          name: 'Name',
          value: 'firstName',
          disabled: true,
        },
        {
          name: 'Qty',
          value: 'sourceInfo',
        },
        {
          name: 'Order Total',
          value: 'cost',
        },
        {
          name: 'Order ID',
          value: 'puid',
        },
        {
          name: 'Source',
          value: 'provider',
        },
        {
          name: 'Status',
          value: 'status',
        },
        {
          name: 'Order Date',
          value: 'orderDate',
        },
      ],
      selectedColumnKeys: [],
      filter: {
        logic: [],
        conditions: [
          {
            name: 'events',
            type: 'condition-search-picker',
            data: {
              condition: 'true_to_all',
              values: [this.$route.params.oid],
            },
          },
        ],
      },
      dropdown: [
        {
          name: 'All orders',
          count: 0,
          key: 'all',
        },
        {
          name: 'Completed',
          count: 0,
          key: 'completed',
        },
        {
          name: 'Paid',
          count: 0,
          key: 'paid',
        },
        {
          name: 'Pending',
          count: 0,
          key: 'pending',
        },
        {
          name: 'Refunded',
          count: 0,
          key: 'refunded',
        },
        {
          name: 'Partially Refunded',
          count: 0,
          key: 'partially-refunded',
        },
        {
          name: 'Transferred',
          count: 0,
          key: 'transferred',
        },
        {
          name: 'Deleted',
          count: 0,
          key: 'deleted',
        },
      ],

      activeDropdownFilter: 'all',
      fanAttributeKeys: [
        'fanPromoterAccount',
        'fanOid',
        'oid',
        'orderDate',
        'puid',
        'cost',
        'promoterOid',
        'provider',
        'sourceInfo',
        'status',
        'sysActivep',
        'sysCtime',
        'sysMtime',
      ],
    };
  },

  computed: {
    ...mapState({
      orders: (state) => state.posOrders.POSOrders,
      totalOrdersCount: (state) => state.posOrders.totalPOSOrdersCount,
      isFetchingOrders: (state) => state.posOrders.isFetchingPOSOrders,
      isNoMoreOrders: (state) => state.posOrders.isNoMorePOSOrders,
    }),

    ...mapGetters ({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),

    isOneSchemaEnabled() {
      return this.isFeatureEnabled(['oneschema']);
    },

    infiniteScrollDisabled() {
      return this.isFetchingOrders || this.isNoMoreOrders;
    },

    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },

    tableBody() {
      if (!this.orders) {
        return [];
      }
      return this.orders.map((attendee) => {
        attendee.avatar = attendee.profileImageUrl;
        attendee.class = attendee.status;
        if (attendee.fanPromoterAccount[0]) {
          attendee.fanPromoterAccount = attendee.fanPromoterAccount[0];
        }
        return attendee;
      });
    },

    displayedTableHeadings() {
      return this.tableHeadings.filter((heading) => {
        if (this.selectedColumnKeys && this.selectedColumnKeys.includes(heading.attributeKeys)) {
          return heading;
        }
      });
    },
  },

  created() {
    this.fetchPermissionsAndMaybeReroute();
    const orderTableSettings = window.localStorage.getItem('_ar_promoter_order_preferences_');
    this.$store.registerModule('event-view-child', {
      mutations: {
        FORCED_EVENT_UPDATE(state) {
          this.loadMoreData();
        },
      },
    });
    this.reloadData();

    this.selectedColumnKeys = this.availableColumns.reduce((keys, { value }) => {
      keys.push(value);
      return keys;
    }, []);
  },

  beforeDestroy() {
    this['posOrders/RESET_ORDERS']();
  },

  watch: {
    selectedColumnKeys() {
      this.updateFanAttributeKeys();
    },
  },

  methods: {
    ...mapActions([
      'posOrders/FETCH_MORE_ORDERS',
      'posOrders/EXPORT_ORDERS_CSV',
      'OPEN_IMPORT_PRODUCT_DATA_MODAL',
      'OPEN_ONESCHEMA_EVENT_IMPORT_MODAL',
      'auth/FETCH_PERMISSIONS',
    ]),
    ...mapMutations(['posOrders/RESET_ORDERS']),

    async handleSortChange(orderObj) {
      let key = orderObj.key;
      const order = orderObj.order;

      if (key === 'cost') {
        key = 'cost[displayValue]';
      }
      if (key === 'firstName') {
        key = 'fanPromoterAccount[firstName]';
      }

      this.orderBy = {
        key,
        order,
      };

      this.reloadData();
    },

    loadMoreData() {
      this['posOrders/FETCH_MORE_ORDERS']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeKeys,
        filter: this.filter,
        orderBy: this.orderBy,
        eventOid: this.$route.params.oid,
        dropdownFilter: this.activeDropdownFilter,
      });
    },

    async serverSideSearch(searchString) {
      this.searchString = searchString;
      this.reloadData();
    },

    reloadData() {
      this['posOrders/FETCH_MORE_ORDERS']({
        searchString: this.searchString,
        selectKeys: this.fanAttributeKeys,
        filter: this.filter,
        orderBy: this.orderBy,
        reload: true,
        eventOid: this.$route.params.oid,
        dropdownFilter: this.activeDropdownFilter,
      });
    },

    handleRowClick(target) {
      if (this.customerProfilesEnabled && target && target.fanOid) {
        this.$router.push({
          path: `/audience/${target.fanOid}/view/overview`,
        });
      }
    },

    handleSelectedColumnKeysUpdate(columnKeys) {
      this.selectedColumnKeys = columnKeys;

      this.reloadData();
    },

    handleDropdownItemSelect(item) {
      this.activeDropdownFilter = item.key;
      this.reloadData();
    },

    updateFanAttributeKeys() {
      let res = ['fanPromoterAccount'];
      for (let i = 0; i < this.tableHeadings.length; i += 1) {
        const heading = this.tableHeadings[i];
        if (this.selectedColumnKeys.includes(heading.attributeKeys) && heading.attributeKeys) {
          if (Array.isArray(heading.attributeKeys)) {
            res = res.concat(heading.attributeKeys);
          } else {
            res.push(heading.attributeKeys);
          }
        }
      }

      this.fanAttributeKeys = res;
    },

    handleClickImportPosOrders() {

      if (this.isOneSchemaEnabled) {
        this.OPEN_ONESCHEMA_EVENT_IMPORT_MODAL({
          typeOfImport: 'pos_orders_single_event',
          eventOid: this.$route.params.oid
        });
      } else {
        this.OPEN_IMPORT_EVENT_DATA_MODAL({
          eventOid: this.$route.params.oid,
        });
      }

    },

    actionAfterGetCompletedTask() {
      this.reloadData();
    },

    async fetchPermissionsAndMaybeReroute() {
      await this['auth/FETCH_PERMISSIONS']();
      if (!this.isPermissionEnabled(['view-sales'])) {
        this.$router.push({
          path: `/events/${this.$route.params.oid}/view/campaigns`,
        });
      }
    }
  },
};
</script>

<style lang="scss">
/* LEADERBOARD ------------------------ */
/*using scss from event order container*/
</style>
