<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Export message report .CSV"
      width="624px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      no-body-overflow
      @close="$emit('close')"
      class="export-messages-modal"
    >
      <div slot="body" class="export-messages-modal__body">
        <ar-field
          label="Select time range"
        >
          <ar-simple-select
            :items="timeRangeItems"
            placeholder="Select a time range"
            data-vv-name="time range"
            v-validate:timeRangeValue="'required'"
            :hasError="veeErrors.has('time range')"
            @select="handleTimeRangeSelect"
          />
          <ar-state-message
            v-show="veeErrors.has('time range')"
            type="error"
            :text="veeErrors.first('time range')"
            :style="{ marginTop: '8px' }"
          />
        </ar-field>

        <div v-if="timeRangeValue === 'custom_range'" class="u-display-flex u-align-items-center u-margin-top-6">
          <ar-field label="Select start date" class="u-width-100-percent">
            <am2-datepicker
              v-validate:customRangeStart="'required|startDate'"
              data-vv-name="start date"
              @input="handleInputStartDate" />
            <ar-state-message
              v-show="veeErrors.has('start date')"
              type="error"
              :text="veeErrors.first('start date')"
              :style="{ marginTop: '8px' }"
            />
            <div v-if="veeErrors.has('end date') && !veeErrors.has('start date')" :style="{height: '28px'}"></div>
          </ar-field>
          <ar-divider :style="{
            minWidth: '40px',
            margin: '25px 24px 0',
            marginTop: (veeErrors.has('end date') && !veeErrors.has('start date')) || (!veeErrors.has('end date') && veeErrors.has('start date')) ? '0px' : '24px',
           }" />
          <ar-field label="Select end date" class="u-width-100-percent">
            <am2-datepicker
              v-validate:customRangeEnd="'required'"
              data-vv-name="end date"
              @input="handleInputEndDate"/>
            <ar-state-message
              v-show="veeErrors.has('end date')"
              type="error"
              :text="veeErrors.first('end date')"
              :style="{ marginTop: '8px' }"
            />
            <div v-if="!veeErrors.has('end date') && veeErrors.has('start date')" :style="{height: '28px'}"></div>
          </ar-field>
        </div>

        <am2-card-container
          class="u-display-flex u-flex-flow-column u-padding-y-6 u-padding-x-6 u-margin-top-8"
        >
          <div class="u-display-flex u-align-items-center u-justify-content-space-between ">
            <div class="u-display-flex u-flex-flow-column">
              <ar-text
                size="xs"
                weight="bold"
                text="Select specific events"
              />
              <ar-text
                size="xs"
                weight="normal"
                :style="{
                  color: $arStyle.color.blueGrey700,
                  marginTop: '8px',
                }"
                text="Export report only for messages linked to one or more events"
              />
            </div>
            <am2-switch
              v-model="isSelectSpecificEvents"
            />
          </div>

          <div class="u-margin-top-6" v-if="isSelectSpecificEvents">
            <ar-field label="Select field">
              <am2-select-search-events
                v-validate:selectedEvents="'required'"
                data-vv-name="event"
                style-like-select
                :value="selectedEvents"
                :default-label="selectEventsPlaceholder"
                @changeSelectedOids="handleChangeEventOids"
              />
              <ar-state-message
                v-show="veeErrors.has('event')"
                type="error"
                :text="'Select an event or toggle off event selection'"
                :style="{ marginTop: '8px' }"
              />
            </ar-field>
          </div>
        </am2-card-container>

        <MessageTypeSelector
          class="u-margin-top-8 export-messages-modal__type-selector"
          :selected="currentMessageType"
          @onSelect="handleMessageTypeSelection"
        />
      </div>

      <div slot="footer" class="export-messages-modal__footer">
        <ar-link-button
          text="Cancel"
          class="u-margin-right-6"
          @click="$emit('close')" />
        <ar-simple-button text="Export" @click="handleClickExport" />
      </div>
    </ar-modal>
  </portal>
</template>
<script>
import MessageTypeSelector from '~/pages/message-center/messages/components/message-type-selector.vue'
import {mapActions} from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'ExportMessagesModal',
  components: {MessageTypeSelector},
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeRangeItems: [
          { name: 'Sent today', value: 'sent_today' },
          { name: 'Sent yesterday', value: 'sent_yesterday' },
          { name: 'This week', value: 'this_week' },
          { name: 'Last week', value: 'last_week' },
          { name: 'Sent this month', value: 'sent_this_month' },
          { name: 'Sent last month', value: 'sent_last_month' },
          { name: 'Custom range', value: 'custom_range' }
        ],
      timeRangeValue: '',
      customRangeStart: '',
      customRangeEnd: '',
      isSelectSpecificEvents: false,
      currentMessageType: 'all',
      selectedEvents: [],
      error: {
        eventsEmpty: false
      }
    }
  },
  computed: {
    selectEventsPlaceholder() {
      if (this.selectedEvents.length === 1) return '1 event selected'
      return this.selectedEvents.length ? `${this.selectedEvents.length} events selected` : 'Select one or more events'
    }
  },
  watch: {
    opened(val) {
      if (val) {
        this.timeRangeValue = '';
        this.customRangeStart = '';
        this.customRangeEnd = '';
        this.isSelectSpecificEvents = false;
        this.currentMessageType = 'all';
        this.selectedEvents = [];
      }
    }
  },
  created() {
    this.$validator.extend('startDate', {
      getMessage: field => `Start date cannot be after end date`,
      validate: value => {
        if (!this.customRangeStart || !this.customRangeEnd) {
          return true;
        }
        return dayjs(this.customRangeStart).isBefore(dayjs(this.customRangeEnd));
      },
    });
  },
  methods: {
    ...mapActions([
      'message/MESSAGES_EXPORT_CSV',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    async handleClickExport() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (!this.isSelectSpecificEvents) this.selectedEvents = [];
      const { dateStart, dateEnd } = this.calculateDateRange();

      await this['message/MESSAGES_EXPORT_CSV']({
        messageProvider: this.currentMessageType,
        dateStart,
        dateEnd,
        eventOids: this.selectedEvents
      })
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      this.$emit('close');
    },
    handleMessageTypeSelection(option) {
      this.currentMessageType = option;
    },
    handleTimeRangeSelect(option) {
      this.timeRangeValue = option.value;
    },
    handleInputStartDate(date) {
      this.customRangeStart = date;
    },
    handleInputEndDate(date) {
      this.customRangeEnd = date;
    },
    calculateDateRange() {
      if (this.timeRangeValue === 'custom_range') {
        return {
          dateStart: this.customRangeStart,
          dateEnd: this.customRangeEnd
        }
      }

      const now = new Date();
      let startDate = new Date(now);
      let endDate = null

      switch (this.timeRangeValue) {
        case 'sent_today':
          break;
        case 'sent_yesterday':
          startDate.setDate(now.getDate() - 1);
          break;
        case 'this_week':
          startDate.setDate(now.getDate() - now.getDay());
          break;
        case 'last_week':
          endDate = new Date(now);
          endDate.setDate(now.getDate() - now.getDay() + 1)
          startDate.setDate(endDate.getDate() - 7);
          break;
        case 'sent_this_month':
          startDate.setDate(1);
          break;
        case 'sent_last_month':
          startDate.setMonth(now.getMonth() - 1);
          startDate.setDate(1);

          endDate = new Date();
          endDate.setDate(1);
          break;
        default:
          return null;
      }

      return {
        dateStart: startDate.toISOString().split('T')[0],
        dateEnd: endDate ? endDate.toISOString().split('T')[0] : null
      }
    },

    handleChangeEventOids(eventOids) {
      this.selectedEvents = eventOids;
    },
  }
}
</script>

<style lang="scss">
.export-messages-modal {
  overflow-y: auto;

  &__body {
    padding: 48px 32px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 20px;
  }

  &__type-selector {
    width: auto;
    box-shadow: none !important;
  }

  // Dropdown should go out of modal

  .ar-modal-content {
    top: 30%;
    transform: translateX(-50%) translateY(-30%);
    max-height: unset !important;
  }

  .select-search-dropdown__option-wrapper {
    max-height: 220px !important;
  }

  .ar-modal-mask {
    position: fixed !important;
  }
}
</style>
