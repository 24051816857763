<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <am2-humanitix-modal
      :is-show="showHumanitixModal"
      :loading="isCreatingHumanitixIntegration"
      @confirm="handleHumanitixConfirm"
      @cancel="showHumanitixModal = false"
    />
    <am2-opt-in-settings-modal
      :is-show="showOptinSettingsModal"
      @cancel="showOptinSettingsModal = false"
      providerName="Humanitix"
      :message-list-oid="integrationMessageListOid"
    />

    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Humanitix"
          description="Automatically sync your members, events, orders from Humanitix"
          :icon-props="{
            name: 'humanitix',
            color: $arStyle.color.humanitix,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingHumanitixIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingHumanitixIntegration && humanitixIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Humanitix Settings',
              value: 'openSettingsModal'
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>
    <am2-tabs
      :items="[
        { title: 'Notifications' },
        { title: 'Status' }
      ]"
      :selected-tab-index="selectedTabIndex"
      class="tab-options"
      @select="(selected, idx) => selectedTabIndex = idx"
    />
    <div
      v-if="selectedTabIndex === 0"
    >
      <NotificationsContainer
        :integration="currentIntegration"
        :loading="isFetchingHumanitixIntegration"
      />
    </div>

    <div
      v-if="selectedTabIndex === 1"
      class="integration-status-container"
    >
      <section v-if="isAddingHumanitixIntegration || isFetchingHumanitixIntegration" class="integration-loading-container">
        <am2-loading-bubble />
      </section>
      <section v-else>
        <section v-if="integrationFailed" class="integration-failed-message">
          <ar-snackbar
            type="warning"
            message="Your Humanitix connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
            @anchorClick="handleReconnectIntegration"
            :style="{ width: '100%' }"
          />
        </section>
        <SyncBlockContainer
          v-else
          class="sync-block-container"
          :is-fetching-sync-task="isFetchingSyncTask"
          :sync-status="syncStatus"
          :sync-time="syncTime"
          :sync-copies="{
            'in-progress': 'Syncing data from Humanitix',
            'pending': 'The data sync is still pending',
            'completed': 'Your Humanitix account is currently connected',
            'cancelled': 'Your Humanitix account connection is cancelled',
            'failed': this.syncErrorMessage,
            'default': 'Syncing tasks not found',
          }"
          @startSync="handleStartSync"
          @stopSync="handleStopSync"
        />
      </section>
    </div>
  </section>
</template>
  
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SyncBlockContainer from '../components/sync-block-container';
import NotificationsContainer from '../components/notifications-container';

export default {
  name: 'HumanitixIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
    NotificationsContainer
  },

  data: () => ({
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    showHumanitixModal: false,
    selectedTabIndex: 1,
    showOptinSettingsModal: false
  }),

  computed: {
    ...mapState({
      humanitixIntegration: state => state.humanitixIntegration.integration,
      isFetchingHumanitixIntegration: state => state.humanitixIntegration.isFetchingIntegration,
      isAddingHumanitixIntegration: state => state.humanitixIntegration.isAddingIntegration,
      isCreatingHumanitixIntegration: state => state.humanitixIntegration.isCreatingIntegration,
      isDeletingHumanitixIntegration: state => state.humanitixIntegration.isDeletingIntegration,
      isFetchingSyncTask: state => state.humanitixIntegration.isFetchingSyncTask,
      syncTask: state => state.humanitixIntegration.syncTask,
      syncStatus: state => state.humanitixIntegration.syncStatus,
    }),

    humanitixIntegrationEnabled() {
      return process.env.arEnableHumanitixIntegration;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.humanitixIntegration) return null;
      const summary = this.humanitixIntegration
        .map(i => ({
          oid: i.oid,
          name: i.integration?.accountId,
          email: i.integration?.accountId
        }));
      return summary
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.humanitixIntegration || this.humanitixIntegration.length < 1 || !this.humanitixIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.humanitixIntegration[this.currentlySelectedIntegrationIndex];
    },

    integrationMessageListOid() {
      return this.currentIntegration ? this.currentIntegration?.meta?.messageListOid : null;
    },

    integrationFailed() {
      if (!this.currentIntegration) return false;
      return this.currentIntegration.status === 'failed';
    },
  },

  async mounted() {
    if (!this.humanitixIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['humanitixIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'humanitixIntegration/FETCH_INTEGRATION',
      'humanitixIntegration/DELETE_INTEGRATION',
      'humanitixIntegration/SYNC_START',
      'humanitixIntegration/SYNC_STOP',
      'humanitixIntegration/FETCH_SYNC_TASK',
      'humanitixIntegration/CREATE_HUMANITIX_INTEGRATION',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'humanitixIntegration/RESET_INTEGRATION',
    ]),
    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },
    async fetchIntegration() {
      const success = await this['humanitixIntegration/FETCH_INTEGRATION']()
      if ((!success || !this.humanitixIntegration || this.humanitixIntegration.length === 0) && this.$route.path != '/settings/integrations') {
        this.$router.push({
          path: '/settings/integrations',
        });
      }
    },

    async fetchTasks() {
      await this['humanitixIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    async handleHumanitixConfirm({ apiKey, accountName }) {
      const succeed = await this['humanitixIntegration/CREATE_HUMANITIX_INTEGRATION']({
        apiKey,
        accountName
      });
      if (succeed) {
        this.fetchIntegration();

        this.showHumanitixModal = false;
      }
    },


    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(integration, index) {
      this.currentlySelectedIntegrationIndex = index;


      this.fetchTasks();
    },

    async handleAddNewIntegration() {
      this.showHumanitixModal = true;
    },

    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['humanitixIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    handleStopSync() {
      this['humanitixIntegration/SYNC_STOP']({ oid: this.currentIntegration.oid });
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleReconnectIntegration() {
      const agreed = await this.SHOW_CONFIRM({
        messageHtml: `Make sure you are logging in with the Humanitix account <strong>${this.currentIntegration.accountId}</strong> on Humanitix before reconnecting it.`,
        confirmButtonText: 'Reconnect account',
      });
      if (!agreed) {
        return;
      }

      const succeed = await this['humanitixIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
      if (!succeed) {
        return;
      }

      this.handleAddNewIntegration();
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Humanitix integration will stop syncing your orders and customer data from Humanitix.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['humanitixIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
            if (this.humanitixIntegration.length > 0) {
              this.currentlySelectedIntegrationIndex = 0;
              await this.fetchIntegration();
              this.startFetchTasksPolling();
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'reconnectIntegration') {
        this.handleReconnectIntegration();
      } else if (item.value == 'openSettingsModal') {
        if (!this.currentIntegration) return;
        this.showOptinSettingsModal = true;
      }
    }
  }
};
</script>
  
<style lang="scss" scoped>
.wrapper {

  .tab-options {
    margin: 60px 0 20px 0;
    border-bottom: 1px $skyBlueGrey500 solid;
  }

  &.sm-max {
    padding: 0 24px;
  }

  &.xs-max {
    padding: 0 12px;
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;

      .tag {
        position: relative;
        margin-left: 10px;
        top: 4px;
      }
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top: 16px;
      }
    }
  }

  .sync-block-container {
    margin-top: 0px;
  }
}
</style>
  