<template>
  <section
    class="event-edit-page"
    ref="event-edit-page"
  >
    <SaveEventModal
      :is-save-event-modal-visible="isSaveEventModalVisible"
      :eventbrite-url="currentEventEventbriteUrl"
      @confirmSave="handleSaveConfirm"
      @close="isSaveEventModalVisible = false"
    />
    <am2-advanced-settings-modal
      title="Advanced Settings"
      :is-show="showAdvancedSettingsModal"
      @close="closeAdvancedSettingsClick"
      @input="handleAdvancedSettingsModalConfirm"
      :hasRSVPEvent="hasRSVPEvent"
    />
    <!-- Header -->
    <div class="event-edit-page-header">
      <div
        :class="[
          'event-edit-page-header-top',
          $arMediaQuery.window.maxWidth('sm') && 'sm-max',
        ]"
        :style="{
          backgroundColor: isNewEvent ? 'white' : $arStyle.color.purple500 ,
        }"
      >
        <ar-text
          v-if="isNewEvent"
          size="xs"
          weight="bold"
          text="Create new event"
        />
        <div v-else>
          <ar-text
            size="xs"
            weight="bold"
            text="Edit Event"
            :style="{
              color:  isNewEvent ? $arStyle.color.purple500 : 'white',
            }"
          />
          <ar-text
            size="xxs"
            class="u-margin-top-3"
            :text="scratchEvent.name"
            :style="{
              color:  isNewEvent ? $arStyle.color.purple500 : 'white',
            }"
          />
        </div>
        <div>

          <ar-link-button
            v-if="isEventRSVPEnabled"
            text="Advanced Settings"
            @click="handleAdvancedSettingsClick"
            :style="{
              color: isNewEvent ? $arStyle.color.purple500 : 'white',
              marginRight: '30px',
            }"
          />
          <ar-link-button
            text="Exit"
            @click="handleExitClick"
            v-ar-hotkey="{
              general: ['Escape'],
              mac: ['Escape'],
              action: handleExitClick,
            }"
            :style="{
              color: isNewEvent ? $arStyle.color.purple500 : 'white',
            }"
          />
        </div>
      </div>
      <div
        v-if="isEventRSVPEnabled && hasRSVPEvent"
        :class="[
          'event-edit-page-header-bottom',
          $arMediaQuery.window.maxWidth('sm') && 'sm-max',
        ]"
      >
        <am2-tabs
          :items="tabItems"
          @select="handleMenuSelect"
        />
      </div>
    </div>
    <div
      :class="[
        'body-section',
      ]"
    >
      <!-- Body -->
      <div
        :class="[
          'edit-section',
          isEditSectionFullSize && 'full-size',
          $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
        ]"
      >
        <div
          :class="[
            'edit-top-section',
            $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          ]"
          ref="content"
        >
          <div class="page-content">
            <EventDetails
              v-show="currentEditTab == 'details'"
              ref="details-section"
              :updateSelectedLocation="updateSelectedLocation"
              :selectedLocationKey="selectedLocationKey"
            />
            <div v-if="isEventRSVPEnabled">
              <EventRsvpBasic
                v-show="currentEditTab === 'rsvp'"
                ref="rsvp-section"
              />
              <EventRsvpRegister
                v-show="currentEditTab === 'registration'"
                ref="registration-section"
              />
              <template
                v-if="hasPlatformTab"
              >
                <EventRsvpPlatform
                  v-show="currentEditTab === 'platforms'"
                  ref="platforms-section"
                />
              </template>
            </div>
          </div>
        </div>
        <div
          :class="[
            'edit-navigation-section',
            $arMediaQuery.window.maxWidth('sm') && 'sm-max',
          ]"
        >
          <ar-simple-button
            text="Cancel"
            outlined
            @click="handleCancelClick"
            :style="{ width: $arMediaQuery.window.maxWidth('sm') ? '90px' : '120px' }"
            data-test-id="cancel-button"
          />
          <div
            class="right-section"
            :style="{
              maxWidth: '300px',
              marginLeft: $arMediaQuery.window.maxWidth('sm') ? '6px' : null,
            }"
          >
            <ar-simple-button
              v-if="isEventRSVPEnabled && (isNewEvent || currentEventHasNoRsvpEvent)"
              :text="$arMediaQuery.window.maxWidth('sm') ? `Create RSVP` : `Create RSVP Page`"
              outlined
              class="u-margin-right-2"
              :icon-name="$arMediaQuery.window.maxWidth('sm') ? null : 'mobile-preview'"
              icon-distance="10px"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              :loading="isCreatingEvent || isUpdatingEvent"
              @click="handleRSVPClick"
              :style="{ width: $arMediaQuery.window.maxWidth('sm') ? '120px' : '200px' }"
              data-test-id="create-rsvp-page-button"
            />

            <ar-simple-button
              v-if="displayPreviewButton"
              :text="rightPreviewIsToggledUp ? 'Edit' : 'Preview'"
              outlined
              class="u-margin-right-2"
              :icon-name="(rightPreviewIsToggledUp || $arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview'"
              icon-distance="10px"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              @click="handlePreviewClick"
              :style="{ width: '130px' }"
            />
            <ar-simple-button
              v-if="isNewEvent"
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleCreateEventClick,
              }"
              text="Create event"
              @click="handleCreateEventClick"
              :loading="isCreatingEvent"
              :style="{ width: $arMediaQuery.window.maxWidth('sm') ? '120px' : '170px' }"
              data-test-id="create-event-button"
            />
            <ar-simple-button
              v-else
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleSaveEventClick,
              }"
              text="Save event"
              @click="handleSaveEventClick"
              :loading="isUpdatingEvent"
              :style="{ width: '120px' }"
              data-test-id="save-event-button"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isEventRSVPEnabled && hasRSVPEvent"
        :class="[
          'right-preview-section',
          hasNoSpaceForRightPreview && 'go-on-top-of-edit-section',
          $arMediaQuery.window.maxWidth('sm') && 'small-window-size',
          displayMobilePreview && 'is-visible'
        ]"
      >
        <am2-campaign-preview
          ref="campaign-preview"
          :has-account="false"
          :has-fan="false"
          :has-footer="!hasNoSpaceForRightPreview"
          :preview-page="previewPage"
          size="fullHeight"
          :campaign="prunedScratchEventRSVPFormForPreview"
        />
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';

import EventRsvpBasic from '../event-rsvp/basic';
import EventRsvpRegister from '../event-rsvp/register';
import EventRsvpPlatform from '../event-rsvp/platform';
import SaveEventModal from './components/save-event-modal';
import EventDetails from './components/event-details';

export default {
  name: 'Event',

  layout: 'edit',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['events'], featureName: 'Events' }),
  ],

  components: {
    SaveEventModal,
    EventRsvpBasic,
    EventRsvpRegister,
    EventRsvpPlatform,
    EventDetails,
  },

  data () {
    return {
      isSaveEventModalVisible: false,
      isScratchEventUpdated: false,
      selectedLocationKey: 'physical',

      // Event RSVP Form-related
      isEventRSVPEnabled: process.env.arEnableEventRSVP,
      // RSVP tab
      currentEditTab: 'details',

      // Platforms tab
      rightPreviewIsToggledUp: false,
      windowHeight: window.innerHeight,
      showAdvancedSettingsModal: false,
    }
  },

  computed: {
    ...mapState({
      campaignType: state => state.route.query.campaignType,
      eventOid: state => state.route.params.oid,
      currentEvent: state => state.event.currentEvent,
      scratchEvent: state => state.event.scratchEvent,
      connectZoomProviderInfo: state => state.event.connectZoomProviderInfo,
      isUpdatingEvent: state => state.event.isUpdatingEvent,
      isCreatingEvent: state => state.event.isCreatingEvent,
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
      currentEventRSVPForm: state => state.eventRSVPForm.currentEventRSVPForm,
      currentMessageList: state => state.messageList.currentMessageList,
      currentRegionSettings: state => state.auth.regionSettings,
      scratchAdvancedSettings: state => state.event.scratchAdvancedSettings,
    }),
    ...mapGetters({
      currentEventEventbriteUrl: 'event/currentEventEventbriteUrl',
      currentEventHasNoRsvpEvent: 'event/currentEventHasNoRsvpEvent',
      scratchEventImageUrl: 'event/scratchEventImageUrl',
      scratchEventIsZoomEvent: 'event/scratchEventIsZoomEvent',
      scratchEventIsPhysicalEvent: 'event/scratchEventIsPhysicalEvent',
      defaultEventStartTime: 'event/defaultEventStartTime',
      prunedScratchEventRSVPFormForServer: 'eventRSVPForm/prunedScratchEventRSVPFormForServer',
      prunedScratchEventRSVPFormForPreview: 'eventRSVPForm/prunedScratchEventRSVPFormForPreview',
      historicalCurrentPagePath: 'application/historicalCurrentPagePath',
    }),
    sanitizedReferringPage() {
      let path = this.historicalCurrentPagePath(path);

      if (!!path && this.$route?.query?.from === 'audience') {
        const pathItems = path.split('/')
        const lastPage = pathItems[pathItems.length - 1]

        if (lastPage === 'sales') {
          return pathItems.slice(0, -1).join('/') + '/audience'
        }
      }

      return path
    },
    isNewEvent() {
      return this.scratchEvent == null || (this.scratchEvent != null && !this.scratchEvent.oid);
    },
    hasRSVPEvent() {
      return !!this.currentEventRSVPForm;
    },
    hasPlatformTab() {
      return !this.scratchEventIsZoomEvent
        && !this.scratchEventIsPhysicalEvent
        && this.selectedLocationKey !== 'physical';
    },
    previewPage() {
      switch (this.currentEditTab) {
        case 'details':
          return '/';
        case 'rsvp':
          return '/';
        case 'registration':
          return '/register';
        case 'platforms':
          return '/';
        default:
          return '/';
      }
    },

    hasNoSpaceForRightPreview() {
      return this.windowHeight < 600 || this.$arMediaQuery.window.maxWidth('md');
    },

    displayMobilePreview() {
      return this.hasNoSpaceForRightPreview && this.rightPreviewIsToggledUp;
    },

    displayPreviewButton() {
      return this.hasRSVPEvent && this.hasNoSpaceForRightPreview;
    },

    isEditSectionFullSize() {
      return this.hasNoSpaceForRightPreview || !this.isEventRSVPEnabled || !this.hasRSVPEvent;
    },
    tabItems() {
      const items = [
        {
          value: 'details',
          title: 'Details',
        },
        {
          value: 'rsvp',
          title: 'RSVP Page',
        },
        {
          value: 'registration',
          title: 'Registration',
        },
      ];

      if (this.hasPlatformTab) {
        items.push({
          value: 'platforms',
          title: 'Platforms',
        });
      }
      return items;
    },
  },

  watch: {
    scratchEvent: {
      handler() {
        this.isScratchEventUpdated = true;
        this['eventRSVPForm/INJECT_SCRATCH_EVENT_IN_SCRATCH_EVENT_RSVP_FORM'](this.scratchEvent);
      },
      immediate: true,
    },
    currentRegionSettings: {
      handler(val) {
        if (!this.scratchEvent?.timeZone) {
          this['event/PATCH_SCRATCH_EVENT']({ timeZone: val?.additionalInfo?.defaultTimezone });
        }
      },
      immediate: true,
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'event/CREATE_EVENT',
      'event/UPDATE_EVENT',
      'event/FETCH_CURRENT_EVENT',
      'eventRSVPForm/FETCH_CURRENT_EVENT_RSVP_FORM',
      'eventRSVPForm/PATCH_EVENT_RSVP_FORM',
      'messageList/FETCH_CURRENT_MESSAGE_LIST',
      'auth/FETCH_REGION_SETTINGS',
      'event/REFRESH_EVENT_AUDIENCE_STATS',
      'tag/FETCH_MORE_TAGS',
      'FETCH_EVENTS',
      'MASS_EDIT_FAN',
    ]),

    ...mapMutations([
      'event/RESET_CURRENT_EVENT',
      'event/SET_SCRATCH_EVENT',
      'event/RESET_SCRATCH_EVENT',
      'event/INJECT_CONNECT_ZOOM_PROVIDER_INFO_TO_SCRATCH_EVENT',
      'event/PATCH_SCRATCH_EVENT',
      'event/RESET_CONNECT_ZOOM_PROVIDER_INFO',
      'event/SET_MESSAGE_LIST_IN_EVENT',
      'eventRSVPForm/SET_SCRATCH_EVENT_RSVP_FORM',
      'eventRSVPForm/INJECT_SCRATCH_EVENT_IN_SCRATCH_EVENT_RSVP_FORM',
      'eventRSVPForm/RESET_CURRENT_EVENT_RSVP_FORM',
      'eventRSVPForm/RESET_SCRATCH_EVENT_RSVP_FORM',
    ]),

    initializeValidator() {
      this.$validator.extend('startDate', {
        getMessage: field => `Start date cannot be after end date`,
        validate: value => {
          if (!this.scratchEvent.endDate || this.scratchEvent.endDate === 'Invalid date') {
            return true;
          }
          return moment(value).isBefore(moment(this.scratchEvent.endDate));
        },
      });
      this.$validator.extend('endDate', {
        getMessage: field => `End date cannot be before start date`,
        validate: value => {
          if (!this.scratchEvent.startDate || this.scratchEvent.startDate === 'Invalid date') {
            return true;
          }
          return moment(value).isAfter(moment(this.scratchEvent.startDate));
        },
      });
      this.$validator.extend('isPositiveInt', {
        getMessage: field => `Event capacity must be a number greater than 0`,
        validate: value => {
          return !isNaN(value) && parseInt(value) >= 1;
        },
      });
    },

    closeAdvancedSettingsClick() {
      this.showAdvancedSettingsModal = false;
    },

    handleAdvancedSettingsModalConfirm(item) {
      this.showAdvancedSettingsModal = false;
    },

    handleAdvancedSettingsClick() {
      this.showAdvancedSettingsModal = true;
    },

    // Add tags for all fans who already purchased a ticket
    applyTagForExistingFans() {
      const savedTags = this.currentEvent?.presentation?.settings?.assignTags || []
      const newTags = this.scratchEvent?.presentation?.settings?.assignTags
      if (newTags && newTags.length !== 0) {
        const tagsToAdd = newTags.filter(item => !savedTags.includes(item))

        if (tagsToAdd.length === 0 || !this.currentEvent.oid) return

        this.MASS_EDIT_FAN({
          filter: {
            "conditions": [
              {
                "data": {
                  "values": [
                    this.currentEvent.oid
                  ],
                  "condition": "true_to_all"
                },
                "name": "events",
                "type": "condition-search-picker"
              }
            ],
            "logic": []
          },
          tags: {add: tagsToAdd},
        })
      }
    },

    // Add to list for all fans who already purchased a ticket
    applySubscribeListForExistingFans() {
      const oldOid = this.currentEvent?.presentation?.settings?.subscribeToMessageList?.messageListOid
      if (this.scratchAdvancedSettings?.subscribeToMessageList
        && this.scratchAdvancedSettings.subscribeToMessageList.messageListOid !== oldOid && this.currentEvent.oid) {
        this.MASS_EDIT_FAN({
          filter: {
            "conditions": [
              {
                "data": {
                  "values": [
                    this.currentEvent.oid
                  ],
                  "condition": "true_to_all"
                },
                "name": "events",
                "type": "condition-search-picker"
              }
            ],
            "logic": []
          },
          "messageList": {
            "oid": this.scratchAdvancedSettings.subscribeToMessageList.messageListOid,
            "subscriptions": {
              "sms": {
                "enable": this.scratchAdvancedSettings.subscribeToMessageList.sms,
                "enableAll": true
              },
              "email": {
                "enable": this.scratchAdvancedSettings.subscribeToMessageList.email,
                "enableAll": true
              }
            }
          },
        })
      }
    },


    async updateEvent({ redirect = true }) {
      let succeed;

      // Also need to connect Event with Zoom ?
      if (this.connectZoomProviderInfo) {
        this['event/INJECT_CONNECT_ZOOM_PROVIDER_INFO_TO_SCRATCH_EVENT'](this.connectZoomProviderInfo);
      }
      succeed = await this['event/UPDATE_EVENT'](this.scratchEvent);
      if (!succeed) { return; }
      this.applyTagForExistingFans()
      this.applySubscribeListForExistingFans()


      this.isScratchEventUpdated = false;
      if (redirect) {
        let eventPath = 'campaigns'
        if (this.$route?.query?.from === 'audience') {
          eventPath = 'audience'
        }
        this.$router.push({ path: `/events/${this.scratchEvent.oid}/view/${eventPath}` });
      }
    },

    async updateEventRSVP() {
      await this['eventRSVPForm/INJECT_SCRATCH_EVENT_IN_SCRATCH_EVENT_RSVP_FORM'](this.scratchEvent);
      const succeed = await this['eventRSVPForm/PATCH_EVENT_RSVP_FORM'](this.prunedScratchEventRSVPFormForServer);

      if (succeed) {
        // TO DO, SUBMIT FORM
        this.$router.push({ path: `/events/${this.scratchEvent.oid}/view/attendees` });
      }
    },

    getTabErrorsInfo(validDetails, validRSVP, validRegistration, validPlatforms) {
      let tabsWithErrors = []

      if (!validDetails) {
        tabsWithErrors.push("Details");
      }

      if (!validRSVP) {
        tabsWithErrors.push("RSVP Page");
      }
      if (!validRegistration) {
        tabsWithErrors.push("Registration");
      }
      if (!validPlatforms) {
        tabsWithErrors.push("Platforms");
      }

      if (tabsWithErrors.length >= 2) {
        tabsWithErrors[1] = "and " + tabsWithErrors[1]
      }

      let message = ""

      if (tabsWithErrors.length > 0) {
        message = ` in the ${tabsWithErrors.join(tabsWithErrors.length > 3 ? ", " : " ")} ${tabsWithErrors.length == 1 ? "tab" : "tabs"}`
      }

      return {
        message: message,
        hasErrors: tabsWithErrors.length > 0
      }
    },

    async validateTab (sectionName) {
      if (!this.$refs[`${sectionName}-section`]) {
        // If no section found, that means we don't need to validate it
        return true;
      }

      return await this.$refs[`${sectionName}-section`].$validator.validateAll();
    },

    async validateFields() {
      if (this.hasRSVPEvent) {
        const validDetails = await this.validateTab("details")
        const validRSVP = await this.validateTab("rsvp")
        const validRegistration = await this.validateTab("registration")
        const validPlatforms = await this.validateTab("platforms")

        const tabErrorInfo = this.getTabErrorsInfo(validDetails, validRSVP, validRegistration, validPlatforms)

        if (tabErrorInfo.hasErrors) {
          this.$arNotification.push({
            type: 'error',
            message: `There were some errors in saving your details${tabErrorInfo.message}. Please review the messages.`,
          });
        }

        return !tabErrorInfo.hasErrors
      } else {
        const validDetails = await this.validateTab("details");
        if (!validDetails) {
          this.$arNotification.push({
            type: 'error',
            message: `There were some errors in saving your details. Please review the messages.`,
          });
        }
        return validDetails;
      }
    },

    handleCancelClick() {
      this.$router.push('/events');
    },

    handleExitClick() {
      this.$router.push({
        path: this.sanitizedReferringPage,
      });
    },

    async handleSaveConfirm(newShowSetting) {
      window.localStorage.setItem('_dont_show_event_warning_again', newShowSetting);
      this.isSaveEventModalVisible = false;

      this.updateEvent({});
    },

    async handleRSVPClick() {
      const passValidation = await this.validateFields();
      if (!passValidation) {
        return;
      }

      // Event image is necessary for creating RSVP Event
      if (!this.scratchEventImageUrl) {
        this.SHOW_CONFIRM({
          messageHtml: 'Event image is required for creating RSVP Page.',
        });
        return;
      }

      if (this.isNewEvent) {
        const newEvent = await this['event/CREATE_EVENT'](this.scratchEvent);
        if (!newEvent) {
          return;
        }
        this.isScratchEventUpdated = false;

        if (newEvent.oid) {
          this.$router.push({
            path: `/events/${newEvent.oid}/edit/event-rsvp/basic`,
          });
        }
      } else {
        this.updateEvent({ redirect: false });
        this.isScratchEventUpdated = false;

        this.$router.push({
          path: `/events/${this.scratchEvent.oid}/edit/event-rsvp/basic`,
        });

      }
    },

    async handleCreateEventClick() {
      const passValidation = await this.validateFields();
      if (!passValidation) {
        return;
      }

      const newEvent = await this['event/CREATE_EVENT'](this.scratchEvent);
      if (!newEvent) {
        return;
      }
      this.isScratchEventUpdated = false;

      if (this.campaignType) {
        this.$router.push({
          path: `/campaigns/new/details`,
          query: {
            eventOid: newEvent.oid,
            type: this.campaignType,
          },
        });

        this.hasBeenUpdated = false;

      } else {
        this.$router.push({
          path: `/events/${newEvent.oid}/view/sales`,
        });
      }
    },
    async handleSaveEventClick() {
      const passValidation = await this.validateFields();
      if (!passValidation) {
        return;
      }

      const doNotShowWarningModal = window.localStorage.getItem('_dont_show_event_warning_again') === 'true';
      if (!doNotShowWarningModal && this.currentEventEventbriteUrl) {
        this.isSaveEventModalVisible = true;
        return;
      }

      if (this.currentEventRSVPForm) {
        // If you have current RSVP event, let's also update it
        await this.updateEvent({ redirect: false });
        this.updateEventRSVP();
      } else {
        this.updateEvent({});
        this['event/REFRESH_EVENT_AUDIENCE_STATS']({eventOid: this.scratchEvent.oid})
      }
    },

    handleMenuSelect(item) {
      if (this.currentEditTab == 'details') {
        this.$nextTick(() => {
          if (this.$refs['campaign-preview']) {
            this.$refs['campaign-preview'].setupInitialPreviewData();
          }

        });
      }
      this.currentEditTab = item.value
    },

    handlePreviewClick() {
      var newData = !this.rightPreviewIsToggledUp
      this.rightPreviewIsToggledUp = newData;
    },

    updateSelectedLocation(value) {
      this.selectedLocationKey = value;
    },


    handleWindowResize() {
      this.windowHeight = window.innerHeight;
    },

    resetEditingData() {
      this['event/RESET_SCRATCH_EVENT']();
      this['event/RESET_CURRENT_EVENT']();
      this['event/RESET_CONNECT_ZOOM_PROVIDER_INFO']();
      this['eventRSVPForm/RESET_CURRENT_EVENT_RSVP_FORM']();
      this['eventRSVPForm/RESET_SCRATCH_EVENT_RSVP_FORM']();
    },
  },

  async created() {
    this.resetEditingData();
    this.initializeValidator();

    this['tag/FETCH_MORE_TAGS']({ searchString: '', reload: true });

    if (this.eventOid) {
      // Old Event
      const succeed = await this['event/FETCH_CURRENT_EVENT'](this.eventOid);
      if (!succeed) {
        return;
      }
      await this['auth/FETCH_REGION_SETTINGS']();
      this['event/SET_SCRATCH_EVENT'](this.currentEvent);


      await this['eventRSVPForm/FETCH_CURRENT_EVENT_RSVP_FORM'](this.eventOid);

      if (this.currentEventRSVPForm) {
        this['eventRSVPForm/SET_SCRATCH_EVENT_RSVP_FORM'](this.currentEventRSVPForm);
      }

      if (this.scratchEventIsPhysicalEvent) {
        this.selectedLocationKey = 'physical';
        this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl: null } });
      } else {
        this.selectedLocationKey = 'online';
        this['event/PATCH_SCRATCH_EVENT']({ location: null, capacity: null });
      }
      // Let's setup message list for our event,for editting
      if (this.scratchEvent.presentation.settings?.subscribeToMessageList?.messageListOid) {
        await this['messageList/FETCH_CURRENT_MESSAGE_LIST']({
          oid: this.scratchEvent.presentation.settings?.subscribeToMessageList?.messageListOid,
        });
        this['event/SET_MESSAGE_LIST_IN_EVENT'](this.currentMessageList);
      }
    } else {
      this['event/PATCH_SCRATCH_EVENT']({
        startDate: this.defaultEventStartTime,
      });
    }

    this.$nextTick(() => {
      this.isScratchEventUpdated = false;
      if (this.$refs['campaign-preview']) {
        this.$refs['campaign-preview'].setupInitialPreviewData();
      }
    });

    window.addEventListener('resize', this.handleWindowResize);
  },

  async beforeRouteLeave (to, from, next) {
    if (this.isScratchEventUpdated) {
      const response = await this.SHOW_CONFIRM({
        messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.',
      })
      if (response) {
        next();
      } else {
        next(false)
      }
    } else {
      next();
    }
  },

  beforeDestroy() {
    this.resetEditingData();

    window.removeEventListener('resize', this.handleWindowResize);
  },
};
</script>

<style lang="scss" scoped>
.event-edit-page {
  background: white;
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .event-edit-page-header {
    width: 100%;
    z-index: $zIndexRegular;
    .event-edit-page-header-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 90px;
      background: white;
      border-bottom: 1px solid $skyBlueGrey500;
      padding: 0 40px;

      &.sm-max {
        height: 70px;
        padding: 0 12px;
      }
    }
    .event-edit-page-header-bottom {
      border-bottom: 1px solid $skyBlueGrey500;
      background: white;
      width: 100%;
      z-index: $zIndexHighest;
      padding: 24px 40px 0px;
      &.sm-max {
        padding: 10px 12px 0px;
      }
    }
  }

  .body-section {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: $skyBlueGrey300;

    > .edit-section {
      position: relative;
      z-index: $zIndexRegular;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 700px;
      max-width: 858px;
      background: white;
      overflow: hidden;

      &.full-size {
        min-width: unset;
        max-width: unset;
      }

      > .edit-top-section {
        flex: 1;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 0 80px;

        .page-content {
          max-width: 760px;
          margin: 50px auto;
        }

        &.small-window-size {
          padding: 0 12px;

          .page-content {
            margin: 50px auto 80px;
          }
        }
      }

      .edit-navigation-section {
        flex-shrink: 0;
        height: 110px;
        border-top: 1px solid $skyBlueGrey500;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 40px;
        z-index: $zIndexHighest;

        .right-section {
          display: flex;
          flex-direction: row;
          position: relative;
          justify-content: space-between;
          max-width: 300px;
        }

        &.sm-max {
          height: 90px;
          padding: 0 12px;
          display: flex;
        }

        &.small-window-size {
          padding: 0 12px;
          height: 75px;
        }
      }
    }
    .right-preview-section {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 45px;

      &.go-on-top-of-edit-section {
        justify-content: center;
        position: fixed;
        padding: 12;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 110px);
        background-color:#FFF;
        z-index: $zIndexLow;
        &.small-window-size {
          height: calc(100% - 75px);
        }
        &.is-visible {
          z-index: $zIndexHigh;
        }
      }
    }
  }

}
</style>
