<template>
  <div class="screen-content">
    <img
      src="~/assets/images/message-center/sms-preview-header.png"
      width="100%"
      height="auto"
    >
    <div class="sender-name">
      {{ sender }}
    </div>
    <section v-if="messageText" class="message-area">
      <img
        v-if="!!messageImage"
        :src="messageImage"
        width="100%"
        height="auto"
        class="mms-image"
      >
      <SenderBubble
        :text="messageText"
        @anchorClick="handlePreviewAnchorClick"
      />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { urlsRegex } from '@/utils/regex'
import { AR_SHORT_URL_PATTERN, AR_DEV1_SHORT_URL_PATTERN, AR_PROD_SHORT_URL_PATTERN, AR_STAGING_SHORT_URL_PATTERN } from '@/utils/constants';
import { generateRandomString } from '@/utils/helpers';
import { injectPreviewContact, beautifyDynamicTagsSyntax } from '../utils';
import SenderBubble from '../SenderBubble';

export default {
  name: 'MessagePreview',

  components: {
    SenderBubble,
  },

  props: {
    sender: {
      type: String,
      default: null,
    },
    messageBody: {
      type: String,
      default: null,
    },
    messageImage: {
      type: String,
      default: null,
    },
    originalText: {
      type: String,
      default: null,
    },
    previewContact: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      storedDummyUrls: [],
      optOutLinkDummyUrl: null,
    }
  },

  watch: {
    messageBody: {
      handler(val) {
        const urls = this.originalText.match(urlsRegex) || [];
        if (urls.length > this.storedDummyUrls.length) {
          // Generate a few more URLs for good measure
          for(let x; x < 5; x++) {
            this.storedDummyUrls.push(`${process.env.arShortUrlDomain}/${generateRandomString(5)}`);
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    messageText() {
      if (!this.messageBody) {
        return null;
      }
      const urifiedMessage = this.urifyMessage(this.messageBody);
      if (this.previewContact) {
        return injectPreviewContact(urifiedMessage, this.previewContact);
      } else {
        return beautifyDynamicTagsSyntax(urifiedMessage);
      }
    },
  },

  created() {
    for(let x = 0; x < 20; x++) {
      this.storedDummyUrls.push(`${process.env.arShortUrlDomain}/${generateRandomString(5)}`);
    }
    this.optOutLinkDummyUrl = `${process.env.arShortUrlDomain}/${generateRandomString(5)}`;
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),
    // TODO - Modulize this function, so we don't duplicate this on "sms" and "facebook-messenger" preview
    urifyMessage(message) {
      const originalUrls = this.originalText.match(urlsRegex) || [];
      let index = -1;
      let storedDummyUrls = this.storedDummyUrls;
      let optOutLinkDummyUrl = this.optOutLinkDummyUrl;

      return message.replace(/\n/g, '<br>').replace(urlsRegex, function(url) {
        index++;
        let matchingDummyUrl = storedDummyUrls[index] || `${process.env.arShortUrlDomain}/${generateRandomString(5)}`;
        if (originalUrls && !!originalUrls[index] && originalUrls[index] !== AR_SHORT_URL_PATTERN && originalUrls[index] !== AR_DEV1_SHORT_URL_PATTERN && originalUrls[index] !== AR_STAGING_SHORT_URL_PATTERN && originalUrls[index] !== AR_PROD_SHORT_URL_PATTERN) {
          return `<a href="${originalUrls[index]}" target="_blank" onclick="return false;">${matchingDummyUrl}</a>`;
        } else if (url === AR_SHORT_URL_PATTERN || url === AR_DEV1_SHORT_URL_PATTERN || url === AR_STAGING_SHORT_URL_PATTERN || url === AR_PROD_SHORT_URL_PATTERN) {
          return `<a href="${url}" data-is-opt-out target="_blank" onclick="return false;">${optOutLinkDummyUrl}</a>`;
        }
        return `<a href="${url}" target="_blank" onclick="return false;">${matchingDummyUrl}</a>`
      });
    },
    handlePreviewAnchorClick(idx, anchor) {
      let urlLink = anchor?.attributes['href']?.textContent;
      const isOptOut = anchor?.attributes['data-is-opt-out'];
      const isValidUrl = new RegExp(urlsRegex).test(urlLink);
      let linkCopy = '';

      if (!!isOptOut) {
        linkCopy = "their personal opt-out page";
      } else if (isValidUrl) {
        if (!urlLink.startsWith('http')) {
          linkCopy = `<a href="https://${urlLink}" target="_blank">${urlLink}</a>`
        } else {
          linkCopy = `<a href="${urlLink}" target="_blank">${urlLink}</a>`
        }
      } else {
        linkCopy = `the link you provided`;
      }

      this.SHOW_CONFIRM({
        title: "You've just clicked a shortlink",
        messageHtml: `We automatically shorten links to provide click tracking. When the recipient clicks this link, they'll be redirected to ${linkCopy}.` ,
        hideCancelButton: true,
        confirmButtonText: 'OK',
        hasCrossIcon: true,
      });
    },
  }

};
</script>

<style lang="scss" scoped>
  .screen-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .sender-name {
      align-self: center;
      font-size: 12px;
      color: black;
    }

    section.message-area {
      flex: 1;
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding: 41px 30px 12px 30px;
    }

    .mms-image {
      border-radius: 8px;
      margin-bottom: 10px;
    }
  }
</style>

