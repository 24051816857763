<template>
  <section class="message-ab">
    <div class="message-ab__list">
      <div class="message-ab__item">
        <div class="message-ab__item-header">
          <ar-text
            :text="'A test'"
            :style="{
              marginRight: '16px'
            }"
          />
          <am2-tag v-if="abPreviewWinner === 'A'" type="purple500" text="Winning" />
          <am2-tag v-else-if="abPreviewWinner === ''" type="purple500" text="Tied" />
        </div>
        <RecipientsOverviewStats :overview-stats="overviewStatsA" :is-sms="false" :oid="oidInteger" ab-variant="A" />
      </div>

      <div class="message-ab__item">
        <div class="message-ab__item-header">
          <ar-text
            :text="'B test'"
            :style="{
              marginRight: '16px'
            }"
          />
          <am2-tag v-if="abPreviewWinner === 'B'" type="purple500" text="Winning" />
          <am2-tag v-else-if="abPreviewWinner === ''" type="purple500" text="Tied" />
        </div>

        <RecipientsOverviewStats :overview-stats="overviewStatsB" :is-sms="false" :oid="oidInteger" ab-variant="B" />
      </div>
    </div>
  </section>
</template>

<script>
import RecipientsOverviewStats from '@/pages/message-center/messages/view/components/recipients-overview-stats.vue';
import { mapState } from 'vuex';

export default {
  name: 'AbTab',
  props: {
    abPreviewWinner: {
      type: String,
      default: ''
    }
  },
  components: { RecipientsOverviewStats },
  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      statsAB: state => state.message.statsAB
    }),

    oidInteger() {
      if (typeof this.oid === 'string' || this.oid instanceof String) return parseInt(this.oid, 10);
      else return this.oid;
    },
    overviewStatsA() {
      if (!!this.statsAB && !!this.statsAB[0].statusDetails) {
        const {
          totalMessages,
          sent,
          opened,
          clicked,
          unsubscribed,
          bounced,
          failed
        } = this.statsAB[0].statusDetails;

        const stats = {
          total: totalMessages || 0,
          sent: (sent || 0) + (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          opened: (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          clicked: clicked || 0,
          unsubscribed: unsubscribed || 0,
          bounced: bounced || 0,
          failed: failed || 0,
          totalSent: (totalMessages || 0) - (bounced || 0),
        };

        const ctorValue = stats.opened === 0 ? 0 : (stats.clicked / stats.opened) * 100;

        return {
          ...stats,
          ctor: ctorValue > 0 && ctorValue < 10 ? ctorValue.toFixed(1) : Math.trunc(ctorValue) || 0
        };
      }

      return {
        total: 0,
        sent: 0,
        opened: 0,
        clicked: 0,
        bounced: 0,
        unsubscribed: 0,
        failed: 0,
        ctor: 0
      };
    },
    overviewStatsB() {
      if (!!this.statsAB && !!this.statsAB[1].statusDetails) {
        const {
          totalMessages,
          sent,
          opened,
          clicked,
          unsubscribed,
          bounced,
          failed
        } = this.statsAB[1].statusDetails;

        const stats = {
          total: totalMessages || 0,
          sent: (sent || 0) + (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          opened: (opened || 0) + (clicked || 0) + (unsubscribed || 0),
          clicked: clicked || 0,
          unsubscribed: unsubscribed || 0,
          bounced: bounced || 0,
          failed: failed || 0,
          totalSent: (totalMessages || 0) - (bounced || 0),
        };

        const ctorValue = stats.opened === 0 ? 0 : (stats.clicked / stats.opened) * 100;

        return {
          ...stats,
          ctor: ctorValue > 0 && ctorValue < 10 ? ctorValue.toFixed(1) : Math.trunc(ctorValue) || 0
        };
      }

      return {
        total: 0,
        totalSent: 0,
        sent: 0,
        opened: 0,
        clicked: 0,
        bounced: 0,
        unsubscribed: 0,
        failed: 0,
        ctor: 0
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.message-ab {
  display: flex;
  justify-content: center;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 56px;
    gap: 32px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item-header {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
  }
}
</style>
