<template>
  <am2-card-container class="powerups-item" :style="{ width: '100%' }">
    <div class="powerups-item__icon-wrapper" :class="[item.provider === 'sms' && 'powerups-item__icon-wrapper--sms']" >
      <ar-icon
        :name="item.provider === 'email' ? 'email-outlined' : 'dialog'"
        height="20px"
        width="20px"
      />
    </div>
    <div class="powerups-item__text">
      <ar-text class="powerups-item__text-title" :text="item.title" size="16px" weight="bold" />
      <ar-text
        v-if="item.desc && !item.task"
        class="powerups-item__text-desc"
        :text="item.desc"
        :multiple-lines="true"
        size="14px"
      />
    </div>
    <div class="powerups-item__right">
      <ar-text
        v-if="item.task && item.task.status === 'draft'"
        text="Your message must be scheduled in order to send"
        class="powerups-item__right-text"
        size="14px"
      />
      <ar-text
        v-if="item.task && (item.task.status === 'scheduled' || item.task.status === 'completed')"
        :text="formatTime(item)"
        class="powerups-item__right-text"
        size="14px"
      />

      <am2-tag
        v-if="item.task && item.task.status !== 'draft'"
        class="powerups-item__status"
        :type="statusToTagColor(item.task.status)"
        shape="round"
        :text="generateStatusCopy(item.task.status)"
      />

      <ar-simple-button
        v-if="item.task && item.task.status === 'draft'"
        class="powerups-item__button"
        outlined text="Edit draft"
        @click="handleGoToMessageEdit(item)"
        data-test-id="powerups-edit-draft"
      />
      <ar-simple-button
        v-else-if="!item.task"
        class="powerups-item__button"
        outlined text="Use power up"
        @click="handleCreatePowerUp(item)"
        data-test-id="powerups-use-power-up"
      />

      <div class="powerups-item__more" v-show="getMoreOptions.length">
        <am2-icon-button-dropdown
          :items="getMoreOptions"
          @select="(option) => handleOptionSelect(option.eventName, item)"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'PowerupItem',
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
    }),
    getMoreOptions() {
      let options = []

      if (!(this.item && this.item.task)) return options

      if (this.item.task.status !== 'draft') {
        options.push({
          name: 'Edit message',
          eventName: 'view',
        },)
      }

      if (this.item.task.status === 'draft') {
        options.push({
          name: 'Settings',
          eventName: 'settings',
        })

        options.push({
          name: 'Delete',
          eventName: 'delete',
          danger: true,
        })
      }

      if (this.item.task.status === 'scheduled') {
        options.push({
          name: 'Cancel',
          eventName: 'cancel',
        })
      }

      return options
    }
  },
  methods: {
    statusToTagColor(status){
      if (!status) return 'grey';
      switch (status.toLowerCase()) {
        case 'scheduled':
          return 'purple';
        case 'completed':
          return 'green';
        case 'failed':
          return 'red';
        default:
          return 'grey';
      }
    },

    generateStatusCopy(word) {
      if (word === 'completed') {
        return 'Sent';
      } else if (word === 'in-progress') {
        return 'Sending';
      } else if (word === 'scheduled') {
        return 'Scheduled';
      } else if (!word) {
        return 'Unknown';
      } else {
        return word;
      }
    },

    formatTime(item) {
      if (!item.task || !this.currentEvent?.timeZone) return '';
      const { scheduledAt } = item.task;
      return dayjs.utc(scheduledAt).tz(this.currentEvent.timeZone).format('MMMM Do YYYY, h:mma') + ' '
        + this.currentEvent.timeZone;
    },

    handleGoToMessageEdit(item) {
      this.$emit('goToMessageEdit', item)
    },

    handleCreatePowerUp(item) {
      this.$emit('createPowerUp', item)
    },

    handleOptionSelect(eventName, item) {
      this.$emit('selectOption', {eventName, item})
    },
  }
}
</script>

<style scoped lang="scss">
.powerups-item {
  display: flex;
  align-items: center;
  padding: 24px 16px;
  margin-bottom: 16px;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &__right {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 16px;
  }

  &__button {
    height: 40px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 50%;
    margin-right: 16px;
    background: $purple100;
    color: $purple500;
  }

  &__icon-wrapper--sms {
    background: $green100;
    color: $green500;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text-title {
    color: $blueGrey800;
  }

  &__text-desc {
    margin-top: 4px;
    color: $blueGrey700;
  }

  &__right-text {
    margin-right: 16px;
    color: $blueGrey800;
  }

  &__status{
  }

  &__more {
    margin-left: 16px;
  }
}
</style>
