import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { JWT } from './types';

export default (axios: NuxtAxiosInstance) => ({

  async fetchJWT(promoterOid: number): Promise<JWT> {
    const { data, status } = await axios.cn.get(`/promoter/${promoterOid}/oneschema-jwt`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Fetch oneschema jwt error',
        message: `Error fetching oneschema jwt`,
        status,
      };
      throw apiError;
    }
    return data;
  }

});