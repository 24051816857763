<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleModalClose"
      class="cancel-schedule-modal"
    >
      <div slot="body" class="body">
        <am2-card-container class="u-padding-y-6 u-padding-x-7 u-margin-y-4 message-details">
          <div class="container">
            <CalendarErrorIcon />
            <ar-text
              class="message-name"
              size="md"
              weight="bold"
              :text="`Are you sure you want to cancel ${scratchEmailMessage.customerName || 'Message Name'}?`"
            />
            <ar-text class="message-name" size="xs" text="Cancelling a scheduled message will move it back to draft." />
          </div>
          <div class="buttons u-margin-top-8">
            <div class="row">
              <ar-simple-button
                text="Yes, Cancel"
                :style="{
                  height: '40px',
                  padding: '0 20px'
                }"
                @click="handleCancelScheduleClick"
              />
            </div>
            <div class="row">
              <ar-link-button
                class="content-link edit"
                text="No, remain scheduled"
                has-underline
                @click="handleModalClose"
              />
            </div>
          </div>
        </am2-card-container>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import CalendarErrorIcon from '~/assets/icons/calender-error.svg?inline';
export default {
  name: 'CancelScheduleModal',
  components: {
    CalendarErrorIcon
  },

  data() {
    return {
      opened: false,
      enableSchedule: false,
      formHasErrors: true,
      emailAddresses: ''
    };
  },

  computed: {
    ...mapState({
      scratchEmailMessage: state => state.message.scratchEmailMessage
    }),
    ...mapGetters({})
  },

  created() {
    window.addEventListener('arModalOpen', this.handleModalOpen);
  },

  beforeDestroy() {
    window.removeEventListener('arModalOpen', this.handleModalOpen);
  },

  methods: {
    handleModalOpen({ detail: { action, payload } }) {
      if (action !== 'OPEN_CANCELLATION_SCHEDULED_MODAL') return;

      this.opened = true;
    },

    respondModalAction(payload) {
      const event = new CustomEvent('arModalResponse', 
        { detail: { action: 'OPEN_CANCELLATION_SCHEDULED_MODAL', payload } });
      window.dispatchEvent(event);
    },

    handleModalClose() {
      this.respondModalAction(false);
      this.opened = false;
    },

    handleCancelScheduleClick() {
      this.respondModalAction(true);
      this.opened = false;
    },
    
    handleCancelClick() {
      this.respondModalAction(false);
      this.opened = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.ar-modal-header {
  border: none;
}
.cancel-schedule-modal {
  .ar-modal-header {
    border: none;
  }
  .body {
    padding: 0px;
    margin: 32px;
    .ar-card-container {
      border: none;
    }
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 8px;
    }
    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
    }
    .row {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
