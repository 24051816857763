var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'ar-elegant-tabs',
    _vm.layout && _vm.layout
  ],on:{"mouseover":_vm.handleMouseOverSection,"mouseleave":_vm.handleMouseLeaveSection}},_vm._l((_vm.items),function(item,idx){return _c('section',{key:idx,class:['item', item.key === _vm.activeKey && 'active'],style:({
      flexBasis: ((parseInt(100 / _vm.items.length, 10)) + "%"),
      height: _vm.height && (_vm.height + "px"),
    }),attrs:{"data-test-id":("elegant-tab-" + (item.name))},on:{"click":function($event){return _vm.handleItemSelect(item, idx)}}},[(item.icon)?_c('ar-icon',{style:({
        marginRight: "8px"
      }),attrs:{"name":item.icon,"color":item.iconColor ? item.iconColor : 'black',"height":item.iconHeight ? item.iconHeight : '26px'}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"name",attrs:{"text":item.name,"size":"xs","weight":"bold"}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }