<template>
  <div class="automation-heading-container">
    <div class="heading-and-button-wrapper">
      <div class="automation-heading-wrapper">
        <ar-text
          class="automation-heading"
          size="lg"
          text="Automation"
        />
        <AutomationListingHeadingDropdown
          v-model="selectedListTypeLocal"
          :options="options"
        />
      </div>
      <div class="automation-action-wrapper">
        <am2-search
          placeholder="Search automations"
          v-model="searchString"
          @enter="handleSearch"
          @clear="handleSearch"
          data-test-id="automation-search-input"
          :style="{
            width: '300px',
            height: '48px',
            marginRight: '16px',
          }"
        />
        <ar-simple-button
          class="automation-create-button"
          text="New automation"
          icon-name="circle-plus"
          :icon-props="{
            height: '22px'
          }"
          :style="{
            width: '175px',
            height: '48px',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 18px 8px 15px',
          }"
          @click="handleAutomationCreate"
        />
      </div>
    </div>
    <ar-divider
      class="automation-listing-divider"
      :style="{
        marginTop: '20px',
        marginBottom: $arMediaQuery.pageContent.maxWidth('md') ? '7px' : '28px',
        width: '100%',
      }"
    />
  </div>
</template>
<script>
import AutomationListingHeadingDropdown from './AutomationListingHeadingDropdown'
export default {
  name: 'AutomationHeadingSection',
  components: {
    AutomationListingHeadingDropdown,
  },
  props: {
    selectedListType: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    }
  },
  model: {
    prop: 'selectedListType',
    event: 'selection'
  },
  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    selectedListTypeLocal: {
      get: function() {
        return this.selectedListType
      },
      set: function(value) {
        this.$emit('selection', value)
      }
    }
  },
  methods: {
    handleAutomationCreate() {
      this.$router.push('/automation/create')
    },
    handleSearch(text) {
      this.$emit('handleSearch', this.searchString)
    },
  }
}
</script>
<style lang="scss" scoped>
.automation-heading-container {
  width: 100%;
  max-width: 1220px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .heading-and-button-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  
    .automation-heading-wrapper {
      margin-top: 50px;
      margin-bottom: 15px;
    }

    .automation-action-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }
  }
}
.automation-heading {
  margin-bottom: 12px;
}

.automation-create-button {
  margin-top: auto;
}
</style>