<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-shopify-access-token-modal"
      header="Connect To Shopify"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-shopify-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Shopify Shop Prefix"
        >
          <ar-input
            v-model="shopPrefix"
            name="shopPrefix"
            placeholder="Please enter your Shopify shop url prefix"
          />
          <ar-text
            size="xxs"
            :text="`For example, if your Shopify shop name is ‘Mary's Sandwich Shop’, then the url prefix will be ‘marys-sandwich-shop’.`"
            :style="{
                    color: $arStyle.color.blueGrey700,
                  }"
            class="u-margin-top-3"
            multiple-lines
          />
        </ar-field>
        <ar-field
          label="Access Token"
          class="u-margin-top-5"
        >
          <ar-input
            v-model="accessToken"
            name="accessToken"
            placeholder="Please enter your Shopify access token"
            id="shopify-token-input"
            type="password"
            data-test-id="shopify-token-input"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'ask-shopify-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          data-test-id="shopify-modal-confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shopPrefix: null,
      accessToken: null,
    };
  },

  computed: {
    confirmButtonDisabled() {
      return !this.shopPrefix || !this.accessToken;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.shopPrefix = null;
        this.accessToken = null;
      }
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', { shopPrefix: this.shopPrefix, accessToken: this.accessToken });
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-shopify-access-token-modal {
  .ask-shopify-access-token-modal-body {
    padding: 20px 30px 30px;
  }
  .ask-shopify-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
