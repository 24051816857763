import IconDice from './icon-dice';
import IconEventGenius from './icon-event-genius';
import IconEventbrite from './icon-eventbrite';
import IconEventix from './icon-eventix';
import IconFacebook from './icon-facebook';
import IconGoogle from './icon-google.vue';
import IconMailchimp from './icon-mailchimp';
import IconMemberful from './icon-memberful';
import IconMoshtix from './icon-moshtix';
import IconMoshtixExportsNew from './icon-moshtix-exports-new';
import IconPatreon from './icon-patreon';
import IconSeeTicketsUk from './icon-see-tickets-uk';
import IconAxs from './icon-axs.vue';
import IconShopify from './icon-shopify';
import IconTixr from './icon-tixr';
import IconTixrNew from './icon-tixr-new';
import IconTicketek from './icon-ticketek';
import IconStripe from './icon-stripe';
import IconUniverse from './icon-universe';
import IconZoom from './icon-zoom';
import IconHumanitix from './icon-humanitix.vue';
import IconTryBooking from './icon-try-booking.vue';
import IconTicketmaster from './icon-ticketmaster.vue';
import IconSeeTicketsBenelux from './icon-see-tickets-benelux.vue';
import IconTicketbooth from './icon-ticketbooth.vue';
import IconTicketspice from './icon-ticketspice.vue'
import IconShowclix from './icon-showclix.vue';
import IconTicketTailor from './icon-ticket-tailor.vue';
import IconTiktok from './icon-tiktok.vue';
import IconOztix from './icon-oztix.vue';
import IconMegatix from './icon-megatix.vue';
import IconSeeTicketsUs from './icon-see-tickets-us.vue';


export default {
  IconDice,
  IconEventGenius,
  IconEventbrite,
  IconEventix,
  IconFacebook,
  IconGoogle,
  IconMailchimp,
  IconMemberful,
  IconMoshtix,
  IconMoshtixExportsNew,
  IconPatreon,
  IconSeeTicketsUk,
  IconAxs,
  IconShopify,
  IconStripe,
  IconTicketek,
  IconTixr,
  IconTixrNew,
  IconUniverse,
  IconZoom,
  IconHumanitix,
  IconTryBooking,
  IconTicketmaster,
  IconSeeTicketsBenelux,
  IconTicketbooth,
  IconTicketspice,
  IconShowclix,
  IconTicketTailor,
  IconTiktok,
  IconOztix,
  IconMegatix,
  IconSeeTicketsUs
}
