<template>
  <div
    class="settings-domains"
  >
    <am2-domain-modal
      :is-show="isDomainModalOpen"
      @cancel="closeModal"
      @confirm="handleCreateModal"
      @verify="verifyDomain"
      :data="selectedDomain"
    />
    <am2-view-domains-modal
      :is-show="isViewDomainsModalOpen"
      @cancel="closeViewDomainsModal"
      :data="selectedDomain"
    />
    <div
      class="headings u-margin-bottom-7"
    >
      <div>
        <ar-text
          size="md"
          text="Domain Authentication"
          class="u-margin-bottom-2"
        />
        <ar-text
          size="xs"
          text="Improves your email deliverability"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
      </div>
      <div>
        <ar-simple-button
          text="New domain"
          icon-name="circle-plus"
          icon-distance="10px"
          :style="{width: '140px'}"
          @click="handleCreateDomainButtonPress"
        />
      </div>
    </div>
    <div
      v-for="domain in domainData"
      :key="domain.oid"
      class="domain-card"
    >
      <am2-domain-card
        :data="domain"
        :defaultDomain="domain.defaultDomain"
        @verifyDomain="() => { openVerifyDomainModal(domain) }"
        @viewDomain="() => { openViewDomainModal(domain) }"
        @deleteDomain="() => { deleteDomain(domain) }"
      />
    </div>
    <div
      class="no-data-skeleton"
      v-if="!domains || domains.length === 0"
    >
      <am2-no-content-section
        header="Authenticate your own domain"
        body="Domain authentication improves your email deliverability. You or a coworker will need access to your DNS host to complete this"
        button-text="New domain"
        @buttonClick="handleCreateDomainButtonPress"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, } from 'vuex';


export default {
  data() {
    return {
      isDomainModalOpen: false,
      isViewDomainsModalOpen: false,
      accountBeingModified: null,
      defaultDomainData: [],
      selectedDomain: null,
    };
  },

  computed: {
    ...mapState({
      domains: state => state.auth.domains,
    }),

    domainData() {
      if (!this.domains) {
        return this.defaultDomainData
      }

      return [
        ...this.defaultDomainData,
        ...this.domains
      ]
    }
  },

  async mounted() {
    const success = await this['auth/FETCH_DOMAINS']()
  },

  methods: {
    ...mapActions([
      'auth/FETCH_DOMAINS',
      'auth/CREATE_DOMAIN_AUTH',
      'auth/VERIFY_DOMAIN_AUTH',
      'auth/DELETE_DOMAIN',
    ]),

    handleCreateDomainButtonPress() {
      this.selectedDomain = null
      this.isDomainModalOpen = true
    },

    closeModal() {
      this.isDomainModalOpen = false
      this.selectedDomain = null
    },

    closeViewDomainsModal() {
      this.isViewDomainsModalOpen = false
      this.selectedDomain = null
    },

    async handleCreateModal(domainData) {
      const success = await this['auth/CREATE_DOMAIN_AUTH'](domainData)
      if (!!success) {
        this.selectedDomain = success
      }
    },

    openVerifyDomainModal(domain) {
      this.selectedDomain = domain
      this.isDomainModalOpen = true
    },

    async verifyDomain(data) {
      const success = await this['auth/VERIFY_DOMAIN_AUTH']({ domain: data.domain})

      if (!!success) {
        this.isDomainModalOpen = false;
        this.selectedDomain = null;
        await this['auth/FETCH_DOMAINS']()
      }
    },

    openViewDomainModal(data) {
      this.selectedDomain = data
      this.isViewDomainsModalOpen = true
    },

    deleteDomain(data) {
      if (!data?.oid) return;
      this['auth/DELETE_DOMAIN']({domainOid: data.oid})
    },

  },
};
</script>

<style lang="scss" scoped>
.settings-domains {
  max-width: 800px;
  margin: 59px auto 0;

  .headings {
    display: flex;
    justify-content: space-between;
  }

  .domain-card {
    margin-bottom: 16px;
  }

  .no-data-skeletons {

  }
}
</style>
