<template>
  <ar-modal
    :is-open="isShow"
    class="power-up-custom-modal"
    :header="modalName"
    width="590px"
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="handleCloseClick">
    <template slot="body">
      <div class="power-up-custom-modal__content">
        <div class="power-up-custom-modal__field" v-validate:dataModel="'isChannelSelected'" data-vv-name="channelSelected">
          <ar-text
            class="power-up-custom-modal__label"
            text="Select a message channel"
            size="14px"
            weight="bold"
          />
          <div class="power-up-custom-modal__item-content">
            <div class="power-up-custom-modal__channel-wrapper">
              <div
                class="power-up-custom-modal__channel-button"
                :class="[
                  dataModel.channelSelected === 'email' && 'power-up-custom-modal__channel-button--active',
                  isEditMode && dataModel.channelSelected !== 'email' && 'power-up-custom-modal__channel-button--disabled',
                  ]"
                @click="handleSelectChannel('email')"
              >
                <div class="power-up-custom-modal__channel-icon-wrapper power-up-custom-modal__channel-icon-wrapper--email">
                  <ar-icon name="email" height="24px" width="24px" />
                </div>
                <ar-text
                  class="power-up-custom-modal__channel-title"
                  text="Email"
                  size="14px"
                  weight="bold"
                />
              </div>

              <div
                v-if="isSmsEnabled"
                class="power-up-custom-modal__channel-button"
                :class="[
                  dataModel.channelSelected === 'sms' && 'power-up-custom-modal__channel-button--active',
                  isEditMode && dataModel.channelSelected !== 'sms' && 'power-up-custom-modal__channel-button--disabled'
                ]"
                @click="handleSelectChannel('sms')"
              >
                <div class="power-up-custom-modal__channel-icon-wrapper power-up-custom-modal__channel-icon-wrapper--sms">
                  <ar-icon name="dialog" height="24px" width="24px" />
                </div>
                <ar-text
                  class="power-up-custom-modal__channel-title"
                  text="SMS"
                  size="14px"
                  weight="bold"
                />
              </div>
            </div>
          </div>
          <ar-state-message
            v-if="veeErrors.has('channelSelected')"
            :style="{ marginTop: '8px' }"
            type="error"
            :text="veeErrors.first('channelSelected')"
          />
        </div>

        <div class="power-up-custom-modal__field">
          <ar-text
            class="power-up-custom-modal__label"
            text="Will this occur before or after the event?"
            size="14px"
            weight="bold"
          />
          <div class="power-up-custom-modal__item-content">
            <ar-simple-select
              class="power-up-custom-modal__select"
              placeholder="Select a message channel"
              :default-select-index="timeSelectLabel"
              :items="timeSelectItems"
              @select="handleSelectTime"
            />
          </div>
        </div>

        <div v-if="dataModel.timeSelectValue !== 'event-day'" class="power-up-custom-modal__field">
          <ar-text
            class="power-up-custom-modal__label"
            text="How many days before or after the event will the message send?"
            size="14px"
            weight="bold"
          />
          <div class="power-up-custom-modal__item-content">
            <ar-input
              class="power-up-custom-modal__input"
              placeholder="Enter a number"
              type="number"
              v-validate:dataModel="'isDaysNumber|isDaysNumberNotNull'"
              data-vv-name="daysNumber"
              :has-error="veeErrors.has('daysNumber')"
              v-model="dataModel.daysNumber"
            />
          </div>
          <ar-state-message
            v-if="veeErrors.has('daysNumber')"
            :style="{ marginTop: '8px' }"
            type="error"
            :text="veeErrors.first('daysNumber')"
          />
        </div>

        <div class="power-up-custom-modal__field">
          <ar-text
            class="power-up-custom-modal__label"
            text="What time will this send?"
            size="14px"
            weight="bold"
          />
          <div class="power-up-custom-modal__item-content-date">
            <div class="u-display-flex u-align-items-center">
              <ar-input
                placeholder="e.g. 12:00pm"
                :value="dataModel.dateTimeViewModel.time"
                v-validate:dataModel="'isTimeToSend|isTimeInvalidFormat'"
                data-vv-name="timeToSend"
                :has-error="veeErrors.has('timeToSend') || veeErrors.has('isTimeInvalidFormat')"
                autocomplete="time"
                @input="handleTimeChange"
                @blur="handleTimeBlur"
                :style="{
                maxWidth: '130px',
              }"
              />

              <ar-divider
                :style="{
                width: '20px',
                margin: '0 10px'
              }"
              />

              <am2-timezone-select
                :value="dataModel.timezone"
                @input="handleTimezoneInput"
                disabled
                v-tooltip.bottom="'Timezone is linked to your event timezone'"
                data-vv-name="timeZone"
                data-vv-as="timezone"
              />
            </div>
            <ar-state-message
              v-if="veeErrors.has('timeToSend') || veeErrors.has('isTimeInvalidFormat')"
              :style="{ marginTop: '8px' }"
              type="error"
              :text="veeErrors.first('timeToSend') || veeErrors.first('isTimeInvalidFormat')"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="power-up-custom-modal__footer">
        <ar-simple-button
          :text="finishButtonLabel"
          :loading="isCreatingPowerUp"
          :type="'purple'"
          :style="{ height: '44px' }"
          @click="handleClickFinishButton"
        />
      </div>
    </template>
  </ar-modal>
</template>

<script>
import moment from 'moment/moment'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {mapGetters, mapState} from 'vuex'
import {calculateDaysBetweenTwoDates} from '~/utils/powerups'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
export default {
  name: 'PowerUpCustomModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    editedPowerUp: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      timeSelectItems: [
        {name: 'Before', value: 'before-event'},
        {name: 'Day of the event', value: 'event-day'},
        {name: 'After', value: 'after-event'},
      ],
      dataModel: {
        channelSelected: '',
        timeSelectValue: 'before-event',
        daysNumber: null,
        timeToSend: '',
        timezone: '',
        dateTimeViewModel: {},
      },
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.dataModel.timezone = this.currentEvent.timeZone
      }
    },

    editedPowerUp (val) {
      if (val) {
        this.dataModel.channelSelected = val.provider
        this.dataModel.timeSelectValue = val.actionType
        this.dataModel.daysNumber = val.days
        this.dataModel.timezone = this.currentEvent.timeZone
        this.dataModel.dateTimeViewModel = {
          time: dayjs.utc(val.task.scheduledAt).tz(this.currentEvent.timeZone).format('hh:mma'),
        }
      }
    },
  },
  created() {
    this.$validator.extend('isChannelSelected', {
      getMessage: () => `Please select a message channel`,
      validate: value => {
        return value.channelSelected && value.channelSelected.length > 0;
      }
    });

    this.$validator.extend('isDaysNumber', {
      getMessage: () => `A number is required`,
      validate: value => {
        if (this.dataModel.timeSelectValue !== 'event-day') {
          return !!value.daysNumber;
        }
        return true;
      }
    });

    this.$validator.extend('isDaysNumberNotNull', {
      getMessage: () => `A number must be greater than 0`,
      validate: value => {
        if (this.dataModel.timeSelectValue !== 'event-day') {
          return !!value.daysNumber && value.daysNumber > 0;
        }
        return true;
      }
    });

    this.$validator.extend('isTimeToSend', {
      getMessage: () => `Time required`,
      validate: value => {
        return !!value.dateTimeViewModel.time;
      }
    });

    this.$validator.extend('isTimeInvalidFormat', {
      getMessage: () => `Invalid format (e.g. 12:00pm)`,
      validate: value => {
        if (!this.dataModel.dateTimeViewModel.time) {
          return true;
        }

        const newTime = dayjs(this.dataModel.dateTimeViewModel.time, 'hh:mma').format('hh:mma');
        return newTime !== 'Invalid Date'
      }
    });
  },
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      isCreatingPowerUp: state => state.event.isCreatingPowerUp,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    timeSelectLabel () {
      return this.timeSelectItems.findIndex(item => item.value === this.dataModel.timeSelectValue) || 0
    },
    isEditMode() { // If not then it's create mode
      return this.editedPowerUp !== null;
    },
    modalName() {
      return this.isEditMode ? 'Edit power up' : 'Create a power up';
    },
    finishButtonLabel() {
      return this.isEditMode ? 'Update' : 'Create'
    },

    isSmsEnabled() {
      return this.isFeatureEnabled(['sms-enabled'])
    }
  },
  methods: {
    handleCloseClick () {
      this.$emit('close')
    },

    handleSelectTime(item) {
      this.dataModel.timeSelectValue = item.value
    },

    handleTimeChange(time) {
      this.dataModel.dateTimeViewModel = {
        ...this.dataModel.dateTimeViewModel,
        time,
      };
    },

    handleTimeBlur() {
      this.handleTimeChange(this.dataModel.dateTimeViewModel.time)
    },

    handleTimezoneInput(timezone) {
      this.timeZone = timezone;
    },

    async handleClickFinishButton() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      let payload = {
        eventOid: this.currentEvent.oid,
        actionType: this.dataModel.timeSelectValue,
        provider: this.dataModel.channelSelected,
        days: this.dataModel.daysNumber,
      }

      if (this.dataModel.timeSelectValue === 'before-event' || this.dataModel.timeSelectValue === 'after-event') {
        const start = dayjs.utc(this.currentEvent.startDate).tz(this.currentEvent.timeZone)
        let finalDate = this.dataModel.timeSelectValue === 'before-event'
          ? dayjs.utc(this.currentEvent.startDate).tz(this.currentEvent.timeZone).subtract(this.dataModel.daysNumber, 'day')
          : dayjs.utc(this.currentEvent.startDate).tz(this.currentEvent.timeZone).add(this.dataModel.daysNumber, 'day')

        let time = this.dataModel.dateTimeViewModel.time.trim().toLowerCase();
        finalDate = finalDate.hour(dayjs(time, ["hh:mma", 'HH:mm']).hour())
        finalDate = finalDate.minute(dayjs(time, ["hh:mma", 'HH:mm']).minute())

        payload.scheduleMinutesOffset = Math.abs(start.diff(finalDate, 'minutes'))
      }

      if (this.dataModel.timeSelectValue === 'event-day') {
        let finalDate = dayjs.utc(this.currentEvent.startDate).tz(this.currentEvent.timeZone)

        let time = this.dataModel.dateTimeViewModel.time.trim().toLowerCase();
        finalDate = finalDate.hour(dayjs(time, ["hh:mma", 'HH:mm']).hour())
        finalDate = finalDate.minute(dayjs(time, ["hh:mma", 'HH:mm']).minute())

        payload.finalDate = finalDate.utc().format('YYYY-MM-DDTHH:mm:ss')
      }

      if (this.isEditMode) {
        payload.oid = this.editedPowerUp.oid;
        this.$emit('edit', payload)
      } else {
        this.$emit('create', payload)
      }
    },

    handleSelectChannel(channel) {
      if (this.isEditMode) return;
      this.dataModel.channelSelected = channel
      this.$validator.validate('channelSelected')
    },
  },
}
</script>

<style lang="scss" scoped>
.power-up-custom-modal {
  &__content {
    padding: 48px 28px;
  }

  &__field {
    margin-bottom: 24px;
  }

  &__label {
    color: $blueGrey800;
    margin-bottom: 12px;
  }

  &__item-content-date {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    padding: 24px 28px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__channel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 12px 16px;
    color: $blueGrey800;
    border: 1px solid $blueGrey500;
    border-radius: 4px;
    cursor: pointer;
  }

  &__channel-button--active {
    border: 1px solid $purple500;
    .power-up-custom-modal__channel-title {
      color: $purple500;
    }
  }

  &__channel-button--sms-active {
    color: $green500;
    border-color: $green500;
  }

  &__channel-button--disabled {
    border: 1px solid $blueGrey700;
    background: $skyBlueGrey300;
    cursor: not-allowed;
  }

  &__channel-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
  }

  &__channel-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 50%;
    background: $purple100;
  }

  &__channel-icon-wrapper--sms {
    background: $green100;
    color: $green500;
  }

  &__channel-icon-wrapper--email {
    color: $purple500;
    border-color: $purple500;
  }
}
</style>
