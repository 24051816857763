<template>
  <div class="section-wrapper">
    <ar-input
      ref="input"
      class="cf-search"
      :value="inputVal"
      placeholder="Search profile fields"
      :enable-clear="true"
      icon-name="search"
      autocomplete="search"
      @enter="handleEnterPress"
      @input="handleInputChange"
      data-test-id="cf-profile-search"
    />
    <div class="table-wrapper">
      <am2-table-html
        :heads="heads"
        :rows="rows"
        has-menu-button
        :menu-button-actions="menuButtonActions"
        @handleMenuButtonSelect="handleMenuButtonSelect"
      >

        <div
          slot="name"
          slot-scope="{ data: { name }, index, active }"
          class="cell cell-left"
          :data-test-id="`top-links-table-row-name-${index}`"
        >
          <ar-text
            size="xs"
            :text="name"
          />
        </div>


        <div
          slot="fieldType"
          slot-scope="{ data: { fieldType }, index, active }"
          class="cell cell-left"
          :data-test-id="`top-links-table-row-type-${index}`"
        >
          <ar-text
            size="xs"
            :text="fieldTypeText[fieldType]"
          />
        </div>
        <div
          slot="contact"
          slot-scope="{ data: { contact }, index, active }"
          class="cell cell-left"
          :data-test-id="`top-links-table-row-contact-${index}`"
          :style="{
            display: 'flex',
            justifyContent: 'flex-end',
          }"
        >
          <ar-text
            size="xs"
            :text="contact"
          />
        </div>

        
        <div
          slot="sysCtime"
          slot-scope="{ data: { sysCtime } }"
          class="cell"
          :style="{
            display: 'flex',
            justifyContent: 'flex-end',
          }"
        >
          <ar-text
            size="xs"
            :text="calculateTimeElapsed(sysCtime)"
            v-tooltip.top="{content: calculateTimeElapsed(sysCtime), ...tooltipOptions}"
          />
        </div>
      </am2-table-html>
    </div>
  </div>
</template>
<script>
import { timeago } from '@/utils/date/';

const defaultHeaders = [
  {
    align: "left",
    attributeKey:"name",
    format:"name",
    key:"name",
    name:"Field Name",
    sortKey: "name"
  },
  {
    align: "left",
    attributeKey:"fieldType",
    format:"fieldType",
    key:"fieldType",
    name:"Type",
    sortKey: "fieldType",
    width: 213,
  },
  // TO DO: This has been moved to v2. Let's just comment this out for now.
  // {
  //   align: "right",
  //   attributeKey:"contacts",
  //   format:"contacts",
  //   key:"contacts",
  //   name:"Contacts",
  //   sortKey: "contacts",
  //   width: 213,
  // },
  {
    align: "right",
    attributeKey:"sysCtime",
    format:"sysCtime",
    key:"sysCtime",
    name:"Date created",
    sortKey: "sysCtime",
    width: 233,
  },
];
export default {
  name: 'profileSection',
  props: {
    rows: {
      type: Array,
      default: null,
    },
    menuButtonActions: {
      type: Array,
      default: [],
    },
    fieldTypeText: {
      type: Object,
      default: {}
    },
  },
  data() {
    return {
      inputVal: '',
      tooltipOptions: {
        delay: { show: 800, hide: 0 },
      }
    }
  },
  computed: {
    heads() {
      return defaultHeaders
    }
  },
  methods: {
    calculateTimeElapsed(date) {
      return timeago(date);
    },
    handleEnterPress(val) {
      console.log("🚀 ~ handleEnterPress")
      this.inputVal = val
      this.$emit('handleUpdateSearchFilter', val)
    },
    handleInputChange(val) {
      if (val === '' && this.inputVal != '') {
        this.inputVal = ''
        this.$emit('handleUpdateSearchFilter', '')
      }
    },
    handleMenuButtonSelect(menuButton, rowData) {
      this.$emit('handleMenuButtonSelect', menuButton, rowData)
    }
  },
}
</script>
<style lang="scss" scoped>
.section-wrapper {
  width: 100%;

  .cf-search {
    width: 336px;
    margin-bottom: 32px;
  }

  .table-wrapper {
    width: 100%;
  }
}
</style>