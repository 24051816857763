<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="user-account-modal"
      header="Edit user"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'user-account-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
        v-if="!!currentData"
      >
        <div
          class="user-account-modal-body-row u-display-flex u-flex-flow-row"
        >
          <ar-field
            label="First name"
            :error-messages="[
              veeErrors.first('first-name'),
            ]"
          >
            <ar-input
              v-validate="'required'"
              v-model="currentData.firstName"
              name="first-name"
              data-vv-name="first-name"
              data-vv-as="first name"
              placeholder="Please enter your first name"
            />
          </ar-field>
          <ar-field
            label="Last name"
            :error-messages="[
              veeErrors.first('last-name'),
            ]"
          >
            <ar-input
              v-model="currentData.lastName"
              v-validate="'required'"
              name="last-name"
              data-vv-name="last-name"
              data-vv-as="last name"
              placeholder="Please enter your last name"
            />
          </ar-field>
        </div>
        
        <ar-field
          label="Email address"
          class="user-account-modal-body-row"
        >
          <ar-input
            v-model="currentData.emailAddress"
            name="email-address"
            placeholder="Please enter your email address"
            :disabled="true"
          />
        </ar-field>
        <ar-field
          label="User type"
          class="user-account-modal-body-row"
        >
          <am2-user-role-select
            class="u-margin-bottom-1"
            data-test-id="user-role-select"
            data-vv-name="user-role-select"
            data-vv-as="user role"
            :default-user-roles="defaultUserRoles"
            :promoter-user-roles="promoterUserRoles"
            :selectedUserRoles="{
              defaultUserRoleOid: currentData.defaultUserRoleOid,
              promoterUserRoleOid: currentData.promoterUserRoleOid,
            }"
            is-user-facing
            :disabled="isUserRoleSelectDisabled"
            @select="handleUserRoleSelect"
            @handleFilterInput="handleUserRoleFilterInput"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'user-account-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          text="Confirm"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UserAccountModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      currentData: !!this.data ? { ...this.data } : {},
    };
  },

  computed: {
    ...mapState({
      promoterUserRoles: state => state.auth.promoterUserRoles,
      defaultUserRoles: state => state.auth.defaultUserRoles,
    }),
    confirmButtonDisabled() {
      if (!this.currentData || !this.data) {
        return;
      }

      return (!this.currentData.firstName || this.currentData.firstName === '') ||
        (!this.currentData.lastName || this.currentData.lastName == '')  ||
        !(
          this.currentData.firstName !== this.data.firstName ||
          this.currentData.lastName !== this.data.lastName ||
          this.currentData.defaultUserRoleOid !== this.data.defaultUserRoleOid ||
          this.currentData.promoterUserRoleOid !== this.data.promoterUserRoleOid
        );
    },
    isUserRoleSelectDisabled() {
      if (!this.currentData) {
        return true;
      } else if (!this.currentData.defaultUserRoleOid) {
        return false;
      }

      const currentDefaultUserRole = this.defaultUserRoles.find((userRole) => userRole.oid === this.currentData.defaultUserRoleOid )
      // As long as the user is an "Owner", roles cannot be changed
      return (currentDefaultUserRole?.name === 'Owner') || false
    },
  },

  watch: {
    data(val) {
      this.currentData = {
        ...val
      }
    }
  },

  methods: {
    async handleConfirmClick() {
      const isValidated = await this.$validator.validateAll();

      if (isValidated) {
        this.$emit('confirm', this.currentData);
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    handleUserRoleSelect(item) {
      if (item.type === 'default') {
        this.currentData.defaultUserRoleOid = item.oid
        this.currentData.promoterUserRoleOid = null
      } else {
        this.currentData.defaultUserRoleOid = null
        this.currentData.promoterUserRoleOid = item.oid
      }
    },
    handleUserRoleFilterInput(searchString) {
      this.$emit('handleUserRoleFilterInput', searchString)
    },
  },
};
</script>

<style lang="scss" scoped>
.user-account-modal {
  .user-account-modal-body {
    display: flex;
    gap: 24px;
    padding: 48px 32px;
    flex-direction: column;

    &-row {
      width: 100%;
      gap: 24px;

      [ar-widget-id=ar-field] {
        width: 100%;
      }
    }
  }

  .user-account-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
