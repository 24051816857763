var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{ref:"template-card",class:['template-card', _vm.disabled && 'disabled'],attrs:{"data-test-id":_vm.dataTestId},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleCardClick.apply(null, arguments)}}},[(_vm.displayedActionItems.length > 0)?_c('am2-icon-button-dropdown',{staticClass:"menu-dropdown",attrs:{"items":_vm.displayedActionItems,"dropdown-style":{
      width: '200px',
    },"dropdown-item-style":{
      height: '41px',
      padding: '0 20px',
    }},on:{"select":_vm.handleDropdownActionClick}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"upper-section"},[_c('div',{ref:"email-review-box",staticClass:"email-review-box"},[(!!_vm.thumbnailUrl)?_c('div',{staticClass:"email-iframe-wrapper",style:(_vm.thumbnailStyle)}):(_vm.loadedHtmlString)?_c('div',{staticClass:"email-iframe-wrapper",style:(_vm.scaleStyle)},[_c('iframe',{staticClass:"email-iframe",style:({
            opacity: _vm.hasLoadedHtml ? '1' : '0',
          }),attrs:{"src":_vm.loadedHtmlString,"loading":"lazy","width":"100%","height":"100%","frameborder":"0","scrolling":"no"},on:{"load":_vm.handleIframeLoad}})]):_vm._e(),_vm._v(" "),(!_vm.templateHtml)?_c('div',{staticClass:"email-review-box-loading"}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"lower-section"},[_c('ar-text',{staticClass:"email-template-title",attrs:{"size":"xs","text":_vm.name,"weight":"bold","multiple-lines":"","max-lines":2,"align":"center"}}),_vm._v(" "),_c('ar-text',{staticClass:"email-template-description",style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xxs","text":_vm.description,"multiple-lines":"","max-lines":3,"align":"center"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }