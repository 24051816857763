<template>
  <section class="ar-features-editor">
    <am2-heading
      type="h1"
      :title="title"
      size="md"
      :style="{ marginBottom: '7px' }"
    />
    <ar-text size="xs" :text="description" multiple-lines />
    <ar-divider :style="{ margin: '20px 0 20px' }" />
    <ar-text size="xs" :text="description" multiple-lines />
    <ar-divider :style="{ margin: '20px 0 20px' }" />
    <div v-if="editPromoterPlan" class="permissions-section">
      <div class="permissions-sub-section">
        <am2-card-container class="container" :style="{ width: '100%' }">
          <ar-checkbox
            label="Disable Promoter"
            align="right"
            :style="{ width: '100%' }"
            v-model="editPromoterPlan.promoterDisabled"
            :disabled="disabled"
          />
        </am2-card-container>
      </div>
    </div>
    <div v-if="editPromoterPlan" class="permissions-section">
      <div class="permissions-container">
        <div class="permissions-sub-section" v-for="feature in editPromoterPlan.features" :key="feature.id">
          <am2-card-container class="container" :style="{ width: '100%' }">
            <ar-checkbox
              :label="feature.id"
              align="right"
              :style="{ width: '100%' }"
              v-model="feature.enabled"
              :disabled="disabled"
            />
            <div v-for="component in feature.components || []" :key="component.id" class="container">
              <ar-checkbox
                :label="component.id"
                align="right"
                :style="{ width: '100%' }"
                v-model="component.enabled"
                :disabled="disabled"
              />
            </div>
          </am2-card-container>
        </div>
      </div>
    </div>
    <ar-simple-button
      v-if="editable && editPromoterPlan"
      text="Update Demo Plan"
      :loading="loading"
      :style="{ margin: '15px 0 30px' }"
      @click="handleUpdateClick"
    />
  </section>
</template>

<script>
import merge from 'deepmerge';

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const defaultPromoterPlan = {
  features: [
    { id: 'campaigns', enabled: false },
    { id: 'campaign-setup', enabled: false },
    { id: 'audience', enabled: false },
    { id: 'filters', enabled: false },
    { id: 'save-filters-and-tags', enabled: false },
    { id: 'customer-profiles', enabled: false },
    { id: 'messages', enabled: false },
    { id: 'facebook-messenger', enabled: false },
    { id: 'sms', enabled: false },
    { id: 'email', enabled: false, editor: 'unlayer' },
    { id: 'conversions', enabled: false },
    { id: 'ab-emails', enabled: false },
    { id: 'integrations', enabled: false },
    { id: 'facebook-custom-audience', enabled: false },
    { id: 'api-tokens', enabled: false },
    { id: 'insights', enabled: false },
    { id: 'events', enabled: false },
    { id: 'suggested-audience', enabled: false },
    { id: 'automation', enabled: false },
    { id: 'custom-fields', enabled: false },
    { id: 'oneschema', enabled: false },
    { id: 'powerups', enabled: false },
  ],
  promoterDisabled: false
};

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    promoterPlan: {
      type: Object,
      default: null
    },
  },

  data() {
    return {
      editPromoterPlan: null,
      arEnableAutomation: process.env.arEnableAutomation,
      arEnableCustomFields: process.env.arEnableCustomFields,
      chosenEmailEditor: null,
      editorItems: [
        {
          name: 'Unlayer',
          key: 'unlayer',
          action: () => this.setChosenEmailEditor('unlayer')
        },
        {
          name: 'Beefree',
          key: 'beefree',
          action: () => this.setChosenEmailEditor('beefree')
        }
      ]
    };
  },

  watch: {
    promoterPlan: {
      handler: function(val) {
        // Create the final editPromoterPlan object
        this.editPromoterPlan = {
          ...defaultPromoterPlan,
          ...val,
          features: defaultPromoterPlan.features.map(defaultFeature => {
            const matchingFeature = (val.features || []).find(f => f.id === defaultFeature.id);
            return matchingFeature ? { ...defaultFeature, ...matchingFeature } : defaultFeature;
          }),
        };

        // Set the chosen email editor
        this.chosenEmailEditor = (val.features || []).find(f => f.id === 'email')?.editor || 'beefree';
      },
      immediate: true
    }
  },

  methods: {
    handleUpdateClick() {
      this.$emit('update', this.editPromoterPlan, this.chosenEmailEditor);
    },
    setChosenEmailEditor(editor) {
      this.chosenEmailEditor = editor;
    },
    handleDropdownItemSelect(item) {
      item.action();
    }
  }
};
</script>
<style lang="scss" scoped>
.ar-features-editor {
  .permissions-section {
    display: flex;
    flex-wrap: wrap;

    .permissions-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

    .permissions-sub-section {
      display: flex;
      flex-direction: column;
      width: calc(50% - 20px);

      .container {
        padding: 10px;
        margin: 0 0 20px;
        text-transform: capitalize;
      }
    }
  }
}
</style>
