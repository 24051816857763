var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"dynamic-tags-modal",attrs:{"is-open":_vm.opened,"header":"Dynamic tag fallbacks","width":"728px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleModalClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:" u-padding-x-8 u-margin-y-4 message-details"},_vm._l((_vm.fallbackDynamicTagsForScratchEmailMessage),function(tag){return _c('ar-field',{key:tag,staticClass:"u-margin-bottom-7",attrs:{"label":tag,"error-messages":[_vm.veeErrors.first(tag)]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true
            }),expression:"{\n              required: true\n            }"}],attrs:{"placeholder":("Enter fallback value of " + tag),"value":_vm.getFallbackDynamicTagValue(tag),"data-vv-name":tag,"data-vv-as":(tag + " fallback tag"),"data-test-id":("fallback-dynamic-tag-" + tag)},on:{"blur":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
_vm.handleFallbackDynamicTagsInput.apply(void 0, [ _vm.convertToCamelCase(tag) ].concat( argsArray ))}}})],1)}),1)]),_vm._v(" "),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('ar-link-button',{style:({
          height: '40px',
          padding: '0 20px'
        }),attrs:{"text":"Cancel"},on:{"click":_vm.handleCancelClick}}),_vm._v(" "),_c('ar-simple-button',{attrs:{"text":"Done"},on:{"click":_vm.handleSaveClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }