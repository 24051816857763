<template>
  <section data-test-id="sms-page" class="sms-page">
    <div
      class="u-margin-top-6"
      v-infinite-scroll="fetchMoreData"
      infinite-scroll-disabled="disableFetchMoreMessages"
      :infinite-scroll-distance="10"
    >
      <am2-heading
        type="h1"
        size="lg"
        title="SMS Customers"
        class="u-margin-bottom-2"
        :style="{
          marginTop: '56px',
        }"
      />
      <ar-text
        class="u-margin-bottom-8"
        multiple-lines
        size="xs"
        text="All companies that have Twilio subaccounts are shown below"
      />
  
      <div
        v-if="isFetchingTwilioSMSSenderEnabledCompanies"
        class="loading-container"
      >
        <am2-loading-spinner size="48px" />
      </div>
      <am2-table
        v-else-if="!!twilioSMSSenderEnabledCompanies"
        :heads="tableHeadings"
        :rows="twilioSMSSenderEnabledCompanies"
        class="sms-companies-table"
        has-sticky-header
        enable-row-click
        has-control-section
        @rowClick="handleSMSRowClick"
      >
        <div
          slot="oid"
          slot-scope="{ data }"
          class="number-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.oid"
            />
          </div>
        </div>
        <div
          slot="emailAddress"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.emailAddress"
            />
          </div>
        </div>
        <div
          slot="name"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.name"
            />
          </div>
        </div>
        <div
          slot="country"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.country"
            />
          </div>
        </div>
        <div
          slot="type"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.type"
            />
          </div>
        </div>
      </am2-table>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: 'SmsCustomers',
  layout: 'default',

  computed: {
    ...mapState({
      twilioSMSSenderEnabledCompanies: state => state.admin.twilioSMSSenderEnabledCompanies,
      isFetchingTwilioSMSSenderEnabledCompanies: state => state.admin.isFetchingTwilioSMSSenderEnabledCompanies,
    }),

    ...mapGetters ({
      isAdminAccount: 'auth/isAdminAccount'
    }),

    tableHeadings() {
      return [
        {
          name: 'OID',
          key: 'oid',
          format: 'oid',
          align: 'left',
          width: 75,
          sortKey: 'oid'
        },
        {
          name: 'Company Name',
          key: 'name',
          format: 'name',
          align: 'left',
          width: 162,
          sortKey: 'name'
        },
        {
          name: 'Email',
          key: 'emailAddress',
          format: 'emailAddress',
          align: 'left',
          width: 233,
          sortKey: 'emailAddress'
        },
        {
          name: 'Region',
          key: 'country',
          format: 'country',
          align: 'left',
          width: 162,
          sortKey: 'country'
        },
        {
          name: 'Type',
          key: 'type',
          format: 'type',
          align: 'left',
          width: 190,
          sortKey: 'type'
        },
      ];
    },
  },

  mounted() {
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }
  },

  methods: {
    ...mapActions([
      'admin/FETCH_SMS_ENABLED_COMPANIES',
    ]),
    ...mapMutations([
      'admin/RESET_SMS_ENABLED_COMPANIES',
    ]),

    handleSMSRowClick(promoterAccount) {
      this.$router.push({
        path: `/admin/companies/${promoterAccount.oid}/edit/alpha-sms?from=sms`
      });
    },

    fetchMoreData() {
      if (!this.isFetchingTwilioSMSSenderEnabledCompanies) {
        this.fetchSMSEnabledCompanies()
      }
    },

    fetchSMSEnabledCompanies() {
      this['admin/FETCH_SMS_ENABLED_COMPANIES']();
    },
  },
}
</script>

<style lang="scss" scoped>
.users-page {
  width: 100%;
  max-width: 100%;
}

.top-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.message-cell, .number-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-cell {
  padding: 0 12px;
  overflow: hidden;
  justify-content: flex-start;
}

.number-cell {
  padding: 0 17px;
}

.action-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>