<template>
  <div class="table-section">
    <transition name="fade">
      <div class="table-wrapper">
        <am2-fan-table
          class="message-table-container"
          :head="tableHeaders"
          :body="tableData"
          :loading="loading"
          :has-index="hasIndex"
          :empty-text="emptyText"
          :enable-row-click="enableRowClick"
          has-sticky-header
          @sortBy="handleSortBy"
          @rowClick="handleRowClick"
        />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'MessageTable',
  props: {
    tableHeaders: {
      type: Array,
      default: null
    },
    tableData: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: true
    },
    hasIndex: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: 'No recipient data'
    },
    enableRowClick: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    handleSortBy(sortBy) {
      this.$emit('sort', sortBy)
    },
    handleRowClick(row) {
      this.$emit('row-click', row)
    },
  }
}
</script>
<style lang="scss" scoped>
  .table-section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    .table-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 1200px;

      .message-table-container {
        width: 100%;
      }

      >>> .table-outer-wrapper {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;

        .table-shadow {
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;

          &.left {
            border-bottom-left-radius: 5px !important;
          }
          &.right {
            border-bottom-right-radius: 3px !important;
          }
        }

        .table-container {
          .table-header-outer-container {
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
          }
        }
      }
    }
  }

  .fade-enter {
    opacity: 0;
    visibility: hidden;
  }
  .fade-enter-active {
    transition: opacity 0.2s ease;
  }
  /* .fade-leave {
    } */
  .fade-leave-active {
    transition: 0.2s opacity ease;
    opacity: 0;
    visibility: hidden
  }
</style>