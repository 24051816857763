<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="new-sub-account-modal"
      header="New Sub-account"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'new-sub-account-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
        v-if="!!currentData"
      >
        <div
          class="new-sub-account-modal-body-row u-display-flex u-flex-flow-row"
        >
          <ar-field
            label="Sub-account ID"
            :error-messages="[
              veeErrors.first('sub-account-id'),
            ]"
          >
            <ar-input
              v-validate="'required|alphanumeric'"
              v-model="currentData.twilioId"
              name="sub-account-id"
              data-vv-name="sub-account-id"
              data-vv-as="sub account ID"
              placeholder="ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            />
            <ar-text
              class="description"
              size="xs"
              text="Copy and paste the sub-account ID here"
              multiple-lines
              :style="{
                color: $arStyle.color.blueGrey700,
                marginTop: '8px',
              }"
            />
          </ar-field>
        </div>

        <ar-field
          label="Phone Message Service SID"
          class="new-sub-account-modal-body-row"
          :error-messages="[
            veeErrors.first('message-service-sid'),
          ]"
        >
          <ar-input
            v-model="currentData.phoneServiceSid"
            v-validate="'twilioMessageServiceSid|required'"
            name="mobile-number"
            data-vv-name="mobile-number"
            data-vv-as="mobile number"
            placeholder="MGXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
          />

          <ar-text
            class="description"
            size="xs"
            text="Copy and paste the phone sending messaging service SID here"
            multiple-lines
            :style="{
              color: $arStyle.color.blueGrey700,
              marginTop: '8px',
            }"
          />
        </ar-field>
      </div>
      <div
        slot="footer"
        :class="[
          'new-sub-account-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-link-button
          text="Cancel"
          @click="handleCloseClick"
          :style="{
            height: '40px',
            padding: '0 20px'
          }"
        />
        <ar-simple-button
          text="Confirm"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TwilioSubAccountModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentData: !!this.data ? { ...this.data } : {},
    };
  },

  computed: {
    ...mapState({
    }),
    confirmButtonDisabled() {
      if (!this.currentData || !this.data) {
        return;
      }

      return (!this.currentData.twilioId || this.currentData.twilioId === '') ||
      (!this.currentData.phoneServiceSid || this.currentData.phoneServiceSid === '')
    },
  },

  watch: {
    data(val) {
      this.currentData = {
        ...val
      }
    }
  },

  methods: {
    async handleConfirmClick() {
      const isValidated = await this.$validator.validateAll();

      if (isValidated) {
        this.$emit('confirm', this.currentData);
        this.resetCurrentData()
      }
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.resetCurrentData()
    },
    handleUserRoleFilterInput(searchString) {
      this.$emit('handleUserRoleFilterInput', searchString)
    },
    resetCurrentData() {
      this.currentData = {
        twilioId: null,
        phoneServiceSid: null,
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.new-sub-account-modal {
  .new-sub-account-modal-body {
    display: flex;
    gap: 24px;
    padding: 48px 32px;
    flex-direction: column;

    &-row {
      width: 100%;
      gap: 24px;

      [ar-widget-id=ar-field] {
        width: 100%;
      }
    }
  }

  .new-sub-account-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
