var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSmsEnabled || _vm.isEmailEnabled)?_c('am2-card-container',{staticClass:"engagement",attrs:{"layout":"regular"}},[_c('div',{staticClass:"engagement__top"},[_c('div',[_c('ar-text',{staticClass:"name",style:({
          color: _vm.$arStyle.color.blueGrey800,
          marginBottom: '8px',
        }),attrs:{"text":_vm.title,"size":"md"}}),_vm._v(" "),_c('div',{staticClass:"engagement__top-desc"},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"text":_vm.description,"size":"sm"}}),_vm._v(" "),_c('div',{on:{"click":_vm.goToRecipients}},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.purple500,
            textDecoration: 'underline',
            cursor: 'pointer',
          }),attrs:{"text":"View messages","size":"xs"}})],1)],1)],1),_vm._v(" "),(!_vm.isOnlyOneMessageType)?_c('am2-message-type-icon-select',{attrs:{"selected":_vm.typeMessageTab},on:{"select":_vm.handleSelectMessageType}}):_vm._e()],1),_vm._v(" "),(!_vm.isEmpty)?_c('div',{staticClass:"engagement__main"},[(_vm.typeMessageTab === 'email')?[_c('RecipientsOverviewRate',{attrs:{"total":_vm.calculatedData.email.totalSent,"value":_vm.calculatedData.email.opened,"title":"Open rate","link":"","tooltip":"% of emails opened","data-test-id":"recipients-overview-open-rate"},on:{"click":_vm.goToRecipients}}),_vm._v(" "),_c('div',{staticClass:"engagement__main-divider"}),_vm._v(" "),_c('RecipientsOverviewRate',{attrs:{"total":_vm.calculatedData.email.totalSent,"value":_vm.calculatedData.email.clicked,"title":"Click rate","link":"","tooltip":"% of emails clicked","data-test-id":"recipients-overview-open-rate"},on:{"click":_vm.goToRecipients}}),_vm._v(" "),_c('div',{staticClass:"engagement__main-divider"}),_vm._v(" "),_c('SinglePercentRate',{staticClass:"single",attrs:{"title":"Click to open rate","tooltip":"% of recipients who clicked a link in the email after opening it","value":_vm.calculatedData.email.ctor}})]:[_c('RecipientsOverviewRate',{attrs:{"total":_vm.statistics.sms.totalMessages,"value":_vm.statistics.sms.clicked,"title":"Click rate","link":"","tooltip":"% of sms clicked","data-test-id":"recipients-overview-open-rate"},on:{"click":_vm.goToRecipients}})]],2):_c('div',{staticClass:"engagement__empty"},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"text":"No messages linked to this event have been sent","size":"xs"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }