<template>
  <MessageNav :menu="menu">
    <template slot="buttons">
      <div class="button-container">
        <ar-simple-button
          text="Refresh"
          :loading="isFetchingMessageList"
          :disabled="!currentSelectedMessage"
          data-test-id="refresh-data-button"
          @click="handleRefreshData"
          outlined
        />
        <am2-loading-section
          v-if="isFetchingMessageList"
          slot="controls"
          :class="$arMediaQuery.pageContent.maxWidth('sm') && 'md-max'"
          :style="{
            width: '128px',
            height: '50px',
          }"
        />
        <ar-simple-button
          v-else
          text="View message"
          :loading="isFetchingSavedEmailTemplate"
          :disabled="!currentSelectedMessage"
          data-test-id="view-message-button"
          @click="handleViewMessage"
        />
      </div>
    </template> 
  </MessageNav>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import MessageNav from './message-nav.vue'

const actionsList = [
  'FETCH_MESSAGE_STATS_BY_OID',
  'message/FETCH_MESSAGE',
  'message/FETCH_MESSAGE_DELIVERY_STATS',
  'messageList/FETCH_CURRENT_MESSAGE_LIST',
  'recipient/FETCH_MESSAGE_RECIPIENTS',
];

export default {
  name: 'MessageViewNav',
  components: {
    MessageNav
  },
  props: {
    menu: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      displayMessagePreviewModal: false
    }
  },
  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      
      isFetchingMessageList: state => state.messageList.isFetchingMessageList,
      isFetchingSavedEmailTemplate: state => state.message.isFetchingSavedEmailTemplate,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
    })
  },
  methods: {
    ...mapActions(actionsList),

    handleViewMessage() {
      this.$emit('display-message-modal')
    },

    handleRefreshData() {
      const messageListOid = this.currentSelectedMessage.meta.messageListOid

      this.FETCH_MESSAGE_STATS_BY_OID({oid: this.oid})
      this['message/FETCH_MESSAGE'](this.oid)
      this['message/FETCH_MESSAGE_DELIVERY_STATS'](this.oid)
      this['messageList/FETCH_CURRENT_MESSAGE_LIST']({oid: messageListOid})

      this.$emit('on-refresh');
    },
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 15px;
}
</style>