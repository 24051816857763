var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('am2-ticketmaster-modal',{attrs:{"is-show":_vm.showTicketmasterCredentialsModal,"loading":_vm.isCreatingTicketmasterIntegration},on:{"confirm":_vm.handleTicketmasterCredentialsConfirm,"cancel":function($event){_vm.showTicketmasterCredentialsModal = false}}}),_vm._v(" "),_c('am2-opt-in-settings-modal',{attrs:{"is-show":_vm.showOptinSettingsModal,"providerName":"Ticketmaster (AU/NZ)","message-list-oid":_vm.currentMessageListOid,"accountName":_vm.currentAccountName},on:{"cancel":function($event){_vm.showOptinSettingsModal = false}}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[_c('am2-icon-title-section',{attrs:{"title":"Ticketmaster (AU/NZ)","description":"Automatically sync your data from Ticketmaster (AU/NZ)","icon-props":{
          name: 'ticketmaster',
          color: _vm.$arStyle.color.tryBooking,
          height: '46px',
          width: '40px',
        }}})],1),_vm._v(" "),(!_vm.isFetchingTicketmasterIntegration && _vm.integrationsSummary)?_c('div',{staticClass:"title-section-right"},[(!_vm.isFetchingTicketmasterIntegration && _vm.ticketmasterIntegration && _vm.currentIntegration)?_c('am2-integration-account-select',{style:({ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }),attrs:{"default-selected-integration-oid":_vm.currentIntegration.oid,"available-integrations":_vm.integrationsSummary},on:{"select":_vm.handleIntegrationChange,"new-account":_vm.handleAddNewIntegration}}):_vm._e(),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Connection Settings',
            value: 'openSettingsModal',
          }
        ]},on:{"select":_vm.handleSettingOptionSelect}})],1):_vm._e()]),_vm._v(" "),_c('am2-tabs',{staticClass:"tab-options",attrs:{"items":[
      { title: 'Notifications' },
      { title: 'Status' }
    ],"selected-tab-index":_vm.selectedTabIndex},on:{"select":function (selected, idx) { return _vm.selectedTabIndex = idx; }}}),_vm._v(" "),(_vm.selectedTabIndex === 0)?_c('div',[_c('NotificationsContainer',{attrs:{"integration":_vm.currentIntegration,"loading":_vm.isFetchingTicketmasterIntegration}})],1):_vm._e(),_vm._v(" "),(_vm.selectedTabIndex === 1)?_c('div',{staticClass:"integration-status-container"},[(_vm.isCreatingTicketmasterIntegration || _vm.isFetchingTicketmasterIntegration)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[(_vm.integrationFailed)?_c('section',{staticClass:"integration-failed-message"},[_c('ar-snackbar',{style:({ width: '100%' }),attrs:{"type":"warning","message":"Your Ticketmaster (AU/NZ) connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"},on:{"anchorClick":_vm.handleReconnectIntegration}})],1):_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"is-fetching-sync-task":_vm.isFetchingSyncTask,"sync-status":_vm.calculatedSyncStatus,"sync-time":_vm.syncTime,"sync-copies":{
          'in-progress': 'Syncing data from Ticketmaster (AU/NZ)',
          'pending': 'The data sync is still pending',
          'completed': 'Your Ticketmaster (AU/NZ) account is currently connected',
          'cancelled': 'Your Ticketmaster (AU/NZ) account connection is cancelled',
          'failed': this.syncErrorMessage,
          'pending-setup': 'Please contact Audience Republic via support@audiencerepublic.com to finish connecting your Ticketmaster (AU/NZ) account.',
          'default': 'Syncing tasks not found',
        },"copy-size":'sm'},on:{"startSync":_vm.handleStartSync,"stopSync":_vm.handleStopSync}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }