<template>
  <am2-link-button-dropdown
    :items="decoratedItems"
    :item-key="selectKey"
    has-search
    :placeholder="placeholder"
    :dropdown-style="{
      width: '280px',
    }"
    button-name="ar-simple-button"
    :button-props="{
      textWeight: 'normal',
      outlined: true,
      iconSide: 'right',
      iconName: 'arrow',
      iconProps: {
        height: '7px',
        color: $arStyle.color.skyBlueGrey700
      },
      iconDistance: 'auto',
      type: 'grey',
    }"
    search-placeholder="Search or add provider"
    data-test-id="select-provider-chooser"
    class="select-provider-chooser"
    :class="{'select-provider-chooser__error': hasError}"
    @select="handleItemSelect"
    @searchStringChange="handleSearchStringChange"
  />
</template>
<script>
export default {
  name: 'SelectProviderChooser',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    defaultSelectedIndex: {
      type: Number,
      default: -1,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select provider',
    },
  },
  data() {
    return {
      searchString: '',
    };
  },
  computed: {
    selectKey() {
      if (this.defaultSelectedIndex === -1) {
        return null;
      }
      if (!this.items[this.defaultSelectedIndex]) {
        return null;
      }
      return this.items[this.defaultSelectedIndex].key;
    },
    decoratedItems() {
      const hasExactMatchItem = this.items.some(item => {
        return item.name.toLowerCase() === this.searchString.toLowerCase();
      });
      const filteredItems = this.items.filter(item => {
        return item.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1;
      });
      if (!hasExactMatchItem && this.searchString) {
        return [
          {
            type: 'action',
            name: `Create ${this.searchString}`,
            value: 'createProvider',
          },
          ...filteredItems,
        ];
      } else {
        return filteredItems;
      }
    },
  },
  methods: {
    handleSearchStringChange(newSearchString) {
      this.searchString = newSearchString;
    },
    createProvider(name) {
      this.dialogOpen = false;
      this.$emit('create-provider', name);
    },
    handleItemSelect(item, index) {
      if (item.type === 'action') {
        if (item.value === 'createProvider') {
          this.createProvider(this.searchString);
        }
      } else {
        this.dialogOpen = false;
        this.$emit('select', item, index);
      }
    },
  },
}
</script>

<style lang="scss">
.select-provider-chooser {
  width: 100%;
  .ar-simple-button {
    width: 100%;
    height: 40px;
    &:hover {
      background: unset !important;
    }
  }

  &__error {
    .ar-simple-button {
      border-color: $red500 !important;
      box-shadow: 0 0 0 3px #ffccc3;
    }
  }
}
</style>

