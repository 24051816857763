<template>
  <portal
    to="modal"
    :disabled="!isOpen"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      class="global-widget-modal"
      :is-open="isOpen"
      header="Create new event"
      width="1000px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleClose"
    >
      <div
        slot="body"
        :class="[
          'body',
          $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3',
      ]">
        <div class="body-container">

          <ar-field
            label="Event name"
            :error-messages="[veeErrors.first('event-name')]"
          >
            <ar-input
              v-validate="'required|max:150'"
              autocomplete="event-name"
              data-vv-name="event-name"
              data-vv-as="event name"
              placeholder="Event name"
              type="text"
              data-test-id="event-name"
              :value="scratchEvent ? scratchEvent.name : ``"
              @input="handleEventNameInput"
            />
          </ar-field>
          <ar-field
            label="Location"
            class="u-margin-top-10"
            :error-messages="[
              veeErrors.first('live-stream-url'),
              veeErrors.first('location'),
            ]"
          >
            <am2-elegant-tabs
              :items="locationTabs"
              :tab-key="selectedLocationKey"
              @select="handleLocationSelect"
              layout="wide"
              data-test-id="location-tabs"
            />
            <ar-input
              v-if="selectedLocationKey === 'physical'"
              class="u-margin-top-2"
              :value="scratchEvent ? scratchEvent.location : ``"
              @input="handleEventLocationInput"
              autocomplete="event-location"
              v-validate="'required'"
              data-vv-name="location"
              data-vv-as="location"
              placeholder="Include a venue, place or address"
              data-test-id="event-location"
            />
          </ar-field>
          <ar-field
            v-if="selectedLocationKey === 'physical'"
            label="Event capacity"
            :error-messages="[veeErrors.first('event-capacity')]"
            class="u-margin-top-10"
          >
            <ar-input
              v-validate="'integer|isPositiveInt'"
              :value="scratchEvent ? scratchEvent.capacity : ``"
              @input="handleEventCapacityInput"
              autocomplete="event-capacity"
              data-vv-name="event-capacity"
              data-vv-as="event capacity"
              placeholder="Enter event capacity"
              type="number"
              data-test-id="event-capacity"
            />
          </ar-field>

          <div
            :class="[
              'u-display-flex',
              'u-margin-top-10',
              $arMediaQuery.window.maxWidth('md') && 'u-flex-flow-column',
              'u-justify-content-space-between'
            ]"
          >
            <ar-field
              label="Event starts"
              :error-messages="[
                veeErrors.first('startDate'),
                veeErrors.first('timezone'),
              ]"
            >
              <div
                :class="[
                  'u-display-flex',
                  'u-align-items-center',
                ]"
              >
                <am2-date-time-input
                  v-validate="startDateValidate"
                  :time-zone="scratchEvent ? scratchEvent.timeZone : null"
                  :value="scratchEvent? scratchEvent.startDate : null"
                  @input="handleEventStartDateInput"
                  :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
                  data-vv-name="startDate"
                  data-vv-as="event start time"
                  data-test-id="event-start-time"
                />
                <ar-link-button
                  v-if="!displayEndTime && !$arMediaQuery.window.maxWidth('xs')"
                  class="u-margin-left-3"
                  text="Add end time"
                  @click="handleAddEndTimeClick"
                  data-test-id="add-event-end-time-link"
                />
              </div>
              <div class="u-margin-top-2 u-display-flex u-flex-flow-column" :style="{ marginBottom: '5px' }">
                <am2-timezone-dropdown
                  v-validate="'required'"
                  data-vv-name="timezone"
                  :value="selectedTimezone"
                  @input="handleEventTimeZoneInput"
                  data-test-id="event-timezone-link"
                />
                <ar-link-button
                  v-if="!displayEndTime && $arMediaQuery.window.maxWidth('xs')"
                  class="u-margin-top-2"
                  text="Add end time"
                  @click="handleAddEndTimeClick"
                  data-test-id="add-event-end-time-link"
                />
              </div>
            </ar-field>
            <!-- Divider -->
            <ar-divider
              v-if="$arMediaQuery.window.minWidth('lg') && displayEndTime"
              :style="{
                position: 'relative',
                top: '57px',
                width: 'calc(100% - 652px)',
                margin: '0 18px',
              }"
            />
            <ar-field
              v-if="displayEndTime"
              label="Event ends"
              :error-messages="[veeErrors.first('endDate')]"
            >
              <div
                :class="[
                  'u-display-flex',
                  'u-flex-flow-column',
                ]"
              >
                <am2-date-time-input
                  v-validate="endDateValidate"
                  :time-zone="scratchEvent ? scratchEvent.timeZone : null"
                  :value="scratchEvent ? scratchEvent.endDate : null"
                  @input="handleEventEndDateInput"
                  :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
                  data-vv-name="endDate"
                  data-vv-as="event end time"
                  data-test-id="event-end-time"
                  :style="{
                    maxWidth: '100%',
                  }"
                />
                <ar-link-button
                  text="Remove end time"
                  @click="handleRemoveEndTimeClick"
                  :style="{
                    marginTop: '8px',
                  }"
                  data-test-id="remove-event-end-time-link"
                />
              </div>
            </ar-field>
          </div>

          <ar-field
            :label="'Event image (optional)'"
            class="u-margin-top-10"
            :error-messages="[
              veeErrors.first('eventImage'),
              veeErrors.first('eventImageUploading')
            ]"
          >
            <div
              :style="{
                height: (scratchEventImageUrl || isUploadingEventImage) ? null : '0',
                overflow: 'hidden',
              }"
            >
              <div
                v-if="isUploadingEventImage"
                v-validate:isUploadingEventImage="'uploadingAssets'"
                data-vv-name="eventImageUploading"
                data-vv-as="event image"
              />
              <am2-dropzone
                ref="img-dropzone"
                :file-type="['image/jpeg', 'image/jpg', 'image/png']"
                file-type-alias="image"
                :placeholder-icon="{
                  name: 'image',
                }"
                :image-options="{
                  dimensions: {
                    width: 1200,
                    height: 628
                  }
                }"
                :ratio="0.525"
                feedback-type="image"
                :feedback="scratchEventImageUrl"
                enable-ar-s3-bucket-upload
                :ar-s3-bucket-upload-options-list="arS3BucketUploadOptionsList"
                @delete="handleImageDelete"
                @uploadStart="isUploadingEventImage = true"
                @uploadEnd="isUploadingEventImage = false"
                @upload="handleImageUpload"
              />
            </div>
            <div
              v-if="!scratchEventImageUrl && !isUploadingEventImage"
              class="u-display-flex"
            >
              <ar-icon
                class="u-margin-right-2"
                name="image"
                height="21px"
                :color="$arStyle.color.blueGrey600"
              />
              <ar-link-button
                text="Upload image"
                @click="handleUploadImageClick"
                data-test-id="upload-image-link"
              />
            </div>
          </ar-field>
        </div>
      </div>
      <div slot="footer" class="footer">
        <ar-simple-button
          :text="'Create Event'"
          style="padding: 18px 42px;"
          @click="handleNextClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations, } from 'vuex';
import { clone } from '@/utils/helpers';
import { copyGenerator } from '@/utils/campaign';
import moment from 'moment';


const defaultBtnTextDict = {
  presale: 'Register for Presale Access',
  competition: 'Enter the Competition',
  ballot: 'Enter the Ballot',
  waitlist: 'Register for the Waitlist',
  livestream: 'Register for live stream access',
  donate: 'Register to donate',
  preorder: 'Register to pre-order',
};

export default {
  name: 'CreateEventModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    campaign: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    campaignType: {
      type: String,
      default: 'free',
    },
  },

  data() {
    return {
      selectedLocationKey: 'physical',

      displayEndTime: false,
      reasonHtmlCopy: 'Event detail updates that you have made on Audience Republic <u><b>will not</b></u> update the event details on Eventbrite. Eventbrite will need to be updated separately.',
      dontShowAgain: false,
      isUploadingEventImage: false,
    };
  },

  async created() {
    this.initializeValidator();
    this['auth/FETCH_REGION_SETTINGS']();
  },

  computed: {
    ...mapState({
      scratchEvent: state => state.event.scratchEvent,
      currentRegionSettings: state => state.auth.regionSettings,
    }),
    ...mapGetters({
      scratchEventImageUrl: 'event/scratchEventImageUrl',
    }),

    locationTabs() {
      return [
        {
          name: 'Physical Event',
          key: 'physical',
        },
        {
          name: 'Online Event',
          key: 'online',
        },
      ];
    },

    arS3BucketUploadOptionsList() {
      const list = [
        {
          assetType: 'event-image',
          campaignOid: null,
          eventOid: this.scratchEvent ? this.scratchEvent.oid : null,
        },
      ];
      return list;
    },

    startDateValidate() {
      let timeMustBeInFuture = false;
      // Make sure the event time is in the future if
      // 1. You're intending to connect the event with Zoom
      const validate = {
        required: true,
        tzFormat: true,
        futureTime: timeMustBeInFuture,
      };
      return validate;
    },

    endDateValidate() {
      const validate = {
        tzFormat: true,
        notBeforeTime: {
          compareTime: this.scratchEvent.startDate,
          compareTimeName: 'event start time',
        },
      };
      return validate;
    },

    selectedTimezone() {
      return this.scratchEvent?.timeZone || null;
    }
  },

  watch: {
    isOpen: {
      handler() {
        this['event/RESET_SCRATCH_EVENT']();
        if (this.currentRegionSettings?.additionalInfo?.defaultTimezone) {
          this['event/PATCH_SCRATCH_EVENT']({
            timeZone: this.currentRegionSettings.additionalInfo.defaultTimezone,
          });
        }
      },
    }
  },

  methods: {
    ...mapActions([
      'event/CREATE_EVENT',
      'auth/FETCH_REGION_SETTINGS',
    ]),

    ...mapMutations([
      'event/PATCH_SCRATCH_EVENT',
      'event/RESET_SCRATCH_EVENT',
    ]),

    initializeValidator() {
      this.$validator.extend('startDate', {
        getMessage: field => `Start date cannot be after end date`,
        validate: value => {
          if (!this.scratchEvent.endDate || this.scratchEvent.endDate === 'Invalid date') {
            return true;
          }
          return moment(value).isBefore(moment(this.scratchEvent.endDate));
        },
      });
      this.$validator.extend('endDate', {
        getMessage: field => `End date cannot be before start date`,
        validate: value => {
          if (!this.scratchEvent.startDate || this.scratchEvent.startDate === 'Invalid date') {
            return true;
          }
          return moment(value).isAfter(moment(this.scratchEvent.startDate));
        },
      });
      this.$validator.extend('isPositiveInt', {
        getMessage: field => `Event capacity must be a number greater than 0`,
        validate: value => {
          return !isNaN(value) && parseInt(value) >= 1;
        },
      });
    },

    handleImageUpload({ arS3BucketResources }) {
      this.deleteEventImage();
      this['event/PATCH_SCRATCH_EVENT']({
        resources: this.scratchEvent.resources.concat([arS3BucketResources[0]]),
      });
    },

    handleImageDelete() {
      this.deleteEventImage();
      this.deleteRSVPEventImage();
    },

    handleLocationSelect(tab) {
      this.selectedLocationKey = tab.key;

      if (tab.key === 'physical') {
        this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl: null } });
      } else if (tab.key === 'online') {
        this['event/PATCH_SCRATCH_EVENT']({ location: null, capacity: null });
      }
    },

    handleEventNameInput(name) {
      this['event/PATCH_SCRATCH_EVENT']({ name });
    },

    handleEventLocationInput(location) {
      this['event/PATCH_SCRATCH_EVENT']({ location });
    },
    handleEventCapacityInput(capacity) {
      const newCapacity = capacity ? parseInt(capacity, 10) : null;
      this['event/PATCH_SCRATCH_EVENT']({ capacity: newCapacity });
    },
    handleEventStartDateInput(startDate) {
      this['event/PATCH_SCRATCH_EVENT']({ startDate });
    },
    handleEventEndDateInput(endDate) {
      this['event/PATCH_SCRATCH_EVENT']({ endDate });
    },
    handleEventTimeZoneInput(timeZone) {
      this['event/PATCH_SCRATCH_EVENT']({ timeZone });
    },
    handleEventLiveStreamUrlInput(liveStreamUrl) {
      this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl } });
    },

    handleAddEndTimeClick() {
      this.displayEndTime = true;
      if (this.scratchEvent.startDate && this.scratchEvent.startDate !== 'Invalid date') {
        const newEndDateFormat = moment.tz(this.scratchEvent.startDate, this.scratchEvent.timeZone)
          .add(2, 'hours')
          .utc()
          .format();
        this['event/PATCH_SCRATCH_EVENT']({
          endDate: newEndDateFormat,
        });
      }
    },

    handleRemoveEndTimeClick() {
      this.displayEndTime = false;
      this['event/PATCH_SCRATCH_EVENT']({ endDate: null });
    },

    handleUploadImageClick() {
      this.$refs['img-dropzone'].selectFile();
    },

    handleClose() {
      this.$emit('cancel');
    },

    async validateAll() {
      return await this.$validator.validateAll();
    },

    async handleNextClick() {
      const isValid = await this.validateAll()

      if (isValid) {
        const newEvent = await this['event/CREATE_EVENT'](this.scratchEvent);
        if (!newEvent) {
          return;
        }

        if (newEvent.oid) {

          this.$router.push({
            name: "campaigns-new-details",
            query: {
              eventOid: newEvent.oid,
              type: this.type,
            },
            params: {
              subscriptionLevel: this.campaignType,
            }
          });
        }
      }

    },

    deleteEventImage() {
      this['event/PATCH_SCRATCH_EVENT']({
        resources: this.scratchEvent.resources.filter(resource => resource.assetType !== 'event-image'),
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.global-widget-modal {
  >>> .modal-body {
    background: #FFFFFF;
  }
  .body {
    background: #FFFFFF;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;

    .body-container {

      padding: 30px 40px;
      max-width: 750px;
      width: 100%;
    }
  }
  .ar-modal-content {
    .ar-modal-footer {
      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 25px 30px;
        height: 100px;
      }
    }
  }
}
</style>
