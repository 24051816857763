/**
 * @function isValidUrl
 * @description Accepts a URL as a string, determines if it is a valid URL, a valid email address, a valid mailto link, or a valid tel link.
 * @param urlString - The string to validate as a URL, email, mailto link, or tel link.
 * @returns boolean - Returns true if valid URL, email, mailto link, or tel link; false otherwise.
 */
export const isValidUrl = (urlString: string): boolean => {
  // Decode the URL to handle encoded characters, like `%40` for `@`
  const decodedUrl = decodeURIComponent(urlString);

  try {
    const emailPattern = new RegExp(
      "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+" + // initial part of email, allowing lowercase letters, digits, and special characters
      "(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*" + // match any number of additional parts separated by a dot
      "@" + // "@" symbol 
      "(?:[a-z0-9]" + // start of the domain part must be alphanumeric
      "(?:[a-z0-9-]*[a-z0-9])?\\.)+" + // subdomains separated by dots, optional hyphens not starting/ending with a hyphen
      "[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$", // TLD ends in alphanumeric, hyphens allowed but not at the end
      "i" // case-insensitive flag for uppercase letters
    );

    const urlPattern = new RegExp(
      "^(https?:\\/\\/)" + // require http:// or https://
      "(?:[\\S]+:[\\S]+@)?" + // optional user:pass@
      "((([a-z\\d]([a-z\\d-–—]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name with allowed dashes
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+–—]*)*" + // port and path, allowing long dashes
      "(\\?[;&a-z\\d%_.~+=-–—]*)?" + // query string with allowed dashes
      "(\\#[-a-z\\d_–—]*)?$", // fragment identifier
      "i"
    );

    const mailtoPattern = /^mailto:([^?]+)(\?[\s\S]*)?$/i;
    
    const telPattern = new RegExp(
      "^tel:\\+?[\\d-\\s().]+$", // start with tel:, allow optional + for international, digits, hyphens, spaces, and parentheses
      "i"
    );

    // Check for mailto links specifically
    if (decodedUrl.startsWith("mailto:")) {
      const match = decodedUrl.match(mailtoPattern);
      return !!match && emailPattern.test(match[1]);
    }

    // Check for tel links specifically
    if (decodedUrl.startsWith("tel:")) {
      return telPattern.test(decodedUrl);
    }

    // Validate as URL
    if (decodedUrl.includes("://") || decodedUrl.match(urlPattern)) {
      return urlPattern.test(decodedUrl);
    }

    // Validate plain email format
    return emailPattern.test(decodedUrl);
  } catch (e) {
    return false;
  }
};
