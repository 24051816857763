<template>
  <div
  :class="{
      'single-rate': true,
      'highlighted': link,
    }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="onClick"
  >
    <ar-text
      :text="`${value}%`"
      size="lg"
      weigth="bold"
      :style="link && hover && {
        color: $arStyle.color.purple500,
      }"
    />
    <ar-text
      v-if="total !== null"
      class="rate-total"
      :text="formattedTotal"
      size="xxs"
      :style="{
        color: $arStyle.color.blueGrey700,
      }"
    />
    <!-- Spacer instead of total value -->
    <div v-else :style="{ height: '13.66px' }"/>
    <div class="rate-title-container">
      <ar-text
        class="rate-desc"
        :text="title"
        size="xs"
        :style="{
          color: $arStyle.color.blueGrey700,
        }"
      />
      <ar-icon
        v-if="!!tooltip && tooltip.length > 1"
        name="tooltip-question-mark"
        class="u-margin-left-2 tooltip-question-mark"
        :color="$arStyle.color.purple500"
        v-tooltip.bottom="{
            content: tooltip,
            classes: 'align-left',
          }"
      />
    </div>
  </div>
</template>

<script>
import accounting from 'accounting';

export default {
  name: 'SinglePercentRate',
  props: {
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    link: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
    }
  },

  computed: {
    formattedTotal() {
      return accounting.formatNumber(this.total)
    }
  },

  methods: {
    onClick() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
.single-rate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
  margin: 0 20px;

  &:first-child {
    margin-left: 0 !important;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &.highlighted {
    cursor: pointer;
    border-radius: 6px;

    &:hover {
      background-color: $purple100;
    }
  }

  .rate-title-container {
    display: flex;
    flex-direction: row;
  }

  .tooltip-question-mark {
    margin-top: 0.5em;
  }

  .rate-desc {
    margin-top: 0.5em;
  }

  .rate-total {
    margin-top: 0.3em;
  }
}
</style>
