var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-body-section"},[_c('am2-design-email-modal',{attrs:{"opened":_vm.displayDesignEmailModal,"show-html-email":_vm.showHtmlEmail,"template-type":_vm.templateType},on:{"designFromScratch":_vm.handleDesignFromScratch,"importHtml":_vm.handleImportHtmlClick,"template":_vm.handleFromTemplate,"close":_vm.handleDesignEmailClosed}}),_vm._v(" "),_c('am2-import-html-email-modal',{attrs:{"opened":_vm.displayHtmlEmailModal},on:{"input":_vm.handleHtmlUpload,"back":_vm.handleImportHtmlBack,"close":_vm.handleImportHtmlClosed}}),_vm._v(" "),_c('div',{ref:"wrapper",staticClass:"wrapper"},[_c('div',[(_vm.isUploadingHtmlEmail)?_c('div',{staticClass:"edit-email-section"},[_c('am2-loading-bubble',{staticClass:"sync-bubbles"}),_vm._v(" "),_c('ar-text',{attrs:{"size":"sm","align":"center","texty":"HTML upload in progress"}})],1):(_vm.htmlEmailUploadFailed)?_c('div',{staticClass:"edit-email-section"},[_c('ar-icon',{staticClass:"warning-exclamation-mark",attrs:{"name":"alert-exclamation-mark","height":"70px","width":"70px"}}),_vm._v(" "),_c('p',[_vm._v("Unable to upload HTML, "),_c('a',{staticClass:"try-again-link",on:{"click":_vm.handleHtmlEmailTryAgainClick}},[_vm._v("try again")])])],1):(_vm.htmlEmailActive)?_c('div',{staticClass:"edit-email-section",class:_vm.isAbDesign ? 'abEmail' : ''},[(_vm.isAbDesign)?_c('div',{staticClass:"design-button ab-menu",class:[ !!_vm.selectedEmailHtml && 'active' ]},[_c('ar-simple-select',{staticClass:"selector ab-selector",style:({ height: '40px' }),attrs:{"enable-filter":"","items":_vm.abOptions,"default-select-index":_vm.abSwitchIndex},on:{"select":_vm.handleVariantSelectClick}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"import-again-button",style:({
              margin: '0',
              height: '40px',
            }),attrs:{"text":"Import again","outlined":""},on:{"click":_vm.handleHtmlEmailImportAgainClick}})],1):_c('div',{staticClass:"design-button",class:[ !!_vm.selectedEmailHtml && 'active' ]},[_c('ar-simple-button',{staticClass:"import-again-button",style:({
              height: '40px',
            }),attrs:{"text":"Import again","outlined":""},on:{"click":_vm.handleHtmlEmailImportAgainClick}}),_vm._v(" "),_c('ar-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'Change design option'
            }),expression:"{\n              content: 'Change design option'\n            }",modifiers:{"top":true}}],staticClass:"icon-button",attrs:{"color":_vm.$arStyle.color.purple500,"icon-props":{
              name: 'swap',
            },"data-test-id":"change-design-option-button"},on:{"click":_vm.handleChangeDesignOptionClick}})],1),_vm._v(" "),_c('div',{ref:"emailBody",staticClass:"email",style:({ zoom: _vm.computedZoom }),domProps:{"innerHTML":_vm._s(_vm.selectedEmailHtml)}})]):(!_vm.selectedEmailHtml)?_c('div',{staticClass:"edit-email-section",class:_vm.isAbDesign ? 'abEmail' : ''},[(_vm.isAbDesign)?_c('div',{staticClass:"design-button"},[_c('ar-simple-select',{staticClass:"selector",style:({ flex: 1, height: '40px' }),attrs:{"enable-filter":"","items":_vm.abOptions,"default-select-index":_vm.abSwitchIndex},on:{"select":_vm.handleVariantSelectClick}}),_vm._v(" "),_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
            }),expression:"{\n              content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\\'re upgrading you to our new editor' : null\n            }",modifiers:{"top":true}}],style:({
              height: '40px',
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":"Design email","outlined":"","disabled":_vm.isLegacyEmail},on:{"click":_vm.handleDesignEmailClick}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"abEmailSection__error"},[(_vm.isEmptyError)?_c('ar-text',{staticClass:"error-subtext",style:({
            'margin-left': '16px'
          }),attrs:{"text":'A design is required',"weight":"normal","size":"12px"}}):_vm._e()],1),_vm._v(" "),_c('img',{style:(_vm.isAbDesign && {
            position: 'absolute',
            top: '165px',
          }),attrs:{"src":require("assets/images/message-center/email-design-image.png")}}),_vm._v(" "),(!_vm.isAbDesign)?_c('div',{staticClass:"u-display-flex u-flex-flow-column"},[_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: _vm.isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
              }),expression:"{\n                content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\\'re upgrading you to our new editor' : null\n              }",modifiers:{"top":true}}],style:({
              height: '40px'
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":'Design email',"outlined":"","disabled":_vm.isLegacyEmail},on:{"click":_vm.handleDesignEmailClick}}),_vm._v(" "),(_vm.isEmptyError)?_c('ar-text',{staticClass:"error-subtext",style:({
              'margin-left': '16px'
            }),attrs:{"text":'A design is required',"weight":"normal","size":"12px"}}):_vm._e()],1):_vm._e()]):_c('div',{ref:"wrapper",staticClass:"edit-email-section email-wrapper"},[(_vm.isAbDesign)?_c('div',{staticClass:"design-button",class:[ !!_vm.selectedEmailHtml && 'active' ]},[_c('ar-simple-select',{staticClass:"selector",style:({ width: '300px', height: '40px' }),attrs:{"enable-filter":"","items":_vm.abOptions,"default-select-index":_vm.abSwitchIndex},on:{"select":_vm.handleVariantSelectClick}}),_vm._v(" "),_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
            }),expression:"{\n              content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\\'re upgrading you to our new editor' : null\n            }",modifiers:{"top":true}}],style:({
            height: '40px',
          }),attrs:{"icon-name":"edit","icon-props":{
            width: '14px'
          },"text":"Edit email","outlined":"","disabled":_vm.isLegacyEmail},on:{"click":_vm.handleDesignEmailClick}}),_vm._v(" "),_c('ar-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'Change design option'
            }),expression:"{\n              content: 'Change design option'\n            }",modifiers:{"top":true}}],staticClass:"icon-button",attrs:{"color":_vm.$arStyle.color.purple500,"icon-props":{
              name: 'swap',
            },"data-test-id":"change-design-option-button"},on:{"click":_vm.handleChangeDesignOptionClick}})],1):_c('div',{staticClass:"design-button",class:[ !!_vm.selectedEmailHtml && 'active' ]},[_c('ar-simple-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                content: _vm.isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\'re upgrading you to our new editor' : null
              }),expression:"{\n                content: isLegacyEmail ? 'Editing legacy emails is temporarily disabled as we\\'re upgrading you to our new editor' : null\n              }",modifiers:{"top":true}}],staticClass:"edit-email-button",style:({
              height: '40px',
            }),attrs:{"icon-name":"edit","icon-props":{
              width: '14px'
            },"text":"Edit email","outlined":"","disabled":_vm.isLegacyEmail},on:{"click":_vm.handleDesignEmailClick}}),_vm._v(" "),_c('ar-icon-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: 'Change design option'
            }),expression:"{\n              content: 'Change design option'\n            }",modifiers:{"top":true}}],staticClass:"icon-button",attrs:{"color":_vm.$arStyle.color.purple500,"icon-props":{
              name: 'swap',
            },"data-test-id":"change-design-option-button"},on:{"click":_vm.handleChangeDesignOptionClick}})],1),_vm._v(" "),_c('div',{ref:"emailBody",staticClass:"email",style:({ zoom: _vm.computedZoom }),domProps:{"innerHTML":_vm._s(_vm.selectedEmailHtml)}})])]),_vm._v(" "),(_vm.isAbEmailFeatureEnabled)?_c('am2-switch-field-card',{staticClass:"u-margin-top-5 ab-email-selector",attrs:{"title":"A/B test your email","size":'normal',"title-size":"sm","subtitle":"Test out variations in design or message details","align-center":"","value":_vm.isAbEmailEnabled,"is-controlled-value":true,"test-id-switch":"message-details-design-block-ab-email-switch"},on:{"input":_vm.handleAbEmailChange}}):_vm._e()],1),_vm._v(" "),_c('AbModalOffConfirm',{attrs:{"opened":_vm.isAbModalOffConfirmOpened,"ab-type":_vm.abType},on:{"close":_vm.handleAbModalOffClose,"confirm":_vm.handleAbModalOffConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }