<template>
  <section>
    <am2-unlock-prompt
      v-if="!isMessageFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Messaging` : `Messaging`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `All-in-one messaging platform across Email Marketing and SMS. <a href='https://audiencerepublic.com/messaging' target='_blank' style='color: #FFF;'>Learn more</a>` : `All-in-one messaging platform. <a href='https://audiencerepublic.com/messaging' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 2,
      }"
    />
    <div class="hero-wrapper">
      <div class="hero-content">
        <am2-heading
          class="message-heading"
          type="h1"
          size="lg"
          :title="computedTitle"
        />
      </div>
    </div>
    <am2-navbar
      :menu="menu"
      v-ar-sticky-top="{
        priority: 1,
      }"
      width="1296px"
      lateral-padding
    >
      <div slot="controls">
        <ar-simple-button
          v-if="!isMessagingRoute"
          type="purple"
          text="Create"
          class="simple-button"
          icon-name="circle-plus"
          icon-side="left"
          icon-distance="12px"
          @click="handleButtonClick"
        />
        <am2-split-dropdown
          v-else
          :items="createButtonOptions"
          class="bottom-left top-create-button-small"
          text="Create"
          icon-name="circle-plus"
          icon-distance="12px"
          @select="runOptionalAction"
          @buttonClick="() => null"
          toggle-dropdown-on-button-click
          data-test-id="create-message-split-dropdown"
        />
      </div>
    </am2-navbar>

    <nuxt-child
      class="content--centered"
      :create-button-clicked="createButtonClicked"
      @cancelCreateClicked="handleCancelCreateClicked"
    />

  </section>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import {defaultSettingsPath} from "@/utils/helpers";

const routesTitles = {
  'messages': 'Messaging',
  'lists': 'Lists',
  'forms': 'Signup Forms',
  'templates': 'Templates',
};

export default {
  name: 'MessageCenter',

  scrollToTop: true,

  data: () => ({
    enableEmailSending: process.env.arEnableEmailSending,
    createButtonClicked: false,
  }),

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.oid,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    menu() {
      const res = {
        messages: {
          name: 'Messages',
          to: '/message-center/messages',
          featureKeys: null,
        },
        lists: {
          name: 'Lists',
          to: '/message-center/lists',
        },
        forms: {
          name: 'Signup Forms',
          to: '/message-center/forms',
        },
        templates: {
          name: 'Templates',
          to: '/message-center/templates',
        },
      };
      return res;
    },

    isMessageFeatureEnabled() {
      return this.isFeatureEnabled(['messages']);
    },

    createButtonOptions() {
      const options = [];

      if (this.enableEmailSending) {
        options.push({
          name: 'Email',
          icon: {
            name: 'email',
            color: this.$arStyle.color.email,
            height: '14px',
            width: '19px',
            wrapperStyle: {
              width: '19px',
            }
          },
          action: this.handleNewEmailMessageClick,
        });
      }

      options.push({
        name: 'SMS',
        icon: {
          name: 'sms',
          color: this.$arStyle.color.sms,
          height: '19px',
          width: '18px',
          wrapperStyle: {
            width: '19px',
          }
        },
        action: this.handleNewSmsMessageClick,
      });

      return options;
    },

    routeName() {
      const path = this.$route.path;
      return path.split('/')[2] || 'messages';
    },

    isMessagingRoute() {
      return  this.routeName === 'messages';
    },

    computedTitle() {
      return routesTitles[this.routeName];
    },
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
    ]),

    async goToPlans() {
      const settingsPath = defaultSettingsPath(this.promoterOid)
      this.$router.push({
        path: settingsPath,
      });
    },

    async createNewEmail() {
      if(this.$arMediaQuery.window.maxWidth('xs')) {
        const response = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
      } else {
        this.$router.push(`/message-center/messages/email/new/basic`);
      }
    },

    createNewMessage(type) {
      if (type === 'sms') {
        this.$router.push(`/message-center/messages/sms/new`);
      }
    },

    handleButtonClick() {
      this.createButtonClicked = true;
    },

    handleCancelCreateClicked() {
      this.createButtonClicked = false;
    },

    handleNewEmailMessageClick() {
      this.createNewEmail();
    },

    handleNewSmsMessageClick() {
      this.createNewMessage('sms');
    },

    runOptionalAction(item, index) {
      if (item.action) {
        item.action(item, index);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  &-wrapper {
    min-height: 100px;
    background-color: white;
    display: flex;
    align-items: end;
    justify-content: center;
  }

  &-content {
    width: 1296px;
    padding: 0 24px;
    margin-bottom: 20px;
  }
}
.simple-button {
  width: 125px;
}
</style>
