var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreSignupForms),expression:"loadMoreSignupForms"}],staticClass:"signup-forms-page-wrapper",attrs:{"infinite-scroll-disabled":"infiniteScrollDisabled","infinite-scroll-distance":10}},[_c('am2-confirm-signup-form-creation-modal'),_vm._v(" "),_c('div',{class:( _obj = {
      content: true
    }, _obj['content'] = true, _obj )},[_c('am2-top',{class:[
        'top-wrapper',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"searchLeft":{
        action: _vm.serverSideSearch,
        placeholder: 'Search signup forms',
        value: _vm.searchString,
        style: {
          width: _vm.$arMediaQuery.pageContent.minWidth('md') ? '330px' : '100%'
        }
      }}}),_vm._v(" "),(!_vm.isFetchingSignupForms && _vm.signupForms.length === 0)?_c('div',[(_vm.searchString)?_c('am2-no-content-section',{attrs:{"header":"No forms found","header-size":"sm"}}):_c('am2-no-content-section',{attrs:{"button-props":{ disabled: _vm.isEmptyMessageList },"icon-name":"ar-form-in-circle","header":"Create a signup form","body":_vm.isEmptyMessageList ? 'You must create a message list in order to create a signup form'
        : 'Use signup forms to capture subscribers across email and SMS',"button-text":"Create form"},on:{"buttonClick":_vm.handleFormCreate}})],1):_c('div',{class:[
        'u-display-flex',
        'u-justify-content-space-between',
        'u-flex-wrap-wrap',
        _vm.$arMediaQuery.pageContent.maxWidth('md') ? '' : 'u-flex-flow-column' ]},[_vm._l((_vm.signupForms),function(signupForm){return _c('am2-signup-form-card',{key:signupForm.oid,style:({
          marginBottom: '20px',
          width: _vm.$arMediaQuery.pageContent.only('md') ? 'calc(50% - 10px)' : '100%',
        }),attrs:{"signup-form":signupForm,"size":_vm.$arMediaQuery.pageContent.maxWidth('md') ? 'tile' : 'list'},on:{"clone":_vm.handleSignupFormClone}})}),_vm._v(" "),_vm._l((_vm.skeletonCardsCount),function(n){return _c('am2-card-skeleton',{key:n,style:({
          width: _vm.$arMediaQuery.pageContent.only('md') ? 'calc(50% - 10px)' : '100%',
          marginBottom: '20px',
          border: '1px solid #EDF1F5',
        }),attrs:{"type":"signup-form","level":2 * n - 1,"show-as":_vm.$arMediaQuery.pageContent.maxWidth('md') ? 'tiles' : 'list'}})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }