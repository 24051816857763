var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:[
    'ar-feature-mask',
    _vm.showUpgradeOn === 'hover' && 'show-upgrade-on-hover',
    _vm.showUpgradeOn === 'always' && 'show-upgrade-on-always' ],style:(_vm.wrapperStyle),on:{"click":_vm.handleSectionClick}},[(_vm.mode !== 'transparent')?_c('div',{staticClass:"lock-icon-wrapper"},[_c('ar-icon',{attrs:{"width":"28px","name":"lock","color":"white"}})],1):_vm._e(),_vm._v(" "),(_vm.mode !== 'transparent')?_c('am2-card-container',{staticClass:"modal-wrapper"},[(_vm.iconName)?_c('ar-icon',{staticClass:"u-margin-bottom-5",attrs:{"name":_vm.iconName,"color":_vm.$arStyle.color.purple500,"wrapper-style":{
        display: 'inline-flex',
        alignItem: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        background: _vm.$arStyle.color.skyBlueGrey300,
      }}}):_vm._e(),_vm._v(" "),(_vm.showPremiumTag)?_c('div',{staticClass:"tag-container",style:({
        backgroundColor: '#E39425',
      })},[_c('ar-text',{staticClass:"tag",style:({
          color: '#FFF',
        }),attrs:{"size":"10px","weight":"bold","text":"PREMIUM"}})],1):_vm._e(),_vm._v(" "),_c('ar-text',{attrs:{"size":"sm","text":_vm.title,"weight":"bold"}}),_vm._v(" "),_c('ar-text',{staticClass:"message",attrs:{"size":"xs","text":_vm.message,"multiple-lines":"","allow-html":"","line-height":"25px","align":"center"}}),_vm._v(" "),(_vm.showUpgradeNowButton)?_c('ar-simple-button',{style:({
        marginTop: '26px',
      }),attrs:{"text":"Upgrade now","icon-name":"lock","icon-props":{
        width: '14px',
      },"type":"green"},on:{"click":_vm.handleUpgradeClick}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }