var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-wrapper"},[_c('ar-input',{ref:"input",staticClass:"cf-search",attrs:{"value":_vm.inputVal,"placeholder":"Search form fields","enable-clear":true,"icon-name":"search","autocomplete":"search","data-test-id":"cf-form-search"},on:{"enter":_vm.handleEnterPress,"input":_vm.handleInputChange}}),_vm._v(" "),_c('div',{staticClass:"table-wrapper"},[_c('am2-table-html',{attrs:{"heads":_vm.heads,"rows":_vm.rows,"has-menu-button":"","menu-button-actions":_vm.menuButtonActions},on:{"handleMenuButtonSelect":_vm.handleMenuButtonSelect},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var name = ref.data.name;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell cell-left",attrs:{"data-test-id":("top-links-table-row-name-" + index)}},[_c('ar-text',{attrs:{"size":"xs","text":name}})],1)}},{key:"fieldType",fn:function(ref){
var fieldType = ref.data.fieldType;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell cell-left",attrs:{"data-test-id":("top-links-table-row-type-" + index)}},[_c('ar-text',{attrs:{"size":"xs","text":fieldType}})],1)}},{key:"contact",fn:function(ref){
var contact = ref.data.contact;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell cell-left",style:({
          display: 'flex',
          justifyContent: 'flex-end',
        }),attrs:{"data-test-id":("top-links-table-row-contact-" + index)}},[_c('ar-text',{attrs:{"size":"xs","text":contact}})],1)}},{key:"sysCtime",fn:function(ref){
        var sysCtime = ref.data.sysCtime;
return _c('div',{staticClass:"cell",style:({
          display: 'flex',
          justifyContent: 'flex-end',
        })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.calculateTimeElapsed(sysCtime)}, _vm.tooltipOptions)),expression:"{content: calculateTimeElapsed(sysCtime), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.calculateTimeElapsed(sysCtime)}})],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }