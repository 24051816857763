<template>
  <portal
    to="modal"
    :disabled="!opened"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="opened"
      class="select-email-template-modal"
      header="Select a template"
      width="1200px"
      height="100%"
      hide-footer
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        ref="body-section"
        class="body-section"
        :style="{
          minHeight: $arMediaQuery.window.maxWidth('xs') ? '100%' : '500px',
        }"
      >
        <am2-select-email-template-section
          v-show="showSelectEmailTemplateSection"
          id="email-template-section"
          @select="(template, templateType, emailHtml) => handleTemplateSelect(template, templateType, emailHtml)"
          :template-type="templateType"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SelectEmailTemplateModal',
  components: {},
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    showSelectEmailTemplateSection: {
      type: Boolean,
      default: true,
    },
    templateType: {
      type: String,
      default: 'beefree',
      validator: (val) => ['unlayer', 'rich-text', 'beefree', 'html', 'custom'].indexOf(val) > -1,
    },
  },
  data() {
    return {
      hasFetchedTemplates: false,
      templateSourceItems: [
        {
          title: 'Your templates',
          value: 'bucket',
        },
        {
          title: 'Library',
          value: 'unlayer',
        },
      ],
    }
  },

  methods: {
    ...mapActions([
      'emailTemplate/FETCH_ALL_EMAIL_TEMPLATES',
    ]),
    handleTemplateSelect(template, templateType, emailHtml) {
      let parsedTemplate = template;
      if (templateType === 'beefree' && typeof parsedTemplate === 'string') {
        parsedTemplate = JSON.parse(parsedTemplate)
      }
      this.$emit('select', parsedTemplate, templateType, emailHtml)
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-email-template-modal {
  .body-section {
    background: $skyBlueGrey300;
    .templates-wrapper {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 22px;
      margin-right: -19px;

      &.sm-max {
        padding: 20px;
      }

      .template-card {
        flex-grow: 0;
        width: calc(25% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
        &.lg-max {
          width: calc(33.3% - 20px);
          margin-right: 20px;
        }

        &.md-max {
          width: calc(50% - 20px);
          margin-right: 20px;
        }

        &.xs-max {
          width: 100%;
        }
      }
    }
  }
}
</style>
