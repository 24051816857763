<template>
  <div class="select-event-section">
    <am2-search
      placeholder="Select your events..."
      :value="searchString"
      @input="handleSearchInput"
    />
    <div
      class="event-items-section"
    >
        <div>
          <template
            v-if="!!selectedEvent && selectedEventNotInSourceEvents && !isFetchingSourceEvents">
          <SelectBox
            :key="`selectedevent-${selectedEvent.oid}`"
            :img-src="getEventImageUrl(selectedEvent)"
            :title="selectedEvent.name"
            :description="formatEventDateRang(selectedEvent)"
            :extra-description="selectedEvent.location"
            selected
            @click="handleEventBoxSelect(event)"
            :style="{
              marginBottom: '10px',
            }"
          />
      </template>
        <template
          v-if="displayEventsSection && sourceEvents"
        >
          <SelectBox
            v-for="event of sourceEvents"
            :key="event.oid || event.name"
            :img-src="getEventImageUrl(event)"
            :title="event.name"
            :description="formatEventDateRang(event)"
            :extra-description="event.location"
            :selected="selectedEvent ? selectedEvent.oid === event.oid : false"
            @click="handleEventBoxSelect(event)"
            :style="{
              marginBottom: '10px',
            }"
          />
        </template>
      </div>
      <div
        v-if="displayNoEventsSection"
        class="no-events-section"
      >
        <ar-text
          size="14px"
          text="No events found"
        />
      </div>
      <div
        v-if="displayLoadMoreEventsSection"
        class="load-more-events-section"
      >
        <ar-link-button
          text="Load more events"
          @click="handleLoadMoreEventsClick"
        />
      </div>
      <am2-loading-section
        v-if="displayLoadingSection"
        class="loading-section"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { debounce } from 'debounce';
import SelectBox from './SelectBox';

export default {
  name: 'SelectEventsSection',
  components: {
    SelectBox,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    defaultEventOid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isFetchingDefaultSelectedEvent: false,
      searchString: null,
      selectedEvent: null,
    };
  },

  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      sourceEvents: state => state.event.events,
      isFetchingSourceEvents: state => state.event.isFetchingEvents,
      hasNoMoreEvents: state => state.event.hasNoMoreEvents,
    }),
    ...mapGetters({
      getEventImageUrl: 'event/getEventImageUrl',
    }),
    selectedEventNotInSourceEvents() {
      if (!this.selectedEvent) {
        return false;
      }
      return !this.sourceEvents.filter(event => {
        return event.oid === this.selectedEvent.oid;
      })[0];
    },
    displayLoadingSection() {
      return this.isFetchingSourceEvents || this.isFetchingDefaultSelectedEvent;
    },
    displayEventsSection() {
      return !this.displayLoadingSection || (this.sourceEvents && this.sourceEvents.length > 0);
    },
    displayLoadMoreEventsSection() {
      return !this.hasNoMoreEvents && !this.displayLoadingSection;
    },
    displayNoEventsSection() {
      return this.sourceEvents.length === 0 && !this.selectedEvent && !this.displayLoadingSection;
    },
  },

  watch: {
    opened(val) {
      if (val) {
        this.initEventsData();
      }
    },
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
    ]),
    handleSearchInput(searchString) {
      if (!this.sourceEvents || this.sourceEvents.length === 0) {
        return;
      }
      this.fetchEventsDebounce(searchString, true);
    },
    async initEventsData() {
      this.selectedEvent = null;
      this.searchString = null;
      this.fetchDefaultSelectedEvent(this.defaultEventOid);
      this.fetchEvents(this.searchString, true);
    },
    async fetchDefaultSelectedEvent(eventOid) {
      if (!eventOid) { return; }
      try {
        this.isFetchingDefaultSelectedEvent = true;
        this.selectedEvent = await this.$api.event.get(this.promoterOid, eventOid);
      } catch (e) {
        console.error(e);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to fetch selected event',
        });
      } finally {
        this.isFetchingDefaultSelectedEvent = false;
      }
    },
    async fetchEvents(searchString, reload) {
      await this['event/FETCH_MORE_EVENTS']({
        searchString,
        top: 10,
        reload,
        orderBy: 'startDate desc',
      });
    },
    formatEventDateRang(event) {
      return this.$arUtils.event.generateDateRangeForEventsModal(event);
    },
    handleLoadMoreEventsClick() {
      this.fetchEvents(this.searchString, false);
    },
    handleEventBoxSelect(event) {
      this.$emit('select', event)
    },
    async handleAddClick() {
      this.$emit('confirm', this.selectedEvent)
    },
    async handleCloseClick() {
      this.$emit('cancel');
    },
  },

  created() {
    this.fetchEventsDebounce = debounce(this.fetchEvents, 300);
  },
};
</script>

<style lang="scss" scoped>


.select-event-section {
  background: #ffffff;
  padding: 30px 40px 50px;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .event-items-section {
    margin-top: 13px;
  }

  .loading-section {
    height: 40px;
  }

  .no-events-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .load-more-events-section {
    display: flex;
    justify-content: center;
  }
}
</style>
