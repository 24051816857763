var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticateFrame',[_c('ar-text',{style:({
      marginBottom: '16px',
    }),attrs:{"text":"This email is linked to an account","size":"lg","multiple-lines":""}}),_vm._v(" "),_c('ar-text',{style:({
      color: _vm.$arStyle.color.skyBlueGrey700,
      marginBottom: '40px',
    }),attrs:{"text":"If you did not receive an email click the link below","size":"sm","multiple-lines":""}}),_vm._v(" "),_c('ar-simple-button',{style:({
      width: '100%',
      marginBottom: '20px',
    }),attrs:{"type":"gradient-purple","shape":"pill","text":"Resend invite","disabled":!_vm.canResend},on:{"click":_vm.resendInvite}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }