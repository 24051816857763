import { ActionTree } from 'vuex';
import { RootState } from '@/store/modules/types';
import { CustomFieldState } from './types'
import moment from 'moment';
import { magicDownload } from '@/utils/html-element/';
import { mergeSearchStringToFanFilters } from '@/utils/filter/';
import { convertToCamelCase } from '@/utils/helpers/';

export const customFieldActions: ActionTree<CustomFieldState, RootState> = {
  async FETCH_CUSTOM_FIELDS({ state, rootState, commit }, {type, searchFilter, filter}) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;
    
    try {
      commit('SET_IS_FETCHING_CUSTOM_FIELDS', true);
      const data = await this.$api.customFields.getCustomFields(promoterOid, type, searchFilter, filter);
      commit('SET_CUSTOM_FIELDS', data);
      return true;

    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to fetch custom fields',
      });
      return false;
    } finally {
      commit('SET_IS_FETCHING_CUSTOM_FIELDS', false);
    }
  },
  async CREATE_CUSTOM_FIELD({ state, rootState, commit}, params) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;

    try {
      commit('SET_IS_CREATING_CUSTOM_FIELD', true);
      const data = await this.$api.customFields.createCustomField({
        promoterOid,
        ...params,
      })
      // Manually add to the custom fields to update Store
      commit('ADD_TO_CUSTOM_FIELDS', data);
      return data;
    } catch (error: any) {
      console.error(error);
      const errorMessage = error?.data?.message?.summary || 'Failed to create custom field'
      this.$arNotification.push({
        type: 'error',
        message: errorMessage,
      });
      return {
        type: 'error',
        ...error.data
      };
    } finally {
      commit('SET_IS_CREATING_CUSTOM_FIELD', false);
    }
  },
  async UPDATE_CUSTOM_FIELD({ state, rootState, commit}, params) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;

    try {
      const {data, status} = await this.$api.customFields.updateCustomField({
        promoterOid,
        ...params,
      })

      commit('PATCH_IN_CUSTOM_FIELDS', {oid: params.oid, changes: params});

      return true;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to update custom field',
      });
      return false;
    }
  },
  async DELETE_CUSTOM_FIELD({ state, rootState, commit}, oid: number) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;

    try {
      const {data, status} = await this.$api.customFields.deleteCustomField(promoterOid, oid)
      commit('REMOVE_FROM_CUSTOM_FIELDS', oid);
      return data;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to delete custom field',
      });
      return false;
    }
  },
  async EXPORT_CUSTOM_FIELD_CSV({ state, rootState, commit}, {oid, fieldType, name}) {
    if (!rootState.auth.account) { return null; }
    const promoterOid = rootState.auth.account.promoterOid;

    try {
      const audienceFilterJson = {
        "logic": [
          "and"
        ],
        "conditions": [
          {
            "name": "custom-field",
            "type": "custom-field",
            "data": {
              "condition": "is_known",
              "custom-field-type": fieldType,
              "custom-field-oid": oid
            }
          }
        ]
      }


      let type = ''

      switch (fieldType) {
        case 'dropdown-single':
        case 'dropdown-multi':
          type = 'customFieldOptions';
          break;
        case 'date':
          type = 'customFieldDatetime';
          break;
        case 'number':
          type = 'customFieldInteger'
          break;
        case 'text':
        default:
          type = 'customFieldString';
      }

      const audienceFilter = JSON.stringify(mergeSearchStringToFanFilters(audienceFilterJson, ''));
      const customFieldProperties = {
        name: convertToCamelCase(name),
        type: type,
      }
      const {data, status} = await this.$api.customFields.exportCustomFieldCsv(promoterOid, oid, customFieldProperties, audienceFilter)

      if (status === 200) {
        const fileName = `attendees-${moment().format('DD/MM/YYYY')}.csv`;
        magicDownload(data, fileName);
      } else {
        this.$arNotification.push({
          type: 'success',
          message: data,
          timeout: 5000
        });
      }

      return data;
    } catch (error) {
      console.error(error);
      this.$arNotification.push({
        type: 'error',
        message: 'Failed to delete custom field',
      });
      return false;
    }
  },
};
