<template>
  <section
    v-infinite-scroll="loadMorePurchases"
    infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="20"
    class="customer-profile-purchases"
  >
    <am2-purchase-details-modal :is-show="modalIsOpen" :purchase-data="modalData" @close="handleClosePurchaseModal" />
    <am2-purchases-control-section
      v-if="computedPurchases.length > 0 || searchQuery || customerState.isFetchingPurchases"
      :search-text="searchQueryModel"
      @searchTextChange="handleSearchQueryModelUpdate"
      @searchAction="(query) => handleSearch(query)"
    />
    <div
      :style="{
        borderRadius: !isCustomerProfileFeatureEnabled ? '5px' : null,
        overflow: !isCustomerProfileFeatureEnabled ? 'hidden' : null,
      }"
      v-ar-feature-mask="{
        show: !isCustomerProfileFeatureEnabled,
        showUpgradeOn: 'hover',
        iconName: 'audience',
        title: lockedTitle.purchases,
        message: 'Unlock customer profiles to get access to purchase history of your fans.',
      }"
    >
      <am2-purchases-table
        v-if="computedPurchases.length > 0 || searchQuery || customerState.isFetchingPurchases"
        :head="headings"
        :body="computedPurchases"
        :loading="customerState.isFetchingPurchases && computedPurchases.length === 0"
        :order-by="orderBy"
        @sortBy="handleSortChange"
        @openPurchaseModal="handleOpenPurchaseModal"
        :locked="!isCustomerProfileFeatureEnabled"
      />
    </div>

    <am2-no-content-section
      v-if="computedPurchases.length === 0 && !customerState.isFetchingPurchases && !searchQuery"
      :class="['ar-transition-group-item', 'u-width-100-percent']"
      :header="
        customerState.customer
          ? `${customerState.customer.name} has not made any purchases`
          : 'Customer has not made any purchases'
      "
      header-size="sm"
    />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { dummyPurchaseData } from '@/utils/audience';

export default {
  name: 'CustomerProfilePosPurchases',
  data: () => ({
    searchQueryModel: '',
    searchQuery: false,
    modalIsOpen: false,
    isOpeningModal: false,
    modalData: null,
    orderBy: { key: 'orderDate', order: 'desc' },
    headings: [
      {
        name: 'Description',
        key: 'description',
        format: 'description',
        sortKey: null,
      },
      {
        name: 'Source',
        key: 'source',
        format: 'source',
        sortKey: 'provider',
      },
      {
        name: 'Items',
        key: 'quantity',
        format: 'quantity',
        sortKey: 'totalTickets',
      },
      {
        name: 'Total Price',
        key: 'totalPrice',
        format: 'totalPrice',
        sortKey: 'totalCost',
      },
      {
        name: 'Order ID',
        key: 'orderId',
        format: 'orderId',
        sortKey: 'orderId',
      },
      {
        name: 'Status',
        key: 'status',
        format: 'status',
        sortKey: 'status',
      },
      {
        name: 'Purchase Date',
        key: 'purchaseDateTime',
        format: 'purchaseDateTime',
        sortKey: 'orderDate',
      },
    ],
  }),

  watch: {
    customer: {
      handler(val, oldVal) {
        if (val && val.oid && !oldVal && !this.isFetchingPurchases) {
          this.fetchPurchases({});
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    ...mapState({
      currentCampaign: (state) => state.campaign.currentCampaign,
      currentEvent: (state) => state.event.currentEvent,
      customerState: (state) => state.customer,
      customer: (state) => state.customer.customer,
      isFetchingPurchases: (state) => state.customer.isFetchingPurchases,
    }),

    lockedTitle() {
      return {
        purchases: this.customerState?.customer?.firstName
          ? `${this.customerState?.customer?.name}'s Purchase History`
          : `Purchase History`,
      };
    },

    infiniteScrollDisabled() {
      return (
        this.customerState.noMorePurchases ||
        this.customerState.isFetchingPurchases ||
        this.customerState.hasFetchCustomerPurchasesFailed
      );
    },

    isCustomerProfileFeatureEnabled() {
      return this.isFeatureEnabled(['customer-profiles']);
    },

    computedPurchases() {
      if (!this.isCustomerProfileFeatureEnabled) return dummyPurchaseData();
      return this.customerState.fanPurchases.filter((item) => item.fanProductOrderOid != null);
    },
  },

  mounted() {
    this['customer/RESET_CUSTOMER_PURCHASES']();
  },

  beforeDestroy() {
    this['customer/RESET_CUSTOMER_PURCHASES']();
    this['campaign/RESET_CURRENT_CAMPAIGN']();
    this['event/RESET_CURRENT_EVENT']();
  },

  methods: {
    ...mapActions([
      'customer/FETCH_CUSTOMER_PURCHASES',
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'event/FETCH_CURRENT_EVENT',
    ]),
    ...mapMutations([
      'customer/RESET_CUSTOMER_PURCHASES',
      'campaign/RESET_CURRENT_CAMPAIGN',
      'event/RESET_CURRENT_EVENT',
    ]),

    handleSearchQueryModelUpdate(text) {
      this.searchQueryModel = text;
    },

    async handleSearch() {
      this.searchQuery = this.searchQueryModel;
      this['customer/RESET_CUSTOMER_PURCHASES']();
      this.fetchPurchases({
        searchString: this.searchQuery,
      });
    },

    async loadMorePurchases() {
      if (this.customerState.noMorePurchases) return;
      if (this.customerState.isFetchingPurchases) return;
      await this.fetchPurchases({
        searchString: this.searchQuery,
        append: true,
      });
    },

    async fetchPurchases({ top = 50, append = false }) {
      if (this.customerState.isFetchingPurchases) return;
      const orderBy = `${this.orderBy.key} ${this.orderBy.order}`;
      this['customer/FETCH_CUSTOMER_PURCHASES']({
        top,
        searchString: this.searchQuery,
        append,
        orderby: orderBy,
        fanOid: this.$route.params.oid,
      });
    },

    async handleOpenPurchaseModal(purchaseData) {
      if (this.isOpeningModal) return;
      if (!purchaseData) {
        this.modalIsOpen = false;
        this.isOpeningModal = false;
        return;
      }
      this.isOpeningModal = true;
      let fallbackImage = null;
      if (!!purchaseData.campaignOid) {
        // Fetch campaign and see if it has an image
        await this['campaign/FETCH_CURRENT_CAMPAIGN'](purchaseData.campaignOid);
        if (this.currentCampaign?.resources?.length > 0) {
          const matchingImage = this.currentCampaign.resources.find(
            (resource) => resource.assetType === 'campaign-image'
          );
          if (matchingImage) {
            fallbackImage = matchingImage.url;
          } else {
            fallbackImage = '/images/placeholders/image-default.png';
          }
        }
      } else if (!!purchaseData.eventOid) {
        // Fetch event and see if it has an image
        await this['event/FETCH_CURRENT_EVENT'](purchaseData.eventOid);
        if (this.currentEvent?.resources?.length > 0) {
          const matchingImage = this.currentEvent.resources.find((resource) => resource.assetType === 'event-image');
          if (matchingImage) {
            fallbackImage = matchingImage.url;
          } else {
            fallbackImage = '/images/placeholders/image-default.png';
          }
        }
      }

      this.modalData = {
        ...purchaseData,
        fallbackImage,
      };
      this.modalIsOpen = true;
      this.isOpeningModal = false;
    },
    handleClosePurchaseModal() {
      this.modalIsOpen = false;
    },

    handleSortChange(sortObj) {
      const key = sortObj.key;
      const order = sortObj.order;

      this.orderBy = {
        key,
        order,
      };

      this.fetchPurchases({
        searchQuery: this.searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
