<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      header="Upload your HTML"
      width="590px"
      class="import-html-email-modal"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @back="handleModalBack"
      @close="handleModalClose"
      data-test-id="upload-html-email-modal"
    >
      <div slot="body">
        <div class="body">
          <ar-text
            text="Your email must contain an unsubscribe link, otherwise we will add one"
            size="xs"
            align="center"
          />
          <am2-dropzone
            class="u-margin-top-5"
            :file-type="['text/html']"
            :file-size="100000000"
            file-type-alias="HTML file"
            :force-error="failedFileType"
            :placeholder-icon="{
              name: 'upload',
              width: '16px',
              height: '16px',
              color: $arStyle.color.purple500,
              wrapperStyle: {
                background: mouseOverHTMLDropdown ? `white` : $arStyle.color.skyBlueGrey400,
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: mouseOverHTMLDropdown ? '2px solid white' : null,
              },
            }"
            :ratio="0.525"
            @upload="handleHTMLUploaded"
            :data-test-id="`import-html-email-dropzone`"
            @mouseover.native="mouseOverHTMLDropdown = true"
            @mouseleave.native="mouseOverHTMLDropdown = false"
          />
          <p v-if="failedFileType" class="import-html-email-modal__red-text">
            Incorrect file type. Please check the file type and try again.
          </p>
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { sanitizeFilename } from "@/utils/helpers";

export default {
  name: 'ImportHtmlEmailModal',
  props: {
    opened: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      mouseOverHTMLDropdown: false,
      failedFileType: false,
    }
  },

  computed: {
    dropzoneBackground() {
      if (this.mouseOverHTMLDropdown)
        return 'white'
      if (this.failedFileType)
        return this.$arStyle.color.red500
      return this.$arStyle.color.skyBlueGrey400
    },
    dropzoneBorder() {
      if (this.mouseOverHTMLDropdown)
        return '2px solid white'
      if (this.failedFileType)
        return `2px solid ${this.$arStyle.color.red500}`
      return null
    }
  },

  methods: {
    handleModalClose() {
      this.$emit('close');
    },
    handleModalBack() {
      this.$emit('back');
    },
    handleHTMLUploaded({ file }) {
      if (file.type !== 'text/html') {
        this.failedFileType = true;
      } else {
        const f = new File(
          [file], 
          sanitizeFilename(file.name), 
          { type: file.type}
        );
        this.$emit('input', f);
        this.failedFileType = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.import-html-email-modal {
  .body {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 23px 28px 28px;
    overflow: auto;
  }
  
  &__text-center {
    text-align: center;
  }

  &__red-text {
    color: red;
  }
}
</style>