<template>
  <ar-select
    ref="select"
    enable-filter
    :items="computedMessages"
    :default-select-index="selectedMessageIndex"
    :placeholder="messagePlaceholder"
    :filter-placeholder="filterPlaceholder"
    @select="handleSelect"
    :disabled="disabled"
    @clear="() => { $emit('clear') }"
    @close="() => { $emit('close') }"
    @filter-string-input="handleSearch"
    data-test-id="message-select"
  >
    <template
      slot="selected-item"
      slot-scope="selectedItemScope"
    >
      <div
        v-if="selectedItemScope.item"
        :style="{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          padding: '0 14px',
          width: '100%'
        }"
        :data-test-id="`message-item-${itemName(selectedItemScope)}`"
        v-tooltip.top="{
            content: `${itemName(selectedItemScope)} - ${selectedItemScope.item.dateTooltip}`
        }"
      >
        <ar-text
          size="xs"
          :text="itemName(selectedItemScope)"
          :style="{
            color: $arStyle.color.skyBlueGrey800,
            maxWidth: '75%',
          }"
        />
        <ar-text
          size="xs"
          :text="`${selectedItemScope.item.subtitle}`"
          :style="{
              width: 'auto',
              color: $arStyle.color.blueGrey700,
              maxWidth: '25%',
            }"
          weight="normal"

        />
      </div>
      <div
        v-else
        :style="{
          display: 'flex',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0 14px',
        }"
      >
        <ar-text
          size="xs"
          :text="messagePlaceholder"
          :style="{
            color: $arStyle.color.blueGrey600,
          }"
        />
      </div>
    </template>
    <template
      slot="item"
      slot-scope="itemScope"
    >
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '46px',
          padding: '0 15px',
          cursor: 'pointer',
          background: itemScope.active || (isSelectedPurple && itemScope.selected) ? $arStyle.color.purple100 : null,
        }"
        :data-test-id="`message-item-${convertListNameToKebabCase(itemScope.item.name)}`"
        v-tooltip.top="{
            content: `${itemName(itemScope)} - ${itemScope.item.dateTooltip}`
        }"
      >
        <ar-text
          size="xs"
          :text="itemName(itemScope)"
          :style="{
            width: 'auto',
            color: itemScope.active || (isSelectedPurple && itemScope.selected) ? '#7344c0' : '#43516b',
            maxWidth: '75%',
          }"
          :weight="itemScope.selected ? 'bold' : 'normal'"
        />
        <ar-text
          size="xs"
          :text="`${itemScope.item.subtitle}`"
          :style="{
              width: 'auto',
              color: $arStyle.color.blueGrey700,
              maxWidth: '25%',
            }"
          weight="normal"
        />
      </div>
    </template>
    <template
      slot="no-item"
    >
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          height: '55px',
          padding: '0 15px',
        }"
      >
        <ar-text
          size="xs"
          :text="'No available messages'"
        />
      </div>
    </template>
  </ar-select>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs'

export default {
  name: 'MessageSelect',
  props: {
    value: { // Value equals to message-oid
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSelectedPurple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedMessageOid: this.value,
      messages: [],
      isFetchingMessages: false,
    };
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    computedMessages() {
      return this.messages.map( message => {
        let candidateName = '';
        if (message.customerName) {
          candidateName = message.customerName;
        } else if (message?.meta?.messageBody?.subject) {
          candidateName = message?.meta.messageBody.subject;
        } else if (typeof message?.meta?.messageBody === 'string') {
          candidateName = message?.meta.messageBody;
        } else if (message?.meta?.messageBody?.preview) {
          candidateName = message.meta.messageBody.preview;
        } else if (typeof message.name === 'string') {
          candidateName = message.name;
        }
        const sendTime = message.started;
        let sendTimeString = '';
        let sendTimeStringFull = '';
        if (sendTime) {
          sendTimeString = dayjs(sendTime).format('DD MMM')
          sendTimeStringFull = dayjs(sendTime).format('DD MMM YYYY, HH:hh a')
        }
        return {
          ...message,
          name: candidateName,
          subtitle: sendTimeString,
          dateTooltip: sendTimeStringFull
        }
      });

    },
    selectedMessageIndex() {
      return this.messages.findIndex(messageList => messageList.oid === this.selectedMessageOid);
    },
    messagePlaceholder() {
      return 'Select a message...';
    },
  },
  watch: {
    value(val) {
      this.selectedMessageOid = val;
    },
  },
  mounted() {
    this.fetchMessages();
  },
  methods: {
    convertListNameToKebabCase(text) {
      return this.$arUtils.general.generateDataTestPrettyName(text);
    },

    filterPlaceholder() {
      return this.messagePlaceholder;
    },

    // For external uses, don't delete it
    openDropdown() {
      this.$refs.select.toggleDropdown(true);
    },

    async fetchMessages() {
      try {
        this.isFetchingMessages = true;
        this.messages = await this.$api.messages.search(this.promoterOid, {
          top: 50,
          skip: 0,
          orderby: 'started desc',
          searchString: this.searchString,
          messageStatus: 'completed',
          provider: this.provider
        });
      } catch (error) {
        console.error(error);
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to fetch messages',
        });
      } finally {
        this.isFetchingMessages = false;
      }
    },
    itemName(itemScope) {
      if (!itemScope) {
        return null;
      }
      return itemScope.item.meta && itemScope.item.meta.name ? itemScope.item.meta.name : itemScope.item.name
    },
    handleSelect(item) {
      this.selectedMessageOid = item.oid;
      this.$emit('input', item.oid);
      this.$emit('select', item);
    },
    handleSearch(val) {
      this.searchString = val;
    }
  },
};
</script>
