var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"moshtix-exports-modal",attrs:{"is-open":_vm.isShow,"header":"Connect to Moshtix Exports","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'moshtix-exports-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-field',{staticClass:"u-margin-top-5",attrs:{"label":"Account Name"}},[_c('ar-input',{attrs:{"name":"accountName","placeholder":"Please enter your Moshtix Exports account name","id":"moshtix-exports-account-name-input","type":"text","data-test-id":"moshtix-exports-account-name-input"},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})],1),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-margin-top-5"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Subscribe associated contacts to list (optional)","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: "Automatically subscribe all attendees of events from this account.",
          }),expression:"{\n            content: `Automatically subscribe all attendees of events from this account.`,\n          }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('mailingListOid')
        ]}},[_c('am2-message-list-select',{attrs:{"message-list-type":"manual","show-stats":false,"channels":['email']},model:{value:(_vm.mailingListOid),callback:function ($$v) {_vm.mailingListOid=$$v},expression:"mailingListOid"}})],1)],1),_vm._v(" "),_c('div',{class:[
        'moshtix-exports-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","loading":_vm.loading,"disabled":_vm.confirmButtonDisabled,"id":"moshtix-exports-confirm-button","data-test-id":"moshtix-exports-confirm-button"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }