<template>
  <section class="campaign-modify-layout">
    <ChangeEventModal
      :is-change-event-modal-visible="isChangeEventModalVisible"
      :is-new-campaign="isNewCampaign"
      :campaign-oid="editCampaign.oid"
      @change="val => isChangeEventModalVisible = val"
      @changeEvent="handleChangeEvent" />

    <am2-top-create
      ref="campaign-top"
      :total-steps="editSteps.length"
      :current-step="currentStep"
      :loading="isFetchingCampaign"
      :exit-path="sanitizedReferringPage"
    >
      <div slot="content">
        <am2-heading
          type="h1"
          size="sm"
          weight="bold"
          :title="editCampaign.name"
          :style="{
            marginBottom: '4px',
          }"
        />
        <div
          :style="{
            display: 'flex',
            alignItems: 'center',
          }"
        >
          <am2-tag
            v-if="campaignType"
            :text="campaignType"
            shape="rectangle"
            :style="{
              position: 'relative',
              height: '25px',
              marginRight: '10px',
              padding: '0 10px',
            }"
          />
          <ar-text
            :text="`${editCampaign.event.name} ${titleDateText} -`"
            size="xs"
            :style="{
              color: '#9fa8b5',
              maxWidth: 'calc(100vw - 292px)',
            }"
          />
          <ar-link-button
            text="Change event"
            data-test-id="change-event-button"
            :style="{
              marginLeft: '4px',
            }"
            @click="isChangeEventModalVisible = true"
          />
        </div>
      </div>
    </am2-top-create>
    <div :class="[
      'wrapper',
      $arMediaQuery.window.maxWidth('sm') && 'sm-max'
    ]">
      <div class="left">

        <div class="inner" ref="inner">

          <am2-loading-section
            v-if="isFetchingCampaign"
            :style="{
              height: '100%',
            }"
          />
          <nuxt-child
            ref="campaign-edit"
            :is-new="isNewCampaign"
            :editCampaign="editCampaign"
            :editSteps="editSteps"
            :setEditCampaign="setEditCampaign"
            :style="{
              overflow: isFetchingCampaign ? 'hidden' : null,
              height: isFetchingCampaign ? '0' : null,
            }"
          />
        </div>

        <nav
          class="navigation"
          :style="{
            justifyContent: isNewCampaign || (!isNewCampaign && currentEditStepIndex > 0) ? 'space-between' : 'flex-end',
          }"
        >
          <ar-simple-button
            v-if="isNewCampaign || (!isNewCampaign && currentEditStepIndex > 0)"
            text="Back"
            outlined
            data-test-id="back-button"
            @click="handlePreviousStep"
            :style="{ width: $arMediaQuery.window.minWidth('sm') ? '120px' : '90px' }"
          />
          <div
            :style="{
              display: 'flex',
              justifyContent: 'flex-end',
            }"
          >
            <ar-simple-button
              v-if="$arMediaQuery.window.maxWidth('sm')"
              :text="previewIsOpen ? 'Edit' : 'Preview'"
              @click="handlePreviewClick"
              data-test-id="preview-button"
              outlined
              :icon-name="(previewIsOpen || $arMediaQuery.window.maxWidth('xs')) ? null : 'mobile-preview'"
              icon-distance="10px"
              :icon-props="{
                height: '26px',
                color: $arStyle.color.purple200,
              }"
              :style="{
                marginRight: '12px',
                width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px'
              }"
            />
            <ar-simple-button
              v-ar-hotkey="{
                general: ['Control', 'Enter'],
                mac: ['Meta', 'Enter'],
                disabled: false,
                action: handleNextStep,
              }"
              data-test-id="next-button"
              :text="nextPage ? 'Next' : 'Finish'"
              :loading="isUpdatingCampaign || isFetchingCampaign"
              @click="handleNextStep"
              :style="{ width: $arMediaQuery.window.minWidth('sm') ? '135px' : '90px' }"
            />
          </div>
        </nav>
      </div>

      <div :class="[
        'right',
        $arMediaQuery.window.maxWidth('sm') && previewIsOpen && 'is-visible'
      ]">

        <am2-campaign-preview
          ref="campaign-preview"
          :has-account="previewHasAccount"
          :has-fan="previewHasFan"
          :preview-page="previewPage"
          :campaign="campaignForReview"
          class="preview"
          size="fullHeight"
        />
      </div>

    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import merge from 'deepmerge';
import checkoutPageAccessibility from '@/mixins/checkoutPageAccessibility';
import { clone, capitalizeFirstLetter } from '@/utils/helpers';
import { findFirstError } from '@/utils/campaign';

import ChangeEventModal from './components/change-event-modal';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

const defaultEditSteps = ['details', 'registrations', 'rewards', 'share', 'invite', 'connect'];
const freeEditSteps = ['details', 'registrations'];

// Needs to be available outside and mounted
const createEditCampaignDTO = (editCampaign) => {
  let DTO = clone(editCampaign);

  delete DTO.availableActions;
  delete DTO.resources;
  delete DTO.event;

  return DTO;
};

// Default campaign points for each event
const defaultCampaignPoints = {
  share: 5,
  follow: 5,
  connect: 20,
  referral: 10,
  register: 1,
};

// Free campaign has no points system
const freeCampaignPoints = {
  share: 0,
  follow: 0,
  connect: 0,
  referral: 0,
  register: 0,
};

const defaultEditCampaign = {
  oid: null,
  name: null,
  urlSlug: null,
  event: {
    presentation: {},
  },
  registrations: {},
  presentation: {},
  startDate: null,
  endDate: null,
  notifyDate: null,
  type: null,
  resources: [],
  settings: {},
  socialActions: {},
  subscriptionLevel: null,
  defaults: {
    points: defaultCampaignPoints,
  },
};

export default {
  name: 'Campaign',

  components: {
    ChangeEventModal,
  },

  layout: 'edit',
  mixins: [
    checkoutPageAccessibility({ featureKeys: ['campaigns'], featureName: 'Campaigns' }),
  ],

  data: () => ({
    isUpdatingCampaign: false,
    editCampaign: defaultEditCampaign,
    editSteps: defaultEditSteps,
    previewHasAccount: false,
    previewHasFan: false,
    previewPage: '',
    bypassDirtyCheck: null,
    isChangeEventModalVisible: false,
    previewIsOpen: false, // Mobile-only - when true, shows the preview instead of the editor
    setupBackPath: '/campaigns/setup/event',
  }),

  computed: {
    ...mapGetters({
      historicalCurrentPagePath: 'application/historicalCurrentPagePath',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    ...mapState({
      query: state => state.route.query,
      path: state => state.route.path,
      oid: state => state.route.params.oid,
      currentCampaign: state => state.campaign.currentCampaign,
      isFetchingCampaign: state => state.campaign.isFetchingCampaign,
    }),

    isPremiumCampaignEnabled() {
      return this.isFeatureEnabled(['campaigns']);
    },

    sanitizedReferringPage() {
      return this.historicalCurrentPagePath('/campaigns');
    },

    isNewCampaign() {
      return !this.oid;
    },

    campaignType() {
      return ` ${this.capitalize(this.editCampaign.type)}`;
    },

    currentEditStep() {
      return this.path.substring(this.path.lastIndexOf('/') + 1);
    },

    currentEditStepIndex() {
      return this.editSteps.indexOf(this.currentEditStep);
    },

    previousPage() {
      return this.editSteps[this.currentEditStepIndex - 1];
    },

    nextPage() {
      return this.editSteps[this.currentEditStepIndex + 1];
    },

    currentStep() {
      return this.editSteps.indexOf(this.currentEditStep) + 1;
    },

    isDirty() {
      return JSON.stringify(this.editCampaign) !== this.initialEditCampaign
    },

    campaignForReview() {
      let campaignForReview = clone(createEditCampaignDTO(this.editCampaign));

      delete campaignForReview.presentation.headHtml;
      delete campaignForReview.presentation.bodyHtml;

      return campaignForReview;
    },

    titleDateText() {
      if (this.editCampaign.event.startDate || this.editCampaign.event.endDate) {
        const startDate = this.formatDate(this.editCampaign.event.startDate, false, this.editCampaign.event.timeZone);
        const hasEndDate = !!this.editCampaign.event.endDate;
        const endDate = hasEndDate
          ? this.formatDate(this.editCampaign.event.endDate, true, this.editCampaign.event.timeZone)
          : null;
        return `(${startDate}${hasEndDate ? ` - ${endDate}` : ""})`;
      } else {
        return null;
      }
    },

    freeCampaign() {
      // To ensure that the campaign is always set with the right subscription permissions even when refreshing,
      // we check the subscription level every time editCampaign is updated / reloaded
      return !(this.editCampaign?.subscriptionLevel === 'regular' && this.isPremiumCampaignEnabled);
    }
  },

  watch: {
    currentEditStep(val) {
      // Nuxt scrollToTop options is not working in each of child route component
      // here, cuz they're wrapped by `inner`, which has 100vh height
      this.$refs.inner.scroll(0, 0);
      this.syncPreviewFrame(val);
    },

    'currentCampaign.subscriptionLevel': {
      handler(val) {
        if (!this.currentCampaign) {
          return null;
        }

        // Loading the subscriptionLevel from an existing campaign and updating editCampaign
        this.setEditCampaign({
          subscriptionLevel: val,
        });
      },
      deep: true
    },

    editCampaign: {
      handler(val) {
        if (!val) {
          return;
        }
        sessionStorage.setItem('_ar_promoter_new_campaign', JSON.stringify(val));
      },
      deep: true
    },

    'editCampaign.subscriptionLevel': {
      handler(val, old) {
        if (!val || val === old) {
          return;
        }

        // If editCampaign.subscriptionLevel actually changes, we will setup the campaign
        this.setupCampaign(val)
      },
      deep: true
    },
  },

  created() {
    if (!defaultEditSteps.includes(this.currentEditStep) && !this.isPremiumCampaignEnabled) {
      // Prevent premium campaign for unauthorized users
      // If other steps, redirect to campaigns
      this.$router.push({
        path: '/campaigns',
      });
    }
  },

  async mounted() {
    const eventOid = this.query.eventOid;
    const campaignOid = this.oid;
    const type = this.query.type;

    if (eventOid) {
      // Check session storage for existing campaign
      const storedEditCampaign = sessionStorage.getItem('_ar_promoter_new_campaign');
      const initialData = storedEditCampaign ? createEditCampaignDTO(JSON.parse(storedEditCampaign)) : null;

      try {
        this['campaign/SET_IS_FETCHING_CAMPAIGN'](true)
        this.editCampaign = await this.FETCH_PREVIEW_CAMPAIGN(merge(initialData, {
          eventOid,
          type,
        }));
      } catch(e) {
        console.error(e);
        this.$arNotification.push({ type: 'error', message: 'Failed to fetch preview campaign' });
      } finally {
        this['campaign/SET_IS_FETCHING_CAMPAIGN'](false)
      }
    } else if (campaignOid) {
      const succeed = await this['campaign/FETCH_CURRENT_CAMPAIGN'](campaignOid);
      if (succeed) {
        this.editCampaign = clone(this.currentCampaign);
      }
    }

    if (!this.editCampaign?.defaults?.points) {
      // Setup initial campaign points
      this.editCampaign.defaults = {
        points: defaultCampaignPoints,
      };
    }

    this.syncPreviewFrame(this.currentEditStep);

    // Unnless explicitly set, for the case of saving, or going back to the start when creating
    this.bypassDirtyCheck = false;

    // Wait for editCampaign to settle
    this.$nextTick(() => {
      this.initialEditCampaign = JSON.stringify(this.editCampaign);
      /**
       * IMPORTANT, call each pageComponents' initializeCampaignViewModel after editCampaign is fetched
       */
      if (this.$refs['campaign-edit']) {
        this.$refs['campaign-edit'].initializeCampaignViewModel();
        if (this.$refs['campaign-edit'].initPaymentModel) {
          this.$refs['campaign-edit'].initPaymentModel();
        }
      }
      if (this.$refs['campaign-preview']) {
        this.$refs['campaign-preview'].setupInitialPreviewData();
      }
    });
  },

  async beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    if(to.path !== this.setupBackPath && ( (this.isDirty || this.isNewCampaign) && !this.bypassDirtyCheck ) ) {
      const response = await this.SHOW_CONFIRM({ messageHtml: 'Are you sure you want to leave?<br />Unsaved changes will be lost.' });
      if (response) {
        sessionStorage.removeItem('_ar_promoter_new_campaign');
        this['campaign/RESET_CURRENT_CAMPAIGN']();
        next();
      } else {
        next(false)
      }
    } else {
      if(to.path !== this.setupBackPath) {
        sessionStorage.removeItem('_ar_promoter_new_campaign');
      }
      this['campaign/RESET_CURRENT_CAMPAIGN']();
      next();
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'campaign/FETCH_CURRENT_CAMPAIGN',
      'CREATE_CAMPAIGN',
      'UPDATE_CAMPAIGN',
      'FETCH_PREVIEW_CAMPAIGN',
    ]),
    ...mapMutations([
      'campaign/RESET_CURRENT_CAMPAIGN',
      'campaign/SET_IS_FETCHING_CAMPAIGN',
    ]),

    setupCampaign(subLevel) {
      // Goal: Prevent premium campaign for unauthorized users
      if (subLevel === "free") {
        this.setupFreeCampaign();
      } else {
        this.setupPremiumCampaign();
      }
    },

    setupFreeCampaign() {
      this.editSteps = freeEditSteps;
      this.editCampaign.defaults.points = freeCampaignPoints;
    },

    setupPremiumCampaign() {
      this.editSteps = defaultEditSteps;
      this.editCampaign.defaults.points = defaultCampaignPoints;
    },

    formatDate(date, displayYear, timezone) {
      let dateObject = null;
      if (timezone) {
        dateObject = dayjs(date).tz(timezone);
      } else {
        dateObject = dayjs(date);
      }
      if (!this.endDate || displayYear) {
        return dateObject.format('MMM DD, YYYY');
      }
      return dateObject.format('MMM DD');
    },

    setEditCampaign(data) {
      this.editCampaign = merge(this.editCampaign, data, { arrayMerge: overwriteMerge });
    },

    syncPreviewFrame(val) {
      switch (val) {
        case 'details':
          this.previewHasAccount = false;
          this.previewHasFan = false;
          this.previewPage = '/';
          break;
        case 'registrations':
          this.previewHasAccount = false;
          this.previewHasFan = false;
          this.previewPage = '/register';

          break;
        case 'rewards':
          this.previewHasAccount = true;
          this.previewHasFan = true;
          this.previewPage = '/unlock';
          break;
        case 'share':
          this.previewHasAccount = true;
          this.previewHasFan = true;
          this.previewPage = '/share';
          break;
        case 'invite':
          this.previewHasAccount = true;
          this.previewHasFan = true;
          this.previewPage = '/invite';
          break;
        case 'connect':
          this.previewHasAccount = true;
          this.previewHasFan = true;
          this.previewPage = '/follow';
          break;
      }
    },

    handlePreviousStep() {
      this.previewIsOpen = false;
      if (this.previousPage) {
        this.$router.push({
          path: this.previousPage,
          query: this.query,
        });
      }
      else {

        if (this.isNewCampaign) {
          this.bypassDirtyCheck = true;
          this.$router.push({
            path: this.setupBackPath,
            query: this.query,
          })
        } else {
          this.$router.push(this.sanitizedReferringPage);
        }
      }
    },

    handlePreviewClick() {
      this.previewIsOpen = !this.previewIsOpen;
    },

    async handleNextStep() {
      this.goToNextStep();
    },

    async goToNextStep() {
      const isValid = await this.$refs['campaign-edit'].$validator.validateAll();
      const areScopesValid = await this.$refs['campaign-edit'].$validator.validateScopes();

      if (!(isValid && areScopesValid)) {
        this.showError();
        const firstError = findFirstError();
        this.$scrollTo(firstError, 500, { offset: -160 });
        return;
      }

      if (this.nextPage) {
        this.$router.push({
          path: this.nextPage,
          query: this.query,
        });
      } else {
        try {
          this.isUpdatingCampaign = true;
          const editCampaignDTO = createEditCampaignDTO(this.editCampaign);
          let res;
          this.bypassDirtyCheck = true;

          if (this.freeCampaign && editCampaignDTO.type === 'competition') {
            this.$arNotification.push({ type: 'error', message: 'Competition type is reserved for gamified campaigns' });
            this.$router.push({ path: '/campaigns/' });
            return;
          }

          if (this.freeCampaign) {
            editCampaignDTO.presentation.flow = ['register'];
            editCampaignDTO.subscriptionLevel = 'free'
          } else {
            // Exclude share page from flow if there are no share platforms enabled
            const isSharePageEnabled = !!editCampaignDTO.socialActions.shares?.facebook?.enabled || !!editCampaignDTO.socialActions.shares?.linkedin?.enabled || !!editCampaignDTO.socialActions.shares?.twitter?.enabled
            if (!isSharePageEnabled) {
              editCampaignDTO.presentation.flow = editCampaignDTO.presentation.flow.filter((flowItem) => { return flowItem !== 'share'})
            } else if (!editCampaignDTO.presentation.flow.includes('share')) {
              // Include 'share' again if there is at least one share platform enabled
              const shareIndex = editCampaignDTO.presentation.flow.findIndex((flowItem) => { return flowItem === 'invite'})
              if (shareIndex > -1) {
                editCampaignDTO.presentation.flow.splice(shareIndex, 0, 'share')
              }
            }
            editCampaignDTO.subscriptionLevel = 'regular'
          }

          if (this.isNewCampaign) {
            res = await this.CREATE_CAMPAIGN(editCampaignDTO);
          } else {
            res = await this.UPDATE_CAMPAIGN(editCampaignDTO);
          }

          if(!res) {
            console.error(`The result was null/undefined after ${this.isNewCampaign ? 'CREATE_CAMPAIGN' : 'UPDATE_CAMPAIGN'} was called.`, );
            this.$arNotification.push({ type: 'error', message: 'Sorry, there was an error. Please try again.' });
          } else if (res.oid) {
            sessionStorage.removeItem('_ar_promoter_new_campaign');

            // Sometimes the server just needs a moment to catch up and build stats. This is not strictly necessary,
            // but will help to reduce the number of false errors we get on campaign creation.
            if (this.isNewCampaign) {
              await new Promise(resolve => setTimeout(resolve, 1000));
            }
            this.$router.push({
              path: `/campaigns/${res.oid}/view/dashboard`
            });
          }
          this.bypassDirtyCheck = false;
        } catch (error) {
          // TODO: Display error using the notification component
          console.error(error);
          this.$arNotification.push({ type: 'error', message: 'Failed to update campaign' });
        } finally {
          this.isUpdatingCampaign = false;
        }
      }
    },

    async changeEvent(event) {
      let editCampaignDTO = createEditCampaignDTO(this.editCampaign);
      editCampaignDTO.eventOid = event.oid;
      this.setEditCampaign({
        eventOid: event.oid,
        event: event
      });
    },

    handleChangeEvent(event) {
      this.changeEvent(event);
    },

    showError() {
      this.$arNotification.push({ type: 'error', message: 'Could not save details, please review error messages' });
    },
    capitalize(word) {
      if (!word) {
        return word;
      }
      return capitalizeFirstLetter(word);
    },
  }
};
</script>

<style lang="scss" scoped>
.campaign-modify-layout {
  .wrapper {
    display: flex;
    padding: 90px 0 0 0;
    height: 100vh;
    > .left {
      display: flex;
      flex-direction: column;
      max-width: $lg-min;
      min-width: $md-min;
      width: 100%;
      height: 100%;
      background: #fff;
      .inner {

        flex-grow: 1;
        padding: 50px 80px;
        height: calc(100% - 110px);
        overflow-y: auto;
        .grey-text {
            color: $blueGrey700;
          }
          .ar-field {
            p {
              margin-bottom: 15px;
            }
          }
        .image-requirements-text {
          margin-top: 14px;
          color: $blueGrey600;
        }
      }

    }

    .navigation {
      position: relative;
      width: 100%;
      z-index: $zIndexHighest;
      height: 110px;
      padding: 30px 95px 30px 80px;
      border-top: 1px solid $skyBlueGrey500;
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .right {
      width: 100%;
      padding: 50px;
      height: 100%;

      .preview {
        max-height: 780px;
      }
    }


    &.sm-max {
      padding: 0;
      position: relative;
      display:block;

      .navigation {
        height: 76px;
        min-height: 76px;
        padding: 10px 12px;
        position: fixed;
        bottom: 0;
      }

      > .left {
        max-width: 100vw;
        min-width: 100vw;
        position: absolute;
        top: 66px;
        height:auto;

        .inner {
          padding: 30px 12px 90px;
          min-height: 0;
          overflow:scroll;
          height:auto;
          z-index:$zIndexRegular;
        }

      }
      .right {
        padding: 12px 12px 92px;
        justify-content: center;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh - 75px);
        background-color:#FFF;
        opacity:0;
        z-index: $zIndexLow;
        transition: opacity 0.2s;
        &.is-visible {
          opacity: 1;
          z-index: $zIndexHigh;
        }
      }
    }
  }
}
</style>
