<template>
  <am2-card-container>
    <div class="top-section">
      <section :class="[
      'filter-integration-card',
      cardSize,
    ]">
        <div class="left-section">
          <am2-heading
            type="h1"
            class="title"
            size="xs"
            :title="title"
          />
          <span v-if="number" class="number">
          {{ `(${number})` }}
        </span>
        </div>
        <div class="right-section">
          <ar-text v-if="syncStatus === 'synced'" class="description-text" size="xs" :text="syncedDate" />
          <ar-text v-if="syncStatus === 'failed'" class="description-text error" size="xs" :text="syncedError" />
          <am2-signal-tag v-if="syncStatus === 'synced'" text="Synced" clsss="tag" />
          <am2-signal-tag v-if="syncStatus === 'syncing'" text="Syncing" clsss="tag" />
          <am2-signal-tag v-if="syncStatus === 'removing'" text="Removing" clsss="tag" />
          <ar-simple-button
            v-if="syncStatus === 'not-synced' || syncStatus === 'failed'"
            class="button"
            :text="syncStatus === 'not-synced' ? 'Sync' : 'Retry'"
            :loading="loading"
            :disabled="disabled"
            @click="handleButtonClick" />
          <div class="icon-button">
            <am2-icon-button-dropdown
              v-if="syncStatus !== 'syncing' && syncStatus !== 'removing'"
              :icon-props="{
              name: 'menu',
            }"
              :items="actionItems"
              :dropdown-style="{
              width: '200px',
            }"
              @select="handleSettingOptionSelect"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-if="!!syncedErrorDetails" class="sub-section" :style="{padding: '10px 30px 20px'}">
      <ar-text class="description-text error" size="xs" multiple-lines :text="syncedErrorDetails" />
    </div>

  </am2-card-container>
</template>

<script>
export default {
  name: 'FilterIntegrationCard',

  props: {
    title: {
      type: String,
      default: null,
    },
    number: {
      type: [String, Number],
      default: null,
    },
    actionItems: {
      type: Array,
      default: () => [],
    },
    buttonText: {
      type: String,
      default: null,
    },
    syncStatus: {
      type: String,
      default: 'not-synced',
      validator(value) {
        return [
          'not-synced',
          'synced',
          'failed',
          'syncing',
          'removing',
        ].includes(value);
      },
    },
    syncedDate: {
      type: String,
      default: null,
    },
    syncedError: {
      type: String,
      default: null,
    },
    syncedErrorDetails: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cardSize: {
      type: String,
      default: 'regular',
    }
  },

  methods: {
    handleSettingOptionSelect(item) {
      this.$emit('itemSelect', item);
    },
    handleButtonClick() {
      this.$emit('buttonClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-integration-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 34px 30px;

  .left-section {
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
      line-height: 1;
      max-width: 200px;
    }

    .number {
      position: relative;
      top: -2px;
      color: $blueGrey700;
      font-size: 14px;
      margin-left: 4px;
      line-height: 1;
    }
  }

  .right-section {
    position: absolute;
    top: 15px;
    right: 32px;
    display: inline-flex;
    align-items: center;
    height: 50px;

    .button {
      width: 100px;
    }

    .description-text {
      color: $blueGrey700;
      margin-right: 21px;
      max-width: 250px;

      &.error {
        color: $red500;
      }
    }

    .icon-button {
      width: 20px;
      margin-left: 3px;
    }
  }
}
</style>
