import { Module } from "vuex";
import { RootState } from '@/store/modules/types';
import { AdminState } from '@/store/modules/admin/types';
import { adminActions} from '@/store/modules/admin/actions';
import { clone, mergeObjects } from '@/utils/helpers';

export const initialAdminState = (): AdminState => ({
  // Promoters
  promoters: [],
  isFetchingPromoters: false,
  totalPromotersCount: 0,

  // PromoterAccounts
  promoterAccounts: [],
  isFetchingPromoterAccounts: false,
  totalPromoterAccountsCount: 0,
  masqueraderPromoterAccounts: [],
  promoterAccountOwner: null,

  // Current promoter
  currentPromoter: null,
  isFetchingCurrentPromoterAccount: false,
  currentPromoterRegionSettings: null,

  // Message tasks
  fanMessageTasks: [],
  isFetchingFanMessageTasks: false,
  noMoreFanMessageTasks: false,
  hasFailedFetchingFanMessageTasks: false,

  // Promoter message tasks overview
  promoterMessageTasks: [],
  isFetchingPromoterMessageTasks: false,
  hasFailedFetchingPromoterMessageTasks: false,

  adminStats: {
    scheduledMessageCount: 0,
    sendingMessageCount: 0,
    failedMessageCount: 0,
  },
  isFetchingAdminStats: false,

  defaultUserRoles: null,
  promoterUserRoles: null,

  // Twilio
  twilioSubAccounts: [],
  isFetchingTwilioSubAccounts: false,

  twilioSMSSender: [],
  isFetchingTwilioSMSSender: false,


  twilioSMSSenderEnabledCompanies: null,
  isFetchingTwilioSMSSenderEnabledCompanies: false,
  
});

const adminModule: Module<AdminState, RootState> = {
  namespaced: true,
  state: initialAdminState,
  actions: adminActions,
  mutations: {
    // Promoter
    SET_PROMOTERS(state, promoters: PromoterAccount[]) {
      state.promoters = clone(clone(state.promoters.concat(promoters)));
    },
    RESET_PROMOTERS(state) {
      state.promoters = [];
    },
    SET_TOTAL_PROMOTERS_COUNT(state, count: number) {
      state.totalPromotersCount = count;
    },
    SET_IS_FETCHING_PROMOTERS(state, isFetching: boolean) {
      state.isFetchingPromoters = isFetching;
    },
    // Promoter Accounts
    SET_PROMOTER_ACCOUNTS(state, promoterAccounts: PromoterAccount[]) {
      state.promoterAccounts = clone(clone(state.promoterAccounts.concat(promoterAccounts)));
    },
    RESET_PROMOTER_ACCOUNTS(state) {
      state.promoterAccounts = [];
    },
    SET_TOTAL_PROMOTER_ACCOUNTS_COUNT(state, count: number) {
      state.totalPromoterAccountsCount = count;
    },
    SET_IS_FETCHING_PROMOTER_ACCOUNTS(state, isFetching: boolean) {
      state.isFetchingPromoterAccounts = isFetching;
    },
    SET_CURENT_PROMOTER_ACCOUNT(state, promoterAccount: PromoterAccount) {
      state.currentPromoter = clone(promoterAccount);
    },
    SET_IS_FETCHING_CURRENT_PROMOTER_ACCOUNT(state, isFetching: boolean) {
      state.isFetchingCurrentPromoterAccount = isFetching;
    },
    RESET_MASQUERADER_PROMOTER_ACCOUNTS(state) {
      state.masqueraderPromoterAccounts = [];
    },
    SET_MASQUERADER_PROMOTER_ACCOUNTS(state, promoterAccounts: PromoterAccount[]) {
      state.masqueraderPromoterAccounts = clone(promoterAccounts);
    },
    ADD_TO_MASQUERADER_PROMOTER_ACCOUNTS(state, promoterAccount: PromoterAccount) {
      state.masqueraderPromoterAccounts = clone(state.masqueraderPromoterAccounts.concat([promoterAccount]));
    },
    REMOVE_FROM_MASQUERADER_PROMOTER_ACCOUNTS(state, oid: number) {
      state.masqueraderPromoterAccounts =
        clone(state.masqueraderPromoterAccounts.filter(account => account.oid !== oid));
    },
    RESET_PROMOTER_ACCOUNT_OWNER(state) {
      state.promoterAccountOwner = null;
    },
    SET_PROMOTER_ACCOUNT_OWNER(state, promoterAccount: PromoterAccount) {
      state.promoterAccountOwner = clone(promoterAccount);
    },

    // Message Tasks
    RESET_ADMIN(state) {
      state = initialAdminState();
    },
    RESET_ADMIN_FAN_MESSAGES(state) {
      state.fanMessageTasks = [];
      state.adminStats = {
        scheduledMessageCount: 0,
        sendingMessageCount: 0,
        failedMessageCount: 0,
      };
      state.isFetchingFanMessageTasks = false;
      state.noMoreFanMessageTasks = false;
      state.hasFailedFetchingFanMessageTasks = false;
    },
    SET_IS_FETCHING_ADMIN_FAN_MESSAGE_TASKS(state, isFetching: boolean) {
      state.isFetchingFanMessageTasks = isFetching;
    },
    SET_NO_MORE_ADMIN_FAN_MESSAGE_TASKS(state, noMore: boolean) {
      state.noMoreFanMessageTasks = noMore;
    },
    SET_HAS_FAILED_FETCHING_ADMIN_FAN_MESSAGE_TASKS(state, isFetching: boolean) {
      state.hasFailedFetchingFanMessageTasks = isFetching;
    },
    REMOVE_MESSAGE_FROM_ADMIN_FAN_MESSAGE_TASKS(state, messageOid: number) {
      state.fanMessageTasks = clone(state.fanMessageTasks.filter((msg:Message) => msg.oid !== messageOid));
    },
    SET_ADMIN_FAN_MESSAGE_TASKS(state, tasks: Message[]) {
      state.fanMessageTasks = clone(tasks);
    },
    CONCAT_ADMIN_FAN_MESSAGE_TASKS(state, tasks: Message[]) {
      state.fanMessageTasks = clone(state.fanMessageTasks.concat(tasks))
    },

    SET_PROMOTER_MESSAGE_TASKS(state, tasks: Message[]) {
      state.promoterMessageTasks = clone(tasks);
    },
    SET_IS_FETCHING_PROMOTER_MESSAGE_TASKS(state, isFetching: boolean) {
      state.isFetchingPromoterMessageTasks = isFetching;
    },
    SET_HAS_FAILED_FETCHING_PROMOTER_MESSAGE_TASKS(state, isFetching: boolean) {
      state.hasFailedFetchingPromoterMessageTasks = isFetching;
    },

    SET_TOTAL_ADMIN_STATS(state, stats: {
      scheduledMessageCount: number;
      sendingMessageCount: number;
      failedMessageCount: number;
    }) {
      state.adminStats = stats;
    },
    SET_IS_FETCHING_ADMIN_STATS(state, isFetching: boolean) {
      state.isFetchingAdminStats = isFetching;
    },

    SET_DEFAULT_USER_ROLES(state, defaultUserRoles: UserRole[]) {
      state.defaultUserRoles = defaultUserRoles
    },

    SET_PROMOTER_USER_ROLES(state, promoterUserRoles: UserRole[]) {
      state.promoterUserRoles = promoterUserRoles
    },

    SET_PROMOTER_REGION_SETTINGS(state, promoterRegionSettings: RegionSettings) {
      state.currentPromoterRegionSettings = promoterRegionSettings
    },

    // Twilio Subaccounts
    SET_TWILIO_SUBACCOUNTS(state, twilioSubAccounts: TwilioSubAccount[]) {
      state.twilioSubAccounts = clone(twilioSubAccounts);
    },
    RESET_TWILIO_SUBACCOUNTS(state) {
      state.twilioSubAccounts = [];
    },
    SET_IS_FETCHING_TWILIO_SUBACCOUNTS(state, isFetching: boolean) {
      state.isFetchingTwilioSubAccounts = isFetching;
    },

    // Update Twilio Subaccount
    SET_TWILIO_SUBACCOUNT(state, { twilioSubAccountId, twilioSubAccountData}) {
      let clonedTwilioSubAccounts: TwilioSubAccount[] = clone(state.twilioSubAccounts);
      clonedTwilioSubAccounts = clonedTwilioSubAccounts.map(twilioSubAccount => {
        return twilioSubAccount.oid === twilioSubAccountId ? mergeObjects(twilioSubAccount, twilioSubAccountData) : twilioSubAccount;
      });

      state.twilioSubAccounts = clonedTwilioSubAccounts;
    },

    REMOVE_FROM_TWILIO_SUB_ACCOUNTS(state, oid: number) {
      state.twilioSubAccounts =
        clone(state.twilioSubAccounts.filter(subAccount => subAccount.oid !== oid));
    },

    // Twilio SMS Senders
    SET_SMS_SENDER_IDS(state, twilioSMSSender: TwilioSMSSender[]) {
      state.twilioSMSSender = clone(twilioSMSSender);
    },
    ADD_TO_SMS_SENDER_IDS(state, twilioSMSSender: TwilioSMSSender[]) {
      state.twilioSMSSender = clone(state.twilioSMSSender.concat(twilioSMSSender))
    },
    RESET_SMS_SENDER_IDS(state) {
      state.twilioSMSSender = [];
    },
    SET_IS_FETCHING_SMS_SENDER_IDS(state, isFetching: boolean) {
      state.isFetchingTwilioSMSSender = isFetching;
    },

    // Update Twilio Subaccount
    SET_SMS_SENDER_ID(state, { smsSenderOid, twilioSmsSenderData}) {
      let clonedTwilioSMSSender: TwilioSMSSender[] = clone(state.twilioSMSSender);
      clonedTwilioSMSSender = clonedTwilioSMSSender.map(smsSender => {
        return smsSender.oid === smsSenderOid ? mergeObjects(smsSender, twilioSmsSenderData) : smsSender;
      });

      state.twilioSMSSender = clonedTwilioSMSSender;
    },

    REMOVE_FROM_SMS_SENDER_IDS(state, oid: number) {
      state.twilioSMSSender =
        clone(state.twilioSMSSender.filter(smsSender => smsSender.oid !== oid));
    },

    // Twilio SMS Sender Enabled Companies
    SET_SMS_ENABLED_COMPANIES(state, twilioSMSSenderEnabledCompanies: TwilioSMSSenderEnabledCompanies[]) {
      state.twilioSMSSenderEnabledCompanies = clone(twilioSMSSenderEnabledCompanies);
    },
    ADD_TO_SMS_ENABLED_COMPANIES(state, twilioSMSSenderEnabledCompanies: TwilioSMSSenderEnabledCompanies[]) {
      state.twilioSMSSenderEnabledCompanies = clone(state.twilioSMSSenderEnabledCompanies.concat(twilioSMSSenderEnabledCompanies))
    },
    RESET_SMS_ENABLED_COMPANIES(state) {
      state.twilioSMSSenderEnabledCompanies = [];
    },
    SET_IS_FETCHING_SMS_ENABLED_COMPANIES(state, isFetching: boolean) {
      state.isFetchingTwilioSMSSenderEnabledCompanies = isFetching;
    },

    // Update Twilio Subaccount
    SET_SMS_ENABLED_COMPANY(state, { smsSenderOid, twilioSmsSenderData}) {
      let clonedTwilioSMSSenderEnabledCompanies: TwilioSMSSenderEnabledCompanies[] = clone(state.twilioSMSSenderEnabledCompanies);
      clonedTwilioSMSSenderEnabledCompanies = clonedTwilioSMSSenderEnabledCompanies.map(smsSender => {
        return smsSender.oid === smsSenderOid ? mergeObjects(smsSender, twilioSmsSenderData) : smsSender;
      });

      state.twilioSMSSenderEnabledCompanies = clonedTwilioSMSSenderEnabledCompanies;
    },

    REMOVE_FROM_SMS_ENABLED_COMPANIES(state, oid: number) {
      state.twilioSMSSenderEnabledCompanies =
        clone(state.twilioSMSSenderEnabledCompanies.filter(smsSender => smsSender.oid !== oid));
    },
  },
}

export default adminModule;
