var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"domain-modal",attrs:{"is-open":_vm.isShow,"header":"Domain authentication","width":_vm.page === 'create' ? '507px' : '964px',"mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[(!!_vm.currentData)?_c('div',{class:[
        'domain-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[(_vm.page === 'create')?_c('ar-field',{staticClass:"domain-modal-body-row",attrs:{"label":"Domain you will send from","error-messages":[
          _vm.veeErrors.first('domain') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|domain-strict'),expression:"'required|domain-strict'"}],attrs:{"name":"domain","data-vv-name":"domain","data-vv-as":"domain","placeholder":"e.g businessname.com"},model:{value:(_vm.currentData.domain),callback:function ($$v) {_vm.$set(_vm.currentData, "domain", $$v)},expression:"currentData.domain"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
            color: _vm.$arStyle.color.skyBlueGrey600
          }),attrs:{"size":"xs","text":"Please enter your domain in the format <strong>businessname.com</strong> - do not include <strong>www</strong> or <strong>https://</strong>","multiple-lines":"","allow-html":""}})],1):_c('div',[_c('ar-text',{staticClass:"u-margin-bottom-5",attrs:{"text":"Add these records to your host’s DNS section","size":"xs","weight":"bold"}}),_vm._v(" "),(!!_vm.currentData)?_c('div',{staticClass:"domain-table-section"},[_c('table',{staticClass:"domain-table"},[_c('tr',{staticClass:"header"},[_c('th',{staticClass:"table-heading"},[_vm._v("Type")]),_vm._v(" "),_c('th',{staticClass:"table-heading"},[_vm._v("Host")]),_vm._v(" "),_c('th',{staticClass:"table-heading"},[_vm._v("Points to")]),_vm._v(" "),_c('th',{staticClass:"table-heading"})]),_vm._v(" "),_vm._l((_vm.currentData.hostRecords),function(item,key){return _c('tr',{key:key,staticClass:"domain"},[_c('td',[_c('ar-text',{style:({
                    textTransform: 'uppercase',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '60px',
                  }),attrs:{"size":"xs","text":item.type}})],1),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-data"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                      content: item.host,
                      classes: ['tooltip']
                    }),expression:"{\n                      content: item.host,\n                      classes: ['tooltip']\n                    }",modifiers:{"top":true}}],style:({
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '228px',
                    }),attrs:{"size":"xs","text":item.host}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-left-3",style:({
                      height: '32px',
                      width: '83px',
                    }),attrs:{"outlined":true,"text":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['hostCopy']) ? 'Copied' : 'Copy',"type":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['hostCopy']) ? 'green' : 'purple'},on:{"click":function () { return _vm.copyItem(key, 'hostCopy', item.host); }}})],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-data"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                      content: item.data,
                    }),expression:"{\n                      content: item.data,\n                    }",modifiers:{"top":true}}],style:({
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '228px',
                    }),attrs:{"size":"xs","text":item.data}}),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-left-3",style:({
                      height: '32px',
                      width: '83px',
                    }),attrs:{"outlined":true,"text":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['dataCopy']) ? 'Copied' : 'Copy',"type":(_vm.hostRecordsMatrix && !!_vm.hostRecordsMatrix[key]['dataCopy']) ? 'green' : 'purple'},on:{"click":function () { return _vm.copyItem(key, 'dataCopy', item.data); }}})],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-data"},[(!!item.valid)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: 'Record has been verified'}),expression:"{content: 'Record has been verified'}",modifiers:{"top":true}}],style:({
                      border: ("2px solid " + (_vm.$arStyle.color.green500)),
                      borderRadius: "50%",
                      padding: "2px",
                      width: '23px',
                      height: '23px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    })},[_c('ar-icon',{attrs:{"name":"check","height":"11px","color":_vm.$arStyle.color.green500}})],1):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({content: 'Record not verified'}),expression:"{content: 'Record not verified'}",modifiers:{"top":true}}],style:({
                      border: "2px solid #FF8B25",
                      borderRadius: "50%",
                      padding: "2px",
                      width: '23px',
                      height: '23px'
                    })},[_c('ar-text',{style:({color: '#FF8B25', cursor: 'default'}),attrs:{"size":"15px","text":"!","weight":"bold","align":"center"}})],1)])])])})],2)]):_vm._e(),_vm._v(" "),_c('ar-simple-button',{staticClass:"u-margin-left-3",style:({
            height: '32px',
            width: '106px',
            marginTop: '10px',
            marginLeft: 0,
            float: 'right',
          }),attrs:{"outlined":true,"text":_vm.copyAllText,"type":_vm.copyAllText === 'Copied' ? 'green' : 'purple'},on:{"click":_vm.handleCopyAllClick}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{class:[
        'domain-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticClass:"connection-guide"},[_c('ar-link-button',{attrs:{"text":"View our connection guide"},on:{"click":function () { return _vm.connectionGuideClick(); }}}),_vm._v(" "),_c('ar-icon',{staticClass:"u-margin-left-1",attrs:{"name":"external-link-bold","width":"16px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('div',[(_vm.page === 'verify')?_c('ar-checkbox',{staticClass:"u-margin-right-7",attrs:{"label":"Records have been added","data-test-id":"confirm-records-added"},model:{value:(_vm.confirmRecord),callback:function ($$v) {_vm.confirmRecord=$$v},expression:"confirmRecord"}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":_vm.page === 'create' ? 'Continue' : 'Verify',"disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }