<template>
  <section
    :class="['events-wrapper', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
    v-infinite-scroll="loadMoreEvents"
    infinite-scroll-disabled="infiniteScrollDisabled"
    :infinite-scroll-distance="10"
  >
    <am2-unlock-prompt
      v-if="!isFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Events` : `Events`"
      :subtitle="$arMediaQuery.pageContent.minWidth('sm') ? `See all your events from the different ticketing platforms you use. <a href='https://audiencerepublic.com/events' target='_blank' style='color: #FFF;'>Learn more</a>` : `See all your events from different ticketing platforms. <a href='https://audiencerepublic.com/events' target='_blank' style='color: #FFF;'>Learn more</a>`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Upgrade Plan` : `Upgrade`"
      @click="goToPlans"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 1,
      }"
    />

    <div
      class="events"
      :class="[
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      ]"
    >
      <div
        :class="[
          'events-page-top',
          $arMediaQuery.window.minWidth('md') && 'window-md-min',
        ]"
      >
        <am2-top
          :dropdown="dropdown"
          :button="{
            action: () => { navigateToNewEventCreation() },
            text: $arMediaQuery.pageContent.minWidth('sm') ? 'Create event' : 'Create',
            iconName: 'circle-plus',
            iconDistance: '9px',
            type: 'split',
            classes: ['top-create-button'],
            options: [
              {name: 'Import events', action: toggleMassImport},
            ],
          }"
          :search="{ action: serverSideSearch, value: queryParams.searchString, placeholder: searchPlaceholderText }"
          title="Events"
          :class="[
            'message-listing-hero-container',
            $arMediaQuery.window.minWidth('md') && 'window-md-min',
          ]"
          :dropdown-style="{ width: '240px' }"
        />
      </div>

      <!-- TEMPORARILY HIDE TICKET STATS BAR AND SHOW HORIZONTAL LINE -->
      <ar-divider
        :style="{
          marginTop: $arMediaQuery.window.minWidth('md') ? '30px' : '22px',
        }"
      />
      <div
        v-if="!noEvents || isDateRangeFilter"
        :class="[
          'events-controls',
          $arMediaQuery.pageContent.maxWidth('sm') && 'md-max',
          $arMediaQuery.pageContent.maxWidth('xs') && 'sm-max',
          $arMediaQuery.window.minWidth('md') && 'window-md-min',
        ]"
      >
        <div class="overall-details">
          <ar-text
            v-if="eventsCount"
            size="xs"
            :text="eventsShowingString"
            allow-html
          />
        </div>
        <div :class="['events-controls__subcontrol', $arMediaQuery.pageContent.maxWidth('sm') && 'md-max', $arMediaQuery.pageContent.maxWidth('xs') && 'sm-max']">
          <div class="events-controls-wrapper">
            <DateRangeDropdown
              testing-id="events-list"
              :default-selected-value="selectedFilterDateRange"
              :default-condition="dateRangeFilter.condition"
              @date-filter="handleDateFilter"
            />

            <ar-simple-select
              class="order-dropdown"
              :items="dropdownOrderByItems.items"
              :default-select-index="eventOrderSelectedIndex"
              :style="{
                width: '200px',
              }"
              :dropdown-style="{
                padding: '8px 0'
              }"
              @select="handleDropdownOrderByItemsSelect"
            />

            <am2-button-group-tabs
              v-if="$arMediaQuery.pageContent.minWidth('sm')"
              :active-index="eventsFilterGroupButton === 'list' ? 1 : 0"
              :items="groupButtonsSelectItems"
              :style="{
                width: '100px',
              }"
              @select="handleGroupButtonSelectClick"
              data-test-id="list-type-tabs"
            />
          </div>
        </div>
      </div>

      <div
        :style="{
          marginTop: $arMediaQuery.pageContent.minWidth('sm') ? '28px' : '22px',
        }"
        class="events-container"
        :class="eventsFilterGroupButton === 'tiles' ? 'tiles' : 'list'"
      >
        <am2-no-content-section
          v-if="noEvents && hasSearchConditions"
          header="No events found"
          header-size="sm"
          :style="{
            width: '100%',
          }"
        />
        <div v-else-if="noEvents && isDateRangeFilter" class="no-date-range-events-wrapper">
          <am2-no-content-section
            header="No events found for selected dates"
            header-size="sm"
            :style="{
              width: '100%',
            }"
          />
        </div>
        <div
          v-else-if="noEvents"
          class="no-events-wrapper"
        >
          <am2-no-content-section
            :class="[
              'no-event-section',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            icon-name="ar-form-in-circle"
            header="Create RSVP page"
            body="Capture registrations for your physical or virtual event"
            button-text="Create RSVP Page"
            :button-props="{
              iconName: null,
            }"
            @buttonClick="navigateToNewEventCreation"
          >
            <am2-tag
              class="free-tag"
              slot="top-right"
              text="Free"
              text-size="xxxs"
              shape="rectangle"
              type="dark-green"
            />
            <div class="footer-section" slot="footer">
              <div
                :class="[
                  'imgs-wrapper',
                  $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
                ]"
              >
                <div class="img-wrapper"><img src="~/assets/images/third-party-logos/zoom.png" alt="Zoom"></div>
                <div class="img-wrapper clubhouse"><span class="emoji">👋</span><img src="~/assets/images/third-party-logos/clubhouse.png" alt="Clubhouse"></div>
                <div class="img-wrapper vimeo"><img src="~/assets/images/third-party-logos/vimeo.png" alt="Vimeo"></div>
                <div class="img-wrapper"><img src="~/assets/images/third-party-logos/youtube.png" alt="Youtube"></div>
                <div class="img-wrapper"><img src="~/assets/images/third-party-logos/twitch.png" alt="Twitch"></div>
              </div>
            </div>
          </am2-no-content-section>
          <am2-no-content-section
            :class="[
              'no-event-section',
              $arMediaQuery.pageContent.maxWidth('lg') && 'lg-max',
              $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
            ]"
            icon-name="ar-event-in-circle"
            header="Connect your ticketing platforms"
            body="Sync all your events, attendees & orders so they’re in one place"
            button-text="Setup integrations"
            :button-props="{
              iconName: null,
            }"
            @buttonClick="navigateToIntegrations"
          >
            <am2-tag
              slot="top-right"
              text="Free"
              text-size="xxxs"
              shape="rectangle"
              type="dark-green"
              :style="{
                width: '45px',
              }"
            />
          </am2-no-content-section>
        </div>
        <div
          v-else
          :class="[
            'cards-container',
            $arMediaQuery.pageContent.minWidth('sm') ? eventsFilterGroupButton : 'tiles',
            $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
            $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
          ]"
        >
          <am2-event-card
            v-for="event in events"
            :key="event.oid"
            :event="event"
            :show-as="setShowAs"
            @delete="handleDeleteEvent"
            @merge="handleEventMerge"
            @ticketSalesDelete="handleTicketSalesDelete"
            @posOrdersDelete="handleTicketSalesDelete"
            :integration-is-possible="eventCanMerge(event)"
            :integration-data="getIntegrationData(event.provider)"
          />
          <am2-card-skeleton
            v-for="n in isFetchingEvents ? 6 : 0"
            :key="n"
            class="base-plan-card"
            :class="[
              $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
            ]"
            type="event"
            :show-as="$arMediaQuery.pageContent.minWidth('sm') ? eventsFilterGroupButton : 'tiles'"
            :level="$arMediaQuery.pageContent.maxWidth('xs') || eventsFilterGroupButton === 'list' ? (2 * n - 1) : (2 * Math.ceil(n / 3) - 1)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import moment from 'moment';
import accounting from 'accounting';
import DateRangeDropdown from './components/DateRangeDropdown'
import { isSyncing, isMerging } from '@/utils/event';
import {clone, defaultSettingsPath} from '@/utils/helpers';
import { setInterval, clearInterval } from 'timers';
import merge from 'deepmerge';
import checkLastCompletedTask from '@/mixins/checkLastCompletedTask'

const defaultQueryParams = {
  orderBy: 'datecreated',
  filter: 'all',
  showAs: 'tiles',
  top: 24
}

// helper anonymous function for deepmerge so arrays aren't combined
// when doing an object merge
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

export default {
  title: 'Events',
  layout: 'default',
  components: {
    DateRangeDropdown,
  },
  mixins: [checkLastCompletedTask],
  data() {
    return {
      promoterStats: null,
      searchString: '',
      skip: 0,
      queryParams: defaultQueryParams,
      filter: 'all',

      eventOrderByItems:
        [
          {
            name: 'Recently created',
            key: 'datecreated',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
          {
            name: 'Event date (recent first)',
            key: 'eventstartdatedesc',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
          {
            name: 'Event date (old first)',
            key: 'eventstartdateasc',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
          {
            name: 'Alphabetical order',
            key: 'alphabetically',
            action: (item, index) => this.setEventsOrderBy(item.key, index),
          },
        ],
      eventPoller: null,
      groupButtonsSelectItems: [
        {
          iconName: 'tile-view',
          showAs: 'tiles',
        },
        {
          iconName: 'list-view',
          showAs: 'list',
        },
      ],
    }
  },

  created() {
    this['segment/RESET_SCRATCH_SEGMENT']();
    this['integration/FETCH_ALL_INTEGRATIONS']();
  },

  computed: {
    ...mapGetters({
      hasPendingTasks: 'promoterTasks/hasPendingTasks',
    }),

    setShowAs() {
      return this.$arMediaQuery.pageContent.maxWidth('xs') ? 'tiles' : this.eventsFilterGroupButton
    },
    ...mapState({
      events: state => state.event.events,
      eventsCount: state => state.event.totalEventsCount,
      isFetchingEvents: state => state.event.isFetchingEvents,
      hasNoMoreEvents: state => state.event.hasNoMoreEvents,

      dateRangeFilter: state => state.event.eventsFilterDateRange,
      eventsFilterOrderByItems: state => state.event.eventsFilterOrderByItems,
      eventsFilterGroupButton: state => state.event.eventsFilterGroupButton,
      eventsFilterProvider: state => state.event.eventsFilterProvider,
      promoterOid: state => state.auth.account?.oid,


      integrations: state => state.integration.integrations,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      eventHasIntegration: 'event/eventHasIntegration',
      userPromoterOid: 'auth/userPromoterOid',
    }),
    eventsShowingString() {
      if (this.events.length === 0 || this.eventsCount === 0) return '';
      return `Showing <b>${ this.formattedEventsLength }</b> of <b>${ this.formattedTotalEventsCount }</b> ${ this.events.length > 1 ? ' events' : ' event'}`
    },
    noEvents() {
      return !this.isFetchingEvents && this.events.length === 0;
    },

    isDateRangeFilter() {
      return this.dateRangeFilter && this.dateRangeFilter.selected && this.dateRangeFilter.selected !== 'all';
    },

    hasSearchConditions() {
      return this.queryParams.filter !== 'all' || !!this.queryParams.searchString;
    },

    searchPlaceholderText() {
      return this.eventsCount && this.eventsCount !== 1 ? `Search ${this.formattedTotalEventsCount} events` : `Search events`;
    },

    formattedEventsLength() {
      return this.eventsCount ? accounting.format(this.events?.length || 0) : 0;
    },

    formattedTotalEventsCount() {
      return this.eventsCount ? accounting.format(this.eventsCount) : 0;
    },

    infiniteScrollDisabled() {
      return this.events.length === this.eventsCount;
    },

    eventOrderSelectedIndex() {
      return this.eventOrderByItems.findIndex(item => item.key === (this.eventsFilterOrderByItems || 'datecreated'));
    },

    selectedFilterDateRange() {
      return this.dateRangeFilter ? this.dateRangeFilter.selected : 'all';
    },

    eventsUndergoingActions () {
      return this.events.some(e =>
        this.eventHasIntegration(e, 'eventbrite') &&
        (isSyncing(e) || isMerging(e))
      );
    },

    dropdown() {
      let hardcodedObjects = [{
        name: 'All events',
        key: 'all',
        action: () => this.setEventProviderFilter('all'),
      }, {
        type: 'divider',
        key: 'divider'
      }]
      let generatedObjects = [];

      const targetCategories = ['ticketing', 'ecommerce', 'virtual_events']
      const activeIntegrations = this.integrations.filter(i =>
        i.categories.split(',').map(s => s.trim()).some(category =>
          targetCategories.includes(category)
        ) && i.enabled)

      for(const itm of activeIntegrations) {
        generatedObjects.push({
          name: itm.name ? itm.name : itm.charAt(0).toUpperCase() + itm.slice(1),
          key: itm.provider,
          action: () => this.setEventProviderFilter(itm.provider),
        })
      }

      return {
        title: 'All events',
        activeItemKey: this.eventsFilterProvider || 'all',
        items: [
          ...hardcodedObjects,
          ...generatedObjects
        ],
      };
    },

    dropdownOrderByItems() {
      const currentOrderByItem = this.dropDownOrderBySelectedItem()

      return {
        title: currentOrderByItem.name,
        items: this.eventOrderByItems,
      };
    },
  },

  async mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.queryParams = { ...this.$route.query };

      // any top value less than our default may cause an
      // unnecessary amount of extra FETCH_MORE_EVENTS actions to
      // be called; or worse, none at all.
      if (this.$route.query.top < defaultQueryParams.top) {
        this.queryParams.top = defaultQueryParams.top;
      }
      this.filter = this.queryParams?.filter;
    }
    this['auth/FETCH_PERMISSIONS']()

    this.getFiltersFromSessionStorage()
    await this.fetchEvents({});
  },

  beforeDestroy() {
    if(this.eventPoller !== null) {
      clearInterval(this.eventPoller);
    }
    this['event/RESET_EVENTS']();
  },

  methods: {
    ...mapActions([
      'event/FETCH_MORE_EVENTS',
      'event/FETCH_EVENT_COUNT',
      'OPEN_IMPORT_EVENT_DATA_MODAL',
      'OPEN_IMPORT_PRODUCT_DATA_MODAL',
      'auth/FETCH_PERMISSIONS',
      'integration/FETCH_ALL_INTEGRATIONS',
    ]),

    ...mapMutations([
      'segment/RESET_SCRATCH_SEGMENT',
      'event/REMOVE_EVENT',
      'event/RESET_EVENTS',
      'event/SET_EVENTS_FILTER_DATE_RANGE',
      'event/SET_EVENTS_FILTER_ORDER_BY_ITEMS',
      'event/SET_EVENTS_FILTER_GROUP_BUTTON',
      'event/SET_EVENTS_FILTER_PROVIDER',
    ]),

    saveFiltersToSessionStorage() {
      let filters = window.localStorage.getItem('events_page_filters');
      filters = filters ? JSON.parse(filters) : {};

      filters[this.userPromoterOid] = {
        dateRange: this.dateRangeFilter,
        orderBy: this.eventsFilterOrderByItems,
        group: this.eventsFilterGroupButton,
        provider: this.eventsFilterProvider,
      }
      window.localStorage.setItem('events_page_filters', JSON.stringify(filters));
    },

    getFiltersFromSessionStorage() {
      let item = window.localStorage.getItem('events_page_filters');
      item = item ? JSON.parse(item) : {};

      const filters = item[this.userPromoterOid]

      if (filters && filters.dateRange) this['event/SET_EVENTS_FILTER_DATE_RANGE'](filters.dateRange)
      if (filters && filters.orderBy) this['event/SET_EVENTS_FILTER_ORDER_BY_ITEMS'](filters.orderBy)
      if (filters && filters.group) this['event/SET_EVENTS_FILTER_GROUP_BUTTON'](filters.group)
      if (filters && filters.group) this['event/SET_EVENTS_FILTER_PROVIDER'](filters.provider)
      return filters;
    },

    handleDateFilter(payload) {
      this['event/RESET_EVENTS']()
      this['event/SET_EVENTS_FILTER_DATE_RANGE'](payload)
      this.fetchEvents({})
      this.saveFiltersToSessionStorage()
    },

    setQueryParams(newParams) {
      let newQueryParams = clone({
       ...this.queryParams,
       ...newParams
      });

      for(let k in newQueryParams) {
        if(!newQueryParams[k]) {
          delete newQueryParams[k]
        }
      }

      this.queryParams = newQueryParams

      // the below catch err stops a navigation
      // console error being thrown in the terminal.
      // this is a bug with vue router that is
      // currently being fixed. (Mar '21)g
      this.$router.push({
        query: {
          ...this.queryParams
        }
      }).catch(err => {})
    },

    eventCanMerge (event) {
      // Since not all eb events are in view, just use this as the condition for merging.
      //
      // TODO: We should add some vuex getters to determine whether or not a promoter has
      // connected to some integrations. This could be done by adding an 'integrations'
      // key to the session API endpoint response which would contain a list of all
      // connected integrations.
      return !(isSyncing(event) || isMerging(event));
    },

    handlePollingOfEvents() {
      if (this.eventPoller !== null && !this.eventsUndergoingActions) {
        clearInterval(this.eventPoller);
        this.eventPoller = null;
      } else if (this.eventPoller === null && this.eventsUndergoingActions) {
        // this condition can happen from a
        // REFRESH action fired from a different component
        this.pollEvents();
      }
    },

    async fetchEvents({ append = false, skip = undefined }) {
      if (this.isFetchingEvents || this.hasNoMoreEvents) return;

      const dateRange = this.dateRangeFilter && this.dateRangeFilter.condition && this.dateRangeFilter.condition.data || null

      this['event/FETCH_MORE_EVENTS']({
        ...this.queryParams,
        ...skip ? { skip } : null,
        dateRange: dateRange,
        orderBy: this.eventsFilterOrderByItems || 'datecreated',
        provider: this.eventsFilterProvider,
        reload: !append,
      })
      if (!append) {
        this['event/FETCH_EVENT_COUNT']({
          dateRange: dateRange,
          provider: this.eventsFilterProvider,
        })
      }

      this.handlePollingOfEvents()
    },

    // Remember to reload the events after you change the filter type
    setEventProviderFilter(provider) {
      this.filter = provider;

      this['event/SET_EVENTS_FILTER_PROVIDER'](provider)
      this.saveFiltersToSessionStorage()
      this['event/RESET_EVENTS']()
      this.fetchEvents({});
    },

    pollEvents() {
      if (this.eventPoller) {
        clearInterval(this.eventPoller);
      }

      this.eventPoller = setInterval(() => {
        this.fetchEvents({ append: false, skip: undefined, inPlaceUpdate: true });
      }, 3000);
    },

    async handleDeleteEvent(eventOid) {
      this['event/REMOVE_EVENT'](eventOid)
    },

    handleEventMerge() {
      this.fetchEvents({});
    },

    handleTicketSalesDelete() {
      this.setQueryParams({
        top: null,
      });
      this.fetchEvents({});
    },

    dropDownOrderBySelectedItem() {
      return this.eventOrderByItems.filter(item => item.key === this.eventsFilterOrderByItems)[0];
    },

    handleDropdownOrderByItemsSelect(item) {
      this['event/SET_EVENTS_FILTER_ORDER_BY_ITEMS'](item.key);
      this.fetchEvents({});
      this.saveFiltersToSessionStorage()
    },

    async setEventsOrderBy(orderBy, index) {

      this.setQueryParams({
        orderBy
      });

      this['event/RESET_EVENTS']()

      this.fetchEvents({});
    },

    async loadMoreEvents() {
      await this.fetchEvents({ append: true, skip: this.events.length });
    },

    async serverSideSearch(searchString) {
      if (searchString !== this.queryParams.searchString) {
        this['event/RESET_EVENTS']()
      };

      this.setQueryParams({
        top: null,
        searchString,
      });

      await this.fetchEvents({})
    },

    navigateToNewEventCreation() {
      this.$router.push({ path: '/events/new' });
    },

    navigateToIntegrations() {
      this.$router.push({ path: '/settings/integrations' });
    },

    toggleMassImport() {
      this.OPEN_IMPORT_EVENT_DATA_MODAL({
        isMassImport: true,
      });
    },

    toggleMassProductImport() {
      this.OPEN_IMPORT_PRODUCT_DATA_MODAL({
        isMassImport: false,
      });
    },

    handleGroupButtonSelectClick(item) {
      this['event/SET_EVENTS_FILTER_GROUP_BUTTON'](item.showAs);
      this.saveFiltersToSessionStorage()
    },

    async goToPlans() {
      const settingsPath = defaultSettingsPath(this.promoterOid)
      this.$router.push({
        path: settingsPath,
      });
    },

    actionAfterGetCompletedTask() {
      this.events = [];
      this.eventsCount = 0;
      this.fetchEvents({});
    },

    getIntegrationData(provider) {
      return this.integrations.find((integration) => {
        return integration.provider === provider
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.events-wrapper {
  margin: 0 auto;
  max-width: 1260px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 30px 100px;

  &.sm-max {
    padding: 0 30px 30px;
  }

  .events {
    width: 100%;

    .events-controls {
      .events-controls__subcontrol {
        .events-controls-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }

  .events-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .no-date-range-events-wrapper {
      width: 100%;
    }
  }

  .no-events-wrapper {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -20px;

    .no-event-section {
      width: calc(50% - 20px);
      margin-right: 20px;
      margin-bottom: 20px;

      &.lg-max {
        width: calc(100% - 20px);
      }

      &.xs-max {
        min-width: unset;
      }

      .free-tag {
        width: 45px;
      }

      .footer-section {
        max-width: 450px;
        margin: 0 auto;

        .imgs-wrapper {
          display: flex;
          justify-content: center;
          margin-right: -28px;
          margin-bottom: -15px;
          &.xs-max {
            flex-wrap: wrap;

            .img-wrapper {
              margin-bottom: 15px;
            }
          }
          .img-wrapper {
            position: relative;
            opacity: 0.2;
            margin-right: 28px;

            &.clubhouse {
              display: flex;
              align-items: center;
              position: relative;
              top: -5px;

              .emoji {
                position: relative;
                margin-right: 5px;
                top: -2px;
              }
            }

            &.vimeo {
              top: -3px;
            }
          }
        }
      }
    }
  }

  .cards-container.tiles {
    display: grid;
    grid-template-columns: 380px 380px 380px;
    grid-gap: 30px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

    @media screen and (max-width: 1350px) {
      grid-template-columns: 380px 380px;
      grid-gap: 30px;
    }

    &.md-max {
      grid-template-columns: 360px 360px;
      grid-gap: 20px 12px;

      .event-card {
        min-width: 360px;
        width: 360px;
      }

      .base-plan-card {
        width: 360px;
      }
    }

    &.sm-max {
      grid-gap: 12px;
      grid-gap: 20px;
      grid-template-columns: 100%;
    }

    &.xs-max {
      grid-gap: 18px;
      grid-template-columns: 100%;
    }

    .base-plan-card {
      width: 380px;
    }
  }

  .cards-container.list {
    width: 100%;

    .event-card {
      margin-bottom: 10px;
      width: 100%;
    }

    .base-plan-card {
      height: 100px;
      margin-bottom: 10px;

      &.md-max {
        height: 112px;
      }
    }
  }

  .events {
    &-page-top {
      margin: 30px 0 12px;
      // We want to use the media query here because we don't want the margin to change when filter pane is opened
      &.window-md-min {
        margin: 50px 0 15px 0;
      }
    }
    &-controls {
      display: flex;
      justify-content: space-between;
      margin-top: 4px;

      // We want to use the media query here because we don't want the margin to change when filter pane is opened
      &.window-md-min {
        margin-top: 30px;
      }

      &.md-max {
        margin-top: 0px;
        margin-bottom: 20px;
      }

      & .dropdown {
        flex: 2 1 auto;
        text-align: right;
        margin-right: 8px;
      }
      .overall-details {
        position: relative;
        font-weight: 500;
        margin-top: 10px;
      }

      &__subcontrol {
        display: inherit;
        justify-content: inherit;

        .order-dropdown {
          background: white;
          width: 260px;
          margin-right: 8px;
          border: 1px solid $blueGrey500;
        }

        &.sm-max {
          .order-dropdown {
            width: 100%;
            margin-right: 0;
          }
        }

        &.md-max {
          margin-top:10px;
        }
      }

      &.md-max {
        flex-direction:column;
        .overall-details {
          margin-top: 12px;
        }
      }
      &.sm-max {
        .overall-details {
          margin-top: 6px;
        }
      }
    }
    &-wrapper {
      margin-top: 27px;
    }
  }
}

.message-listing-hero-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start !important;
  justify-content: space-between;
  margin-bottom: 20px !important;
}
</style>
