<template>
    <am2-card-container 
      class="chart-container"
      layout="regular"
      :data-test-id="`delivery-bar-chart-${dataTestId}-container`"
    >
      <ar-text
        class="chart-title"
        size="xs"
        weight="bold"
        :text="title"
      />
      <div class="chart-wrapper">
        <apexcharts
          v-if="!loading"
          type="bar" 
          :height="chartHeight"
          :options="chartOptions" 
          :series="chartSeries"
          :data-test-id="`delivery-bar-chart-${dataTestId}`"
        />
        <div
          v-if="shortenCategories"
          class="see-all-container"
        >
          <span @click="expandCategories">
            <ar-text
              class="see-all"
              size="xs"
              :text="expandedCategories ? 'Hide' : 'See all'"
            />
          </span>
        </div>
      </div>
    </am2-card-container>
</template>

<script>
import BasicChart from '~/components/charts/base/BasicChart.vue';
import { sliceObject, capitalizeFirstLetter } from '@/utils/helpers';

export default {
  name: 'DeliveryBarChart',
  components: { BasicChart },

  props: {
    title: {
      type: String,
      default: '',
    },
    series: {
      type: Object,
      default: null,
    },
    visibleCategories: {
      type: Number,
      default: 5,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    expandedCategories: false,
  }),

  computed: {
    dataTestId() {
      return this.$arUtils.general.generateDataTestPrettyName(this.title);
    },

    chartDataAvailable() {
      return !this.loading && !!this.series && !!this.series.data;
    },

    // Use orderedSeries in both series and categories to have
    // the series ordered by value DESC
    orderedSeries() {
      if (!this.series.data) {
        return {}
      }

      return Object.entries(this.series.data)
        .sort(([,a],[,b]) => b-a) // DESC, use a-b for ASC
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    },

    categories() {
      if (this.chartDataAvailable) {
        return Object.keys(this.orderedSeries);
      }
      return [];
    },

    shortenCategories() {
      return this.categories.length > this.visibleCategories;
    },

    chartCategories() {
      const capitalizedCategories = this.categories.map(c => capitalizeFirstLetter(c))

      if (this.shortenCategories && !this.expandedCategories) {
        return capitalizedCategories.slice(0, this.visibleCategories);
      }
      return capitalizedCategories;
    },

    chartSeries() {
      if (this.chartDataAvailable) {
        const dataSeries = this.expandedCategories ? this.orderedSeries : sliceObject(this.orderedSeries, 0, this.visibleCategories)
        return [
          {
            name: this.series.name,
            data: Object.values(dataSeries),
          }
        ];
      }
      return [];
    },

    chartHeight() {
      return Math.max(this.chartCategories.length * 75, 125);
    },

    chartOptions() {
      return {
        chart: {
          height: `${this.chartHeight}px`,
          type: "bar",
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
        },
        tooltip: {
          enabkled: false,
          inverseOrder: true,
          y: {
            formatter: val => val + '%',
            title: {
              formatter: (v) => ""
            }
          },
          x: {
            show: false,
          }
        },
        colors: [this.$arStyle.color.purple500],
        plotOptions: {
          bar: {
            events: {
              click: () => null,
            },
            states: {
              hover: {
                filter: 'none',
                color: this.$arStyle.color.purple500
              }
            },
            animation: false,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            }
          }
        },
        xaxis: {
          max: 100,
          categories: this.chartCategories,
          labels: {
            formatter: val => val + '%',
          }
        },
      }
    },
  },

  methods: {
    expandCategories() {
      this.expandedCategories = !this.expandedCategories;
    }
  },
}
</script>

<style lang="scss" scoped>
  .chart-container {
    position: relative;
    width: 100%;

    padding: 25px;

    .chart-title {
      margin-bottom: 20px;
    }

    .see-all {
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      color: $purple500;
    }

    .see-all-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chart-wrapper {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
</style>