<template>
  <section>
    <am2-order-details-modal
      title="Order Details"
      :is-show="showOrderDetailsModal"
      @close="closeOrderDetailsClick"
      type="order-details-card"
      :orderData="order"
    />

    <am2-table
      ref="table"
      :heads="decoratedHead"
      :rows="body"
      :loading="loading"
      @sortBy="handleSortByChange"
      class="subscribers-table"
      :empty-text="emptyText"
      :enable-row-click="customerProfilesEnabled"
      :has-sticky-header="hasStickyHeader"
      @rowClick="handleRowClick"
      has-control-section
    >
      <div slot="name" slot-scope="{ data: { fanPromoterAccount, status, fanOid }, active }" class="cell left">
        <ar-avatar
          ref="avatar"
          class="avatar"
          :image-path="fanPromoterAccount.avatar"
          :name="determineName(fanPromoterAccount)"
          :color="audienceSelection.partlySelectedAudienceMap[fanOid] || active ? 'white' : 'grey'"
          :style="{
            marginRight: '10px',
            cursor: customerProfilesEnabled ? 'pointer' : null,
          }"
        />
        <ar-text
          class="name-text"
          size="xxs"
          :text="determineName(fanPromoterAccount)"
          :style="{
            cursor: customerProfilesEnabled ? 'pointer' : null,
            textDecoration: status == 'deleted' ? `line-through` : '',
            color: status == 'deleted' ? '#8492A6' : '',
          }"
        />
      </div>
      <div slot="qty" slot-scope="{ data: { sourceInfo, status } }" class="cell">
        <ar-text
          size="xxs"
          width="18.67px"
          :color="$arStyle.color.email"
          :text="getDisplayValueInQty(sourceInfo)"
          :style="{
            textDecoration: status == 'deleted' ? `line-through` : '',
            color: status == 'deleted' ? '#8492A6' : '',
          }"
        />
      </div>
      <div slot="orderTotal" slot-scope="{ data: { cost, status } }" class="cell">
        <ar-text
          size="xxs"
          :text="getDisplayValueInOrder(cost)"
          :style="{
            textDecoration: status == 'deleted' ? `line-through` : '',
            color: status == 'deleted' ? '#8492A6' : '',
          }"
        />
      </div>
      <div slot="orderId" slot-scope="{ data: { puid, status } }" class="cell">
        <ar-text
          size="xxs"
          :text="puid"
          :style="{
            textDecoration: status == 'deleted' ? `line-through` : '',
            color: status == 'deleted' ? '#8492A6' : '',
          }"
        />
      </div>
      <div slot="source" slot-scope="{ data: { provider } }" class="cell">
        <ar-text v-if="provider" size="xxs" :text="`${provider[0].toUpperCase()}${provider.substring(1)}`" />
      </div>
      <div slot="status" class="cell" slot-scope="{ data: { status } }">
        <div :class="['order-status', `order-${status}`]">
          <ar-text
            size="xxs"
            :text="formatStatus(status)"
            :style="{
              color: getColorBasedOnStatus(status),
            }"
            weight="bold"
          />
        </div>
      </div>
      <div slot="orderTime" slot-scope="{ data: { orderDate, status } }" class="cell">
        <ar-text
          size="xxs"
          :text="getOrderTimeFromNow(orderDate)"
          :style="{
            textDecoration: status == 'deleted' ? `line-through` : '',
            color: status == 'deleted' ? '#8492A6' : '',
          }"
          v-tooltip.bottom="{
            content: getOrderDateFormatted(orderDate),
          }"
        />
      </div>
    </am2-table>
  </section>
</template>

<script>
import moment from 'moment';
import { getFormattedDateTimeWithTimezone } from '@/utils/date/';
import { mapState } from 'vuex';

export default {
  name: 'PosOrdersTable',

  data() {
    return {
      showOrderDetailsModal: false,
      order: null,
    };
  },

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'No data',
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      audienceSelection: (state) => state.audience.audienceSelection,
    }),
    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },
    decoratedHead() {
      return this.head.map((item, index) => {
        let width;
        if (item.key === 'name') {
          width = 230;
        } else if (item.key === 'emailAddress') {
          width = 230;
        } else if (item.key === 'mobileNumber') {
          width = 150;
        } else if (item.key === 'lastMessageTime') {
          width = 180;
        } else {
          width = 136;
        }
        return {
          ...item,
          width,
          align: index == 0 ? 'left' : 'right',
        };
      });
    },
  },

  methods: {
    determineName(fanPromoterAccount) {
      if (fanPromoterAccount) {
        return fanPromoterAccount.name || fanPromoterAccount.firstName ? fanPromoterAccount.name || `${fanPromoterAccount.firstName || ''} ${fanPromoterAccount.lastName || ''}` : fanPromoterAccount.emailAddress
      } else {
        return 'Redacted'
      }
    },

    getDisplayValueInOrder(cost) {
      return cost?.displayValue;
    },

    getDisplayValueInQty(sourceInfo) {
      var qtySum = 0;
      if (sourceInfo) {
        sourceInfo.forEach((item) => {
          qtySum += item.quantity;
        });
      }
      return qtySum;
    },

    handleSortByChange(sortBy) {
      this.$emit('sortBy', sortBy);
    },

    handleUserClick(item) {
      this.showOrderDetailsModal = true;
      this.order = item;
    },

    handleRowClick(row, index) {
      this.$emit('rowClick', row, index);
    },

    closeOrderDetailsClick() {
      this.showOrderDetailsModal = false;
    },

    formatStatus(status) {
      let formattedStatus;
      if (status === 'deleted') {
        formattedStatus = 'Deleted';
      } else if (status === 'refunded') {
        formattedStatus = 'Refunded';
      } else if (status === 'completed') {
        formattedStatus = 'Completed';
      } else if (status === 'partially-refunded') {
        formattedStatus = 'Partially Refunded';
      } else if (status === 'transferred') {
        formattedStatus = 'Transferred';
      } else if (status === 'paid') {
        formattedStatus = 'Paid';
      } else {
        formattedStatus = 'Pending';
      }

      return formattedStatus;
    },

    getColorBasedOnStatus(status) {
      let color = this.$arStyle.color.skyBlueGrey700;
      if (status === 'refunded' || status === 'partially-refunded') {
        color = '#FFA95D';
      } else if (status === 'completed' || status === 'paid') {
        color = this.$arStyle.color.green500;
      }
      return color;
    },

    getOrderTimeFromNow(orderDate) {
      return moment.utc(orderDate).fromNow();
    },

    getOrderDateFormatted(orderDate) {
      if (!orderDate) {
        return null;
      }
      const date = new Date(orderDate);
      return date ? getFormattedDateTimeWithTimezone(date) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribers-table {
  .cell {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 12px;
    justify-content: flex-end;

    &-left {
      justify-content: flex-start;
    }
  }

  .order-status {
    background-color: $skyBlueGrey400;
    padding: 5px 10px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;

    &.order-completed,
    &.order-paid {
      background-color: $green200;
    }

    &.order-refunded,
    &.order-partially-refunded {
      background-color: $orange200;
    }
  }

  .channel {
    .icon {
      margin-right: 23px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .empty-dash {
    width: 14px;
    border-bottom: 1px solid $blueGrey600;
  }

  .name-text {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    >>> a {
      color: $blueGrey800;
    }
  }

  .tr:hover {
    .order-status {
      border: 1px solid white;
    }
  }
}
</style>
