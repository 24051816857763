<template>
  <am2-card-container
    class="conversion-stats-card"
    layout="regular"
  >
    <am2-typography-skeleton
      v-if="isLoading"
      size="lg"
    />
    <div class="conversion-stats-card__header" v-else>
      <ar-text
        size="lg"
        :text="value"
      />
    </div>
    <ar-text
      class="conversion-stats-card__description"
      size="xs"
      :text="description"
    />
  </am2-card-container>
</template>

<script>

export default {
  name: 'InsightsInfoCard',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    description: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.conversion-stats-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 25px;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__description {
    color: $blueGrey700;
    margin-top: 1em;
  }
}
</style>
