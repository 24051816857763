var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-dynamic-tags-section"},[(_vm.step === 1)?_c('div',{staticClass:"body"},[_c('div',{class:[
        'upload-csv-body',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3' ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isParsingCsvFile),expression:"!isParsingCsvFile"}]},[_c('ar-text',{attrs:{"size":"xs","text":"Dynamic tags allow you to personalize your messages (e.g. unique codes, custom urls, etc).","multiple-lines":""}}),_vm._v(" "),_c('am2-heading',{style:({
            marginTop: '28px',
            marginBottom: '4px',
          }),attrs:{"size":"xs","title":"Step 1","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"step-1-desc-section",style:({
            marginTop: '2px',
          })},[_c('ar-icon',{attrs:{"name":"download","color":_vm.$arStyle.color.purple500}}),_vm._v(" "),_c('ar-text',{style:({
              marginLeft: '4px',
            }),attrs:{"size":"xs","text":"<a>Download this csv file</a> containing all recipients of this message.","multiple-lines":"","line-height":"25px","allow-html":""},on:{"anchorClick":_vm.handleStepOneDescriptionAnchorClick}})],1),_vm._v(" "),_c('am2-heading',{style:({
            marginTop: '28px',
            marginBottom: '4px',
          }),attrs:{"size":"xs","title":"Step 2","weight":"bold"}}),_vm._v(" "),_c('ar-text',{attrs:{"size":"xs","text":"Add a new column for your dynamic tag and specify the info for each contact (the column header will be the name of your dynamic tag).","multiple-lines":"","line-height":"25px","allow-html":""}}),_vm._v(" "),_c('am2-heading',{style:({
            marginTop: '28px',
            marginBottom: '4px',
          }),attrs:{"size":"xs","title":"Step 3","weight":"bold"}}),_vm._v(" "),_c('ar-text',{attrs:{"size":"xs","text":"Upload the csv file below. Once uploaded you'll be able to insert tags into your message from the dynamic tags menu.","multiple-lines":"","line-height":"25px","allow-html":""}})],1),_vm._v(" "),_c('div',{staticClass:"dropzone-section"},[_c('am2-dropzone',{ref:"dropzone-wrapper",staticClass:"u-margin-top-5",attrs:{"file-type":['text/csv'],"file-size":100000000,"csv-options":{
          shortUrl: true,
        },"file-type-alias":"CSV file","placeholder-icon":{
          name: 'upload',
          width: '16px',
          height: '16px',
          color: _vm.$arStyle.color.purple500,
          wrapperStyle: {
            background: _vm.mouseOverCsvDropdown ? "white" : _vm.$arStyle.color.skyBlueGrey400,
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: _vm.mouseOverCsvDropdown ? '2px solid white' : null,
          },
        },"ratio":0.525,"data-test-id":"upload-dynamic-tags-dropzone"},on:{"uploadStart":function($event){_vm.isParsingCsvFile = true},"uploadEnd":function($event){_vm.isParsingCsvFile = false},"upload":_vm.handleCSVUploaded},nativeOn:{"mouseover":function($event){_vm.mouseOverCsvDropdown = true},"mouseleave":function($event){_vm.mouseOverCsvDropdown = false}}})],1)])]):(_vm.step === 2)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"error-message-body"},[_c('ar-icon',{attrs:{"name":"alert-exclamation-mark","height":"70px"}}),_vm._v(" "),_c('am2-heading',{style:({
          marginTop: '22px',
        }),attrs:{"size":"md","title":_vm.errorMessageTitle,"align":"center"}}),_vm._v(" "),_c('ar-text',{style:({
          marginTop: '14px',
        }),attrs:{"size":"xs","text":_vm.errorMessage,"multiple-lines":"","line-height":"25px","align":"center"}})],1)]):(_vm.step === 3)?_c('div',{staticClass:"body"},[_c('div',{staticClass:"csv-review-body"},[_c('ar-text',{attrs:{"size":"xs","text":"Verify that your dynamic fields are correct with the preview below.","multiple-lines":"","line-height":"25px"}}),_vm._v(" "),_c('am2-csv-review-table',{style:({
          marginTop: '24px',
        }),attrs:{"head":_vm.csvHeaders,"body":_vm.csvRows}})],1)]):_vm._e(),_vm._v(" "),(_vm.step === 1)?_c('div',{class:[
      'footer',
      'footer-for-step-one',
      _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-x-3' ]},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xs","text":"Tip: You can add more dynamic fields by adding more columns.","multiple-lines":""}})],1):_vm._e(),_vm._v(" "),(_vm.step === 3)?_c('div',{staticClass:"footer-for-step-four",attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{attrs:{"text":"Import Fields","loading":_vm.isImportingCsv,"data-test-id":"import-fields-button"},on:{"click":_vm.handleImportFieldsClick}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }