export const automationCardType = item => {
  switch (item?.key) {
    case 'purchased.ticket-to-event':
      return 'purchasedTicket';
    case 'purchased.product-from-store':
      return 'purchasedProduct';
    case 'campaign.registered':
      return 'registersForCampaign';
    case 'messaging.list.subscribed':
      return 'listSubscribed';
    case 'messaging.list.unsubscribed':
      return 'listUnsubscribed';
    case 'contact.tag.added':
      return 'triggerTagAdded';
    case 'contact.tag.removed':
      return 'triggerTagRemoved';
    case 'contact.birthday':
      return 'triggerBirthday';
    case 'webhook':
      return 'triggerWebhook';
    case 'send.sms':
      return 'sendSMS';
    case 'send.email':
      return 'sendEmail';
    case 'tag.add':
      return 'tagAdd';
    case 'tag.remove':
      return 'tagRemove';
    case 'if-else':
      return 'ifElse';
    case 'delay-by':
      return 'delayBy';
    case 'wait-until':
      return 'waitUntil';
    case 'list.add':
      return 'listAdd';
    case 'list.remove':
      return 'listRemove';
    case 'short-url.visited':
      return 'shortUrlVisited';
    case 'unique':
      return 'unique';
    case 'event-date':
      return 'eventDate'
    default:
      console.log('An unrecognised item or item key was received: ', item);
      return;
  }
};
