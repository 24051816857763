<template>
  <div class="progress-bar-container">
    <div class="title-and-limit-consumed">
      <p
        style="font-weight: bold;"
      >
        {{ title }}
    </p>
      <p>{{ limitConsumed }}</p>
    </div>
    <div class="progress-bar">
      <div
        class="progress-bar-fill"
        :style="{ width: progressPercentage + '%', backgroundColor: '#7344c0' }"
      ></div>
    </div>
    <p :style="{color: '#8e97a6'}">{{ limitRemaining }}</p>
  </div>
</template>

<script>

import accounting from 'accounting';

export default {
  name: 'EntitlementLimit',
  props: {
    title: {
      type: String,
      required: true
    },
    totalProgress: {
      type: Number,
      required: true
    },
    currentProgress: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressPercentage() {
      return (this.currentProgress / this.totalProgress) * 100;
    },
    limitRemaining() {
      return `${accounting.formatNumber(this.totalProgress - this.currentProgress)} Remaining`;
    },
    limitConsumed() {
      return `${accounting.formatNumber(this.currentProgress)} of ${accounting.formatNumber(this.totalProgress)}`;
    }
  }
}
</script>

<style scoped>
.title-and-limit-consumed {
  display: flex;
  justify-content: space-between;
}

.progress-bar-container {
  width: 100%;
  margin: 20px 0;
}

.progress-bar {
  width: 100%;
  background-color: #8e97a62b;
  border-radius: 5px;
  overflow: hidden;
  height: 5px;
  margin: 5px 0;
}

.progress-bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

h3 {
  margin: 0;
  font-size: 1.2em;
}

p {
  margin: 5px 0 0 0;
  font-size: 0.9em;
}
</style>
