<template>
  <section
    class="icon-title-section"
  >
    <div class="icon">
      <am2-provider-icon
        v-bind="iconProps"
      />
    </div>
    <section class="title-section">
      <am2-heading
        type="h1"
        :title="title"
        :multiple-lines="multipleLinesTitle"
        :size="textSize" />
      <p class="description">
        {{ description }}
      </p>
    </section>
  </section>
</template>

<script>
export default {
  name: 'IconTitleSection',
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    iconProps: {
      type: Object,
      required: true,
    },
    textSize: {
      type: String,
      default: 'lg',
      validator: function (value) {
        return ['xl', 'lg', 'md', 'sm', 'xs'].indexOf(value) !== -1;
      },
    },
    multipleLinesTitle: {
      type: Boolean,
      default: false,
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.icon-title-section {
  display: flex;
  align-items: flex-start;
  .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 21px;
  }

  .title-section {
    display: inline-block;

    .description {
      font-size: 16px;
      color: $blueGrey700;
      line-height: 1;
      margin-top: 16px;
    }
  }
}
</style>
