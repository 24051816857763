var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"users-page",attrs:{"data-test-id":"users-page"}},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreData),expression:"fetchMoreData"}],staticClass:"u-margin-top-6",style:({
      width: '100%',
    }),attrs:{"infinite-scroll-disabled":"disableFetchMoreMessages","infinite-scroll-distance":10}},[_c('am2-top',{class:[
        'top-wrapper',
        'u-margin-bottom-8',
        _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max' ],attrs:{"search":{
        action: _vm.serverSideSearch,
        placeholder: 'Search users by email, name or oid',
        value: _vm.searchString,
        style: {
          width: '350px',
        }
      },"title":"Users"}}),_vm._v(" "),(_vm.isFetchingPromoterAccounts && _vm.promoterAccounts.length === 0)?_c('div',{staticClass:"loading-container"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):_c('am2-table',{staticClass:"message-table",attrs:{"heads":_vm.tableHeadings,"rows":_vm.promoterAccounts,"has-sticky-header":"","enable-row-click":"","has-control-section":"","default-sort-by":_vm.sortBy},on:{"rowClick":_vm.handlePromoterRowClick,"sortBy":_vm.handleSortBy},scopedSlots:_vm._u([{key:"oid",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"number-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.oid}})],1)])}},{key:"emailAddress",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.emailAddress}})],1)])}},{key:"firstName",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.firstName}})],1)])}},{key:"lastName",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":data.lastName}})],1)])}},{key:"sysCtime",fn:function(ref){
      var data = ref.data;
return _c('div',{staticClass:"message-cell"},[_c('div',{staticClass:"body-container"},[_c('ar-text',{attrs:{"size":"xs","text":_vm.formattedDate(data.sysCtime)}})],1)])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }