var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-users"},[_c('am2-user-account-modal',{attrs:{"is-show":_vm.isUserAccountModalOpen,"data":_vm.accountBeingModified},on:{"cancel":_vm.closeEditModal,"handleUserRoleFilterInput":_vm.handleUserRoleFilterInput,"confirm":_vm.handleUpdateUserAccount}}),_vm._v(" "),_c('am2-invite-account-modal',{attrs:{"is-show":_vm.isInviteAccountModalOpen},on:{"cancel":_vm.closeInviteModal,"handleUserRoleFilterInput":_vm.handleUserRoleFilterInput,"confirm":_vm.handleInviteUserAccount}}),_vm._v(" "),_c('div',{staticClass:"headings u-margin-bottom-7"},[_c('div',[_c('ar-text',{staticClass:"u-margin-bottom-2",attrs:{"size":"md","text":"User management"}}),_vm._v(" "),_c('ar-text',{style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"xs","text":"Create, edit and delete users"}})],1),_vm._v(" "),_c('div',[_c('ar-simple-button',{style:({width: '120px'}),attrs:{"text":"New User","icon-name":"circle-plus","icon-distance":"10px"},on:{"click":_vm.handleInviteUserButtonPress}})],1)]),_vm._v(" "),_c('am2-table',{ref:"table",staticClass:"users-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.users,"loading":_vm.isFetchingUsers,"has-sticky-header":""},scopedSlots:_vm._u([{key:"user",fn:function(ref){
      var ref_data = ref.data;
      var firstName = ref_data.firstName;
      var lastName = ref_data.lastName;
      var emailAddress = ref_data.emailAddress;
return _c('div',{staticClass:"cell"},[_c('div',{staticClass:"subtitle"},[(firstName || lastName)?_c('ar-text',{staticClass:"name-text u-margin-bottom-2",attrs:{"size":"xs","text":((firstName || '') + " " + (lastName || ''))}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"name-text",style:({
            color: _vm.$arStyle.color.skyBlueGrey700
          }),attrs:{"size":firstName || lastName ? 'xxs' : 'xs',"text":("" + emailAddress)}})],1)])}},{key:"role",fn:function(ref){
          var ref_data = ref.data;
          var role = ref_data.role;
          var isInvite = ref_data.isInvite;
          var inviteStatus = ref_data.inviteStatus;
return _c('div',{staticClass:"cell"},[(!!isInvite)?_c('am2-tag',{staticClass:"tag",style:({
           textTransform: 'capitalize',
           marginRight: '16px'
        }),attrs:{"type":"purple","text":inviteStatus}}):_vm._e(),_vm._v(" "),_c('ar-text',{attrs:{"size":"xs","text":("" + role),"align":"right"}})],1)}},{key:"menu",fn:function(ref){
        var data = ref.data;
return _c('div',{class:['menu-cell']},[(!data.isInvite || _vm.isUserManagementPermitted)?_c('am2-icon-button-dropdown',{attrs:{"icon-props":{
          name: 'menu',
        },"items":_vm.menuOptions(data)},on:{"select":function (item) { return _vm.handleUserActionSelect(item.eventName, data); }}}):_vm._e()],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }