<template>
  <div class="mfa-page">
    <div class="mfa-page__line">
      <ar-text
        text="Multi-Factor Authentication"
      />

      <ar-simple-button
        :disabled="mfaForce && mfaEnabled"
        :text="buttonText"
        @click="handleClickButton"
      />
    </div>
    <div class="mfa-page__line" v-if="mfaEnabled && mfaForce">
      <ar-text
        text="You can't disable MFA if it's required by your organization."
        size="xs"
      />
    </div>

    <create-mfa-modal v-if="showMfaCreateModal" @close="showMfaCreateModal = false" />

    <delete-mfa-modal v-if="showMfaDeleteModal" @close="showMfaDeleteModal = false" />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DeleteMfaModal from '~/components/modals/auth/delete-mfa-modal/index.vue'
import CreateMfaModal from '~/components/modals/auth/create-mfa-modal/index.vue'

export default {
  components: {DeleteMfaModal, CreateMfaModal},
  data() {
    return {
      showMfaCreateModal: false,
      showMfaDeleteModal: false,
    }
  },
  computed: {
    ...mapState({
      mfaEnabled: state => state.auth.account.mfaEnabled,
      mfaForce: state => state.auth.account.mfaForce,
    }),
    isShowDisableButton() {
      return this.mfaEnabled && !this.mfaForce
    },
    buttonText() {
      return this.mfaEnabled ? 'Disable MFA' : 'Enable MFA'
    },
  },
  methods: {
    handleClickButton() {
      if (this.isShowDisableButton) {
        this.showMfaDeleteModal = true
      } else {
        this.showMfaCreateModal = true
      }
    },
  },
}
</script>

<style lang="scss">
.mfa-page {
  max-width: 800px;
  margin: 59px auto 0;

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
