var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-table-html',{staticClass:"top-links-table",attrs:{"heads":_vm.heads,"rows":_vm.rows,"row-key-name":"name","custom-head-padding":"0 30px","hide-borders":"","full-width":"","data-test-id":"top-links-table"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var name = ref.data.name;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell cell-left",attrs:{"data-test-id":("top-links-table-row-name-" + index)}},[_c('a',{attrs:{"href":name,"target":"_blank"}},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.purple500,
        }),attrs:{"size":"xs","text":name}})],1)])}},{key:"uniqueTotal",fn:function(ref){
        var uniqueTotal = ref.data.uniqueTotal;
        var index = ref.index;
return _c('div',{staticClass:"cell cell-link",style:({
      display: 'flex',
      justifyContent: 'flex-end',
    }),attrs:{"data-test-id":("top-links-table-row-uniqueTotal-" + index)},on:{"click":function($event){return _vm.onUniqueLinkClick(index)}}},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.purple500,
      }),attrs:{"size":"xs","text":uniqueTotal}})],1)}},{key:"total",fn:function(ref){
      var total = ref.data.total;
      var index = ref.index;
return (!_vm.reduced)?_c('div',{staticClass:"cell",style:({
      display: 'flex',
      justifyContent: 'flex-end',
    }),attrs:{"data-test-id":("top-links-table-row-reduced-" + index)}},[_c('ar-text',{attrs:{"size":"xs","text":total}})],1):_vm._e()}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }