<template>
  <component :is="IntegrationComponent" />
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import CustomAudienceIntegration from '~/pages/settings/integrations/facebook-custom-audiences/'
import FacebookMessengerIntegration from '~/pages/settings/integrations/facebook-messenger/'
import GoogleCustomAudiencesIntegration from '~/pages/settings/integrations/google-custom-audiences/'
import EventbriteIntegration from '~/pages/settings/integrations/eventbrite/'
import ShopifyIntegration from '~/pages/settings/integrations/shopify/'
import ZoomIntegration from '~/pages/settings/integrations/zoom/'
import PatreonIntegration from '~/pages/settings/integrations/patreon/'
import UniverseIntegration from '~/pages/settings/integrations/universe/'
import StripeIntegration from '~/pages/settings/integrations/stripe/'
import TicketekIntegration from '~/pages/settings/integrations/ticketek/'
import AxsIntegration from '~/pages/settings/integrations/axs/'
import MoshtixIntegration from '~/pages/settings/integrations/moshtix/'
import EventixIntegration from '~/pages/settings/integrations/eventix/'
import MemberfulIntegration from '~/pages/settings/integrations/memberful/'
import EventGeniusIntegration from '~/pages/settings/integrations/event-genius/'
import DiceIntegration from '~/pages/settings/integrations/dice/'
import HumanitixIntegration from '~/pages/settings/integrations/humanitix/'
import TryBookingIntegration from '~/pages/settings/integrations/try-booking/'
import TicketmasterIntegration from '~/pages/settings/integrations/ticketmaster/';
import MoshtixExportsIntegration from '~/pages/settings/integrations/moshtix-exports-new/';
import SeeTicketsBeneluxIntegration from '~/pages/settings/integrations/see-tickets-benelux/'
import TicketboothIntegration from '~/pages/settings/integrations/ticketbooth/'
import TicketspiceIntegration from '~/pages/settings/integrations/ticketspice/'
import TixrIntegration from '~/pages/settings/integrations/tixr-new/'
import ShowclixIntegration from '~/pages/settings/integrations/showclix/'
import Workato from '~/pages/settings/integrations/workato/'
import TicketTailorIntegration from '~/pages/settings/integrations/ticket-tailor/'
import TiktokCustomAudiencesIntegration from '~/pages/settings/integrations/tiktok-custom-audiences/'
import SeeTicketsUsIntegration from '~/pages/settings/integrations/see-tickets-us/';
import { isIntegrationEnabled } from "~/store/modules/integration/utils";

export default {
  name: 'IntegrationContainer',
  layout: 'default',

  computed: {
    ...mapState({
      integrations: state => state.integration.integrations,
      isFetchingIntegrations: state => state.integration.isFetchingIntegrations,
      currentIntegration: state => state.route.params.integration,
      promoter: state => state.auth.account,
    }),

    ...mapGetters({
      findIntegrationByKey: 'integration/findIntegrationByKey'
    }),

    IntegrationComponent() {
      switch (this.currentIntegration) {
        case 'eventbrite':
          return EventbriteIntegration;
        case 'facebook-messenger':
          return FacebookMessengerIntegration;
        case 'facebook-custom-audiences':
          return CustomAudienceIntegration;
        case 'shopify':
          return ShopifyIntegration;
        case 'zoom':
          return ZoomIntegration;
        case 'patreon':
          return PatreonIntegration;
        case 'memberful':
          return MemberfulIntegration;
        case 'universe':
          return UniverseIntegration;
        case 'stripe':
          return StripeIntegration;
        case 'axs':
          return AxsIntegration;
        case 'ticketek':
          return TicketekIntegration;
        case 'moshtix':
          return MoshtixIntegration;
        case 'eventix':
          return EventixIntegration;
        case 'event-genius':
          return EventGeniusIntegration;
        case 'dice':
          return DiceIntegration;
        case 'humanitix':
          return HumanitixIntegration;
        case 'tixr-new':
          return TixrIntegration;
        case 'see-tickets-uk':
        case 'tixr':
        case 'mailchimp':
          return Workato;
        case 'try-booking':
          return TryBookingIntegration
        case 'ticketmaster':
          return TicketmasterIntegration;
        case 'see-tickets-benelux':
          return SeeTicketsBeneluxIntegration;
        case 'ticketbooth':
          return TicketboothIntegration;
        case 'ticketspice':
          return TicketspiceIntegration;
        case 'showclix':
          return ShowclixIntegration;
        case 'ticket-tailor':
          return TicketTailorIntegration;
        case 'moshtix-exports-new':
          return MoshtixExportsIntegration;
        case 'tiktok-custom-audiences':
          return TiktokCustomAudiencesIntegration;
        case 'google-custom-audiences': 
          return GoogleCustomAudiencesIntegration;
        case 'see-tickets-us':
          return SeeTicketsUsIntegration;
        default:
          break;
      }
      return null;
    }
  },

  integrations() {
    return this.integrations;
  },

  async mounted() {
    await this['integration/FETCH_ALL_INTEGRATIONS']();

    const integration = this.findIntegrationByKey(this.currentIntegration);
    if ((!integration || !isIntegrationEnabled(integration, this.promoter)) && this.$route.path != '/settings/integrations') {
      await this.$router.replace({ path: '/settings/integrations' });
    }
  },

  methods: {
    ...mapActions([
      'integration/FETCH_ALL_INTEGRATIONS',
    ])
  }
};
</script>
