var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetchMoreFans),expression:"fetchMoreFans"}],staticClass:"insights-wrapper",attrs:{"infinite-scroll-disabled":"disableFetchMoreFans","infinite-scroll-distance":"100"}},[(_vm.seeAllTableDataKey)?_c('div',{staticClass:"see-all-table"},[_c('header',{staticClass:"header"},[_c('am2-step-link',{style:({
          marginBottom: '20px',
        }),attrs:{"text":"Audience Insights"},on:{"click":_vm.handleInsightLinkClick}}),_vm._v(" "),_c('div',{class:[
        'title',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max'
      ]},[_c('am2-heading',{attrs:{"type":"h1","size":_vm.$arMediaQuery.pageContent.maxWidth('xs') ? 'md' : 'lg',"title":_vm.seeAllTableTitle}})],1)],1),_vm._v(" "),(_vm.seeAllTableHeadings)?_c('am2-fan-table',{ref:"see-all-insights-table",staticClass:"see-all-table",attrs:{"head":_vm.seeAllTableHeadings,"body":_vm.seeAllTableDataForFanTable,"loading":(_vm.isPollingCurrentInsights || _vm.isFetchingAudience) && _vm.seeAllTableDataForFanTable.length === 0,"has-index":"","empty-text":"No audience data","tableType":"audience","rowKeyName":"puid","enable-row-click":_vm.customerProfilesEnabled},on:{"rowClick":_vm.handleRowClick}}):_vm._e(),_vm._v(" "),(_vm.isPollingCurrentInsights || _vm.isFetchingAudience)?_c('am2-loading-section',{style:({
        marginTop: '30px',
      })}):_vm._e()],1):_vm._e(),_vm._v(" "),(!_vm.seeAllTableDataKey)?_c('div',{class:[
      'chart-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[(_vm.isPermissionEnabled(['view-sales']))?_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock the highest value customers',
        message: 'See who is spending the most on tickets',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock the highest value customers',\n        message: 'See who is spending the most on tickets',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-small-table',{style:({ height: 'auto' }),attrs:{"loading":_vm.isPollingCurrentInsights,"locked":!_vm.isInsightsEnabled,"error":_vm.hasFetchInsightsFailed,"data":_vm.highestValueCustomerDatas,"is-value-currency":"","heading":"Top spending ticket buyers","tooltip":"Your customers that have spent the most on tickets across all your events","column-names":['NAME','TOTAL TICKET SALES'],"empty-text":"No data is available"},on:{"seeAll":function($event){return _vm.handleSeeAllClick('highestValueCustomers')}}})],1):_vm._e(),_vm._v(" "),(_vm.isPermissionEnabled(['view-sales']))?_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock the most loyal customers',
        message: 'Identify & reward your biggest supporters.',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock the most loyal customers',\n        message: 'Identify & reward your biggest supporters.',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-small-table',{style:({ height: 'auto' }),attrs:{"loading":_vm.isPollingCurrentInsights,"locked":!_vm.isInsightsEnabled,"error":_vm.hasFetchInsightsFailed,"data":_vm.mostLoyalCustomerDatas,"heading":"Repeat ticket buyers","tooltip":"Customers that have purchased tickets to the most events you’ve done","column-names":['NAME','# EVENTS'],"empty-text":"No data is available"},on:{"seeAll":function($event){return _vm.handleSeeAllClick('mostLoyalCustomers')}}})],1):_vm._e(),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock location insights',
        message: 'See where your customers are located',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock location insights',\n        message: 'See where your customers are located',\n      }"}],staticClass:"chart-container full-width",attrs:{"layout":"soft"}},[_c('am2-multiple-bar-charts',{ref:"locationChart",staticClass:"location-insights-chart",style:({ height: ((_vm.locationChartMaxHeight + 85) + "px") }),attrs:{"locked":!_vm.isInsightsEnabled,"loading":_vm.isPollingCurrentInsights,"title":"Location","topics":_vm.locationChartData.topics,"chart-datas":_vm.locationChartData.chartDatas,"topic-index":_vm.locationChartIndex,"chart-height":_vm.locationChartMaxHeight},on:{"topicChange":function (topic) { _vm.changeLocationGraphTopic(topic) }}}),_vm._v(" "),(_vm.showSeeMoreOnLocationChart && !_vm.isLoadingSeeMore)?_c('ar-text',{attrs:{"text":"<a style='text-decoration:underline;'>See more</a>","allow-html":"","size":"xs","align":"center"},on:{"anchorClick":_vm.increaseMaxSizeOfLocationGraph}}):(_vm.showSeeMoreOnLocationChart && _vm.isLoadingSeeMore)?_c('div',{style:({textAlign: 'center'})},[_c('am2-loading-bubble')],1):_vm._e()],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock age insights',
        message: 'See the age breakdown for your audience',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock age insights',\n        message: 'See the age breakdown for your audience',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-multiple-column-charts',{style:({ height: '462px' }),attrs:{"loading":_vm.isPollingCurrentInsights,"hide-switch-buttons":"","title":"Age","topics":_vm.ageChartData.topics,"chartDatas":_vm.ageChartData.chartDatas,"chart-height":467,"topic-index":0,"locked":!_vm.isInsightsEnabled}}),_vm._v(" "),(_vm.averageAge)?_c('am2-tag',{style:({ position: 'absolute', top: '30px', right: '30px', height: '32px', padding: '0 9px',  }),attrs:{"text":("Average: " + _vm.averageAge),"type":"purple","shape":"rectangle"}}):_vm._e()],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock gender insights',
        message: 'See the gender breakdown for your audience',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock gender insights',\n        message: 'See the gender breakdown for your audience',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-multiple-pie-charts',{style:({ height: '462px' }),attrs:{"locked":!_vm.isInsightsEnabled,"title":"Gender","hide-switch-buttons":"","loading":_vm.isPollingCurrentInsights,"topics":_vm.genderChartData.topics,"chart-data":_vm.genderChartData.chartData,"units":{
          singular: 'person',
          plural: 'people',
        },"topic-index":0,"chart-height":467}})],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock top referrers',
        message: 'Uncover key influencers to engage as ticket sellers',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock top referrers',\n        message: 'Uncover key influencers to engage as ticket sellers',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-small-table',{style:({ height: 'auto' }),attrs:{"loading":_vm.isPollingCurrentInsights,"locked":!_vm.isInsightsEnabled,"error":_vm.hasFetchInsightsFailed,"data":_vm.topReferrerDatas,"heading":"Top referrers","tooltip":"People who have referred the most friends to register for campaigns","columnNames":['NAME','REFERRALS'],"emptyText":"No referrals are available"},on:{"seeAll":function($event){return _vm.handleSeeAllClick('topReferrers')}}})],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock top artists',
        message: 'Discover the artists your audiences are listening to most',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock top artists',\n        message: 'Discover the artists your audiences are listening to most',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-small-table',{style:({ height: 'auto' }),attrs:{"loading":_vm.isPollingCurrentInsights,"locked":!_vm.isInsightsEnabled,"error":_vm.hasFetchInsightsFailed,"data":_vm.topArtistDatas,"heading":"Top artists","tooltip":"The artists that are most popular within your audience","column-names":['NAME','LISTENERS'],"empty-text":"No artists are available"},on:{"seeAll":function($event){return _vm.handleSeeAllClick('topArtists')}}})],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock top tracks',
        message: 'Discover the tracks your audiences are listening to most.',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock top tracks',\n        message: 'Discover the tracks your audiences are listening to most.',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-small-table',{style:({ height: 'auto' }),attrs:{"loading":_vm.isPollingCurrentInsights,"locked":!_vm.isInsightsEnabled,"error":_vm.hasFetchInsightsFailed,"data":_vm.topTrackDatas,"heading":"Top tracks","tooltip":"The tracks that are most popular within your audience","column-names":['NAME','LISTENERS'],"empty-text":"No tracks are available"},on:{"seeAll":function($event){return _vm.handleSeeAllClick('topTracks')}}})],1),_vm._v(" "),_c('am2-card-container',{directives:[{name:"ar-feature-mask",rawName:"v-ar-feature-mask",value:({
        show: !_vm.isInsightsEnabled,
        title: 'Unlock Top podcast shows',
        message: 'Discover the podcast shows your audiences are listening to most.',
      }),expression:"{\n        show: !isInsightsEnabled,\n        title: 'Unlock Top podcast shows',\n        message: 'Discover the podcast shows your audiences are listening to most.',\n      }"}],class:[
        'chart-container',
        _vm.$arMediaQuery.pageContent.maxWidth('md') && 'full-width' ],attrs:{"layout":"soft"}},[_c('am2-small-table',{style:({ height: 'auto' }),attrs:{"loading":_vm.isPollingCurrentInsights,"locked":!_vm.isInsightsEnabled,"error":_vm.hasFetchInsightsFailed,"data":_vm.topPodcastsDatas,"heading":"Top podcast shows","tooltip":"The podcast shows that are most popular within your audience","column-names":['NAME','LISTENERS'],"empty-text":"No podcast shows are available"},on:{"seeAll":function($event){return _vm.handleSeeAllClick('topPodcasts')}}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }