<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="domain-modal"
      header="Domain authentication"
      :width="page === 'create' ? '507px' : '964px'"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'domain-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
        v-if="!!currentData"
      >
        <ar-field
          v-if="page === 'create'"
          label="Domain you will send from"
          class="domain-modal-body-row"
          :error-messages="[
            veeErrors.first('domain'),
          ]"
        >
          <ar-input
            v-validate="'required|domain-strict'"
            v-model="currentData.domain"
            name="domain"
            data-vv-name="domain"
            data-vv-as="domain"
            placeholder="e.g businessname.com"
          />
          <ar-text
            size="xs"
            text="Please enter your domain in the format <strong>businessname.com</strong> - do not include <strong>www</strong> or <strong>https://</strong>"
            multiple-lines
            allow-html
            class="u-margin-top-3"
            :style="{
              color: $arStyle.color.skyBlueGrey600
            }"
          />
        </ar-field>
        <div
          v-else
        >
          <ar-text
            text="Add these records to your host’s DNS section"
            size="xs"
            weight="bold"
            class="u-margin-bottom-5"
          />
          <div
            v-if="!!currentData"
            class="domain-table-section"
          >

            <table class="domain-table">
              <tr class="header">
                <th class="table-heading">Type</th>
                <th class="table-heading">Host</th>
                <th class="table-heading">Points to</th>
                <th class="table-heading"></th>
              </tr>
              <tr
                v-for="(item, key) in currentData.hostRecords"
                :key="key"
                class="domain">
                <td>
                  <ar-text
                    size="xs"
                    :text="item.type"
                    :style="{
                      textTransform: 'uppercase',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '60px',
                    }"
                  />
                </td>
                <td>
                  <div class="text-data">
                    <ar-text
                      size="xs"
                      :text="item.host"
                      :style="{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '228px',
                      }"
                      v-tooltip.top="{
                        content: item.host,
                        classes: ['tooltip']
                      }"
                    />
                    <ar-simple-button
                      class="u-margin-left-3"
                      :outlined="true"
                      :text="(hostRecordsMatrix && !!hostRecordsMatrix[key]['hostCopy']) ? 'Copied' : 'Copy'"
                      :type="(hostRecordsMatrix && !!hostRecordsMatrix[key]['hostCopy']) ? 'green' : 'purple'"
                      :style="{
                        height: '32px',
                        width: '83px',
                      }"
                      @click="() => copyItem(key, 'hostCopy', item.host)"
                    />
                  </div>
                </td>
                <td>
                  <div class="text-data">
                    <ar-text
                      size="xs"
                      :text="item.data"
                      :style="{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '228px',
                      }"
                      v-tooltip.top="{
                        content: item.data,
                      }"
                    />
                    <ar-simple-button
                      class="u-margin-left-3"
                      :outlined="true"
                      :text="(hostRecordsMatrix && !!hostRecordsMatrix[key]['dataCopy']) ? 'Copied' : 'Copy'"
                      :type="(hostRecordsMatrix && !!hostRecordsMatrix[key]['dataCopy']) ? 'green' : 'purple'"
                      :style="{
                        height: '32px',
                        width: '83px',
                      }"
                      @click="() => copyItem(key, 'dataCopy', item.data)"
                    />
                  </div>
                </td>
                <td>
                  <div class="text-data">
                    <div
                      v-if="!!item.valid"
                      v-tooltip.top="{content: 'Record has been verified'}"
                      :style="{
                        border: `2px solid ${$arStyle.color.green500}`,
                        borderRadius: `50%`,
                        padding: `2px`,
                        width: '23px',
                        height: '23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }">
                      <ar-icon
                        name="check"
                        height="11px"
                        :color="$arStyle.color.green500"
                      />
                    </div>
                    <div
                      v-else
                      v-tooltip.top="{content: 'Record not verified'}"
                      :style="{
                        border: `2px solid #FF8B25`,
                        borderRadius: `50%`,
                        padding: `2px`,
                        width: '23px',
                        height: '23px'
                      }">
                      <ar-text
                        size="15px"
                        text="!"
                        weight="bold"
                        align="center"
                        :style="{color: '#FF8B25', cursor: 'default'}"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <ar-simple-button
            class="u-margin-left-3"
            :outlined="true"
            :text="copyAllText"
            :type="copyAllText === 'Copied' ? 'green' : 'purple'"
            :style="{
              height: '32px',
              width: '106px',
              marginTop: '10px',
              marginLeft: 0,
              float: 'right',
            }"
            @click="handleCopyAllClick"
          />
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'domain-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <div class="connection-guide">
          <ar-link-button
            text="View our connection guide"
            @click="() => connectionGuideClick()"
          />
          <ar-icon
            name="external-link-bold"
            width="16px"
            :color="$arStyle.color.purple500"
            class="u-margin-left-1"
          />
        </div>
        <div>
          <ar-checkbox
            v-if="page === 'verify'"
            label="Records have been added"
            data-test-id="confirm-records-added"
            class="u-margin-right-7"
            v-model="confirmRecord"
          />
          <ar-simple-button
            :text="page === 'create' ? 'Continue' : 'Verify'"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'DomainModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      currentData: !!this.data ? { ...this.data } : {},
      page: 'create',
      confirmRecord: false,
      hostRecordsMatrix: {},
      copyAllText: 'Copy All',
      isCreating: false,
    };
  },

  computed: {
    ...mapState({
      isValidatingDomain: state => state.auth.isValidatingDomain,
    }),

    confirmButtonDisabled() {
      if (this.page === 'verify' && this.isValidatingDomain) {
        return true
      }

      if (this.page === 'verify') {
        return !this.confirmRecord;
      }

      if (this.page === 'create' && this.isCreating) {
        return true;
      }

      if (!this.currentData) {
        return;
      }

      return (!this.currentData.domain || this.currentData.domain === '')
    },

    copyAllDataString() {
      if (!this.data) return '';

      const records = {}
      let maxHostLength = 0;
      let maxTypeLength = 0;

      Object.values(this.data.hostRecords).forEach(record => {
        if (records[record.type]) {
          records[record.type].push(record);
        } else {
          records[record.type] = [record];
        }

        maxTypeLength = Math.max(maxTypeLength, record.type.length);
        maxHostLength = Math.max(maxHostLength, record.host.length);
      });

      let resultString = '';

      // Generate strings for each record type
      Object.entries(records).forEach(([recordType, recordList]) => {
        if (recordList.length > 0) {
          if (resultString.length > 0) {
            resultString += '\n';
          }
          resultString += `; ${recordType.toUpperCase()} Record\n`;
          recordList.forEach(record => {
            const paddingHost = ' '.repeat(maxHostLength - record.host.length + 4);
            const paddingType = ' '.repeat(maxTypeLength - record.type.length + 4);
            resultString += `${record.host}${paddingHost}${recordType.toUpperCase()}${paddingType}"${record.data}"\n`;
          });
        }
      });

      return resultString;
    },
  },

  watch: {
    data(val) {
      this.currentData = {
        ...val
      }

      if (!val) {
        this.page = 'create'
      } else if (!val.verified) {
        this.page = 'verify'
      }
    },
    isShow(val) {
      if (val) {
        this.currentData = !!this.data ? { ...this.data } : {}
        this.updateHostRecordKeys(this.currentData)

        if (!this.data) {
          this.page = 'create'
        } else if (!this.data.verified) {
          this.page = 'verify'
        }
      }
    },
    currentData(val) {
      if (!val) {
        this.hostRecordsMatrix = {}
      }

      this.updateHostRecordKeys(val)
    },
  },

  methods: {
    async handleConfirmClick() {
      if (this.page === 'create') {
        const isValidated = await this.$validator.validateAll();
        if (isValidated) {
          this.isCreating = true;
          this.$emit('confirm', this.currentData);
        }
      } else {
        this.$emit('verify', this.data);
      }
    },
    handleCopyAllClick() {
      navigator.clipboard.writeText(this.copyAllDataString);
      this.copyAllText = 'Copied';
      setTimeout(() => {
        this.copyAllText = 'Copy All';
      }, 5000)
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.resetData()
    },
    connectionGuideClick() {
      window.open('https://audiencerepublic.freshdesk.com/support/solutions/articles/51000309976-domain-authentication', '_blank');
    },
    resetData() {
      this.currentData = {}
      this.page = 'create'
      this.confirmRecord = false
      this.hostRecordsMatrix = {}
      this.isCreating = false
    },
    copyItem(key, field, item) {
      navigator.clipboard.writeText(item);
      this.$set(this.hostRecordsMatrix[key], field, true)

      setTimeout(() => {
        this.$set(this.hostRecordsMatrix[key], field, false)
      }, 5000)
    },
    updateHostRecordKeys(val) {
      this.copyAllText = 'Copy All';
      if (!val.hostRecords) {
        this.hostRecordsMatrix = {}
        return;
      }

      const hostRecordsKeys = Object.keys(val.hostRecords)

      this.hostRecordsMatrix = hostRecordsKeys.reduce((keys, key, index) => {
        return {
          ...keys,
          [key]: {
            hostCopy: false,
            dataCopy: false,
          },
        }
      }, {})
    },
  },
};
</script>

<style lang="scss" scoped>
.domain-modal {
  .domain-modal-body {
    display: flex;
    gap: 24px;
    padding: 48px 32px;
    flex-direction: column;

    &-row {
      width: 100%;
      gap: 24px;

      [ar-widget-id=ar-field] {
        width: 100%;
      }
    }



    .domain-table-section {
      border: 1px solid $skyBlueGrey400;
      border-radius: 4px;
      padding: 12px 24px;
    }

    .domain-table {
      width: 100%;

      table {
        padding: 0px;
      }

      td, th {
        padding: 15px;
        margin: 0px;
      }

      th {

        border-bottom: 1px solid $skyBlueGrey400;

      }

      .header {
        .table-heading {
          font-size: 14px;
          text-align: left;
        }
      }

      .text-data {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

    }
  }

  .domain-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
    height: 100px;

    .connection-guide {
      align-items: flex-end;
    }
  }
}
</style>
