<template>
    <portal
      to="modal"
      :disabled="!isShow"
      :style="{ position: 'absolute' }"
    >
      <ar-modal
        :is-open="isShow"
        class="see-tickets-benelux-modal"
        header="Connect To See Tickets Benelux"
        width="590px"
        :mobile="$arMediaQuery.window.maxWidth('xs')"
        @close="handleCloseClick"
      >
        <div
          slot="body"
          :class="[
            'see-tickets-benelux-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
          <ar-field
            label="Username"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="username"
              name="username"
              placeholder="Please enter your See Tickets Benelux username"
              id="see-tickets-benelux-username-input"
              type="text"
              data-test-id="see-tickets-benelux-username-input"
            />
          </ar-field>
          <ar-field
            label="Password"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="password"
              name="password"
              placeholder="Please enter your See Tickets Benelux Password"
              id="see-tickets-benelux-password-input"
              type="password"
              data-test-id="see-tickets-benelux-password-input"
            />
          </ar-field>
          <ar-field
            label="Merchant ID"
            class="u-margin-top-5"
          >
            <ar-input
              v-model="merchantId"
              name="merchantId"
              placeholder="Please enter your See Tickets Benelux merchant Id"
              id="see-tickets-benelux-merchant-id-input"
              type="text"
              data-test-id="see-tickets-benelux-merchant-id-input"
            />
          </ar-field>
        </div>
        <div
          slot="body"
          :class="[
            'see-tickets-benelux-modal-body',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]"
        >
        </div>
        <div
          slot="footer"
          :class="[
            'see-tickets-benelux-modal-footer',
            $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
          ]">
          <ar-simple-button
            text="Confirm"
            :loading="loading"
            :disabled="confirmButtonDisabled"
            :style="{ width: '108px' }"
            @click="handleConfirmClick"
            id="see-tickets-benelux-modal-confirm-button"
            data-test-id="see-tickets-benelux-modal-confirm-button"
          />
        </div>
      </ar-modal>
    </portal>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  export default {
    name: 'SeeTicketsBeneluxModal',
    components: {},
    props: {
      isShow: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        password: null,
        username: null,
        merchantId: null 
      };
    },
  
    computed: {
      confirmButtonDisabled() {
        return !this.password || !this.username || !this.merchantId;;
      }
    },
  
    watch: {
      isShow(val) {
        if (val) {
          this.password = null;
          this.username = null;
          this.merchantId = null;
        }
      },
    },
  
    methods: {
      ...mapActions([
        'seeTicketsBeneluxIntegration/CREATE_SEE_TICKETS_BENELUX_INTEGRATION',
        'seeTicketsBeneluxIntegration/FETCH_INTEGRATION'
      ]),
      async fetchIntegration() {
        const success = await this['seeTicketsBeneluxIntegration/FETCH_INTEGRATION']()
        return success;
      },
  
      async handleConfirmClick() {
        this.errorMessage = null;
        this.httpStatus = null;
        const password = this.password;
        const username = this.username;
        const merchantId = this.merchantId;
        const response = await this['seeTicketsBeneluxIntegration/CREATE_SEE_TICKETS_BENELUX_INTEGRATION']({
          password,
          username,
          merchantId
        });
        if (response) {
          if (this.$route.path != '/settings/integrations/see-tickets-benelux') {
            await this.$router.push({ path: '/settings/integrations/see-tickets-benelux' });;
          }
          else {
            this.fetchIntegration();
          }
          this.$emit('cancel');
        }
        else {
          this.errorMessage = response.message;
          this.httpStatus = response.httpStatusCode;
        }
      },
      handleCloseClick() {
        this.$emit('cancel');
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .see-tickets-benelux-modal {
    .see-tickets-benelux-modal-body {
      padding: 20px 30px 30px;
  
      &:nth-child(2) {
        border-top: 1px solid $blueGrey500;
      }
    }
    .see-tickets-benelux-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 25px 30px;
      height: 100px;
    }
  }
  </style>