<template>
  <RendererFrame
    :is-readonly="!disableReadMode && isReadOnly"
    :title="filterCriteria.title"
    :filter-tooltip="filterTooltip"
    :subtitle="expectedConditionTitle"
    :readonly-items="readonlyItems"
    :apply-disabled="!isEditConditionComplete"
    :additional-constraints="filterCriteria.constraints"
    :condition-button-text="conditionButtonText"
    :hide-button="hideButton"
    @confirm="handleConfirmClick"
    @cancel="handleCancelClick"
    @delete="handleDeleteClick"
    @openChangeMode="isReadOnly = false"
  >
    <div slot="content" class="numeric-picker">
      <AdditionalConstraintRenderer
        class="mandatory-constraints u-margin-bottom-2"
        v-if="criteriaHasMandatoryConstraints"
        :filter-condition="filterCondition"
        :filter-constraints="filterCriteria.constraints"
        mandatory-constraints
        @constraintUpdate="handleConstraintUpdate"
      />
      <div v-if="!criteriaHasMandatoryConstraints || criteriaHasFilledMandatoryConstraint">
        <div class="select-section">
          <ar-simple-select
            :items="numericTypeItems"
            :default-select-index="numericTypeSelectedIndex"
            :placeholder="`Select the ${filterCriteria.title}`"
            class="select"
            @select="handleNumericTypeSelect"
            data-test-id="filter-predicate-selector"
          />
        </div>
        <div class="input-section">
          <div v-if="editFilterCondition.data.condition === 'is_between'" class="two-input-section">
            <div class="small-input">
              <div v-if="filterCriteria.type === 'currency'" class="currency">
                <ar-text
                  :text="currency"
                  size="xs"
                />
              </div>
              <ar-input
                class="input"
                :auto-focus="shouldAutoFocus"
                :value="filterCriteria.type === 'currency' ? editFilterCondition.data.values[0] / 100 : editFilterCondition.data.values[0]"
                :placeholder="inputPlaceholder"
                type="number"
                :min="0"
                @input="handleInputChange(0, ...arguments)"
                @enter="handleEnterPress"
                :style="{
                  borderTopLeftRadius: filterCriteria.type === 'currency' ? 0 : null,
                  borderBottomLeftRadius: filterCriteria.type === 'currency' ? 0 : null,
                }"
                data-test-id="filter-currency-input-1"
              />
            </div>
            <div class="small-input">
              <div v-if="filterCriteria.type === 'currency'" class="currency">
                <ar-text
                  :text="currency"
                  size="xs"
                />
              </div>
              <ar-input
                class="input"
                :value="filterCriteria.type === 'currency' ? editFilterCondition.data.values[1] / 100 : editFilterCondition.data.values[1]"
                :placeholder="inputPlaceholder"
                :auto-focus="shouldAutoFocus"
                type="number"
                :min="0"
                @input="handleInputChange(1, ...arguments)"
                @enter="handleEnterPress"
                :style="{
                  borderTopLeftRadius: filterCriteria.type === 'currency' ? 0 : null,
                  borderBottomLeftRadius: filterCriteria.type === 'currency' ? 0 : null,
                }"
                data-test-id="filter-currency-input-2"
              />
            </div>
          </div>

          <div
            v-else-if="editFilterCondition.data.condition !== 'is_known' && editFilterCondition.data.condition !== 'is_unknown'" class="big-input">
            <div v-if="filterCriteria.type === 'currency'" class="currency">
              <ar-text
                :text="currency"
                size="xs"
              />
            </div>
            <ar-input
              class="input"
              :auto-focus="shouldAutoFocus"
              :value="filterCriteria.type === 'currency' ? editFilterCondition.data.values[0] / 100 : editFilterCondition.data.values[0]"
              :placeholder="inputPlaceholder"
              type="number"
              @input="handleInputChange(0, ...arguments)"
              @enter="handleEnterPress"
              :style="{
              borderTopLeftRadius: filterCriteria.type === 'currency' ? 0 : null,
              borderBottomLeftRadius: filterCriteria.type === 'currency' ? 0 : null,
            }"
              data-test-id="filter-currency-input"
            />
          </div>
        </div>
        <AdditionalConstraintRenderer
          class="optional-constraints"
          v-if="criteriaHasConstraints"
          :filter-condition="filterCondition"
          :filter-constraints="filterCriteria.constraints"
          @constraintUpdate="handleConstraintUpdate"
        />
      </div>
    </div>
  </RendererFrame>
</template>

<script>
import { mapGetters } from 'vuex';
import accounting from 'accounting';
import { clone } from '@/utils/helpers/';
import { filterCriteriaHasConstraint, filterCriteriaHasMandatoryConstraint } from '@/utils/filter';
import { filterCriteriaMandatoryConstraintsFilled } from '~/utils/filter';
import RendererFrame from '../RendererFrame'
import AdditionalConstraintRenderer from '../RendererFrame/AdditionalConstraintRenderer'

export default {
  title: 'NumericPicker',
  components: {
    RendererFrame,
    AdditionalConstraintRenderer
  },
  props: {
    filterCriteria: {
      type: Object,
      default: () => ({}),
    },
    filterCondition: {
      type: Object,
      default: () => ({}),
    },
    conditionButtonText: {
      type: String,
      default: 'Apply filter'
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    disableReadMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReadOnly: false,
      cachedFilterCondition: clone(this.filterCondition),
      editFilterCondition: clone(this.filterCondition),
    };
  },
  computed: {
    shouldAutoFocus() {
      return !this.disableReadMode
    },
    criteriaHasConstraints() {
      return filterCriteriaHasConstraint(this.filterCriteria);
    },
    criteriaHasMandatoryConstraints() {
      return filterCriteriaHasMandatoryConstraint(this.filterCriteria);
    },
    criteriaHasFilledMandatoryConstraint() {
      return filterCriteriaMandatoryConstraintsFilled(this.filterCriteria, this.editFilterCondition);
    },
    filterTooltip() {
      return this.filterCriteria?.description;
    },
    isEditConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition)
    },
    isCachedConditionComplete() {
      return this.$arUtils.segment.isSegmentConditionComplete(this.cachedFilterCondition)
    },
    inputPlaceholder() {
      if (this.filterCriteria.resource === 'age') {
        return 'Age';
      }
      if (this.filterCriteria.type === 'currency') {
        return 'Amount';
      } else {
        return 'Quantity';
      }
    },
    numericTypeItems() {
      return this.filterCriteria.data.predicates.map(predicate => ({
        name: predicate.title,
        class: 'bold',
        value: predicate.value,
      }));
    },
    numericTypeSelectedIndex() {
      for (let i = 0; i < this.filterCriteria.data.predicates.length; i += 1) {
        if (this.filterCriteria.data.predicates[i].value === this.editFilterCondition.data.condition) {
          return i;
        }
      }
      return -1;
    },
    expectedConditionTitle() {
      const condition = this.editFilterCondition.data.condition;
      if (condition === 'is_known' || condition === 'is_unknown') {
        return null;
      }

      const clickedPredicate = this.findPredicate(condition);
      return clickedPredicate ? clickedPredicate.title.toLowerCase() : null;
    },
    readonlyItems() {
      if (this.editFilterCondition.data.condition === 'is_known' || this.editFilterCondition.data.condition === 'is_unknown') {
        return [{ name: this.findPredicate(this.editFilterCondition.data.condition).title }];
      }

      let value1;
      let value2;
      if (this.filterCriteria.type === 'currency') {
        value1 = `${this.currency}${accounting.formatNumber(this.editFilterCondition.data.values[0] / 100)}`;
        value2 = `${this.currency}${accounting.formatNumber(this.editFilterCondition.data.values[1] / 100)}`;
      } else {
        value1 = accounting.formatNumber(this.editFilterCondition.data.values[0]);
        value2 = accounting.formatNumber(this.editFilterCondition.data.values[1]);
      }

      if (this.editFilterCondition.data.condition === 'is_between') {
        return [{ name: `${value1} - ${value2}` }]
      }
      return [{ name: value1 }];
    },
    currency() {
      return this.regionSettingsCurrencySymbol() || '$'
    }
  },

  async created() {
    this.isReadOnly = this.$arUtils.segment.isSegmentConditionComplete(this.editFilterCondition)
  },

  methods: {
    ...mapGetters({
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
    }),
    findPredicate(condition) {
      return this.filterCriteria.data.predicates.find(
        predicate => predicate.value === condition
      );
    },
    review() {
      this.$emit('apply', clone(this.editFilterCondition));
    },
    handleConfirmClick() {
      this.isReadOnly = true;
      this.cachedFilterCondition = clone(this.editFilterCondition);
      this.$emit('apply', clone(this.editFilterCondition));
    },
    handleEnterPress() {
      if (!this.isEditConditionComplete || this.hideButton) {
        return;
      }
      this.handleConfirmClick();
    },
    handleDeleteClick() {
      this.$emit('delete');
    },
    handleCancelClick() {
      if (this.isCachedConditionComplete) {
        if (!this.disableReadMode) {
          this.isReadOnly = true;
          this.editFilterCondition = clone(this.cachedFilterCondition);
          this.$emit('apply', clone(this.editFilterCondition));
        } else {
          this.$emit('delete');
        }
      } else {
        this.$emit('delete');
      }
    },
    handleInputChange(index, val) {
      if (this.filterCriteria.type === 'currency') {
        this.$set(this.editFilterCondition.data.values, index, parseInt(val * 100, 10));
      } else {
        this.$set(this.editFilterCondition.data.values, index, parseInt(val, 10));
      }
      this.review();
    },
    getNewValueByCondition(condition) {
      const newFilterCondition = clone(this.editFilterCondition);
      newFilterCondition.data.condition = condition;

      // Get old numbers
      let oldNumbers = [NaN, NaN];

      oldNumbers =  this.editFilterCondition.data.values || [NaN, NaN];

      if (newFilterCondition.data.condition === 'is_between') {
        newFilterCondition.data.values = [oldNumbers[0], oldNumbers[1]];
      } else if (newFilterCondition.data.condition === 'is_known' || newFilterCondition.data.condition === 'is_unknown') {
        delete newFilterCondition.data.values;
      } else {
        newFilterCondition.data.values = [oldNumbers[0]];
      }
      return newFilterCondition;
    },
    handleNumericTypeSelect(item) {
      // Skip if it doesn't change
      if (this.editFilterCondition.data.condition === item.value) {
        return;
      }

      this.editFilterCondition = this.getNewValueByCondition(item.value);
      this.review();
    },
    handleConstraintUpdate(item) {
      const { constraint, key, values } = item;
      this.$set(this.editFilterCondition.constraints[constraint], 'condition', key);
      if (!!values) this.$set(this.editFilterCondition.constraints[constraint], 'values', values);
      this.review();
    },
    // TODO - Add this to to toValue field's formatter prop to ensure that upper bound is never smaller than lower bound
    inputFormatterMax(input) {
      const initialValue = input.value;
      const valueLeft = this.filterCriteria.type === 'currency' ? this.editFilterCondition.data.values[0] / 100 : this.editFilterCondition.data.values[0];
      const minValue = !isNaN(valueLeft) && valueLeft > 0 ? valueLeft : 0;

      return {
        value: (minValue > 0 && initialValue < minValue) ? minValue : initialValue,
        position: null,
      };
    },
    // TODO - Add this to to toValue field's value prop to ensure that upper bound is never smaller than lower bound
    toValue() {
      const leftVal = this.filterCriteria.type === 'currency' ? this.editFilterCondition.data.values[0] / 100 : this.editFilterCondition.data.values[0];
      const rightVal = this.filterCriteria.type === 'currency' ? this.editFilterCondition.data.values[1] / 100 : this.editFilterCondition.data.values[1];

      if (isNaN(leftVal) && isNaN(rightVal)) return rightVal; // So that the placeholder can still appear
      if (!isNaN(leftVal) && isNaN(rightVal)) { // So that there's always a min
        this.$set(this.editFilterCondition.data.values, 1, parseInt(this.editFilterCondition.data.values[0], 10)); // replace with // this.handleInputChange(1, this.data.values[0])
        return leftVal;
      }

      if (!isNaN(leftVal) && leftVal > 0 && leftVal > rightVal) { // RHS is lower than LHS
        this.$set(this.editFilterCondition.data.values, 1, parseInt(this.editFilterCondition.data.values[0], 10)); // replace with // this.handleInputChange(1, this.data.values[0])
        return leftVal;
      }
      return rightVal; // RHS is higher than or equal to LHS
    },
  },
};
</script>

<style lang="scss" scoped>
.numeric-picker {
  .select-section {
    .select {
      height: 40px;
    }
  }
  .input-section {
    margin-top: 10px;

    .input {
      height: 40px;
    }

    .two-input-section {
      position: relative;
      display: flex;
      justify-content: space-between;

      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        border-bottom: 1px solid $blueGrey600;
        width: 10px;
        height: 1px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .small-input {
        width: 125px;
        display: inline-flex;
      }
    }

    .currency {
      display: flex;
      height: 40px;
      min-width: 34px;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid $skyBlueGrey500;
      border-right:none;
    }

    .big-input {
      width: 100%;
      display: inline-flex;
    }
  }
}
</style>
