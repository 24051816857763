<template>
  <am2-table
    ref="table"
    :heads="decoratedHead"
    :rows="body"
    :loading="loading"
    @sortBy="handleSortByChange"
    class="subscribers-table"
    :empty-text="emptyText"
    :enable-row-click="customerProfilesEnabled"
    @rowClick="handleUserClick"
    :has-sticky-header="hasStickyHeader"
  >
    <div
      slot="name"
      slot-scope="{ data: { avatar, name, firstName, lastName, emailAddress, fanOid }, index, active }"
      class="cell"
    >
      <ar-avatar
        ref="avatar"
        class="avatar"
        :image-path="avatar"
        :name="firstName || lastName ? `${firstName} ${lastName}` : emailAddress"
        :color="audienceSelection.partlySelectedAudienceMap[fanOid] || active ? 'white' : 'grey'"
        :style="{
          marginRight: '10px',
          cursor: customerProfilesEnabled ? 'pointer' : null,
        }"
      />
      <ar-text
        class="name-text"
        size="xs"
        :text="determineName(name, firstName, lastName, emailAddress)"
        :style="{
          cursor: customerProfilesEnabled ? 'pointer' : null,
        }"
      />
    </div>
    <div
      slot="channel"
      slot-scope="{ data: { optedIntoEmail, optedIntoSms } }"
      class="cell channel"
    >
      <ar-icon
        v-if="optedIntoEmail"
        class="icon"
        name="email"
        width="18.67px"
        :color="$arStyle.color.email"
        v-tooltip.top="{
          content: 'Subscribed to Email'
        }"
      />
      <ar-icon
        v-if="optedIntoSms"
        class="icon"
        name="sms"
        width="18px"
        :color="$arStyle.color.sms"
        v-tooltip.top="{
          content: 'Subscribed to SMS'
        }"
      />
    </div>
    <div
      slot="lastMessageTime"
      slot-scope="{ data: { lastMessageTime } }"
      class="cell"
    >
      <ar-text
        v-if="lastMessageTime"
        size="xs"
        :text="toDateString(lastMessageTime)"
      />
      <div
        v-else
        class="empty-dash"
      />
    </div>
    <div
      slot="emailAddress"
      slot-scope="{ data, head }"
      class="cell"
    >
      <ar-text
        size="xs"
        :text="data[head.key]"
      />
    </div>
    <div
      slot="text"
      slot-scope="{ data, head }"
      class="cell"
    >
      <ar-text
        size="xs"
        :text="data[head.key]"
      />
    </div>
  </am2-table>
</template>

<script>
import accounting from 'accounting';
import { generateMessageCenterDateCopy } from '@/utils/date/';
import { mapState } from 'vuex';

export default {
  name: 'SubscribersTable',

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: 'No data',
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      audienceSelection: state => state.audience.audienceSelection,
    }),
    customerProfilesEnabled() {
      return !!process.env.arEnableCustomerProfiles;
    },
    decoratedHead() {
      return this.head.map(item => {
        let width;
        if (item.key === 'name') {
          width = 230;
        } else if (item.key === 'emailAddress') {
          width = 230;
        } else if (item.key === 'mobileNumber') {
          width = 150;
        } else if (item.key === 'lastMessageTime') {
          width = 180;
        } else {
          width = 136;
        }
        return {
          ...item,
          width,
          align: 'left',
        };
      });
    },
  },

  methods: {
    determineName(name, fName, lName, email) {
      if (name) {
        return name;
      } else if (fName && lName) {
        return `${fName} ${lName}`;
      } else {
        return fName || lName || email;
      }
    },
    calculateTimeElapsed(date) {
      return timeago(date);
      // return '2000';
    },

    handleSortByChange(sortBy) {
      this.$emit('sortBy', sortBy);
    },

    toDateString(dateString) {
      return generateMessageCenterDateCopy(dateString);
    },

    handleUserClick(item) {
      if (!this.customerProfilesEnabled) return;
      if (!item || !item.fanOid) return;
      this.$router.push(`/audience/${item.fanOid}/view/overview`);
    },

  },
};
</script>

<style lang="scss" scoped>
.subscribers-table {
  .cell {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 12px;
  }

  .channel {
    .icon {
      margin-right: 23px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .empty-dash {
    width: 14px;
    border-bottom: 1px solid $blueGrey600;
  }


  .name-text {
    flex-grow: 1;
    max-width: 100%;
    overflow: hidden;
    >>> a {
      color: $blueGrey800;
    }
  }
}
</style>
