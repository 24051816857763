var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"message-preview-modal",attrs:{"is-open":_vm.opened,"header":_vm.header,"hide-footer":"","width":"1200px","height":"100%","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleModalClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[(_vm.showShareLink && _vm.csvPreviewContacts && _vm.csvPreviewContacts.length !== 0 && _vm.resourceUrl)?_c('div',{staticClass:"message-preview-modal__header-first"},[_c('am2-copy-input',{staticClass:"u-margin-right-2",style:({height: '40px'}),attrs:{"value":_vm.resourceUrl,"input-styles":{height: '40px'}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"message-preview-modal__header"},[(_vm.csvPreviewContacts && _vm.csvPreviewContacts.length !== 0)?_c('div',{staticClass:"message-preview-modal__dynamic-tags"},[_c('ar-text',{style:({
              color: _vm.$arStyle.color.blueGrey800,
              marginRight: '8px',
            }),attrs:{"text":"Dynamic tag preview","size":"xs","weight":"bold"}}),_vm._v(" "),_c('am2-switch',{on:{"input":_vm.handleChangeIsDynamicTag},model:{value:(_vm.isDynamicTag),callback:function ($$v) {_vm.isDynamicTag=$$v},expression:"isDynamicTag"}})],1):(_vm.showShareLink && _vm.resourceUrl)?_c('am2-copy-input',{staticClass:"u-margin-right-2",style:({height: '40px'}),attrs:{"value":_vm.resourceUrl,"input-styles":{height: '40px'}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"message-preview-modal__header-right"},[(_vm.isAbDesignOn)?_c('ar-simple-select',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.isAbSelectDisabled ? 'Design your email to preview.' : null
            }),expression:"{\n              content: isAbSelectDisabled ? 'Design your email to preview.' : null\n            }",modifiers:{"top":true}}],style:({ width: '150px', height: '40px', marginLeft: 'auto', marginRight: '20px' }),attrs:{"enable-filter":"","items":_vm.abOptions,"default-select-index":_vm.abSwitchIndex,"disabled":_vm.isAbSelectDisabled},on:{"select":_vm.handleVariantSelectClick}}):_vm._e(),_vm._v(" "),(_vm.hasModeTabs)?_c('am2-elegant-tabs',{staticClass:"message-preview-modal__tabs",attrs:{"layout":"wide","items":_vm.modeTabs,"tab-key":_vm.mode},on:{"select":_vm.handleModeSelect}}):_vm._e()],1)],1),_vm._v(" "),_c('ar-divider'),_vm._v(" "),(_vm.isDynamicTag)?_c('div',{staticClass:"message-preview-modal__preview-counter"},[_c('ar-icon-button',{attrs:{"icon-props":{
            name: 'arrow',
            rotate: 90,
          }},on:{"click":_vm.handleClickPrevDynamicTag}}),_vm._v(" "),_c('ar-text',{style:({
            margin: '0 9px',
          }),attrs:{"size":"xs","text":_vm.dynamicTagsPreviewText}}),_vm._v(" "),_c('ar-icon-button',{attrs:{"icon-props":{
            name: 'arrow',
            rotate: 270,
          }},on:{"click":_vm.handleClickNextDynamicTag}})],1):_vm._e(),_vm._v(" "),(_vm.mode === 'mobile')?_c('div',{staticClass:"mobile-preview-section"},[_c('am2-mobile-container',{attrs:{"has-footer":false,"size":"fullHeight"}},[(_vm.isLoading)?_c('div',{staticClass:"message-preview-modal__loading-spinner"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):_c('am2-email-preview',{attrs:{"opened":"","email-template":_vm.preparedEmailTemplate,"sender-name":_vm.senderName,"subject":_vm.preparedSubject,"preview-text":_vm.previewText,"scheduled-at":_vm.scheduledAt}})],1)],1):_vm._e(),_vm._v(" "),(_vm.mode === 'desktop')?_c('div',{staticClass:"desktop-preview-section"},[(_vm.isLoading)?_c('div',{staticClass:"message-preview-modal__loading-spinner"},[_c('am2-loading-spinner',{attrs:{"size":"48px"}})],1):_c('am2-email-preview',{attrs:{"mobile":false,"opened":"","email-template":_vm.preparedEmailTemplate,"sender-name":_vm.senderName,"subject":_vm.preparedSubject,"preview-text":_vm.previewText,"scheduled-at":_vm.scheduledAt}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }