<template>
  <AuthenticateFrame
    hasGoBackLink
    @previousStep="handleBack"
  >
    <ar-text
      text="Setup MFA"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '20px',
      }"
    />
    <MfaSection :loading="isLoading" @success="handleSuccess" />
  </AuthenticateFrame>
</template>

<script>
import MfaSection from '~/components/sections/mfa-section/MfaSection.vue'
import AuthenticateFrame from '~/pages/authenticate/authenticate-frame'
import {mapActions, mapMutations} from 'vuex'
export default {
  layout: 'split',
  components: {
    AuthenticateFrame,
    MfaSection
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    ...mapActions(['auth/VERIFY_TOKEN', 'auth/LOGOUT']),
    ...mapMutations(['layout/SWITCH_SPLIT_BACKGROUND_IMAGE']),
    async handleSuccess() {
      this.isLoading = true
      await this['auth/VERIFY_TOKEN']()
      this.isLoading = false
    },
    handleBack() {
      this['auth/LOGOUT']()
    }
  },
  created() {
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);
  }
}
</script>
<style scoped lang="scss">
</style>
