<template>
  <div
    class="select-search-dropdown"
    :class="[disabled ? 'disabled' : '', styleLikeSelect ? 'select-search-dropdown--select' : '']"
    v-click-outside="closeAndReset"
    tabindex="0"
    @keydown.esc="handleCompareEscape"
  >
    <div class="select-search-dropdown__button"
         :class="[
             isDropdownOpen ? 'select-search-dropdown__button--active' : '',
             isEmpty ? 'select-search-dropdown__button--empty' : '']"
         ref="select-button"
         @click="toggleDropdown"
    >
      <ar-text
        class="select-search-dropdown__text"
        :class="[isEmpty ? 'select-search-dropdown__text--empty' : '']"
        size="xs"
        :text="defaultLabel"
        multiple-lines
        :max-lines="1"
        line-height="20px"
      />
      <ar-icon
        width="10px"
        name="arrow"
        class="icon-arrow"
        :rotate="isDropdownOpen ? 180 : 0"
      />
    </div>

    <div
      v-show="isDropdownOpen"
      class="select-search-dropdown__items-wrapper"
      :style="buttonStyles"
    >
      <div class="triangle">
        <div class="triangle-outer"></div>
        <div class="triangle-inner"></div>
      </div>
      <div class="select-search-dropdown__container">
        <am2-search
          v-if="!offSearch"
          class="select-search-dropdown__search"
          v-model="searchText"
          placeholder="Search events..."
          @input="handleSearch"
        />
        <div
            class="select-search-dropdown__option-wrapper"
            :class="{ 'select-search-dropdown__option-wrapper--no-search': offSearch}"
            @scroll="handleScrollOptionWrapper">
          <template v-if="options.length">
            <div class="scroll-gradient-top" />
            <a
              v-if="enableAny"
              :class="[
                'select-search-dropdown__option',
                selectedIdsKeys.length === 0 && 'selected'
              ]"
              @click="handleClickOption('any')"
            >
              <ar-text
                :class="[
                  'select-search-events-option__event-name',
                  'u-margin-top-2',
                  'u-margin-bottom-2',
                ]"
                size="xs"
                :text="anyOptionText"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                :weight="selectedIdsKeys.length === 0 ? 'bold' : null"
              />
            </a>
            <a
              v-for="option in options"
              :key="`select-search-dropdown-option-${option.oid}`"
              class="select-search-dropdown__option"
              @click="handleClickOption(option.oid)"
            >
              <ar-checkbox
                :value="selectedIds[option.oid]"
                class="select-search-dropdown__checkbox"
              />
              <slot name="item" :option="option"></slot>
            </a>
          </template>
          <div
            v-if="isLoading"
            class="select-search-dropdown__loading"
          >
            <am2-loading-spinner size="48px" />
          </div>
          <template v-if="!isLoading && !options.length">
            <ar-text
              class="select-search-dropdown__no-results"
              size="xs"
              text="No results found"
              multiple-lines
              :max-lines="1"
              line-height="20px" />
          </template>
        </div>
      </div>

      <div class="select-search-dropdown__bottom">
        <ar-link-button
          v-if="isShowDeselect"
          class="select-search-dropdown__deselect"
          text="Deselect all"
          :has-underline="true"
          :color="$arStyle.color.$purple500"
          @click="handleClickDeselect"
        />

        <ar-simple-button
          class="select-search-dropdown__finish"
          :text="textSelect"
          @click="handleClickFinish"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from 'debounce'

const modalWidth = 516;

export default {
  name: 'SelectSearchDropdown',
  props: {
    selectedIds: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultLabel: {
      type: String,
      default: 'Select',
    },
    textSelect: {
      type: String,
      default: 'Apply',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    defaultTrackKey: {
      type: String,
      default: 'oid',
    },
    styleLikeSelect: {
      type: Boolean,
      default: false,
    },
    offSearch: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    enableAny: {
      type: Boolean,
      default: false,
    },
    anyOptionText: {
      type: String,
      default: 'Any item',
    }
  },
  data() {
    return {
      isDropdownOpen: false,
      searchText: '',
      selectedIdsLocal: {},
      buttonStyles: {
        left: 0,
        right: 'unset',
        top: null,
      },
    }
  },
  watch: {
    selectedIds() {
      if (!Object.keys(this.selectedIds).length) {
        this.searchText = ''
      }
    },
    isDropdownOpen(val) {
      if (val) {
        this.$emit('open')
      }
    },
  },
  computed: {
    isShowDeselect() {
      return Object.keys(this.selectedIds).length > 0
    },
    selectedIdsKeys() {
      return Object.keys(this.selectedIds)
    }
  },
  methods: {
    handleClickDeselect() {
      this.$emit('clear')
      this.searchText = ''
      this.selectedIdsLocal = {}
    },

    handleClickFinish() {
      this.isDropdownOpen = false
      this.$emit('finish')
    },

    handleClickOption(key) {
      this.$emit('clickSelect', { key, newValue: !this.selectedIds[key]} )
    },

    closeAndReset() {
      if (this.isDropdownOpen) {
        this.isDropdownOpen = false
        this.searchText = ''
        this.$emit('search', '')
        this.$emit('close')
      }
    },

    handleCompareEscape() {
      if (!!this.searchText) {
        this.searchText = ''
      } else {
        this.closeAndReset()
        this.$emit('clear')
      }
    },

    handleSearch: debounce(function(text) {
      this.$emit('search', text)
    }, 300),

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen

      if (this.isDropdownOpen) {
        let left = 0;
        let right = 0;

        if (this.$refs['select-button'].getBoundingClientRect().x < modalWidth) {
          left = '0'
          right = 'unset'
        } else {
          left = 'unset'
          right = '0'
        }

        this.buttonStyles = {
          left,
          right,
        }
      }
    },

    handleScrollOptionWrapper(event) {
      const target = event.target
      if (target.scrollTop + target.clientHeight + 10 >= target.scrollHeight) {
        this.$emit('load-more')
      }
    },
  }
}
</script>
<style lang="scss" scoped>

$borderColor: $blueGrey500;
$disabledColor: $blueGrey700;

@mixin flexRow {
  display: flex;
  flex-flow: row nowrap;
}

@mixin dropdownOptionsStyle {
  background: white;
  border: ui-px2em(1) solid $borderColor;
  border-radius: 5px;
  box-shadow: 0px 1px 11px -2px rgba(0, 0, 0, 0.2);
}

.select-search-dropdown {
  position: relative;
  outline: none;

  &--select {
    .select-search-dropdown__button {
      justify-content: space-between;
    }

    .select-search-dropdown__items-wrapper {
      width: calc(100% - 6px);
      left: 0;
      right: 0;
    }
  }

  &.disabled {
    pointer-events: none;

    .select-search-dropdown__button {
      .select-search-dropdown__text {
        color: $disabledColor;
      }

      .icon-arrow {
        color: $disabledColor;
      }
    }
  }

  &__button {
    @include flexRow;
    height: 50px;
    border: ui-px2em(1) solid $borderColor;
    border-radius: 4px;
    padding: ui-px2em(8) ui-px2em(14);
    background: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--active {
      border: 1px solid $green600;
      box-shadow: 0 0 0 3px $green200;
    }

    &--empty {
      .icon-arrow {
        color: $blueGrey700;
      }
    }

    .select-search-dropdown__text {
      margin-right: 5px;
    }

    .icon-arrow {
      margin-top: 2px;
    }
  }

  &__text--empty {
    color: $blueGrey600 !important;
  }

  &__finish {
    height: unset;
    padding: 13px 16px;
    margin-left: auto;

    &:focus {
      outline:lightpink;
    }
  }

  &__items-wrapper {
    @include dropdownOptionsStyle;
    position: absolute;
    z-index: 999;
    width: 516px;
    top: 60px;
    right: 0;
    min-height: 200px;
    padding: ui-px2em(12) 0 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: $md-min) {
      left: 10px;
      right: 10px;
      width: 300px;
    }
  }

  .select-search-dropdown__container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .select-search-dropdown__search {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 ui-px2em(12);

    &:focus {
      outline: none;
    }
  }

  .select-search-dropdown__option-wrapper {
    width: 100%;
    max-height: 336px;
    overflow: scroll;
    margin-top: 7px;
    border-top: 1px solid $borderColor;

    &--no-search {
      margin-top: 0;
      border-top: none;
    }
  }

  .select-search-dropdown__option {
    @include flexRow;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: ui-px2em(9) ui-px2rem(15);
    cursor: pointer;

    &:hover {
      background: #f2edff;
    }

    &.selected {
      background: #f2edff;
    }
  }

  .select-search-dropdown__checkbox {
    margin-right: 15px;
    cursor: pointer;
    pointer-events: none;
  }

  .select-search-dropdown__bottom {
    @include flexRow;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding: ui-px2em(14) ui-px2em(14);
    border-top: ui-px2em(1) solid $blueGrey500;
  }

  .compare-checkbox-wrapper {
    .compare-text {
      margin-right: 11px;
    }
    .start-radio {
      margin-right: 12px;
    }
  }

  .triangle {
    position: absolute;
    width: 10px;
    top: -5px;
    left: 275px;
  }

  .triangle-outer {
    position: absolute;
    top: 0;
    left: 0;
    border-left: ui-px2em(5) solid transparent;
    border-right: ui-px2em(5) solid transparent;
    border-bottom: ui-px2em(5) solid #d8dde3;
  }

  .triangle-inner {
    position: absolute;
    top: 1px;
    left: 0;
    border-left: ui-px2em(5) solid transparent;
    border-right: ui-px2em(5) solid transparent;
    border-bottom: ui-px2em(5) solid white;
    transform: translateY(10%);
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 32px 0;
  }

  &__no-results {
    margin-top: 12px;
    margin-left: calc(12em / 16);
  }
}
</style>
