<template>
  <div class="bar-container">
    <div v-if="title" class="bar-container__row">
      <div :class="[
        'bar-container__row-header',
        $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
        layout,
        ]">
        <am2-heading
          type="h1"
          size="md"
          :title="title"
          :style="{
            marginRight: '12px',
          }"
        />
        <div
          v-if="dropdown"
          class="dropdown"
        >
          <am2-link-button-dropdown
            v-if="dropdown && layout === 'old-style'"
            :item-key="dropdown.filterKey"
            :items="dropdown.items"
            @select="(item) => item.action()"
          />
          <ar-simple-select
            v-if="dropdown && layout === 'box-layout'"
            :items="dropdown.items"
            :default-select-index="dropdownIndex || dropdownIndex === 0 ? dropdownIndex : 1"
            placeholder="Date range"
            align="right"
            :dropdown-width="dropdownWidth"
            @select="(item, key) => handleSelect(item, key)" />
        </div>
      </div>
    </div>
    <div :class="[
        'bar-container__row-content',
        layout,
        $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max', $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max'
      ]">
      <div
        v-for="(v, idx) in values"
        :key="v.index"
        @click="!!v.clickAction ? v.clickAction() : null"
        :style="{
          cursor: !!v.clickAction ? 'pointer' : null,
        }"
        :class="[
          'bar-block',
          layout,
          v.layout && v.layout
        ]"
        @mouseenter="activeHover = idx"
        @mouseleave="activeHover = null"
        data-test-id="overview-bar-values"
      >
        <am2-heading
          type="h2"
          :size="layout === 'box-layout' ? 'xxs' : 'xs'"
          :title="v.label"
          :style="{
            color: '#8e97a6',
            lineHeight: '20px',
            marginTop: '6px',
          }"
          :data-test-id="`overview-bar-heading-${idx}`"
        />
        <div :class="[
            'bar-block__body',
            layout,
            blurValues && 'blurred fake-values',
          ]"
           v-tooltip.top="{
            content: v.tooltip || null,
            show: activeHover === idx,
            trigger: 'manual',
          }"
        >
          <div v-if="loading" class="bar-block-loading-section">
            <am2-typography-skeleton
              size="md"
              :style="{
                marginTop: layout === 'box-layout' ? '7px' : '10px',
                width: '56px',
                marginBottom: layout === 'box-layout' ? '7px' : null,
              }"
            />
          </div>

          <div v-else-if="v.type === 'CURRENCY'" class="sales-container">
            <div
              class="sales-plain" 
              :data-test-id="`overview-sales-plain-${idx}`"
            >
              {{ regionSettingsCurrencySymbol() }}{{ amountInDollarsLocal(v.amount) }}
            </div>
            <div
              class="sales-cents"
              v-if="v.showDecimal !== false"
              :data-test-id="`overview-sales-cents-${idx}`"
            >
              {{ centsRemainingLocal(v.amount) }}
            </div>
          </div>
          <div v-else-if="v.type === 'NUMBER'" :data-test-id="`overview-number-${idx}`">
            {{ formatIntegerLocal(v.amount) }}
          </div>
          <div v-else-if="v.type === 'FLOAT'" :data-test-id="`overview-float-${idx}`">
            {{ formatFloatLocal(v.amount) }}
          </div>
          <div v-else :data-test-id="`overview-default-total-${idx}`">
            {{ formatIntegerLocal(v.amount) }}
            <span v-if="v.maximum" class="over-total">/ {{ formatIntegerLocal(v.maximum) }}</span>
          </div>
          <am2-tendency-tag
            v-if="!loading && v.percentChange !== undefined"
            :value="v.percentChange"
            :data-test-id="`overview-percentage-change-${idx}`"
          />
        </div>
        <am2-gauge
          class="sales-gauge"
          :data-test-id="`overview-sakes-gauge-${idx}`"
          v-if="v.type === 'PROGRESSION'" :value="parseInt(v.amount)" :max="parseInt(v.maximum)"
        />
      </div>
    </div>
    <div v-if="extracontent">
      <transition name="slide">
        <div v-if="isOpen" class="bar-container__row-graph">
          <slot name="extracontent_graph" />
        </div>
      </transition>
      <div
        class="puller bar-container__row"
        @click="toggleOpening"
      >
        <ar-icon
          name="chevron"
          :rotate="isOpen ? 180 : 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { centsRemaining, formatInteger, formatFloat, amountInDollars } from '@/utils/helpers';
import { mapGetters} from 'vuex';

export default {
  name: 'OverviewBar',
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    extracontent: {
      type: Boolean,
      default: false,
    },
    promoterOid: {
      type: String,
      default: '',
    },
    dropdown: {
      type: Object,
      required: false,
      default: () => {},
    },
    dropdownIndex: {
      type: Number,
      required: false,
      default: null,
    },
    layout: {
      type: String,
      default: 'old-style'
    },
    values: {
      type: Array,
      default: () => [],
    },
    dropdownWidth: {
      type: Number,
      default: 100,
    },
    blurValues: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  data: () => ({
    isOpen: false,
    activeHover: null,
  }),
  methods: {
    ...mapGetters({
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
    }),
    toggleOpening() {
      if (this.isOpen === false) this.slideOut();
      else this.slideBack();
    },
    slideOut() {
      this.isOpen = !this.isOpen;
    },
    slideBack() {
      this.isOpen = !this.isOpen;
    },
    formatIntegerLocal: number => formatInteger(number),
    formatFloatLocal: number => formatFloat(number),
    centsRemainingLocal: number => centsRemaining(number),
    amountInDollarsLocal: number => amountInDollars(number),
    handleSelect(item, index) {
      this.$emit('select', item, index)
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-container {
  position: relative;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    svg {
      color: $blueGrey700;
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      padding: 34px 34px 0 39px;
      &.xs-max {
        padding: 24px 12px 0px 14px;
        margin-bottom:8px;
      }

      &.old-style {
        .dropdown {
          border-left: 1px solid $blueGrey400;
          padding-left: 10px;
        }
      }

      &.box-layout {
        width: 100%;
        align-items:center;
        padding: 24px 24px 24px;
      }
    }
    &-content {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      margin-top: 24px;
      border-radius: 3px;

      &.spacious {
        margin: 30px 0 36px;
      }

      &.box-layout {
        margin: 0 24px 24px;
        border:1px solid $skyBlueGrey400;
      }

      .bar-block {
        flex: 1 100%;
        min-height: 80px;
        padding-left: 39px;
        font-size: 30px;
        &:not(:last-child) {
          border-right: 1px solid $blueGrey400;
        }

        &.spacious {
          display:flex;
          flex-direction: column;
          align-items: left;
          padding-top: 12px;
        }

        &.status-bar {
          margin-bottom: -22px;
        }

        &.box-layout {
          display:flex;
          flex-direction:column-reverse;
          justify-content: space-around;
          align-items: center;
          padding: 26px 0;
          text-transform: uppercase;
          height: 110px;
        }

        &__body {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 3px;

          &.blurred {
            filter: blur(6px);
          }

          > div:first-child {
            margin-right: 10px;
          }

          &.box-layout {
            margin-top:0;

            > div:first-child {
              margin-right: 0;
            }
          }




          .sales-container {
            display: flex;
            flex-direction: row;
            align-items: baseline;
          }

          & .sales-cents {
            font-size: 0.65em;
          }
          .over-total {
            color: $blueGrey700;
          }
        }

        .sales-gauge {
          margin-top: 15px;
          width: 90%;
        }
      }

      &.xs-max {
        .bar-block {
          padding-left: 12px;
        }
      }

      &.xs-max {
        flex-direction:column;
        margin-bottom:0;
        padding-bottom:8px;

        .bar-block {
          &:not(:last-child) {
            border-bottom: 1px solid $blueGrey400;
          }
          border-right:none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          &__body {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 12px;

            &>div {
              margin-right:0;
            }

            .tag {
              margin-top: 12px;
            }
          }

          &:last-child{
            padding-bottom:8px;
          }

          .sales-gauge {
            width: calc(50% - 12px);
            position: absolute;
            right: 12px;
            bottom: 16px;
          }
        }

        &.box-layout {
          margin-bottom: 24px;
          padding-bottom: 0;
          margin-top: 12px;

          .bar-block {
            padding: 16px;

            &__body {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .puller {
    display: flex;
    flex: 1 100%;
    border-top: 1px solid $blueGrey400;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 22px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  .bar-block-loading-section {
    display:flex;
    flex-direction: row;
    width: 100%;
  }

}
</style>
