<template>
   <am2-card-container layout="regular">
    <div 
      class="vertical-menu"
      :style="{ 
        padding: customPadding,
        minWidth: minWidth,
      }"
    >
      <div 
        v-if="!!title" 
        class="vertical-menu-head"
        :style="customHeadPadding && { padding: customHeadPadding }"
      >
        <ar-text
          :text="title"
          size="xs"
          weight="bold"
        />
      </div>
      <div class="vertical-menu-body">
        <div 
          v-for="(item) in formattedItems"
          :class="['vertical-menu-option',
                    item.key == selectedKey && 'option-selected']"
          @click="() => { handleClick(item) }"
          :data-test-id="`vertical-menu-option-${item.key}`"
        >
          <ar-text
            :text="item.value"
            size="xs"
            :weight="item.key === selectedKey ? 'bold' : 'normal'"
          />
        </div>
      </div>
    </div>
   </am2-card-container>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils/helpers';

export default {
  name: 'VerticalMenu',

  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedKey: {
      type: String,
      default: null,
    },
    minWidth: {
      type: String,
      default: null,
    },
    customHeadPadding: {
      type: String,
      default: null,
    },
    customPadding: {
      type: String,
      default: null,
    }
  },

  computed: {
    formattedItems() {
      return this.items.map(item => {
        item.value = capitalizeFirstLetter(item.value)
        return item
      });
    }
  },

  methods: {
    handleClick(item) {
      this.$emit('select', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-menu {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  min-width: 150px;

  &-head {
    padding: 10px 10px;
  }

  &-option {
    padding: 10px 10px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: $purple100;
    }

    &:hover .ar-text {
      color: $purple500 !important;
      font-weight: bold !important;
    }
  }

  .option-selected {
    background-color: $purple100;
    
    .ar-text {
      color: $purple500;
    }
  }
}
</style>