var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"ask-try-booking-credentials-modal",attrs:{"is-open":_vm.isShow,"header":"Connect to TryBooking","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'ask-try-booking-credentials-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-field',{staticClass:"u-margin-top-5",attrs:{"label":"API key"}},[_c('ar-input',{attrs:{"name":"apiKey","placeholder":"Please enter your TryBooking api-key","id":"try-booking-api-key-input","type":"text","data-test-id":"try-booking-api-key-input"},model:{value:(_vm.apiKey),callback:function ($$v) {_vm.apiKey=$$v},expression:"apiKey"}})],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-5",attrs:{"label":"Secret"}},[_c('ar-input',{attrs:{"name":"secret","placeholder":"Please enter your TryBooking api secret","id":"try-booking-secret-input","type":"text","data-test-id":"try-booking-secret-input"},model:{value:(_vm.secret),callback:function ($$v) {_vm.secret=$$v},expression:"secret"}})],1),_vm._v(" "),_c('div',{staticClass:"u-display-flex u-margin-top-5"},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","text":"Subscribe associated contacts to list (optional)","weight":"bold"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
            content: "Automatically subscribe all attendees of events from this account.",
          }),expression:"{\n            content: `Automatically subscribe all attendees of events from this account.`,\n          }",modifiers:{"top":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","height":"15px","width":"15px","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('ar-field',{style:({
          marginTop: '15px',
        }),attrs:{"error-messages":[
          _vm.veeErrors.first('messageList')
        ]}},[_c('am2-message-list-select',{attrs:{"message-list-type":"manual","show-stats":false,"channels":['email']},model:{value:(_vm.messageList),callback:function ($$v) {_vm.messageList=$$v},expression:"messageList"}})],1),_vm._v(" "),_c('div',{staticClass:"u-margin-top-5 click-to-copy-webhook-url",on:{"click":_vm.clickCopyWebhookUrl}},[_c('ar-text',{style:({
            color: _vm.$arStyle.color.blueGrey700
          }),attrs:{"text":"<span style='text-decoration:underline;'>Click here</span> to copy webhook URL. Paste in Try Booking Dashboard > Integration Tools > Notify URL and click save","multiple-lines":"","allow-html":"","size":"xxs","has-underline":true}})],1)],1),_vm._v(" "),_c('div',{class:[
        'ask-try-booking-credentials-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","loading":_vm.loading,"disabled":_vm.confirmButtonDisabled,"id":"try-booking-confirm-button","data-test-id":"try-booking-confirm-button"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }