var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"multi-select-with-menu-modal",attrs:{"is-open":_vm.isShow,"header":_vm.title,"width":"600px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"navigation"},[_c('ar-text',{attrs:{"size":"xs","text":"Categories","weight":"bold"}}),_vm._v(" "),_c('div',{staticClass:"categories u-margin-top-3"},_vm._l((_vm.interactiveListItems),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.key === 'custom-fields' ? _vm.filterSidebar(item.key) : true),expression:"item.key === 'custom-fields' ? filterSidebar(item.key) : true"}],key:item.key,class:[
              'category-item',
              _vm.activeSection === item.key && 'active'
            ],on:{"click":item.action}},[_c('ar-text',{style:({
                color: _vm.activeSection === item.key ? _vm.$arStyle.color.purple500 : null,
              }),attrs:{"size":"xs","text":item.title,"weight":_vm.activeSection === item.key ? 'bold' : 'normal'}})],1)}),0)],1),_vm._v(" "),_c('div',{staticClass:"select-menu"},[(!!_vm.description || !!_vm.subtitle)?_c('div',{staticClass:"description-subtitle"},[(_vm.description)?_c('p',{class:[
            'description',
            _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-y-3' ]},[_vm._v("\n            "+_vm._s(_vm.description)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.subtitle)?_c('am2-heading',{staticClass:"subtitle",attrs:{"type":"h2","size":"xs","title":_vm.subtitle,"weight":"bold"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{ref:"scrollableContent",class:[
          'modal-content',
            _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ]},[_c('am2-search',{staticClass:"search-input",attrs:{"placeholder":"Search"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_vm._v(" "),_c('div',{ref:"currentSection",staticClass:"currentSection checkbox-section"},[_c('div',{staticClass:"checkbox"},[_c('ar-text',{attrs:{"size":"xs","text":"Current","weight":"bold"}})],1),_vm._v(" "),(!_vm.currentFields || _vm.currentFields.length === 0)?_c('div',{staticClass:"checkbox"},[_c('ar-text',{style:({
                  color: _vm.$arStyle.color.skyBlueGrey700,
                }),attrs:{"size":"xs","text":"No matching fields","weight":"normal"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.currentFields),function(item){return _c('div',{key:item.value,staticClass:"checkbox"},[_c('ar-checkbox',{attrs:{"disabled":item.disabled || false,"label":item.name},model:{value:(_vm.selectValueMap[item.value]),callback:function ($$v) {_vm.$set(_vm.selectValueMap, item.value, $$v)},expression:"selectValueMap[item.value]"}}),_vm._v(" "),(!!item.error)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                }),expression:"{\n                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null\n                }",modifiers:{"top":true}}],staticClass:"u-margin-left-3",attrs:{"type":"red","text":"Error","text-size":"xxxs"}}):_vm._e()],1)})],2),_vm._v(" "),_c('div',{ref:"defaultFieldsSection",staticClass:"defaultFieldsSection checkbox-section"},[_c('div',{staticClass:"checkbox"},[_c('ar-text',{attrs:{"size":"xs","text":"Default fields","weight":"bold"}})],1),_vm._v(" "),(!_vm.filteredDefaultFields || _vm.filteredDefaultFields.length === 0)?_c('div',{staticClass:"checkbox"},[_c('ar-text',{style:({
                  color: _vm.$arStyle.color.skyBlueGrey700,
                }),attrs:{"size":"xs","text":"No matching fields","weight":"normal"}})],1):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredDefaultFields),function(item){return _c('div',{key:item.value,staticClass:"checkbox"},[_c('ar-checkbox',{attrs:{"disabled":item.disabled || false,"label":item.name},model:{value:(_vm.selectValueMap[item.value]),callback:function ($$v) {_vm.$set(_vm.selectValueMap, item.value, $$v)},expression:"selectValueMap[item.value]"}}),_vm._v(" "),(!!item.error)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                }),expression:"{\n                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null\n                }",modifiers:{"top":true}}],staticClass:"u-margin-left-3",attrs:{"type":"red","text":"Error","text-size":"xxxs"}}):_vm._e()],1)})],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.filteredCustomFields && _vm.filteredCustomFields.length > 0),expression:"!!filteredCustomFields && filteredCustomFields.length > 0"}],ref:"customFieldsSection",staticClass:"customFieldsSection checkbox-section"},[_c('div',{staticClass:"checkbox"},[_c('ar-text',{attrs:{"size":"xs","text":"Custom fields","weight":"bold"}})],1),_vm._v(" "),_vm._l((_vm.filteredCustomFields),function(item){return _c('div',{key:item.value,staticClass:"checkbox"},[_c('ar-checkbox',{attrs:{"disabled":item.disabled || false,"label":item.name},model:{value:(_vm.selectValueMap[item.value]),callback:function ($$v) {_vm.$set(_vm.selectValueMap, item.value, $$v)},expression:"selectValueMap[item.value]"}}),_vm._v(" "),(!!item.error)?_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null
                }),expression:"{\n                  content: item.error && item.error.userErrorString ? item.error.userErrorString : null\n                }",modifiers:{"top":true}}],staticClass:"u-margin-left-3",attrs:{"type":"red","text":"Error","text-size":"xxxs"}}):_vm._e()],1)})],2)],1)])]),_vm._v(" "),_c('div',{class:[
      'footer',
      _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-3' ],attrs:{"slot":"footer"},slot:"footer"},[(_vm.enableResetButton && _vm.resetDefaultKeys.length && _vm.showResetButton)?_c('ar-simple-button',{style:({ marginRight: 'auto', border: 'none' }),attrs:{"text":"Reset to default","type":"purple","outlined":true,"icon-name":"refresh"},on:{"click":_vm.handleResetClick}}):_vm._e(),_vm._v(" "),_c('ar-simple-button',{style:({ width: '130px' }),attrs:{"text":"Save"},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }