var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-select',{ref:"select",attrs:{"enable-filter":"","items":_vm.computedMessageLists,"default-select-index":_vm.selectedMessageListIndex,"placeholder":_vm.listPlaceholder,"filter-placeholder":_vm.filterPlaceholder,"disabled":_vm.disabled,"data-test-id":"message-list-select"},on:{"select":_vm.handleSelect,"clear":function () { _vm.$emit('clear') },"close":function () { _vm.$emit('close') }},scopedSlots:_vm._u([{key:"selected-item",fn:function(selectedItemScope){return [(selectedItemScope.item)?_c('div',{style:({
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        padding: '0 14px',
      }),attrs:{"data-test-id":("message-list-item-" + (_vm.itemName(selectedItemScope)))}},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey800,
        }),attrs:{"size":"xs","text":_vm.itemName(selectedItemScope)}}),_vm._v(" "),(_vm.formatStatsTagText(selectedItemScope.item))?_c('ar-text',{style:({
          width: 'auto',
          marginLeft: '10px',
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"size":"xs","text":_vm.formatStatsTagText(selectedItemScope.item),"weight":"normal"}}):_vm._e()],1):_c('div',{style:({
        display: 'flex',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0 14px',
      })},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey600,
        }),attrs:{"size":"xs","text":_vm.listPlaceholder}})],1)]}},{key:"item",fn:function(itemScope){return [_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        padding: '0 15px',
        cursor: 'pointer',
        background: itemScope.active || (_vm.isSelectedPurple && itemScope.selected) ? _vm.$arStyle.color.purple100 : null,
        borderBottom: '1px solid #dcdee4',
      }),attrs:{"data-test-id":("message-list-item-" + (_vm.convertListNameToKebabCase(itemScope.item.name)))}},[(itemScope.item.filterGroupOid && !_vm.campaignOid && !_vm.eventOid)?_c('am2-tag',{style:({
          height: '22px',
          marginRight: '8px',
        }),attrs:{"text":"Dynamic","type":"purple","shape":"rectangle"}}):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
          width: 'auto',
          color: itemScope.active || (_vm.isSelectedPurple && itemScope.selected) ? '#7344c0' : '#43516b',
        }),attrs:{"size":"xs","text":_vm.itemName(itemScope),"weight":itemScope.selected ? 'bold' : 'normal'}}),_vm._v(" "),(_vm.formatStatsTagText(itemScope.item))?_c('ar-text',{style:({
          width: 'auto',
          marginLeft: '10px',
          color: itemScope.active ? _vm.$arStyle.color.blueGrey800 : _vm.$arStyle.color.blueGrey700,
        }),attrs:{"size":"xs","text":_vm.formatStatsTagText(itemScope.item),"weight":itemScope.selected ? 'bold' : 'normal'}}):_vm._e()],1)]}}])},[_vm._v(" "),_vm._v(" "),_c('template',{slot:"no-item"},[_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        padding: '0 15px',
      })},[_c('ar-text',{attrs:{"size":"xs","text":_vm.noListAvailableForThisChannel ? 'No lists available for this channel' : 'No available lists'}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }