var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('am2-table',{ref:"table",staticClass:"notifications-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.body,"loading":_vm.loading,"empty-text":_vm.emptyText,"has-sticky-header":_vm.hasStickyHeader,"has-control-section":"","default-sort-by":_vm.defaultSortBy},on:{"sortBy":_vm.handleSortByChange},scopedSlots:_vm._u([{key:"notificationType",fn:function(ref){
var notificationType = ref.data.notificationType;
return _c('div',{staticClass:"cell"},[_c('div',{class:[
        'notification-type'
      ]},[_c('ar-text',{style:({
            color: _vm.getColorBasedOnNotificationType(notificationType)
          }),attrs:{"size":"xs","text":notificationType,"weight":"bold"}})],1)])}},{key:"message",fn:function(ref){
          var message = ref.data.message;
return _c('div',{staticClass:"cell"},[_c('ar-text',{staticClass:"message-text",style:({

        }),attrs:{"size":"xxs","text":message}})],1)}},{key:"sysCtime",fn:function(ref){
        var sysCtime = ref.data.sysCtime;
return _c('div',{staticClass:"cell sysctime-date"},[_c('ar-text',{style:({

        }),attrs:{"size":"xxs","text":_vm.getSysCtimeFormatted(sysCtime)}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }