var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthenticateFrame',[_c('ar-text',{style:({
      marginBottom: '16px',
    }),attrs:{"text":"You’ve been invited to a workspace","size":"lg","multiple-lines":""}}),_vm._v(" "),_c('ar-text',{style:({
      color: _vm.$arStyle.color.skyBlueGrey700,
      marginBottom: '40px',
    }),attrs:{"text":"Please sign up to accept the invite","size":"sm","multiple-lines":""}}),_vm._v(" "),(_vm.isLoading)?_c('am2-loading-bubble',{style:({
      color: _vm.$arStyle.color.purple500,
      marginLeft: '20px'
    })}):_vm._e(),_vm._v(" "),(!_vm.isLoading)?_c('div',[_c('div',{style:({
        marginBottom: '20px',
      })},[_c('div',{style:({
          display: 'flex',
          justifyContent: 'space-between',
        })},[_c('div',{style:({
            width: 'calc(50% - 7px)'
          })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"firstName","autocomplete":"given-name","data-vv-as":"first name","placeholder":"First name","has-error":_vm.veeErrors.has('firstName')},model:{value:(_vm.registerData.firstName),callback:function ($$v) {_vm.$set(_vm.registerData, "firstName", $$v)},expression:"registerData.firstName"}}),_vm._v(" "),(_vm.veeErrors.has('firstName'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('firstName'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('div',{style:({
            width: 'calc(50% - 7px)'
          })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"lastName","autocomplete":"family-name","data-vv-as":"last name","placeholder":"Last name","has-error":_vm.veeErrors.has('lastName')},model:{value:(_vm.registerData.lastName),callback:function ($$v) {_vm.$set(_vm.registerData, "lastName", $$v)},expression:"registerData.lastName"}}),_vm._v(" "),(_vm.veeErrors.has('lastName'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('lastName'),"type":"error"}}):_vm._e()],1)])]),_vm._v(" "),_c('div',{style:({
        marginBottom: '40px',
      })},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|arPasswordLength|max:256'),expression:"'required|arPasswordLength|max:256'"}],attrs:{"data-vv-name":"password","data-vv-as":"password","placeholder":"Create a password","autocomplete":"new-password","has-error":_vm.veeErrors.has('password'),"text-secure":"","type":"password"},model:{value:(_vm.registerData.password),callback:function ($$v) {_vm.$set(_vm.registerData, "password", $$v)},expression:"registerData.password"}}),_vm._v(" "),(_vm.veeErrors.has('password'))?_c('ar-state-message',{style:({ marginTop: '8px' }),attrs:{"text":_vm.veeErrors.first('password'),"type":"error"}}):_vm._e()],1),_vm._v(" "),_c('ar-simple-button',{style:({
        width: '100%',
        marginBottom: '20px',
      }),attrs:{"type":"gradient-purple","shape":"pill","text":"Create account","loading":_vm.isFinishingInvite},on:{"click":_vm.submitForm}}),_vm._v(" "),_c('ar-text',{staticClass:"comment",attrs:{"size":"xxxs","align":"center","text":_vm.commentCopy,"allow-html":"","multiple-lines":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }