var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"messenger-edit-details"},[_c('am2-import-dynamic-tags-modal',{attrs:{"is-show":_vm.showImportDynamicTagsModal,"message-list-oid":_vm.scratchSimpleMessage.meta.messageListOid,"channel":"facebookMessenger"},on:{"import":_vm.handleDynamicTagsImport,"cancel":function($event){_vm.showImportDynamicTagsModal = false}}}),_vm._v(" "),_c('ar-field',{attrs:{"label":"To"}},[_c('am2-message-list-select',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: _vm.dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null
      }),expression:"{\n        content: dynamicTagsExist ? 'Changing lists will clear all dynamic tags from your message!' : null\n      }",modifiers:{"top":true}}],ref:"message-list-select",attrs:{"value":_vm.scratchSimpleMessage.meta.messageListOid,"campaign-oid":_vm.scratchSimpleMessageCampaignOid,"event-oid":_vm.scratchSimpleMessageEventOid,"facebook-page-id":_vm.selectedFacebookMessengerPageId,"channels":['facebookMessenger'],"data-test-id":"message-list-select"},on:{"select":_vm.handleMessageListChange}}),_vm._v(" "),(_vm.noContacts && !!_vm.selectedFacebookMessengerPageId && !_vm.isFetchingFacebookPages && _vm.hasFetchedInitialFacebookPages)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"There are no valid messenger subscribers"}}):_vm._e(),_vm._v(" "),(_vm.noContacts && !_vm.selectedFacebookMessengerPageId && !_vm.isFetchingFacebookPages && _vm.hasFetchedInitialFacebookPages)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"warning","message":("Please select a Facebook page to see the number of recipients in your " + (!!_vm.currentCampaign ? 'tier' : 'list'))}}):_vm._e(),_vm._v(" "),(_vm.noIntegration && !_vm.isFetchingFacebookPages && _vm.hasFetchedInitialFacebookPages)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"Integration error - <a>please reconnect your Facebook account</a>"},on:{"anchorClick":_vm.handleIntegrationClick}}):_vm._e(),_vm._v(" "),(_vm.currentSelectedMessage && _vm.currentMessageListIsForAllCampaignRegistrants)?_c('ar-snackbar',{staticClass:"u-margin-top-5",attrs:{"type":"warning","message":"Changing from All Recipients to a Tier will disable scheduling."}}):_vm._e(),_vm._v(" "),(_vm.currentCampaignIsActive && _vm.currentMessageListIsForCampaignTier)?_c('ar-state-message',{staticClass:"u-margin-top-5",attrs:{"text-props":{
        weight: 'bold',
      },"has-icon":"","disable-text-color":"","type":"information","text":"Note: It's recommended to only message individual tiers once the campaign is ended."}}):_vm._e()],1),_vm._v(" "),(_vm.showAdvancedTargetingModule)?_c('am2-message-list-advanced-targeting-section',{attrs:{"channel":"facebookMessenger","show-toggle":""},on:{"updateAdvancedTargeting":_vm.handleAdvancedTargetingUpdate}}):_vm._e(),_vm._v(" "),(_vm.messageList)?_c('ar-field',{style:({
      margin: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '32px 0' : '44px 0 60px',
    }),attrs:{"label":"From"}},[_c('am2-facebook-page-select',{staticClass:"select",attrs:{"value":_vm.selectedFacebookMessengerPageId},on:{"select":_vm.handleFacebookPageSelect}}),_vm._v(" "),(_vm.selectedFacebookMessengerPageId && !_vm.selectedFacebookMessengerPage && !_vm.isFetchingFacebookPages && _vm.hasFetchedInitialFacebookPages)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"The page you selected is currently unavailable - <a>please reconnect your Facebook account</a>","allow-html":""},on:{"anchorClick":_vm.handleIntegrationClick}}):_vm._e(),_vm._v(" "),(_vm.facebookPageSelectionHasChanged && !_vm.isFetchingFacebookPages && _vm.hasFetchedInitialFacebookPages)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"warning","message":"Changing Facebook Page will reduce the number of valid recipients in your message"}}):_vm._e(),_vm._v(" "),(_vm.facebookPageIsNotAuthorized && !_vm.isFetchingFacebookPages && _vm.hasFetchedInitialFacebookPages)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"This page is not authorized to send messages. Please <a target='__blank' href='https://audiencerepublic.com/contact-us?type=organizer'>contact sales</a> or <a href='/settings/subscriptions'>purchase a subscription</a> to continue.","allow-html":""}}):_vm._e(),_vm._v(" "),(_vm.integrationDisconnected)?_c('ar-snackbar',{style:({ marginTop: '10px' }),attrs:{"type":"error","message":"Your Facebook Page has been disconnected.<br/><a>Re-connect Facebook Account</a>"},on:{"anchorClick":_vm.handleIntegrationClick}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"u-position-relative",style:({
      marginTop: '40px',
    })},[_c('div',{staticClass:"u-position-absolute u-display-flex u-justify-content-flex-end",style:({ top: 0, right: 0 })},[_c('am2-link-button-dropdown',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.isListValid ? 'When you select a list with valid Facebook Messenger subscribers you’ll be able to use dynamic tags.' : null
        }),expression:"{\n          content: isListValid ? 'When you select a list with valid Facebook Messenger subscribers you’ll be able to use dynamic tags.' : null\n        }",modifiers:{"top":true}}],attrs:{"items":_vm.dynamicTagSelectItems,"button-props":{
          hasArrow: false,
          hasUnderline: true,
        },"disabled":_vm.isListValid || !_vm.scratchSimpleMessage.meta.messageListOid,"item-style":{
          height: '44px',
          padding: '0 20px',
        },"divider-style":{
          margin: '0',
        },"placeholder":"Insert dynamic tag","dropdown-style":{
          padding: '0',
        },"data-test-id":"insert-dynamic-tag-link"},on:{"select":_vm.handleInsertDynamicTagsSelect}})],1)]),_vm._v(" "),_c('ar-field',{attrs:{"label":"Message","error-messages":[_vm.veeErrors.first('messageBody')]}},[_c('SimpleMessageEditor',{directives:[{name:"validate",rawName:"v-validate",value:({
        required: true,
        max: _vm.scratchSimpleMessage.meta.messageBody.length + _vm.charactersLeft,
      }),expression:"{\n        required: true,\n        max: scratchSimpleMessage.meta.messageBody.length + charactersLeft,\n      }"}],ref:"simple-message-editor",attrs:{"dynamic-tags":_vm.dynamicTags,"value":_vm.scratchSimpleMessage.meta.messageBody,"data-vv-name":"messageBody"},on:{"input":_vm.handleMessageBodyInput}}),_vm._v(" "),(_vm.nonExistingTagsInSimpleMessageErrorCopy)?_c('ar-snackbar',{style:({
        marginTop: '10px',
      }),attrs:{"type":"error","message":_vm.nonExistingTagsInSimpleMessageErrorCopy}}):_vm._e(),_vm._v(" "),_c('am2-characters-left-copy-section',{style:({
        marginTop: '10px',
      }),attrs:{"current-length":_vm.scratchSimpleMessage.meta.messageBody.length,"tooltip":"This number includes the largest possible values represented by your dynamic tags. It may not match the number of characters in your message preview","max-length":_vm.scratchSimpleMessage.meta.messageBody.length + _vm.charactersLeft}})],1),_vm._v(" "),_c('ar-field',{style:({
      marginTop: '40px',
    }),attrs:{"label":"Schedule?"}},[_c('ar-checkbox',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
        content: !_vm.currentMessageListCanBeSchedued && !_vm.enableSchedule ? 'You cannot schedule messages from campaigns' : null,
      }),expression:"{\n        content: !currentMessageListCanBeSchedued && !enableSchedule ? 'You cannot schedule messages from campaigns' : null,\n      }",modifiers:{"top":true}}],attrs:{"value":_vm.enableSchedule,"label":"Schedule a time to send this message","disabled":_vm.currentSelectedMessageUiStatus === 'scheduled' || !_vm.currentMessageListCanBeSchedued},on:{"input":_vm.handleEnableScheduleToggle}}),_vm._v(" "),(_vm.enableSchedule)?_c('div',{style:({
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '20px',
        flexDirection: _vm.$arMediaQuery.pageContent.minWidth('md') ? 'row' : 'column',
      })},[_c('ar-field',{class:[
          'u-color-blueGrey700',
          'u-flex-shrink-0' ],attrs:{"label":"Start","error-messages":[_vm.veeErrors.first('scheduledAt')]}},[_c('am2-date-time-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|tzFormat|afterNow'),expression:"'required|tzFormat|afterNow'"}],attrs:{"value":_vm.scratchSimpleMessage.scheduledAt,"time-zone":_vm.scratchSimpleMessage.meta.presentation.timeZone,"data-vv-name":"scheduledAt","data-vv-as":"scheduled time"},on:{"input":_vm.handleScheduledAtInput}})],1),_vm._v(" "),(_vm.$arMediaQuery.pageContent.minWidth('md'))?_c('ar-divider',{style:({
          position: 'relative',
          top: '38px',
          width: '50px',
          margin: '0 10px',
        })}):_vm._e(),_vm._v(" "),_c('ar-field',{class:[
          'u-flex-shrink-0' ],style:({
          marginTop: _vm.$arMediaQuery.pageContent.maxWidth('sm') ? '24px' : null,
          width: _vm.$arMediaQuery.pageContent.minWidth('md') ? 'calc(100% - 340px)' : '100%',
          color: _vm.$arStyle.color.blueGrey700,
        }),attrs:{"label":"Timezone","error-messages":[_vm.veeErrors.first('timeZone')]}},[_c('am2-timezone-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"value":_vm.selectedTimezone,"data-vv-name":"timeZone"},on:{"input":_vm.handleTimezoneInput}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }