<template>
  <div id="editable-wrapper" class="editable-wrapper" @keydown.esc="handleEscape" tabindex="0" >
    <component
      :is="cardType(item)"
      :item="item"
      @close="handleClose"
      @dropdown-open="handleOpen"
    />
  </div>
</template>
<script>
import PurchasedTicket from './PurchasedTicket'
import PurchasedProduct from './PurchasedProduct'
import RegistersForCampaign from './RegistersForCampaign'
import TriggerTagAdded from './TriggerTagAdded'
import TriggerTagRemoved from './TriggerTagRemoved'
import TriggerWebhook from './TriggerWebhook'
import SendSMS from './SendSMS'
import SendEmail from './SendEmail'
import TagAdd from './TagAdd'
import TagRemove from './TagRemove'
import IfElse from './IfElse'
import DelayBy from './DelayBy'
import WaitUntil from './WaitUntil'
import ListAdd from './ListAdd'
import ListRemove from './ListRemove'
import ListSubscribed from './ListSubscribed'
import ListUnsubscribed from './ListUnsubscribed'
import ShortURLVisited from './ShortURLVisited'
import Unique from './Unique'
import Birthday from './TriggerBirthday'
import EventDate from './EventDate'
import { mapState, mapActions } from 'vuex'
import { automationCardType } from '../../../utils'
export default {
  name: 'SidebarEditable',
  components: {
    purchasedTicket: PurchasedTicket,
    purchasedProduct: PurchasedProduct,
    registersForCampaign: RegistersForCampaign,
    triggerTagAdded: TriggerTagAdded,
    triggerTagRemoved: TriggerTagRemoved,
    triggerWebhook: TriggerWebhook,
    sendSMS: SendSMS,
    sendEmail: SendEmail,
    tagAdd: TagAdd,
    tagRemove: TagRemove,
    ifElse: IfElse,
    delayBy: DelayBy,
    waitUntil: WaitUntil,
    listAdd: ListAdd,
    listRemove: ListRemove,
    listSubscribed: ListSubscribed,
    listUnsubscribed: ListUnsubscribed,
    shortUrlVisited: ShortURLVisited,
    unique: Unique,
    triggerBirthday: Birthday,
    eventDate: EventDate
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      childDropdownIsOpen: false,
    }
  },
  computed: {
    ...mapState({
      editableItem: state => state.automation.editableItem
    }),
    resetToTab() {
      if (!this.editableItem) return
      if (this.editableItem.type === 'action' || this.editableItem.type === 'control') {
        return 'actions'
      }
      return 'triggers'
    }
  },
  methods: {
    ...mapActions({
      clearEditable: 'automation/CLEAR_EDITABLE',
    }),
    handleEscape() {
      if (this.childDropdownIsOpen) return
      this.clearEditable(this.resetToTab)
    },
    handleOpen(val) {
      this.childDropdownIsOpen = val
    },
    cardType(item) {
      return automationCardType(item)
    },
    handleClose() {
      this.clearEditable(this.resetToTab)
    },

    capitalizeFirstLetter([ first, ...rest ]) {
      return first.toUpperCase() + rest.join('')
    }
  }
}
</script>
<style lang="scss" scoped>
.editable-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &:focus {
    outline: none;
  }
}
</style>