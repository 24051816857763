<template>
  <am2-field name="registration-fields" class="registration-fields" label="Registration fields">
    <draggable
      :list="value"
    >
      <transition-group name="overlay-fade" tag="div">
        <div
          v-for="(field, idx) of value"
          :key="field.key"
          :data-test-id="`registration-field-${field.key}`"
        >
          <div :class="['u-display-flex', 'u-align-items-center', 'u-justify-content-space-between']" :style="{ height: '52px' }">
          <div :class="['u-display-flex', 'u-align-items-center']">
            <ar-text
              class="u-flex-grow-1"
              size="xs"
              :text="field.name"
              :data-test-id="`registration-field-${idx}-text`"
            />
            <ar-icon
              v-if="!field.predefined"
              class="icon edit-button"
              name="edit"
              height="10px"
              width="10px"
              stroke-width="4"
              stroke-linecap="round"
              :color="$arStyle.color.blueGrey700"
              @click="handleEditCustomField(field)"
              :data-test-id="`registration-field-${idx}-edit-button`"
            />
          </div>
            <ar-icon
              v-if="!(mandatoryRegistrationFields.indexOf(field.key) > -1)"
              class="icon remove-button"
              name="cross"
              height="10px"
              width="10px"
              stroke-width="4"
              stroke-linecap="round"
              :color="$arStyle.color.blueGrey700"
              @click="handleRemoveField(field)"
              :data-test-id="`registration-field-${idx}-remove-button`"
            />
          </div>
          <ar-divider />
        </div>
      </transition-group>
    </draggable>
    <div class="u-margin-top-5">
      <am2-link-button-dropdown
        placeholder="+ Add field"
        :items="filteredFields"
        search-placeholder="Search fields"
        has-search
        no-option-text="No other fields found"
        max-height="300px"
        @searchStringChange="handleSearchStringChange"
        :dropdown-style="{
          width: '280px',
        }"
        :button-props="{
          hasArrow: false,
          hasUnderline: true,
          textProps: {
            size: 'xs',
          },
        }"
        @select="handleSelectField"
        :hasFixedFooter="!isFreeCampaign"
        @fixedFooterClick="addCustomFieldClick"
        data-test-id="registration-fields-add-field-dropdown"
      />
    </div>
    <am2-select-edit-create-custom-field-modal
      :open="displayCustomFieldsModal"
      @save="handleCustomFieldSave"
      @close="handleCustomFieldCancel"
      @fieldSelected="handleCustomFieldSelected"
      :create-state="customFieldCreateState"
      :selected-custom-field="editCustomField"
      :restricted-mode="true"
    />
  </am2-field>
</template>
<script>
import {mapActions, mapState} from 'vuex';
import CustomFieldsModal from '../custom-fields-modal';
import draggable from 'vuedraggable'
import { clone } from '@/utils/helpers/';

export default {
  name: 'RegistrationFieldsDropdown',
  props: {
    value: {
      type: Array,
    },
    campaignType: {
      type: String,
    },
    // Unselected fields (pre-defined only)
    campaignUnselectedFields: {
      type: Array,
    },
    isFreeCampaign: {
      type: Boolean,
      default: true
    }
  },
  components: {
    draggable,
  },
  data() {
    return {
      searchString: null,
      displayCustomFieldsModal: false,
      editCustomField: null,
      editCustomFieldIndex: null,
      customFieldCreateState: 'select'
    };
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
    }),
    mandatoryRegistrationFields() {
      const mandatoryFields = ['emailAddress'];
      if (this.campaignType === 'preorder') {
        mandatoryFields.push('firstName', 'lastName', 'streetAddress', 'postcode', 'city', 'state', 'country');
      }
      return mandatoryFields;
    },
    filteredFields() {
      if (this.searchString) {
        return this.campaignUnselectedFields.filter((field) => {
          return this.$arFuzzySearch(field.name, this.searchString);
        });
      }
      return this.campaignUnselectedFields;
    },
    campaignOid() {
      return this.$route.params.oid || null;
    },
    selectedCustomFields() {
      return this.value.filter( item => !item.predefined && !!item.oid)
    },
  },
  methods: {
    handleSelectField(field) {
      // Move item from unselected field to selected fields
      const unselectedIndex = this.campaignUnselectedFields.findIndex((f) => f.key === field.key)
      const selectedValue = this.campaignUnselectedFields[unselectedIndex];
      selectedValue.enabled = true;
      this.$emit('input', this.value.concat(selectedValue))

      const newUnseletedFields = this.campaignUnselectedFields.slice(0, unselectedIndex).concat(this.campaignUnselectedFields.slice(unselectedIndex + 1));
      this.$emit('updateUnselectedFields', newUnseletedFields)
    },
    handleRemoveField(field) {
      const selectedIndex = this.value.findIndex((f) => f.key === field.key)

      if (!!field.predefined) {
        // Put the removed value into unselectedFields
        const removedValue = this.value[selectedIndex]
        removedValue.enabled = false;
        this.$emit('updateUnselectedFields', this.campaignUnselectedFields.concat(removedValue))
      }

      // Remove index from fields
      const newFields = this.value.slice(0, selectedIndex).concat(this.value.slice(selectedIndex + 1));
      this.$emit('input', newFields)
    },
    async handleEditCustomField(field) {
      const index = this.value.findIndex((f) => (f.predefined === false && f.name === field.name));
      if (!!field.oid) {
        const data = await this.$api.customFields.getCustomField(this.promoterOid, field.oid);
        this.editCustomField = data;
        this.editCustomFieldIndex = index;
        this.customFieldCreateState = 'edit';
        this.displayCustomFieldsModal = true;
      } else {
        this.$arNotification.push({ type: 'error', message: 'Cannot edit this field. Please create a new one' });
      }
    },
    handleSearchStringChange(value) {
      this.searchString = value;
    },
    addCustomFieldClick() {
      this.customFieldCreateState = 'select'
      this.displayCustomFieldsModal = true;
    },
    appendCustomFieldToRegoOptions(field) {
      const newCustomField = {
        ...field,
        key: field.name,
        predefined: false,
        enabled: true,
        type: field.fieldType,
        campaignOid: this.campaignOid,
      }
      const selectedIndex = this.value.length;
      const newSelectedFields = this.value.concat(newCustomField)
      if (selectedIndex !== null && selectedIndex !== undefined) {
        newSelectedFields[selectedIndex] = {
          ...newSelectedFields[selectedIndex],
        }
      }
      this.$emit('input', newSelectedFields);
    },
    overwriteExistingFieldInRegoOptions(field) {
      let selectedIndex;
      let newSelectedFields = clone(this.value);
      const index = this.editCustomFieldIndex;
      const oldKey = !!this.value ? (this.value[index]?.name || null) : null
      const updatedExistingField = {
        ...this.value[this.editCustomFieldIndex],
        ...field
      }
      const newCustomField = {
        ...updatedExistingField,
        key: updatedExistingField.name,
        predefined: false,
        enabled: true,
        type: updatedExistingField.fieldType,
        campaignOid: this.campaignOid,
      }
      if (oldKey !== null) {
        // Find the existing selected field based on the old key
        selectedIndex = this.value.findIndex((f) => f.predefined === false && f.key === oldKey)
        if (selectedIndex !== null && selectedIndex !== undefined) {
          newSelectedFields = clone(this.value);
          newSelectedFields[selectedIndex] = {
            ...newCustomField
          }
        }
      }
      this.$emit('input', newSelectedFields);
    },
    handleCustomFieldSelected(field) {
      const existingValueIndex = this.value.findIndex( item => item.oid === field.oid );
      if (existingValueIndex > -1) {
        this.$arNotification.push({ type: 'error', message: 'This custom field has already been added' });
        return;
      }
      this.appendCustomFieldToRegoOptions(field)
      this.displayCustomFieldsModal = false;
      this.editCustomField = null
      this.editCustomFieldIndex = null
      this.customFieldCreateState = 'select';
    },
    async handleCustomFieldSave(editingMode, field) {
      if (editingMode === 'new' || editingMode === 'select') {
        const data = await this.$api.customFields.createCustomField({
          promoterOid: this.promoterOid,
          ...field,
        })
        this.appendCustomFieldToRegoOptions(data)
      } else if (editingMode === 'edit') {
        let data = field; // Here you need to update the PromoterFormFieldInstance if it exists
        if (field.visibility === 'profile') {
          this.$arNotification.push({ type: 'error', message: 'Cannot edit Profile fields from within a campaign' });
          return;
        }
        this.overwriteExistingFieldInRegoOptions(data);
      }
      this.displayCustomFieldsModal = false;
      this.editCustomField = null;
      this.editCustomFieldIndex = null
      this.customFieldCreateState = 'select';
    },
    handleCustomFieldCancel() {
      this.displayCustomFieldsModal = false;
      this.editCustomField = null;
      this.editCustomFieldIndex = null
      this.customFieldCreateState = 'select';
    },
  },
};
</script>
<style lang="scss" scoped>
.remove-button, .edit-button {
  cursor: pointer;
}
.edit-button {
  margin-left: 10px;
}
</style>
