import { render, staticRenderFns } from "./events-overview-tables.vue?vue&type=template&id=95408d6e&scoped=true&"
import script from "./events-overview-tables.vue?vue&type=script&lang=js&"
export * from "./events-overview-tables.vue?vue&type=script&lang=js&"
import style0 from "./events-overview-tables.vue?vue&type=style&index=0&id=95408d6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95408d6e",
  null
  
)

export default component.exports