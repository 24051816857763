var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.opened}},[_c('ar-modal',{staticClass:"view-filters-modal",attrs:{"is-open":_vm.opened,"header":"Sent to","width":"800px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleModalClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-icon"},[_c('SendMessage',{attrs:{"height":"32px","width":"32px","fill":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('div',{staticClass:"heading-columns"},[_c('div',{staticClass:"heading-column left"},[_c('ar-text',{staticClass:"subheading",style:({
                color: ("" + (_vm.$arStyle.color.skyBlueGrey700)),
              }),attrs:{"text":"Sent to","size":"14px"}}),_vm._v(" "),_c('ar-text',{staticClass:"heading",attrs:{"text":_vm.messageListName,"size":"16px"}})],1),_vm._v(" "),_c('div',{staticClass:"heading-column right"},[_c('ar-text',{staticClass:"subheading",style:({
                color: ("" + (_vm.$arStyle.color.skyBlueGrey700)),
              }),attrs:{"text":"Filtered subscribers","size":"14px"}}),_vm._v(" "),_c('ar-text',{staticClass:"heading",attrs:{"text":_vm.recipientTotal,"size":"16px"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"filters"},_vm._l((_vm.displayedFilters),function(block,blockIndex){return _c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: block.text,
          }),expression:"{\n            content: block.text,\n          }"}],key:blockIndex,staticClass:"filter-tag",attrs:{"type":block.type === 'condition' ? 'purple' : 'grey',"shape":"rectangle","text":block.text}})}),1),_vm._v(" "),(_vm.filterBlocks && _vm.filterBlocks.length > _vm.MIN_ITEMS_DISPLAYED)?_c('ar-link-button',{staticClass:"u-margin-top-4",attrs:{"text":_vm.areFiltersTruncated ? 'View all' : 'View less'},on:{"click":_vm.toggleViewAllLink}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"footer",attrs:{"slot":"footer"},slot:"footer"},[_c('ar-link-button',{style:({
          height: '40px',
          padding: '0 20px'
        }),attrs:{"text":"Go to list"},on:{"click":_vm.handleOpenList}}),_vm._v(" "),_c('ar-simple-button',{attrs:{"text":"Use filters"},on:{"click":_vm.handleViewFilteredAudience}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }