<template>
  <section data-test-id="users-page" class="users-page">
    <div
      class="u-margin-top-6"
      v-infinite-scroll="fetchMoreData"
      infinite-scroll-disabled="disableFetchMoreMessages"
      :infinite-scroll-distance="10"
      :style="{
        width: '100%',
      }"
    >
      <am2-top
        :search="{
          action: serverSideSearch,
          placeholder: 'Search users by email, name or oid',
          value: searchString,
          style: {
            width: '350px',
          }
        }"
  
        title="Users"
        :class="[
          'top-wrapper',
          'u-margin-bottom-8',
          $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
        ]"
      >
      </am2-top>
  
      <div
        v-if="isFetchingPromoterAccounts && promoterAccounts.length === 0"
        class="loading-container"
      >
        <am2-loading-spinner size="48px" />
      </div>
      <am2-table
        v-else
        :heads="tableHeadings"
        class="message-table"
        :rows="promoterAccounts"
        has-sticky-header
        enable-row-click
        has-control-section
        :default-sort-by="sortBy"
        @rowClick="handlePromoterRowClick"
        @sortBy="handleSortBy"
      >
        <div
          slot="oid"
          slot-scope="{ data }"
          class="number-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.oid"
            />
          </div>
        </div>
        <div
          slot="emailAddress"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.emailAddress"
            />
          </div>
        </div>
        <div
          slot="firstName"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.firstName"
            />
          </div>
        </div>
        <div
          slot="lastName"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.lastName"
            />
          </div>
        </div>
        <div
          slot="sysCtime"
          slot-scope="{ data }"
          class="message-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="formattedDate(data.sysCtime)"
            />
          </div>
        </div>
      </am2-table>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { generateMessageCenterDateCopy } from '@/utils/date';

export default {
  name: 'Users',
  layout: 'default',

  data: () => ({
    searchString: '',
    sortBy: { key: 'emailAddress', order: 'asc' },
  }),

  computed: {
    ...mapState({
      promoterAccounts: state => state.admin.promoterAccounts,
      isFetchingPromoterAccounts: state => state.admin.isFetchingPromoterAccounts,
    }),

    ...mapGetters ({
      isAdminAccount: 'auth/isAdminAccount'
    }),

    tableHeadings() {
      return [
        {
          name: 'OID',
          key: 'oid',
          format: 'oid',
          align: 'left',
          width: 75,
          sortKey: 'oid'
        },
        {
          name: 'Firstname',
          key: 'firstName',
          format: 'firstName',
          align: 'left',
          width: 162,
          sortKey: 'firstName'
        },
        {
          name: 'Lastname',
          key: 'lastName',
          format: 'lastName',
          align: 'left',
          width: 162,
          sortKey: 'lastName'
        },
        {
          name: 'Email',
          key: 'emailAddress',
          format: 'emailAddress',
          align: 'left',
          width: 233,
          sortKey: 'emailAddress'
        },
        {
          name: 'Created',
          key: 'sysCtime',
          format: 'sysCtime',
          align: 'left',
          width: 190,
          sortKey: 'sysCtime'
        },
      ];
    },
  },

  mounted() {
    if (!this.isAdminAccount) {
      this.$router.push({
        path: '/audience',
      });
    }
  },

  methods: {
    ...mapActions([
      'admin/FETCH_MORE_PROMOTER_ACCOUNTS',
      'admin/DELETE_PROMOTER',
      'SHOW_CONFIRM',
    ]),
    ...mapMutations([
      'admin/RESET_PROMOTER_ACCOUNTS',
    ]),

    handleSortBy(sortBy) {
      this.sortBy = sortBy
      this.fetchPromoterAccounts(true)
    },

    formattedDate(date) {
      return generateMessageCenterDateCopy(date)
    },

    handlePromoterRowClick(promoterAccount) {
      this['admin/RESET_PROMOTER_ACCOUNTS']();
      this.$router.push({
        path: `/admin/companies/${promoterAccount.promoterOid}/edit/accounts?selected=${promoterAccount.oid}`
      });
    },

    fetchMoreData() {
      if (!this.isFetchingPromoterAccounts) {
        this.fetchPromoterAccounts()
      }
    },

    serverSideSearch(search) {
      this.searchString = search;
      this.fetchPromoterAccounts(true)
    },

    fetchPromoterAccounts(reload=false) {
      const { key, order } = this.sortBy

      this['admin/FETCH_MORE_PROMOTER_ACCOUNTS']({
        orderby: `${key} ${order}`,
        reload,
        count: reload,
        filterString: this.searchString,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.users-page {
  width: 100%;
  max-width: 100%;
}

.top-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.message-cell, .number-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-cell {
  padding: 0 12px;
  overflow: hidden;
  justify-content: flex-start;
}

.number-cell {
  padding: 0 17px;
}

.action-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>