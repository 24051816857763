<template>
  <ar-modal
    :is-open="showModal"
    class="delete-modal"
    width="500px"
    hide-header
    hide-footer
    :mobile="$arMediaQuery.window.maxWidth('xs')"
    @close="handleCloseClick"
  >
    <div
      slot="body"
      :class="[
        'delete-modal-body',
        $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-5',
      ]"
    >
      <div class="exclamation-mark">
        <ar-icon
          v-bind="{
            name: 'discard',
            color: this.$arStyle.color.red500,
            width: '34px',
            height: '34px',
            wrapperStyle: {
              backgroundColor: this.$arStyle.color.red100,
              borderRadius: '50%',
              width: '70px',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          }"
        />
      </div>
      <ar-text
        v-if="title"
        class="delete-modal-title"
        :text="title"
        multiple-lines
        weight="bold"
        align="center"
        size="md"
      />

      <ar-text
        class="warning-modal-message"
        size="xs"
        :text="messageHtml"
        allow-html
        multiple-lines
        line-height="25px"
        :style="{
          marginBottom: messageHtml ? '25px' : '8px',
          color: '#8E97A6'
        }"
        align="center"
      />

      <div v-if="validation" class="validation-section">
        <ar-input
          v-model="validationAnswer"
          :placeholder="validation.question"
          class="input"
          data-test-id="warning-modal-validation-input"
        />
      </div>

      <div
        v-if="validation"
        :class="['delete-modal-button-wrapper', $arMediaQuery.window.maxWidth('xs') && 'xs-max']"
        :style="{
          flexDirection: cancelType === 'link' ? 'column' : 'row',
          alignItems: cancelType === 'link' ? 'center' : null,
        }"
      >
        <ar-simple-button
          :loading="loading"
          @click="handleConfirmClick"
          :disabled="!isValidationConfirmButtonValid"
          v-bind="confirmButtonPropsComputed"
        />
        <ar-simple-button
          v-if="cancelType === 'button'"
          :loading="loading"
          :class="$arMediaQuery.pageContent.minWidth('sm') ? 'u-margin-left-2' : 'u-margin-top-3'"
          @click="handleCloseClick"
          v-bind="cancelButtonPropsComputed"
        />
        <ar-link-button
          v-else-if="cancelType === 'link'"
          :disabled="loading"
          class="u-margin-top-5"
          @click="handleCloseClick"
          v-bind="cancelButtonPropsComputed"
        />
      </div>
    </div>
  </ar-modal>
</template>

<script>
  export default {
    name: 'DeleteModal',

    data() {
      return {
        loading: false,
        showModal: false,
        title: null,
        validation: null,
        validationAnswer: null,
        messageHtml: null,
        cancelType: null,
        deleteText: null,
        cancelButtonProps: {},
        confirmButtonProps: {},
        deleteTextareaProps: {},
        onConfirm: () => {},
        onClose: () => {}
      }
    },

    computed: {
      confirmButtonPropsComputed() {
        return {
          type: "red",
          text: this.deleteText || "Proceed with delete",
          style: {
            width: '250px',
            borderRadius: '5px',
          },
          ...this.confirmButtonProps,
        }
      },
      cancelButtonPropsComputed() {
        let deleteText = "Don't delete";

        return {
          type: "grey",
          text: deleteText,
          style: {
            width: '250px',
            borderRadius: '7px',
          },
          ...this.cancelButtonProps,
        }
      },
      isValidationConfirmButtonValid() {
        if (this.loading) {
          return false;
        }
        if (this.validation.answer !== this.validationAnswer) {
          return false;
        }
        return true;
      },
    },

    created() {
      window.addEventListener('arModalOpen', this.handleModalOpen);
    },

    beforeDestroy() {
      window.removeEventListener('arModalOpen', this.handleModalOpen);
    },

    methods: {
      handleModalOpen({ detail: { action, payload } }) {
        if (action !== 'OPEN_DELETE_MODAL') return;

        this.startModalAction(payload);
      },
      respondModalAction(payload) {
        window.dispatchEvent(new CustomEvent('arModalResponse', { detail: { action: 'OPEN_DELETE_MODAL', payload } }));
      },
      startModalAction({
        confirmButtonProps,
        confirmButtonFormatter,
        title,
        messageHtml,
        validation,
        cancelType,
        cancelButtonProps,
        deleteTextareaProps,
        deleteText,
        cancelText,
        // If you need some action to be completed before we close the modal
        asyncCompleteFunction,
      }) {
        this.title = title;
        this.messageHtml = messageHtml;
        this.validation = validation;
        if (cancelText) this.cancelText= cancelText;
        this.confirmButtonProps = confirmButtonProps || {};
        this.confirmButtonFormatter = confirmButtonFormatter || null;
        this.deleteTextareaProps = deleteTextareaProps || {};
        this.deleteText = deleteText;

        this.cancelType = cancelType || null;
        this.cancelButtonProps = cancelButtonProps || {};

        this.showModal = true;

        this.onConfirm = async () => {
          this.validationAnswer = null;
          this.respondModalAction(true);
          this.showModal = false;
        };
        this.onClose = () => {
          this.validationAnswer = null;
          this.respondModalAction(false);
          this.showModal = false;
        }
      },
      handleConfirmClick() {
        this.onConfirm();
      },
      handleCloseClick() {
        this.onClose();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .delete-modal {

    .delete-modal-title {
      margin-bottom: 12px;
    }
    .delete-modal-body {
      padding: 0 48px 24px;
    }
    .exclamation-mark {
      display: flex;
      justify-content: center;
      margin: 46px 0 36px;
    }


    .validation-section {
      width: 80%;
      margin: auto;
    }

    .delete-modal-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 36px 0 16px;

      &.xs-max {
        margin: 16px 0;
        flex-direction: column;
      }
    }
  }

</style>
