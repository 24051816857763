<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <am2-see-tickets-us-modal
      :is-show="showSeeTicketsUsModal"
      :loading="isCreatingSeeTicketsUsIntegration"
      @confirm="handleSeeTicketsUsConfirm"
      @cancel="showSeeTicketsUsModal = false"
    />
    <am2-opt-in-settings-modal
      :is-show="showOptinSettingsModal"
      @cancel="showOptinSettingsModal = false"
      providerName="See Tickets US"
      :message-list-oid="integrationMessageListOid"
    />

    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="See Tickets US"
          description="Automatically sync your members, events, orders from See Tickets US"
          :icon-props="{
            name: 'see-tickets-us',
            color: $arStyle.color.seeTicketsUs,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingSeeTicketsUsIntegration && integrationsSummary">
        <am2-integration-account-select
          v-if="!isFetchingSeeTicketsUsIntegration && seeTicketsUsIntegration && currentIntegration"
          :default-selected-integration-oid="currentIntegration.oid"
          :available-integrations="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'See Tickets US Settings',
              value: 'openSettingsModal'
            },
            {
              name: 'Reconnect Integration',
              value: 'reconnectIntegration',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>
    <am2-tabs
      :items="[
        { title: 'Notifications' },
        { title: 'Status' }
      ]"
      :selected-tab-index="selectedTabIndex"
      class="tab-options"
      @select="(selected, idx) => selectedTabIndex = idx"
    />
    <div
      v-if="selectedTabIndex === 0"
    >
      <NotificationsContainer
        :integration="currentIntegration"
        :loading="isFetchingSeeTicketsUsIntegration"
      />
    </div>

    <div
      v-if="selectedTabIndex === 1"
      class="integration-status-container"
    >
      <section v-if="isAddingSeeTicketsUsIntegration || isFetchingSeeTicketsUsIntegration" class="integration-loading-container">
        <am2-loading-bubble />
      </section>
      <section v-else>
        <section v-if="integrationFailed" class="integration-failed-message">
          <ar-snackbar
            type="warning"
            message="Your See Tickets US connection is no longer valid. This means we can't sync your member data. <a>Please reconnect your account</a>"
            @anchorClick="handleReconnectIntegration"
            :style="{ width: '100%' }"
          />
        </section>
        <SyncBlockContainer
          v-else
          class="sync-block-container"
          :is-fetching-sync-task="isFetchingSyncTask"
          :sync-status="syncStatus"
          :sync-time="syncTime"
          :sync-copies="{
            'in-progress': 'Syncing data from See Tickets US',
            'pending': 'The data sync is still pending',
            'completed': 'Your See Tickets US account is currently connected',
            'cancelled': 'Your See Tickets US account connection is cancelled',
            'failed': this.syncErrorMessage,
            'default': 'Syncing tasks not found',
          }"
          @startSync="handleStartSync"
          @stopSync="handleStopSync"
        />
      </section>
    </div>
  </section>
</template>
    
  <script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import SyncBlockContainer from '../components/sync-block-container';
  import NotificationsContainer from '../components/notifications-container';
  export default {
    name: 'SeeTicketsUsIntegration',
    layout: 'default',
    components: {
      SyncBlockContainer,
      NotificationsContainer
    },
  
    data: () => ({
      currentlySelectedIntegrationIndex: 0,
      syncStatusPoller: null,
      showSeeTicketsUsModal: false,
      selectedTabIndex: 1,
      showOptinSettingsModal: false
    }),
    computed: {
      ...mapState({
        seeTicketsUsIntegration: state => state.seeTicketsUsIntegration.integration,
        isFetchingSeeTicketsUsIntegration: state => state.seeTicketsUsIntegration.isFetchingIntegration,
        isAddingSeeTicketsUsIntegration: state => state.seeTicketsUsIntegration.isAddingIntegration,
        isCreatingSeeTicketsUsIntegration: state => state.seeTicketsUsIntegration.isCreatingIntegration,
        isDeletingSeeTicketsUsIntegration: state => state.seeTicketsUsIntegration.isDeletingIntegration,
        isFetchingSyncTask: state => state.seeTicketsUsIntegration.isFetchingSyncTask,
        syncTask: state => state.seeTicketsUsIntegration.syncTask,
        syncStatus: state => state.seeTicketsUsIntegration.syncStatus,
      }),
  
      syncErrorMessage() {
        return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason;
      },
  
      syncTime() {
        return this.syncTask?.sysMtime || null;
      },
  
      integrationsSummary() {
        if (!this.seeTicketsUsIntegration) return null;
        const summary = this.seeTicketsUsIntegration
          .map(i => ({
            oid: i.oid,
            name: i.integration?.accountId,
            email: i.integration?.accountId
          }));
        return summary
      },
      
      currentIntegration() {
        if (this.currentlySelectedIntegrationIndex === null) return null;
        if (!this.seeTicketsUsIntegration || this.seeTicketsUsIntegration.length < 1 || !this.seeTicketsUsIntegration[this.currentlySelectedIntegrationIndex]) return null;
        return this.seeTicketsUsIntegration[this.currentlySelectedIntegrationIndex];
      },

      integrationMessageListOid() {
        return this.currentIntegration ? this.currentIntegration?.meta?.messageListOid : null;
      },
  
      integrationFailed() {
        if (!this.currentIntegration) return false;
        return this.currentIntegration.status === 'failed';
      },
    },
  
    async mounted() {
      await this.fetchIntegration();
      this.startFetchTasksPolling();
    },
    beforeDestroy() {
      this['seeTicketsUsIntegration/RESET_INTEGRATION']();
  
      if (this.syncStatusPoller) {
        clearInterval(this.syncStatusPoller);
      }
    },
  
    methods: {
      ...mapActions([
        'SHOW_CONFIRM',
        'seeTicketsUsIntegration/FETCH_INTEGRATION',
        'seeTicketsUsIntegration/DELETE_INTEGRATION',
        'seeTicketsUsIntegration/SYNC_START',
        'seeTicketsUsIntegration/SYNC_STOP',
        'seeTicketsUsIntegration/FETCH_SYNC_TASK',
        'seeTicketsUsIntegration/CREATE_SEE_TICKETS_US_INTEGRATION',
        'promoterTasks/START_POLLING_PENDING_TASKS',
      ]),

      ...mapMutations([
        'seeTicketsUsIntegration/RESET_INTEGRATION',
      ]),

      startFetchTasksPolling() {
        if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
          this.fetchTasks();
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },
      async fetchIntegration() {
        const success = await this['seeTicketsUsIntegration/FETCH_INTEGRATION']()
        if ((!success || !this.seeTicketsUsIntegration || this.seeTicketsUsIntegration.length === 0) && this.$route.path != '/settings/integrations') {
          this.$router.push({
            path: '/settings/integrations',
          });
        }
      },
  
      async fetchTasks() {
        await this['seeTicketsUsIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);
  
        if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
          window.clearInterval(this.syncStatusPoller);
          this.syncStatusPoller = null;
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }
      },
  
      async handleSeeTicketsUsConfirm({ clientId, apiKey, apiSecret }) {
        const succeed = await this['seeTicketsUsIntegration/CREATE_SEE_TICKETS_US_INTEGRATION']({
          clientId,
          apiKey,
          apiSecret,
          messageListOid
        });
        if (succeed) {
          this.fetchIntegration();
  
          this.showSeeTicketsUsModal = false;
        }
      },
  
      hasExistingTask() {
        return this.syncTask !== null;
      },
  
      handleBackLinkClick() {
        this.$router.push({ path: '/settings/integrations' });
      },
  
      handleIntegrationChange(integration, index) {
        this.currentlySelectedIntegrationIndex = index;
        this.fetchTasks();
      },
  
      async handleAddNewIntegration() {
        this.showSeeTicketsUsModal = true;
      },

      async handleStartSync() {
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
        await this['seeTicketsUsIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
        if (this.syncStatus !== 'completed') {
          this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
        }
      },

      handleStopSync() {
        this['seeTicketsUsIntegration/SYNC_STOP']({ oid: this.currentIntegration.oid });
        setTimeout(() => {
          this['promoterTasks/START_POLLING_PENDING_TASKS']({});
        }, 750);
      },

      async handleReconnectIntegration() {
        const agreed = await this.SHOW_CONFIRM({
          messageHtml: `Make sure you are logging in with the See Tickets US account <strong>${this.currentIntegration.accountId}</strong> on See Tickets US before reconnecting it.`,
          confirmButtonText: 'Reconnect account',
        });
        if (!agreed) {
          return;
        }

        const succeed = await this['seeTicketsUsIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
        if (!succeed) {
          return;
        }

        this.handleAddNewIntegration();
      },

      async handleSettingOptionSelect(item) {
        if (item.value === 'removeIntegration') {
          const answer = await this.SHOW_CONFIRM({
            messageHtml: 'Removing the See Tickets US integration will stop syncing your orders and customer data from See Tickets US.',
            confirmButtonText: 'Remove integration',
            iconName: 'alert-question-mark',
            title: 'Are you sure?',
          });
          if (answer) {
            try {
              await this['seeTicketsUsIntegration/DELETE_INTEGRATION'](this.currentIntegration.oid);
              if (this.seeTicketsUsIntegration.length > 0) {
                this.currentlySelectedIntegrationIndex = 0;
                await this.fetchIntegration();
                this.startFetchTasksPolling();
              } else {
                this.$router.push({ path: '/settings/integrations' });
              }
            } catch (e) {
              this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
            }
          }
        } else if (item.value === 'reconnectIntegration') {
          this.handleReconnectIntegration();
        } else if (item.value == 'openSettingsModal') {
          if (!this.currentIntegration) return;
          this.showOptinSettingsModal = true;
        }
      }
    }
  };
  </script>
    
  <style lang="scss" scoped>
  .wrapper {
  
    .tab-options {
      margin: 60px 0 20px 0;
      border-bottom: 1px $skyBlueGrey500 solid;
    }
  
    &.sm-max {
      padding: 0 24px;
    }
  
    &.xs-max {
      padding: 0 12px;
    }
  
    .integration-loading-container {
      display: flex;
      justify-content: center;
      margin-top: 65px;
    }
  
  
    .step-back-link {
      margin-top: 48px;
    }
  
    .title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 48px;
  
      .title-section-left {
        display: inline-flex;
        align-items: flex-start;
  
        .tag {
          position: relative;
          margin-left: 10px;
          top: 4px;
        }
      }
  
      .title-section-right {
        display: inline-flex;
        align-items: center;
  
        .select {
          width: 300px;
          margin-right: 10px;
        }
      }
  
      &.md-max {
        flex-direction: column;
        align-items: flex-start;
  
        .title-section-right {
          margin-top: 16px;
        }
      }
    }
  
    .sync-block-container {
      margin-top: 0px;
    }
  }
  </style>
    