var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"message-list-preferences-modal",attrs:{"is-open":_vm.isShow,"data-test-id":"message-list-preferences-modal","header":"List settings","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"body",attrs:{"slot":"body"},slot:"body"},[_c('div',{class:[
          'modal-content',
          _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ]},[_c('am2-card-container',{staticClass:"u-display-flex u-align-items-center u-justify-content-space-between u-padding-y-6 u-padding-x-7 u-margin-top-4"},[_c('div',{staticClass:"u-display-flex u-flex-flow-column"},[_c('ar-text',{attrs:{"size":"xs","weight":"bold","text":"Manage Subscription Preferences"}}),_vm._v(" "),_c('ar-text',{style:({
                color: _vm.$arStyle.color.blueGrey700,
                paddingTop: '8px',
              }),attrs:{"size":"xs","weight":"normal","text":"Adds option to manage preferences via unsubscribe link"}})],1),_vm._v(" "),_c('am2-switch',{model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1)],1)]),_vm._v(" "),_c('div',{class:[
        'footer',
        _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{attrs:{"text":"Save"},on:{"click":_vm.handleSaveClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }