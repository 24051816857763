import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';

export default (axios: NuxtAxiosInstance) => ({
  /**
   * Get Twilio SMS Sender for promoter
   * @param promoterOid 
   * @returns
   */
  async get(): Promise<TwilioSMSSender | null> {
    const { status, data } = await axios.get(`/companies-sms`);
    if (isErrorStatus(status)) {
      const apiError: CustomApiError = {
        name: 'Get Twilio SMS Sender-enabled companies error',
        message: `Error getting Twilio SMS Sender-enabled companies`,
        status,
      };
      throw apiError;
    }
    return data;
  },
});
