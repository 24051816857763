var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-preview"},[(_vm.mobile)?_c('img',{attrs:{"src":require("assets/images/message-center/email-preview-header.png"),"width":"100%","height":"auto"}}):_vm._e(),_vm._v(" "),(!_vm.opened)?_c('div',{staticClass:"unopened-email",style:({
      borderBottom: !!_vm.senderName || !!_vm.subject || !!_vm.previewText ? null : 'none',
    })},[(!!_vm.senderName)?_c('div',{staticClass:"blue-ball"}):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
        color: 'black',
      }),attrs:{"size":"sm","text":_vm.senderName}}),_vm._v(" "),_c('ar-text',{style:({
        color: 'black',
        marginTop: '8px',
      }),attrs:{"size":"xs","text":_vm.subject,"multipleLines":""}}),_vm._v(" "),_c('ar-text',{style:({
        color: _vm.$arStyle.color.blueGrey700,
        marginTop: '6px',
      }),attrs:{"size":"xs","text":_vm.previewText,"multiple-lines":"","max-lines":2}})],1):_c('div',{staticClass:"opened-email"},[_c('div',{staticClass:"header",style:({
            padding: _vm.mobile ? '0 16px' : '0 32px',
      })},[_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey800,
        }),attrs:{"size":"xs","weight":"bold","text":_vm.senderName}}),_vm._v(" "),_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey800,
          marginTop: '16px',
        }),attrs:{"text":_vm.subject,"weight":"bold","multipleLines":""}}),_vm._v(" "),_c('ar-text',{style:({
        color: _vm.$arStyle.color.blueGrey700,
        marginTop: '6px',
        marginBottom: '24px',
      }),attrs:{"size":"xs","text":_vm.previewText,"multiple-lines":"","max-lines":2}})],1),_vm._v(" "),_c('div',{staticClass:"iframe-wrapper"},[_c('iframe',{ref:"email-iframe",style:({
          marginBottom: '-6px',
        }),attrs:{"width":"100%","height":"100%","frameborder":"0"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }