var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('am2-card-container',{staticClass:"revenue-by-message-stats",attrs:{"layout":"soft"}},[(_vm.isLocked)?_c('div',{staticClass:"revenue-by-message-stats__locked",attrs:{"layout":"soft"}},[_c('am2-card-container',{staticClass:"revenue-by-message-stats__locked-content",attrs:{"layout":"soft"}},[_c('am2-tag',{staticClass:"free-tag",style:({
        marginRight: '16px',
      }),attrs:{"text":"PREMIUM","text-size":"xxxs","shape":"round","type":"gold"}}),_vm._v(" "),_c('div',[_c('ar-text',{style:({
          marginBottom: '4px',
          color: _vm.$arStyle.color.blueGrey800,
      }),attrs:{"text":"Revenue tracking","size":"sm","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
          color: _vm.$arStyle.color.blueGrey700,
      }),attrs:{"text":"Track revenue attributed to all message sends linked to this event ","size":"xs"}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__content",class:[_vm.isLocked && 'locked']},[_c('ar-text',{style:({marginRight: '34px'}),attrs:{"text":"Ticket sales attributed to"}}),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__container"},[_c('div',{staticClass:"revenue-by-message-stats__icon-wrapper"},[_c('ar-icon',{staticClass:"revenue-by-message-stats__icon",attrs:{"name":"email","color":_vm.$arStyle.color.purple500}})],1),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__text"},[(!_vm.isLoading)?[_c('div',{staticClass:"revenue-by-message-stats__label"},[_vm._v(_vm._s(_vm.formatEmailText(_vm.formattedStats.email.count)))]),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__value"},[_vm._v(_vm._s(_vm.regionSettingsCurrencySymbol)+_vm._s(_vm.formatNumber(_vm.formattedStats.email.revenue)))])]:[_c('am2-typography-skeleton',{style:({
            marginBottom: '10px',
            maxWidth: '70px',
        }),attrs:{"size":"xs"}}),_vm._v(" "),_c('am2-typography-skeleton',{style:({
            maxWidth: '150px',
        }),attrs:{"size":"xs"}})]],2)]),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__container"},[_c('div',{staticClass:"revenue-by-message-stats__icon-wrapper"},[_c('ar-icon',{staticClass:"revenue-by-message-stats__icon",attrs:{"name":"sms","color":_vm.$arStyle.color.green500}})],1),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__text"},[(!_vm.isLoading)?[_c('div',{staticClass:"revenue-by-message-stats__label"},[_vm._v(_vm._s(_vm.formatSmsText(_vm.formattedStats.sms.count)))]),_vm._v(" "),_c('div',{staticClass:"revenue-by-message-stats__value"},[_vm._v(_vm._s(_vm.regionSettingsCurrencySymbol)+_vm._s(_vm.formatNumber(_vm.formattedStats.sms.revenue)))])]:[_c('am2-typography-skeleton',{style:({
            marginBottom: '10px',
            maxWidth: '70px',
        }),attrs:{"size":"xs"}}),_vm._v(" "),_c('am2-typography-skeleton',{style:({
            maxWidth: '150px',
        }),attrs:{"size":"xs"}})]],2)]),_vm._v(" "),(!_vm.isLoading && (_vm.formattedStats.email.revenue === 0 && _vm.formattedStats.sms.revenue === 0))?_c('div',{staticClass:"revenue-by-message-stats__link",on:{"click":_vm.goToMessages}},[_c('ar-text',{style:({
        color: _vm.$arStyle.color.purple500,
        textDecoration: 'underline',
        cursor: 'pointer',
      }),attrs:{"text":"Link previously sent messages","size":"xs"}}),_vm._v(" "),_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            content: 'Select a previously sent message and link one or more events via the conversions tab. Your combined conversions will appear here.',
          }),expression:"{\n            content: 'Select a previously sent message and link one or more events via the conversions tab. Your combined conversions will appear here.',\n          }",modifiers:{"bottom":true}}],staticClass:"u-margin-left-2",attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.blueGrey700,"height":"16px","width":"16px"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }