import { NuxtAxiosInstance } from "@nuxtjs/axios";
import { isErrorStatus } from '../utils';
import { CustomApiError } from '../types';
import { Notification } from './types';
import { makeSafeOrderbyParams } from '~/utils/api/makeSafeOrderbyParams';

export default (axios: NuxtAxiosInstance) => ({
  async fetchNotifications(promoterOid: number, integrationOid: number, pageSize: number, offset: number, orderBy: {key: string, order: string} = { key: 'sysCtime', order: 'desc' }): Promise<Notification[]> {
      const { data, status } = await axios.get(`/promoter/${promoterOid}/integration/${integrationOid}/notification`, {
        params: {
          $top: pageSize,
          $skip: offset,
          $orderby: makeSafeOrderbyParams(orderBy),
          $filter: `promoter_oid=${promoterOid} AND promoter_integration_oid=${integrationOid} AND sysActivep = true`,
          $select: 'sysCtime,message,oid,notificationType,promoterIntegrationOid,promoterOid'
        }
      });
      if (isErrorStatus(status)) {
        const apiError: CustomApiError = {
          name: 'Fetch Notifications error',
          message: `Error fetching Notifications`,
          status,
        };
        throw apiError;
      };
      return data;
    }
});
