<template>
  <div
    data-test-id="company-page-messages"
  >
    <am2-twilio-sub-account-modal
      :is-show="isTwilioSubAccountModalOpen"
      @cancel="closeTwilioSubAccountModal"
      @confirm="handleCreateTwilioSubAccount"
    />
    <am2-twilio-alphanumeric-id-modal
      :is-show="isTwilioAlphanumericIdModalOpen"
      :mode="smsSenderModalMode"
      @cancel="closeTwilioAlphanumerIdModal"
      @confirm="handleCreateTwilioAlphanumericId"
      :data="selectedSMSSender"
    />
    <div class="u-display-flex u-flex-flow-row u-justify-content-space-between u-align-items-center">
      <div>
        <am2-heading
          type="h1"
          title="Alphanumeric SMS"
          size="md"
        />
        <div
          class="u-display-flex u-flex-flow-row"
          :style="{
            marginTop: '12px',
          }"
          v-if="!noMessages && !isFetchingTwilioSubAccounts"
        >
          <ar-text
            size="xs"
            text="Sub-account SID:"
            weight="bold"
          />
          <ar-text
            class="u-margin-left-2"
            size="xs"
            :text="subAccountSID"
          />
          <ar-link-button
            class="u-margin-left-2"
            text="Delete"
            :color="$arStyle.color.red500"
            @click="handleDeleteSubAccount"
          />
        </div>
      </div>
      <div
        class="u-display-flex u-flex-flow-row"
        v-if="!noMessages && !isFetchingTwilioSubAccounts"
      >
        <ar-link-button
          class="u-margin-right-4"
          text="View connection guide"
          @click="handleConnectionGuideClick"
        />
        <ar-simple-button
          :outlined="false"
          text="Add alphanumeric ID"
          @click="openCreateAlphanumericIdModal"
        />

      </div>
    </div>
    <div
      v-if="isFetchingTwilioSubAccounts"
      class="loading-container"
    >
      <am2-loading-spinner size="48px" />
    </div>
    <div
      v-else-if="noMessages"
      :style="{
        marginTop: '32px',
      }"
    >
      <am2-no-content-section
        header="Add a sub-account to start using alphanumeric sender ID’s"
      >
        <template v-slot:button>
          <ar-simple-button
            :outlined="false"
            text="Add sub-account"
            :style="{
              marginTop: '32px',
            }"
            @click="openCreateTwilioSubAccountModal"
          />
        </template>
      </am2-no-content-section>
    </div>
    <div
      v-else
      :style="{
        marginTop: '32px',
      }"
    >
      <am2-table
        :heads="tableHeadings"
        class="message-table"
        :rows="twilioSMSSender"
        has-sticky-header
        empty-text="No data available"
      >
        <div
          slot="friendlyName"
          slot-scope="{ data }"
          class="string-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.friendlyName"
            />
          </div>
        </div>
        <!-- 2 Nov 2023 ADR
          This will be un-commented later on.
         -->
        <!-- <div
          slot="token"
          slot-scope="{ data }"
          class="string-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.token"
            />
          </div>
        </div>
        <div
          slot="link"
          slot-scope="{ data }"
          class="string-cell"
        >
          <div class="body-container">
            <ar-text
              size="xs"
              :text="data.oid"
            />
          </div>
        </div> -->
        <div
          slot="menu"
          slot-scope="{ data }"
          class="menu-cell"
        >
          <div class="action-container">
            <am2-icon-button-dropdown
              :icon-props="{
                name: 'menu',
              }"
              :items="menuOptions"
              @select="(item) => handleMenuOptionSelect(item.eventName, data)"
            />
          </div>
        </div>
      </am2-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'CompanyMessages',

  data() {
    return {
      isTwilioSubAccountModalOpen: false,
      isTwilioAlphanumericIdModalOpen: false,
      menuOptions: [
        {
          name: 'Edit',
          eventName: 'edit',
        },
        {
          name: 'Delete',
          eventName: 'delete',
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          },
        }
      ],
      smsSenderModalMode: 'new',
      selectedSMSSender: null,
    }
  },

  computed: {
    ...mapState({
      currentPromoter: state => state.admin.currentPromoter,

      twilioSubAccounts: state => state.admin.twilioSubAccounts,
      isFetchingTwilioSubAccounts: state => state.admin.isFetchingTwilioSubAccounts,
      twilioSMSSender: state => state.admin.twilioSMSSender,
      isFetchingTwilioSMSSender: state => state.admin.isFetchingTwilioSMSSender,
    }),

    noMessages() {
      return !this.isFetchingTwilioSubAccounts && this.twilioSubAccounts.length === 0
    },

    tableHeadings() {
      return [
        {
          name: 'Friendly name',
          key: 'oid',
          format: 'friendlyName',
          align: 'left',
          width: 233,
          sortKey: 'friendlyName'
        },
        // 31 Oct 2023 ADR
        // These will be used at a later date. We'll keep these in for now.
        // {
        //   name: 'Twilio String Identifier',
        //   key: 'token',
        //   format: 'token',
        //   align: 'left',
        //   width: 233,
        //   sortKey: 'token'
        // },
        // {
        //   name: 'Linked to active messages',
        //   key: 'link',
        //   format: 'number',
        //   align: 'right',
        //   width: 233,
        //   sortKey: 'link'
        // },
        {
          key: 'menu',
          format: 'menu',
          align: 'left',
          width: 32,
        }
      ];
    },

    subAccountSID() {
      if (!this.twilioSubAccounts || this.twilioSubAccounts.length === 0) {
        return null;
      }

      return this.twilioSubAccounts[0]?.twilioId
    },
  },

  created() {
    this.reloadData()
  },

  watch: {
    twilioSubAccounts(val) {
      if (!!val) {
        this['admin/FETCH_ALPHANUMERIC_SENDER']({
          promoterOid: this.$route.params.oid,
        });
      }
    }
  },

  beforeDestroy() {
    this['admin/RESET_TWILIO_SUBACCOUNTS']();
    this['admin/RESET_SMS_SENDER_IDS']();

    this.isTwilioSubAccountModalOpen = false
    this.isTwilioAlphanumericIdModalOpen = false
    this.smsSenderModalMode = 'new';
    this.selectedSMSSender = null;
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'admin/FETCH_TWILIO_SUBACCOUNTS',
      'admin/CREATE_TWILIO_SUBACCOUNT',
      'admin/DELETE_TWILIO_SUBACCOUNT',
      'admin/FETCH_ALPHANUMERIC_SENDER',
      'admin/CREATE_ALPHANUMERIC_SENDER',
      'admin/UPDATE_ALPHANUMERIC_SENDER',
      'admin/DELETE_ALPHANUMERIC_SENDER',
    ]),

    ...mapMutations([
      'admin/RESET_SMS_SENDER_IDS',
      'admin/RESET_TWILIO_SUBACCOUNTS',
    ]),

    openCreateTwilioSubAccountModal() {
      this.isTwilioSubAccountModalOpen = true
    },

    closeTwilioSubAccountModal() {
      this.isTwilioSubAccountModalOpen = false
    },

    openCreateAlphanumericIdModal() {
      this.isTwilioAlphanumericIdModalOpen = true
    },

    closeTwilioAlphanumerIdModal() {
      this.isTwilioAlphanumericIdModalOpen = false
      this.smsSenderModalMode = 'new'
      this.selectedSMSSender = null
    },

    handleCreateTwilioSubAccount(subAccountData) {
      const success = this['admin/CREATE_TWILIO_SUBACCOUNT']({
        promoterOid: this.$route.params.oid,
        ...subAccountData
      })

      if (!!success) {
        this.isTwilioSubAccountModalOpen = false
      }
    },

    async handleCreateTwilioAlphanumericId(smsSenderData) {
      let success = null
      if (this.smsSenderModalMode === 'new') {
        success = await this['admin/CREATE_ALPHANUMERIC_SENDER']({
          promoterOid: this.$route.params.oid,
          twilioSubaccountOid: this.twilioSubAccounts[0].oid,
          ...smsSenderData
        })
      } else if (this.smsSenderModalMode === 'edit') {
        success = await this['admin/UPDATE_ALPHANUMERIC_SENDER']({
          promoterOid: this.$route.params.oid,
          twilioSubaccountOid: this.twilioSubAccounts[0].oid,
          smsSenderOid: smsSenderData.oid,
          ...smsSenderData
        })
      }

      if (!!success) {
        this.isTwilioAlphanumericIdModalOpen = false
        this.smsSenderModalMode = 'new'
        this.selectedSMSSender = null

        setTimeout(() => {
          this['admin/FETCH_ALPHANUMERIC_SENDER']({
            promoterOid: this.$route.params.oid,
          }, 500)
        })
      }
    },


    async reloadData() {
      this['admin/FETCH_TWILIO_SUBACCOUNTS']({
        promoterOid: this.$route.params.oid,
      });
    },

    async handleDeleteSubAccount() {
      await this.SHOW_CONFIRM({
        title: `Are you sure you want to delete this sub account?`,
        messageHtml: "This will remove all alpha numeric sender ID’s associated with this account.",
        asyncCompleteFunction: this.deleteSubAccount,
        confirmButtonText: 'Delete',
        validation: {
          question: 'Type DELETE to confirm',
          answer: 'DELETE',
        },
        hideCancelButton: true,
        hasCrossIcon: true,
      });
    },

    async deleteSubAccount() {
      await this['admin/DELETE_TWILIO_SUBACCOUNT']({
        promoterOid: this.$route.params.oid,
        twilioSubaccountId: this.twilioSubAccounts[0].oid,
      });
    },

    handleConnectionGuideClick() {
      window.open("https://www.notion.so/audiencerepublic/Alphanumeric-Sender-IDs-Onboarding-Guide-9cb5569f5afc43eaacda72c4235f0b46?pvs=4", "_blank");
    },

    handleMenuOptionSelect(event, data) {
      if (event === 'edit') {
        this.selectedSMSSender = data
        this.smsSenderModalMode = 'edit'
        this.isTwilioAlphanumericIdModalOpen = true
      } else if (event === 'delete') {
        this.handleDeleteSMSSender(data.oid)
      }
    },

    async handleDeleteSMSSender(smsSenderOid) {
      await this.SHOW_CONFIRM({
        title: `Are you sure you want to delete this alphanumeric sender ID?`,
        // 31 Oct 2023 ADR
        // We will omit the messageHtml for now until linked messages have been finalised in the backend.
        // messageHtml: "<X> messages will be unscheduled and sent to drafts.",
        asyncCompleteFunction: () => { this.deleteSMSSender(smsSenderOid) },
        confirmButtonText: 'Delete',
        validation: {
          question: 'Type DELETE to confirm',
          answer: 'DELETE',
        },
        hideCancelButton: true,
        hasCrossIcon: true,
      });
    },

    async deleteSMSSender(smsSenderOid) {
      await this['admin/DELETE_ALPHANUMERIC_SENDER']({
        promoterOid: this.$route.params.oid,
        smsSenderOid: smsSenderOid,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.table-wrapper {
  margin-top: 28px;

  &:first-child {
    margin-top: 0px;
  }
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.string-cell, .number-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.string-cell {
  padding: 0 12px;
  overflow: hidden;
  justify-content: flex-start;
}

.number-cell {
  padding: 0 17px;
}

.menu-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
</style>
