import { ActionTree } from 'vuex'
import { RootState } from '@/store/modules/types';
import { Oneschema } from './types';

export const jwtActions: ActionTree<Oneschema, RootState> = {

  async FETCH_ONESCHEMA_JWT({ rootState, commit }) {
    if (!rootState.auth.account) { return null; }
    const { promoterOid } = rootState.auth.account;
    try {
      commit('SET_IS_FETCHING_JWT', true);

      const jwt = await this.$api.oneschema.fetchJWT(promoterOid);
      commit('SET_ONESCHEMA_JWT', jwt);

      return true;
    } catch (error) {
      console.error(error);

      return true;
    } finally {
      commit('SET_IS_FETCHING_JWT', false);
    }
  },
};
