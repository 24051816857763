<template>
  <ar-modal
    :is-open="true"
    header="Disable MFA"
    width="600px"
    @close="$emit('close')"
  >
    <div slot="body" class="mfa-delete-modal-body">
      <ar-text
        text="Are you sure you want to disable MFA?"
        class="u-margin-bottom-4"
        weight="bold"
      />
      <ar-text
        text="To disable MFA, you need to type your OTP code from the Authenticator app."
        size="xs"
        class="u-margin-bottom-6"
      />
      <ar-input
        v-model="deleteOtpCode"
        class="input-number-without-arrows"
        type="number"
        placeholder="Code"
        v-validate="'required|digits:6'"
        data-vv-name="Code"
      />
      <ar-state-message
        v-show="veeErrors.has('Code')"
        :style="{ marginTop: '8px' }"
        type="error"
        :text="veeErrors.first('Code')"
      />
    </div>
    <div slot="footer" class="mfa-delete-modal-footer">
      <ar-simple-button
        text="Delete"
        :loading="deleteLoading"
        :style="{
            height: '40px',
          }"
        @click="disableMfa"
      />
    </div>
  </ar-modal>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

export default {
  data() {
    return {
      deleteLoading: false,
      deleteOtpCode: false,
    }
  },

  methods: {
    ...mapActions(['auth/DISABLE_MFA']),
    ...mapMutations(['auth/SET_MFA_ENABLED']),
    async disableMfa() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      this.deleteLoading = true

      const result = await this['auth/DISABLE_MFA']({ otp: Number(this.deleteOtpCode) })

      if (result) {
        this['auth/SET_MFA_ENABLED'](false)
      }

      this.deleteLoading = false
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
.mfa-delete-modal-footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.mfa-delete-modal-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
</style>
