<template>
  <am2-card-container
    class="insights-info"
    layout="regular"
  >
    <am2-typography-skeleton
      v-if="isLoading"
      size="lg"
    />
    <div class="insights-info-header" v-else>
      <ar-text
        size="lg"
        :text="percentValue ? `${value}%` : formattedValue"
      />
      <ar-text
        v-if="!!secondaryValue"
        size="xs"
        :text="percentSecondary ? `${secondaryValue}%` : formattedSecondaryValue"
        :style="{
          color: $arStyle.color.blueGrey700,
        }"
      />
    </div>
    <div class="desc-container">
      <ar-text
        class="desc"
        size="xs"
        :text="desc"
      />
      <ar-icon
        v-if="!!tooltip && tooltip.length > 1"
        name="tooltip-question-mark"
        class="u-margin-left-2 u-margin-top-3"
        :color="$arStyle.color.purple500"
        v-tooltip.bottom="{
            content: tooltip,
            classes: 'align-left',
          }"
      />
    </div>
  </am2-card-container>
</template>

<script>
import accounting from 'accounting';

export default {
  name: 'InsightsInfoCard',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    secondaryValue: {
      type: Number,
      default: null,
    },
    desc: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
    percentValue: {
      type: Boolean,
      default: false,
    },
    percentSecondary: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    formattedValue() {
      return accounting.formatNumber(this.value)
    },
    formattedSecondaryValue() {
      return accounting.formatNumber(this.secondaryValue)
    },
  }
}
</script>

<style lang="scss" scoped>
.insights-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 25px;

  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .desc-container {
    display: flex;
    flex-direction: row;
  }

  .desc {
    color: $blueGrey700;
    margin-top: 1em;
  }
}
</style>
