<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-memberful-access-token-modal"
      header="Connect To Memberful"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-memberful-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="Site Name"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            data-test-id="memberful-modal-site-name"
            v-model="siteName"
            autocomplete="memberful-site-name"
            suffix=".memberful.com"
            placeholder="Please enter your Memberful site name"
            enable-suffix-background
          />
        </ar-field>
        <ar-field
          label="API Key"
          :style="{
            marginTop: '20px',
          }"
        >
          <ar-input
            data-test-id="memberful-modal-api-key"
            v-model="apiKey"
            autocomplete="memberful-api-key"
            type="password"
            placeholder="Please enter your Memberful API key"
          />
        </ar-field>
        <ar-link-button
          data-test-id="memberful-modal-setup-support-link"
          class="u-margin-top-6"
          text="Set up your API key on Memberful"
          :has-underline="true"
          @click="handleSetupClick"
        />
      </div>
      <div
        slot="footer"
        :class="[
          'ask-memberful-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          data-test-id="memberful-modal-confirm"
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AskInputModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiKey: null,
      siteName: null,
      setupUrl: "https://support.audiencerepublic.com/en/articles/5722323-create-an-api-key-for-memberful",
    };
  },

  computed: {
    confirmButtonDisabled() {
      return !this.apiKey || !this.siteName;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.apiKey = null;
        this.siteName = null;
      }
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', { apiKey:  this.apiKey, siteName: this.siteName });
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    handleSetupClick() {
      window.open(this.setupUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-memberful-access-token-modal {
  .ask-memberful-access-token-modal-body {
    padding: 20px 30px 30px;
  }
  .ask-memberful-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>
