<template>
  <am2-table-html
    class="top-links-table"
    :heads="heads"
    :rows="rows"
    row-key-name="name"
    custom-head-padding="0 30px"
    hide-borders
    full-width
    data-test-id="top-links-table"
  >
    <div
      slot="name"
      slot-scope="{ data: { name }, index, active }"
      class="cell cell-left"
      :data-test-id="`top-links-table-row-name-${index}`"
    >
      <a :href="name" target="_blank">
        <ar-text
          size="xs"
          :text="name"
          :style="{
            color: $arStyle.color.purple500,
          }"
        />
      </a>
    </div>
    <div
      slot="uniqueTotal"
      slot-scope="{ data: { uniqueTotal }, index }"
      class="cell cell-link"
      :style="{
        display: 'flex',
        justifyContent: 'flex-end',
      }"
      @click="onUniqueLinkClick(index)"
      :data-test-id="`top-links-table-row-uniqueTotal-${index}`"
    >
      <ar-text
        size="xs"
        :text="uniqueTotal"
        :style="{
          color: $arStyle.color.purple500,
        }"
      />
    </div>
    <div
      v-if="!reduced"
      slot="total"
      slot-scope="{ data: { total }, index }"
      class="cell"
      :style="{
        display: 'flex',
        justifyContent: 'flex-end',
      }"
      :data-test-id="`top-links-table-row-reduced-${index}`"
    >
      <ar-text
        size="xs"
        :text="total"
      />
    </div>
  </am2-table-html>
</template>

<script>
const defaultHeaders = [
  {
    align: "left",
    attributeKey:"name",
    format:"name",
    key:"name",
    name:"Link",
  },
  {
    align: "right",
    attributeKey:"uniqueTotal",
    format:"uniqueTotal",
    key:"uniqueTotal",
    name:"Unique clicks",
    sortKey: "uniqueTotal",
    width: 180,
  },
  {
    align: "right",
    attributeKey:"total",
    format:"total",
    key:"total",
    name:"Total clicks",
    sortKey: "total",
    width: 180,
  },
];

const reducedHeaders = defaultHeaders.slice(0, -1);

export default {
  name: 'TopLinksTable',

  props: {
    reduced: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    heads() {
      return this.reduced ? reducedHeaders : defaultHeaders;
    }
  },

  methods: {
    onUniqueLinkClick(index) {
      const clickedUrl = this.rows[index].name;
      const url = `/message-center/messages/${this.$route.params.oid}/view/recipients`;

      this.$router.push({ path: url, query: { filter: `clicked=${clickedUrl}`, viewBy: 'clicked' } });
    }
  }
}
</script>

<style lang="scss" scoped>
.top-links-table {
  font-size: 14px;
}
.cell {
  padding: 0 30px !important;
}
.cell-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>