<template>
  <div
    ref="settingsWrapper"
    class="sales-chart-settings-wrapper"
    v-click-outside="handleSettingsClickOutside"
    @keydown.esc="onEsc"
    tabindex="0"
  >
    <div class="settings-wheel-wrapper settings-button" @click="toggleDropdown" ref="settingsButton" data-test-id="settings-toggle-button">
      <SettingsWheel
        class="sales-chart-settings-icon settings-button"
        :class="settingsOpen ? 'active' : ''"
        width="16px"
        height="16px"
      />
    </div>

    <div
      class="settings-dropdown-wrapper"
      v-show="settingsOpen"
    >
      <div class="triangle">
        <div class="triangle-outer"></div>
        <div class="triangle-inner"></div>
      </div>

      <div
        v-if="!activeSetting"
        ref="optionsWrapper"
        class="settings-options-wrapper"
        @keydown.down.prevent="onArrowDown"
        @keydown.up.prevent="onArrowUp"
        @keyup.enter="onEnter"
        tabindex="1"
      >
        <div
          data-option="edit-timezone"
          class="edit-graph-timezone-wrapper settings-option"
          :class="isActive('edit-timezone') ? 'keyfocus' : ''"
          @click="setActiveSetting('timezone')"
          data-test-id="timezone-settings-button"
        >
          <ar-text
            class="edit-graph-timezone-button"
            size="14px"
            text="Edit Graph Timezone"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
        </div>
        <div v-if="arEnableSalesChartFilter" class="filter-graph-wrapper settings-option">
          <ar-text
            class="filter-graph-button"
            size="14px"
            text="Filter Graph"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
        </div>
        <div
          class="hide-annotations-wrapper settings-option"
          data-option="toggle-annotations"
          :class="[
            !withAnnotations ? 'active' : '',
            isActive('toggle-annotations') ? 'keyfocus' : '',
            timescaleActive ? 'disabled' : ''
          ]"
          @click="handleAnnotationsToggle"
          data-test-id="settings-hide-annotations-button"
        >
          <ar-text
            class="hide-annotations-button"
            :weight="'normal'"
            size="14px"
            :text="!withAnnotations ? 'Show Annotations' : 'Hide Annotations'"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
        </div>
      </div>

      <ChangeTimezoneDropdown
        v-if="activeSetting === 'timezone'"
        ref="timezoneMenu"
        v-model="selectedTimezone"
        :options="timezoneOptions"
        @close="handleTimezoneDropdownClose"
      />
    </div>
  </div>
</template>
<script>
import SettingsWheel from '~/assets/icons/settings-wheel.svg?inline'
import ChangeTimezoneDropdown from './settings-dropdown/ChangeTimezoneDropdown.vue'
import { mapState, mapActions } from 'vuex'
import { filteredTimezones } from '@/utils/timezones';
const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay))
export default {
  name: 'SettingsDropdown',
  components: {
    SettingsWheel,
    ChangeTimezoneDropdown,
  },
  props: {
    activeSetting: {
      type: String,
      default: null,
    },
    withAnnotations: {
      type: Boolean,
      default: null,
    },
    timezone: {
      type: String,
      default: null,
    },
    timescaleActive: {
      type: Boolean,
      default: false,
    }
  },
  model: {
    prop: 'activeSetting',
    event: 'selection',
  },
  data() {
    return {
      arEnableSalesChartFilter: process.env.arEnableSalesChartFilter,
      settingsOpen: false,
      selectedTimezone: null,
      timezoneOptions: null,
      arrowCounter: -1,
    }
  },
  watch: {
    selectedTimezone(newVal, oldVal) {
      if (oldVal) {
        this.changeDataTimezone(newVal)
      }
    },
    currentEvent(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.selectedTimezone = this.currentEventTimezoneObject
      }
    },
    activeSetting() {
      this.handleActiveMenuFocus()
    }
  },
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
    }),
    currentEventTimezoneObject() {
      return {
        name: this.currentEvent.timeZone.split("_").join(" "),
        value: this.currentEvent.timeZone,
      }
    },
  },
  methods: {
    ...mapActions({
      updateSalesChartDataTimezone: 'event/UPDATE_SALES_CHART_TIMEZONE',
    }),
    isActive(dataVal) {
      if (!this.settingsOpen || !!this.activeSetting || this.arrowCounter === -1) return false

        let activeHoverChild = this.$refs?.optionsWrapper?.children[this.arrowCounter]
        if (activeHoverChild?.dataset?.option === dataVal) {
          return true
        }

        return false
    },
    onArrowDown() {
      let optionsLength = this.$refs.optionsWrapper.children.length
      if (this.arrowCounter < optionsLength - 1) {
        this.arrowCounter++
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter--
      }
    },
    onEnter(e) {
      if (this.arrowCounter === -1) return
      let childOption = this.$refs.optionsWrapper.children[this.arrowCounter]
      childOption.click()
      this.arrowCounter = -1
    },
    onEsc() {
      if (!this.activeSetting) {
        this.settingsOpen = false
      }
    },
    handleActiveMenuFocus() {
      switch (this.activeSetting) {
        case null:
          this.$nextTick(() => {
            let wrapper = document.querySelector('.settings-options-wrapper')
            wrapper.focus()
          })
          break
        case 'timezone':
          this.$nextTick(() => {
            this.$refs.timezoneMenu.$el.focus()
          })
          break
      }
    },
    async changeDataTimezone(timezone = this.currentEventTimezoneObject) {
      this.$emit('update', true)
      await waitFor(200)
      await this.updateSalesChartDataTimezone({timezone: timezone.value, timescaleActive: this.timescaleActive})
      this.$emit('update', false)
    },
    handleTimezoneDropdownClose() {
      this.$emit('selection', null)
      this.$refs.timezoneMenu.$el.focus()
    },
    handleAnnotationsToggle() {
      this.$emit('annotations')
    },
    setActiveSetting(setting) {
      this.$emit('selection', setting)
    },
    handleSettingsClickOutside() {
      this.settingsOpen = false
      this.$emit('selection', null)
    },
    toggleDropdown() {
      this.settingsOpen = !this.settingsOpen
      this.$emit('selection', null)
      if (this.settingsOpen) {
        this.$nextTick(() => {
          let wrapper = document.querySelector('.settings-options-wrapper')
          wrapper.focus()
        })
      }
    }
  },
  mounted() {
    this.timezoneOptions = filteredTimezones
    this.selectedTimezone = this.timezone
  }
}
</script>
<style lang="scss" scoped>

@mixin flexRow {
  display: flex;
  flex-flow: row nowrap;
}

.sales-chart-settings-wrapper {
  height: 100%;
  width: auto;
  border-radius: 3px;
  border: solid ui-px2em(1) #dce0e4;
  background-color: #fff;
  margin-right: 6px;
  cursor: pointer;
  position: relative;

  &:focus {
    outline: none;
  }

  .settings-wheel-wrapper {
    @include flexRow;
    width: 36px;
    height: 100%;
    align-items: center;
    justify-content: center;

    .sales-chart-settings-icon {
      transition: all 0.3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .settings-dropdown-wrapper {
    @include flexRow;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 48px;
    right: 0;
    box-shadow: 0 ui-px2em(2) ui-px2em(6) 0 $shadow;
    border: solid ui-px2em(1) $blueGrey500;
    background-color: #fff;
    min-height: 50px;
    border-radius: 5px;
    z-index: 30;

    .triangle {
      position: absolute;
      width: 10px;
      top: -6px;
      right: 12px;

      .triangle-outer {
        position: absolute;
        top: 0;
        left: 0;
        border-left: ui-px2rem(5) solid transparent;
        border-right: ui-px2rem(5) solid transparent;
        border-bottom: ui-px2rem(5) solid $skyBlueGrey500;
      }

      .triangle-inner {
        position: absolute;
        top: 1px;
        left: 0;
        border-left: ui-px2rem(5) solid transparent;
        border-right: ui-px2rem(5) solid transparent;
        border-bottom: ui-px2rem(5) solid white;
        transform: translateY(10%);
      }
    }
    .settings-options-wrapper {
      width: 195px;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      &:focus {
        outline: none;
      }

      .settings-option {
        width: 100%;
        padding: ui-px2em(10) ui-px2em(20);

        &:first-child {
          margin-top: 5px;
        }

        &:last-child {
          margin-bottom: 5px;
        }

        &:hover {
          background: #f2edff;
        }
      }

      .edit-graph-timezone-wrapper, .hide-annotations-wrapper {
        &.keyfocus {
          background: #f2edff;
        }
      }

      .hide-annotations-wrapper {
        &.disabled {
          pointer-events: none;
          cursor: default;

          .hide-annotations-button {
            color: #ccc3dc;
          }
        }
      }
    }
  }
}
</style>
