var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{class:[
    'ar-simple-form',
    _vm.opened && 'opened',
    _vm.disabled && 'disabled' ],style:({
    padding: _vm.opened ? '11px 20px' : '0 20px',
  }),attrs:{"ar-widget-id":"ar-simple-form","ar-widget-meta":_vm.arWidgetMeta || _vm.computedArWidgetMeta,"name":"ar-simple-form"},on:{"click":_vm.handleFormClick,"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"ar-simple-form-right-section"},[_c('div',{style:({
        height: _vm.opened ? 0 : null,
        overflow: 'hidden',
      })},[_c('div',{style:({
          minHeight: '40px',
        })},[_c('ar-text',{style:({
            padding: '7px 0 0 0',
            'color': _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"v-if":!_vm.opened,"size":"xxs","text":_vm.title,"multiple-lines":""}}),_vm._v(" "),(!_vm.disabled)?_c('div',[(_vm.parsedData)?_c('div',{style:({
              padding: '0 0 8px 0',
            })},[_c('ar-text',{attrs:{"size":"xs","text":_vm.parsedData,"multiple-lines":"","allow-html":"","line-height":"20px"}})],1):_c('ar-link-button',{style:({
              marginTop: '13px',
              marginBottom: '13px',
            }),attrs:{"text":("+ " + _vm.placeholder),"text-props":{
              weight: 'bold',
            },"has-underline":false,"color":_vm.color},on:{"click":_vm.handleFormClick}})],1):_c('div',[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'This field is read only and cannot be edited.',
            }),expression:"{\n              content: 'This field is read only and cannot be edited.',\n            }"}],style:({
              padding: '7px 0 7px 0',
              'color': _vm.$arStyle.color.skyBlueGrey700,
            }),attrs:{"size":"xs","text":_vm.parsedData || _vm.placeholder,"multiple-lines":"","allow-html":"","line-height":"20px"}})],1)],1)]),_vm._v(" "),_c('div',{style:({
        height: _vm.opened ? null : 0,
        overflow: _vm.opened ? null : 'hidden',
      })},[_c('div',{style:({
          padding: '2px',
          minHeight: '40px',
        })},[_c('ar-text',{style:({
            padding: '10px 0 12px 0',
            'color': _vm.$arStyle.color.blueGrey800,
          }),attrs:{"size":"xs","weight":"bold","text":_vm.title,"multiple-lines":""}}),_vm._v(" "),_vm._t("fields"),_vm._v(" "),_c('div',{staticClass:"u-margin-top-3 u-display-flex u-align-items-center"},[_c('ar-simple-button',{style:({
              width: '90px',
              height: '40px',
            }),attrs:{"text":"Save","type":_vm.color,"loading":_vm.loading}}),_vm._v(" "),(!_vm.loading)?_c('ar-link-button',{staticClass:"u-margin-left-3",attrs:{"show-arrow":false,"show-underline":"","color":_vm.color,"text":"Cancel"},on:{"click":_vm.handleCancelClick}}):_vm._e()],1)],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }