var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MulitpleChartsFrame',{attrs:{"title":_vm.title,"description":_vm.description,"topics":_vm.topics,"hideTitle":_vm.hideTitle,"hideSwitchButtons":_vm.hideSwitchButtons,"topicIndex":_vm.topicIndex,"loading":_vm.loading,"locked":_vm.locked,"has-data":_vm.hasData},on:{"topicChange":_vm.handleTopicChange}},[(_vm.displayedData)?_c('BasicChart',{ref:"basicChart",attrs:{"tooltip-title":_vm.title,"options":_vm.options},on:{"chartReady":_vm.addLegendListeners}}):_vm._e(),_vm._v(" "),_c('div',{ref:"centerPiece",class:[
      'center-piece',
      !!_vm.highlighted ? 'is-visible' : null ]},[_c('ar-text',{style:({
        color: '#697489', // Colour only exists in this specific use case, so we wont use $arStyle
        marginBottom: '1px',
      }),attrs:{"text":_vm.highlightedPercentage,"size":"lg","align":"center"}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-1",style:({
        color: '#8790A1', // Colour only exists in this specific use case, so we wont use $arStyle
      }),attrs:{"text":_vm.highlightedTopic,"size":"sm","align":"center"}}),_vm._v(" "),(_vm.shouldShowNumber)?_c('ar-text',{style:({
        color: _vm.$arStyle.color.blueGrey700,
        marginTop: '19px',
      }),attrs:{"text":_vm.highlightedCount,"size":"xs","align":"center"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }