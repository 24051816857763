var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"invite-account-modal",attrs:{"is-open":_vm.isShow,"header":"New user","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[_c('div',{class:[
        'invite-account-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-field',{staticClass:"invite-account-modal-body-row",attrs:{"label":"Email address"}},[_c('ar-input',{attrs:{"value":_vm.emailAddress,"name":"email-address","placeholder":"e.g. jess@domain.com"},on:{"input":_vm.handleEmailAddressInput}})],1),_vm._v(" "),_c('ar-field',{staticClass:"invite-account-modal-body-row",attrs:{"label":"User type"}},[_c('am2-user-role-select',{staticClass:"u-margin-bottom-1",attrs:{"data-test-id":"user-role-select","data-vv-name":"user-role-select","data-vv-as":"user role","default-user-roles":_vm.defaultUserRoles,"promoter-user-roles":_vm.promoterUserRoles,"selectedUserRoles":{
            defaultUserRoleOid: _vm.defaultUserRoleOid,
            promoterUserRoleOid: _vm.promoterUserRoleOid,
          },"is-user-facing":""},on:{"select":_vm.handleUserRoleSelect,"handleFilterInput":_vm.handleUserRoleFilterInput}})],1)],1),_vm._v(" "),_c('div',{class:[
        'invite-account-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-link-button',{style:({
          height: '40px',
          padding: '0 20px'
        }),attrs:{"text":"Cancel"},on:{"click":_vm.handleCloseClick}}),_vm._v(" "),_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Create","disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }