<template>
  <EditableFrame
    class="send-sms-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
    <am2-message-preview-modal
      :opened="displayPreviewModal"
      provider="sms"
      :message-body="smsMessagePreview.body || ''"
      @close="handlePreviewModalClose"
    />
    <am2-create-message-list-modal
      :is-show="displayCreateMessageListModal"
      @close="handleCreateMessageListModalClose"
      :isAutomation="true"
    />
    <!-- <ar-text
      class="recipient-heading"
      size="13px"
      text="RECIPIENT"
      weight="bold"
      multiple-lines
      :max-lines="1"
      letter-spacing="1px"
    />
    <RecipientDropdown
      v-model="recipientData"
    /> -->
    <!-- Type -->
    <ar-field  class="type-section">
      <div>
        <ar-text
          class="label u-margin-bottom-3"
          size="14px"
          text="What type of message are you sending?"
          weight="bold"
          multiple-lines
          :max-lines="1"
        />
        <am2-button-switch
          :value="selectedType"
          :items="types"
          @update="handleUpdateSelectedType"
        />
      </div>

      <ar-text
        class="u-margin-top-3"
        size="13px"
        :text="description[selectedType]"
        multiple-lines
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
    </ar-field>
    <!-- Recipient -->
    <ar-field
      v-if="selectedType === 'marketing'"
      :error-messages="[
        veeErrors.first('messageRecipient'),
      ]"
      data-test-id="automation-email-recipient-field"
    >
      <div class="recipient-section">
        <ar-text
          class="label"
          size="14px"
          text="To"
          weight="bold"
          multiple-lines
          :max-lines="1"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />

        <div class="add-to-list-dropdown-wrapper" @click="toggleOptions" data-test-id="add-to-list-toggle">
          <ar-text
            class="add-to-list-copy"
            size="14px"
            :text="messageListText"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
          <ArrowHead
            class="add-to-list-dropdown-arrowhead"
            :class="listOpen && 'rotate'"
            height="8px"
            width="10px"
            fill="#8492a6"
          />
        </div>
        <div v-if="listOpen" class="list-search-wrapper">
          <am2-search
            class="add-to-list-search"
            v-model="listSearchText"
            placeholder="Search lists..."
            data-test-id="add-to-list-search"
          />
          <div class="message-list-wrapper">
            <div v-if="noMatchingLists" class="message-list-link no-list-found-wrapper">
              <div class="item-wrapper">
                <ar-text
                  class="item-name"
                  :style="{ marginRight: '4px' }"
                  size="xs"
                  text="No matching list found"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  weight="normal"
                />
              </div>
            </div>
            <template v-for="list in filteredMessageList">
              <a
                :key="list.oid"
                class="message-list-link"
                @click="() => setSelectedListOid(list.oid)"
                :data-test-id="`add-to-list-anchor-${list.oid}`"
              >
                <div class="item-wrapper">
                  <ar-text
                    :class="[
                      'list-name',
                      list.oid === selectedListOid && 'selected'
                    ]"
                    size="xs"
                    :text="list.name"
                    multiple-lines
                    :max-lines="1"
                    line-height="20px"
                    :weight="list.oid === selectedListOid ? 'bold' : 'normal'"
                  />
                </div>
              </a>
            </template>
          </div>
        </div>
        <template v-if="isTriggerMessageListMatching">
            <ar-text
              text="Automated marketing message recipients must be subscribed to a list"
              size="xxs"
              multiple-lines
              :style="{
                  color: $arStyle.color.skyBlueGrey700,
              }"
            />
        </template>
        <template v-else>
            <ar-snackbar
               type="warning"
               message="Your list does not match your trigger list. Unless recipients are in both lists, they will not receive an email."
               class="u-margin-top-0"
            />
        </template>
        <div class="item-wrapper u-padding-top-4">
          <ar-simple-button
            class="create-list-button"
            text="Create New List"
            outlined
            @click="handleCreateListClick"
          />
        </div>
      </div>
    </ar-field>

    <ar-field
      :error-messages="[
        veeErrors.first('message'),
      ]"
    >

      <div class="message-section">
        <div class="label-section">
          <ar-text
            size="14px"
            text="Message"
            weight="bold"
            multiple-lines
            :max-lines="1"
          />
          <ar-link-button
            text="Preview"
            @click="handlePreviewClick"
          />
        </div>
        <SimpleMessageEditor
          ref="simple-message-editor"
          :value="scratchSimpleMessage.meta.messageBody"
          @input="handleMessageBodyInput"
          v-validate="{
            required: true,
            max: scratchSimpleMessage.meta.messageBody ? scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft : null,
          }"
          data-vv-name="messageBody"
        />
      </div>
    </ar-field>
    <div class="preview-button-wrapper">
      <am2-characters-left-copy-section
        v-if="!!scratchSimpleMessage.meta.messageBody && !!scratchSimpleMessage.meta.messageBody.length >= 0"
        :current-length="scratchSimpleMessage.meta.messageBody.length"
        tooltip="The character count below excludes the opt out message, which gets automatically appended to your SMS."
        :max-length="scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft"
        :template="`${smsMessagePreviewLength} characters / ${smsMessagePreviewSegmentsCount} SMS`"
        :style="{
          marginTop: '10px',
          alignSelf: 'center'
        }"
      />
    </div>
    <div class="sms-charge-wrapper">
      <ar-checkbox
        v-model="costAccepted"
        label="I agree to receive and pay an invoice for these SMS sends"
        :label-typography-props="{multipleLines: true}"
        data-test-id="automation-sendsms-accept-charge-checkbox"
      />
    </div>
  </EditableFrame>
</template>
<script>
import RecipientDropdown from './RecipientDropdown'
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import SimpleMessageEditor from '@/components/editors/simple-message-editor';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { debounce } from 'debounce';

export default {
  name: 'SendSMS',
  components: {
    RecipientDropdown,
    EditableFrame,
    ArrowHead,
    SimpleMessageEditor
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      displayPreviewModal: false,
      costAccepted: false,



      types: [{
        name: 'Marketing',
        key: 'marketing',
      }, {
        name: 'Transactional',
        key: 'transactional'
      }],
      selectedType: 'marketing',


      // To List
      description: {
        marketing: "Promoting a new event, merchandise or ticket sales. These messages can only be sent to opted in recipients.",
        transactional: "Event updates or important information about an event the recipient has already purchased a ticket to."
      },
      listSearchText: '',
      listOpen: false,
      selectedListOid: null,
      filteredMessageList: [],
      isTriggerMessageListMatching: true,
    }
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      scratchSimpleMessage: state => state.message.scratchSimpleMessage,
      smsMessagePreview: state => state.message.smsMessagePreview,
    }),
    ...mapGetters({
      smsMessagePreviewLength: 'message/smsMessagePreviewLength',
      smsMessagePreviewSegmentsCount: 'message/smsMessagePreviewSegmentsCount'
    }),
    ...mapGetters({
      messageLists: 'messageList/userDefinedMessageLists'
    }),
    savedButtonDisabled() {
      return (!this.scratchSimpleMessage.meta.messageBody?.length || !this.costAccepted || !this.messageListValueValid)
    },
    messageListText() {
      if (!this.selectedListOid || !this.messageLists?.length) return 'Select message list...'
      return this.messageLists?.find(item => item.oid === this.selectedListOid)?.name
    },
    noMatchingLists() {
      return !this.filteredMessageList?.length
    },
    messageListValueValid() {
      return this.selectedType === 'marketing' ? !!this.selectedListOid : true
    },
    displayCreateMessageListModal: {
      get() {
          return this.$store.state.automation.displayListModal
      },
      set(value) {
          this.$store.state.automation.displayListModal = value
      }
    }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    scratchSimpleMessage: {
      handler(newVal) {
        this.debounceFetchPreviewMessage(newVal.meta.messageBody);
      },
    },
    messageLists: {
      immediate: true,
      handler() {
        this.filteredMessageList = this.messageLists
      }
    },
    listSearchText() {
      this.filterLists(this.listSearchText)
    }
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG',
      fetchManualMessageLists: 'automation/FETCH_MANUAL_MESSAGE_LISTS',
      fetchSmsPreview: 'message/FETCH_SMS_MESSAGE_PREVIEW',
    }),
    ...mapMutations([
      'message/PUT_SCRATCH_SIMPLE_MESSAGE',
      'message/RESET_SCRATCH_SIMPLE_MESSAGE',
    ]),
    async validateInputs() {
      const isValid = await this.$validator.validateAll();
      return isValid;
    },
    handlePreviewClick() {
      this.displayPreviewModal = true;
    },
    handlePreviewModalClose() {
      this.displayPreviewModal = false;
    },
    async handleSave() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }

      const contextData = {
        config: {
          message: this.scratchSimpleMessage.meta.messageBody,
          messageType: this.selectedType,
          linkedMessageListOid: this.selectedType === 'marketing' ? this.selectedListOid : null
        },
        meta: {
        },
      };
      this.updateActionConfig({
        id: this.item.id,
        data: contextData,
      });
      this.$emit('close');
    },
    setItemVals() {
      this['message/RESET_SCRATCH_SIMPLE_MESSAGE']()
      if (!this.item.config) {
        return
      }
      
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: { messageBody: this.item.config.message },
      });

      this.costAccepted = true
      this.selectedListOid = this.item.config?.['linked-message-list-oid'] || null;
      this.selectedType = this.item.config?.['message-type'] || 'marketing';
    },
    handleUpdateSelectedType(typeKey) {
      this.selectedType = typeKey
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    isTriggerListSubscribed(selectedListOid) {
          return this.$store.state.automation.triggers.items.every(t => t.key !== 'messaging.list.subscribed' || (t.config?.['message-list-oid'] === undefined || t.config?.['message-list-oid'] === selectedListOid))
    },
    setSelectedListOid(oid) {
      this.isTriggerMessageListMatching = this.isTriggerListSubscribed(oid)
      this.selectedListOid = oid
      this.listOpen = false
    },
    filterLists(text) {
      let filteredMessageList = []
      let i = 0
      while (i < this.messageLists?.length) {
        if (this.$arFuzzySearch(this.messageLists[i].name, text)) {
          filteredMessageList.push(this.messageLists[i])
        }

        i++
      }

      this.filteredMessageList = filteredMessageList
    },
    handleMessageBodyInput(val) {
      this.costAccepted = false;
      this['message/PUT_SCRATCH_SIMPLE_MESSAGE']({
        meta: { messageBody: val },
      });
    },
    updateSmsPreview() {
      this.fetchSmsPreview({
        editSmsMessage: this.scratchSimpleMessage,
        cost: this.costAccepted,
      });
    },
    handleCreateMessageListModalClose() {
      this.displayCreateMessageListModal = false;
    },
    handleCreateListClick() {
      this.displayCreateMessageListModal = true;
    },
  },
  created() {
    this.debounceFetchPreviewMessage = debounce(this.updateSmsPreview, 1000);
  },
  fetch() {
    this.fetchManualMessageLists()
  },
  mounted() {
    this.setItemVals();
    this.updateSmsPreview();
  }
}
</script>
<style lang="scss" scoped>
.send-sms-wrapper {
  width: 100%;
  height: 100%;

  .tooltip {
    cursor: pointer;
  }

  .label-section {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .type-section {
    margin-bottom: 31px;
  }
  .recipient-section {
    margin-bottom: 31px;

    .add-to-list-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(13) ui-px2em(16) ui-px2em(13) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      .add-to-list-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .list-search-wrapper {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;
      position: absolute;

      .add-to-list-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .message-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: auto;
        padding-bottom: 10px;

        .message-list-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-list-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;

            .list-name {
              &.selected {
                color: $purple500;
              }
            }
          }
        }
      }
    }
    .create-list-button {
      margin-bottom: 10px;
      width: 100%;
      height: 40px;
    }
  }
  .recipient-heading {
    margin-bottom: 14px;
  }

  .message-section {
    .label-section {
      justify-content: space-between;
      margin-bottom: 13px;
    }
  }

  .preview-button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .sms-charge-wrapper {
    margin-top: 40px;
    background-color: $purple100;
    padding: 12px;
    border-radius: 4px;
    height: 61px;
    display: flex;
    align-items: center;
  }
}
</style>
