<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="verify-domain-modal"
      header="Domain authentication"
      width="964px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'verify-domain-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div
          v-if="!!data"
          class="domain-table-section"
        >

          <table class="domain-table">
            <tr class="header">
              <th class="table-heading">Type</th>
              <th class="table-heading">Host</th>
              <th class="table-heading">Points to</th>
              <th class="table-heading"></th>
            </tr>
            <tr
              v-for="(item, key) in data.hostRecords"
              :key="key"
              class="domain">
              <td>
                <ar-text
                  size="xs"
                  :text="item.type"
                  :style="{
                    textTransform: 'uppercase',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '60px',
                  }"
                />
              </td>
              <td>
                <div class="text-data">
                  <ar-text
                    size="xs"
                    :text="item.host"
                    :style="{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '228px',
                    }"
                    v-tooltip.top="{
                      content: item.host,
                      classes: ['tooltip']
                    }"
                  />
                    <ar-simple-button
                      class="u-margin-left-3"
                      :outlined="true"
                      :text="(hostRecordsMatrix && !!hostRecordsMatrix[key]['hostCopy']) ? 'Copied' : 'Copy'"
                      :type="(hostRecordsMatrix && !!hostRecordsMatrix[key]['hostCopy']) ? 'green' : 'purple'"
                      :style="{
                        height: '32px',
                        width: '83px',
                      }"
                      @click="() => copyItem(key, 'hostCopy', item.host)"
                    />
                </div>
              </td>
              <td>
                <div class="text-data">
                  <ar-text
                    size="xs"
                    :text="item.data"
                    :style="{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '228px',
                    }"
                    v-tooltip.top="{
                      content: item.data,
                    }"
                  />
                  <ar-simple-button
                    class="u-margin-left-3"
                    :outlined="true"
                    :text="(hostRecordsMatrix && !!hostRecordsMatrix[key]['dataCopy']) ? 'Copied' : 'Copy'"
                    :type="(hostRecordsMatrix && !!hostRecordsMatrix[key]['dataCopy']) ? 'green' : 'purple'"
                    :style="{
                      height: '32px',
                      width: '83px',
                    }"
                    @click="() => copyItem(key, 'dataCopy', item.data)"
                  />
                </div>
              </td>
              <td>
                <div class="text-data">
                  <div
                    v-if="!!item.valid"
                    v-tooltip.top="{content: 'Record has been verified'}"
                    :style="{
                        border: `2px solid ${$arStyle.color.green500}`,
                        borderRadius: `50%`,
                        padding: `2px`,
                        width: '23px',
                        height: '23px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }">
                    <ar-icon
                      name="check"
                      height="11px"
                      :color="$arStyle.color.green500"
                    />
                  </div>
                  <div
                    v-else
                    v-tooltip.top="{content: 'Record not verified'}"
                    :style="{
                        border: `2px solid #FF8B25`,
                        borderRadius: `50%`,
                        padding: `2px`,
                        width: '23px',
                        height: '23px'
                      }">
                    <ar-text
                      size="15px"
                      text="!"
                      weight="bold"
                      align="center"
                      :style="{color: '#FF8B25', cursor: 'default'}"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div :style="{textAlign: 'end'}">
          <ar-simple-button
            class="u-margin-left-3"
            :outlined="true"
            :type="copyAllText === 'Copied' ? 'green' : 'purple'"
            :text="copyAllText"
            :style="{
              height: '32px',
              width: '106px',
              marginTop: '10px',
              marginLeft: 0,
              float: 'right',
            }"
            @click="handleCopyAllClick"
          />
        </div>
      </div>
      <div
        slot="footer"
        :class="[
          'verify-domain-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <div class="connection-guide">
          <ar-link-button
            text="View our connection guide"
            @click="() => connectionGuideClick()"
          />
          <ar-icon
            name="external-link-bold"
            width="16px"
            :color="$arStyle.color.purple500"
            class="u-margin-left-1"
          />
        </div>
        <!-- TO DO: Uncomment once Deletion is worked on -->
        <!-- <ar-simple-button
          text="Delete"
          :style="{ width: '44px' }"
          type="red"
        /> -->
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'ViewDomainsModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      confirmRecord: false,
      hostRecordsMatrix: {},
      copyAllText: 'Copy All',
    };
  },

  computed: {
    copyAllDataString() {
      if (!this.data) return '';

      const records = {}
      let maxHostLength = 0;
      let maxTypeLength = 0;

      Object.values(this.data.hostRecords).forEach(record => {
        if (records[record.type]) {
          records[record.type].push(record);
        } else {
          records[record.type] = [record];
        }

        maxTypeLength = Math.max(maxTypeLength, record.type.length);
        maxHostLength = Math.max(maxHostLength, record.host.length);
      });

      let resultString = '';

      // Generate strings for each record type
      Object.entries(records).forEach(([recordType, recordList]) => {
        if (recordList.length > 0) {
          if (resultString.length > 0) {
            resultString += '\n';
          }
          resultString += `; ${recordType.toUpperCase()} Record\n`;
          recordList.forEach(record => {
            const paddingHost = ' '.repeat(maxHostLength - record.host.length + 4);
            const paddingType = ' '.repeat(maxTypeLength - record.type.length + 4);
            resultString += `${record.host}${paddingHost}${recordType.toUpperCase()}${paddingType}"${record.data}"\n`;
          });
        }
      });

      return resultString;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.updateHostRecordKeys(this.data)
      }
    },
    data(val) {
      if (!val) {
        this.hostRecordsMatrix = {}
      }

      this.updateHostRecordKeys(val)
    },
  },

  methods: {
    handleConfirmClick() {
      this.$emit('confirm', this.data);
    },
    handleCloseClick() {
      this.$emit('cancel');
      this.resetData()
    },
    connectionGuideClick() {
      window.open('https://audiencerepublic.freshdesk.com/support/solutions/articles/51000309976-domain-authentication', '_blank');
    },
    copyItem(key, field, item) {
      navigator.clipboard.writeText(item);
      this.$set(this.hostRecordsMatrix[key], field, true)

      setTimeout(() => {
        this.$set(this.hostRecordsMatrix[key], field, false)
      }, 5000)
    },
    handleCopyAllClick() {
      navigator.clipboard.writeText(this.copyAllDataString);
      this.copyAllText = 'Copied';
      setTimeout(() => {
        this.copyAllText = 'Copy All';
      }, 5000)
    },
    updateHostRecordKeys(val) {
      this.copyAllText = 'Copy All';
      if (!val?.hostRecords) {
        this.hostRecordsMatrix = {}
        return;
      }

      const hostRecordsKeys = Object.keys(val.hostRecords)

      this.hostRecordsMatrix = hostRecordsKeys.reduce((keys, key) => {
        return {
          ...keys,
          [key]: {
            hostCopy: false,
            dataCopy: false,
          },
        }
      }, {})
    },
    resetData() {
      this.hostRecordsMatrix = {}
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-domain-modal {
  .verify-domain-modal-body {
    display: flex;
    gap: 24px;
    padding: 48px 32px;
    flex-direction: column;

    .domain-table-section {
      border: 1px solid $skyBlueGrey400;
      border-radius: 4px;
      padding: 12px 24px;
    }

    .domain-table {
      width: 100%;

      table {
        padding: 0px;
      }

      td, th {
        padding: 15px;
        margin: 0px;
      }

      th {

        border-bottom: 1px solid $skyBlueGrey400;

      }

      .header {
        .table-heading {
          font-size: 14px;
          text-align: left;
        }
      }

      .text-data {
        // width: 300px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

    }

  }

  .verify-domain-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 30px;
    height: 100px;

    .connection-guide {
      align-items: flex-end;
    }
  }
}
</style>
