<template>
  <section class="message-center-insights">
    <div class="insights-info-wrapper">
      <InfoCard 
        class="info-card" 
        :value="totalClicks" 
        desc="Total clicks" 
      />
      <InfoCard 
        class="info-card" 
        :value="uniqueClicks" 
        desc="Unique clicks"
        :tooltip="`The count of distinct recipients who clicked at least once on any link in the ${isSms ? 'sms' : 'email'}, ignoring multiple clicks from the same recipient.`"
      />
      <InfoCard 
        class="info-card" 
        :value="clicksPerRecipients" 
        desc="Clicks per recipient" 
      />
      <InfoCard 
        class="info-card" 
        :value="clickToOpenRate" 
        desc="Click to open rate" 
        :tooltip="`% of recipients who clicked a link in the ${isSms ? 'sms' : 'email'} after opening it`"
        percent-value
      />
    </div>
    <div class="insights-wrapper">
      <am2-card-container layout="regular" :style="{ width: '100%' }">
        <TopLinksTable :rows="clickStatsData" />
      </am2-card-container>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TopLinksTable from './components/top-links-table.vue'
import InfoCard from './components/info-card.vue';
import { extractClicksStats } from './utils/clickStats'

export default {
  name: 'MessageViewLinkActivity',
  components: { TopLinksTable, InfoCard },

  data() {
    return {
      clickStatsTimestamp: null,
      head: ['Link'],
    }
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      shortUrls: state => state.shortUrls.shortUrls,
    }),

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms';
    },

    totalClicks() {
      if (!!this.currentSelectedMessage?.statusDetails?.clicksSnapshot) {
        return this.currentSelectedMessage.statusDetails.clicksSnapshot.reduce((acc, val) => {
          return acc + val.total;
        }, 0);
      }
      return 0;
    },

    uniqueClicks() {
      if (!!this.currentSelectedMessage?.statusDetails?.clicksSnapshot) {
        return this.currentSelectedMessage.statusDetails.clicksSnapshot.reduce((acc, val) => {
          return acc + val.uniqueTotal;
        }, 0);
      }
      return 0;
    },

    clicksPerRecipients() {
      if (!!this.currentSelectedMessage?.statusDetails) {
        const stats = this.currentSelectedMessage.statusDetails;
        const clicksPerRecipients = stats.totalFinished === 0 ? 0 : (this.totalClicks / stats.totalFinished) || 0
        
        if (clicksPerRecipients >= 0.1 && clicksPerRecipients < 1.0) {
          return clicksPerRecipients.toFixed(1);
        }

        return Math.trunc(clicksPerRecipients);
      }
      return 0;
    },

    clickToOpenRate() {
      if (!!this.currentSelectedMessage?.statusDetails) {
        const stats = this.currentSelectedMessage.statusDetails;
        const opened = stats.opened + stats.clicked;
        return opened === 0 ? opened : Math.trunc((stats.clicked / opened) * 100) || 0;
      }
      return 0;
    },

    clickStatsData() {
        return extractClicksStats(
          this.currentSelectedMessage?.statusDetails?.clicksSnapshot, 
          this.shortUrls, 
          (latestDate) => {
            this.clickStatsTimestamp = latestDate
          },
          (latestDate, clicksSnapshotItemSysMtime) => {
            return this.getLatestSyncDate(latestDate, clicksSnapshotItemSysMtime)
          }
        )
    }
  },

  async mounted() {
    this.fetchMessage();
    this['shortUrls/FETCH_SHORT_URLS']({ taskOid: this.oid });
  },

  methods: {
    ...mapActions([
      'message/FETCH_MESSAGE',
      'shortUrls/FETCH_SHORT_URLS',
    ]),

    getLatestSyncDate(latestDate, currentLinkDate) {
      if (!latestDate) {
        return currentLinkDate
      }

      if (!currentLinkDate) {
        return latestDate;
      }

      const latestDateConverted = new Date(latestDate)
      const currentLinkDateCoverted = new Date(currentLinkDate)

      if (latestDateConverted < currentLinkDateCoverted) {
        return currentLinkDate
      } else {
        return latestDate
      }
    },

    fetchMessage() {
      this['message/FETCH_MESSAGE'](this.oid);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/base/mixins';

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.message-center-insights {
  margin-top: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .insights-info-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;

    width: 90%;
    height: 100%;
    max-width: 1200px;

    margin-bottom: 30px;
  }

  .insights-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    width: 90%;
    height: 100%;
    max-width: 1200px;
  }
}

.fade-enter {
  opacity: 0;
  visibility: hidden;
}

.fade-enter-active {
  transition: opacity 0.4s ease;
}

.fade-leave-active {
  transition: 0.2s opacity ease;
  opacity: 0;
  visibility: hidden
}
</style>
