<template>
  <div>
    <am2-message-preview-modal
      v-if="!!displayMessageData"
      data-test-id="message-preview-modal-from-list"
      :opened="displaySmsMessagePreviewModal"
      :message="displayMessageData"
      :message-list="displayMessageData.messageListOid"
      :provider="displayMessageData.provider"
      :email-template="savedEmail"
      :message-body="currentSelectedMessageMessageBody"
      :subject="displayMessageData.meta.messageBody.subject"
      :preview-text="displayMessageData.meta.messageBody.previewText"
      :scheduled-at="displayMessageData.scheduledAt"
      :sender-name="currentMessageSenderName"
      @close="handleClose"
      :facebook-page-name="currentSelectedMessageFacebookPageName"
      :facebook-page-image-src="currentSelectedMessageFacebookPageImageSrc"
    />
    <am2-email-preview-modal
      :opened="displayEmailMessagePreviewModal"
      :subject="currentSelectedMessageSubject"
      :preview-text="currentSelectedMessagePreviewText"
      :scheduled-at="scheduledAt"
      :sender-name="currentMessageSenderName"
      :email-html="templateHTML"
      :message="currentSelectedMessage"
      :is-loading="isFetchingPreviewTemplate"
      @close="handleClose"
      @variant-select="handleVariantSelect"
      show-share-link
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

export default {
  name: 'MessagePreviewCombined',
  props: {
    messageProp: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      displayEmailMessagePreviewModal: false,
      displaySmsMessagePreviewModal: false,
      displayMessageData: null,
      isFetchingPreviewTemplate: false,
      generatedHtml: null,
      scratchEmailWithInfo: '',
    }
  },
  watch: {
    messageProp: {
      handler: function (val) {
        if (val) {
          this.openMessagePreviewModal(val)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      messages: state => state.message.messages,
      hasFetchMessagesFailed: state => state.message.hasFetchMessagesFailed,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      statsAB: state => state.message.statsAB,
      scratchEmailMessage: state => state.message.scratchEmailMessage,

      messageLists: state => state.messageList.messageLists,

      currentMessageSender: state => state.messageSender.currentMessageSender,
      savedEmail: state => state.message.savedEmail,
    }),
    ...mapGetters({
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      getBeefreePreviewAndFooterHtmlForInjection: 'message/getBeefreePreviewAndFooterHtmlForInjection',
    }),

    currentMessageSenderName() {
      if (this.currentMessageSender && this.currentMessageSender.additionalInfo && this.currentMessageSender.additionalInfo.senderName) {
        return this.currentMessageSender.additionalInfo.senderName;
      } else if (this.currentMessageSender && this.currentMessageSender.meta && this.currentMessageSender.meta.email && this.currentMessageSender.meta.email.senderName) {
        return this.currentMessageSender.meta.email.senderName;
      } else if (this.currentMessageList) {
        const messageListPromoterPropertyOid = this.currentMessageList && this.currentMessageList.meta.email ? this.currentMessageList.meta.email.promoterPropertyOid : null;
        const sender = this.messageSenders.find( item => item.oid === messageListPromoterPropertyOid);
        return sender && sender.additionalInfo ? sender.additionalInfo.senderName : '';
      } else {
        return null;
      }
      return null;
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageFacebookPageImageSrc() {
      if (this.displayMessageData.provider !== 'facebook') {
        return null;
      }
      if (this.displayMessageData?.meta?.facebookMessenger?.imageSrc) {
        return this.displayMessageData?.meta?.facebookMessenger?.imageSrc;
      } else {
        return this.displayMessageData?.meta?.facebookMessenger?.imageSrc;
      }
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageFacebookPageName() {
      if (this.displayMessageData.provider !== 'facebook') {
        return null;
      }
      if (this.displayMessageData?.meta?.facebookMessenger?.pageName) {
        return this.displayMessageData?.meta?.facebookMessenger?.pageName;
      } else {
        return this.displayMessageData?.meta?.facebookMessenger?.pageName;
      }
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageMessageBody() {
      if (this.displayMessageData.provider === 'email') {
        return null;
      }
      return this.displayMessageData?.meta.messageBody;
    },

    currentMessageListsOid() {
      return this.messageLists.map(ml => ml.oid);
    },

    messageListsOids() {
      if (!this.messages.length) return [];

      return this.messages.reduce((acc, msg) => {
        const messageListOid = msg.meta.messageListOid;
        // Check that messageListOid is not null, and is not already loaded
        return messageListOid && !this.currentMessageListsOid.includes(messageListOid) ?
          // eslint-disable-next-line multiline-ternary
          [...acc, messageListOid] : acc;
      }, []);
    },

    // Fields for preview modal

    currentSelectedMessageSubject() {
      if (this.currentSelectedMessage?.abEmail && this.currentSelectedMessage?.abEmailType === 'subject') {
        return this.scratchEmailMessage.meta?.messageBody?.subject || ''
      }

      return this.currentSelectedMessage?.meta.messageBody.subject;
    },
    currentSelectedMessagePreviewText() {
      if (this.currentSelectedMessage?.abEmail && this.currentSelectedMessage?.abEmailType === 'subject') {
        return this.scratchEmailMessage.meta?.messageBody?.previewText || ''
      }
      return this.currentSelectedMessage?.meta.messageBody.previewText;
    },

    templateHTML() {
      if (this.currentSelectedMessage?.abEmail)
        return this.scratchEmailWithInfo;
      else if (this.currentSelectedMessage?.meta?.presentation?.templateType === 'html')
        return this.currentSelectedMessage?.meta?.presentation?.template
      return this.savedEmail
    },

    scheduledAt() {
      return this.displayMessageData?.scheduledAt
    },
  },

  created() {
      this['message/RESET_MESSAGES']();
  },

  beforeDestroy() {
      this['message/RESET_MESSAGES']();
      this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
  },
  methods: {
    ...mapActions([
      'message/FETCH_MORE_MESSAGES',
      'message/CANCEL_MESSAGES_FETCHING',
      'message/FETCH_BEEFREE_EMAIL_TEMPLATE_FROM_URL',
      'message/FETCH_MESSAGE',
      'messageList/FETCH_MORE_MESSAGE_LISTS',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'SHOW_CONFIRM',
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE'
    ]),
    ...mapMutations([
      'message/RESET_MESSAGES',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/RESET_SCRATCH_EMAIL_MESSAGE',
      'message/SELECT_AB_EMAIL_MESSAGE',
      'message/SET_STATS_AB',
      'message/RESET_CURRENT_SELECTED_MESSAGE',
    ]),
    async openMessagePreviewModal(data) {
      this.isFetchingPreviewTemplate = true;

      this.displayMessageData = data

      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({ oid: data.meta?.email?.promoterPropertyOid })

      if (data.provider === 'email') {
        this.displayEmailMessagePreviewModal = true;
        await this['message/FETCH_MESSAGE'](data.oid);
        if (this.currentSelectedMessage?.abEmail) {
          this['message/SELECT_AB_EMAIL_MESSAGE']('A')
          await this.getScratchEmailWithInfo()
        } else if (this.currentSelectedMessage?.meta?.presentation?.templateType === 'html') {
          // Do nothing
        } else {
          await this['message/FETCH_BEEFREE_EMAIL_TEMPLATE_FROM_URL'](data.meta.templateResourceOid);
        }
      } else {
        this.displaySmsMessagePreviewModal = true;
      }

      this.isFetchingPreviewTemplate = false;
    },

    handleVariantSelect() {
      this.getScratchEmailWithInfo();
    },

    async updateInjectedHtml() {
      this.generatedHtml = await this.getBeefreePreviewAndFooterHtmlForInjection(this.currentMessageSender);
    },

    async getScratchEmailWithInfo() {
      this.isFetchingTemplate = true;
      if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
        await this.updateInjectedHtml();
        const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
          design: this.scratchEmailMessage?.meta?.presentation?.template,
          previewHtml: this.generatedHtml?.previewHtml || null,
          footerHtml: this.generatedHtml?.footerHtml || null
        });
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);

        this.scratchEmailWithInfo = html;
      } else if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'html') {
        const html = this.scratchEmailMessage?.meta?.presentation?.template;
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);
        this.scratchEmailWithInfo = html;
      } else {
        // Unlayer
        this.scratchEmailWithInfo = this.injectInfoToScratchEmail(this.currentMessageSender);
      }
      this.isFetchingTemplate = false;
    },

    handleClose() {
      this.displaySmsMessagePreviewModal = false
      this.displayEmailMessagePreviewModal = false
      this.$emit('close')
    }
  },
}
</script>
