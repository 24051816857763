<template>
  <section class="external-container">
    <div v-if="!error" class="message-preview-container">
      <div class="header-container">
        <ar-simple-select
          v-if="isAbEmail"
          :style="{ width: '150px', height: '40px' }"
          enable-filter
          :items="abOptions"
          :default-select-index="abSwitchIndex"
          @select="handleVariantSelectClick"
        />
        <am2-elegant-tabs
          class="tabs"
          :items="modeTabs"
          :tab-key="mode"
          @select="handleModeSelect"
        />
      </div>
      <div
        v-if="mode === 'mobile'"
        class="mobile-preview-section"
      >
        <am2-mobile-container
          :has-footer="false"
          size="fullHeight"
        >
          <am2-email-preview
            opened
            :email-template="emailWithInfo"
            :subject="subject"
            :preview-text="previewText"
            :sender-name="senderName"
          />
        </am2-mobile-container>
      </div>
      <div
        v-if="mode === 'desktop'"
        class="desktop-preview-section"
      >
        <am2-email-preview
          :mobile="false"
          opened
          :email-template="emailWithInfo"
          :subject="subject"
          :preview-text="previewText"
          :sender-name="senderName"
        />
      </div>
    </div>
    <div v-else class="center-text-cointainer">
      <p>Preview Error: {{error}}</p>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'MessagePreview',
  layout: 'mono-no-auth',

  data() {
    return {
      mode: 'desktop',
      modeTabs: [
        {
          name: 'Desktop',
          key: 'desktop',
        },
        {
          name: 'Mobile',
          key: 'mobile',
        },
      ],
      emailWithInfo: '',
      abOptions: [{ name: 'Type A', val: 'A' }, { name: 'Type  B', val: 'B' }],
      selectedVariant: 'A',
    };
  },

  computed: {
    ...mapState({
      hash: state => state.route.params.hash,
      abEmail: state => state.route.query.abEmail,
      message: state => state.messagePreview.message,
      emailHtml: state => state.messagePreview.emailHtml,
      error: state => state.messagePreview.error,
    }),

    ...mapGetters({
      injectInfoToPreviewEmail: 'messagePreview/injectInfoToPreviewEmail',
    }),

    isAbEmail() {
      return this.abEmail === "true";
    },

    abSwitchIndex() {
      return this.selectedVariant === 'A' ? 0 : 1;
    },

    subject() {
      return this.message?.messageBody?.subject || '';
    },

    previewText() {
      return this.message?.messageBody?.previewText || '';
    },

    senderName() {
      return this.message?.senderName || '';
    },
  },

  watch: {
    message() {
      this.generateHtmlAndGetEmailWithInfo();
    },

    selectedVariant(newVal, oldVal) {
      if (newVal === oldVal) return
      this.loadMessage()
    },
  },

  methods: {
    ...mapActions([
      'messagePreview/FETCH_MESSAGE',
      'messagePreview/GENERATE_EMAIL_HTML',
    ]),

    async generateHtmlAndGetEmailWithInfo() {
      await this['messagePreview/GENERATE_EMAIL_HTML']();
      await this.getEmailWithInfo()
    },

    async loadMessage() {
      if (this.isAbEmail) {
        await this['messagePreview/FETCH_MESSAGE']({
          hash: this.hash,
          variant: this.selectedVariant,
        });
      } else {
        await this['messagePreview/FETCH_MESSAGE']({
          hash: this.hash
        });
      }
    },

    handleModeSelect(tabItem) {
      this.mode = tabItem.key;
    },

    async getEmailWithInfo() {
      const injectedData = this.injectInfoToPreviewEmail();
      if (this.message?.presentation?.templateType === 'beefree') {
        this.emailWithInfo = this.message.templateHtml
      } else if (this.message?.presentation?.templateType === 'html') {
        this.emailWithInfo = this.message.presentation.template
      } else {
        this.emailWithInfo = injectedData
      }
    },

    handleVariantSelectClick(item) {
      this.selectedVariant = item.val;
    },
  },

  async mounted() {
    this.loadMessage();
  },
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 10px;
}
.tabs {
  width: 200px;
}

.external-container {
  height: 100vh;
  background: #FFF;
}

.message-preview-container {
  position: relative;
  height: 100%;
  background: #FFF;
}

.mobile-preview-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  height: calc(100% - 30px);
}

.desktop-preview-section {
  padding: 12px 0 0;
  height: calc(100% - 30px);
}

.center-text-cointainer {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}
</style>
