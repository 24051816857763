<template>
  <am2-table
    ref="message-table"
    class="message-table"
    :heads="decoratedHead"
    :rows="tableRows"
    :loading="loading"
    :row-height="74"
    empty-text="No messages available"
    enable-row-click
    :has-sticky-header="hasStickyHeader"
    @rowClick="handleMessageRowClick"
    custom-head-padding="0 22px"
  >
    <div
      slot="oid"
      slot-scope="{ data, head }"
      :class="[
        'percentage-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="percentage-cell__container">
        <template v-if="!!data[head.key]">
          <ar-text
            class="value"
            size="xs"
            :text="data[head.key]"
            align="left"
          />
        </template>
        <span v-else class="empty-dash" />
      </div>
    </div>
    <div
      slot="message"
      slot-scope="{ data }"
      class="message-cell"
      v-tooltip.top="{
        content: getError(data) || null,
      }"
      :style="{
        padding: $arMediaQuery.pageContent.minWidth('sm') ? '17px 14.5px 16.5px 22px' : '10px 14.5px 10px 22px',
      }"
    >
      <div class="type-container">
        <ar-icon
          class="icon"
          :name="getIcon(data.provider, data).name"
          :color="getIcon(data.provider, data).color"
          width="19px"
        />
      </div>
      <div class="body-container">
        <div style="display: flex">
          <am2-tag v-if="isAbEmail(data)" class="ab-tag" type="purple500" shape="rectangle" text="A/B" text-size="10px" />
          <ar-text
            v-if="data.provider === 'email'"
            :class="['main', getMessageUiStatus(data) === 'completed' && 'sent']"
            size="xs"
            :text="generateEmailMessageName(data)"
            weight="bold"
            v-tooltip.top="{
              content: generateEmailMessageName(data) ? generateEmailMessageName(data) : null,
              delay: { show: 800, hide: 0 }
            }"
          />
          <ar-text
            v-else
            :class="['main', getMessageUiStatus(data) === 'completed' && 'sent']"
            size="xs"
            :text="generateSimpleMessageName(data)"
            weight="bold"
            v-tooltip.top="{
              content: generateSimpleMessageName(data) ? generateSimpleMessageName(data) : null,
              delay: { show: 800, hide: 0 }
            }"
          />
        </div>
        <ar-text
          class="subtitle"
          size="xxxs"
          :text="data.lastActionTimeText"
          v-tooltip.top="{
            content: data.lastActionTimeTooltip ? data.lastActionTimeTooltip : null
          }"
          multiple-lines
          :max-lines="1"
          line-height="16px"
        />
      </div>
    </div>
    <div
      slot="recipients"
      slot-scope="{ data, head }"
      :class="[
        'percentage-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="percentage-cell__container">
        <template v-if="!!data[head.key]">
          <ar-text
            class="value"
            size="xs"
            :text="data[head.key]"
            align="right"
          />
        </template>
        <span v-else class="empty-dash" />
      </div>
    </div>
    <div
      slot="opens"
      slot-scope="{ data, head }"
      :class="[
        'percentage-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="percentage-cell__container">
        <template v-if="data[head.key] > 0 && data.provider !== 'sms'">
          <ar-text
            class="value inactive-only"
            size="xs"
            :text="`${data[head.key]}%`"
            align="right"
            weight="bold"
          />
          <ar-text
            class="value active-only"
            size="xs"
            :text="data.opensRaw"
            align="right"
            weight="bold"
          />
        </template>
        <span v-else class="empty-dash" />
      </div>
    </div>
    <div
      slot="clicks"
      slot-scope="{ data, head }"
      :class="[
        'percentage-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="percentage-cell__container">
        <template v-if="data[head.key] > 0">
          <ar-text
            class="value inactive-only"
            size="xs"
            :text="`${data.clicks}%`"
            align="right"
            weight="bold"
          />
          <ar-text
            class="value active-only"
            size="xs"
            :text="`${data.clicksRaw}`"
            align="right"
            weight="bold"
          />
        </template>
        <span v-else class="empty-dash" />
      </div>
    </div>
    <div
      slot="edited"
      slot-scope="{ data, head }"
      :class="[
        'message-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="text-cell-container">
        <div class="text-cell">
          <ar-text
            class="main"
            size="xs"
            :text="data.editedText"
            v-tooltip.top="{
              content: data.editedText ? data.editedText : null
            }"
          />
        </div>
      </div>
    </div>
    <div
      slot="sent"
      slot-scope="{ data, head }"
      :class="[
        'message-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="text-cell-container">
        <div class="text-cell">
          <ar-text
            class="main"
            size="xs"
            :text="data.startedText"
            v-tooltip.top="{
              content: data.startedText ? data.startedText : null
            }"
          />
        </div>
      </div>
    </div>
    <div
      slot="delivers"
      slot-scope="{ data, head }"
      :class="[
        'message-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
      <div class="text-cell-container">
        <div class="text-cell">
          <ar-text
            class="main"
            size="xs"
            :text="data.deliversText"
            v-tooltip.top="{
              content: data.deliversText ? data.deliversText : null
            }"
          />
        </div>
      </div>
    </div>
    <div
      slot="status"
      slot-scope="{ data, head }"
      :class="[
        'message-cell',
        $arMediaQuery.pageContent.maxWidth('xs') && 'u-padding-y-3'
      ]"
    >
    <div class="status-action-container">
      <div class="status">
        <div>
          <am2-tag
            :text="generateStatusCopy(data.uiStatus)"
            :type="statusToTagColor(data.uiStatus)"
            :style="{
              border: '1px solid white'
            }"
          />
        </div>
      </div>
    </div>
    </div>
    <div
      slot="menu"
      slot-scope="{ data, head }"
      :class="['menu-cell']"
    >
      <div class="preview-action-container">
        <ar-icon-button
          v-if="data.uiStatus === 'completed'"
          :data-test-id="`message-preview-button-${data.oid}`"
          class="preview-button"
          :icon-props="{
            name: 'preview',
            width: '16px',
          }"
          @click="() => preview(data)"
        />
      </div>
      <div class="action-container">
        <am2-icon-button-dropdown
          v-if="!data.hideActions"
          :icon-props="{
            name: 'menu',
          }"
          :items="messageOptions(data)"
          @select="(item) => handleMessageOptionSelect(item.eventName, data)"
        />
      </div>
    </div>
  </am2-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import accounting from 'accounting';
import { convertToTitleCase } from '@/utils/helpers';
import { generateMessageCenterDateCopy } from '@/utils/date/';

export default {
  name: 'MessagesTable',

  props: {
    head: {
      type: Array,
      default: () => [],
    },
    messages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasStickyHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enableEmailSending: process.env.arEnableEmailSending,
    };
  },
  computed: {
    ...mapGetters({
      getMessageUiStatus: 'message/getMessageUiStatus',
      getAvailableMessageOptionsMap: 'message/getAvailableMessageOptionsMap',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    decoratedHead() {
      return this.head.map(item => {
        let align = 'right', width;

        if (item.key === 'message') {
          width = 392;
          align = 'left';
        } else if (item.key === 'menu') {
          width = 56;
          align = 'right';
        } else if (item.key === 'edited') {
          width = 400;
        } else if (item.key === 'oid') {
          width = 50;
          align = 'left';
        }

        return {
          ...item,
          width,
          align,
        }
      });
    },
    tableRows() {
      return this.messages.map(m => {
        const { opened = 0, clicked = 0, bounced = 0, deferred = 0, failed = 0, unsubscribed = 0, totalMessages } = m?.statusDetails || {};
        const successfulSends = (totalMessages || 0)
          - (bounced || 0)
          - (deferred || 0)
          - (failed || 0);
        let lastActionTimeText;

        let usersTimeZone = null;
        try {
          usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch(e) {
          //
        }

        const uiStatus = this.getMessageUiStatus(m);
        const timezone = m.meta && m.meta.presentation ? m.meta.presentation.timeZone : null;

        // Only use 'last updated time' when the message is a draft
        let lastActionTimeTooltip = null;
        if (uiStatus === 'completed' || uiStatus === 'in-progress') {
          const startedTime = m.started || m.scheduledAt || m.sysCtime;
          lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(startedTime, (timezone || usersTimeZone))}`;
          lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(startedTime)} local time` : null;
        } else if (uiStatus === 'scheduled') {
          lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(m.scheduledAt, (timezone || usersTimeZone))}`;
          lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(m.scheduledAt)} local time` : null;
        } else {
          lastActionTimeText = `${this.actionStartingWord(uiStatus)} ${generateMessageCenterDateCopy(m.sysMtime, (timezone || usersTimeZone))}`;
          lastActionTimeTooltip = timezone && timezone !== usersTimeZone ? `${generateMessageCenterDateCopy(m.sysMtime)} local time` : null;
        }

        if (m?.meta?.senderFriendlyName) {
          lastActionTimeText += ` from ${m.meta.senderFriendlyName}`;
        }

        let hideActions = false;
        if (m.provider === 'email' && !this.enableEmailSending) {
          hideActions = true;
        }

        const editedText = moment.utc(m.sysMtime).tz(timezone || usersTimeZone)?.fromNow() || generateMessageCenterDateCopy(m.sysMtime, false);
        const startedText = moment.utc(m.started).tz(timezone || usersTimeZone)?.fromNow() || generateMessageCenterDateCopy(m.started, false);
        const deliversText = generateMessageCenterDateCopy(m.scheduledAt, (timezone || usersTimeZone));

        // clicks should count as opens because one needs to open a message before
        // they can click a link inside a message
        // click rate should be a % of opens, rather than sends.
        const opensCount = opened + clicked + unsubscribed;

        let opens = successfulSends > 0 ? ((100 * opensCount) / (totalMessages - (bounced || 0))) : 0;
        if (opens < 10) {
          opens = opens.toFixed(2);
        } else if (opens > 99 && opens < 100) {
          opens = 99;
        } else {
          opens = Math.trunc(opens);
        }

        // The CTR for SMS is clicks / recipients
        // The CTR for email is clicks / opens
        let clicks;
        if (m.provider === 'sms') {
          clicks = successfulSends > 0 ? ((100 * clicked) / successfulSends) : 0;
        } else {
          clicks = clicked > 0 ? ((100 * clicked) / (totalMessages - (bounced || 0))) : 0;
        }

        if (clicks < 10) {
          clicks = clicks.toFixed(2);
        } else if (clicks > 99 && clicks < 100) {
          clicks = 99;
        } else {
          clicks = Math.trunc(clicks);
        }

        let totalRecipients = null;
        if (m.statusDetails && Object.keys(m.statusDetails).length > 0) {
          totalRecipients = totalMessages;
        } else if (m.messageList) {
          totalRecipients = m?.messageList?.statsSnapshot?.total;
        }

        return {
          ...m,
          uiStatus,
          opens: opens,
          opensRaw:  accounting.formatNumber(opensCount),
          clicks: clicks,
          clicksRaw: accounting.formatNumber(clicked),
          lastActionTimeText,
          lastActionTimeTooltip,
          editedText,
          startedText,
          deliversText,
          hideActions,
          recipients: accounting.formatNumber(totalRecipients),
        }
      });
    },

    isAbEnabled() {
      return this.isFeatureEnabled(['ab-emails'])
    },
  },

  methods: {
    ...mapActions([
      'message/ARCHIVE_MESSAGE',
      'message/CANCEL_MESSAGE',
      'message/CLONE_MESSAGE',
      'SHOW_CONFIRM',
      'OPEN_CANCELLATION_SCHEDULED_MODAL',
    ]),
    getError(task) {
      const { status, statusDetails, uiStatus } = task;
      if (status !== 'failed' && uiStatus !== 'failed') return null
      if (!statusDetails?.error?.details?.code) return null
      if (statusDetails.error.details.code === "no-personalizations") {
        return "The dynamic tag CSV did not match your recipient list."
      }
      if (statusDetails.error.details.code === 'malformed-url') {
        return "Your email contained an invalid or malformed URL."
      }
      return "Your message failed to send. For more information, please contact support@audiencerepublic.com"
    },
    getIcon(provider, data) {
      if (provider === 'facebook') {
        return {
          name: 'messenger',
          color: this.$arStyle.color.messenger,
        };
      } else if (provider === 'sms') {
        return {
          name: 'sms',
          color: this.$arStyle.color.sms,
        };
      } else if (provider === 'email' && data?.meta?.initiator?.powerup) {
        return {
          name: 'email-outlined-power',
          color: this.$arStyle.color.email,
        };
      } else {
        // Email
        return {
          name: 'email',
          color: this.$arStyle.color.email,
        };
      }
    },
    numSent(details) {
      if (details.totalMessages) {
        const sent = details.sent || 0;
        const delivered = details.delivered || 0;
        const bounced = details.bounced || 0;
        const failed = details.failed || 0;
        const clicked = details.clicked || 0;
        const opened = details.opened || 0;
        const spam = details.markedSpam || 0;
        const unsubscribed = details.unsubscribed || 0;
        const deferred = details.deferred || 0;

        return sent + delivered + bounced + failed + opened + clicked + spam + unsubscribed + deferred;
      } else {
        return 0;
      }
    },

    isAbEmail(data) {
      return data.provider === 'email' && !!data.meta?.messageTreatment;
    },

    generateEmailMessageName(data) {
      if (data.customerName) {
        return data.customerName;
      } else if (data.meta?.messageBody?.subject) {
        return data.meta.messageBody.subject;
      } else {
        return 'Untitled email';
      }
    },

    generateSimpleMessageName(data) {
      return data.customerName || data.meta?.messageBody || null;
    },

    generateStatusCopy(word) {
      if (word === 'completed') {
        return 'Sent';
      } else if (word === 'in-progress') {
        return 'Sending';
      } else {
        return convertToTitleCase(word);
      }
    },

    statusToTagColor(status){
      switch (status.toLowerCase()) {
        case 'in-progress':
          return 'purple';
        case 'completed':
          return 'green';
        case 'failed':
          return 'red';
        default:
          return 'grey';
      }
    },
    actionStartingWord(status) {
      if (status ===  'draft') {
        return 'Edited';
      } else if (status ===  'scheduled') {
        return 'Scheduled';
      } else if (status ===  'archived') {
        return 'Archived';
      } else if (status ===  'cancelled') {
        return 'Cancelled';
      } else if (status ===  'failed') {
        return 'Failed';
      } else {
        return 'Sent';
      }
    },

    messageOptions(message) {
      const isUnlayer = message?.meta?.presentation?.templateType === 'unlayer'
      const availableMessageOptionsMap = this.getAvailableMessageOptionsMap(message);
      const options = [{
        name: 'Edit',
        eventName: 'edit',
      }];

      if (message.provider !== 'facebook') {
        options.push({
          name: 'Duplicate',
          eventName: 'duplicate',
          dataTestId: `Dropdown-item-Duplicate${isUnlayer ? '-unlayer' : ''}`
        });
      }

      options.push({
          name: 'View',
          eventName: 'view',
        },
        {
          name: 'Archive',
          eventName: 'archive',
        },
        {
          name: 'Cancel',
          eventName: 'cancel',
          typography: {
            style: {
              color: this.$arStyle.color.red500,
            },
          },
        });

      return options.filter(option => availableMessageOptionsMap[option.eventName]);
    },

    async goToMessageEdit(message) {
      if (message.provider === 'sms') {
        this.$router.push(`/message-center/messages/sms/${message.oid}/edit`);
      } else if (message.provider === 'facebook') {
        this.$arNotification.push({ type: 'warning', message: 'Facebook Messenger is no longer available' });
      } else if (message.provider === 'email') {
        if(this.$arMediaQuery.window.maxWidth('xs')) {
          const response = await this.SHOW_CONFIRM({
            messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
            hideConfirmButton: true,
            cancelButtonText: 'Back',
          });
        } else {
          this.$router.push(`/message-center/messages/email/${message.oid}/edit/basic`);
        }
      }
    },

    goToMessageView(message) {
      this.$router.push(`/message-center/messages/${message.oid}/view/overview`);
    },

    async duplicateMessage(message) {
      await this['message/CLONE_MESSAGE'](message.oid);
      this.$emit('cloneMessage');
    },

    async archiveMessage(message) {
      const messageTaskOid = message.oid;
      this['message/ARCHIVE_MESSAGE'](messageTaskOid);
      this.$emit('archiveMessage');
    },

    async cancelMessage(message) {
      const resp = await this.OPEN_CANCELLATION_SCHEDULED_MODAL();

      if(resp) {
        const messageTaskOid = message.oid;
        await this['message/CANCEL_MESSAGE']({ taskOid: messageTaskOid});
        this.$emit('cancelMessage');
      }
    },

    handleMessageRowClick(message) {
      const availableOptionsMap = this.getAvailableMessageOptionsMap(message);
      if (availableOptionsMap.edit) {
        this.goToMessageEdit(message);
      } else if (availableOptionsMap.view) {
        this.goToMessageView(message);
      }
    },

    handleMessageOptionSelect(event, message) {
      if (event === 'view') {
        this.goToMessageView(message);
      } else if (event === 'edit') {
        this.goToMessageEdit(message);
      } else if (event === 'duplicate') {
        this.duplicateMessage(message);
      } else if (event === 'archive') {
        this.archiveMessage(message);
      } else if (event === 'cancel') {
        this.cancelMessage(message);
      }
    },

    preview(data) {
      this.$emit('openMessagePreviewModal', data)
    },
  },
};
</script>

<style lang="scss" scoped>
.message-table {
  .message-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .preview-action-container {
      opacity: 0;
    }

    &:hover {
      .type-container {
        box-shadow: 0px 0px 1px rgba(0,0,0,0.5);
      }

      .preview-action-container {
        opacity: 1;
      }
    }

    .type-container {
      border: 2px #EBEBEB solid;
      border-radius: 20px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 23px;
      background-color: white;
      flex-shrink: 0;

      .icon {
        position: relative;
        left: 0.5px;
      }
    }

    .action-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  // TODO - Need a better solution than referencing the row within the parent.
  >>>.tr {
    .percentage-cell {
      &__container {
        .active-only {
          display: none;
        }
        .inactive-only {
          display: block;
        }
      }
    }
    &:hover {
      .percentage-cell {
        &__container {
          .active-only {
            display: block;
          }
          .inactive-only {
            display: none;
          }
        }
      }
    }
  }

  .body-container {
    flex-grow: 1;
    font-weight: bold;
    overflow: hidden;
    padding-right: 16px;

    .main {
      color: rgba-to-rgb(rgba($blueGrey800, 0.5));

      &.sent {
        color: $blueGrey800;
      }
    }

    .subtitle {
      color: rgba-to-rgb(rgba($blueGrey800, 0.5));
      margin-top: 5px;
    }
  }

  .text-cell-container {
    width: 100%;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text-cell {
    padding: 25.5px 25.5px 16.5px 25.5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .status-action-container {
    width: 100%;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .tr:hover {
    .preview-button {
      visibility: visible;
    }
  }

  .menu-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .preview-button {
      visibility: hidden;
    }
  }

  .percentage-cell {
    padding: 25.5px 25.5px 16.5px 25.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__container {
      width: 100%;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .value {
      margin-bottom: 6px;
    }

    .subtitle {
      color: rgba-to-rgb(rgba($blueGrey800, 0.5));
    }

    .empty-dash {
      align-self: flex-end;
      width: 14px;
      border-bottom: 1px solid $blueGrey600;
    }
  }

  //.ab-email-enabled {
  //  display: flex;
  //  flex-direction: row;
  //  align-items: flex-start;
  //  background-color: #7344c0;
  //  border-radius: 3px;
  //  color: white;
  //  margin-right: 12px;
  //  min-width: 22px;
  //  min-height: 16px;
  //  .ar-text {
  //    color: white;
  //    padding: 2px 4px 2px 4px;
  //  }
  //}

  &__ab-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .ab-tag {
    border: none !important;
    margin-right: 4px;
  }
}
</style>
