var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"new-sub-account-modal",attrs:{"is-open":_vm.isShow,"header":(_vm.title + " Alphanumeric ID"),"width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[(!!_vm.currentData)?_c('div',{class:[
        'new-sub-account-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('ar-field',{staticClass:"new-sub-account-modal-body-row",attrs:{"label":"Friendly name","error-messages":[
          _vm.veeErrors.first('friendly-name') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|alphanumericSpace|notAllNumbers'),expression:"'required|alphanumericSpace|notAllNumbers'"}],attrs:{"name":"friendly-name","data-vv-name":"friendly-name","data-vv-as":"friendly name","placeholder":"e.g Audience Republic","maxLength":11},model:{value:(_vm.currentData.friendlyName),callback:function ($$v) {_vm.$set(_vm.currentData, "friendlyName", $$v)},expression:"currentData.friendlyName"}}),_vm._v(" "),_c('ar-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.veeErrors.first('friendly-name')),expression:"!veeErrors.first('friendly-name')"}],staticClass:"description",style:({
            color: _vm.$arStyle.color.blueGrey700,
            marginTop: '8px',
          }),attrs:{"size":"xs","text":"Up to 11 characters long, upper and lower-case letters, numbers 0 through 9, and the space character. They cannot be numbers only.","multiple-lines":""}})],1),_vm._v(" "),_c('ar-field',{staticClass:"new-sub-account-modal-body-row",attrs:{"label":"Alpha Sending Service SID","error-messages":[
          _vm.veeErrors.first('message-service-sid') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"alpha-send-service-sid","data-vv-name":"alpha-send-service-sid","data-vv-as":"alpha send service sid","placeholder":"MGXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"},model:{value:(_vm.currentData.messageServiceSid),callback:function ($$v) {_vm.$set(_vm.currentData, "messageServiceSid", $$v)},expression:"currentData.messageServiceSid"}}),_vm._v(" "),_c('ar-text',{staticClass:"description",style:({
            color: _vm.$arStyle.color.blueGrey700,
            marginTop: '8px',
          }),attrs:{"size":"xs","text":"Copy and paste the phone sending alpha send service SID here","multiple-lines":""}})],1),_vm._v(" "),_c('ar-field',{staticClass:"new-sub-account-modal-body-row",attrs:{"label":"Countries","error-messages":[
          _vm.veeErrors.first('countries') ]}},[_vm._l((_vm.countries),function(country,index){return _c('div',{key:index},[_c('div',{staticClass:"u-display-flex u-margin-bottom-3"},[_c('ar-country-select',{style:({
              width: '100%',
              height: '42px',
            }),attrs:{"value":country,"value-type":"iso"},on:{"select":function (selection) { _vm.handleSelectCountry(selection.iso, index) }}}),_vm._v(" "),_c('ar-icon',{staticClass:"delete-button u-margin-left-2",style:({cursor: 'pointer'}),attrs:{"name":"discard","height":"16px","color":_vm.$arStyle.color.blueGrey600},on:{"click":function () { return _vm.handleDeleteCountryButton(index); }}})],1)])}),_vm._v(" "),_c('ar-simple-button',{style:({
            width: '100%',
          }),attrs:{"icon-name":"plus","icon-props":{
            width: '16px',
          },"outlined":""},on:{"click":_vm.handleAddCountryButton}}),_vm._v(" "),_c('ar-text',{staticClass:"description",style:({
            color: _vm.$arStyle.color.blueGrey700,
            marginTop: '8px',
          }),attrs:{"size":"xs","text":"Pick the country or countries supported by this alpha sender","multiple-lines":""}})],2),_vm._v(" "),(_vm.mode === 'edit')?_c('ar-snackbar',{attrs:{"type":"warning","message":"Editing these details WILL NOT change what a customer sees. They MUST be edited in Twilio first."}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{class:[
        'new-sub-account-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-link-button',{style:({
          height: '40px',
          padding: '0 20px'
        }),attrs:{"text":"Cancel"},on:{"click":_vm.handleCloseClick}}),_vm._v(" "),_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }