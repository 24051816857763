<template>
  <am2-card-container layout="regular">
    <div class="overview-open-clicks-chart-container">

      <!-- Time switcher -->

      <div class="overview-open-clicks-chart-header">
        <ar-text :text="isSms ? 'Clicks' : 'Opens vs clicks'" size="sm" />
        <am2-elegant-tabs 
          :items="ocChartTabItems" 
          tab-key="day"
           @select="handleTimeRangeChange" 
           :style="{
            minWidth: '250px',
          }"
          layout="wide"
          data-test-id="overview-open-vs-click-tabs"
        />
      </div>

      <!-- Line chart -->

      <div class="chart-wrapper">
        <apexcharts
          v-if="!chartLoading"
          type="line" 
          height="250" 
          :options="chartOptions" 
          :series="chartSeries"
          data-test-id="overview-open-vs-click-chart"
        />
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'OverviewOpenClicksChart',

  data() {
    return {
      ocChartTimeRange: 'day',
      ocChartTabItems: [
        {
          key: 'day',
          name: 'First 24 hours'
        },
        {
          key: 'week',
          name: 'First 7 days'
        }
      ],
    };
  },

  computed: {
    ...mapState({
      oid: state => state.route.params.oid,
      currentSelectedMessage: state => state.message.currentSelectedMessage,

      messageActionStatsTimeseries: state => state.message.messageActionStatsTimeseries,
      isFetchingMessageActionStatsTimeseries: state => state.message.isFetchingMessageActionStatsTimeseries,
    }),

    isSms() {
      return this.currentSelectedMessage?.provider === 'sms';
    },

    chartLoading() {
      return this.isFetchingMessageActionStatsTimeseries;
    },

    chartDataAvailable() {
      return !this.isFetchingMessageActionStatsTimeseries && !!this.messageActionStatsTimeseries;
    },

    currentSeries() {
      if (this.chartDataAvailable) {
        const k = this.ocChartTimeRange === 'day' ? 'daily' : 'weekly';
        return this.messageActionStatsTimeseries[k];
      }
    },

    chartSeries() {
      if (this.chartDataAvailable && this.currentSeries) {
        const isDay =  this.ocChartTimeRange === 'day';
        const series = this.currentSeries;

        const opens = {
          name: 'Opens',
          data: series.map((x, i) => {
            if (i === (series.length - 1) && isDay) {
              return 0;
            } else {
              return x.opened;
            }
          }),
        };

        const clicks = {
          name: 'Clicks',
          data: series.map((x, i) => {
            if (i === (series.length - 1) && isDay) {
              return 0;
            } else {
              return x.clicked;
            }
          }),
        };

        if (this.isSms) {
          return [clicks]
        }
        return [opens, clicks];
      }
    },

    chartCategories() {
      if (this.chartDataAvailable && this.currentSeries) {
        return this.currentSeries.map(({ ts }) => ts);
      }
    },

    chartOptions() {
      const timerange = this.ocChartTimeRange
      return {
        chart: {
          height: 250,
          type: 'line',
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          }
        },
        colors: [this.$arStyle.color.purple500, '#000'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        legend: {
          showForSingleSeries: true,
        },
        tooltip: {
          x: {
            show: false,
          }
        },
        yaxis: {
          labels: {
            formatter: val => val | 0,
          },
        },
        grid: {
          row: {
            opacity: 0.3
          },
        },
        xaxis: {
          type: 'datetime',
          categories: this.chartCategories,
          labels: {
            formatter: function (value, ts) {
              return moment(ts).format(timerange === 'day' ? 'hh a' : 'DD MMM');
            }, 
          }
        }
      };
    },
  }, 

  async mounted() {
    this.fetchMessageActionStatsTimeseries();
  },

  methods: {
    ...mapActions([
      'message/FETCH_MESSAGE_ACTION_STATS_TIMESERIES',
    ]),

    handleTimeRangeChange(range) {
      this.ocChartTimeRange = range.key;
    },

    async fetchMessageActionStatsTimeseries() {
      await this['message/FETCH_MESSAGE_ACTION_STATS_TIMESERIES'](this.oid);
    },
  },
}
</script>

<style lang="scss" scoped>
.overview-open-clicks-chart {
  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 30px;
  }

  &-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2em;
  }

  .chart-container {
    position: relative;
    width: 100%;
  }

  .chart-wrapper {
    opacity: 1;
    visibility: visible;
    width: 100%;

    min-height: 250px;
  }

  .apex-chart {
    height: 100%;
  }
}
</style>
