import { parseJwt } from '@/utils/helpers';

export function generateCountdown(targetTime: number, applicationTime: number): number {
  const diffInSeconds = Math.floor((targetTime - applicationTime) / 1000);
  return diffInSeconds > 0 ? diffInSeconds : 0;
};

type AnyObject = { [key: string]: any };

export function flattenMaps(obj: AnyObject): AnyObject[] {
  function deepestMaps(o: AnyObject, acc: AnyObject[]): AnyObject[] {
    for (const [_, value] of Object.entries(o)) {
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        const hasNestedObjects = Object.values(value).some(v => typeof v === 'object' && v !== null && !Array.isArray(v));
        if (hasNestedObjects) {
          deepestMaps(value, acc);
        }
        // Remove nested maps and retain only simple properties
        const simpleProps: AnyObject = Object.entries(value).reduce((acc: AnyObject, [k, v]) => {
          if (typeof v !== 'object' || v === null || Array.isArray(v)) {
            acc[k] = v;
          }
          return acc;
        }, {});
        if (Object.keys(simpleProps).length > 0) {
          acc.push(simpleProps);
        }
      }
    }
    return acc;
  }

  return deepestMaps(obj, []);
}

export function checkFeatureEnabled(features: string[], promoterAccountFeatures: PromoterAccountFeaturesPc2[]): boolean {

  for (const feature of features) {
    // Find the corresponding feature in the promoterAccountFeatures array
    const matchingFeature = promoterAccountFeatures.find(promoterFeature => promoterFeature.id === feature);

    // If the feature is not found or it is not enabled, return false immediately
    if (!matchingFeature?.enabled) {
      return false;
    }
  }

  return true;
}

// All permissionKeys listed should be all inside promoterPermissions for this to be true
export function checkPermissionEnabled(permissionKeys: string[], promoterPermissions: PermissionsState) {
  if (!promoterPermissions) {
    return false;
  }

  const promoterPermissionsKeys = Object.keys(promoterPermissions)

  if (promoterPermissionsKeys.length <= 0) {
    return false;
  }

  return permissionKeys.every(permissionKey =>
    promoterPermissionsKeys.some(promoterPermissionKey => {
      return promoterPermissions[promoterPermissionKey]?.includes(permissionKey) || false
    })
  );
}

export function checkIsAdminAccount(xAuthToken: string) {
  const jwt = parseJwt(xAuthToken);
  const clientIdentity = JSON.parse(jwt['client-identity']);
  return clientIdentity.accountType === 'ADMIN';
}
