<template>
  <div class="user-role-select">
    <ar-select
      ref="select"
      :items="selectItems"
      :style="{
        height: '100%',
      }"
      enable-filter
      :filter-placeholder="filterPlaceholder"
      @select="handleSelect"
      @close="() => { $emit('close') }"
      @filterStringInput="handleFilterStringInput"
      data-test-id="user-role-select-dropdown"
      layer="global-highest"
      :defaultSelectIndex="selectedUserRoleIndex"
      :disabled="disabled"
    >

      <template
        slot="selected-item"
      >
        <div
          :style="{
            display: 'flex',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0 14px',
          }"
          :data-test-id="`user-role-select-out-of-scope-selected-item`"
        >
          <ar-text
            size="xs"
            :text="selectedUserRoleName || 'Select user role'"
            :style="{
              color: selectedUserRoleName ? $arStyle.color.blueGrey800 : $arStyle.color.blueGrey600,
            }"
          />
        </div>
      </template>

      <template
        slot="item"
        slot-scope="itemScope"
      >
        <!-- Action Item -->
        <div
          v-if="itemScope.item.isAction"
          :data-test-id="`user-role-item-${itemScope.item.name}`"
          class="user-role-item"
          :style="{
            background: itemScope.active ? $arStyle.color.purple100 : null,
          }"
        >
          <ar-icon
            class="plus-icon"
            name="circle-plus"
            :color="$arStyle.color.purple500"
            height="16px"
            width="16px"
          />
          <ar-text
            size="xs"
            :text="itemScope.item.name"
            :style="{
              width: 'auto',
              color: $arStyle.color.purple500,
              marginLeft: '8px',
            }"
            weight="bold"
          />
        </div>
        <!-- Normal Item -->
        <div
          v-else
          class="user-role-item u-margin-top-3 u-margin-bottom-3"
          :data-test-id="`user-role-item-${itemScope.item.name}`"
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '55px',
            padding: '0 15px',
            cursor: 'pointer',
            background: itemScope.index === selectedUserRoleIndex ? $arStyle.color.purple100 : null,
          }"
        >
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              flexGrow: '1',
              overflow: 'hidden',
              justifyContent: 'center',
              alignItems: 'flext-start',
              flexDirection: 'column',
            }"
          >
            <ar-text
              size="xs"
              :text="itemScope.item.name"
              :style="{
                width: 'auto',
                color: itemScope.index === selectedUserRoleIndex ? $arStyle.color.purple500 : $arStyle.color.blueGrey800,
              }"
              weight="bold"
            />
            <ar-text
              class="u-margin-top-2"
              size="xs"
              :text="itemScope.item.description"
              :style="{
                width: 'auto',
                color: $arStyle.color.blueGrey700,
              }"
              weight="normal"
            />
          </div>
        </div>
      </template>
    </ar-select>
  </div>
</template>

<script>
  import { clone } from '@/utils/helpers/';

  export default {
    name: 'UserRoleSelect',

    props: {
      defaultUserRoles: {
        type: Array,
        default: [],
      },
      promoterUserRoles: {
        type: Array,
        default: [],
      },
      isUserFacing: {
        type: Boolean,
        default: false,
      },
      selectedUserRoles: {
        type: Object,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        selectedUserRoleName: null,
        selectedUserRoleType: null,
        selectedUserRoleIndex: null,
      };
    },

    computed: {
      selectItems() {
        let defaultUserRolesMapped = null;
        let promoterUserRolesMapped = null;
        
        if (!!this.defaultUserRoles) {
          let finalDefaultUserRoles = clone(this.defaultUserRoles)
          if (!!finalDefaultUserRoles && !!this.isUserFacing) {
            finalDefaultUserRoles = finalDefaultUserRoles?.filter((role) => {
              if (role.name === 'Owner') {
                return this.selectedUserRoleName === 'Owner'
              }

              return role.name !== 'AREP Admin'
            })
          }

          defaultUserRolesMapped = finalDefaultUserRoles?.map((role) => {
            return {
              ...role,
              type: 'default'
            }
          }) || []
        }

        if (!!this.promoterUserRoles) {
          promoterUserRolesMapped = this.promoterUserRoles?.map((role) => {
            return {
              ...role,
              type: 'promoter'
            }
          }) || []
        }

        if (!defaultUserRolesMapped && !promoterUserRolesMapped) {
          return []
        } else {
          return [
            ...defaultUserRolesMapped || [],
            ...promoterUserRolesMapped || [],
          ]
        }

      },
    },

    mounted() {
      this.updateSelectedUserRole(this.selectedUserRoles)
    },

    watch: {
      selectedUserRoles(val) {
        this.updateSelectedUserRole(val)
      },
    },

    methods: {
      filterPlaceholder() {
        return 'Select user role';
      },

      handleSelect(item) {
        if (!item) {
          return;
        }
        this.$emit('select', item);
        this.selectedUserRoleOid = item?.oid
        this.selectedUserRoleName = item?.name
        this.selectedUserRoleType = item?.type
        this.setSelectedUserRoleIndex()
      },

      handleFilterStringInput(string) {
        this.selectedUserRoleName = string
        this.$emit('handleFilterInput', string)
      },

      updateSelectedUserRole(selectedUserRoles = null) {
        if (!selectedUserRoles) {
          return null;
        }

        let item = null
        if (selectedUserRoles?.defaultUserRoleOid !== null && !!this.defaultUserRoles) {
          item = this.defaultUserRoles?.find((role) => {
            return role.oid === selectedUserRoles?.defaultUserRoleOid
          })
          this.selectedUserRoleType = 'default'
        } else if (selectedUserRoles?.promoterUserRoleOid !== null && !!this.promoterUserRoles) {
          item = this.promoterUserRoles?.find((role) => {
            return role.oid === selectedUserRoles?.promoterUserRoleOid
          })
          this.selectedUserRoleType = 'promoter'
        }

        this.selectedUserRoleOid = item?.oid
        this.selectedUserRoleName = item?.name

        this.setSelectedUserRoleIndex()
      },

      setSelectedUserRoleIndex() {
        if (!this.selectItems || !this.selectedUserRoleOid) {
          return null;
        }
        this.selectedUserRoleIndex = this.selectItems?.findIndex((item) => {
          return item.type === this.selectedUserRoleType && item.oid === this.selectedUserRoleOid
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
.user-role-select {
  height: 50px;
}

.user-role-item {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  cursor: pointer;

  &:hover {
    background-color: $skyBlueGrey300;
  }
}
</style>
