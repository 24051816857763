<template>
  <AuthenticateFrame>
    <ar-text
      text="You’ve been invited to a workspace"
      size="lg"
      multiple-lines
      :style="{
        marginBottom: '16px',
      }"
    />
    <ar-text
      text="Please sign up to accept the invite"
      size="sm"
      multiple-lines
      :style="{
        color: $arStyle.color.skyBlueGrey700,
        marginBottom: '40px',
      }"
    />

    <am2-loading-bubble
      v-if="isLoading"
      :style="{
        color: $arStyle.color.purple500,
        marginLeft: '20px'
      }"
    />

    <div
      v-if="!isLoading"
    >

      <div
        :style="{
          marginBottom: '20px',
        }"
      >
        <div
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
          }"
        >
          <div
            :style="{
              width: 'calc(50% - 7px)'
            }"
          >
            <ar-input
              v-model="registerData.firstName"
              v-validate="'required'"
              data-vv-name="firstName"
              autocomplete="given-name"
              data-vv-as="first name"
              placeholder="First name"
              :has-error="veeErrors.has('firstName')"
            />
            <ar-state-message
              v-if="veeErrors.has('firstName')"
              :text="veeErrors.first('firstName')"
              type="error"
              :style="{ marginTop: '8px' }"
            />
          </div>
          <div
            :style="{
              width: 'calc(50% - 7px)'
            }"
          >
            <ar-input
              v-model="registerData.lastName"
              v-validate="'required'"
              data-vv-name="lastName"
              autocomplete="family-name"
              data-vv-as="last name"
              placeholder="Last name"
              :has-error="veeErrors.has('lastName')"
            />
            <ar-state-message
              v-if="veeErrors.has('lastName')"
              :text="veeErrors.first('lastName')"
              type="error"
              :style="{ marginTop: '8px' }"
            />
          </div>
        </div>
      </div>

      <div
        :style="{
          marginBottom: '40px',
        }"
      >
        <ar-input
          v-model="registerData.password"
          v-validate="'required|arPasswordLength|max:256'"
          data-vv-name="password"
          data-vv-as="password"
          placeholder="Create a password"
          autocomplete="new-password"
          :has-error="veeErrors.has('password')"
          text-secure
          type="password"
        />
        <ar-state-message
          v-if="veeErrors.has('password')"
          :text="veeErrors.first('password')"
          type="error"
          :style="{ marginTop: '8px' }"
        />
      </div>
      <ar-simple-button
        type="gradient-purple"
        shape="pill"
        text="Create account"
        :loading="isFinishingInvite"
        :style="{
          width: '100%',
          marginBottom: '20px',
        }"
        @click="submitForm"
      />
      <ar-text
        class="comment"
        size="xxxs"
        align="center"
        :text="commentCopy"
        allow-html
        multiple-lines
      />

    </div>
  </AuthenticateFrame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import AuthenticateFrame from '../authenticate-frame';

export default {
  name: 'invite',
  
  layout: 'split',
  components: {
    AuthenticateFrame,
  },
  computed: {
    ...mapState({
      isFinishingInvite: state => state.auth.isFinishingInvite,
    }),
  },
  data() {
    return {
      registerData: {
        firstName: null,
        lastName: null,
        password: null,
      },

      hashData: {
        hash: null,
        promoterOid: null,
        email: null,
      },

      commentCopy: 'By continuing you agree to our <a href="https://audiencerepublic.com/privacy/" target="_blank" style="font-size: 12px">Privacy Policy</a> and <a href="https://audiencerepublic.com/terms-fans/" target="_blank" style="font-size: 12px">Terms of Service</a>',

      isInviteValid: null,
      isLoading: true,
    }
  },
  async created() {
    this.hashData = {
      hash: this.$route.query.code,
      id: this.$route.query.id,
      promoterOid: this.$route.query.company,
    }

    this.hashData.email = await this['auth/VALIDATE_INVITE'](this.hashData)
    this.isInviteValid = !!this.hashData.email

    if (!this.isInviteValid) {
      this.$router.push({
        path: '/authenticate/invite-expired',
      });
    } else {
      this.isLoading = false;
    }    
  },
  mounted() {
    // Update layout options
    this['layout/SWITCH_SPLIT_BACKGROUND_IMAGE'](1);
  },
  methods: {
    ...mapActions([
      'auth/FINISH_INVITE',
      'auth/VALIDATE_INVITE',
    ]),
    ...mapMutations([
      'layout/SWITCH_SPLIT_BACKGROUND_IMAGE',
    ]),
    async submitForm() {
      // Check first if the form is valid
      const isValid = await this.$validator.validateAll()

      if (isValid) {
        this['auth/FINISH_INVITE']({
          ...this.hashData,
          ...this.registerData,
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
