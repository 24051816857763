<template>
  <portal
    to="modal"
    :disabled="!shouldShow"
    :style="{ position: 'absolute' }"
  >

    <ar-modal
      :is-open="shouldShow"
      :header="!!integration ? 'Connect to ' + integration.name : 'Connect'"
      :has-cross-icon="!isWaiting"
      class="mailchimp-modal"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >

      <div
        slot="body"
        :class="[
          'mailchimp-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <ar-field
          label="API Key"
        >
          <ar-input
            v-model="apiKey"
            name="apiKey"
            type="password"
            placeholder="Please enter your Mailchimp API key"
            :disabled="isWaiting"
          />
        </ar-field>
      </div>

      <div
        slot="footer"
        :class="[
          isWaiting ? 'mailchimp-waiting-modal-footer' : 'mailchimp-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">

        <am2-loading-bubble v-if="isWaiting"/>
        <ar-simple-button
          v-else
          text="Confirm"
          :loading="isWaiting"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>

    </ar-modal>

  </portal>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'MailchimpModal',
  components: {},
  props: {
    shouldShow: {
      type: Boolean,
      default: false,
    },
    isWaiting: {
      type: Boolean,
      default: false,
    },
    integration: {
      type: Object,
      default: null
    },
    providerAccount: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      apiKey: null
    };
  },

  computed: {
    confirmButtonDisabled() {
      return !this.apiKey;
    },
  },

  watch: {
    async shouldShow(val) {
      if (val) {
        this.apiKey = null;
      }
    },
  },

  methods: {
    ...mapActions([
      'workatoIntegration/CREATE_PROVIDER_ACCOUNT'
    ]),

    async handleConfirmClick() {
      await this['workatoIntegration/CREATE_PROVIDER_ACCOUNT']({
        integration: this.integration,
        account: {
          accountName: 'Default Account',
          integration: {
            account: {
              apiKey: this.apiKey
            }
          }
        }
      });
      this.$emit('complete');
    },

    handleCloseClick() {
      this.$emit('cancel');
    },
  }
};
</script>

<style lang="scss" scoped>
.mailchimp-modal {
  .mailchimp-modal-body {
    padding: 20px 30px 30px;
  }

  .mailchimp-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}

.mailchimp-waiting-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 30px;
  height: 100px;
}

</style>
