<template>
  <ar-modal
    :is-open="true"
    header="Enable MFA"
    width="600px"
    @close="$emit('close')"
  >
    <div slot="body">
      <div class="mfa-create-modal">
        <MfaSection @success="handleSuccessCreateMfa" />
      </div>
    </div>
  </ar-modal>
</template>

<script>
import MfaSection from '~/components/sections/mfa-section/MfaSection.vue'
import {mapMutations} from 'vuex'

export default {
  components: {MfaSection},
  methods: {
    ...mapMutations(['auth/SET_MFA_ENABLED']),
    handleSuccessCreateMfa() {
      this['auth/SET_MFA_ENABLED'](true)
      this.$emit('close')
      this.$arNotification.push({type: 'success', message: 'MFA enabled'})
    }
  }
}
</script>

<style lang="scss">
.mfa-create-modal {
  padding: 20px;
}
</style>
