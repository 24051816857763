<template>
  <div
    :class="[
      'automation-sidebar-wrapper',
      isFullScreen && 'hidden'
    ]"
  >
    <SidebarEditable
      v-if="editableItem && (isEditEnabled || !promoterIsInAutomationWhitelist)"
      :item="editableItem"
    />
    <SidebarElements v-else
    />
  </div>
</template>
<script>
import SidebarEditable from './SidebarEditable'
import SidebarElements from './SidebarElements'
import { mapState } from 'vuex'
export default {
  name: 'AutomationSidebar',
  components: {
    SidebarEditable,
    SidebarElements,
  },
  computed: {
    ...mapState({      
      editableItem: state => state.automation.editableItem,
      isFullScreen: state => state.automation.isFullScreen,
      isEditEnabled: state => state.automation.isEditEnabled,
      promoter: state => state.auth.account,
    }),
    promoterIsInAutomationWhitelist() {
      if (this.promoter?.emailAddress?.endsWith('@arep.co')) return true;
      if (this.promoter?.emailAddress?.endsWith('@audiencerepublic.com')) return true;
      return false;
    },
  },
}
</script>
<style lang="scss" scoped>
.automation-sidebar-wrapper {
  width: 360px;
  background: white;
  box-shadow: 2px 0 6px 0 #e4e5e7;
  height: 100vh;
  position: fixed;
  right: 0;
  z-index: 20;

  &.hidden {
    display: none;
  }
}
</style>