<template>
  <portal to="modal" :disabled="!opened" :style="{ position: 'absolute' }">
    <ar-modal
      :is-open="opened"
      width="550px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      hide-header
      hide-footer
      @close="handleModalClose"
      class="ab-modal-off-confirm"
    >
      <div slot="body" class="ab-modal-off-confirm__body">
        <div class="ab-modal-off-confirm__content">
          <div class="ab-modal-off-confirm__exclamation-mark">
            <ar-icon
              name="alert-exclamation-mark"
              height="60px"
            />
          </div>
          <ar-text size="md" weight="bold" :text="`Are you sure you want to discard ${abType} B?`" />
          <ar-text
            multiple-lines
            size="xs"
            class="u-margin-top-2"
            :text="`Turning off your A/B test will delete any content in ${abType} B.`"
          />
        </div>
        <div class="ab-modal-off-confirm__footer">
          <ar-simple-button :text="`Yes, discard ${abType} B`" @click="handleConfirmOffAb" />
          <ar-link-button
            text="Cancel"
            @click="handleModalClose"
            :style="{
            height: '40px',
            padding: '0 20px'
          }"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'AbEmailModal',
  components: {

  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    abType: {
      type: String,
      default: 'subject'
    }
  },
  data() {
    return {
    };
  },

  methods: {
    handleModalClose() {
      this.$emit('close');
    },
    handleConfirmOffAb() {
      this.$emit('confirm');
    },
  }
};
</script>

<style lang="scss" scoped>
.ab-modal-off-confirm {
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 48px 34px;
  }
  .ab-designs {
    display: flex;
    gap: 32px;
    margin-top: 24px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  &__exclamation-mark {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}
</style>
