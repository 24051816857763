var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'wrapper',
    'content',
    'content--centered',
    _vm.$arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
    _vm.$arMediaQuery.pageContent.maxWidth('xs') && 'xs-max' ]},[_c('am2-step-link',{staticClass:"step-back-link",attrs:{"text":"Back to integrations"},on:{"click":_vm.handleBackLinkClick}}),_vm._v(" "),_c('div',{class:[
      'title-section',
      _vm.$arMediaQuery.pageContent.maxWidth('md') && 'md-max' ]},[_c('div',{staticClass:"title-section-left"},[(!!_vm.integration)?_c('am2-icon-title-section',{attrs:{"title":_vm.integration.name,"description":_vm.integration.description,"icon-props":{
          name: _vm.integration.provider,
          color: _vm.iconColor,
          height: '46px',
          width: '40px',
        }}}):_vm._e()],1),_vm._v(" "),_c(_vm.providerModal,{tag:"component",attrs:{"should-show":_vm.showModal,"is-waiting":_vm.isWaiting,"integration":_vm.integration},on:{"complete":_vm.handleComplete,"cancel":_vm.handleCancel}}),_vm._v(" "),(!_vm.isWaiting)?_c('div',{staticClass:"title-section-right"},[(!!_vm.providerAccounts && _vm.providerAccounts.length > 0 && !!_vm.integration && _vm.integration.provider !== 'see-tickets-uk')?_c('am2-integration-account-select',{style:({ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }),attrs:{"default-selected-integration-oid":_vm.providerAccounts[_vm.currentlySelectedIntegrationIndex].oid,"available-integrations":_vm.integrationsSummary},on:{"select":_vm.handleAccountChange,"new-account":_vm.handleNewAccount}}):_vm._e(),_vm._v(" "),_c('am2-expand-button-dropdown',{attrs:{"align":"left","button-props":{ iconName: 'settings' },"items":[
          {
            name: 'Remove Account',
            value: 'removeAccount',
          },
          {
            name: 'Reset Account',
            value: 'resetConnection',
          }
        ]},on:{"select":_vm.handleSettingsSelect}})],1):_vm._e()],1),_vm._v(" "),(_vm.isWaiting)?_c('section',{staticClass:"integration-loading-container"},[_c('am2-loading-bubble')],1):_c('section',[_c('SyncBlockContainer',{staticClass:"sync-block-container",attrs:{"is-fetching-sync-task":_vm.isWaiting,"sync-status":_vm.syncStatus,"sync-time":_vm.syncTime,"sync-copies":_vm.syncCopies,"copy-size":'sm'}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }