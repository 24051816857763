var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fan-table-wrapper"},[_c('am2-table-html',{ref:"table",staticClass:"fan-table",attrs:{"heads":_vm.decoratedHead,"rows":_vm.data,"tick-all-rows":_vm.audienceSelection.useScratchSegment,"ticked-rows-map":_vm.audienceSelection.partlySelectedAudienceMap,"locked":_vm.locked,"loading":_vm.loading,"disable-sorting":_vm.disableSorting,"default-sort-by":_vm.defaultSortBy,"enable-row-tick":_vm.enableRowTick,"enable-row-click":_vm.enableRowClick,"window-width":_vm.windowWidth,"has-sticky-header":_vm.hasStickyHeader,"has-control-section":_vm.hasControlSection,"rowKeyName":_vm.rowKeyName},on:{"sortBy":_vm.handleSortByChange,"rowTick":_vm.handleRowTick,"tickAllRows":_vm.handleTickAllRows,"rowClick":_vm.handleRowClick,"cellMouseEnter":_vm.handleCellMouseEnter,"cellMouseLeave":_vm.handleCellMouseLeave,"columnResize":_vm.handleColumnResize,"tableMouseLeave":_vm.handleTableMouseLeave},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var ref_data = ref.data;
var avatar = ref_data.avatar;
var name = ref_data.name;
var firstName = ref_data.firstName;
var lastName = ref_data.lastName;
var emailAddress = ref_data.emailAddress;
var oid = ref_data.oid;
var index = ref.index;
var active = ref.active;
return _c('div',{staticClass:"cell cell-left"},[(_vm.hasIndex)?_c('ar-text',{style:({
          marginRight: '10px',
        }),attrs:{"size":"xs","text":index + 1}}):_vm._e(),_vm._v(" "),_c('ar-avatar',{ref:("avatar-" + index),style:({
          marginRight: '10px',
          cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
        }),attrs:{"image-path":avatar || null,"name":(name || firstName) ? (name || (firstName + " " + (lastName || ''))) : emailAddress,"color":_vm.audienceSelection.partlySelectedAudienceMap[oid] || active ? 'white' : 'grey'},nativeOn:{"click":function($event){return _vm.handleUserClick(("/audience/" + oid + "/view/overview"))}}}),_vm._v(" "),_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.determineName(name, firstName, lastName, emailAddress)}, _vm.tooltipOptions)),expression:"{content: determineName(name, firstName, lastName, emailAddress), ...tooltipOptions}",modifiers:{"top":true}}],staticClass:"name-text",style:({
          cursor: _vm.customerProfilesEnabled ? 'pointer' : null,
        }),attrs:{"size":"xs","allow-html":"","text":_vm.determineName(name, firstName, lastName, emailAddress),"multiple-lines":"","max-lines":1},nativeOn:{"click":function($event){return _vm.handleUserClick(("/audience/" + oid + "/view/overview"))}}})],1)}},{key:"registrationTime",fn:function(ref){
        var registrationTime = ref.data.registrationTime;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.calculateTimeElapsed(registrationTime)}, _vm.tooltipOptions)),expression:"{content: calculateTimeElapsed(registrationTime), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.calculateTimeElapsed(registrationTime)}})],1)}},{key:"dob",fn:function(ref){
        var dob = ref.data.dob;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.transformTimestringToDate(dob)}, _vm.tooltipOptions)),expression:"{content: transformTimestringToDate(dob), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.transformTimestringToDate(dob)}})],1)}},{key:"status",fn:function(ref){
        var status = ref.data.status;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.capitalizeFirstLetter(status)}, _vm.tooltipOptions)),expression:"{content: capitalizeFirstLetter(status), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.capitalizeFirstLetter(status)}})],1)}},{key:"lastActiveTime",fn:function(ref){
        var lastActiveTime = ref.data.lastActiveTime;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
          content: _vm.lastActiveTimeTooltip(lastActiveTime),
        }),expression:"{\n          content: lastActiveTimeTooltip(lastActiveTime),\n        }",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.formatDateModified(_vm.calculateTimeElapsed(lastActiveTime))}})],1)}},{key:"sysMtime",fn:function(ref){
        var sysMtime = ref.data.sysMtime;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.formatDateModified(_vm.calculateTimeElapsed(sysMtime))}, _vm.tooltipOptions)),expression:"{content: formatDateModified(calculateTimeElapsed(sysMtime)), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.formatDateModified(_vm.calculateTimeElapsed(sysMtime))}})],1)}},{key:"country",fn:function(ref){
        var country = ref.data.country;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.getCountryName(country)}, _vm.tooltipOptions)),expression:"{content: getCountryName(country), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.getCountryName(country)}})],1)}},{key:"points",fn:function(ref){
        var data = ref.data;
        var head = ref.head;
return _c('div',{staticClass:"cell",style:({
      justifyContent: 'flex-end',
    })},[_c('am2-tag',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data[head.key]}, _vm.tooltipOptions)),expression:"{content: data[head.key], ...tooltipOptions}",modifiers:{"top":true}}],style:({ width: '83px' }),attrs:{"text":data[head.key],"type":"green","shape":"round"}})],1)}},{key:"currency",fn:function(ref){
    var data = ref.data;
    var head = ref.head;
return _c('div',{staticClass:"cell",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.renderRow(data[head.key], 'currency')}, _vm.tooltipOptions)),expression:"{content: renderRow(data[head.key], 'currency'), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.renderRow(data[head.key], 'currency')}})],1)}},{key:"number",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.renderRow(data[head.key], 'number')}, _vm.tooltipOptions)),expression:"{content: renderRow(data[head.key], 'number'), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.renderRow(data[head.key], 'number')}})],1)}},{key:"text",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data[head.key]}, _vm.tooltipOptions)),expression:"{content: data[head.key], ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":data[head.key]}})],1)}},{key:"emailAddress",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell cell-left"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data[head.key]}, _vm.tooltipOptions)),expression:"{content: data[head.key], ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":data[head.key]}})],1)}},{key:"customFieldInteger",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell cell-left",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data.customFieldInteger ? data.customFieldInteger[head.key.split('cf_')[1]] : ''}, _vm.tooltipOptions)),expression:"{content: data.customFieldInteger ? data.customFieldInteger[head.key.split('cf_')[1]] : '', ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.customFieldInteger ? data.customFieldInteger[head.key.split('cf_')[1]] : ''}})],1)}},{key:"customFieldDatetime",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell cell-left",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data.customFieldDatetime ? _vm.transformTimestringToDate(data.customFieldDatetime[head.key.split('cf_')[1]]) : ''}, _vm.tooltipOptions)),expression:"{content: data.customFieldDatetime ? transformTimestringToDate(data.customFieldDatetime[head.key.split('cf_')[1]]) : '', ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.customFieldDatetime ? _vm.transformTimestringToDate(data.customFieldDatetime[head.key.split('cf_')[1]]) : ''}})],1)}},{key:"customFieldString",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell cell-left",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data.customFieldString ? data.customFieldString[head.key.split('cf_')[1]] : ''}, _vm.tooltipOptions)),expression:"{content: data.customFieldString ? data.customFieldString[head.key.split('cf_')[1]] : '', ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":data.customFieldString ? data.customFieldString[head.key.split('cf_')[1]] : ''}})],1)}},{key:"customFieldOptions",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell cell-left",style:({
        justifyContent: 'flex-end',
      })},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: _vm.renderRow(data.customFieldOptions ? data.customFieldOptions[head.key.split('cf_')[1]] : '', 'customFieldOptions')}, _vm.tooltipOptions)),expression:"{content: renderRow(data.customFieldOptions ? data.customFieldOptions[head.key.split('cf_')[1]] : '', 'customFieldOptions'), ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":_vm.renderRow(data.customFieldOptions ? data.customFieldOptions[head.key.split('cf_')[1]] : '', 'customFieldOptions', head.options)}})],1)}},{key:"messageVariant",fn:function(ref){
      var data = ref.data;
      var head = ref.head;
return _c('div',{staticClass:"cell"},[_c('ar-text',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(Object.assign({}, {content: data[head.key]}, _vm.tooltipOptions)),expression:"{content: data[head.key], ...tooltipOptions}",modifiers:{"top":true}}],attrs:{"size":"xs","text":data[head.key]}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }