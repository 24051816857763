import { Module } from "vuex";
import { RootState } from '@/store/modules/types';
import { clone } from '@/utils/helpers/';
import { Oneschema } from './types';
import { JWT } from '~/api/oneschema/types';
import { jwtActions } from './actions';


export const initialOneschemaState = (): Oneschema => ({
  oneschema: {
    jwt: null
  },
  isFetchingOneschemaJWT: false
});

const oneschemaModule: Module<Oneschema, RootState> = {
  namespaced: true,
  state: initialOneschemaState,
  actions: jwtActions,
  mutations: {
    SET_ONESCHEMA_JWT(state, jwt: JWT) {
      state.oneschema = clone(jwt);
    },
    RESET_API_TOKENS(state) {
      state.oneschema = initialOneschemaState().oneschema;
    },
    SET_IS_FETCHING_JWT(state, isFetching: boolean) {
      state.isFetchingOneschemaJWT = isFetching;
    }
  }
};

export default oneschemaModule;