<template>
  <section class="messaging-lists-list">
    <div class="top-section">
      <am2-heading
        type="h1"
        size="md"
        class="section-heading"
        title="Lists"
      />
      <ar-link-button
        v-if="lists.length > 0"
        text="Opt out all"
        :has-underline="false"
        :text-props="{
          weight: 'bold',
        }"
        @click="handleOptOutAllClick"
      />
    </div>
    <div v-if="isLoading || !lists">
      <div
        v-for="n in 3"
        class="item"
        :key="n">
        <am2-typography-skeleton
          class="text-skeleton"
          size="xs"
          :style="{
            margin: '10px 0',
            maxWidth: '250px',
          }"
        />
      </div>
    </div>
    <div
      v-if="!isLoading && lists && lists.length < 1"
    >
      <ar-text
        size="xs"
        text="No lists"
        :style="{
          color: $arStyle.color.skyBlueGrey700,
        }"
      />
    </div>
    <div v-else>
      <div
        v-for="(list, key) in lists"
        class="item"
        :key="key">
        <ar-link-button
          :text="list.name"
          color="black"
          :active="key === openIndex"
          has-arrow
          :has-underline="false"
          :style="{
            margin: '12px 0 8px',
            width: '100%',
          }"
          @click="openList(key)"
        />
        <transition name="expand">
          <div v-if="key === openIndex" class="u-padding-y-2">
            <div
              v-for="(subscription, subscriptionName) in list.subscriptions"
              class="u-margin-y-1"
              :key="subscriptionName + subscription">
              <ar-checkbox
                v-if="subscriptionName === 'email' && list.enabled.email"
                ref="subscriptionCheckboxEmail"
                v-model="list.subscriptions[subscriptionName]"
                label="Email"
                :label-typography-props="{
                  size: 'xs',
                }"
                @input="value => changeSubscription([subscriptionName], value, list.fanListOid, list.messageListOid, 'email')"
              />
              <ar-checkbox
                v-if="subscriptionName === 'sms' && list.enabled.sms"
                ref="subscriptionCheckboxSms"
                v-model="list.subscriptions[subscriptionName]"
                label="SMS"
                :label-typography-props="{
                  size: 'xs',
                }"
                @input="value => changeSubscription([subscriptionName], value, list.fanListOid, list.messageListOid, 'sms')"
              />
              <ar-checkbox
                v-if="subscriptionName === 'facebookMessenger' && list.enabled.facebookMessenger"
                ref="subscriptionCheckboxFacebook"
                v-model="list.subscriptions[subscriptionName]"
                label="Facebook Messenger"
                :label-typography-props="{
                  size: 'xs',
                }"
                @input="value => changeSubscription([subscriptionName], value, list.fanListOid, list.messageListOid, 'facebook')"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapActions } from 'vuex';

  // Shows a list of lists
  export default {
    name: 'MessagingListsList',
    props: {
      fanOid: {
        type: Number,
        default: null,
      }
    },
    data() {
      return {
        openIndex: null,
        lists: [],
        isLoading: true,
      }
    },
    watch: {
      fanOid() {
        this.fetchMessageLists();
      },
    },
    mounted() {
      this.fetchMessageLists();
    },
    methods: {
      ...mapActions([
        'FETCH_MESSAGE_LISTS_BY_FAN',
        'messageList/UPDATE_FAN_MESSAGE_LIST_SUBSCRIPTIONS',
        'messageList/UPDATE_FAN_MESSAGE_LIST_SUBSCRIPTION_HISTORY',
        'messageList/DELETE_FAN_MESSAGE_LIST_SUBSCRIPTION',
        'messageList/DELETE_FAN_MESSAGE_LIST_SUBSCRIPTION_HISTORY',
        'messageList/SET_GLOBAL_OPT_IN',
        'SHOW_CONFIRM'
      ]),
      openList(key) {
        this.openIndex = this.openIndex === key ? null : key;
      },
      async fetchMessageLists() {
        if (!this.fanOid) return;
        const { rows, count } = await this.FETCH_MESSAGE_LISTS_BY_FAN({
          top: 50,
          orderBy: this.orderBy,
          fanOid: this.fanOid,
        });
        this.isLoading = false;

        const lists = rows.map( item => {
          const list = {};
          list.name = item.messageList.name;
          list.messageListOid = item.messageListOid;
          list.fanListOid = item.oid;
          list.subscriptions = {
            email: item.email,
            sms: item.sms,
            facebookMessenger: item.facebookMessenger,
          },
          list.enabled = {
            email: item.messageList.email,
            sms: item.messageList.sms,
            facebookMessenger: item.messageList.facebookMessenger,
          }
          return list;
        });


        this.lists = lists;
      },
      async handleOptOutAllClick() {
        const consentGiven = await this.SHOW_CONFIRM({ messageHtml: 'You are about to opt this user out of all communications from you. <br /> Would you like to continue?' });
        if(consentGiven) {
          await this['messageList/SET_GLOBAL_OPT_IN']({
            fanOid: this.fanOid
          });
          this.$arNotification.push({
            type: 'success',
            message: 'Fan successfully opted out from all message lists'
          });
          this.fetchMessageLists();
        }
      },
      async changeSubscription(subscriptionNames, value, fanListOid, messageListOid, platform) {
        if(!fanListOid) return;
        if(!subscriptionNames) return;
        if(!messageListOid) return;
        if(typeof value !== 'boolean') return;
        const listsKey = this.lists.findIndex( item => item.messageListOid = messageListOid);

        let add = [];
        let remove = [];
        let consentGiven = false;
        if(value) {
          consentGiven = await this.SHOW_CONFIRM({ messageHtml: 'You are about to opt this user in to a new messaging channel. <br /> Please only opt in users who have consented to receive communications from you. <br /> Would you like to continue?' });
          if (consentGiven) {
            add = subscriptionNames;
          } else {
            subscriptionNames.forEach( subscriptionName => {
              if (!isNaN(listsKey)) {
                if (platform === 'email' && this.$refs['subscriptionCheckboxEmail'] && this.$refs['subscriptionCheckboxEmail'][0]) {
                  this.$refs['subscriptionCheckboxEmail'][0].toggle(false);
                } else if (platform === 'sms' && this.$refs['subscriptionCheckboxSms'] && this.$refs['subscriptionCheckboxSms'][0]) {
                  this.$refs['subscriptionCheckboxSms'][0].toggle(false);
                } else if (platform === 'facebook' && this.$refs['subscriptionCheckboxFacebook'] && this.$refs['subscriptionCheckboxFacebook'][0]) {
                  this.$refs['subscriptionCheckboxFacebook'][0].toggle(false);
                }
                this.lists[listsKey].subscriptions[subscriptionName] = false;
              }
            });
          }
        } else {
          remove = subscriptionNames;
        }

        if (value && consentGiven) {
          subscriptionNames.forEach( subName => {
            this.lists[listsKey].subscriptions[subName] = true;
          });
        } else if (!value || !consentGiven) {
          subscriptionNames.forEach( subName => {
            this.lists[listsKey].subscriptions[subName] = false;
          });
        }

        if (consentGiven) {
          await this['messageList/SET_GLOBAL_OPT_IN']({
            fanOid: this.fanOid,
            channels: {
              email: true,
              sms:   true
            }
          });
        }

        const allOff = Object.keys(this.lists[listsKey].subscriptions).every( subscription => this.lists[listsKey].subscriptions[subscription] === false);

        if (allOff) {
          await this['messageList/DELETE_FAN_MESSAGE_LIST_SUBSCRIPTION']({
            fanListOid,
          });
          await this['messageList/DELETE_FAN_MESSAGE_LIST_SUBSCRIPTION_HISTORY']({
            messageListOid,
            fanOid: this.fanOid
          });
          this.openIndex = null;
          this.fetchMessageLists();
        } else {
          await this['messageList/UPDATE_FAN_MESSAGE_LIST_SUBSCRIPTIONS']({
            fanListOid,
            add,
            remove,
          });
          await this['messageList/UPDATE_FAN_MESSAGE_LIST_SUBSCRIPTION_HISTORY']({
            messageListOid,
            fanOid: this.fanOid,
            add,
            remove
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .messaging-lists-list {
    padding: 32px;

    .no-lists {
      color: $skyBlueGrey700;
    }

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .section-heading {
        margin-bottom: 24px;
      }
    }

    .expand-enter-active, .expand-leave-active {
      max-height: 220px;
      opacity: 1;
      overflow: hidden;
    }
    .expand-enter, .expand-leave-to /* .expand-leave-active below version 2.1.8 */ {
      opacity: 0;
      max-height: 0;
    }

    .item {
      padding:8px 0;
      border-bottom:1px solid $skyBlueGrey400;
      &:last-child {
        border-bottom: none;
      }

      >>>.icon-text-section {
        width: calc(100% - 20px);
      }
    }

  }
</style>
