<template>
  <div class="auto-page-container">
    <am2-unlock-prompt
      data-test-id="automation-contact-sales-header"
      v-if="!isAutomationFeatureEnabled"
      :title="$arMediaQuery.pageContent.minWidth('sm') ? `Unlock Automation` : `Automation`"
      :subtitle="`Create automations & email sequences.`"
      :button-text="$arMediaQuery.pageContent.minWidth('sm') ? `Contact Sales` : `Contact`"
      @click="contactSales"
      :size="$arMediaQuery.pageContent.maxWidth('xs') ? 'small' : 'medium'"
      v-ar-sticky-top="{
        priority: 2,
      }"
    />
    <div class="automation-listing-page-wrapper">
      <AutomationHeadingSection
        v-model="selectedListType"
        :options="headingOptions"
        @handleSearch="handleSearch"
      />

      <template v-if="!loading && isAutomationFeatureEnabled">
        <am2-no-content-section
          v-if="!hasAutomations"
          class="automation-no-content-wrapper"
          icon-name="sync"
          header="Create an automation"
          body="Explore powerful automation tools to engage with your existing audiences"
          button-text="New automation"
          @buttonClick="handleAutomationCreate"
        />
        <div v-if="!!hasAutomations && !filteredAutomations.length" class="not-found-wrapper">
          <ar-text
            class="not-found-copy"
            size="16px"
            :text="`No ${selectedListType.key} automations found`"
            weight="normal"
            multiple-lines
            :max-lines="1"
            line-height="25px"
          />
        </div>
      </template>

      <am2-upgrade-plan-section
        v-if="!isAutomationFeatureEnabled && (!filteredAutomations || filteredAutomations.length == 0)"
        class="upgrade-plan-section"
        feature-type="automation"
        design="column"
        :style="{
          marginTop: '28px',
        }"
        @buttonClick="contactSales"
      />
      <div v-else class="automation-listing-items-wrapper">
        <template v-for="item in filteredAutomations">
          <AutomationCard
            :key="item.oid"
            :item="item"
            @filter="handleFilterUpdate"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import AutomationHeadingSection from './components/AutomationHeadingSection'
import { mapState, mapActions, mapGetters } from 'vuex'
import AutomationCard from './components/AutomationList/AutomationCard'
export default {
  components: {
    AutomationHeadingSection,
    AutomationCard
  },
  data() {
    return {
      selectedListType: { name: 'All automations', key: 'all' },
      headingOptions: [
        {
          name: 'All automations',
          key: 'all',
        },
        {
          name: 'Active automations',
          key: 'active',
        },
        {
          name: 'Paused automations',
          key: 'paused',
        },
        {
          name: 'Inactive automations',
          key: 'inactive',
        },
      ],
      poller: null,
      searchString: ''
    }
  },
  computed: {
    ...mapState({
      automations: state => state.automation.automationsList,
      loading: state => state.automation.loading,
    }),
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    hasAutomations() {
      return !!this.automations && !!this.automations.length
    },
    filteredAutomations() {
      if (!this.automations) return

      let statusFilter = this.automations

      switch (this.selectedListType.key) {
        case 'active':
          statusFilter = this.automations.filter(item => item.status.includes('start'))
          break;
        case 'paused':
          statusFilter = this.automations.filter(item => item.status.includes('paus'))
          break;
        case 'inactive':
          statusFilter = this.automations.filter(item => (item.status === 'stopped' || item.status === 'new' || item.status === 'error'))
          break;
        case 'all':
          break;
        default:
          console.log('Unrecognised list filter type recieved')
      }

      if (this.searchString === '') {
        return statusFilter
      } else {
        return statusFilter.filter(item => item.name.toLowerCase().includes(this.searchString.toLowerCase()))
      }
    },
    isAutomationFeatureEnabled() {
      return this.isFeatureEnabled(['automation']);
    },
  },
  methods: {
    ...mapActions({
      fetchAutomations: 'automation/FETCH_AUTOMATIONS',
      clearAutomationsList: 'automation/CLEAR_AUTOMATIONS_LIST',
      showConfirm: 'SHOW_CONFIRM',
    }),
    handleFilterUpdate({item, status}) {
      this.automations.find(option => option.oid === item.oid).status = status
    },
    async contactSales() {
      window.open('https://audiencerepublic.com/request-demo');
    },
    handleAutomationCreate() {
      this.$router.push('/automation/create')
    },
    handleSearch(text) {
      this.searchString = text
    }
  },
  beforeRouteLeave(_, __, next) {
    clearInterval(this.poller)
    this.clearAutomationsList()
    next()
  },
  fetch() {
    this.fetchAutomations()
  },
}
</script>
<style lang="scss" scoped>
.auto-page-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.upgrade-plan-section {
  width: 100%;
}
.automation-listing-page-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  max-width: 1260px;
  padding: 0 30px 50px;

  .not-found-wrapper {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .automation-listing-items-wrapper {
    width: 100%;
    margin-top: 25px;
  }

  .automation-no-content-wrapper {
    width: 100%;
  }
}
</style>