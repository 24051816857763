var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{style:({ position: 'absolute' }),attrs:{"to":"modal","disabled":!_vm.isShow}},[_c('ar-modal',{staticClass:"user-account-modal",attrs:{"is-open":_vm.isShow,"header":"Edit user","width":"590px","mobile":_vm.$arMediaQuery.window.maxWidth('xs')},on:{"close":_vm.handleCloseClick}},[(!!_vm.currentData)?_c('div',{class:[
        'user-account-modal-body',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"user-account-modal-body-row u-display-flex u-flex-flow-row"},[_c('ar-field',{attrs:{"label":"First name","error-messages":[
            _vm.veeErrors.first('first-name') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"first-name","data-vv-name":"first-name","data-vv-as":"first name","placeholder":"Please enter your first name"},model:{value:(_vm.currentData.firstName),callback:function ($$v) {_vm.$set(_vm.currentData, "firstName", $$v)},expression:"currentData.firstName"}})],1),_vm._v(" "),_c('ar-field',{attrs:{"label":"Last name","error-messages":[
            _vm.veeErrors.first('last-name') ]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"last-name","data-vv-name":"last-name","data-vv-as":"last name","placeholder":"Please enter your last name"},model:{value:(_vm.currentData.lastName),callback:function ($$v) {_vm.$set(_vm.currentData, "lastName", $$v)},expression:"currentData.lastName"}})],1)],1),_vm._v(" "),_c('ar-field',{staticClass:"user-account-modal-body-row",attrs:{"label":"Email address"}},[_c('ar-input',{attrs:{"name":"email-address","placeholder":"Please enter your email address","disabled":true},model:{value:(_vm.currentData.emailAddress),callback:function ($$v) {_vm.$set(_vm.currentData, "emailAddress", $$v)},expression:"currentData.emailAddress"}})],1),_vm._v(" "),_c('ar-field',{staticClass:"user-account-modal-body-row",attrs:{"label":"User type"}},[_c('am2-user-role-select',{staticClass:"u-margin-bottom-1",attrs:{"data-test-id":"user-role-select","data-vv-name":"user-role-select","data-vv-as":"user role","default-user-roles":_vm.defaultUserRoles,"promoter-user-roles":_vm.promoterUserRoles,"selectedUserRoles":{
            defaultUserRoleOid: _vm.currentData.defaultUserRoleOid,
            promoterUserRoleOid: _vm.currentData.promoterUserRoleOid,
          },"is-user-facing":"","disabled":_vm.isUserRoleSelectDisabled},on:{"select":_vm.handleUserRoleSelect,"handleFilterInput":_vm.handleUserRoleFilterInput}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{class:[
        'user-account-modal-footer',
        _vm.$arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3' ],attrs:{"slot":"footer"},slot:"footer"},[_c('ar-simple-button',{style:({ width: '108px' }),attrs:{"text":"Confirm","disabled":_vm.confirmButtonDisabled},on:{"click":_vm.handleConfirmClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }