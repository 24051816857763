<template>
  <div
    :class="[
      'message-strip-wrapper',
      alwaysOpen && 'no-wrapper',
      disabled && 'disabled'
    ]"
    :style="{
      background: backgroundColor,
      border: !alwaysOpen ? (isOpen ? `1px solid ${$arStyle.color.purple500}` : `1px solid ${$arStyle.color.skyBlueGrey500}`) : '',
      'box-shadow': !alwaysOpen ? (isOpen ? `0px 2px 2px rgba(0, 0, 0, 0.1)` : '') : ''
    }"
  >
    <div v-if="!alwaysOpen" class="message-strip-inner">
      <div :class="['icon-wrapper', status !== 'complete' ? 'new-icon-wrapper' : 'edit-icon-wrapper']">
        <EditPencilCircle v-if="status !== 'complete'" :fill="`${$arStyle.color.skyBlueGrey300}`" />
        <GreenTickCircle v-else />
      </div>
      <div class="message-content-wrapper">
        <div v-if="status === 'complete'" class="message-content-top complete">
          <ar-text class="content-title" multiple-lines text="To" weight="bold" size="16px" />
          <ar-link-button
            class="content-link"
            text="Edit"
            has-underline
            @click="handleEdit"
            :data-test-id="`message-to-${contentButtonText}-button`"
          />
        </div>
        <div v-else class="message-content-top new">
          <ar-text class="content-title" multiple-lines text="To" weight="bold" size="16px" />
          <ar-simple-button
            v-if="!isOpen"
            class="content-button"
            text="Add"
            outlined
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleAdd"
            :data-test-id="`message-to-${contentButtonText}-button`"
          />
        </div>
        <div class="message-content-middle">
          <div :class="['message-content-middle-container', status === 'complete' ? 'complete' : '']">
            <ar-text
              v-if="status !== 'complete'"
              class="left-subheading"
              multiple-lines
              :text="`Who are you sending this ${channel} to?`"
              weight="normal"
              size="14px"
            />
            <div v-else class="complete-details-wrapper">
              <div class="complete-left">
                <ar-text class="left-subheading" multiple-lines text="Send to" weight="normal" size="14px" />
                <ar-text class="left-copy" :text="selectedListName" weight="normal" size="16px" />
              </div>
              <div class="complete-right">
                <ar-text class="left-subheading" text="Subscribers" align="right" weight="normal" size="14px" />
                <ar-text class="left-copy" :text="finalRecipientCopy" align="right" weight="normal" size="16px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isOpen || alwaysOpen" class="inner-details-wrapper">
      <div class="inner-details-container">
        <ar-text class="subheading" multiple-lines text="Send to" weight="bold" size="14px" />
        <am2-elegant-tabs
          v-if="hasSenderTabs"
          class="sender-tabs"
          layout="wide"
          :items="senderTabs"
          :tab-key="senderTabSelected"
          @select="handleSenderTabSelect"
        />

        <div
          v-if="senderTabSelected === 'list'"
          class="selected-tab-wrapper"
        >
          <div class="input-search-wrapper">
            <div
              v-if="!showInput"
              :class="['fake-input-wrapper', showInvalidListWarning || ( showPlaceholder && isEmptyError) ? 'invalid-warning' : '']"
              @click="handleFakeInputClick"
            >
              <div class="fake-text-area-wapper">
                <ar-text
                  v-if="showPlaceholder"
                  class="placeholder"
                  text="Select a list..."
                  weight="normal"
                  size="14px"
                  :style="{
                    color: `${$arStyle.color.blueGrey600}`
                  }"
                />
                <template v-else>
                  <ar-text class="list-name" :text="selectedListName" weight="normal" size="14px" />
                  <ar-text
                    class="list-name"
                    :text="recipientCountText"
                    weight="normal"
                    size="14px"
                    :style="{
                      color: `${$arStyle.color.blueGrey700}`,
                      marginLeft: '4px'
                    }"
                  />
                </template>
              </div>
              <ArrowHead
                class="send-to-input-dropdown-arrowhead"
                :class="listOpen && 'rotate'"
                height="8px"
                width="10px"
                :fill="$arStyle.color.skyBlueGrey700"
              />
            </div>
            <div v-else :class="['input-arrowhead-wrapper', listOpen ? 'active' : '']">
              <div class="input-selection-container" v-click-outside="handleClickOutside">
                <div v-if="listOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
                <ar-input
                  ref="sendToInput"
                  class="message-input send-to-input"
                  :style="{
                    paddingLeft: listOpen ? '23px' : '0px'
                  }"
                  :value="listSearchText"
                  @input="handleListSearchInput"
                  :placeholder="sendToPlaceholder"
                  @focus="handleFocus"
                  autocomplete="off"
                />
                <div class="arrowhead-wrapper" @click="handleArrowClick">
                  <ArrowHead
                    class="send-to-input-dropdown-arrowhead"
                    :class="listOpen && 'rotate'"
                    height="8px"
                    width="10px"
                    :fill="$arStyle.color.skyBlueGrey700"
                  />
                </div>
              </div>
            </div>
            <div class="filter-button-wrapper">
              <ar-simple-button
                icon-name="filter-alt"
                side-length="40px"
                :icon-props="{
                  height: '10px'
                }"
                :style="{
                  padding: '0 18px'
                }"
                text="Filter"
                :type="hasFilterConditions ? 'purple' : 'grey'"
                outlined
                :disabled="filterButtonShouldBeDisabled"
                v-tooltip.top="{
                  content: filterButtonShouldBeDisabled ? filterButtonTooltip : null
                }"
                class="filter-btn u-margin-left-3"
                @click="handleFilterClick"
                data-test-id="message-to-filter-button"
              />
              <div v-if="hasFilterConditions && !!filterCount && filterCount > 0" class="filter-count-circle-wrapper">
                <span class="filter-recipient-count">{{ filterCount }}</span>
              </div>
            </div>
          </div>
          <ar-text
            v-if="showInvalidListWarning"
            class="no-valid-contacts-copy"
            text="There are no valid, opted-in contacts in this list"
            weight="normal"
            size="12px"
          />
          <ar-text
            v-if="showPlaceholder && isEmptyError"
            class="no-valid-contacts-copy"
            text="A list is required, please select one"
            weight="normal"
            size="12px"
          />
          <am2-message-list-advanced-targeting-section
            v-if="showAdvancedTargetingModule"
            :channel="channel"
            show-toggle
            @updateAdvancedTargeting="handleAdvancedTargetingUpdate"
          />
          <div
            v-if="!alwaysOpen && hasFilterConditions && recipientsCopy"
            :class="['filtered-recipient-wrapper', filteredRecipientListCount === 0 ? 'no-recipients' : '']"
          >
            <div class="filtered-recipient-container">
              <div class="recipients-copy-inner">
                <am2-loading-spinner
                  v-if="isFetchingFilteredRecipientListCount"
                  :style="{
                    width: '16px',
                    height: '16px',
                    marginRight: '16px',
                  }"
                />
                <PeopleIcon
                  v-else
                  class="people-icon"
                  :color="
                    filteredRecipientListCount > 0 ? `${$arStyle.color.blueGrey700}` : `${$arStyle.color.orange500}`
                  "
                />
                <ar-text
                  class="recipients-copy"
                  :text="recipientsCopy"
                  :weight="filteredRecipientListCount === 0 ? 'bold' : 'normal'"
                  size="14px"
                />
              </div>
              <ar-link-button
                :class="['filter-reset-link']"
                text="Remove all filters"
                has-underline
                @click="handleFiltersReset"
                :data-test-id="`message-to-filter-reset-link-button`"
              />
            </div>
          </div>
          <div
            v-if="associatedEventCopy"
            class="filtered-recipient-wrapper"
          >
            <div class="filtered-recipient-container">
              <div class="recipients-copy-inner">
                <ar-icon
                  name="calendar"
                  width="16px"
                  height="16px"
                  class="u-margin-right-3"
                  :color="$arStyle.color.blueGrey700"
                />
                <ar-text
                  class="recipients-copy"
                  multiple-lines
                  :text="associatedEventCopy"
                  :weight="filteredRecipientListCount === 0 ? 'bold' : 'normal'"
                  size="14px"
                />
              </div>
              <ar-link-button
                v-if="!eventOidQueryParam && isSuggestedAudienceFeatureEnabled"
                :class="['filter-reset-link']"
                text="Remove"
                has-underline
                @click="resetEvent"
                :data-test-id="`message-to-filter-reset-link-button`"
              />
            </div>
          </div>
          <div v-if="listOpen" :class="['list-items-wrapper', isSuggestedAudienceFeatureEnabled && !campaignOid && 'suggested-audiences-enabled' ]">
            <div v-for="list in filteredMessageList" :key="list.oid" class="list-item" @click="handleListSelect(list)">
              <ar-text
                class="list-name"
                multiple-lines
                :text="list.meta && list.meta.name ? list.meta.name : list.name"
                :weight="selectedListName === list.name ? 'bold' : 'normal'"
                size="14px"
              />
              <ar-text
                class="list-name"
                :text="calculateRecipientText(list)"
                weight="normal"
                size="14px"
                :style="{
                  color: `${$arStyle.color.blueGrey700}`,
                  marginLeft: '4px'
                }"
              />
            </div>
            <div v-if="noMatchingLists" class="list-item no-match">
              <ar-text
                class="list-name"
                :style="{ marginRight: '4px' }"
                size="xs"
                text="No matching list found"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                weight="normal"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="selected-tab-wrapper"
        >
          <div class="input-search-wrapper event-input-container">
            <div :class="['input-arrowhead-wrapper', suggestedAudienceOpen ? 'active' : '']">
              <div
                class="input-selection-container event-container"
                v-click-outside="handleClickOutsideEventsListContainer"
              >
                <div v-if="eventsListOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
                <ar-input
                  :id="linkInputId"
                  ref="recipientLinkListInput"
                  class="message-input send-to-input"
                  :style="{
                    paddingLeft: eventsListOpen ? '23px' : '0px'
                  }"
                  :disabled="!!eventOidQueryParam"
                  :placeholder="linkToPlaceholder"
                  autocomplete="off"
                  @focus="handleLinkFocus"
                  @input="handleEventSearchInput"
                  :value="eventInputValue"
                />
                <div class="link-arrowhead-wrapper" @click="handleLinkArrowClick">
                  <ArrowHead
                    class="send-to-input-dropdown-arrowhead"
                    :class="eventsListOpen && 'rotate'"
                    height="8px"
                    width="10px"
                    :fill="$arStyle.color.skyBlueGrey700"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="eventsListOpen"
            :class="[
              'list-items-wrapper event-items-wrapper',
              filteredEventsList.length > 4 ? 'overflow' : '']"
          >
            <div
              v-for="event in filteredEventsList"
              :id="`listItem-${event.oid}`"
              :key="event.oid"
              :class="[
                'link-list-item',
                'list-item',
                suggestedAudienceEventOid === event.oid && 'selected',
              ]"
              @click="() => { handleEventSelect(event) }"
            >
              <div class="dropdown-copy-wrapper">
                <ar-text
                  class="list-name"
                  multiple-lines
                  :text="event.name"
                  weight="bold"
                  size="14px"
                  :style="{ color: suggestedAudienceEventOid === event.oid ? $arStyle.color.purple500 : null }"
                />
                <div class="event-date-location-wrapper">
                  <ar-text class="list-date" multiple-lines :text="event['date-string']" weight="normal" size="14px" />
                  <ar-text
                    v-if="event.location"
                    class="list-date"
                    multiple-lines
                    text="•"
                    weight="normal"
                    size="14px"
                  />
                  <ar-text class="list-location" multiple-lines :text="event.location" weight="normal" size="14px" />
                </div>
              </div>
            </div>
            <div v-if="noMatchingLinkLists" class="link-list-item list no-match">
              <ar-text
                class="list-name"
                :style="{ margin: '0 16px' }"
                size="xs"
                text="No matching events found"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                weight="normal"
              />
            </div>
          </div>
          <div v-if="!!suggestedAudienceEventOid">
            <ar-text class="subheading" multiple-lines text="Suggested audience" weight="bold" size="14px" :style="{ marginTop: '32px' }" />
          </div>
          <div
            v-if="!!suggestedAudienceEventOid"
            class="input-search-wrapper suggested-audience-input-container"
          >
            <div :class="['input-arrowhead-wrapper', eventsListOpen ? 'active' : '']">
              <div
                class="input-selection-container event-container"
                v-click-outside="handleClickOutsideSuggestedAudienceContainer"
              >
                <div v-if="suggestedAudienceOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
                <ar-input
                  :id="suggestedAudienceInputId"
                  ref="suggestedAudienceInput"
                  class="message-input send-to-input"
                  :style="{
                    paddingLeft: suggestedAudienceOpen ? '23px' : '0px'
                  }"
                  :placeholder="suggestedAudiencePlaceholder"
                  autocomplete="off"
                  @focus="handleSuggestedAudienceFocus"
                  @input="handleSuggestedAudienceSearchInput"
                  :value="suggestedAudienceInputValue"
                />
                <div class="link-arrowhead-wrapper" @click="handleSuggestedAudienceArrowClick">
                  <ArrowHead
                    class="send-to-input-dropdown-arrowhead"
                    :class="suggestedAudienceOpen && 'rotate'"
                    height="8px"
                    width="10px"
                    :fill="$arStyle.color.skyBlueGrey700"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="suggestedAudienceOpen"
            :class="['list-items-wrapper suggested-audience-items-wrapper', suggestedAudienceList.length > 4 ? 'overflow' : '']"
          >
            <div
              v-for="(suggestedAudienceItem, index) in suggestedAudienceList"
              :id="`suggested-audience-${index}`"
              :key="`suggested-audience-${index}`"
              class="link-list-item list-item"
              :data-test-id="`suggested-audience-item-${suggestedAudienceItem.key}`"
              v-tooltip.top="{
                content: suggestedAudiencesDisabled.indexOf(suggestedAudienceItem.oid) > -1 ?
                `This suggested audience is not configured yet` : null
              }"
              @click="() => { handleSuggestedAudienceSelect(suggestedAudienceItem, index) }"
            >
              <ar-checkbox
                :ref="`suggested-audience-checkbox-${index}`"
                v-model="suggestedAudienceLocal[suggestedAudienceItem.key]"
                class="dropdown-item-checkbox"
                :disabled="suggestedAudiencesDisabled.indexOf(suggestedAudienceItem.oid) > -1"
                :data-test-id="`suggested-audience-checkbox-${index}`"
              />
              <div class="dropdown-copy-wrapper suggested-audience-copy-wrapper">
                <ar-text class="list-name" multiple-lines :text="suggestedAudienceItem.name" size="14px" />
                <ar-text
                  class="list-contacts"
                  multiple-lines
                  :text="channel === 'email' ? suggestedAudienceItem.contactsEmail : suggestedAudienceItem.contactsSms"
                  size="14px"
                  :style="{ color: $arStyle.color.skyBlueGrey700 }" />
              </div>
            </div>
            <div v-if="!noMatchingLinkLists" class="apply-deselect-wrapper">
              <div
                class="apply-button-wrapper"
                @click="handleApplySuggestedAudienceSelections"
                data-test-id="`apply-events-button`"
              >
                <ar-simple-button
                  class="apply-button"
                  text="Apply"
                  weight="bold"
                  :style="{
                    padding: '10px 16px 11px',
                    height: 'unset'
                  }"
                />
              </div>
            </div>
            <div v-if="noMatchingLinkLists" class="link-list-item list no-match">
              <ar-text
                class="list-name"
                :style="{ marginRight: '4px' }"
                size="xs"
                text="No matching events found"
                multiple-lines
                :max-lines="1"
                line-height="20px"
                weight="normal"
              />
            </div>
          </div>
        </div>
        <div v-if="!alwaysOpen" class="action-buttons-wrapper">
          <ar-link-button
            class="cancel-link"
            text="Cancel"
            has-underline
            @click="handleCancel"
            data-test-id="`message-to-cancel-link`"
          />
          <ar-simple-button
            class="save-button"
            text="Save"
            weight="bold"
            :style="{
              padding: '10px 16px 11px',
              height: 'unset'
            }"
            @click="handleSave"
            data-test-id="`message-to-save-button`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GreenTickCircle from '~/assets/icons/green-tick-circle.svg?inline';
import EditPencilCircle from '~/assets/icons/edit-pencil-circle.svg?inline';
import ArrowHead from '~/assets/icons/arrow-head.svg?inline';
import SearchIcon from '~/assets/icons/search.svg?inline';
import PeopleIcon from '~/assets/icons/people-icon.svg?inline';
import accounting from 'accounting';
import { displayDateRangeUSNoDay } from '@/utils/date/';
import dayjs from 'dayjs';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { clone } from '@/utils/helpers/';
export default {
  name: 'MessageDetailsToBlock',
  components: {
    EditPencilCircle,
    GreenTickCircle,
    ArrowHead,
    SearchIcon,
    PeopleIcon
  },
  props: {
    isFieldExpanded: Boolean,
    showAdvancedTargetingModule: Boolean,
    channel: {
      type: String,
      validator: (val) => ['sms', 'email'].indexOf(val) > -1,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
      listOpen: false,
      listSearchText: '',
      filteredMessageList: null,
      selectedList: null,
      recipientCount: 0,
      suggestedAudienceRecipientCount: 0,
      ignoreSuppressions: false,
      filterCriteria: null,
      showInput: false,
      disabled: false,
      isEmptyError: false,

      // Store instances of resources locally within the component
      // Easier to manipulate, etc
      localMessageLists: [],
      localEvents: [],
      localEventAudienceStats: [],

      senderTabs: [
        {
          name: 'List',
          key: 'list',
        },
        {
          name: 'Suggested audience',
          key: 'suggested-audience',
        },
      ],
      senderTabSelected: 'list',
      suggestedAudienceOpen: false,
      eventsListOpen: false,
      arEnableSuggestedAudiences: process.env.arEnableSuggestedAudiences,
      eventsListObject: null,
      filteredEventsList: [],
      eventsListSearchText: '',
      selectedEventOid: null,

      sendFromEventOid: null,
      suggestedAudienceEventOid: null,

      linkInputId: 'linkInput',

      // Suggested Audiences
      suggestedAudienceInputId: 'suggestedAudienceInputId',
      suggestedAudienceSearchText: '',
      suggestedAudienceLocal: {},
      suggestedAudiencesDisabled: [], // List of disabled suggested audiences
      filteredSuggestedAudience: [],

      preEditSettings: {},

      oldSelectedList: null,
      oldFilter: null,
    };
  },
  watch: {
    isOpen(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.preEditSettings = {
          senderTabSelected: this.senderTabSelected,
          messageList: clone(this.selectedList),
          audienceOids: this.scratchMessage?.meta?.suggestedAudience?.audienceOids,
          recipientFilter: this.scratchMessage?.meta?.recipientFilter,
          selectedEventOid: this.selectedEventOid,
          sendFromEventOid: this.sendFromEventOid,
          suggestedAudienceEventOid: this.suggestedAudienceEventOid,
          filteredSuggestedAudience: clone(this.filteredSuggestedAudience)
        }
      } else if (!newVal && oldVal) {
        this.preEditSettings = {}
      }
    },
    prunedScratchSegment() {
      if (this.channel === 'sms') return;
      this.fetchFilteredRecipientListCount({ channel: this.channel });
      this.handleAdvancedTargetingUpdate();
    },
    async selectedList() {
      if (!this.selectedList) return;
      this.listSearchText =
        this.selectedList.meta && this.selectedList.meta.name ? this.selectedList.meta.name : this.selectedList.name;

      const total = this.selectedList.statsSnapshot.total
      const ignoreOptIns = this.selectedList.meta?.ignoreMessageListOptIn
      let channelOptins = 0
      if (this.channel === 'email') {
        channelOptins = this.selectedList.statsSnapshot?.email
      } else {
        channelOptins = this.selectedList.statsSnapshot?.sms?.optedIn
      }
      this.recipientCount = ignoreOptIns ?
        total :
        channelOptins;

      await this.$nextTick(() => {
        this.fetchFilteredRecipientListCount({ channel: this.channel });
      });
    },
    localMessageLists: {
      immediate: true,
      handler(newVal, oldVal) {
        this.filteredMessageList = this.messageListsMinusHiddenOnes;
        this.$nextTick(() => {
          if (!!this.suggestedAudienceEventOid &&
            !this.currentMessageList &&
            !!this.baselineSuggestedAudienceList &&
            this.isSuggestedAudienceSelected) {
            this.selectedList = this.baselineSuggestedAudienceList;
          }
        })
      }
    },
    currentMessageList: {
      immediate: true,
      async handler(newVal, oldVal) {
        this.fetchFilteredRecipientListCount({ channel: this.channel });
        await this.$nextTick(() => {
          if (this.currentMessageList) {
            this.selectedList = this.currentMessageList;

            const total = this.selectedList.statsSnapshot.total
            const ignoreOptIns = this.selectedList.meta?.ignoreMessageListOptIn
            let channelOptins = 0
            if (this.channel === 'email') {
              channelOptins = this.selectedList.statsSnapshot?.email
            } else {
              channelOptins = this.selectedList.statsSnapshot?.sms?.optedIn
            }

            this.recipientCount = ignoreOptIns ?
              total :
              channelOptins;
          }
        });
      }
    },
    isFieldExpanded: {
      handler() {
        this.disabled = this.alwaysOpen ? false : (this.isFieldExpanded == false || this.isOpen ? false : true);
      },
      immediate: true
    },
    scratchMessage: {
      handler(newVal, oldVal) {
        // If selectedEvent is present in newVal, and is not present in oldVal (or oldVal is null)
        // set tab to suggested-audience
        const newValSuggestedAudienceEventOid = newVal?.meta?.suggestedAudience?.eventOid;
        const newValSSendFromEventOid = newVal?.meta?.sendFromEvent?.eventOid || this.eventOidQueryParam;

        if (!!newVal?.meta?.selectedEvent && newVal?.meta?.selectedEvent !== oldVal?.meta?.selectedEvent) {
          this.suggestedAudienceEventOid = newValSuggestedAudienceEventOid
          this.sendFromEventOid = newValSSendFromEventOid;
          if (this.hasSenderTabs && !!newValSuggestedAudienceEventOid) {
            this.senderTabSelected = 'suggested-audience'
            this.constructSuggestedAudienceLocal()
          }
          return
        }

        // If eventOid initiator is set in newVal, and is not present in oldVal (or oldVal is null)
        // set tab to suggested-audience
        if (!!newVal?.meta?.initiator?.eventOid &&
          newVal?.meta?.initiator?.eventOid !== oldVal?.meta?.initiator?.eventOid) {
          this.suggestedAudienceEventOid = newValSuggestedAudienceEventOid
          this.sendFromEventOid = newValSSendFromEventOid;
          if (this.hasSenderTabs && !!newValSuggestedAudienceEventOid) {
            this.senderTabSelected = 'suggested-audience'
            this.constructSuggestedAudienceLocal()
          }
          return
        }

        // If oldVal contains a value and newVal does not, then clear selectedEventOid and set tab to list
        if ((!!oldVal?.meta?.selectedEvent || !!oldVal?.meta?.initiator?.eventOid) &&
          !newVal?.meta?.initiator?.eventOid && !newVal?.meta?.selectedEvent) {
          this.suggestedAudienceEventOid = newValSuggestedAudienceEventOid
          this.sendFromEventOid = newValSSendFromEventOid;
          this.senderTabSelected = 'list'
          return
        }
      },
      immediate: true
    },
    // Clear out selected list, filters and settings when the tab changes
    senderTabSelected: {
      handler(newVal, oldVal) {
        if (newVal === 'list' && (oldVal === 'suggested-audience' || !oldVal)) {
          this.handleFiltersReset()
          this.reinstateFilters()
          if (oldVal === 'suggested-audience') {
            this.suggestedAudienceEventOid = null;
            this.suggestedAudienceLocal = {};
            this.suggestedAudienceRecipientCount = 0;
          }
          const tempSelectedList = clone(this.selectedList)
          this.handleListSelect(this.oldSelectedList)
          this.selectedList = this.oldSelectedList || null;
          this.oldSelectedList = tempSelectedList

          this.resetCurrentMessageList()
          this.oldFilter = null
        } else if (newVal === 'suggested-audience' && (oldVal === 'list' || !oldVal)) {
          this.oldFilter = this.prunedScratchSegment?.filter
          this.handleFiltersReset()
          if (!!this.eventOidQueryParam) {
            this.suggestedAudienceEventOid = this.eventOidQueryParam;
          }
          const tempSelectedList = clone(this.selectedList)
          this.handleListSelect(this.oldSelectedList)
          if (this.isSuggestedAudienceSelected) {
            this.selectedList = this.baselineSuggestedAudienceList;
          }
          this.oldSelectedList = tempSelectedList
          this.resetCurrentMessageList()
          this.constructSuggestedAudienceLocal()
        }
      },
    },
    sendFromEventOid: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        if (newVal === null && !!oldVal && !this.suggestedAudienceEventOid && !this.scratchInitiatorEventOid) {
          this.fetchLocalLists({})
          return
        } else if (!!newVal) {
          this.fetchLocalLists({ eventOid: this.sendFromEventOid || this.suggestedAudienceEventOid || this.scratchInitiatorEventOid })
        }

      },
      immediate: true,
    },
    // When the selected event changes, we need to re-fetch lists and suggested audiences
    suggestedAudienceEventOid: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        if (newVal === null && !!oldVal && !this.sendFromEventOid && !this.scratchInitiatorEventOid) {
          this.fetchLocalLists({})
          this.localEventAudienceStats = []
          return
        } else if (!!newVal) {
          this.fetchLocalLists({ eventOid: this.suggestedAudienceEventOid || this.sendFromEventOid || this.scratchInitiatorEventOid })
          this.fetchLocalEventAudienceStats(this.suggestedAudienceEventOid)
        }

      },
      immediate: true,
    },
    scratchCampaignOid: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        if (!newVal) return;
        this.fetchLocalLists({ campaignOid: newVal })
      },
      immediate: true,
    },
    // When localEventAudienceStats changes, rebuild the local map of suggested audiences
    localEventAudienceStats: {
      handler(newVal, oldVal) {
        if (!newVal && !oldVal) return;
        if ((!!newVal && !oldVal && newVal.length > 0) || (!!newVal && !!oldVal && newVal.length > 0 && oldVal.length === 0)) {
          this.constructSuggestedAudienceLocal();
          return
        }
        if (!!newVal && oldVal && newVal.length > 0 && oldVal.length > 0 && newVal[0].oid !== oldVal[0].oid) {
          this.constructSuggestedAudienceLocal();
        }
      },
      immediate: true,
    },
    suggestedAudienceOids: {
      handler(newVal,oldVal) {
        const newValSorted = (newVal || []).sort()
        const oldValSorted = (oldVal || []).sort()
        const theSame = JSON.stringify(newValSorted) === JSON.stringify(oldValSorted)
        if (!theSame) {
          this.updateRecipientFilterFromSuggestedAudience()
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.initialise();
  },
  computed: {
    ...mapState({
      messageOid: state => parseInt(state.route.params.oid, 10),
      scratchSegment: state => state.segment.scratchSegment,
      filteredRecipientListCount: state => state.messageList.filteredRecipientListCount,
      isFetchingFilteredRecipientListCount: state => state.messageList.isFetchingFilteredRecipientListCount,
      currentMessageList: state => state.messageList.currentMessageList,
      advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
      scratchEmailMessage: state => state.message.scratchEmailMessage,   // Email
      scratchSimpleMessage: state => state.message.scratchSimpleMessage, // SMS
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      isFetchingMessage: state => state.message.isFetchingMessage,
      promoterOid: state => state.auth.account.promoterOid
    }),
    ...mapGetters({
      prunedScratchSegment: 'segment/prunedScratchSegment',
      getCurrentFilterExpression: 'messageList/getCurrentFilterExpression',
      scratchEmailCampaignOid: 'message/scratchEmailMessageCampaignOid',
      scratchEmailEventOid: 'message/scratchEmailMessageEventOid',
      scratchEmailMessageSendFromEventOid: 'message/scratchEmailMessageSendFromEventOid',
      scratchEmailMessageSuggestedAudienceEventOid: 'message/scratchEmailMessageSuggestedAudienceEventOid',
      scratchSmsCampaignOid: 'message/scratchSimpleMessageCampaignOid',
      scratchSmsEventOid: 'message/scratchSimpleMessageEventOid',
      scratchSimpleMessageSendFromEventOid: 'message/scratchSimpleMessageSendFromEventOid',
      scratchSimpleMessageSuggestedAudienceEventOid: 'message/scratchSimpleMessageSuggestedAudienceEventOid',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    campaignOid() {
      return this.$route?.query?.campaignOid || this.initiatorCampaignOid
    },
    backgroundColor() {
      if (this.alwaysOpen) return '#fff';
      if (this.isOpen) return '#fff';
      if (this.status === 'complete') {
        return '#f6f9fc'
      }
      return '#fff'
    },
    scratchMessage() {
      if (this.channel === 'email') {
        return this.scratchEmailMessage;
      } else if (this.channel === 'sms') {
        return this.scratchSimpleMessage;
      }
      return null;
    },
    scratchEventOid() {
      if (this.channel === 'email') {
        return this.scratchEmailEventOid;
      } else if (this.channel === 'sms') {
        return this.scratchSmsEventOid;
      }
      return null;
    },
    scratchSuggestedAudienceEventOid() {
      if (this.channel === 'email') {
        return this.scratchEmailMessageSuggestedAudienceEventOid;
      } else if (this.channel === 'sms') {
        return this.scratchSimpleMessageSuggestedAudienceEventOid;
      }
      return null;
    },
    scratchSendFromEventOid() {
      if (this.channel === 'email') {
        return this.scratchEmailMessageSendFromEventOid;
      } else if (this.channel === 'sms') {
        return this.scratchSimpleMessageSendFromEventOid;
      }
      return null;
    },
    scratchInitiatorEventOid() {
      if (this.channel === 'email') {
        return this.scratchEmailMessage?.meta?.initiator?.eventOid;
      } else if (this.channel === 'sms') {
        return this.scratchMessage?.meta?.initiator?.eventOid;
      }
      return null;
    },

    scratchCampaignOid() {
      if (this.channel === 'email') {
        return this.scratchEmailCampaignOid;
      } else if (this.channel === 'sms') {
        return this.scratchSmsCampaignOid;
      }
      return null;
    },
    initiatorCampaignOid() {
      if (this.channel === 'email') {
        return this.scratchEmailMessage.meta.initiator.campaignOid;
      } else if (this.channel === 'sms') {
        return this.scratchMessage.meta.initiator.campaignOid;
      }
      return null
    },
    // We want to fetch the baseline suggested audience list, but we never want it to be visible from the UI
    messageListsMinusHiddenOnes() {
      const scratchOid = this.scratchSuggestedAudienceEventOid || this.scratchSendFromEventOid
      const queryOid = this.queryParamSuggestedAudienceOid || this.eventOidQueryParam
      const initiator = this.scratchInitiatorEventOid;
      if (!scratchOid && !queryOid && !initiator) return this.localMessageLists
      const listName = `_internal_suggested_audience_${scratchOid || queryOid || initiator}_baseline_`
      return this.localMessageLists.filter( item => item.name !== listName)
    },
    showInvalidListWarning() {
      return this.selectedList && this.recipientCount === 0;
    },
    finalRecipientCopy() {
      if (this.suggestedAudienceOids?.length > 0 && this.senderTabSelected === 'suggested-audience') {
        return `${accounting.formatNumber(this.suggestedAudienceRecipientCount)}`;
      }
      if (this.hasFilterConditions) {
        return `${accounting.formatNumber(this.filteredRecipientListCount)} (filtered)`;
      } else {
        return accounting.formatNumber(this.recipientCount);
      }
    },
    filterButtonShouldBeDisabled() {
      // currently can't apply filters to campaign message lists
      return !this.selectedList || this.recipientCount === 0 || !!this.selectedList.campaignOid;
    },
    filterButtonTooltip() {
      if (!!this.selectedList?.campaignOid) {
        return "Filtering disabled for campaign registrants"
      }
      return null;
    },
    recipientsCopy() {
      if (this.isFetchingFilteredRecipientListCount) {
        return 'Loading...';
      }
      if (this.filteredRecipientListCount === null) return null;
      if (this.filteredRecipientListCount === 0) {
        return 'No matching recipients';
      } else if (this.filteredRecipientListCount === 1) {
        return `${this.filteredRecipientListCount} recipient will receive your message`;
      } else {
        return `${this.filteredRecipientListCount} recipients will receive your message`;
      }
    },
    associatedEventCopy() {
      if (!this.sendFromEventOid && !this.suggestedAudienceEventOid) {
        return null;
      }
      const oid = this.sendFromEventOid || this.suggestedAudienceEventOid
      const eventObject = this.eventsListObject[oid]
      if (!eventObject) return null;
      return `This is a transactional message for all attendees of ${eventObject.name || 'event'}`
    },
    status() {
      if (!this.selectedList) return 'new';
      if (this.hasFilterConditions && this.senderTabSelected != 'suggested-audience') {
        if (!!this.filteredRecipientListCount) {
          return 'complete';
        } else {
          return 'partial';
        }
      }
      if (this.recipientCount) {
        return 'complete';
      } else {
        return 'partial';
      }
    },
    filterCount() {
      return this.scratchSegment?.filter?.conditions.length;
    },
    hasFilterConditions() {
      return (
        this.filterIsNotEmpty ||
        !(!!this.advancedMessageListTargeting.condition && this.advancedMessageListTargeting.type == 'all')
      );
    },
    filterIsNotEmpty() {
      return this.prunedScratchSegment && this.prunedScratchSegment.filter.conditions.length !== 0;
    },

    recipientCountText() {
      if (!this.selectedList) return;
      return this.recipientCount === 1 ? `(${this.recipientCount} recipient)` : `(${this.recipientCount} recipients)`;
    },
    showPlaceholder() {
      return !this.selectedList;
    },
    selectedListName() {
      if (!!this.suggestedAudienceEventOid && this.suggestedAudienceOids.length > 0) {
        const eventName = this.eventSelectionsText && this.eventSelectionsText.length > 0
          ? this.eventSelectionsText
          : 'Event';
        const audienceNames = this.localEventAudienceStats
          .filter(item => {
            return this.suggestedAudienceOids.indexOf(item.oid) > -1
          })
          .map(item => {
            return item.cleanName
          })
        const concatNames = audienceNames.join(", ")
        return `${eventName} | ${concatNames}`
      }

      if (this.selectedList?.meta?.name) {
        return this.selectedList.meta.name;
      }
      if (!!this.sendFromEventOid && this.sendFromEventOid == this.selectedList?.meta?.eventOid) {
        return 'All attendees';
      }
      if (this.scratchCampaignOid && this.scratchCampaignOid == this.selectedList?.meta?.campaignOid) {
        return this.selectedList.meta && this.selectedList.meta.name
          ? this.selectedList.meta.name
          : this.selectedList.name;
      } else {
        return this.selectedList?.name;
      }
    },
    noMatchingLists() {
      return !this.filteredMessageList.length;
    },
    sendToPlaceholder() {
      return this.listOpen ? 'Search Lists' : 'Select a list...';
    },
    contentButtonText() {
      return this.status === 'new' ? 'Add' : 'Edit';
    },
    recipientsCount() {
      return this.filteredRecipientListCount || 0;
    },
    // SENDER TABS
    hasSenderTabs() {
      return !!this.arEnableSuggestedAudiences && this.isSuggestedAudienceFeatureEnabled && !this.initiatorCampaignOid
    },

    queryParamSuggestedAudienceOid() {
      return this.$route?.query?.suggestedAudience
    },

    eventOidQueryParam() {
      return this.$route?.query?.eventOid
    },

    // EVENT DROPDOWN
    noMatchingLinkLists() {
      return !!this.eventsListSearchText.length && !this.filteredEventsList.length;
    },
    linkToPlaceholder() {
      return this.eventsListOpen ? 'Search events' : 'Select an event...'
    },
    eventInputValue() {
      return this.eventsListOpen ? this.eventsListSearchText : this.eventSelectionsText;
    },
    eventSelectionsText() {
      if (!this.eventsListObject || this.eventsListObject.length === 0) {
        return ''
      }

      if (this.suggestedAudienceEventOid !== null || this.suggestedAudienceEventOid !== undefined) {
        return this.eventsListObject[this.suggestedAudienceEventOid]?.name;
      } else {
        return '';
      }
    },


    // SUGGESTED AUDIENCES
    suggestedAudiencePlaceholder() {
      return 'Select one or more audiences'
      // return this.suggestedAudienceOpen ? 'Search events' : 'Select an event...'
    },
    suggestedAudienceInputValue() {
      return this.suggestedAudienceOpen ? this.suggestedAudienceSearchText : this.suggestedAudienceSelectionsText;
    },
    suggestedAudienceSelectionsText() {
      const keys = Object.keys(this.suggestedAudienceLocal).filter((key) => {
        return !!this.suggestedAudienceLocal[key]
      })
      const keysLength = keys.length
      if (keysLength === 1) {
        return '1 audience type selected';
      } else if (keysLength > 1) {
        return `${keysLength} audience type selected`;
      } else {
        return '';
      }
    },

    baselineSuggestedAudienceList() {
      if (!this.scratchSuggestedAudienceEventOid && !this.suggestedAudienceEventOid) {
        return null;
      }
      if (!this.localMessageLists || this.localMessageLists.length === 0) {
        return null;
      }
      const listName = `_internal_suggested_audience_${this.scratchSuggestedAudienceEventOid || this.suggestedAudienceEventOid}_baseline_`
      return this.localMessageLists.find( item => {
        return item.name === listName
      })
    },
    suggestedAudienceList() {
      if (!this.localEventAudienceStats || this.localEventAudienceStats.length < 1) return [];
      return this.localEventAudienceStats.map (audience => {
        return {
          key: audience.audience,
          contactsEmail: audience.optInEmail,
          contactsSms: audience.optInSms,
          name: audience.cleanName,
          oid: audience.oid,
        }
      })
    },
    // Suggested Audience Oids from suggestedAudienceLocal (before it gets added to scratch)
    suggestedAudienceOids() {
      const audienceMap = this.suggestedAudienceLocal || {};
      const enabledKeys = Object.keys(audienceMap)
        .filter(function(k) {
          return audienceMap[k]
        })
      const audienceOids = (this.localEventAudienceStats || [])
        .filter( item => enabledKeys.indexOf(item.audience) > -1)
        .map( item => item.oid)
      return audienceOids;
    },

    isSuggestedAudienceFeatureEnabled() {
      return !!this.isFeatureEnabled(['suggested-audience'])
    },

    isSuggestedAudienceSelected() {
      return !!this.queryParamSuggestedAudienceOid || this.senderTabSelected === 'suggested-audience'
    }
  },
  methods: {
    ...mapActions({
      fetchMessageLists: 'messageList/FETCH_MESSAGE_LISTS_AND_ATTENDEES',
      fetchFilteredRecipientListCount: 'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      fetchEvents: 'FETCH_EVENTS',
      fetchEventsByOid: 'FETCH_EVENTS_BY_OID_AND_SEARCH',
    }),
    ...mapMutations({
      toggleFilterSidebar: 'layout/TOGGLE_SEGMENT_DRAWER',
      setCurrentMessageList: 'messageList/SET_CURRENT_MESSAGE_LIST',
      resetCurrentMessageList: 'messageList/RESET_CURRENT_MESSAGE_LIST',
      resetFilters: 'segment/RESET_SCRATCH_SEGMENT_FROM_MESSAGE_SEGMENT',
      updateEmailFilter: 'message/PUT_FILTERING_IN_SCRATCH_EMAIL_MESSAGE',
      updateSmsFilter: 'message/PUT_FILTERING_IN_SCRATCH_SIMPLE_MESSAGE',
      resetAdvancedFilters: 'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      setSuggestedAudienceRecipientListCount: 'messageList/SET_SUGGESTED_AUDIENCE_RECIPIENT_LIST_COUNT',
      setScratchSegmentByMessageSegment: 'segment/SET_SCRATCH_SEGMENT_BY_MESSAGE_SEGMENT',
    }),
    reinstateFilters() {
      if (!this.oldFilter) {
        return;
      }

      this.setScratchSegmentByMessageSegment({
        messageSegmentOid: this.messageOid || null,
        filter: this.oldFilter
      });

    },
    async handleFiltersReset() {
      this.resetFilters();
      this.updateFilter({conditions: [], logic: []})
      await this.resetAdvancedFilters();
      if (this.senderTabSelected !== 'suggested-audience') {
        this.handleAdvancedTargetingUpdate();
      }
    },
    async resetEvent() {
      this.selectedEventOid = null
      this.suggestedAudienceEventOid = null
      this.sendFromEventOid = null
      this.selectedList = null
      await this.fetchLocalLists({})
    },
    handleFilterClick() {
      this.toggleFilterSidebar({ hideCreateSegmentButton: true });
    },
    async handleFakeInputClick() {
      this.showInput = true;
      this.suggestedAudienceOpen = false;
      await this.$nextTick();
      this.$refs.sendToInput.focus();
    },
    handleListSearchInput(val) {
      this.listSearchText = val;

      this.filterLists(this.listSearchText);
    },
    async handleListSelect(list) {
      // reset filters if switching to a campaign list
      if (!!list?.campaignOid) {
        await this.handleFiltersReset();
      }
      this.selectedList = list;
      this.listOpen = false;
      this.showInput = false;
      this.filteredMessageList = this.messageListsMinusHiddenOnes;
      this.setCurrentMessageList(list);

      if (this.alwaysOpen) {
        this.handleSave()
      }
    },
    filterLists(text) {
      let filteredMessageList = [];
      let i = 0;
      while (i < this.messageListsMinusHiddenOnes.length) {
        let name =
          this.messageListsMinusHiddenOnes[i].meta && this.messageListsMinusHiddenOnes[i].meta.name
            ? this.messageListsMinusHiddenOnes[i].meta.name
            : this.messageListsMinusHiddenOnes[i].name;
        if (this.$arFuzzySearch(name, text)) {
          filteredMessageList.push(this.messageListsMinusHiddenOnes[i]);
        }

        i++;
      }

      this.filteredMessageList = filteredMessageList;
    },
    handleClickOutside(e) {
      if (!this.isOpen) return;

      let safeElementClasses = ['list-item', 'arrowhead-wrapper', 'input', 'list-item no-match'];
      if (!safeElementClasses.includes(e.target.className)) {
        this.listOpen = false;
        this.showInput = false;

        let name = ''
        if (!this.selectedList) {
          this.listSearchText = '';
        } else {
          name = this.selectedList.meta && this.selectedList.meta.name ? this.selectedList.meta.name : this.selectedList.name;
        }

        if (!!this.selectedList && this.listSearchText !== name) {
          this.selectedList = null;
          this.listSearchText = '';
          this.showInput = false;
        }
        this.filteredMessageList = this.messageListsMinusHiddenOnes;
      }
    },
    handleFocus() {
      this.listOpen = true;
    },
    handleArrowClick() {
      this.listOpen = !this.listOpen;
      if (!this.listOpen) {
        this.showInput = false;
      }
    },
    handleSave() {
      if (!this.alwaysOpen && (!this.selectedList || this.showInvalidListWarning)) {
        this.isEmptyError = true;
        return
      }
      this.$emit('update', {
        list: this.selectedList,
        filter: this.scratchSegment?.filter,
        ignoreSuppressions: this.ignoreSuppressions,
        suggestedAudience: {
          audienceOids: this.suggestedAudienceOids,
          eventOid: this.suggestedAudienceEventOid,
        },
        sendFromEvent: {
          eventOid: this.sendFromEventOid,
        },
        eventOid: this.suggestedAudienceEventOid || this.sendFromEventOid || this.selectedEventOid,
      });

      // Reset old selected list
      this.oldSelectedList = null;
      this.oldFilter = null;

      if (!this.alwaysOpen) {
        this.isOpen = false;
        this.$emit('close');
      }
    },
    handleCancel() {
      if (this.selectedList?.oid !== this.scratchMessage?.meta?.messageListOid
        && typeof this.selectedList?.oid === 'number' && typeof this.scratchMessage?.meta?.messageListOid  === 'number') {
        this.$emit('closeCallback', this.restoreSavedSettings)
      } else {
        this.restoreSavedSettings()
      }
    },
    restoreSavedSettings() {
      if (!!this.preEditSettings.messageList) {
        this.selectedList = clone(this.preEditSettings.messageList);
        this.setCurrentMessageList(this.selectedList)
      }
      this.selectedEventOid = this.preEditSettings.selectedEventOid;
      this.sendFromEventOid = this.preEditSettings.sendFromEventOid;
      this.suggestedAudienceEventOid = this.preEditSettings.suggestedAudienceEventOid;
      this.audienceOids = clone(this.preEditSettings.audienceOids)
      this.senderTabSelected = this.preEditSettings.senderTabSelected
      this.filteredSuggestedAudience = clone(this.preEditSettings.filteredSuggestedAudience)

      this.isOpen = false;
      this.filteredMessageList = this.messageListsMinusHiddenOnes;
      if (!!this.preEditSettings?.recipientFilter) {
        const cloned = clone(this.preEditSettings?.recipientFilter)
        this.updateFilter(cloned)
        this.setScratchSegmentByMessageSegment({
          messageSegmentOid: this.messageOid || null,
          filter: cloned
        });
      } else {
        this.resetFilters();
      }
      this.$emit('close');
    },
    handleAdd() {
      this.isOpen = !this.isOpen;
      this.$emit('open');
    },
    handleEdit() {
      this.isOpen = true;
      this.$emit('open');
    },
    async handleAdvancedTargetingUpdate() {
      const targetingFilter = this.appendSidebarFiltersToQuickFilters(clone(this.getCurrentFilterExpression));
      this.updateFilter(targetingFilter);
      await this.$nextTick(() => {
        this.fetchFilteredRecipientListCount({ channel: this.channel });
      });
    },
    appendSidebarFiltersToQuickFilters(targetingFilter) {
      if (!targetingFilter || targetingFilter?.conditions?.length === 0) {
        return this.prunedScratchSegment.filter;
      }
      if (!!this.prunedScratchSegment.filter.conditions && this.prunedScratchSegment.filter.conditions.length > 0) {
        targetingFilter.conditions = this.prunedScratchSegment.filter.conditions.concat(targetingFilter.conditions);
        if (this.prunedScratchSegment.filter.logic.length > 0) {
          targetingFilter.logic = ['(']
            .concat(this.prunedScratchSegment.filter.logic)
            .concat([')'])
            .concat(['and'])
            .concat(targetingFilter.logic);
        } else {
          targetingFilter.logic = ['and'].concat(targetingFilter.logic);
        }
      }

      return targetingFilter;
    },

    // triggered by ref in modify/email/index
    handleEmptyError() {
      this.isOpen = true;
      this.isEmptyError = true;
    },

    // SENDER TABS
    handleSenderTabSelect(tab) {
      this.senderTabSelected = tab.key
    },

    updateFilter(filter) {
      if (this.senderTabSelected === 'suggested-audience') {
        this.fetchSuggestedAudienceRecipientCount(filter);
      }
      if (this.channel === 'email') {
        this.updateEmailFilter(filter);
        return;
      } else if (this.channel === 'sms') {
        this.updateSmsFilter(filter);
        return;
      }
      console.error("No channel specified! Cannot update filter!")
    },

    async fetchSuggestedAudienceRecipientCount(filter) {
      if (filter) {
        this.suggestedAudienceRecipientCount =
          await this.$api.audience.fetchAudienceCount(this.promoterOid, this.addOptInConditionToFilter(filter));
      } else {
        this.suggestedAudienceRecipientCount = 0;
      }

      this.setSuggestedAudienceRecipientListCount(this.suggestedAudienceRecipientCount)
    },

    addOptInConditionToFilter(filter) {
      if (!filter) return filter;
      if (!this.channel) return filter;
      let newFilter = {
        ...filter
      }
      if (this.channel === 'email') {
        newFilter.conditions.push({ name: 'subscribedTo', type: 'select', data: 'email' })
      } else if (this.channel === 'sms') {
        newFilter.conditions.push({ name: 'subscribedTo', type: 'select', data: 'sms' })
      }
      newFilter.logic = ["("].concat(filter.logic).concat([")", "and"])
      return newFilter;
    },




    // ============================
    // EVENT DROPDOWN
    // ============================

    handleClickOutsideEventsListContainer(e) {
      if (!this.eventsListOpen) return;
      if (e.target.id === 'linkInput') return;

      let safeClasses = [
        'deselect-link-wrapper',
        'link-list-item list-item',
        'apply-deselect-wrapper',
        'apply-button-wrapper',
        'link-arrowhead-wrapper'
      ];
      if (safeClasses.includes(e.target.className)) return;

      this.eventsListSearchText = '';
      this.filteredEventsList = this.eventsListObject;
      this.eventsListOpen = false;
    },

    async searchEventsOnBackend(text) {
      let { rows } = await this.fetchEvents({
        orderBy: 'datecreated',
        top: '10',
        selectString: 'name,location,startDate',
        searchString: text
      });
      this.localEvents = rows
      let localTimezone = dayjs.tz.guess();
      let events = rows?.map(item => {
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          'date-string': displayDateRangeUSNoDay(
            dayjs(item.startDate)
              .tz(localTimezone)
              .valueOf(),
            null,
            localTimezone
          )
        };
      }) || null;
      this.filteredEventsList = events;
    },
    handleLinkArrowClick() {
      this.eventsListOpen = !this.eventsListOpen;
      if (this.eventsListOpen) {
        this.$refs.recipientLinkListInput.focus();
      } else {
        if (!this.suggestedAudienceEventOid) this.eventsListSearchText = '';
      }
    },
    handleEventSelect(event) {
      // this.$refs[`linked-event-checkbox-${event.oid}`][0].toggle();
      this.eventsListObject[event.oid] = event;

      // Code after 'Apply' is pressed
      this.sendFromEventOid = event.oid
      this.suggestedAudienceEventOid = event.oid;
      this.eventsListOpen = false;
      if (this.alwaysOpen) {
        this.handleSave()
      }
    },
    handleLinkFocus() {
      this.eventsListOpen = true;
    },
    handleEventSearchInput(text) {
      this.eventsListSearchText = text;
      if (!text.length) {
        this.filteredEventsList = Object.values(this.eventsListObject);
      } else {
        this.searchEventsOnBackend(text);
      }
    },

    // ============================
    // SUGGESTED AUDIENCE DROPDOWN
    // ============================

    handleClickOutsideSuggestedAudienceContainer(e) {
      if (!this.suggestedAudienceOpen) return;
      if (e.target.id === 'suggestedAudienceInput') return;

      let safeClasses = [
        'deselect-link-wrapper',
        'link-list-item list-item',
        'apply-deselect-wrapper',
        'apply-button-wrapper',
        'link-arrowhead-wrapper'
      ];
      if (safeClasses.includes(e.target.className)) return;

      this.suggestedAudienceSearchText = '';
      this.filteredSuggestedAudience = this.suggestedAudienceList;
      this.suggestedAudienceOpen = false;
    },
    handleSuggestedAudienceFocus() {
      this.suggestedAudienceOpen = true;
    },
    handleSuggestedAudienceSearchInput(text) {
      this.suggestedAudienceSearchText = text;
      if (!text.length) {
        this.filteredSuggestedAudience = Object.values()
        // this.filteredEventsList = Object.values(this.eventsListObject);
      } else {
        this.filterSuggestedAudience(text);
      }
    },
    handleSuggestedAudienceSelect(suggestedAudience, index) {
      this.$refs[`suggested-audience-checkbox-${index}`][0].toggle();
      // this.selectedSuggestedAudienceLocal[suggestedAudience.key] = suggestedAudience;
      if (this.alwaysOpen) {
        this.handleSave()
      }
    },
    handleSuggestedAudienceArrowClick() {
      this.suggestedAudienceOpen = !this.suggestedAudienceOpen;
      if (this.suggestedAudienceOpen) {
        this.$refs.suggestedAudienceInput.focus();
      } else {
        if (this.suggestedAudienceLocal.length === 0) this.suggestedAudienceSearchText = '';
      }
    },
    handleApplySuggestedAudienceSelections() {
      if (this.alwaysOpen) {
        this.handleSave()
      }

      this.suggestedAudienceOpen = false;
    },
    filterSuggestedAudience(text) {
      let filteredSuggestedAudience = [];
      let i = 0;
      while (i < this.suggestedAudienceList.length) {
        let name = this.suggestedAudienceList[i].name
        if (this.$arFuzzySearch(name, text)) {
          filteredSuggestedAudience.push(this.suggestedAudienceList[i]);
        }
        i++;
      }
      this.filteredSuggestedAudience = filteredSuggestedAudience;
    },

    generateSegmentCondition(filterGroupOid) {
      return {
        data: {
          values: [filterGroupOid],
          condition: "true_to_all"
        },
        name: "segment",
        type: "condition-search-picker"
      }
    },

    updateRecipientFilterFromSuggestedAudience() {
      const suggestedAudienceOids = this.suggestedAudienceOids;
      // Grab the relevant audience stats, making sure to exclude any which have invalid filters (if they happen
      // to have been selected somehow)
      const relevantAudienceSnapshots = this.localEventAudienceStats.filter( item => {
        return suggestedAudienceOids.indexOf(item.oid) > -1
      }).filter( item => {
        return !!item?.meta?.audienceFilterValid
      })
      // Then pull out their filterGroupOids
      const relevantFilterGroupOids = relevantAudienceSnapshots.map(item => {
        return item.filterGroupOid
      })
      const segmentConditions = relevantFilterGroupOids.map(oid =>
        this.generateSegmentCondition(oid)
      )
      let logicValues = [];
      if (segmentConditions.length - 1 > 0) {
        logicValues = Array(segmentConditions.length - 1)
          .fill("or", 0, segmentConditions.length - 1);
      }

      const recipientFilter = {
        conditions: segmentConditions,
        logic: logicValues
      }

      this.updateFilter(recipientFilter)
    },

    // Construct the suggestedAudienceLocal map from the scratch message object
    constructSuggestedAudienceLocal() {
      if (!this.localEventAudienceStats || this.localEventAudienceStats.length === 0) {
        this.suggestedAudienceLocal = {};
        return
      }
      // Dont use suggestedAudienceLocal because thats constructed from suggestedAudienceLocal
      // Got a weird circular logic happening here
      const scratchOids = this.scratchMessage?.meta?.suggestedAudience?.audienceOids || []
      const queryOid = this.queryParamSuggestedAudienceOid


      let audienceOids = [];
      if (scratchOids?.length > 0) {
        audienceOids = scratchOids;
      } else if (!!queryOid) {
        audienceOids = [parseInt(queryOid)]
      }

      const oids = (audienceOids?.length > 0 ? audienceOids : null) ||
        (this.queryParamSuggestedAudienceOid ? [parseInt(this.queryParamSuggestedAudienceOid)] : [])

      const keyValues = this.localEventAudienceStats.map( item => {
        if (audienceOids.indexOf(item.oid) > -1) {
          return [item.audience, true]
        }
        return [item.audience, false]
      });
      this.suggestedAudienceLocal = Object.fromEntries(keyValues)
    },





    // ========================
    // INITIALISATION
    // ========================

    // This is a complex component which has to behave differently depending on whether its an event send, campaign send,
    // regular message send or a suggested audience send. Additionally, it needs to behave differently for saved messages
    // vs new messages.
    initialise() {
      if (!!this.currentSelectedMessage?.oid) {
        this.initialiseFromExistingMessage()
      } else {
        this.initialiseFromQueryParams();
      }
    },

    // Existing messages don't need to check query params - we know what we need from the values stored against
    // the message itself
    async initialiseFromExistingMessage() {
      const listOid = this.currentSelectedMessage.meta?.messageListOid;
      const campaignOid = this.currentSelectedMessage.meta?.initiator?.campaignOid;
      const eventOid = this.currentSelectedMessage.meta?.initiator?.eventOid;
      const suggestedAudienceOids = this.currentSelectedMessage.meta?.suggestedAudience?.audienceOids || [];
      await this.fetchRequiredResources({
        campaignOid,
        eventOid,
      })
      this.populateInternalValues({
        campaignOid,
        eventOid,
        listOid,
        suggestedAudienceOids,
      })
    },

    // New messages are initialized using the query parameters as a baseline for required resources
    async initialiseFromQueryParams() {
      const listOid = this.$route?.query?.messageList || null;
      const campaignOid = this.campaignOid || null;
      const eventOid = this.$route?.query?.eventOid || null;
      const suggestedAudienceOid = this.$route?.query?.suggestedAudience || null;
      const suggestedAudienceOids = suggestedAudienceOid ? [suggestedAudienceOid] : []
      await this.fetchRequiredResources({
        campaignOid,
        eventOid,
      })
      this.populateInternalValues({
        campaignOid,
        eventOid,
        listOid,
        suggestedAudienceOids,
      })
    },

    async fetchRequiredResources({campaignOid, eventOid}) {
      await this.fetchLocalEvents()
      // We have watchers for grabbing lists for campaigns and events, so just grab regular lists here
      if (!campaignOid && !eventOid) await this.fetchLocalLists({})
    },

    // Once we've fetched required resource, we need to populate our internal component values so that the
    // component can correctly render
    populateInternalValues({campaignOid, eventOid, listOid, suggestedAudienceOids}) {
      if (!!eventOid &&
        !!suggestedAudienceOids &&
        suggestedAudienceOids.length > 0 &&
        this.arEnableSuggestedAudiences &&
        this.isSuggestedAudienceFeatureEnabled) {
        this.senderTabSelected = 'suggested-audience'
        this.suggestedAudienceEventOid = eventOid;
      } else {
        this.senderTabSelected = 'list'
        this.sendFromEventOid = eventOid || null
      }
      if (eventOid && !listOid && this.isSuggestedAudienceSelected) {
        this.selectedList = this.baselineSuggestedAudienceList
      }
    },

    async fetchLocalLists({ campaignOid, eventOid }) {
      let finalMessageLists = [];
      try {
        if (eventOid){
          let eventLists = await this.$api?.messageLists.fetchEventMessageLists(this.promoterOid, eventOid);
          if (!!eventLists) {
            eventLists.forEach(item => {
              finalMessageLists.push(item);
            });
            let suggestedAudienceLists = await this.$api?.messageLists.fetchEventSuggestedAudienceList(this.promoterOid, eventOid);
            suggestedAudienceLists.forEach(item => {
              finalMessageLists.push(item);
            })
          }
        }
        if (campaignOid){
          let campaignLists = await this.$api?.messageLists.fetchCampaignMessageLists(this.promoterOid, campaignOid);
          if (!!campaignLists) {
            campaignLists.forEach(item => {
              finalMessageLists.push(item);
            });
          }
        }
        if (!eventOid && !campaignOid) {
          let messageLists = await this.$api?.messageLists.getMessageLists(this.promoterOid);
          if (!!messageLists) {
            messageLists.forEach(item => {
              finalMessageLists.push(item);
            });
          }
        }
        this.localMessageLists = finalMessageLists;
      } catch (error) {
        console.error(error)
      }
      return true;
    },

    async fetchLocalEvents() {
      try {
        let { rows } = await this.fetchEvents({
          orderBy: 'datecreated',
          top: '10',
          selectString: 'name,location,startDate'
        });
        this.localEvents = rows

        // TODO - Can we simplify the below into a computed prop?

        let localTimezone = dayjs.tz.guess();
        let length = rows.length;
        let i = 0;
        let eventsListObject = {};
        while (i < length) {
          eventsListObject[rows[i].oid] = {
            oid: rows[i].oid,
            name: rows[i].name,
            location: rows[i].location,
            'date-string': displayDateRangeUSNoDay(
              dayjs(rows[i].startDate)
                .tz(localTimezone)
                .valueOf(),
              null,
              localTimezone
            )
          };
          i++;
        }
        this.filteredEventsList = Object.values(eventsListObject);
        this.eventsListObject = eventsListObject;
      } catch(e) {
        console.error("Failed to fetch events for messaging!")
      }
      return true;
    },

    async fetchLocalEventAudienceStats(eventOid) {
      if (!eventOid) {
        this.localEventAudienceStats = [];
        return
      }
      try {
        const data = await this.$api.event.fetchEventAudienceStatistics(this.promoterOid, eventOid) || [];
        this.localEventAudienceStats = data;

        // Yeah, its a bit specific but we want to look for 'false', not undefined
        this.suggestedAudiencesDisabled = data
          .filter( item => item?.meta?.audienceFilterValid === false)
          .map ( item => item.oid)
      } catch (e) {
        console.error("Failed to fetch event audience stats for messaging!")
      }
      return true;
    },

    // Method used by the UI renderer to determine recipient text
    calculateRecipientText(list) {
      const total = list.statsSnapshot.total
      const ignoreOptIns = list.meta?.ignoreMessageListOptIn
      let channelOptins = 0
      let recipientText = 'recipient'
      if (this.channel === 'email') {
        channelOptins = list.statsSnapshot?.email
      } else {
        channelOptins = list.statsSnapshot?.sms?.optedIn
      }
      if (total !== 1) recipientText = 'recipients'
      if (total === channelOptins || ignoreOptIns) {
        return `(${accounting.formatNumber(total)} ${recipientText})`
      } else {
        return `(${accounting.formatNumber(channelOptins)} valid ${recipientText})`
      }

    }
  },
};
</script>
<style lang="scss" scoped>
.message-strip-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 24px;
  width: 100%;
  margin-top: 30px;
  border: 1px solid $skyBlueGrey500;
  border-radius: 4px;
  &.disabled {
    pointer-events: none; // disable all pointer events
    opacity: 0.5;
  }

  &.no-wrapper {
    padding: 0px;
    margin-top: 0px;
    border: 0px;
  }

  .message-strip-inner {
    min-height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: auto;

    .icon-wrapper {
      margin-right: 24px;

      &.new-icon-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
      &.edit-icon-wrapper {
        margin-bottom: auto;
      }
    }

    .message-content-wrapper {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    .message-content-top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &.complete {
        margin-bottom: 21px;
      }

      .content-title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .content-button {
        padding: 10px 16px 11px;
        border: 1px solid $purple500;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }

    .message-content-middle {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

      .message-content-middle-container {
        &.complete {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;

          .complete-details-wrapper {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            .complete-left {
              width: 60%;
              padding-right: 15px;
            }

            .complete-right {
              margin-left: auto;

              .left-copy {
                color: $blueGrey800;
              }
            }

            .left-subheading {
              margin-bottom: 6px;
            }
          }
        }
        .left-subheading {
          color: $skyBlueGrey700;
        }
      }
    }
  }

  .inner-details-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .inner-details-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-top: 26px;
      position: relative;

      .sender-tabs {
        width: 100%;
        margin-top: 12px;
      }

      .selected-tab-wrapper {
        width: 100%;
      }

      .input-search-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 12px;

        .fake-input-wrapper {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 19px 17px 15px;
          border: 1px solid $blueGrey500;
          border-radius: 4px;
          cursor: pointer;
          position: relative;

          &.event-input-container {
            flex-flow: row wrap;

            .subheading {
              width: 100%;
              margin-top: 8px;
            }
          }

          &.invalid-warning {
            border: 1px solid $red500;
            box-shadow: 0px 0px 0px 3px $red400;
          }

          .fake-text-area-wapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }

          .arrowhead-wrapper {
            position: absolute;
            right: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            top: 0;

            & > * {
              pointer-events: none;
            }

            .send-to-input-dropdown-arrowhead {
              transition: all 0.2s ease;

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
        }

        .input-arrowhead-wrapper {
          width: 100%;
          position: relative;
          cursor: pointer;

          .search-icon-wrapper {
            z-index: 1;
            height: 100%;
            position: absolute;
            left: 13px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .search-icon {
              opacity: 0.4;
            }
          }

          .arrowhead-wrapper {
            position: absolute;
            right: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            top: 0;

            & > * {
              pointer-events: none;
            }

            .send-to-input-dropdown-arrowhead {
              transition: all 0.2s ease;

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }

          .search-icon-wrapper {
            z-index: 1;
            height: 100%;
            position: absolute;
            left: 13px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .search-icon {
              opacity: 0.4;
            }
          }

          .sender-arrowhead-wrapper,
          .link-arrowhead-wrapper {
            position: absolute;
            right: 0;
            height: 50px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            top: 0;

            & > * {
              pointer-events: none;
            }

            .send-to-input-dropdown-arrowhead {
              &.rotate {
                transform: rotate(180deg);
              }
            }
          }
        }

        .filter-button-wrapper {
          position: relative;

          .filter-count-circle-wrapper {
            position: absolute;
            top: 1px;
            right: 1px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            border-radius: 7.5px;
            background: $purple500;
            transform: translateX(50%) translateY(-50%);
            color: white;
            z-index: $zIndexHigh;

            .filter-recipient-count {
              position: relative;
              font-size: 10px;
              line-height: 10px;
              top: -1.5px;
              left: 0.2px;
            }
          }
        }
      }

      .no-valid-contacts-copy {
        color: $red500;
        margin-top: 8px;
      }

      .ignore-suppressions-wrapper {
        width: 100%;
        margin-top: 12.5px;
      }

      .filtered-recipient-wrapper {
        background: $skyBlueGrey300;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 13px 16px;
        margin-top: 16px;
        border-radius: 4px;

        &.no-recipients {
          background: $orange200;

          .filtered-recipient-container {
            .filter-reset-link {
              color: $orange500;
            }
          }
        }

        .filtered-recipient-container {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;

          .recipients-copy-inner {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;

            .people-icon {
              margin-right: 12px;
            }
          }
        }
      }

      .list-items-wrapper {
        border: 1px solid $blueGrey500;
        border-radius: 4px;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
        width: calc(100% - 100px);
        background: white;
        position: absolute;
        z-index: 1;
        max-height: 181px;
        overflow: auto;
        top: 85px;

        &.event-items-wrapper {
          max-height: 294px;
          width: 100%;
          top: 136px;
        }

        &.suggested-audiences-enabled {
          top: 136px;
        }


        .list-item {
          padding: 12px 20px;
          border-bottom: 1px solid $blueGrey500;
          width: 100%;
          cursor: pointer;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          &:hover {
            background: #f5f1ff;
          }

          &.no-match {
            cursor: default;
          }

          &:last-child {
            border-bottom: unset;
          }

          & > * {
            pointer-events: none;
          }
        }

        .link-list-item {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 12px 0;
          width: 100%;
          cursor: pointer;

          &:hover {
            background: #f5f1ff;
          }

          &:first-child {
            border-top: unset;
          }

          &.no-match {
            cursor: default;

            &:hover {
              background: white;
            }
          }

          & > * {
            pointer-events: none;
          }

          /*.dropdown-item-checkbox {
            margin-top: 4px;
          }*/

          .dropdown-copy-wrapper {
            pointer-events: none;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            margin-left: 15px;

            &.suggested-audience-copy-wrapper {
              width: 100%;
              flex-flow: row;
              justify-content: space-between;
              align-items: center;
            }

            .event-date-location-wrapper {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: flex-start;
              width: 100%;

              .list-date {
                color: $skyBlueGrey700;
                margin-right: 5px;
              }
              .list-location {
                color: $skyBlueGrey700;
              }
            }
          }

          &.selected {
            background: #f5f1ff;
          }
        }



        &.suggested-audience-items-wrapper {
          max-height: 294px;
          width: 100%;
          top: 250px;

          .link-list-item {
            padding: 12px 16px;

          }
        }




        .apply-deselect-wrapper {
          position: sticky;
          bottom: 0;
          padding: 16px 12px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;
          border-top: 1px solid #dcdee4;
          background: white;

          .apply-button-wrapper {
            cursor: pointer;

            & > * {
              pointer-events: none;
            }
          }
        }
      }



      .event-items-wrapper {
        max-height: 329px;
      }

      .action-buttons-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        margin-top: 32px;

        .cancel-link {
          margin-right: 16px;
        }
      }
    }
  }
}
.message-list-advanced-targeting-section {
  width: 100%;
}
</style>
