var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditableFrame',{staticClass:"send-email-wrapper",attrs:{"title":"Edit Action","subtitle":_vm.item.name,"has-save-button":"","save-button-disabled":_vm.savedButtonDisabled},on:{"close":function($event){return _vm.$emit('close')},"save":_vm.handleSave}},[_c('am2-create-message-list-modal',{attrs:{"is-show":_vm.displayCreateMessageListModal,"isAutomation":true},on:{"close":_vm.handleCreateMessageListModalClose}}),_vm._v(" "),_c('am2-email-editor-modal',{attrs:{"opened":_vm.displayEmailEditorModal,"location":"automation","template-type":_vm.emailEditorModalTemplateType,"default-template":_vm.emailTemplate},on:{"update":_vm.handleEmailEditingUpdate,"cancel":_vm.handleEmailEditingCancel,"select":_vm.handleSelectEmailTemplateSelect,"setEmailEditor":_vm.setEmailEditorModalTemplateType}}),_vm._v(" "),_c('am2-email-preview-modal',{attrs:{"opened":_vm.displayEmailPreviewModal,"has-mode-tabs":"","subject":_vm.subject,"preview-text":_vm.previewText,"sender-name":_vm.senderName,"business-address":_vm.businessAddress,"email-html":_vm.emailHtmlWithInfo,"automation-block-oid":_vm.blockOid,"show-share-link":""},on:{"close":_vm.handleEmailPreviewClose}}),_vm._v(" "),_c('ar-field',[_c('div',{staticClass:"type-section"},[_c('ar-text',{staticClass:"u-margin-bottom-3",attrs:{"size":"14px","text":"What type of message are you sending?","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-button-switch',{attrs:{"value":_vm.selectedType,"items":_vm.types},on:{"update":_vm.handleUpdateSelectedType}}),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"13px","text":_vm.description[_vm.selectedType],"multiple-lines":""}})],1)]),_vm._v(" "),(_vm.selectedType === 'marketing')?_c('ar-field',{attrs:{"error-messages":[
      _vm.veeErrors.first('messageRecipient') ],"data-test-id":"automation-email-recipient-field"}},[_c('div',{staticClass:"recipient-section"},[_c('ar-text',{staticClass:"label",attrs:{"size":"14px","text":"To","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('div',{staticClass:"add-to-list-dropdown-wrapper",attrs:{"data-test-id":"add-to-list-toggle"},on:{"click":_vm.toggleOptions}},[_c('ar-text',{staticClass:"add-to-list-copy",attrs:{"size":"14px","text":_vm.messageListText,"multiple-lines":"","max-lines":1,"line-height":"20px"}}),_vm._v(" "),_c('ArrowHead',{staticClass:"add-to-list-dropdown-arrowhead",class:_vm.listOpen && 'rotate',attrs:{"height":"8px","width":"10px","fill":"#8492a6"}})],1),_vm._v(" "),(_vm.listOpen)?_c('div',{staticClass:"list-search-wrapper"},[_c('am2-search',{staticClass:"add-to-list-search",attrs:{"placeholder":"Search lists...","data-test-id":"add-to-list-search"},model:{value:(_vm.listSearchText),callback:function ($$v) {_vm.listSearchText=$$v},expression:"listSearchText"}}),_vm._v(" "),_c('div',{staticClass:"message-list-wrapper"},[(_vm.noMatchingLists)?_c('div',{staticClass:"message-list-link no-list-found-wrapper"},[_c('div',{staticClass:"item-wrapper"},[_c('ar-text',{staticClass:"item-name",style:({ marginRight: '4px' }),attrs:{"size":"xs","text":"No matching list found","multiple-lines":"","max-lines":1,"line-height":"20px","weight":"normal"}})],1)]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredMessageList),function(list){return [_c('a',{key:list.oid,staticClass:"message-list-link",attrs:{"data-test-id":("add-to-list-anchor-" + (list.oid))},on:{"click":function () { return _vm.setSelectedListOid(list.oid); }}},[_c('div',{staticClass:"item-wrapper"},[_c('ar-text',{class:[
                    'list-name',
                    list.oid === _vm.selectedListOid && 'selected'
                  ],attrs:{"size":"xs","text":list.name,"multiple-lines":"","max-lines":1,"line-height":"20px","weight":list.oid === _vm.selectedListOid ? 'bold' : 'normal'}})],1)])]})],2)],1):_vm._e(),_vm._v(" "),(_vm.isTriggerMessageListMatching)?[_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
          }),attrs:{"text":"Automated marketing message recipients must be subscribed to a list","size":"xxs","multiple-lines":""}})]:[_c('ar-snackbar',{staticClass:"u-margin-top-0",attrs:{"type":"warning","message":"Your list does not match your trigger list. Unless recipients are in both lists, they will not receive an email."}})],_vm._v(" "),_c('div',{staticClass:"item-wrapper u-padding-top-4"},[_c('ar-simple-button',{staticClass:"create-list-button",attrs:{"text":"Create New List","outlined":""},on:{"click":_vm.handleCreateListClick}})],1)],2)]):_vm._e(),_vm._v(" "),_c('ar-field',{attrs:{"error-messages":[
      _vm.veeErrors.first('messageSender') ],"data-test-id":"automation-email-sender-field"}},[_c('div',{staticClass:"sender-section"},[_c('ar-text',{staticClass:"label",attrs:{"size":"14px","text":"From","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-message-sender-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"sender-select",attrs:{"value":_vm.messageSenderOid,"data-vv-name":"messageSender","data-vv-as":"message sender"},on:{"select":_vm.handleMessageSenderSelect}}),_vm._v(" "),(_vm.emailAddressIsRestricted)?_c('ar-snackbar',{staticClass:"u-margin-top-4",attrs:{"type":"warning","message":"<b>Domains from email providers like @gmail.com are invalid. <a>Please create a new authenticated domain</a>.</b>"},on:{"anchorClick":_vm.handleDomainAuthClick}}):_vm._e(),_vm._v(" "),(_vm.domainIsRestricted)?_c('ar-snackbar',{staticClass:"u-margin-top-4",attrs:{"type":"warning","message":"<b>Your sender domain is not authenticated. <a>Please authenticate this domain</a>.</b>"},on:{"anchorClick":_vm.handleDomainAuthClick}}):_vm._e(),_vm._v(" "),(_vm.messageSenderNotVerified)?_c('ar-snackbar',{style:({
          marginTop: '10px',
          width: '100%',
        }),attrs:{"type":"general","message":"Please verify this email address. <a>Resend verification email</a>."},on:{"anchorClick":_vm.retryEmailVerification}}):_vm._e()],1)]),_vm._v(" "),_c('ar-field',{attrs:{"error-messages":[
      _vm.veeErrors.first('subject') ],"data-test-id":"automation-email-subject-field"}},[_c('div',{staticClass:"subject-section"},[_c('div',{staticClass:"label-section"},[_c('ar-text',{staticClass:"label",attrs:{"size":"14px","text":"Subject","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),(!_vm.displayPreviewLine)?_c('ar-link-button',{attrs:{"text":"Add preview line"},on:{"click":_vm.handleAddPreviewLineClick}}):_vm._e()],1),_vm._v(" "),_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"input",attrs:{"data-vv-name":"subject","data-vv-as":"subject","placeholder":"Email subject","data-test-id":"automation-email-subject-input-field"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)]),_vm._v(" "),(_vm.displayPreviewLine)?_c('div',{staticClass:"preview-line-section"},[_c('div',{staticClass:"label-section"},[_c('ar-text',{staticClass:"label",attrs:{"size":"14px","text":"PREVIEW LINE","weight":"bold","multiple-lines":"","max-lines":1,"letter-spacing":"1px"}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"Remove preview line","data-test-id":"automation-email-remove-preview-link"},on:{"click":_vm.handleRemovePreviewLineClick}})],1),_vm._v(" "),_c('ar-input',{staticClass:"input",attrs:{"placeholder":"Email preview line","data-test-id":"automation-email-preview-input"},model:{value:(_vm.previewText),callback:function ($$v) {_vm.previewText=$$v},expression:"previewText"}})],1):_vm._e(),_vm._v(" "),_c('ar-field',{attrs:{"error-messages":[
      _vm.veeErrors.first('emailTemplate') ],"data-test-id":"automation-email-design-field"}},[_c('div',{directives:[{name:"validate",rawName:"v-validate:emailHtmlBucketFieldsIsComplete",value:('required:true'),expression:"'required:true'",arg:"emailHtmlBucketFieldsIsComplete"}],attrs:{"data-vv-name":"emailTemplate","data-vv-as":"email design"}}),_vm._v(" "),_c('div',{staticClass:"design-section"},[_c('div',{staticClass:"label-section"},[_c('ar-text',{staticClass:"label",attrs:{"size":"14px","text":"DESIGN","weight":"bold","multiple-lines":"","max-lines":1,"letter-spacing":"1px"}})],1),_vm._v(" "),[(_vm.isGeneratingEmailHtmlFromTemplate)?_c('ar-skeleton',{staticClass:"html-preview",style:({
            borderRadius: '3px',
          })}):(_vm.emailHtml)?_c('am2-html-preview',{staticClass:"html-preview",attrs:{"html":_vm.emailHtmlWithInfo,"enable-scroll":""}}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"action-buttons-section"},[_c('ar-simple-button',{staticClass:"launch-email-button",attrs:{"text":"Launch email builder","outlined":"","data-test-id":"automation-email-launch-builder-button"},on:{"click":_vm.handleLaunchEmailBuilderClick}}),_vm._v(" "),(_vm.emailHtml)?_c('ar-simple-button',{staticClass:"review-email-button",attrs:{"icon-name":"preview","text":"Preview","type":"grey","data-test-id":"automation-email-preview-button"},on:{"click":_vm.handlePreviewEmailClick}}):_vm._e()],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }