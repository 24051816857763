var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'single-rate': true,
    'highlighted': _vm.link,
  },on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":_vm.onClick}},[_c('ar-text',{style:(_vm.link && _vm.hover && {
      color: _vm.$arStyle.color.purple500,
    }),attrs:{"text":(_vm.value + "%"),"size":"lg","weigth":"bold"}}),_vm._v(" "),(_vm.total !== null)?_c('ar-text',{staticClass:"rate-total",style:({
      color: _vm.$arStyle.color.blueGrey700,
    }),attrs:{"text":_vm.formattedTotal,"size":"xxs"}}):_c('div',{style:({ height: '13.66px' })}),_vm._v(" "),_c('div',{staticClass:"rate-title-container"},[_c('ar-text',{staticClass:"rate-desc",style:({
        color: _vm.$arStyle.color.blueGrey700,
      }),attrs:{"text":_vm.title,"size":"xs"}}),_vm._v(" "),(!!_vm.tooltip && _vm.tooltip.length > 1)?_c('ar-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
          content: _vm.tooltip,
          classes: 'align-left',
        }),expression:"{\n          content: tooltip,\n          classes: 'align-left',\n        }",modifiers:{"bottom":true}}],staticClass:"u-margin-left-2 tooltip-question-mark",attrs:{"name":"tooltip-question-mark","color":_vm.$arStyle.color.purple500}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }